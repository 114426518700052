import { createReducer } from '@reduxjs/toolkit';
import {
  clearCandidateVacanciesAction,
  clearVacanciesItemsAction,
  clearVacanciesOptionsAction,
  updatesVacanciesOptionsAction,
} from './vacancies.action';
import { preloadedVacanciesState } from './vacancies.preloaded-state';
import {
  getVacancyItemByIdThunk,
  createVacancyThunk,
  updateVacancyThunk,
  searchAllVacanciesThunk,
  exportVacanciesThunk,
  getCandidateLinkedVacanciesThunk,
  getCandidateVacanciesThunk,
  getVacanciesByIdsThunk,
  searchVacancyOptionsThunk,
  deleteVacancyThunk,
} from './vacancies.thunk';
import { getArrayIds, getStringArrayIds, transformArrayToObject } from '@innowise-group/utilities';
import { logoutAction } from '../common';

export const vacanciesReducer = createReducer(preloadedVacanciesState, (builder) =>
  builder
    .addCase(deleteVacancyThunk.fulfilled, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(deleteVacancyThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(deleteVacancyThunk.rejected, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(clearCandidateVacanciesAction, (state) => {
      state.vacancies.currentCandidateVacancies = preloadedVacanciesState.vacancies.currentCandidateVacancies;
    })
    .addCase(createVacancyThunk.fulfilled, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(createVacancyThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(createVacancyThunk.rejected, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(updateVacancyThunk.fulfilled, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(updateVacancyThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(updateVacancyThunk.rejected, (state) => {
      state.vacancies.isLoading = false;
    })
    .addCase(getVacancyItemByIdThunk.fulfilled, (state, { payload }) => {
      state.vacancies.currentVacancy = payload;
      state.vacancies.items = transformArrayToObject([payload]);
      state.vacancies.ids = getArrayIds([payload]);
      state.vacancies.isLoading = false;
      state.vacancies.overallFound = payload.overallFound;
      state.vacancies.overallNeeded = payload.overallNeeded;
      state.vacancies.requestCount = payload.requestCount;
    })
    .addCase(getVacancyItemByIdThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(getVacancyItemByIdThunk.rejected, (state) => {
      state.vacancies = preloadedVacanciesState.vacancies;
    })
    .addCase(clearVacanciesItemsAction, (state) => {
      state.vacancies = preloadedVacanciesState.vacancies;
    })
    .addCase(searchAllVacanciesThunk.fulfilled, (state, { payload }) => {
      state.vacancies.items = transformArrayToObject(payload.content);
      state.vacancies.ids = getArrayIds(payload.content);
      state.vacancies.isLoading = false;
      state.vacancies.totalElements = payload.totalElements;
      state.vacancies.totalPages = payload.totalPages;
    })
    .addCase(searchAllVacanciesThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(searchAllVacanciesThunk.rejected, (state) => {
      state.vacancies = preloadedVacanciesState.vacancies;
    })
    .addCase(exportVacanciesThunk.fulfilled, (state) => {
      state.vacancies.isExportLoading = false;
    })
    .addCase(exportVacanciesThunk.pending, (state) => {
      state.vacancies.isExportLoading = true;
    })
    .addCase(exportVacanciesThunk.rejected, (state) => {
      state.vacancies.isExportLoading = false;
    })
    .addCase(getCandidateLinkedVacanciesThunk.fulfilled, (state, { payload }) => {
      state.vacancies.items = { ...state.vacancies.items, ...transformArrayToObject(payload.content) };
      state.vacancies.ids = [...state.vacancies.ids, ...getArrayIds(payload.content)];
      state.vacancies.isLoading = false;
      state.vacancies.totalElements = payload.totalElements;
      state.vacancies.totalPages = payload.totalPages;
    })
    .addCase(getCandidateLinkedVacanciesThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(getCandidateLinkedVacanciesThunk.rejected, (state) => {
      state.vacancies = preloadedVacanciesState.vacancies;
    })
    .addCase(getCandidateVacanciesThunk.pending, (state) => {
      state.vacancies.isLoading = true;
    })
    .addCase(getCandidateVacanciesThunk.fulfilled, (state, { payload }) => {
      state.vacancies.isLoading = false;
      state.vacancies.currentCandidateVacancies = payload.content;
    })
    .addCase(getCandidateVacanciesThunk.rejected, (state) => {
      state.vacancies.isLoading = false;
    })

    .addCase(getVacanciesByIdsThunk.pending, (state) => {
      state.vacancyOptions.optionsLoading = true;
    })
    .addCase(getVacanciesByIdsThunk.fulfilled, (state, { payload }) => {
      state.vacancyOptions.optionsLoading = false;
      state.vacancyOptions.ids = getStringArrayIds(payload.options);
      state.vacancyOptions.options = transformArrayToObject(payload.options);
      state.vacancyOptions.items = transformArrayToObject(payload.items);
    })
    .addCase(getVacanciesByIdsThunk.rejected, (state) => {
      state.vacancyOptions.optionsLoading = false;
    })

    .addCase(searchVacancyOptionsThunk.pending, (state, { meta }) => {
      if (meta.arg.params.currentPage === 1) {
        state.vacancyOptions.optionsLoading = true;
      } else {
        state.vacancyOptions.additionalOptionsLoading = true;
      }
    })
    .addCase(searchVacancyOptionsThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.params.currentPage === 1) {
        state.vacancyOptions.optionsLoading = false;
      } else {
        state.vacancyOptions.additionalOptionsLoading = false;
      }
      state.vacancyOptions.ids = [...new Set([...state.vacancyOptions.ids, ...getStringArrayIds(payload.options)])];
      state.vacancyOptions.items = {
        ...state.vacancyOptions.items,
        ...transformArrayToObject(payload.items),
      };
      state.vacancyOptions.options = {
        ...state.vacancyOptions.options,
        ...transformArrayToObject(payload.options),
      };
    })
    .addCase(searchVacancyOptionsThunk.rejected, (state) => {
      state.vacancyOptions = preloadedVacanciesState.vacancyOptions;
    })

    .addCase(updatesVacanciesOptionsAction, (state, { payload }) => {
      state.vacancyOptions.options = {
        ...state.vacancyOptions.options,
        ...payload.reduce(
          (acc, { id, name }) => ({
            ...acc,
            [id]: {
              id: id.toString(),
              value: id.toString(),
              title: name,
            },
          }),
          {},
        ),
      };
      state.vacancyOptions.items = {
        ...state.vacancyOptions.items,
        ...payload.reduce(
          (acc, vacancy) => ({
            ...acc,
            [vacancy.id]: vacancy,
          }),
          {},
        ),
      };
      state.vacancyOptions.ids = [...new Set([...state.vacancyOptions.ids, ...getStringArrayIds(payload)])];
    })
    .addCase(clearVacanciesOptionsAction, (state) => {
      state.vacancyOptions = preloadedVacanciesState.vacancyOptions;
    })
    .addCase(logoutAction, () => preloadedVacanciesState),
);
