import { Input, styled, css } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { VacanciesDiff, StringArrayDataDiff, SalaryDiff, IsBlockedDiff } from '../../../diff-variants';

export const VacancyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
`;

export const TooltipIconWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: 0;
`;

export const CurrencyContainer = styled.div`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
`;

export const SalaryContainer = styled.div`
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SalaryCommentContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 4 / span 1;
  display: flex;
  flex-direction: column;
`;

export const IsBlockedContainer = styled.div`
  grid-column: 3 / -1;
  grid-row: 4 / -1;
  align-self: self-end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 1.75rem;
  position: relative;
`;

export const WorkFormatsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`;

export const FormatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 2 / -1;
  grid-row: 1 / span 3;
  position: relative;
`;

export const CommentInput = styled(Input)`
  &.MuiTextField-root {
    flex: 1;
  }
  & .MuiOutlinedInput-root {
    height: 100%;
  }
`;

export const FormatsTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[60]};
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const BoxForDiffWorkConditions = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 3 / -1;
  grid-row: 1 / 4;
`;

export const BoxForSalaryDiffConsolidation = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1/3;
  grid-row: 3 / span 1;
  column-gap: 1.75rem;
`;

export const CustomLabeledCheckbox = styled(LabeledCheckbox)`
  padding-top: 10px;
`;

const commonDiffElementsPosition = css`
  top: 15px;
`;

export const CustomVacanciesDiff = styled(VacanciesDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomStringArrayDataDiff = styled(StringArrayDataDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomSalaryDiff = styled(SalaryDiff)`
  ${commonDiffElementsPosition}
  grid-column: 1/-1;
`;

export const CustomIsBlockedDiff = styled(IsBlockedDiff)`
  ${commonDiffElementsPosition}
  top: 20px;
`;

export const WorkLoadsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SalaryIconContainer = styled.div`
  margin-top: 3px;
  cursor: pointer;

  & svg:hover {
    fill: transparent;

    & path {
      stroke: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;
