import { Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const ButtonsSection = styled.div`
  display: flex;
  gap: 0.75rem;
  & > * {
    flex: 1;
  }
  margin-top: 1rem;
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ConditionBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  padding: 4px 10px;
  gap: 4px;
`;

export const ConditionTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[60]};
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;

export const ConditionsItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  row-gap: 0.25rem;
`;

export const SalaryIconContainer = styled.div`
  margin-top: 3px;
  cursor: pointer;
  & svg:hover {
    fill: transparent;
    & path {
      stroke: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;
