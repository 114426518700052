import { BaseFilterType, MoneyFilterType } from '@innowise-group/core';
import * as Styled from './salary.styles';
import { Divider } from '@innowise-group/mui-kit';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from '../shared';
import React from 'react';
import { LabeledRadioButton } from '@shared-mui-components';
import { defaultCurrency } from '@constants';

export interface SalaryFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: MoneyFilterType['state'];
  onValueChange: (args: Omit<MoneyFilterType['state'], 'currency'> & { currency: string }) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: MoneyFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const SalaryFilter: React.FC<SalaryFilterProps> = ({
  state,
  labelKey,
  isOpen,
  onValueChange,
  onToggleFilter,
  onRemoveFilter,
  onClickFilterOutside,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [minInputValue, setMinInputValue] = useState('');
  const [maxInputValue, setMaxInputValue] = useState('');

  const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange({ ...state, from: Number(e.target.value) });
    setMinInputValue(e.target.value);
  };
  const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange({ ...state, to: Number(e.target.value) });
    setMaxInputValue(e.target.value);
  };

  const onReset = () => {
    setMinInputValue('');
    setMaxInputValue('');
    onValueChange({
      ...state,
      from: state?.variants?.[state?.currency]?.min,
      to: state?.variants?.[state?.currency]?.max,
      currency: defaultCurrency,
    });
  };

  const onMinInputBlur = () => {
    if (!minInputValue) return;
    return setMinInputValue(state.from?.toString());
  };

  const onMaxInputBlur = () => {
    if (!maxInputValue) {
      return;
    }
    return setMaxInputValue(state.to?.toString());
  };

  const onMinInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setMinInputValue('');
      onValueChange({ ...state, from: state?.variants?.[state?.currency]?.min });
      return;
    }
    if (Number(e.target.value) < state.to && Number(e.target.value) > state?.variants?.[state?.currency]?.min) {
      return onMinChange(e);
    }
    return setMinInputValue(e.target.value);
  };

  const onMaxInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setMaxInputValue('');
      onValueChange({ ...state, to: state?.variants?.[state?.currency]?.max });
      return;
    }
    if (Number(e.target.value) > state.from && Number(e.target.value) <= state?.variants?.[state?.currency]?.max) {
      return onMaxChange(e);
    }
    return setMaxInputValue(e.target.value);
  };

  const onCurrencyChangeHandler = (currency: string) => () => {
    onValueChange({ ...state, from: state?.variants?.[currency]?.min, to: state?.variants?.[currency]?.max, currency });
    setMinInputValue(state?.variants?.[currency]?.min);
    setMaxInputValue(state?.variants?.[currency]?.max);
  };

  const onRemoveFilterHandler = () => {
    onRemoveFilter({
      ...state,
      from: state?.variants?.[defaultCurrency]?.min,
      to: state?.variants?.[defaultCurrency]?.max,
      currency: defaultCurrency,
    });
  };

  const onClickFilterOutsideHandle = () => {
    const isEmpty =
      state.from === state?.variants?.[state?.currency]?.min && state.to === state?.variants?.[state?.currency]?.min;
    if (isEmpty) onRemoveFilterHandler();
    onClickFilterOutside();
  };

  const currentCurrency = state.currency;

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Container ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandler}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          <Styled.SelectedCount>{`${state.from} - ${state.to} ${currentCurrency}`}</Styled.SelectedCount>
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          <Styled.ControlsContainer>
            <Styled.RangePrice>
              <Styled.RangePriceField>
                <Styled.RangePriceLabel htmlFor="min">{t('filters.from')}</Styled.RangePriceLabel>
                <Styled.RangePriceInput
                  placeholder={t('filters.from')}
                  onChange={onMinInputChangeHandler}
                  min={minInputValue}
                  value={minInputValue}
                  onBlur={onMinInputBlur}
                  inputMode="numeric"
                  type="number"
                  name="min"
                />
              </Styled.RangePriceField>
              <Styled.RangePriceField>
                <Styled.RangePriceLabel htmlFor="max">{t('filters.to')}</Styled.RangePriceLabel>
                <Styled.RangePriceInput
                  placeholder={t('filters.to')}
                  onChange={onMaxInputChangeHandler}
                  max={maxInputValue}
                  value={maxInputValue}
                  onBlur={onMaxInputBlur}
                  inputMode="numeric"
                  type="number"
                  name="max"
                />
              </Styled.RangePriceField>
            </Styled.RangePrice>
            <Styled.CustomDualSlider
              minValue={state.from}
              maxValue={state.to}
              step={state.step}
              minRange={state?.variants?.[currentCurrency]?.min}
              maxRange={state?.variants?.[currentCurrency]?.max}
              onMinChange={onMinChange}
              onMaxChange={onMaxChange}
              onReset={onReset}
              staticLimitLabels
            />
            <Styled.CurrencyLabel>{t('pages.candidates.candidateCreation.selectCurrency')}</Styled.CurrencyLabel>
            <Styled.CurrenciesSection>
              {Object.keys(state?.variants)?.map((currency) => (
                <Styled.CurrencyContainer key={currency}>
                  <LabeledRadioButton
                    value={'false'}
                    label={currency}
                    labelPlacement="start"
                    onChange={onCurrencyChangeHandler(currency)}
                    checked={state.currency === currency}
                  />
                </Styled.CurrencyContainer>
              ))}
            </Styled.CurrenciesSection>
          </Styled.ControlsContainer>
          <Divider />
          <Styled.CustomButton onClick={onReset} variant="text">
            {t('filters.resetFilter')}
          </Styled.CustomButton>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Container>
  );
};

export default memo(SalaryFilter);
