import {
  AuthService,
  EnvConfigurationService,
  NotificationMessage,
  PushNotification,
  useDebounce,
  useNotificationsApi,
  useProfileState,
  useResizeObserver,
} from '@innowise-group/core';
import { useEffect, useState } from 'react';
import { Client } from '@stomp/stompjs';
import { notificationsManager, useTheme } from '@innowise-group/mui-kit';
import { PartOfGroupedNotification } from '@shared-mui-components';

let socket;

const useNotificationSubscription = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { item: profile } = useProfileState();
  const { getMessageById } = useNotificationsApi();

  const [shownNotifications, setShownNotifications] = useState<NotificationMessage[]>([]);
  const [value] = useDebounce(shownNotifications.length);

  useEffect(() => {
    if (shownNotifications.length) {
      notificationsManager.warning({
        subtitle: shownNotifications.map((curr) => {
          return <PartOfGroupedNotification key={curr.id} title={curr.title} message={curr.message} />;
        }),
      });
      setShownNotifications([]);
    }
  }, [value]);

  const initSubscriptions = async () => {
    const { WEBSOCKET_BASE_URL: brokerURL } = await EnvConfigurationService.getEnvConfig();
    if (!socket) {
      socket = new Client({
        brokerURL,
        connectHeaders: {
          Authorization: `Bearer ${AuthService.getToken()}`,
        },
      });
    }

    socket.onConnect = async () => {
      socket.subscribe(`/topic/EMPLOYEE-${profile.id}`, async (res) => {
        const message: PushNotification = JSON.parse(res.body);
        const { data } = await getMessageById(message).unwrap();
        if (data) {
          if (isDesktopView) {
            setShownNotifications((prev) => [...prev, data]);
          } else {
            notificationsManager.warning({
              title: data.title,
              subtitle: data.message,
            });
          }
        }
      });
    };
    socket.activate();
  };

  useEffect(() => {
    if (profile) initSubscriptions();
  }, [profile]);

  useEffect(() => {
    return () => {
      if (profile) socket?.deactivate();
    };
  }, []);
};

export default useNotificationSubscription;
