import { useResizeObserver } from '@innowise-group/core';
import { NoResults } from '../../components/no-results';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface MobileGuardProps extends React.PropsWithChildren {
  breakpoint: number;
}

const MobileGuard: React.FC<MobileGuardProps> = ({ breakpoint, children }) => {
  const { t } = useTranslation();
  const isDesktopView = useResizeObserver(breakpoint);
  return isDesktopView ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <NoResults text=" " title={t('errors.onlyWebVersion')} />
  );
};

export default MobileGuard;
