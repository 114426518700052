import { Accordion } from '@innowise-group/mui-kit';
import React, { useMemo } from 'react';
import * as Styled from './section-block.styles';
import { SectionRow } from '../section-row';

interface SectionBlockProps extends React.PropsWithChildren {
  label: string;
  openedSections: number[];
  id: number;
  setOpenedSections: React.Dispatch<React.SetStateAction<number[]>>;
}

const SectionBlock: React.FC<SectionBlockProps> = ({ label, openedSections, id, setOpenedSections, children }) => {
  const isOpen = useMemo(() => !!openedSections.find((item) => item === id), [id, openedSections]);

  const handleChangeIsOpen = () => {
    if (isOpen) {
      setOpenedSections((prev) => prev.filter((item) => item !== id));
      return;
    }
    setOpenedSections((prev) => [...prev, id]);
  };

  return (
    <Accordion expanded={isOpen}>
      <Styled.AccordionSummary onClick={handleChangeIsOpen}>
        <Styled.ArrowIcon type="u_angle-down" isOpen={isOpen} />
        <span>{label}</span>
      </Styled.AccordionSummary>
      <SectionRow>{children}</SectionRow>
    </Accordion>
  );
};

export default React.memo(SectionBlock);
