import { AppRoutes } from '@constants';
import {
  Candidates,
  Vacancies,
  Requests,
  Profile,
  Notifications,
  Administration,
  Analytics,
  Faq,
  Search,
  Calendar,
} from '@modules';

export const AppPages = [
  { path: AppRoutes.Candidates, Component: Candidates },
  { path: AppRoutes.Vacancies, Component: Vacancies },
  { path: AppRoutes.Requests, Component: Requests },
  { path: AppRoutes.Profile, Component: Profile },
  { path: AppRoutes.Notifications, Component: Notifications },
  { path: AppRoutes.Administration, Component: Administration },
  { path: AppRoutes.Analytics, Component: Analytics },
  { path: AppRoutes.FaqView, Component: Faq },
  { path: AppRoutes.Search, Component: Search },
  { path: AppRoutes.Calendar, Component: Calendar },
];
