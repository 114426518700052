import { useContext } from 'react';
import { useTheme as styledUseTheme } from 'styled-components';
import { GlobalThemeContext } from './theme-context.component';
import { FontsMap, Theme } from './theme.model';
import { breakpointUtils, defaultBreakpoints } from './breakpoints.utils';

export * from './theme.model';
export * from './theme-context.component';
export * from 'styled-components';

export { default as styled } from 'styled-components';
export { default as GlobalThemeContextProvider } from './theme-provider.component';

export function useGlobalThemeContext() {
  return useContext(GlobalThemeContext);
}

export function useTheme(): Theme {
  return styledUseTheme() as Theme;
}

const fontsMap: FontsMap = {
  fonts: {
    primary: {
      100: `
        font-family: 'Manrope', sans-serif;
        font-size: 1.375rem;
        font-weight: 800;
      `,
      90: `
        font-family: 'Manrope', sans-serif;
        font-size: 1.25rem;
        font-weight: 700;
      `,
      80: `
        font-family: 'Manrope', sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
      `,
      70: `
        font-family: 'Manrope', sans-serif;
        font-size: 1.125rem;
        font-weight: 500;
      `,
      60: `
        font-family: 'Manrope', sans-serif;
        font-size: 1rem;
        font-weight: 600;
      `,
      50: `
        font-family: 'Manrope', sans-serif;
        font-size: 1rem;
        font-weight: 500;
      `,
      40: `
        font-family: 'Manrope', sans-serif;
        font-size: 1rem;
        font-weight: 400;
      `,
      30: `
        font-family: 'Manrope', sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
      `,
      20: `
        font-family: 'Manrope', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
      `,
      10: `
        font-family: 'Manrope', sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
      `,
    },
  },
};

const commonThemesValues: Pick<Theme, 'animation'> = {
  animation: {
    transition: '0.2s',
  },
};

export function lightTheme(): Theme {
  return {
    ...fontsMap,
    ...commonThemesValues,
    breakpoints: {
      values: defaultBreakpoints,
      ...breakpointUtils,
    },
    palette: {
      main: {
        background: '#DCEAFF',
        backgroundSecondary: '#B8D4FF',
        default: '#0B69FF',
        hover: '#0058E3',
        pressed: '#0055DC',
      },

      secondary: {
        background: '#f0dcfa',
        backgroundSecondary: '#e5c2fd',
        default: '#9757B0',
        hover: '#7B468E',
        pressed: '#643772',
      },

      info: {
        background: '#EAF2FC',
        backgroundSecondary: '#f5f8fe',
        default: '#055AA9',
        hover: '#2c8ce5',
        pressed: '#3179E3',
      },

      general: {
        darkGrey: {
          '100': '#353535',
          '90': '#494949',
          '80': '#5D5D5D',
          '70': '#767676',
          '60': '#868686',
          '40': '#AAAAAA',
          '20': '#F7F7F7',
        },
        lightGrey: {
          '100': '#C3C3C3',
          '90': '#C9C9C9',
          '80': '#CFCFCF',
          '70': '#9A9A9A',
          '60': '#DBDBDB',
          '50': '#EBEBEB',
          '40': '#F3F3F3',
          '30': '#FAFAFA',
          '20': '#FBFBFB',
        },

        paper: 'white',
        white: '#FFFFFF',
        smart: '#C63031',
        lilac: '#9AB4E8',
      },

      specials: {
        avatars: {
          first: '#B3F4E1',
          second: '#B0CDFA',
          third: '#D5BBFF',
          fourth: '#FFBBD3',
          fifth: '#FFDCBB',
          sixth: '#FFF1BB',
          seventh: '#00B2CA',
          eights: '#0051CA',
          nines: '#4D00CA',
          tens: '#F13838',
          eleventh: '#D98304',
          twelfth: '#CA9F00',
        },

        timelines: {
          first: '#A9ECA3',
          second: '#B3F4E1',
          third: '#A4E3FE',
          fourth: '#AFC1FF',
          fifth: '#C3AEFF',
          sixth: '#FF9DE4',
          seventh: '#FF9D9D',
          eights: '#FFC28A',
          nines: '#FFD89D',
          tens: '#DDC196',
          eleventh: '#FFFAFA',
        },
      },

      state: {
        error: {
          default: '#F13838',
          pressed: '#D71414',
          hover: '#E42727',
          background: '#FFEDED',
          backgroundSecondary: '#FFBABA',
        },

        success: {
          default: '#098C84',
          pressed: '#2AB0A8',
          hover: '#086862',
          background: '#F0FFF7',
          backgroundSecondary: '#F4FBFB',
        },

        warning: {
          default: '#FFB800',
          pressed: '#EAA800',
          hover: '#F2AE00',
          background: '#FFF6E0',
          backgroundSecondary: '#FFECBF',
        },
      },
    },
  };
}

export function darkTheme(): Theme {
  return {
    ...fontsMap,
    ...commonThemesValues,
    breakpoints: {
      values: defaultBreakpoints,
      ...breakpointUtils,
    },
    palette: {
      main: {
        background: '#64b5f6',
        backgroundSecondary: '#42a5f5',
        default: '#1976d2',
        hover: '#1976d2',
        pressed: '#1565c0',
      },

      secondary: {
        background: '#b39ddb',
        backgroundSecondary: '#9575cd',
        default: '#7e57c2',
        hover: '#673ab7',
        pressed: '#5e35b1',
      },

      info: {
        background: '#6ea9e1',
        backgroundSecondary: '#589de0',
        default: '#3c88d2',
        hover: '#2375c5',
        pressed: '#1264b4',
      },

      general: {
        lightGrey: {
          '100': 'rgba(247,247,247,0.9)',
          '90': '#AAAAAA',
          '80': '#868686',
          '70': '#9A9A9A',
          '60': '#5D5D5D',
          '50': '#EBEBEB',
          '40': '#494949',
          '30': '#FAFAFA',
          '20': '#353535',
        },

        darkGrey: {
          '100': 'rgba(251,251,251,0.9)',
          '90': '#F3F3F3',
          '80': '#DBDBDB',
          '70': '#767676',
          '60': '#CFCFCF',
          '40': '#C9C9C9',
          '20': '#C3C3C3',
        },

        paper: '#2E2E2E',
        white: '#FFFFFF',
        smart: '#C63031',
        lilac: '#7C92BE',
      },

      specials: {
        avatars: {
          first: '#B3F4E1',
          second: '#B0CDFA',
          third: '#D5BBFF',
          fourth: '#FFBBD3',
          fifth: '#FFDCBB',
          sixth: '#FFF1BB',
          seventh: '#00B2CA',
          eights: '#0051CA',
          nines: '#4D00CA',
          tens: '#F13838',
          eleventh: '#D98304',
          twelfth: '#CA9F00',
        },

        timelines: {
          first: '#A9ECA3',
          second: '#B3F4E1',
          third: '#A4E3FE',
          fourth: '#AFC1FF',
          fifth: '#C3AEFF',
          sixth: '#FF9DE4',
          seventh: '#FF9D9D',
          eights: '#FFC28A',
          nines: '#FFD89D',
          tens: '#DDC196',
          eleventh: '#FFFAFA',
        },
      },

      state: {
        error: {
          default: '#d32f2f',
          pressed: '#c62828',
          hover: '#d02d2d',
          background: '#e57373',
          backgroundSecondary: '#ef5350',
        },

        success: {
          default: '#2e7d32',
          pressed: '#1b5e20',
          hover: '#2a772e',
          background: '#81c784',
          backgroundSecondary: '#4caf50',
        },

        warning: {
          default: '#ffb300',
          pressed: '#ff8f00',
          hover: '#ffa000',
          background: '#ffd54f',
          backgroundSecondary: '#ffca28',
        },
      },
    },
  };
}
