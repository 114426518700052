import { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';

export default class ConsolidationService {
  static async mergeMany(args: {
    originalCandidateId: number;
    duplicateCandidateIds: number[];
  }): Promise<AxiosResponse<void>> {
    return $api.post('/recruiting-tool/api/v1/candidate-duplicates/merge/many', { ...args });
  }
}
