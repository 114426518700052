import { styled, css } from '@innowise-group/mui-kit';

const SliderThumb = css`
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.white};
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 3px 1px ${({ theme }) => theme.palette.general.lightGrey[80]};
`;

const FontStyles = css`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const RangeSlider = styled.div`
  height: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 2px;
  width: 100%;
`;

export const RangeSelected = styled.span<{ min: number; max: number }>`
  height: 100%;
  position: absolute;
  left: ${({ min }) => min}%;
  right: ${({ max }) => max}%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.general.smart};
`;

export const RangeInput = styled.div`
  position: relative;
`;

export const RangeInputField = styled.input`
  position: absolute;
  width: 100%;
  height: 6px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-slider-thumb {
    ${SliderThumb}
  }
  &::-moz-range-thumb {
    ${SliderThumb}
  }
`;

const RangeInputLabel = styled.span`
  width: 55px;
  ${FontStyles}
`;

export const FromInputLabel = styled(RangeInputLabel)`
  text-align: left;
`;
export const ToInputLabel = styled(RangeInputLabel)`
  text-align: right;
`;
