import { FC, HTMLAttributes } from 'react';
import { Box, TooltipProps } from '@mui/material';
import { Tooltip } from '../tooltip';
import { Icon } from './info-tooltip.styles';

interface InfoTooltipProps
  extends Omit<TooltipProps, 'children'>,
    Pick<HTMLAttributes<HTMLOrSVGElement>, 'className'> {}

const InfoTooltip: FC<InfoTooltipProps> = ({ className, ...tooltipProps }) => {
  return (
    <Tooltip {...tooltipProps}>
      <Box>
        <Icon type="u_info" className={className} />
      </Box>
    </Tooltip>
  );
};

export default InfoTooltip;
