import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AuthService } from '../auth';
import { EnvConfigurationService } from '../env-configuration';
import { $api, $filesApi } from './axios';
import { notificationsManager } from '@innowise-group/mui-kit';
import i18n from '@localization';

interface CustomAxiosError<T = unknown, D = any> extends AxiosError<T, D> {
  config: RetryConfig<D>;
}

interface RetryConfig<D = any> extends InternalAxiosRequestConfig<D> {
  _retry: boolean;
}

const initBaseUrl = async () => {
  const envConfig = await EnvConfigurationService.getEnvConfig();
  $api.defaults.baseURL = envConfig.BASE_URL;
  $filesApi.defaults.baseURL = envConfig.BASE_URL;
};

const initInterceptors = () => {
  $filesApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!config?.headers) {
      throw new Error("Expected 'config' and 'config.headers' not to be undefined");
    }
    if (AuthService.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
    }
    return config;
  });
  $filesApi.interceptors.response.use((response) => response, handleError);
  $api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!config?.headers) {
      throw new Error("Expected 'config' and 'config.headers' not to be undefined");
    }
    if (AuthService.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
    }
    return config;
  });
  $api.interceptors.response.use((response) => response, handleError);
};

const handleError = async (err: CustomAxiosError): Promise<CustomAxiosError> => {
  const error = err as CustomAxiosError;
  const { status } = error.response;
  const originalRequest = error.config;
  if (status < 500) {
    if (status === 401 && originalRequest._retry) {
      AuthService.logout();
      notificationsManager.error({
        subtitle: i18n.t('notifications.text.accessDenied'),
      });
      return Promise.reject(error);
    }
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await AuthService.updateToken();
      return $api(originalRequest);
    } else if (status !== 423) {
      notificationsManager.error({
        subtitle: error.response?.data?.['message'] || error.message,
      });
    }
  } else {
    notificationsManager.error({
      subtitle: i18n.t('notifications.text.serverError'),
    });
  }
  return Promise.reject(error);
};

export { initInterceptors as InitInterceptors, initBaseUrl as InitBaseUrl };
