import {
  LocalizedRequestItemResponse,
  LocalizedRequestItemsState,
  RequestItem,
  RequestLoggerState,
  ThunkEffects,
  useAppDispatch,
  useAppSelector,
} from '@innowise-group/core';
import { useCallback } from 'react';
import { getVacancyRequestsThunk } from '../vacancies/vacancies.thunk';
import {
  clearCurrentRequestsAction,
  clearLinkedCandidatesByRequestAction,
  clearLoggerDataAction,
  clearRequestsAction,
  setTabToOpenAction,
} from './requests.actions';
import {
  getRequestsItemsState,
  getDefaultRequestById,
  getCurrentRequestById,
  getRequestsState,
  getRequestsLoggerData,
} from './requests.selectors';
import {
  createRequestThunk,
  getRequestByIdThunk,
  deleteRequestThunk,
  updateRequestThunk,
  getAllRequestsThunk,
  exportRequestsThunk,
  getSelectedCandidatesByRequestThunk,
  approveSelectedCandidatesFromVacancyResponseThunk,
  deleteCandidateFromVacancyResponseThunk,
  getRequestLoggerDataThunk,
} from './requests.thunk';
import { useSelector } from 'react-redux';

export const useRequestsItemsState = (): LocalizedRequestItemsState => useAppSelector(getRequestsItemsState);

export const useDefaultRequestById = (): RequestItem | null => useAppSelector(getDefaultRequestById);
export const useCurrentRequestById = (): LocalizedRequestItemResponse | null => useAppSelector(getCurrentRequestById);

export const useRequestsLoggerData = (): RequestLoggerState => useAppSelector(getRequestsLoggerData);

export const useRequestsAPI = () => {
  const dispatch = useAppDispatch();
  // TODO: Normalize implementation <<<BEGIN
  // https://innowise-group.atlassian.net/jira/software/projects/ITSRT/boards/1455?selectedIssue=ITSRT-504&text=504
  // US-6.2:
  // АС-7
  const { tabToOpen } = useSelector(getRequestsState);
  const {
    isLoading,
    items: requests,
    ids: requestsIds,
    isExportLoading,
    totalPages,
    totalElements,
  } = useRequestsItemsState();
  const {
    isLoading: isLoggerLoading,
    ids: loggerIds,
    items: loggerItems,
    totalPages: loggerTotalPages,
    totalElements: loggerTotalElements,
  } = useRequestsLoggerData();

  const fetchLoggerData = useCallback(
    (args: Parameters<typeof getRequestLoggerDataThunk>[0]) => {
      return dispatch(getRequestLoggerDataThunk(args));
    },
    [dispatch],
  );

  const clearLoggerData = useCallback(() => {
    return dispatch(clearLoggerDataAction());
  }, [dispatch]);

  const fetchSelectedCandidates = useCallback(
    (vacancyRequestId: number) => {
      dispatch(getSelectedCandidatesByRequestThunk({ vacancyRequestId }));
    },
    [dispatch],
  );

  const approveSelectedCandidatesFromVacancyResponse = useCallback(
    (args: Parameters<typeof approveSelectedCandidatesFromVacancyResponseThunk>[0]) => {
      return dispatch(approveSelectedCandidatesFromVacancyResponseThunk(args));
    },
    [dispatch],
  );

  const deleteCandidateFromVacancyResponse = useCallback(
    (args: Parameters<typeof deleteCandidateFromVacancyResponseThunk>[0]) => {
      return dispatch(deleteCandidateFromVacancyResponseThunk(args));
    },
    [dispatch],
  );

  // TODO: Normalize implementation <<<BEGIN
  // https://innowise-group.atlassian.net/jira/software/projects/ITSRT/boards/1455?selectedIssue=ITSRT-504&text=504
  // US-6.2:
  // АС-7
  const setTabToOpen = useCallback(
    (tab: Parameters<typeof setTabToOpenAction>[0]) => dispatch(setTabToOpenAction(tab)),
    [dispatch],
  );

  const clearLinkedCandidatesByRequest = useCallback(
    (vacancyRequestId: number) => {
      dispatch(clearLinkedCandidatesByRequestAction(vacancyRequestId));
    },
    [dispatch],
  );

  const fetchAllRequests = useCallback(
    (data: Parameters<typeof getAllRequestsThunk>[0]) => {
      return ThunkEffects.takeLatest(dispatch(getAllRequestsThunk(data)), getAllRequestsThunk.typePrefix);
    },
    [dispatch],
  );

  const exportRequests = useCallback(
    (body: Parameters<typeof exportRequestsThunk>[0]) => {
      dispatch(exportRequestsThunk(body));
    },
    [dispatch],
  );

  const createRequest = useCallback(
    (data: RequestItem, successCallback?: (requestId: number) => void) => {
      dispatch(createRequestThunk({ data, successCallback }));
    },
    [dispatch],
  );

  const updateRequest = useCallback(
    (data: RequestItem, id: number, successCallback?: (requestId: number) => void) => {
      return dispatch(updateRequestThunk({ data, id, successCallback }));
    },
    [dispatch],
  );

  const deleteRequest = useCallback(
    (id: number, successCallback?: () => void) => {
      return dispatch(deleteRequestThunk({ id, successCallback }));
    },
    [dispatch],
  );

  const fetchRequestsByVacancyId = useCallback(
    (id: number, page: number) => {
      dispatch(getVacancyRequestsThunk({ id, page }));
    },
    [dispatch],
  );

  const fetchRequestById = useCallback(
    (id: number) => {
      return dispatch(getRequestByIdThunk({ id }));
    },
    [dispatch],
  );

  const clearRequests = useCallback(() => {
    dispatch(clearRequestsAction());
  }, [dispatch]);

  const clearCurrentRequests = useCallback(() => {
    dispatch(clearCurrentRequestsAction());
  }, [dispatch]);

  return {
    totalElements,
    isLoading,
    isLoggerLoading,
    requestsIds,
    totalPages,
    requests,
    tabToOpen,
    loggerIds,
    loggerItems,
    loggerTotalPages,
    loggerTotalElements,
    fetchAllRequests,
    isExportLoading,
    exportRequests,
    setTabToOpen,
    createRequest,
    fetchLoggerData,
    clearLoggerData,
    clearRequests,
    fetchRequestById,
    updateRequest,
    clearCurrentRequests,
    deleteRequest,
    fetchRequestsByVacancyId,
    fetchSelectedCandidates,
    clearLinkedCandidatesByRequest,
    approveSelectedCandidatesFromVacancyResponse,
    deleteCandidateFromVacancyResponse,
  };
};
