export enum LifecycleStatuses {
  Deleted = 'DELETED',
  Actual = 'ACTUAL',
}

export enum CandidateLifecycleStatuses {
  Deleted = 'DELETED',
  Actual = 'ACTUAL',
  Merged = 'MERGED',
}

export enum StatusLifecycleStatuses {
  Deleted = 'DELETED',
  Actual = 'ACTUAL',
  Base = 'BASE',
}
