import { FC, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './nested-select.styles';
import { type NestedSelectProps } from '../../../../types';

const NestedSelectFilter: FC<NestedSelectProps> = ({ state, onValueChange, labelKey }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const [currentParent, setCurrentParent] = useState<string>('');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);

  const { selected, rest } = Object.entries(state).reduce(
    (acc, [, { title, value, active }]) => {
      let key;
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && active) {
        key = 'selected';
      }
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && !active) {
        key = 'rest';
      }
      if (!key) return acc;
      return {
        ...acc,
        total: acc.total + 1,
        [key]: acc.total + 1,
        [key]: {
          ...acc[key],
          count: active ? acc.selected.count + 1 : acc.rest.count + 1,
          ids: [...acc[key].ids, value],
        },
      };
    },
    {
      selected: {
        count: 0,
        ids: [],
      },
      rest: { count: 0, ids: [] },
      total: 0,
    },
  );

  const onToggleParentIcon = (id: string) => () => {
    return setCurrentParent((prev) => (prev !== id ? id : ''));
  };

  const onParentClick = (parentId: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onValueChange({
      ...state,
      [parentId]: {
        ...state[parentId],
        active: !state[parentId].active,
        ...(state[parentId]?.active && {
          nested: state[parentId].nested
            ? Object.entries(state[parentId].nested).reduce((acc, [key, val]) => {
                return {
                  ...acc,
                  [key]: {
                    ...val,
                    active: false,
                  },
                };
              }, {})
            : state[parentId].nested,
        }),
      },
    });
  };

  const onNestedVariantClick =
    (args: { parentId: string; id: string }) => (e: Pick<Event, 'stopPropagation' | 'preventDefault'>) => {
      e.preventDefault();
      e.stopPropagation();
      const { parentId, id } = args;
      const selectedNestedElements = Object.entries(state[parentId].nested).reduce((acc, [, { active, value }]) => {
        if (active) return [...acc, value];
        return acc;
      }, []);
      const isEmptyNestedSelectedElements = !selectedNestedElements?.length;
      const isOnlyOneSelectedElementWhichEqualClicked =
        selectedNestedElements?.length === 1 && selectedNestedElements[0] === id;
      const shouldActivateParent =
        isEmptyNestedSelectedElements ||
        (!isOnlyOneSelectedElementWhichEqualClicked && !!selectedNestedElements.length);

      if (isOnlyOneSelectedElementWhichEqualClicked) setCurrentParent('');

      onValueChange({
        ...state,
        [parentId]: {
          ...state[parentId],
          ...{ active: shouldActivateParent },
          nested: {
            ...state[parentId].nested,
            [id]: {
              ...state[parentId].nested[id],
              active: !state[parentId].nested[id].active,
            },
          },
        },
      });
    };

  const renderNestedItems = (checked: boolean) => (parentId: string) =>
    (
      <Styled.SelectItemContainer key={parentId}>
        <Styled.SelectItemWrapper>
          <Styled.SelectItemPressContainer onClick={onParentClick(parentId)}>
            <Styled.Checkbox readOnly defaultChecked={checked} id={parentId} />
            <Styled.SelectItemLabel>{state[parentId].title}</Styled.SelectItemLabel>
          </Styled.SelectItemPressContainer>
          {state[parentId].nested && <Styled.ExpandIcon type="u_angle-down" onClick={onToggleParentIcon(parentId)} />}
        </Styled.SelectItemWrapper>
        {currentParent === parentId && state[parentId].nested && (
          <Styled.NestedContainer>
            {Object.entries(state[parentId].nested).map(([id, el]) => (
              <Styled.NestedItemContainer onClick={onNestedVariantClick({ parentId, id })} key={id}>
                <Styled.Checkbox readOnly checked={!!el.active} id={parentId} />
                <Styled.SelectItemLabel>{state[parentId].nested[id].title}</Styled.SelectItemLabel>
              </Styled.NestedItemContainer>
            ))}
          </Styled.NestedContainer>
        )}
      </Styled.SelectItemContainer>
    );

  return (
    <Styled.Wrapper>
      <Styled.Input
        placeholder={t('buttons.search')}
        label={t(labelKey)}
        value={filter}
        onChange={onFilterInputChange}
      />
      <Styled.ScrollableWrapper ref={wrapperRef}>
        {selected.ids.map(renderNestedItems(true))}
        {rest.ids.map(renderNestedItems(false))}
      </Styled.ScrollableWrapper>
    </Styled.Wrapper>
  );
};

export default memo(NestedSelectFilter);
