import { AdministrationState } from './administration.types';

export const preloadedState: AdministrationState = {
  employees: {
    isLoading: false,
    employees: null,
    employeesIds: [],
    totalElements: 0,
    totalPages: 0,

    searchedRequestsOptions: [],
    searchedVacanciesOptions: [],
    optionsLoading: false,

    employeesEmailsList: [],
    employeesEmailsListLoading: false,

    userToAdd: null,
    userLoading: false,

    vacanciesOptions: {
      ids: [],
      options: null,
    },
    requestsOptions: {
      ids: [],
      options: null,
    },
    pagable: {
      currentPage: 1,
      pageSizes: [
        { title: '10', value: '10' },
        { title: '20', value: '20' },
        { title: '50', value: '50' },
        { title: '70', value: '70' },
        { title: '100', value: '100' },
      ],
      pageSize: 20,
    },
  },
  locations: {
    citiesDataByLanguage: {},
    isLoading: false,
  },
  statuses: {
    isLoading: false,
    currentStatus: null,
  },
  referral: {
    manager: null,
    managerOptions: [],
    isManagerOptionsLoading: false,
    isLoading: false,
    items: [],
    isManagerLoading: false,
    isExportLoading: false,
  },
  units: {
    isLoading: false,
    ids: [],
    items: {},
  },
  sourcing: {
    isLoading: false,
    ids: [],
    items: {},
    totalPages: 0,
    totalElements: 0,
  },
};
