import { EmployeeOption, EmployeesItemState, useAppDispatch, useAppSelector } from '@innowise-group/core';
import { useCallback } from 'react';
import {
  clearEmployeesAction,
  clearMentionedEmployeesAction,
  setMentionedEmployeesItemsAction,
} from './employees.actions';
import {
  addEmployeesForCandidateDiffMergeThunk,
  getEmployeeByIdThunk,
  getMentionedEmployeesThunk,
  searchEmployeesListThunk,
} from './employees.thunk';
import {
  getEmployeesForCandidateDiffMergeOptions,
  getEmployeesItems,
  getEmployeesOptions,
  getMentionedEmployeesItems,
  getMentionedEmployeesOptions,
  getMentionedOptionsItems,
  getResponsibleEmployeesOptions,
} from './employees.selectors';
import { SelectOption } from '@innowise-group/core';

export const useEmployeesState = (): EmployeesItemState => useAppSelector(getEmployeesItems);
export const useMentionedEmployeesState = (): EmployeesItemState => useAppSelector(getMentionedEmployeesItems);
export const useEmployeesOptions = (): EmployeeOption[] => useAppSelector(getEmployeesOptions);
export const useMentionedEmployees = () => useAppSelector(getMentionedOptionsItems);
export const useResponsibleEmployeesOptions = (): SelectOption[] => useAppSelector(getResponsibleEmployeesOptions);
export const useEmployeesForCandidateDiffMergeOptions = (): SelectOption[] =>
  useAppSelector(getEmployeesForCandidateDiffMergeOptions);
export const useMentionedEmployeesOptions = (): EmployeeOption[] => useAppSelector(getMentionedEmployeesOptions);

export const useEmployeesAPI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, items: employees, ids: employeesIds } = useEmployeesState();
  const {
    isLoading: mentionedIsLoading,
    items: mentionedEmployees,
    ids: mentionedEmployeesIds,
  } = useMentionedEmployeesState();

  const fetchEmployeeById = useCallback(
    (employeeId: number | string) => {
      dispatch(getEmployeeByIdThunk({ employeeId }));
    },
    [dispatch],
  );

  const searchEmployees = useCallback(
    (partOfName: string) => {
      return dispatch(searchEmployeesListThunk({ partOfName })).unwrap();
    },
    [dispatch],
  );

  const clearEmployees = useCallback(() => {
    dispatch(clearEmployeesAction());
  }, [dispatch]);

  const fetchMentionedEmployees = useCallback(
    (candidateId: number) => {
      return dispatch(getMentionedEmployeesThunk({ candidateId }));
    },
    [dispatch],
  );

  const addMention = useCallback(
    (mention: EmployeeOption) => {
      dispatch(setMentionedEmployeesItemsAction(mention));
    },
    [dispatch],
  );

  const clearMentionedEmployees = useCallback(() => {
    dispatch(clearMentionedEmployeesAction());
  }, [dispatch]);

  const addEmployeeByIdToEmployeesList = useCallback(
    (employeeId: string | number) => {
      dispatch(addEmployeesForCandidateDiffMergeThunk({ employeeId }));
    },
    [dispatch],
  );

  return {
    searchEmployees,
    fetchEmployeeById,
    clearEmployees,
    fetchMentionedEmployees,
    clearMentionedEmployees,
    addMention,
    addEmployeeByIdToEmployeesList,
    isLoading,
    mentionedIsLoading,
    employees,
    mentionedEmployees,
    employeesIds,
    mentionedEmployeesIds,
  };
};
