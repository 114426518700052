import { Icon, Button as BaseButton } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';
import { MobileBottomMenu as BaseMobileBottomMenu } from '../../../mobile-bottom-menu';
import { Slide } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
`;

export const ToggleIcon = styled(Icon)`
  align-self: center;
`;

export const MobileBottomMenu = styled(BaseMobileBottomMenu)`
  display: flex;
  flex-direction: column;
`;

export const FilterButton = styled(BaseButton)`
  &.MuiButton-endIcon {
    margin: 0;
  }
  &.MuiButton-root.MuiButton-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.palette.general.darkGrey[40]};
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    ${({ theme }) => theme.fonts.primary[80]}
    & > .MuiButton-endIcon.MuiButton-iconSizeMedium {
      margin: 0;
    }
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
  &.MuiButton-root.MuiButton-text svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const CustomSlide = styled(Slide)`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 0;
`;

export const FiltersListContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;
