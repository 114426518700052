import { useCallback, useMemo } from 'react';
import {
  resetEmployeesAdministrationsStateAction,
  resetEmployeeFromHrmByEmailAction,
  selectAllUserAuthDataPermissionsAction,
  setNewEmployeeRoleFromHrmAction,
  setPagableAction,
  unSelectAllUserAuthDataPermissionsAction,
  updateExistingUserRoleAction,
  updateUserSelectedAuthDataPermissionsAction,
  clearCurrentStatusAction,
  clearReferralStateAction,
  clearUnitsStateAction,
  clearSourcingStateAction,
} from './administration.actions';
import {
  AdministrationStatusItem,
  MergeLocationsItem,
  ReferralState,
  useAppDispatch,
  useAppSelector,
  ThunkEffects,
  useProfileAPI,
  AdministrationUnitsState,
  UnitItem,
  AdministrationSourcingState,
  SourcingItem,
  SelectOption,
} from '@innowise-group/core';
import {
  getEmployeeFromHrmByEmailThunk,
  searchEmployeesThunk,
  searchRequestOptionsThunk,
  searchVacancyOptionsThunk,
  updateEmployeeAuthDataThunk,
  addEmployeeThunk,
  searchEmailByEmailPartThunk,
  rolePermissionAssignThunk,
  deleteEmployeeThunk,
  fetchCitiesByLanguagesThunk,
  mergeLocationsThunk,
  createStatusThunk,
  updateStatusThunk,
  getStatusItemByIdThunk,
  deleteStatusItemByIdThunk,
  restoreStatusItemByIdThunk,
  getReferralDataThunk,
  getReferralsManagerThunk,
  updateReferralsManagerThunk,
  resolveReferralStatusThunk,
  getReferralManagerOptionsThunk,
  exportReferralDataThunk,
  getAllUnitsThunk,
  createUnitThunk,
  updateUnitThunk,
  getAllSourcingDataThunk,
  createSourcingEmployeeThunk,
  updateSourcingEmployeeThunk,
  getActualUnitsThunk,
} from './administration.thunk';
import {
  getAdministrationStateSelector,
  getCitiesByLangAdministrationState,
  getEmployeesAdministrationStateSelector,
  getEmployeesEmailsOptions,
  getLocationsAdministrationState,
  getReferralsAdministrationState,
  getSourcingAdministrationState,
  getSourcingDefaultFormItemById,
  getStatusesAdministrationState,
  getUnitsAdministrationOptions,
  getUnitsAdministrationState,
} from './administration.selectors';
import { useTranslation } from 'react-i18next';
import { CandidateStatusGroupEnum, RolesValues } from '@constants';

export const useAdministrationStateSelector = () => useAppSelector(getAdministrationStateSelector);
export const useEmployeesAdministrationStateSelector = () => useAppSelector(getEmployeesAdministrationStateSelector);
export const useLocationsAdministrationState = () => useAppSelector(getLocationsAdministrationState);
export const useCitiesByLangAdministrationState = () => useAppSelector(getCitiesByLangAdministrationState);
export const useStatusesAdministrationState = () => useAppSelector(getStatusesAdministrationState);
export const useEmployeesEmailsOptions = () => useAppSelector(getEmployeesEmailsOptions);

export const useAdministrationStatusesAPI = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLoading, currentStatus } = useStatusesAdministrationState();

  const statusesPhaseOptions = useMemo(() => {
    return Object.keys(CandidateStatusGroupEnum).map((item) => {
      return { title: t(`options.status_group_${item}`), value: item };
    });
  }, [t]);

  const createStatus = useCallback(
    (data: AdministrationStatusItem) => {
      return dispatch(createStatusThunk({ data }));
    },
    [dispatch],
  );

  const updateStatus = useCallback(
    (id: string, data: AdministrationStatusItem) => {
      return dispatch(updateStatusThunk({ id, data }));
    },
    [dispatch],
  );

  const getStatusById = useCallback(
    (id: string) => {
      return dispatch(getStatusItemByIdThunk({ id }));
    },
    [dispatch],
  );

  const deleteStatusById = useCallback(
    (id: string, priority: number) => {
      return dispatch(deleteStatusItemByIdThunk({ id, priority }));
    },
    [dispatch],
  );

  const restoreStatusById = useCallback(
    (id: string, priority: number) => {
      return dispatch(restoreStatusItemByIdThunk({ id, priority }));
    },
    [dispatch],
  );

  const clearCurrentStatus = useCallback(() => {
    dispatch(clearCurrentStatusAction());
  }, [dispatch]);

  return {
    statusesPhaseOptions,
    clearCurrentStatus,
    getStatusById,
    createStatus,
    updateStatus,
    isLoading,
    currentStatus,
    deleteStatusById,
    restoreStatusById,
  };
};

export const useLocationsAdministrationAPI = () => {
  const dispatch = useAppDispatch();
  const state = useLocationsAdministrationState();

  const fetchCitiesByLanguages = useCallback(() => {
    return dispatch(fetchCitiesByLanguagesThunk());
  }, [dispatch]);

  const mergeLocations = useCallback(
    (data: MergeLocationsItem) => {
      return dispatch(mergeLocationsThunk({ data }));
    },
    [dispatch],
  );

  return { ...state, fetchCitiesByLanguages, mergeLocations };
};

export const useEmployeesAdministrationAPI = () => {
  const dispatch = useAppDispatch();
  const state = useEmployeesAdministrationStateSelector();

  const requestsOptions = useMemo(
    () => state.requestsOptions.ids.map((id) => state.requestsOptions.options[id]),
    [state.requestsOptions],
  );

  const vacanciesOptions = useMemo(
    () => state.vacanciesOptions.ids.map((id) => state.vacanciesOptions.options[id]),
    [state.vacanciesOptions],
  );

  const setPagable = useCallback(
    (...pagable: Parameters<typeof setPagableAction>) => dispatch(setPagableAction(...pagable)),
    [dispatch],
  );

  const searchEmployees = useCallback(
    (...args: Parameters<typeof searchEmployeesThunk>) => dispatch(searchEmployeesThunk(...args)),
    [dispatch],
  );

  const updateEmployeeAuthData = useCallback(
    (...args: Parameters<typeof updateEmployeeAuthDataThunk>) => {
      return dispatch(updateEmployeeAuthDataThunk(...args));
    },
    [dispatch],
  );

  const updateUserSelectedAuthDataPermissions = useCallback(
    (...arg: Parameters<typeof updateUserSelectedAuthDataPermissionsAction>) =>
      dispatch(updateUserSelectedAuthDataPermissionsAction(...arg)),
    [dispatch],
  );

  const selectAllUserAuthDataPermissions = useCallback(
    (...arg: Parameters<typeof selectAllUserAuthDataPermissionsAction>) =>
      dispatch(selectAllUserAuthDataPermissionsAction(...arg)),
    [dispatch],
  );

  const unSelectAllUserAuthDataPermissions = useCallback(
    (...arg: Parameters<typeof unSelectAllUserAuthDataPermissionsAction>) =>
      dispatch(unSelectAllUserAuthDataPermissionsAction(...arg)),
    [dispatch],
  );

  const setNewEmployeeRoleFromHrm = useCallback(
    (...args: Parameters<typeof setNewEmployeeRoleFromHrmAction>) => dispatch(setNewEmployeeRoleFromHrmAction(...args)),
    [dispatch],
  );

  const updateExistingUserRole = useCallback(
    (...args: Parameters<typeof updateExistingUserRoleAction>) => dispatch(updateExistingUserRoleAction(...args)),
    [dispatch],
  );

  const searchVacancyOptions = useCallback(
    (args: Parameters<typeof searchVacancyOptionsThunk>[0]) => dispatch(searchVacancyOptionsThunk(args)),
    [dispatch],
  );

  const searchRequestOptions = useCallback(
    (args: Parameters<typeof searchRequestOptionsThunk>[0]) => dispatch(searchRequestOptionsThunk(args)),
    [dispatch],
  );

  const getEmployeeFromHrmByEmail = useCallback(
    (args: Parameters<typeof getEmployeeFromHrmByEmailThunk>[0]) => dispatch(getEmployeeFromHrmByEmailThunk(args)),
    [dispatch],
  );

  const addEmployee = useCallback(
    (args: Parameters<typeof addEmployeeThunk>[0]) => dispatch(addEmployeeThunk(args)),
    [dispatch],
  );

  const searchEmailByEmailPart = useCallback(
    (args: Parameters<typeof searchEmailByEmailPartThunk>[0]) => {
      return ThunkEffects.takeLatest(
        dispatch(searchEmailByEmailPartThunk(args)),
        searchEmailByEmailPartThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const rolePermissionAssign = useCallback(
    (args: Parameters<typeof rolePermissionAssignThunk>[0]) => dispatch(rolePermissionAssignThunk(args)),
    [dispatch],
  );

  const deleteEmployee = useCallback(
    (args: Parameters<typeof deleteEmployeeThunk>[0]) => dispatch(deleteEmployeeThunk(args)),
    [dispatch],
  );

  const resetEmployeeFromHrmByEmail = useCallback(() => dispatch(resetEmployeeFromHrmByEmailAction()), [dispatch]);

  const resetEmployeesAdministrationsState = useCallback(
    () => dispatch(resetEmployeesAdministrationsStateAction()),
    [dispatch],
  );

  return {
    ...state,
    requestsOptions,
    vacanciesOptions,
    setPagable,
    deleteEmployee,
    addEmployee,
    searchEmployees,
    rolePermissionAssign,
    searchVacancyOptions,
    searchRequestOptions,
    updateExistingUserRole,
    searchEmailByEmailPart,
    updateEmployeeAuthData,
    getEmployeeFromHrmByEmail,
    setNewEmployeeRoleFromHrm,
    resetEmployeesAdministrationsState,
    resetEmployeeFromHrmByEmail,
    selectAllUserAuthDataPermissions,
    unSelectAllUserAuthDataPermissions,
    updateUserSelectedAuthDataPermissions,
  };
};

export const useReferralsAdministrationState = (): ReferralState => useAppSelector(getReferralsAdministrationState);

export const useReferralAPI = () => {
  const dispatch = useAppDispatch();
  const state = useReferralsAdministrationState();
  const { profile } = useProfileAPI();
  const { manager } = state;

  const allowedToAgree = useMemo(() => {
    if (!profile) return false;
    return manager.employeeId === profile.id;
  }, [manager, profile]);

  const getReferralsData = useCallback(
    (args: Parameters<typeof getReferralDataThunk>[0]) => {
      return ThunkEffects.takeLatest(dispatch(getReferralDataThunk(args)), getReferralDataThunk.typePrefix);
    },
    [dispatch],
  );

  const exportReferralsData = useCallback(
    (args: Parameters<typeof exportReferralDataThunk>[0]) => {
      return dispatch(exportReferralDataThunk(args));
    },
    [dispatch],
  );

  const updateReferralsManager = useCallback(
    (args: Parameters<typeof updateReferralsManagerThunk>[0]) => {
      return dispatch(updateReferralsManagerThunk(args));
    },
    [dispatch],
  );

  const resolveReferralStatus = useCallback(
    (data: { yearMonth: string; id: string; accepted: boolean }) => {
      return dispatch(resolveReferralStatusThunk(data));
    },
    [dispatch],
  );

  const getReferralsManager = useCallback(() => {
    dispatch(getReferralsManagerThunk());
  }, [dispatch]);

  const getReferralsManagerOptions = useCallback(
    (roles: RolesValues[]) => {
      dispatch(getReferralManagerOptionsThunk({ roles }));
    },
    [dispatch],
  );

  const clearReferralsData = useCallback(() => {
    dispatch(clearReferralStateAction());
  }, [dispatch]);

  return {
    ...state,
    allowedToAgree,
    exportReferralsData,
    getReferralsData,
    getReferralsManagerOptions,
    updateReferralsManager,
    getReferralsManager,
    resolveReferralStatus,
    clearReferralsData,
  };
};

export const useUnitsAdministrationState = (): AdministrationUnitsState => useAppSelector(getUnitsAdministrationState);
export const useUnitsAdministrationOptions = (): SelectOption[] => useAppSelector(getUnitsAdministrationOptions);

export const useUnitsAPI = () => {
  const dispatch = useAppDispatch();
  const state = useUnitsAdministrationState();
  const unitsOptions = useUnitsAdministrationOptions();

  const createUnit = useCallback(
    (data: UnitItem) => {
      return dispatch(createUnitThunk(data));
    },
    [dispatch],
  );

  const updateUnit = useCallback(
    (data: UnitItem) => {
      return dispatch(updateUnitThunk(data));
    },
    [dispatch],
  );

  const getAllUnits = useCallback(() => {
    return dispatch(getAllUnitsThunk());
  }, [dispatch]);

  const getActualUnits = useCallback(() => {
    return dispatch(getActualUnitsThunk());
  }, [dispatch]);

  const clearUnitsState = useCallback(() => {
    dispatch(clearUnitsStateAction());
  }, [dispatch]);

  return { ...state, unitsOptions, createUnit, updateUnit, getAllUnits, getActualUnits, clearUnitsState };
};

const useSourcingAdministrationState = (): AdministrationSourcingState =>
  useAppSelector(getSourcingAdministrationState);
export const useSourcingDefaultFormItemById = (id: string): SourcingItem | null =>
  useAppSelector(getSourcingDefaultFormItemById(id));

export const useSourcingAPI = () => {
  const dispatch = useAppDispatch();
  const state = useSourcingAdministrationState();

  const createSourcingEmployee = useCallback(
    (args: Parameters<typeof createSourcingEmployeeThunk>[0]) => {
      return dispatch(createSourcingEmployeeThunk(args));
    },
    [dispatch],
  );

  const updateSourcingEmployee = useCallback(
    (args: Parameters<typeof updateSourcingEmployeeThunk>[0]) => {
      return dispatch(updateSourcingEmployeeThunk(args));
    },
    [dispatch],
  );

  const deleteSourcingEmployee = useCallback(
    (employeeId: string) => {
      return dispatch(createSourcingEmployeeThunk({ requiredNumberOfEvents: '0', employeeId }));
    },
    [dispatch],
  );

  const getAllSourcingData = useCallback(
    (args: Parameters<typeof getAllSourcingDataThunk>[0]) => {
      return dispatch(getAllSourcingDataThunk(args));
    },
    [dispatch],
  );

  const clearSourcingState = useCallback(() => {
    return dispatch(clearSourcingStateAction());
  }, [dispatch]);

  return {
    ...state,
    createSourcingEmployee,
    updateSourcingEmployee,
    deleteSourcingEmployee,
    clearSourcingState,
    getAllSourcingData,
  };
};
