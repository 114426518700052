import React from 'react';
import * as Styled from './desktop-view.styles';
import { EmployeeField } from '../../employee-field';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LabeledCheckbox } from '@shared-mui-components';
import { ClaimTypes, DateFormats, MaxInterestedStatusPerson } from '@constants';
import { Button, Icon } from '@innowise-group/mui-kit';

interface DesktopViewProps {
  handleRemoveItem: (idx: number, onChange: (arr: object[]) => void) => () => void;
  handleChangeIsActive: (
    idx: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  ) => (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
  getIsActive: (idx: number) => boolean;
  handleAddItem: <T>(fieldName: string, fieldsArray: T[], emptyItem: T) => () => void;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  handleAddItem,
  getIsActive,
  handleChangeIsActive,
  handleRemoveItem,
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const agreements = watch('agreements');

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    return new Date().getTime() < selectedDate.getTime();
  };

  return agreements.map((item, idx) => {
    return (
      <Styled.SectionRowContainer key={idx}>
        <EmployeeField index={idx} claimType="agreements" />
        <Controller
          name={`agreements.${idx}.isActive`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabeledCheckbox
              checked={value}
              onChange={handleChangeIsActive(idx, onChange)}
              label={t('pages.candidates.statuses.requireAgreement')}
            />
          )}
        />
        <Controller
          name={`agreements.${idx}.deadline`}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            return (
              <DatePicker
                disabled={!getIsActive(idx)}
                selected={value ? new Date(value) : null}
                value={value}
                onChange={onChange}
                isConstrained={false}
                withTime
                dateFormat={DateFormats.DayAndMonthWithHoursAndMinutes}
                filterTime={filterPassedTime}
                startDate={new Date()}
                minDate={new Date()}
                minYear={new Date().getFullYear()}
                label={t('pages.candidates.candidateCreation.date')}
                placeholder={t('pages.candidates.candidateCreation.date')}
                error={!!errors?.agreements?.[idx]?.deadline?.message}
                helperText={errors?.agreements?.[idx]?.deadline?.message}
              />
            );
          }}
        />
        <Controller
          name="agreements"
          control={control}
          render={({ field: { onChange } }) => {
            return agreements.length > 1 ? (
              <Styled.DeleteIcon type="u_plus" isPointer onClick={handleRemoveItem(idx, onChange)} />
            ) : (
              <div />
            );
          }}
        />
        {agreements.length < MaxInterestedStatusPerson && idx === agreements.length - 1 ? (
          <Button
            variant="outlined"
            onClick={handleAddItem('agreements', agreements, {
              isActive: false,
              claimType: ClaimTypes.AGREEMENT,
              employeeId: '',
              deadline: null,
            })}
            startIcon={<Icon type="u_plus" size={14} />}
          >
            {t('buttons.more')}
          </Button>
        ) : (
          <div />
        )}
      </Styled.SectionRowContainer>
    );
  });
};

export default DesktopView;
