import { memo, useEffect, useRef, useState } from 'react';
import * as Styled from './nested-select.styles';
import { FilterButton } from '../shared';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { type NestedSelectProps } from '../../../../types';

const NestedSelect: React.FC<NestedSelectProps> = ({
  state,
  search,
  pinned,
  onClickFilterOutside,
  onRemoveFilter,
  onToggleFilter,
  onValueChange,
  labelKey,
  isOpen,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentParent, setCurrentParent] = useState('');

  const initialState = Object.entries(state).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: {
        ...val,
        active: false,
        nested: state[key].nested
          ? Object.keys(state[key].nested).reduce(
              (acc, key) => ({
                ...acc,
                [key]: {
                  ...val.nested[key],
                  active: false,
                },
              }),
              {},
            )
          : state[key].nested,
      },
    }),
    {},
  );

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);

  const { selected, rest, total } = Object.entries(state).reduce(
    (acc, [, { title, value, active }]) => {
      let key;
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && active) {
        key = 'selected';
      }
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && !active) {
        key = 'rest';
      }
      if (!key) return acc;
      return {
        ...acc,
        total: acc.total + 1,
        [key]: acc.total + 1,
        [key]: {
          ...acc[key],
          count: active ? acc.selected.count + 1 : acc.rest.count + 1,
          ids: [...acc[key].ids, value],
        },
      };
    },
    {
      selected: {
        count: 0,
        ids: [],
      },
      rest: { count: 0, ids: [] },
      total: 0,
    },
  );

  const isEmpty = !selected.count && !filter;

  const onParentClick = (id: string) => () => {
    return setCurrentParent((prev) => (prev !== id ? id : ''));
  };

  const onParentSelect = (parentId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onValueChange({
      ...state,
      [parentId]: {
        ...state[parentId],
        active: !state[parentId].active,
        ...(state[parentId]?.active && {
          nested: state[parentId].nested
            ? Object.entries(state[parentId].nested).reduce((acc, [key, val]) => {
                return {
                  ...acc,
                  [key]: {
                    ...val,
                    active: false,
                  },
                };
              }, {})
            : state[parentId].nested,
        }),
      },
    });
  };

  const onNestedVariantClick =
    (args: { parentId: string; id: string }) => (e: Pick<Event, 'stopPropagation' | 'preventDefault'>) => {
      e.preventDefault();
      e.stopPropagation();
      const { parentId, id } = args;
      const selectedNestedElements = Object.entries(state[parentId].nested).reduce((acc, [, { active, value }]) => {
        if (active) return [...acc, value];
        return acc;
      }, []);
      const isEmptyNestedSelectedElements = !selectedNestedElements?.length;
      const isOnlyOneSelectedElementWhichEqualClicked =
        selectedNestedElements?.length === 1 && selectedNestedElements[0] === id;
      const shouldActivateParent =
        isEmptyNestedSelectedElements ||
        (!isOnlyOneSelectedElementWhichEqualClicked && !!selectedNestedElements.length);

      if (isOnlyOneSelectedElementWhichEqualClicked) setCurrentParent('');

      onValueChange({
        ...state,
        [parentId]: {
          ...state[parentId],
          ...{ active: shouldActivateParent },
          nested: {
            ...state[parentId].nested,
            [id]: {
              ...state[parentId].nested[id],
              active: !state[parentId].nested[id].active,
            },
          },
        },
      });
    };

  const onReset = () => {
    setCurrentParent('');
    setFilter('');
    return onValueChange(initialState);
  };

  const onRemoveFilterHandler = () => onRemoveFilter(initialState);

  const onClickFilterOutsideHandle = () => {
    if (isEmpty && !pinned) onRemoveFilterHandler();
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Wrapper ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={!pinned ? onRemoveFilterHandler : null}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>
            {`${t(labelKey)}${selected.ids.length ? `: ${state[selected.ids[0]].title}` : ''}`}
          </Styled.FirstSelectedValue>
          {selected.ids.length > 1 && <Styled.SelectedCount>+{selected.ids.length - 1}</Styled.SelectedCount>}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          {search ? (
            <Styled.InputContainer>
              <Styled.CustomInput placeholder={t('pages.search')} onChange={onFilterInputChange} value={filter} />
            </Styled.InputContainer>
          ) : (
            <Styled.EmptyInputContainer />
          )}
          <Styled.VariantsList>
            <Styled.PreselectedVariantsGroupContainer>
              {selected.ids.map((parentId) => (
                <Styled.VariantContainer key={parentId}>
                  <Styled.LabelContainer onClick={onParentClick(parentId)}>
                    <Styled.LabeledCheckbox
                      onChange={onParentSelect(parentId)}
                      checked={!!state[parentId].active}
                      id={parentId}
                      label={state[parentId].title}
                    />
                    {state[parentId].nested && (
                      <Styled.CustomExpandIcon
                        expanded={currentParent === parentId}
                        size={17}
                        viewBox="5 5 13 13"
                        type="u_angle-down"
                      />
                    )}
                  </Styled.LabelContainer>
                  {state[parentId].nested && (
                    <React.Fragment>
                      {currentParent === parentId && (
                        <React.Fragment>
                          <Styled.NestedDivider />
                          <Styled.NestedContainer>
                            {Object.entries(state[parentId].nested).map(([id, el]) => (
                              <Styled.NestedItem onClick={onNestedVariantClick({ parentId, id })} key={id}>
                                <Styled.LabeledCheckbox
                                  onChange={onNestedVariantClick({ parentId, id })}
                                  checked={!!el.active}
                                  id={parentId}
                                  label={el.title}
                                />
                              </Styled.NestedItem>
                            ))}
                          </Styled.NestedContainer>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </Styled.VariantContainer>
              ))}
            </Styled.PreselectedVariantsGroupContainer>
            <Styled.CustomDivider />
            <Styled.UnselectedVariantsGroupContainer>
              {rest.ids.map((parentId, idx) => (
                <Styled.VariantContainer even={(rest.ids.length + idx) % 2 === 0} key={parentId}>
                  <Styled.LabelContainer onClick={onParentClick(parentId)}>
                    <Styled.LabeledCheckbox
                      onChange={onParentSelect(parentId)}
                      checked={state[parentId].active}
                      id={parentId}
                      label={state[parentId].title}
                    />
                    {state[parentId].nested && (
                      <Styled.CustomExpandIcon
                        expanded={currentParent === parentId}
                        size={17}
                        viewBox="5 5 13 13"
                        type="u_angle-down"
                      />
                    )}
                  </Styled.LabelContainer>
                  {state[parentId].nested && (
                    <React.Fragment>
                      {currentParent === parentId && (
                        <React.Fragment>
                          <Styled.NestedDivider />
                          <Styled.NestedContainer>
                            {Object.entries(state[parentId].nested).map(([id, el]) => (
                              <Styled.NestedItem onClick={onNestedVariantClick({ parentId, id })} key={id}>
                                <Styled.LabeledCheckbox
                                  onChange={onNestedVariantClick({ parentId, id })}
                                  checked={el.active}
                                  id={parentId}
                                  label={el.title}
                                />
                              </Styled.NestedItem>
                            ))}
                          </Styled.NestedContainer>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </Styled.VariantContainer>
              ))}
            </Styled.UnselectedVariantsGroupContainer>
          </Styled.VariantsList>
          <Styled.BottomControls>
            <Styled.CustomButton disabled={isEmpty} onClick={onReset} variant="text">
              {t('filters.resetFilter')}
            </Styled.CustomButton>
            {!filter && (
              <Styled.AmountLabel>
                {t('buttons.amountFrom', { from: selected.count, amount: total })}
              </Styled.AmountLabel>
            )}
          </Styled.BottomControls>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default memo(NestedSelect);
