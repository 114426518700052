import { AppIconsType } from '../icon';
import * as Styled from './modal.styles';

export interface ModalAction {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  highlighted?: boolean;
  icon?: AppIconsType;
  type?: 'button' | 'submit' | 'reset';
}

export interface ModalActionsProps {
  actions?: ModalAction[];
  noVerticalPadding: boolean;
  children?: React.ReactNode;
  position?: 'left' | 'center' | 'right' | 'full';
}

const ModalActions: React.FC<ModalActionsProps> = ({ actions, children, noVerticalPadding, position }) => (
  <Styled.ModalActionsContainer noVerticalPadding={noVerticalPadding} position={position}>
    {actions
      ? actions.map(({ onClick, title, icon, highlighted, type, disabled }) => (
          <Styled.Button
            key={title}
            type={type}
            disabled={disabled}
            onClick={onClick}
            $highlighted={highlighted}
            variant={highlighted ? 'contained' : 'outlined'}
          >
            {icon}
            {title}
          </Styled.Button>
        ))
      : children}
  </Styled.ModalActionsContainer>
);

export default ModalActions;
