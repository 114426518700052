import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseFilterType, FiltersEntity } from '@innowise-group/core';
import * as Styled from './filters-manager.styles';
import { FilterButton } from '../shared';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from '@shared-mui-components';
import { InputAdornment } from '@mui/material';
import { Icon } from '@innowise-group/mui-kit';

interface FiltersManagerProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  search?: boolean;
  singleSelect?: boolean;
  state: FiltersEntity[keyof FiltersEntity];
  onValueChange: (id: string) => void;
  onToggleFilter: () => void;
  onRemoveFilter: () => void;
  onClickFilterOutside: () => void;
}

const FiltersManager: React.FC<FiltersManagerProps> = ({
  onClickFilterOutside,
  onRemoveFilter,
  onToggleFilter,
  onValueChange,
  isOpen,
  state,
  labelKey,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const onInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) =>
    setSearch(e.currentTarget.value);

  const onValueChangeHandler = (id: string) => (e: Pick<Event, 'stopPropagation'>) => {
    e.stopPropagation();
    setAnchorEl(null);
    return onValueChange(id);
  };

  const { from, amount } = useMemo(
    () =>
      Object.entries(state).reduce(
        (acc, [, val]) => ({
          ...acc,
          ...(!val.pinned &&
            val.active && {
              from: acc.from + 1,
            }),
          ...(!val.pinned && { amount: acc.amount + 1 }),
        }),
        { from: 0, amount: 0 },
      ),
    [state],
  );

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  const onClickFilterOutsideHandler = () => {
    setAnchorEl(null);
    return onClickFilterOutside();
  };

  return (
    <Styled.Wrapper>
      <FilterButton
        ref={ref}
        isActive={isOpen}
        action="add"
        iconSize={16}
        iconViewBox="1 0 14 14"
        onClick={onToggleFilter}
      >
        {t(labelKey)}
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        transitionDuration={{ exit: 0 }}
        onClose={onClickFilterOutsideHandler}
      >
        <Styled.FilterWrapper>
          <Styled.Input
            onChange={onInputChange}
            value={search}
            placeholder={t('buttons.search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon type="u_search" />
                </InputAdornment>
              ),
            }}
          />
          <Styled.VariantsList>
            <Styled.Preselected>
              {Object.entries(state).reduce((acc, [key, value]) => {
                if (value.pinned) {
                  return acc;
                }

                if (t(value.labelKey).toLowerCase()?.includes(search.toLowerCase())) {
                  return [
                    ...acc,
                    <Styled.VariantContainer key={key}>
                      <LabeledCheckbox
                        onChange={onValueChangeHandler(key)}
                        checked={value.active}
                        id={key}
                        label={t(value.labelKey)}
                      />
                    </Styled.VariantContainer>,
                  ];
                }
                return acc;
              }, [])}
            </Styled.Preselected>
          </Styled.VariantsList>
          <Styled.BottomControls>
            <Styled.AmountLabel>{t('buttons.amountFrom', { from, amount })}</Styled.AmountLabel>
            <Styled.CustomButton disabled={!from} onClick={onRemoveFilter} variant="text">
              {t('filters.resetFilter')}
            </Styled.CustomButton>
          </Styled.BottomControls>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default FiltersManager;
