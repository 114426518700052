import { Modal, ModalProps, modalHoc, useModal, Icon } from '@innowise-group/mui-kit';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './feedback-modal.styles';
import {
  FileIdResponse,
  ShortStatusItemResponse,
  StatusItemResponse,
  useCandidateEventsAPI,
  useCandidateFilesAPI,
} from '@innowise-group/core';
import { DateFormats, FeedbackFileNames, FilesAcceptFormats, FilesFormats, MaxFileSize } from '@constants';
import { FileAccessModal } from '../file-access-modal';
import { FilePreviewModal } from '../file-preview-modal';
import { Text, Document as PDFDocument, pdf, Font, StyleSheet, Page, View } from '@react-pdf/renderer';
import { calculateDateWithTimeZone } from '@innowise-group/utilities';
import { format } from 'date-fns';
import { Input } from '@innowise-group/mui-kit';
import { notificationsManager, Loader } from '@innowise-group/mui-kit';

Font.register({
  family: 'Roboto',
  fontWeight: 'normal',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});
Font.register({
  family: 'Roboto-Bold',
  fontWeight: 'bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 16,
    fontFamily: 'Roboto',
    padding: 20,
  },
  view: {
    margin: '15px 35px',
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
  },
});

interface FeedbackModalProps {
  status: ShortStatusItemResponse | StatusItemResponse;
  successCallback: (file: FileIdResponse) => () => void;
}

const FeedbackModal: React.FC<ModalProps & FeedbackModalProps> = ({ close, status, successCallback }) => {
  const { t } = useTranslation();
  const { events } = useCandidateEventsAPI();
  const { createFileId } = useCandidateFilesAPI();
  const [file, setFile] = useState<FileIdResponse>(null);
  const [text, setText] = useState<string>('');
  const [isLoading] = useState<boolean>(false);

  const [showFileAccessModal, closeFileAccessModal] = useModal(FileAccessModal);
  const [showFilePreview] = useModal(FilePreviewModal);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const isFileLoading = useMemo(() => events[status.id].comment.isLoading, [events, status]);

  const handleClickFile = () => {
    fileInputRef.current.click();
  };

  const openFileAccessModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const fileType = event.target.files[0].type || event.target.files[0].name.split('.').reverse()[0];
      const isSizeValidate = event.target.files[0].size <= MaxFileSize;
      const isFormatValidate = FilesFormats.includes(fileType);
      if (event.target.files[0] && isSizeValidate && isFormatValidate) {
        showFileAccessModal({ successCallback: handleAddStrictAccessFile(event), closeCallback: handleAddFile(event) });
      } else {
        notificationsManager.error({
          subtitle: t(`notifications.text.${!isSizeValidate ? 'invalidFileSize' : 'invalidFileFormat'}`),
        });
        fileInputRef.current.value = null;
      }
    }
  };

  const handleAddStrictAccessFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const file = await createFileId(event.target.files[0], true, closeModal, true);
      setFile(file);
    } catch {
      setFile(null);
      closeModal();
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const file = await createFileId(event.target.files[0], false, closeModal, true);
      setFile(file);
    } catch {
      setFile(null);
      closeModal();
    }
  };

  const closeModal = () => {
    closeFileAccessModal();
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const handleOpenFile = () => {
    showFilePreview({
      fileName: file.filename,
      id: file.id,
    });
  };

  const handleDeleteFile = async (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();
    if (file) {
      setFile(null);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  const generateFile = async () => {
    // TODO:  move to backend side with dynamic statuses
    const blob = await pdf(<GeneratedDocument text={text} statusId={status.status?.id} />).toBlob();
    const generatedFile = new File(
      [blob],
      `Feedback_${FeedbackFileNames[status.status?.id]}_${format(
        calculateDateWithTimeZone(new Date()),
        DateFormats.Short,
      )}.pdf`,
      {
        type: 'application/pdf',
      },
    );
    const file = await createFileId(generatedFile, false, closeModal, true);
    setFile(file);
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>
          <span>{t('pages.candidates.statuses.feedbackOnStatus', { status: status.status?.localizedName })}</span>
        </Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.BodyContainer>
          {isLoading && <Loader />}
          {file && !isLoading ? (
            <React.Fragment>
              <Styled.FileItem onClick={handleOpenFile}>
                {file.secured && (
                  <Styled.SecureFileIconContainer>
                    <Icon type="u_lock_alt" />
                  </Styled.SecureFileIconContainer>
                )}
                <span>{file.filename}</span>
                <Styled.FileButtonContainer>
                  <Icon type="u_plus" onClick={handleDeleteFile} />
                </Styled.FileButtonContainer>
              </Styled.FileItem>
              <Styled.SubmitButton disabled={isFileLoading} onClick={successCallback(file)}>
                {t('buttons.sendFeedback')}
              </Styled.SubmitButton>
            </React.Fragment>
          ) : (
            !isLoading && (
              <React.Fragment>
                <span>
                  {t('pages.candidates.statuses.feedbackFilePlaceholder')}{' '}
                  <Styled.InputLabel onClick={handleClickFile}>
                    {t('pages.candidates.statuses.downloadTheFile')}
                  </Styled.InputLabel>
                </span>
                <Styled.FileInput
                  ref={fileInputRef}
                  type="file"
                  onChange={openFileAccessModal}
                  accept={FilesAcceptFormats.join(', ')}
                />
                <Input
                  rows={14}
                  multiline
                  onChange={handleChange}
                  placeholder={t('pages.candidates.statuses.feedbackPlaceholder')}
                  error={text.length > 5000}
                  helperText={text.length > 5000 ? t('errors.characterLimit') : ''}
                />
                <Styled.SubmitButton onClick={generateFile} disabled={!text}>
                  {t('buttons.finished')}
                </Styled.SubmitButton>
              </React.Fragment>
            )
          )}
        </Styled.BodyContainer>
      </Modal.Body>
    </Modal.Container>
  );
};

export default modalHoc(FeedbackModal, 'feedback-modal');

interface GeneratedDocumentProps {
  text: string;
  statusId: string;
}

const GeneratedDocument: React.FC<GeneratedDocumentProps> = ({ text, statusId }) => {
  return (
    <PDFDocument>
      <Page size="A4" wrap style={styles.page}>
        <View style={styles.view}>
          <Text style={styles.title}>Feedback on the status of the {FeedbackFileNames[statusId]}</Text>
          <Text wrap>{text}</Text>
        </View>
      </Page>
    </PDFDocument>
  );
};
