import { NotificationTabs, NotificationsGroups } from './notifications.types';

export const getUnreadNotificationsCountMapper = (data: { [key in NotificationsGroups]: number }): {
  [key in NotificationTabs]: number;
} => {
  const calendarCount = data[NotificationsGroups.Calendar] || 0;
  const mentionCount = data[NotificationsGroups.Mention] || 0;
  const requiresResponseCount = data[NotificationsGroups.RequiresResponse] || 0;
  const responsibleCount = data[NotificationsGroups.Responsible] || 0;
  const vacancyClaimsCount = data[NotificationsGroups.VacancyClaim] || 0;
  const allCount = calendarCount + mentionCount + requiresResponseCount + responsibleCount + vacancyClaimsCount;
  return {
    [NotificationTabs.Calendar]: calendarCount,
    [NotificationTabs.Mention]: mentionCount,
    [NotificationTabs.RequiresResponse]: requiresResponseCount,
    [NotificationTabs.Responsible]: responsibleCount,
    [NotificationTabs.VacancyClaim]: vacancyClaimsCount,
    [NotificationTabs.All]: allCount,
  };
};
