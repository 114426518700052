import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './personal-section.styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FileIdResponse,
  useCandidateFilesAPI,
  useCandidateStatusAPI,
  useCurrentCandidate,
  useCurrentStatusInfo,
  useDebounce,
  useEmployeesAdministrationAPI,
  useEmployeesEmailsOptions,
  useOptionsApi,
  useProfileAPI,
  useResizeObserver,
} from '@innowise-group/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { calculateDateWithTimeZone, calculateWordDeclension, calculateYearsOld } from '@innowise-group/utilities';
import {
  Input,
  Menu,
  MenuItem,
  Select,
  notificationsManager,
  AvatarItem,
  Icon,
  useTheme,
} from '@innowise-group/mui-kit';
import {
  CandidateMergeQueryParams,
  CandidateSourceNeedClarificationIds,
  CandidateSourceNeedReferralIds,
  CandidateStatusColors,
  DateFormats,
  ImageFormats,
  MaxImageSizeInBytes,
  MaxPhotoSizeInMb,
  RolesValues,
} from '@constants';
import { SelectOption } from '@innowise-group/core';
import { DatePicker } from '@shared-mui-components';
import { format } from 'date-fns';

interface PersonalSectionProps {
  isEdit?: boolean;
  isDiff?: boolean;
  disabled?: boolean;
}

const PersonalSection: React.FC<PersonalSectionProps> = ({ isEdit, isDiff, disabled }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.lg);
  const isDateView = useResizeObserver(theme.breakpoints.values.md);
  const { id } = useParams();
  const { profile } = useProfileAPI();
  const { control, watch, setValue, trigger } = useFormContext();
  const candidate = useCurrentCandidate();
  const { createAvatarPhoto, clearCandidateAvatar, avatar } = useCandidateFilesAPI();
  const { countryOptions, gradeOptions, candidateSourceOptions, genderOptions } = useOptionsApi();
  const { items: statusItems } = useCandidateStatusAPI();
  const { statusAuthorsOptions: rolesEmployeesOptions, employeesLoading } = useOptionsApi();
  const employeesEmailsList = useEmployeesEmailsOptions();
  const { searchEmailByEmailPart, employeesEmailsListLoading } = useEmployeesAdministrationAPI();
  const [searchParams] = useSearchParams();

  const isMerge = searchParams.get(CandidateMergeQueryParams.Strategy);

  const currentStatusId = isEdit || isMerge ? useCurrentStatusInfo()?.form?.statusId : null;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [photo, setPhoto] = useState<FileIdResponse>();
  const [email, setEmail] = useState<string>('');
  const [debouncedEmailValue] = useDebounce<string>(email, 500);
  const avatarInputRef = useRef<null | HTMLInputElement>();
  const birth = watch('birthDate');
  const sourceId = watch('sourceId');
  const preview = watch('photoId');
  const recommenderEmail = watch('candidateReferral.recommenderEmail');

  const years = calculateYearsOld(birth);
  const statusName = statusItems[currentStatusId]?.localizedName || '-';

  const employeesOptions =
    !candidate ||
    rolesEmployeesOptions.find((item) => item.value === candidate.responsibleEmployee.employeeId.toString())
      ? rolesEmployeesOptions
      : [
          ...rolesEmployeesOptions,
          {
            title: candidate.responsibleEmployee.localizedFullName,
            value: candidate.responsibleEmployee.employeeId.toString(),
            miniPhotoUrl: candidate.responsibleEmployee.photoId?.toString() || null,
          },
        ];

  const handleRecommenderInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleNamesChange =
    (onChange: (newValue: string) => void) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target.value);
      trigger(['firstNameRu', 'lastNameRu', 'firstNameEn', 'lastNameEn']);
    };

  const renderEmployeeListItem = (props, value: SelectOption, { selected }) => {
    return (
      <MenuItem disabled={disabled} key={value.value} {...props} selected={selected}>
        <AvatarItem photoId={value.miniPhotoUrl} name={value.title} isOption />
      </MenuItem>
    );
  };

  const handleEditAvatar = () => {
    if (avatarInputRef.current) {
      avatarInputRef.current.click();
      if (anchorEl) {
        setAnchorEl(null);
      }
    }
  };

  const handleDeleteAvatar = () => {
    setValue('photoId', null);
    setPhoto(null);
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const formatError = !ImageFormats.includes(event.target.files[0].type);
      const sizeError = !(event.target.files[0]?.size < MaxImageSizeInBytes);
      if (formatError || sizeError) {
        notificationsManager.error({
          subtitle: t('errors.invalidPhotoSize', { count: MaxPhotoSizeInMb }),
        });
      } else {
        createAvatarPhoto(event.target.files[0]);
      }
    } else {
      clearCandidateAvatar();
    }
  };

  useEffect(() => {
    if (!CandidateSourceNeedClarificationIds.includes(sourceId)) {
      setValue('sourceDetails', '');
    }
    if (!CandidateSourceNeedReferralIds.includes(sourceId)) {
      setValue('candidateReferral', {
        reward: '',
        recommenderEmail: '',
        referralComment: '',
        startGrade: '',
      });
    }
  }, [sourceId, setValue]);

  useEffect(() => {
    if (avatar?.id) {
      setPhoto(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    if (photo) {
      setValue('photoId', photo.id);
    }
  }, [photo]);

  useEffect(() => {
    searchEmailByEmailPart({ email: debouncedEmailValue, limit: 10, includeRtEmployees: true });
  }, [debouncedEmailValue]);

  useEffect(() => {
    if (recommenderEmail) searchEmailByEmailPart({ email: recommenderEmail, limit: 10, includeRtEmployees: true });
  }, [recommenderEmail]);

  const disabledReferralsFields = (() => {
    if (!isEdit || !candidate || !candidate.candidateReferral) return false;
    if (candidate.candidateReferral?.editable === false) {
      return true;
    }
    if (candidate.candidateReferral?.editable === null) {
      return false;
    }
    return profile.role.name !== RolesValues.Admin;
  })();

  return (
    <Styled.Wrapper>
      <Styled.AvatarBlock>
        <Styled.Input type="file" ref={avatarInputRef} accept={ImageFormats.join(',')} onChange={handleChangeAvatar} />
        {preview ? (
          <React.Fragment>
            <Styled.Avatar onClick={handlePopoverOpen}>
              <Styled.Image id={preview} />
            </Styled.Avatar>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleEditAvatar}>{t('pages.candidates.candidateDetails.edit')}</MenuItem>
              <MenuItem onClick={handleDeleteAvatar}>{t('pages.candidates.candidateDetails.delete')}</MenuItem>
            </Menu>
          </React.Fragment>
        ) : (
          <Styled.NoImageContainer onClick={handleEditAvatar}>
            <Icon type="u_camera" size={50} viewBox="0 0 49 44" />
          </Styled.NoImageContainer>
        )}
        {isEdit && isDesktopView && (
          <Styled.UpdatedDate>{`${t('pages.candidates.candidateCreation.updated')} ${format(
            calculateDateWithTimeZone(candidate.lastModifiedDate),
            DateFormats.OnlyTime,
          )} ${format(
            calculateDateWithTimeZone(candidate.lastModifiedDate),
            DateFormats.DayFirst,
          )}`}</Styled.UpdatedDate>
        )}
      </Styled.AvatarBlock>
      <Styled.FieldsWrapper>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="firstNameRu" />}
          <Controller
            name="firstNameRu"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => {
              return (
                <Input
                  disabled={disabled}
                  label={t('pages.candidates.candidateCreation.firstname')}
                  error={!!errors['firstNameRu']?.message}
                  helperText={errors['firstNameRu']?.message.toString()}
                  value={value}
                  onChange={handleNamesChange(onChange)}
                  inputRef={ref}
                  placeholder={t('pages.candidates.candidateCreation.enterTheFirstname')}
                />
              );
            }}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="lastNameRu" />}
          <Controller
            name="lastNameRu"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={t('pages.candidates.candidateCreation.lastname')}
                error={!!errors['lastNameRu']?.message}
                helperText={errors['lastNameRu']?.message.toString()}
                value={value}
                onChange={handleNamesChange(onChange)}
                inputRef={ref}
                placeholder={t('pages.candidates.candidateCreation.enterTheLastname')}
              />
            )}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomCitizenshipDiff />}
          <Controller
            name="citizenshipId"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Select
                disabled={disabled}
                value={value}
                onChange={onChange}
                multiple
                limitTags={1}
                disableCloseOnSelect
                disableSortByAlphabet={false}
                options={countryOptions}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.nationality')}
                      placeholder={t('pages.candidates.candidateCreation.selectTheCountry')}
                      error={!!errors['citizenshipId']?.message}
                      helperText={errors['citizenshipId']?.message.toString()}
                    />
                  );
                }}
              />
            )}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.StatusCell>
          <Styled.StatusContainer statusColors={CandidateStatusColors[statusItems[currentStatusId]?.group]}>
            {statusName}
          </Styled.StatusContainer>
        </Styled.StatusCell>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="firstNameEn" />}
          <Controller
            name="firstNameEn"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={`${t('pages.candidates.candidateCreation.firstname')} (ENG)`}
                error={!!errors['firstNameEn']?.message}
                helperText={errors['firstNameEn']?.message.toString()}
                value={value}
                inputRef={ref}
                onChange={handleNamesChange(onChange)}
                placeholder={t('pages.candidates.candidateCreation.enterTheFirstname')}
              />
            )}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="lastNameEn" />}
          <Controller
            name="lastNameEn"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={`${t('pages.candidates.candidateCreation.lastname')} (ENG)`}
                error={!!errors['lastNameEn']?.message}
                helperText={errors['lastNameEn']?.message.toString()}
                value={value}
                onChange={handleNamesChange(onChange)}
                inputRef={ref}
                placeholder={t('pages.candidates.candidateCreation.enterTheLastname')}
              />
            )}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.DatePickerContainer>
          {isDiff && <Styled.CustomBirthdayDiff />}
          <Controller
            name="birthDate"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => {
              const handleReset = (event: React.MouseEvent<HTMLInputElement>) => {
                event.stopPropagation();
                onChange(null);
              };
              return (
                <DatePicker
                  disabled={disabled}
                  label={t('pages.candidates.candidateCreation.dateOfBirth')}
                  error={!!errors['birthDate']?.message}
                  selected={value}
                  onChange={onChange}
                  placeholder={t('pages.candidates.candidateCreation.date')}
                  helperText={errors['birthDate']?.message.toString()}
                  InputProps={{
                    inputRef: ref,
                    endAdornment: (
                      <Styled.IconsContainer>
                        {!!years && years > -1 && isDateView && (
                          <Styled.YearsContainer>
                            {`(${years} ${t(
                              `pages.candidates.candidateCreation.${calculateWordDeclension('year', years)}`,
                            )})`}
                          </Styled.YearsContainer>
                        )}
                        {value && (
                          <Styled.ClearIcon type="u_multiply" size={18} isPointer={!disabled} onClick={handleReset} />
                        )}
                        <Styled.CalendarIcon type={'u_calendar'} size={20} isRelative={!!value} />
                      </Styled.IconsContainer>
                    ),
                  }}
                />
              );
            }}
          />
        </Styled.DatePickerContainer>
        <Styled.BoxForDiffConsolidation>
          {isDiff && <Styled.CustomGenderDiff />}
          <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Select
                value={value}
                disabled={disabled}
                onChange={onChange}
                disableClearable={!value}
                options={genderOptions || []}
                disableSortByAlphabet={false}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      inputRef={ref}
                      error={!!errors?.gender?.message}
                      helperText={errors?.gender?.message.toString()}
                      label={t('pages.candidates.candidateCreation.gender')}
                      placeholder={t('pages.candidates.candidateCreation.selectTheGender')}
                    />
                  );
                }}
              />
            )}
          />
        </Styled.BoxForDiffConsolidation>
        <Styled.SourceBoxForDiffConsolidation>
          {isDiff && (
            <Styled.CustomSourceWithDetailsDiff
              $withClarification={CandidateSourceNeedClarificationIds.includes(sourceId)}
              $withReferralProgram={CandidateSourceNeedReferralIds === sourceId}
            />
          )}
          {isDiff && <Styled.CustomResponsibleDiff />}
          <Controller
            name="responsibleEmployee"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => {
              const wrappedValue =
                typeof value === 'number' && isFinite(value) && !isNaN(value) ? String(value) : value;

              return (
                <Select
                  value={wrappedValue}
                  disabled={disabled}
                  onChange={onChange}
                  disableClearable={!value}
                  options={employeesOptions || []}
                  renderOption={renderEmployeeListItem}
                  loading={employeesLoading}
                  renderInput={(params) => {
                    return (
                      <Styled.SectionInput
                        {...params}
                        error={!!errors?.responsibleEmployee?.message}
                        helperText={errors?.responsibleEmployee?.message.toString()}
                        required
                        label={t('pages.candidates.candidateCreation.candidateManager')}
                        placeholder={t('pages.candidates.candidateCreation.selectTheEmployee')}
                        inputRef={ref}
                      />
                    );
                  }}
                />
              );
            }}
          />
          <Controller
            name="sourceId"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Select
                value={value}
                onChange={onChange}
                disabled={disabled || disabledReferralsFields}
                placeholder={t('pages.candidates.candidateCreation.selectTheSource')}
                options={candidateSourceOptions}
                disableSortByAlphabet={false}
                disableClearable
                renderInput={(params) => {
                  return (
                    <Styled.SectionInput
                      {...params}
                      error={!!errors['sourceId']?.message}
                      required
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.informationSource')}
                      placeholder={t('pages.candidates.candidateCreation.selectTheSource')}
                      helperText={errors['sourceId']?.message.toString()}
                    />
                  );
                }}
              />
            )}
          />
          {CandidateSourceNeedClarificationIds.includes(sourceId) && (
            <Controller
              name="sourceDetails"
              control={control}
              render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                <Styled.SectionInput
                  disabled={disabled}
                  label={t('pages.candidates.candidateCreation.clarification')}
                  error={!!errors['sourceDetails']?.message}
                  helperText={errors['sourceDetails']?.message.toString()}
                  value={value}
                  onChange={onChange}
                  required
                  inputRef={ref}
                  placeholder={t('pages.candidates.candidateCreation.enterClarification')}
                />
              )}
            />
          )}
          {CandidateSourceNeedReferralIds === sourceId && (
            <Controller
              name="candidateReferral.recommenderEmail"
              control={control}
              render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                <Select
                  disabled={disabled || disabledReferralsFields}
                  value={value}
                  onChange={onChange}
                  disableSortByAlphabet={false}
                  disableClearable={!value}
                  options={employeesEmailsList || []}
                  loading={employeesEmailsListLoading}
                  renderInput={(params) => {
                    return (
                      <Styled.SectionInput
                        {...params}
                        disabled={disabled || disabledReferralsFields}
                        label={t('pages.candidates.candidateCreation.recommender')}
                        error={!!errors.candidateReferral?.['recommenderEmail']?.message}
                        helperText={errors.candidateReferral?.['recommenderEmail']?.message.toString()}
                        value={value}
                        onChange={handleRecommenderInputChange}
                        required
                        inputRef={ref}
                        placeholder={t('pages.candidates.candidateCreation.enterTheEmail')}
                      />
                    );
                  }}
                />
              )}
            />
          )}
          {isEdit && <Styled.SectionInput disabled label="ID" value={id} />}
          {CandidateSourceNeedReferralIds === sourceId && (
            <React.Fragment>
              <Controller
                name="candidateReferral.startGrade"
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Select
                    disabled={disabled || disabledReferralsFields}
                    value={value}
                    onChange={onChange}
                    options={gradeOptions}
                    disableClearable
                    renderInput={(params) => {
                      const Input = isEdit ? Styled.CommentInput : Styled.SectionInput;
                      return (
                        <Input
                          {...params}
                          disabled={disabled || disabledReferralsFields}
                          label={t('pages.candidates.candidateCreation.initialGrade')}
                          error={!!errors.candidateReferral?.['startGrade']?.message}
                          helperText={errors.candidateReferral?.['startGrade']?.message.toString()}
                          value={value}
                          onChange={onChange}
                          required
                          inputRef={ref}
                          placeholder={t('pages.candidates.candidateCreation.selectGrade')}
                        />
                      );
                    }}
                  />
                )}
              />
              <Controller
                name="candidateReferral.reward"
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Input
                    disabled={disabled || disabledReferralsFields}
                    label={`${t('pages.candidates.candidateCreation.amount')}, $`}
                    error={!!errors.candidateReferral?.['reward']?.message}
                    helperText={errors.candidateReferral?.['reward']?.message.toString()}
                    value={value}
                    onChange={onChange}
                    required
                    inputRef={ref}
                    placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                  />
                )}
              />
              <Controller
                name="candidateReferral.referralComment"
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Styled.CommentInput
                    disabled={disabled || disabledReferralsFields}
                    label={t('pages.candidates.candidateCreation.commentRP')}
                    error={!!errors.candidateReferral?.['referralComment']?.message}
                    helperText={errors.candidateReferral?.['referralComment']?.message.toString()}
                    value={value}
                    onChange={onChange}
                    multiline
                    rows={2}
                    inputRef={ref}
                    placeholder={t('pages.candidates.candidateCreation.commentRPPlaceholder')}
                  />
                )}
              />
            </React.Fragment>
          )}
        </Styled.SourceBoxForDiffConsolidation>
        <Styled.AboutMeContainer>
          {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="aboutMe" />}
          <Controller
            name="aboutMe"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={`${t('pages.candidates.candidateCreation.aboutMe')}`}
                error={!!errors['aboutMe']?.message}
                helperText={errors['aboutMe']?.message.toString()}
                value={value}
                multiline
                minRows={2}
                maxRows={12}
                inputRef={ref}
                onChange={onChange}
                placeholder={t('pages.candidates.candidateCreation.enterTheAboutMe')}
              />
            )}
          />
        </Styled.AboutMeContainer>
      </Styled.FieldsWrapper>
      {isEdit && !isDesktopView && (
        <Styled.UpdatedDate>{`${t('pages.candidates.candidateCreation.updated')} ${format(
          calculateDateWithTimeZone(candidate.lastModifiedDate),
          DateFormats.OnlyTime,
        )} ${format(calculateDateWithTimeZone(candidate.lastModifiedDate), DateFormats.DayFirst)}`}</Styled.UpdatedDate>
      )}
    </Styled.Wrapper>
  );
};

export default React.memo(PersonalSection);
