import { FC, forwardRef } from 'react';
import { TextFieldProps } from '@mui/material';
import * as Styled from './xray-input.styles';

type XrayButtonProps = {
  disabledDelete: boolean;
  onButtonClick: () => void;
};

type XrayInputProps = TextFieldProps & XrayButtonProps;

const XrayInput: FC<XrayInputProps> = forwardRef<HTMLInputElement, XrayInputProps>(
  ({ onButtonClick, disabledDelete, error, helperText, ...props }, ref) => (
    <Styled.Wrapper>
      <Styled.FieldContainer error={error}>
        <Styled.Input ref={ref} {...props} />
        {!disabledDelete && (
          <Styled.CloseButton tabIndex={-1} disabled={disabledDelete} variant="text" onClick={onButtonClick}>
            <Styled.Icon type="u_plus" />
          </Styled.CloseButton>
        )}
      </Styled.FieldContainer>
      <Styled.HelperText>{helperText}</Styled.HelperText>
    </Styled.Wrapper>
  ),
);

export default XrayInput;
