import React from 'react';
import * as Styled from './experience-section.styles';
import { useTranslation } from 'react-i18next';
import { CandidateExperience, useControlFormField, useValidationSchemaCandidate } from '@innowise-group/core';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@innowise-group/mui-kit';
import { MaxJobsCount } from '@constants';
import { DatePicker } from '@shared-mui-components';

interface ExperienceSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const ExperienceSection: React.FC<ExperienceSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { validationSchema } = useValidationSchemaCandidate();
  const { control, watch } = useFormContext();
  const { handleAddItem, handleRemoveItem, handleChangeInputItem, handleChangeDateItem, handleChangeBooleanItem } =
    useControlFormField(validationSchema);
  const jobs = watch('candidateExperiences');
  const stillWorking = (index: number) => {
    return watch(`candidateExperiences.${index}.stillWorking`);
  };

  return (
    <React.Fragment>
      <Styled.DiffContainer>
        {isDiff && <Styled.CustomExperienceDiff />}
        {!!jobs?.length &&
          jobs.map((item: CandidateExperience, index: number) => (
            <React.Fragment key={index}>
              <Styled.WorkPlaceCell>
                <Controller
                  name={`candidateExperiences.${index}.company`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={t('pages.candidates.candidateCreation.workplace')}
                      error={
                        !!(
                          errors.candidateExperiences?.[index]?.company?.message ||
                          errors.candidateExperiences?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateExperiences?.[index]?.company?.message ||
                        errors.candidateExperiences?.[index]?.message
                      }
                      inputRef={ref}
                      value={value}
                      onChange={handleChangeInputItem(onChange, 'candidateExperiences')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.WorkPlaceCell>
              <Styled.PositionCell>
                <Styled.DeleteGroupContainer>
                  {jobs.length > 1 && (
                    <Styled.DeleteButton
                      startIcon={<Styled.DeleteIcon type="u_plus" />}
                      disabled={disabled}
                      onClick={handleRemoveItem<CandidateExperience>(index, 'candidateExperiences', jobs)}
                      variant="text"
                    >
                      {t('pages.candidates.candidateCreation.deleteWorkplace')}
                    </Styled.DeleteButton>
                  )}
                </Styled.DeleteGroupContainer>
                <Controller
                  name={`candidateExperiences.${index}.position`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={t('pages.candidates.candidateCreation.position')}
                      error={
                        !!(
                          errors.candidateExperiences?.[index]?.position?.message ||
                          errors.candidateExperiences?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateExperiences?.[index]?.position?.message ||
                        errors.candidateExperiences?.[index]?.message
                      }
                      inputRef={ref}
                      value={value}
                      onChange={handleChangeInputItem(onChange, 'candidateExperiences')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.PositionCell>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateExperiences.${index}.workedFrom`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled}
                      selected={value}
                      onChange={handleChangeDateItem(onChange, 'candidateExperiences')}
                      withoutDays
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.period')}
                      placeholder={t('pages.candidates.candidateCreation.from')}
                      error={
                        !!(
                          errors.candidateExperiences?.[index]?.workedFrom?.message ||
                          errors.candidateExperiences?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateExperiences?.[index]?.workedFrom?.message ||
                        errors.candidateExperiences?.[index]?.message
                      }
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateExperiences.${index}.workedTo`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled || !!stillWorking(index)}
                      selected={value}
                      onChange={handleChangeDateItem(onChange, 'candidateExperiences')}
                      withoutDays
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.to')}
                      placeholder={t('pages.candidates.candidateCreation.to')}
                      error={
                        !!(
                          errors.candidateExperiences?.[index]?.workedTo?.message ||
                          errors.candidateExperiences?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateExperiences?.[index]?.workedTo?.message ||
                        errors.candidateExperiences?.[index]?.message
                      }
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Controller
                name={`candidateExperiences.${index}.stillWorking`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Styled.CustomCheckbox
                    disabled={disabled}
                    onChange={handleChangeBooleanItem(onChange, 'candidateExperiences')}
                    checked={value}
                    label={t('pages.candidates.candidateCreation.workingNow')}
                  />
                )}
              />
              <Controller
                name={`candidateExperiences.${index}.itSphere`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Styled.CustomCheckbox
                    disabled={disabled}
                    onChange={handleChangeBooleanItem(onChange, 'candidateExperiences')}
                    checked={value}
                    label={t('pages.candidates.candidateCreation.itSphere')}
                  />
                )}
              />
              <Styled.CommentCell>
                <Controller
                  name={`candidateExperiences.${index}.comment`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      inputRef={ref}
                      value={value}
                      label={t('pages.candidates.candidateCreation.comment')}
                      onChange={handleChangeInputItem(onChange, 'candidateExperiences')}
                      error={!!errors.candidateExperiences?.[index]?.comment?.message}
                      placeholder={t('pages.candidates.candidateCreation.addAComment')}
                      rows={4}
                      helperText={errors.candidateExperiences?.[index]?.comment?.message}
                      multiline
                    />
                  )}
                />
              </Styled.CommentCell>
            </React.Fragment>
          ))}
      </Styled.DiffContainer>
      {jobs?.length < MaxJobsCount && (
        <Styled.AddButton
          disabled={disabled}
          onClick={handleAddItem('candidateExperiences', jobs, {
            company: '',
            position: '',
            workedFrom: null,
            workedTo: null,
            stillWorking: false,
            comment: '',
            itSphere: false,
          })}
          variant="text"
          startIcon={<Styled.PlusIcon type="u_plus" />}
        >
          {t('pages.candidates.candidateCreation.addAWorkplace')}
        </Styled.AddButton>
      )}
    </React.Fragment>
  );
};

export default React.memo(ExperienceSection);
