import { TFunction } from 'i18next';
import { format } from 'date-fns';
import { DateFormats } from '@constants';
import { calculateDateWithTimeZone } from './calculate-date-with-timezone.utility';

const MILLISECONDS = 1000;
const MINUTES_IN_ONE_HOUR = 60;
const SECONDS_IN_ONE_MINUTE = 60;

export const agoTimeValueCounter = (date: Date | string, t: TFunction) => {
  const prevDate = new Date(date);
  const currentDate = new Date();
  const localTimeZone = currentDate.getTimezoneOffset();
  const diff = (currentDate.valueOf() - prevDate.valueOf()) / MILLISECONDS / SECONDS_IN_ONE_MINUTE + localTimeZone;
  const count = Math.round(diff);
  if (count === 0) return t('buttons.rightNow');
  if (count < MINUTES_IN_ONE_HOUR) return t('buttons.minutesAgo', { count });
  return `${format(calculateDateWithTimeZone(prevDate), DateFormats.OnlyTime)} ${format(
    calculateDateWithTimeZone(prevDate),
    DateFormats.DayFirst,
  )}`;
};
