export const EMOJIS = [
  { name: 'u_auth_users', size: 55, viewBox: '0 0 56 44' },
  { name: 'u_auth_file', size: 45, viewBox: '0 0 40 50' },
  { name: 'u_auth_request', size: 57, viewBox: '0 0 56 61' },
  { name: 'u_auth_square', size: 50, viewBox: '0 0 50 50' },
];

export const MOBILE_EMOJIS = [
  { name: 'u_auth_users', size: 30, viewBox: '0 0 56 44' },
  { name: 'u_auth_file', size: 28, viewBox: '0 0 40 50' },
  { name: 'u_auth_request', size: 30, viewBox: '0 0 56 61' },
  { name: 'u_auth_square', size: 30, viewBox: '0 0 50 50' },
];
