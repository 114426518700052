interface ObjectWithId {
  id: string | number;
}

export const transformArrayToObject = <T extends ObjectWithId>(array: T[]) => {
  return array.reduce((obj, value) => {
    obj[value.id] = value;
    return obj;
  }, {});
};

export const getArrayIds = <T extends ObjectWithId>(array: T[]) => {
  return array.map((item) => parseInt(item.id.toString()));
};
// Replace getArrayIds to this getStringArrayIds
export const getStringArrayIds = <T extends ObjectWithId>(array: T[]) => {
  return array.map((item) => item.id.toString());
};

export const getArrayStringIds = <T extends ObjectWithId>(array: T[]) => {
  return array.map((item) => item.id.toString());
};
