import { useCurrentCandidateVacancyOptions, useRequestsAPI, useSearchVacancyRequest } from '@innowise-group/core';
import { Input, Select } from '@innowise-group/mui-kit';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RequestSection: React.FC = () => {
  const { t } = useTranslation();
  const vacanciesOptions = useCurrentCandidateVacancyOptions();

  const vacanciesOptionsIds = useMemo(() => {
    return vacanciesOptions.reduce((accum, curr) => {
      if (Number(curr.value)) return [...accum, curr.value];
      return accum;
    }, []);
  }, [vacanciesOptions]);

  const { clearRequests } = useRequestsAPI();
  const { handleRequestChange, handleSearchRequests, requestOptions } = useSearchVacancyRequest(
    'vacancyId',
    'vacancyName',
    'vacancyRequestId',
    vacanciesOptionsIds,
  );

  const { control, resetField, watch } = useFormContext();
  const statusId = watch('statusId');

  useEffect(() => {
    resetField(`vacancyRequestId`);
    return () => {
      clearRequests();
    };
  }, [resetField, statusId, clearRequests]);

  return (
    <Controller
      name="vacancyRequestId"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <Select
            value={value}
            onChange={handleRequestChange(onChange)}
            disableClearable={!value}
            options={requestOptions || []}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  error={!!errors?.vacancyRequestId?.message}
                  helperText={errors?.vacancyRequestId?.message.toString()}
                  onChange={handleSearchRequests}
                  label={t('pages.candidates.allCandidatesTable.request')}
                  placeholder={t('pages.candidates.allCandidatesTable.selectTheRequest')}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default RequestSection;
