import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Client } from '@stomp/stompjs';
import {
  AuthService,
  EditCandidateActions,
  EnvConfigurationService,
  LocalizedShortEmployeeItemResponse,
  ShortEmployeeItemResponse,
  useProfileState,
} from '@innowise-group/core';
import { localizedNameObject } from '@innowise-group/utilities';

export interface LocalizedMessage extends Omit<Message, 'employee'> {
  employee: LocalizedShortEmployeeItemResponse;
}

export interface Message {
  action: EditCandidateActions;
  employee: ShortEmployeeItemResponse;
  entity: string;
  entityId: number;
  active: boolean;
  id: string;
}

const useCandidateChangesSubscription = (edit?: boolean) => {
  const { id: candidateId } = useParams();
  const { item: profile } = useProfileState();

  const [message, setMessage] = useState<LocalizedMessage | null>(null);

  let socket;

  const initSocketConnection = async () => {
    const { WEBSOCKET_BASE_URL: brokerURL } = await EnvConfigurationService.getEnvConfig();
    socket = new Client({
      brokerURL,
      connectHeaders: {
        Authorization: `Bearer ${AuthService.getToken()}`,
      },
    });
    socket.onConnect = () => {
      socket.subscribe(`/topic/CANDIDATE-${candidateId}`, (res) => {
        const message = JSON.parse(res.body);
        if (message.employee.employeeId !== profile?.id) {
          setMessage({
            ...message,
            active: true,
            employee: { ...message.employee, ...localizedNameObject(message.employee) },
          });
        }
      });
      if (edit) {
        socket.publish({
          destination: '/app/websocket',
          body: JSON.stringify({
            action: EditCandidateActions.EditStarted,
            entity: 'CANDIDATE',
            entityId: candidateId,
          }),
        });
      }
    };

    socket.activate();
  };

  const sendMessage = useCallback(
    (action: EditCandidateActions) =>
      socket.publish({
        destination: '/app/websocket',
        body: JSON.stringify({ action, entity: 'CANDIDATE', entityId: candidateId }),
      }),
    [socket],
  );

  const closeMessage = useCallback(() => setMessage((prev) => ({ ...prev, active: false })), [setMessage]);

  useEffect(() => {
    if (profile) initSocketConnection();
    return () => {
      if (profile) socket?.deactivate();
    };
  }, [profile]);

  return { message, sendMessage, closeMessage };
};

export default useCandidateChangesSubscription;
