import React, { useCallback, useEffect, useState } from 'react';
import { FileViewerContainer, ImageItem } from './image-viewer.styles';

interface ImageViewerProps {
  currentFile: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ currentFile }) => {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const calculateSize = useCallback(async () => {
    const img = new Image();
    img.src = currentFile;
    try {
      await img.decode();
    } finally {
      setSize({ height: img.naturalHeight, width: img.naturalWidth });
    }
  }, [currentFile]);

  useEffect(() => {
    calculateSize();
  }, [calculateSize]);

  return (
    <FileViewerContainer width={size.width} height={size.height}>
      {currentFile && <ImageItem src={currentFile} width={size.width} height={size.height} />}
    </FileViewerContainer>
  );
};

export default ImageViewer;
