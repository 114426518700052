import { SelectOption } from '@innowise-group/core';
import { EmployeesState } from './employees.types';
import { localizedNameObject } from '@innowise-group/utilities';

export const employeesMapper = (
  state: EmployeesState,
  entity: keyof Pick<EmployeesState, 'employees' | 'employeesForCandidateDiffMerge'>,
) => {
  const options: SelectOption[] = state[entity].ids?.map((id) => {
    const { localizedFullName } = localizedNameObject(state[entity].items[id]);
    return {
      value: id.toString(),
      title: localizedFullName,
      ...(state[entity].items[id].photoId ? { miniPhotoUrl: state[entity].items[id].photoId.toString() } : {}),
    };
  });
  if (!options.find((item) => item.value === state.responsibleEmployee?.id?.toString()) && state.responsibleEmployee) {
    const { localizedFullName: responsibleFullName } = localizedNameObject(state.responsibleEmployee);
    options.push({
      value: state.responsibleEmployee.id?.toString(),
      title: responsibleFullName,
      ...(state.responsibleEmployee.photoRt ? { miniPhotoUrl: state.responsibleEmployee.photoRt.id.toString() } : {}),
    });
  }
  return options;
};
