import { styled } from '@innowise-group/mui-kit';
import { Button as BaseButton, Icon } from '@innowise-group/mui-kit';
import { Slide as BaseSlide } from '@mui/material';

export const Slide = styled(BaseSlide)`
  position: fixed;
  top: 0;
  z-index: 1310;
  width: 100dvw;
  height: 100dvh;
  display: flex;
`;

export const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  z-index: 1310;
  width: 100dvw;
  height: 100dvh;
  display: flex;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const TopButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const ChildrenContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px 0 10px;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ButtonsContainer = styled.div`
  padding: 5px;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const Button = styled(BaseButton)`
  &.MuiButton-root.MuiButton-contained,
  &.MuiButton-root.MuiButton-outlined {
    padding: 15px;
  }
`;

export const Line = styled.div`
  width: 30dvw;
  height: 4px;
  margin: 15px auto;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const GoBackButton = styled(BaseButton)`
  width: fit-content;
`;

export const CloseButton = styled(BaseButton)`
  width: 100%;
`;

export const CloseIcon = styled(Icon)``;
