import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePickerContainer } from './period-section.styles';
import { DatePicker } from '@shared-mui-components';

interface PeriodSectionProps {
  fieldName: 'periodFrom' | 'periodTo';
}

const PeriodSection: React.FC<PeriodSectionProps> = ({ fieldName }) => {
  const { t } = useTranslation();
  const { control, watch, resetField, trigger } = useFormContext();
  const statusId = watch('statusId');

  const handleChange = (onChange: (value: Date) => void) => (value: Date) => {
    onChange(value);
    trigger(fieldName === 'periodFrom' ? 'periodTo' : 'periodFrom');
  };

  useEffect(() => {
    resetField(fieldName);
  }, [statusId]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        return (
          <DatePickerContainer>
            <DatePicker
              selected={value ? new Date(value) : null}
              onChange={handleChange(onChange)}
              isConstrained={false}
              label={t(`pages.candidates.comments.${fieldName}`)}
              placeholder={t(`pages.candidates.comments.${fieldName}`)}
              error={!!errors?.[fieldName]?.message}
              helperText={errors?.[fieldName]?.message as string}
            />
          </DatePickerContainer>
        );
      }}
    />
  );
};

export default React.memo(PeriodSection);
