import { Icon } from '@innowise-group/mui-kit';
import React, { MouseEventHandler } from 'react';
import * as Styled from './avatar-menu.styles';

interface AvatarMenuProps {
  photoId?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  firstNameRu?: string;
  lastNameRu?: string;
  jobTitle?: string;
  inTwoColumn?: boolean;
}

const AvatarMenu: React.FC<AvatarMenuProps> = ({
  photoId,
  firstNameRu,
  lastNameRu,
  jobTitle,
  inTwoColumn,
  onClick,
}) => {
  return (
    <Styled.Container onClick={onClick} isPointer={!!onClick}>
      {photoId ? (
        <Styled.Avatar id={photoId} />
      ) : (
        <Styled.NoImageContainer>
          <Icon type="u_camera" size={25} viewBox="2 1 46 40" />
        </Styled.NoImageContainer>
      )}
      {(firstNameRu || lastNameRu || jobTitle) && (
        <Styled.InfoBlock>
          <Styled.FullName>
            {firstNameRu} {!inTwoColumn && lastNameRu}
          </Styled.FullName>
          {inTwoColumn && <Styled.FullName>{lastNameRu}</Styled.FullName>}
          <Styled.JobTitle>{jobTitle}</Styled.JobTitle>
        </Styled.InfoBlock>
      )}
    </Styled.Container>
  );
};

export default AvatarMenu;
