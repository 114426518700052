import { Button, Input as InputBase, Icon as IconBase } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div``;

export const FieldContainer = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.palette.state.error.default : theme.palette.general.lightGrey[100])};
`;

export const Input = styled(InputBase)`
  & .MuiInputBase-root.Mui-disabled {
    & > input {
      background: ${({ theme }) => theme.palette.general.white};
      border-radius: 6px;
    }
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    outline: none;
  }
  &.MuiFormControl-root.MuiTextField-root {
    margin-top: 0;
  }
  & > .MuiFormHelperText-root.MuiFormHelperText-contained {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiTextField-root {
      width: 100%;
    }
  }
`;

export const CloseButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    min-width: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    height: 100%;
  }
`;

export const Icon = styled(IconBase)`
  transform: rotate(45deg);
`;

export const HelperText = styled.span`
  display: inline-block;
  height: 15px;
  color: ${({ theme }) => theme.palette.state.error.default};
  ${({ theme }) => theme.fonts.primary[30]}
`;
