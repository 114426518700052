import { FiltersUnion } from '@innowise-group/core';
import { FC, memo, useEffect, useState } from 'react';
import { generateFilterLabel } from './filter-label.data';
import * as Styled from './filter-label.styles';

const FilterLabel: FC<FiltersUnion> = (props) => {
  const [{ value, title }, setState] = useState({ value: '', title: '' });

  const getLabel = async () => {
    const state = await generateFilterLabel(props);
    setState(state);
  };

  useEffect(() => {
    getLabel();
  }, [props]);

  return (
    <Styled.Wrapper>
      {title}
      {value && <Styled.Value>{value}</Styled.Value>}
    </Styled.Wrapper>
  );
};

export default memo(FilterLabel);
