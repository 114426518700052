import { FC, FocusEventHandler, memo } from 'react';
import type { TextProps } from '../text';
import { InputAdornment } from '@mui/material';
import { Icon } from '@innowise-group/mui-kit';
import { CustomTextFilter, RemoveFilterButton } from './unpinned-search.styles';
import { useTranslation } from 'react-i18next';

interface UnpinnedSearchProps extends TextProps {
  onRemoveFilter?: (id: string) => () => void;
  filterProps: {
    placeholder: string;
  };
}

export const UnpinnedSearch: FC<UnpinnedSearchProps> = ({ onRemoveFilter, ...props }) => {
  const { t } = useTranslation();
  const onRemoveFilterHandle = () => {
    onRemoveFilter(props.id);
  };
  const onBlurHandle: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (!e.currentTarget.value) return onRemoveFilterHandle();
  };
  return (
    <CustomTextFilter
      {...props}
      placeholder={t(props.filterProps.placeholder)}
      InputProps={{
        onBlur: onBlurHandle,
        startAdornment: null,
        endAdornment: (
          <InputAdornment position="end">
            <RemoveFilterButton onClick={onRemoveFilterHandle}>
              <Icon viewBox="-1 -1 14 14" size={16} type="u_del_vector" />
            </RemoveFilterButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(UnpinnedSearch);
