import { BaseFilterType, NumberRangeFilterType } from '@innowise-group/core';
import * as Styled from './number-range.styles';
import { Divider } from '@innowise-group/mui-kit';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from '../shared';

export interface NumberRangeProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: NumberRangeFilterType['state'];
  onValueChange: (args: { [key in keyof NumberRangeFilterType['state']]: number | boolean }) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: NumberRangeFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const NumberRange: React.FC<NumberRangeProps> = ({
  state,
  labelKey,
  isOpen,
  onValueChange,
  onToggleFilter,
  onRemoveFilter,
  onClickFilterOutside,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [minInputValue, setMinInputValue] = useState('');
  const [maxInputValue, setMaxInputValue] = useState('');

  const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange({ ...state, from: Number(e.target.value) });
    setMinInputValue(e.target.value);
  };

  const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange({ ...state, to: Number(e.target.value) });
    setMaxInputValue(e.target.value);
  };

  const onReset = () => {
    setMinInputValue('');
    setMaxInputValue('');
    onValueChange({ ...state, from: state.minValue, to: state.maxValue });
  };

  const onMinInputBlur = () => {
    if (!minInputValue) return;
    return setMinInputValue(state.from?.toString());
  };

  const onMaxInputBlur = () => {
    if (!maxInputValue) return;
    return setMaxInputValue(state.to?.toString());
  };

  const onMinInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setMinInputValue('');
      onValueChange({ ...state, from: state.minValue });
      return;
    }
    if (Number(e.target.value) <= state.to && Number(e.target.value) >= Number(state.minValue)) return onMinChange(e);
    return setMinInputValue(e.target.value);
  };

  const onMaxInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setMaxInputValue('');
      onValueChange({ ...state, to: state.maxValue });
      return;
    }
    if (Number(e.target.value) >= state.from && Number(e.target.value) <= state.maxValue) return onMaxChange(e);
    return setMaxInputValue(e.target.value);
  };

  const onRemoveFilterHandler = () => onRemoveFilter({ ...state, from: state.minValue, to: state.maxValue });

  const onClickFilterOutsideHandle = () => {
    const isEmpty = state.from === state.minValue && state.to === state.maxValue;
    if (isEmpty) onRemoveFilterHandler();
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Container ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandler}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          <Styled.SelectedCount>{`${state.from} - ${state.to}`}</Styled.SelectedCount>
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          <Styled.ControlsContainer>
            <Styled.RangePrice>
              <Styled.RangePriceField>
                <Styled.RangePriceLabel htmlFor="min">{t('filters.from')}</Styled.RangePriceLabel>
                <Styled.RangePriceInput
                  onChange={onMinInputChangeHandler}
                  value={minInputValue}
                  onBlur={onMinInputBlur}
                  placeholder={t('filters.from')}
                  inputMode="numeric"
                  type="number"
                  name="min"
                />
              </Styled.RangePriceField>
              <Styled.RangePriceField>
                <Styled.RangePriceLabel htmlFor="max">{t('filters.to')}</Styled.RangePriceLabel>
                <Styled.RangePriceInput
                  onChange={onMaxInputChangeHandler}
                  value={maxInputValue}
                  onBlur={onMaxInputBlur}
                  placeholder={t('filters.to')}
                  inputMode="numeric"
                  type="number"
                  name="max"
                />
              </Styled.RangePriceField>
            </Styled.RangePrice>
            <Styled.CustomDualSlider
              minValue={state.from}
              maxValue={state.to}
              step={state.step}
              minRange={state.minValue}
              maxRange={state.maxValue}
              onMinChange={onMinChange}
              onMaxChange={onMaxChange}
              onReset={onReset}
              staticLimitLabels
            />
          </Styled.ControlsContainer>
          <Divider />
          <Styled.CustomButton onClick={onReset} variant="text">
            {t('filters.resetFilter')}
          </Styled.CustomButton>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Container>
  );
};

export default memo(NumberRange);
