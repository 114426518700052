import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelTransformer, DiffBase } from '../diff-base';
import * as Styled from './is-blocked-diff.styles';

const IsBlockedDiff: FC = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'blockedWithReason'> = (el) => (
    <Styled.OptionWrapper>
      <Styled.OptionValue>{`${t('pages.candidates.candidateCreation.blackList')}: ${t(
        `buttons.${el.data ? 'yes' : 'no'}`,
      )}`}</Styled.OptionValue>
      <Styled.OptionComment>{el.data}</Styled.OptionComment>
    </Styled.OptionWrapper>
  );
  return <DiffBase fieldName="blockedWithReason" labelTransformer={labelTransformer} {...props} />;
};

export default IsBlockedDiff;
