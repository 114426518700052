import { FC, FocusEventHandler, useEffect, useRef, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSearchRoutes } from '@modules';
import { MappedEntity, useClickOutside, useSearchAPI } from '@innowise-group/core';
import { useNavigate } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
import { Icon } from '@innowise-group/mui-kit';
import * as Styled from './global-search-form.styles';
import { iconsData } from './global-search-form.data';
import { RoleGuard } from '@shared-components';
import { RolesValues } from '@constants';

type SearchInput = { search: string };

const GlobalSearchForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const tabsUnion = useSearchRoutes();
  const [show, setShow] = useState<boolean>(false);
  const [searchContainerWidth, setSearchContainerWidth] = useState<number>(0);
  const { addHistoryQuery, removeHistoryQuery, removeHistoryEntity, manageXrayMode, historyEntities, historyQueries } =
    useSearchAPI();
  const { handleSubmit, register, setValue } = useForm<SearchInput>({ defaultValues: { search: '' } });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSearchContainerWidth(entry.contentRect.width);
      }
    });
    if (show && searchResultsRef.current) {
      resizeObserver.observe(searchResultsRef.current);
    }
    return () => {
      setSearchContainerWidth(0);
      resizeObserver.disconnect();
    };
  }, [show]);

  const onInputFocus: FocusEventHandler<HTMLInputElement> = () => {
    setShow(true);
  };

  const redirectToGlobalSearchPage = ({ search }: SearchInput) => {
    if (search) {
      addHistoryQuery(search);
      navigate(`${tabsUnion[0].value}?globalSearch=${search}`);
      setValue('search', '');
    }
    searchInputRef.current.blur();
    setShow(false);
  };

  const onInputBlur = () => {
    setShow(false);
  };

  const onQueryClickHandle = (search: string) => () => {
    return redirectToGlobalSearchPage({ search });
  };

  const redirectToGlobalXraySearchPage = () => {
    setValue('search', '');
    setShow(false);
    manageXrayMode(true);
    return navigate(tabsUnion[0].value);
  };

  const onEntityClickHandle =
    ({ entityId, redirectUrlBasePath }: MappedEntity) =>
    () => {
      setValue('search', '');
      navigate(`${redirectUrlBasePath}/${entityId}`);
      searchInputRef.current.blur();
      setShow(false);
    };

  const deleteHistoryQuery = (idx: number) => (e: MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    removeHistoryQuery(idx);
  };

  const deleteHistoryEntity = (idx: number) => (e: MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    removeHistoryEntity(idx);
  };

  const ref = useClickOutside<HTMLFormElement>(onInputBlur, show);

  return (
    <Styled.Form ref={ref} onSubmit={handleSubmit(redirectToGlobalSearchPage)}>
      <Styled.SearchInput
        {...register('search')}
        width={searchContainerWidth}
        inputRef={searchInputRef}
        placeholder={t('buttons.search')}
        onFocus={onInputFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon type="u_search" />
            </InputAdornment>
          ),
        }}
      />
      <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter]}>
        <Styled.XrayButton
          onClick={redirectToGlobalXraySearchPage}
          variant="text"
          startIcon={<Styled.XraySearchIcon viewBox="0 0 22 17" size={35} type="u_xray_search" />}
        />
      </RoleGuard>
      {show && !!(historyQueries.length || historyEntities.length) && (
        <Styled.HistoryWrapper timeout={300} in={true}>
          <Styled.HistoryChildrenWrapper ref={searchResultsRef}>
            {!!historyQueries.length && (
              <Styled.Content>
                <Styled.HistoryContentTitle>{t('buttons.lastRequests')}</Styled.HistoryContentTitle>
                {historyQueries.map((query, i) => (
                  <Styled.HistoryItem key={i} onClick={onQueryClickHandle(query)}>
                    <Icon viewBox="0 0 24 18" type="u_last_time" />
                    <Styled.HistoryItemLabel>{query}</Styled.HistoryItemLabel>
                    <Styled.DeleteIcon type="u_decline_icon" viewBox="-6 -6 20 20" onClick={deleteHistoryQuery(i)} />
                  </Styled.HistoryItem>
                ))}
              </Styled.Content>
            )}
            {!!historyEntities.length && (
              <Styled.Content>
                <Styled.HistoryContentTitle>{t('buttons.inDocuments')}</Styled.HistoryContentTitle>
                {historyEntities.map((el, i) => (
                  <Styled.HistoryItem key={i} onClick={onEntityClickHandle(el)}>
                    <Icon {...iconsData[el.entity]} />
                    <Styled.HistoryItemLabel>{el.title}</Styled.HistoryItemLabel>
                    <Styled.DeleteIcon type="u_decline_icon" viewBox="-6 -6 20 20" onClick={deleteHistoryEntity(i)} />
                  </Styled.HistoryItem>
                ))}
              </Styled.Content>
            )}
          </Styled.HistoryChildrenWrapper>
        </Styled.HistoryWrapper>
      )}
    </Styled.Form>
  );
};

export default GlobalSearchForm;
