import { styled } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const UserName = styled.h3`
  ${({ theme }) => theme.fonts.primary[60]}
`;

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.25rem;
  gap: 0.5rem;
`;

export const OptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SubOptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 10px;
`;
