export enum PassingProbationEnum {
  WithoutProbation = 'WITHOUT_PROBATION',
  QuickProbation = 'QUICK_PROBATION',
  ProbationFailed = 'PROBATION_FAILED',
}

export const ProbationsTranslation: { [key: string]: string } = {
  [PassingProbationEnum.WithoutProbation]: 'pages.administration.referral.withoutProbation',
  [PassingProbationEnum.QuickProbation]: 'pages.administration.referral.quickProbation',
  [PassingProbationEnum.ProbationFailed]: 'pages.administration.referral.probationFailed',
};

export enum OffersType {
  Offer = 'OFFER',
  PreOffer = 'PRE_OFFER',
}
