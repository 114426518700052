import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.general.paper};
  overflow-y: auto;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 1.5rem 1.5rem 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    overflow-x: hidden;
  }
`;
