import React, { forwardRef } from 'react';
import { FormControlLabelProps, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import * as Styled from './labeled-checkbox.styles';
import { Checkbox } from '@innowise-group/mui-kit';

type CheckboxProps = MuiCheckboxProps & Pick<FormControlLabelProps, 'label' | 'labelPlacement'> & { error?: boolean };

const LabeledCheckbox: React.FC<CheckboxProps> = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ label, labelPlacement, className, ...props }, ref) => (
    <Styled.LabelContainer
      className={className}
      ref={ref}
      label={label}
      labelPlacement={labelPlacement}
      control={<Checkbox {...props} />}
    />
  ),
);

export default React.memo(LabeledCheckbox);
