import { styled } from '@innowise-group/mui-kit';
import bg from './404_not_found.svg';

export const Image = styled.div`
  background-image: url(${bg});
  background-size: cover;
  width: 426px;
  height: 262px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    transform: scale(0.7);
  }
`;

export const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1.25rem;
  }
`;
export const NotFoundTitle = styled.h1`
  margin: 20px 0;
  ${({ theme }) => theme.fonts.primary[100]}
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 10px 0;
  }
`;
export const NotFoundText = styled.p`
  ${({ theme }) => theme.fonts.primary[50]}
`;
