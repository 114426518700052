import { Button, DropdownListItem, styled, DropdownList } from '@innowise-group/mui-kit';
import { MentionsInput } from 'react-mentions';

export const Container = styled.div<{ withPadding: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: ${({ withPadding }) => withPadding && ' 1.25rem 1.25rem 1.25rem 2rem'};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ withPadding }) => withPadding && ' 1rem'};
    gap: 4px;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 8.7fr;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentContainer = styled.div<{ withPadding: boolean }>`
  display: flex;
  padding-left: ${({ withPadding }) => withPadding && '3.25rem'};
  width: 100%;
  & > * {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  padding-left: 3.25rem;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding-left: 0;
    gap: 1rem;
    position: relative;
    top: -5px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    width: 11.25rem;
    max-width: 11.25rem;
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
    &:disabled {
      color: ${({ theme }) => theme.palette.general.darkGrey[60]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;

export const PostButton = styled(Button)`
  &.MuiButton-root.MuiButton-contained {
    width: 11.25rem;
    max-width: 11.25rem;
    transition: 0.2s;
    color: ${({ theme }) => theme.palette.general.white};
    background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    &:hover {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;

export const CommentContainer = styled.div<{ errors: boolean; isFocused: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  box-sizing: border-box;
  border-radius: 6px;
  transition: 0.2s;
  border: 1px solid
    ${({ theme, errors, isFocused }) =>
      errors
        ? theme.palette.state.error.default
        : isFocused
        ? theme.palette.general.darkGrey[80]
        : theme.palette.general.lightGrey[100]};
  &:hover {
    border: 1px solid
      ${({ theme, errors }) => (errors ? theme.palette.state.error.default : theme.palette.general.darkGrey[80])};
  }
`;

export const CommentButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: end;
  & > * {
    width: 130px;
  }
`;

export const CommentTextareaContainer = styled.div`
  display: flex;
  min-height: 64px;
`;

export const TextAreaElement = styled(MentionsInput)`
  flex: 1;
  & textarea {
    resize: none;
    min-height: 64px;
    outline: none;
    margin: 0;
    padding: 0;
    word-spacing: 0 !important;
    letter-spacing: 0px !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 22px !important;
    box-sizing: border-box;
    width: 100%;
    word-break: break-all;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.breakpoints.down('sm')} {
      left: 0px !important;
      top: 0px !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  & [class*='__highlighter'] {
    border: 0 !important;
    font-weight: 400 !important;
    letter-spacing: 0px !important;
    word-spacing: 0 !important;
    font-size: 1rem !important;
    word-break: break-all;
    line-height: 22px !important;
    background-color: ${({ theme }) => theme.palette.general.paper};
  }

  & [class*='__highlighter'] strong {
    position: relative;
    z-index: 1;
    color: ${({ theme }) => theme.palette.info.default};
    text-shadow: 1px 1px 0 ${({ theme }) => theme.palette.general.paper},
      1px -1px 0 ${({ theme }) => theme.palette.general.paper}, -1px 1px 0 ${({ theme }) => theme.palette.general.paper},
      -1px -1px 0 ${({ theme }) => theme.palette.general.paper};
    pointer-events: none;
  }
`;

export const TagsDropdownList = styled(DropdownList)`
  width: max-content;
`;

export const ListItem = styled(DropdownListItem)`
  width: auto;
`;
