import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useTheme } from '../theme';
import { AppIconsType } from '../icon';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, NAVIGATION_BAR_LAPTOP_WIDTH, RolesValues, StorageKeys } from '@constants';
import { LocalStorageService, useResizeObserver } from '@innowise-group/core';
import * as Styled from './navigation-bar.styles';
import { Tooltip } from '../tooltip';

export interface NavigationBarProps {
  routes: Route[];
  isCollapsed: boolean;
  toggleCollapsed: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

interface Route {
  name: string;
  path: string;
  viewBox?: string;
  icon: AppIconsType;
  activeWhen?: (route: string) => boolean;
  allowed: boolean;
  roles?: RolesValues[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ routes, children, isCollapsed, toggleCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  const handleToggleNavBar = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleCollapsed((previousIsCollapsed) => {
      const newState = !previousIsCollapsed;
      LocalStorageService.setItem(StorageKeys.NavigationMenuOpenState, newState || '');
      return newState;
    });
  };

  const currentTab = useMemo(() => {
    const [, basePath] = location.pathname.split('/');
    return routes.find(({ path }) => path.includes(basePath))?.path || false;
  }, [location.pathname, routes]);

  const handleNavigationItemClick = (path: string) => () => {
    navigate(path);
    if (document.body.clientWidth <= NAVIGATION_BAR_LAPTOP_WIDTH) toggleCollapsed(true);
  };

  return (
    <Styled.NavigationBarContainer collapsed={isCollapsed ? 1 : 0}>
      <Styled.NavigationBarContentContainer>
        <Styled.CustomTabs value={currentTab} orientation={isDesktopView ? 'vertical' : 'horizontal'}>
          {routes.map(({ name, path, icon, activeWhen, viewBox, allowed }, index) => {
            const isSelected = activeWhen ? activeWhen(location.pathname) : location.pathname.includes(path);
            return (
              allowed && (
                <Styled.Tab
                  key={index}
                  onClick={handleNavigationItemClick(path)}
                  tabsvalue={currentTab}
                  value={path}
                  path={path}
                >
                  <Styled.NavigationItem selected={isSelected ? 1 : 0}>
                    {path === AppRoutes.Candidates && (
                      <Styled.CollapseButton onClick={handleToggleNavBar}>
                        <Styled.IconItem
                          type={isCollapsed ? 'u_angle-right-b' : 'u_angle-left-b'}
                          fill={theme.palette.general.darkGrey[80]}
                          size={18}
                          isCollapsed={true}
                          {...(viewBox && { viewBox })}
                        />
                      </Styled.CollapseButton>
                    )}
                    <Tooltip
                      title={name}
                      placement="right"
                      disableInteractive={!isCollapsed}
                      disableFocusListener={!isCollapsed}
                      disableHoverListener={!isCollapsed}
                      disableTouchListener={!isCollapsed}
                    >
                      <span>
                        <Styled.IconItem
                          type={icon}
                          fill={isSelected ? theme.palette.general.smart : ''}
                          isCollapsed={isCollapsed}
                          {...(viewBox && { viewBox })}
                        />
                      </span>
                    </Tooltip>
                    {!isCollapsed && isDesktopView && <Styled.NavigationLinkText>{name}</Styled.NavigationLinkText>}
                  </Styled.NavigationItem>
                </Styled.Tab>
              )
            );
          })}
        </Styled.CustomTabs>
        {children}
      </Styled.NavigationBarContentContainer>
    </Styled.NavigationBarContainer>
  );
};

export default NavigationBar;
