import { FileTypeConstants, FileTypesEnum } from '@constants';
import {
  EmployeeAuthDataResponse,
  EmployeesService,
  FileIdResponse,
  FilesService,
  MyKnownError,
  ProfileItem,
  ProfileItemResponse,
  ProfileService,
} from '@innowise-group/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProfileThunk = createAsyncThunk<ProfileItemResponse, void, { rejectValue: MyKnownError }>(
  'profile/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProfileService.getProfile();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const createAvatarPhotoThunk = createAsyncThunk<
  FileIdResponse,
  {
    file: File;
    successCallback?: () => void;
  },
  { rejectValue: MyKnownError }
>('profile/createAvatar', async ({ file, successCallback }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await FilesService.createFile(formData, false, FileTypeConstants.ProfilePhoto);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateProfileThunk = createAsyncThunk<
  ProfileItemResponse,
  { data: ProfileItem; id: number; authData: EmployeeAuthDataResponse; successCallback: () => void },
  { rejectValue: MyKnownError }
>('profile/update', async ({ data, id, authData, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      photoRtId: data.miniPhotoUrl?.id || null,
      phoneRt: data.phoneRt.value ? '+' + data.phoneRt.value : null,
      telegramRt: data.telegramRt || null,
      authData: authData
        ? {
            permittedProfessionIds: authData.permittedProfessions?.map((item) => item.valueId),
            permittedVacancyIds: authData.permittedVacancies?.map((item) => item.id),
            permittedVacancyRequestIds: authData?.permittedVacancyRequestIds,
          }
        : null,
    };
    const response = await ProfileService.updateProfile(newData, id);
    successCallback();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getHrmAvatarPhotoThunk = createAsyncThunk<
  void,
  {
    url: string;
    successCallback: (file: File) => void;
  },
  { rejectValue: MyKnownError }
>('profile/createAvatarFromAvatar', async ({ url, successCallback }, { rejectWithValue }) => {
  try {
    const response = await FilesService.createFileFromHrm(url);
    const blob = new Blob([response.data], { type: FileTypesEnum.jpeg });
    const file = new File([blob], 'avatar.jpeg', {
      type: blob.type,
    });
    successCallback(file);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getProfileCurrentEventsCountThunk = createAsyncThunk<number, undefined, { rejectValue: MyKnownError }>(
  'profile/getProfileCurrentEventsCount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await EmployeesService.getProgressCount();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);
