import { styled } from '@innowise-group/mui-kit';
import { Typography } from '../typography';

export const NamedSectionContainer = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`;

export const Title = styled(Typography)`
  position: absolute;
  left: 10px;
  background-color: ${({ theme }) => theme.palette.general.paper};
  top: -10px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  padding: 0 3px;
  ${({ theme }) => theme.fonts.primary[60]}
`;
