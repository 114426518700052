import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import * as Styled from './manage-notification-modal.styles';

export interface ManageNotificationModalProps {
  handleSubmit: () => void;
  title: string;
  subtitle: string;
  isLoading: boolean;
}

const ManageNotificationModal: React.FC<ManageNotificationModalProps & ModalProps> = ({
  close,
  handleSubmit,
  title,
  subtitle,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{title}</Styled.Header>
      </Modal.Header>
      <Modal.Body>{isLoading ? <Loader /> : <Styled.Description>{subtitle}</Styled.Description>}</Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.no'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.yes'),
            onClick: handleSubmit,
            highlighted: true,
            type: 'button',
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(ManageNotificationModal, 'manage-notification');
