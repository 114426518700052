import { SelectOption } from '@innowise-group/core';
import React, { ComponentProps } from 'react';
import { FiltersConfig } from '../../use-report-manager.hook';
import { ReportFormValues } from '../../report-manager.types';
import { ReportButtonProps } from '../report-button';
import * as Styled from './mobile-view.styles';
import { useTranslation } from 'react-i18next';
import { Reports, reports } from '../choose-report/choose-report.data';
import { ChooseReport } from '../choose-report';
import { Accordion, AccordionSummary, Icon, Loader } from '@innowise-group/mui-kit';
import { RolesValues } from '@constants';
import { RoleGuard } from '@shared-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useStatisticsAPI } from '@innowise-group/core';
import { DateRangeFilter } from '../dates-range';
import { SelectFilter } from '../select';

interface MobileViewProps {
  toggleButton: () => void;
  expanded: boolean;
  periodLabel: string;
  config: FiltersConfig<ReportFormValues>;
  applyFilters: (args: ReportFormValues) => void;
  reportTypeIcons: ReportButtonProps['icons'];
  onExportHandle: (args: ReportFormValues) => void;
  handleStatusChange: (
    onChange: (value: string | SelectOption[], reason?: string) => void,
  ) => (value: string | SelectOption[], reason?: string) => void;
  reportTypeVariants: ReportButtonProps['variants'];
  submitButtonDisabled: boolean;
  switcherTypeDisabled: boolean;
  switcherDisabled: boolean;
  statusAuthorDisabled: boolean;
  statusDisabled: boolean;
}

const MobileView: React.FC<MobileViewProps> = ({
  toggleButton,
  onExportHandle,
  applyFilters,
  handleStatusChange,
  periodLabel,
  submitButtonDisabled,
  statusAuthorDisabled,
  statusDisabled,
  expanded,
  config,
}) => {
  const { t } = useTranslation();
  const { isExportLoading } = useStatisticsAPI();
  const { handleSubmit, control } = useFormContext<ReportFormValues>();

  const currentReport = reports.reduce((acc, { path, value }) => {
    if (window.location.pathname.endsWith(path)) {
      return value;
    }
    return acc;
  }, '' as Reports);

  const shouldShowReportFilters = currentReport !== Reports.Duplicates;

  const shouldShowExportButton = ![Reports.TeamWork, Reports.Letters, Reports.Employee].includes(currentReport);

  return (
    <Styled.Wrapper>
      <Styled.ButtonsContainer>
        <ChooseReport />
        {shouldShowReportFilters && (
          <React.Fragment>
            <RoleGuard roles={[RolesValues.Admin, RolesValues.Supervisor]}>
              {shouldShowExportButton && (
                <Styled.ExportButton
                  onClick={handleSubmit(onExportHandle)}
                  variant="outlined"
                  startIcon={isExportLoading ? <Loader size={20} /> : <Icon type="u_export" />}
                  disabled={isExportLoading}
                >
                  {t('buttons.export')}
                </Styled.ExportButton>
              )}
            </RoleGuard>
            <Styled.SettingButton
              onClick={toggleButton}
              startIcon={<Icon type="u_report_settings" />}
              endIcon={<Styled.ArrowIcon open={expanded} />}
            >
              {t('buttons.reportSettings')}
            </Styled.SettingButton>
            {periodLabel && <Styled.PeriodText>{periodLabel}</Styled.PeriodText>}
          </React.Fragment>
        )}
      </Styled.ButtonsContainer>
      {shouldShowReportFilters && (
        <Accordion expanded={expanded}>
          <AccordionSummary />
          <Styled.AccordionDetails>
            <Styled.FiltersContainer>
              <DateRangeFilter
                minName={config.dateRange.minName}
                maxName={config.dateRange.maxName}
                disabled={(config.dateRange as unknown as ComponentProps<typeof SelectFilter>).disabled}
              />
              <Controller
                name={config.statuses.name}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <SelectFilter
                    onChange={handleStatusChange(onChange)}
                    {...{ ...field, ...(config.statuses as unknown as ComponentProps<typeof SelectFilter>) }}
                    disabled={
                      (config.statuses as unknown as ComponentProps<typeof SelectFilter>).disabled || statusDisabled
                    }
                  />
                )}
              />
              <Controller
                name={config.vacancies.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancies as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.sources.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.sources as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.vacancyClaims.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancyClaims as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.vacancyClaimAuthors.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancyClaimAuthors as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.statusAuthors.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.statusAuthors as unknown as ComponentProps<typeof SelectFilter>) }}
                    disabled={
                      (config.statusAuthors as unknown as ComponentProps<typeof SelectFilter>).disabled ||
                      statusAuthorDisabled
                    }
                  />
                )}
              />
              <Controller
                name={config.candidateResponsible.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{
                      ...field,
                      ...(config.candidateResponsible as unknown as ComponentProps<typeof SelectFilter>),
                    }}
                  />
                )}
              />
              <Styled.SubmitButton onClick={handleSubmit(applyFilters)} disabled={submitButtonDisabled}>
                {t('buttons.apply')}
              </Styled.SubmitButton>
            </Styled.FiltersContainer>
          </Styled.AccordionDetails>
        </Accordion>
      )}
    </Styled.Wrapper>
  );
};

export default MobileView;
