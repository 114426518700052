import { FC, PropsWithChildren, memo } from 'react';
import { Wrapper, Content } from './page-layout.styles';

const PageLayout: FC<PropsWithChildren> = ({ children, ...props }) => (
  <Wrapper>
    <Content {...props}>{children}</Content>
  </Wrapper>
);

export default memo(PageLayout);
