import { createReducer } from '@reduxjs/toolkit';
import {
  addHistoryEntityAction,
  addHistoryQueryAction,
  clearSearchAction,
  removeHistoryEntityAction,
  removeHistoryQueryAction,
  toggleXrayModeAction,
  manageXrayModeAction,
  addHistoryXrayQueryAction,
  removeHistoryXrayQueryAction,
} from './search.action';
import { preloadedState } from './search.preloaded-state';
import { searchCandidatesThunk, searchVacanciesThunk, searchRequestsThunk } from './search.thunk';

export const MAX_HISTORY_QUERY_ITEMS_COUNT = 5;
export const MAX_HISTORY_XRAY_QUERY_ITEMS_COUNT = 15;
const MAX_HISTORY_ENTITIES_ITEMS_COUNT = 10;

export const searchReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(addHistoryEntityAction, (state, { payload }) => {
      const isExists = state.searchHistory.entities.some(
        ({ entityId: id, entity }) => id === payload.entityId && entity === payload.entity,
      );
      if (!isExists) {
        if (state.searchHistory.entities.length >= MAX_HISTORY_ENTITIES_ITEMS_COUNT) {
          state.searchHistory.entities.pop();
        }
        state.searchHistory.entities = [payload, ...state.searchHistory.entities];
      }
    })
    .addCase(addHistoryQueryAction, (state, { payload }) => {
      if (!state.searchHistory.queries.includes(payload)) {
        if (state.searchHistory.queries.length >= MAX_HISTORY_QUERY_ITEMS_COUNT) {
          state.searchHistory.queries.pop();
        }
        state.searchHistory.queries = [payload, ...state.searchHistory.queries];
      }
    })
    .addCase(addHistoryXrayQueryAction, (state, { payload }) => {
      const isExists = state.searchHistory.xrayQuery.some(({ xRaySearch }) => xRaySearch === payload.xRaySearch);
      if (!isExists) {
        if (state.searchHistory.xrayQuery.length >= MAX_HISTORY_XRAY_QUERY_ITEMS_COUNT) {
          state.searchHistory.xrayQuery.pop();
        }
        state.searchHistory.xrayQuery = [payload, ...state.searchHistory.xrayQuery];
      }
    })
    .addCase(removeHistoryEntityAction, (state, { payload }) => {
      state.searchHistory.entities = state.searchHistory.entities.filter((_, idx) => idx !== payload);
    })
    .addCase(removeHistoryQueryAction, (state, { payload }) => {
      state.searchHistory.queries = state.searchHistory.queries.filter((_, idx) => idx !== payload);
    })
    .addCase(removeHistoryXrayQueryAction, (state, { payload }) => {
      state.searchHistory.xrayQuery = state.searchHistory.xrayQuery.filter(({ id }) => id !== payload);
    })
    .addCase(toggleXrayModeAction, (state) => {
      state.activeXrayMode = !state.activeXrayMode;
    })
    .addCase(manageXrayModeAction, (state, { payload }) => {
      state.activeXrayMode = payload;
    })
    .addCase(clearSearchAction, (state) => {
      state.loading = preloadedState.loading;
      state.isLastPage = preloadedState.isLastPage;
      state.requestsSearchContent = preloadedState.requestsSearchContent;
      state.vacanciesSearchContent = preloadedState.vacanciesSearchContent;
      state.candidatesSearchContent = preloadedState.candidatesSearchContent;
    })

    .addCase(searchCandidatesThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(searchCandidatesThunk.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      state.isLastPage = payload.empty;
      if (meta.arg.page === 1) {
        state.candidatesSearchContent = payload.searchHits;
      } else {
        state.candidatesSearchContent = [...state.candidatesSearchContent, ...payload.searchHits];
      }
    })
    .addCase(searchCandidatesThunk.rejected, (state) => {
      state.loading = false;
    })

    .addCase(searchVacanciesThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(searchVacanciesThunk.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      state.isLastPage = payload.empty;
      if (meta.arg.page === 1) {
        state.vacanciesSearchContent = payload.searchHits;
      } else {
        state.vacanciesSearchContent = [...state.vacanciesSearchContent, ...payload.searchHits];
      }
    })
    .addCase(searchVacanciesThunk.rejected, (state) => {
      state.loading = false;
    })

    .addCase(searchRequestsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(searchRequestsThunk.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      state.isLastPage = payload.empty;
      if (meta.arg.page === 1) {
        state.requestsSearchContent = payload.searchHits;
      } else {
        state.requestsSearchContent = [...state.requestsSearchContent, ...payload.searchHits];
      }
    })
    .addCase(searchRequestsThunk.rejected, (state) => {
      state.loading = false;
    }),
);
