import React, { useEffect, useRef } from 'react';
import { renderAsync } from 'docx-preview';
import { FileTypesEnum } from '@constants';
import * as Styled from './docx-viewer.styles';
import { $filesApi } from '@innowise-group/core';

interface DocxViewerProps {
  id: number;
  fileName: string;
}

const DocxViewer: React.FC<DocxViewerProps> = ({ fileName, id }) => {
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const fetchFile = async () => {
      const response = await $filesApi.get<ArrayBuffer>(`/recruiting-tool/api/v1/attachment?id=${id}`, {
        responseType: 'arraybuffer',
      });
      const data = new Blob([response.data], {
        type: FileTypesEnum[fileName.split('.').reverse()[0]],
      });
      if (container.current) {
        await renderAsync(data, container.current, null, { renderFooters: false });
      }
    };
    fetchFile();
  }, [id]);

  return <Styled.Container ref={container} />;
};

export default React.memo(DocxViewer);
