import { createAction } from '@reduxjs/toolkit';
import {
  ComparedCandidateWithSeveralCandidatesItems,
  MappedTargetCandidateDtoWithPossibleVariants,
} from './consolidation.types';
import { CandidateItem } from '../candidates';

export const saveMappedCandidateDiffsAction = createAction<ComparedCandidateWithSeveralCandidatesItems>(
  'diffs/saveMappedCandidateDiffs',
);

export const removeDuplicateItemFromListAction = createAction<number>('diffs/removeDuplicateItemFromList');

export const cleanUpFieldDiffAction =
  createAction<keyof MappedTargetCandidateDtoWithPossibleVariants>('diffs/cleanUpFieldDiff');

export const saveUnsavedCandidateAction = createAction<CandidateItem>('diffs/saveUnsavedCandidate');

export const clearDiffAction = createAction('diffs/clearDiff');
export const ignoreAllDiffsAction = createAction('diffs/ignoreAllDiffs');
