import { styled, DualSlider, css } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[80]}
`;

export const ControlsContainer = styled.div`
  padding: 15px 15px 0 15px;
  width: 100%;
`;

export const RangePrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RangePriceField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RangePriceLabel = styled.label`
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const RangePriceInput = styled.input`
  ${({ theme }) => theme.fonts.primary[20]}
  width: 150px;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const rangeThumbStyles = css`
  width: 30px;
  height: 30px;
`;

export const CustomDualSlider = styled(DualSlider)`
  & .min-range-input {
    &::-moz-range-thumb {
      ${rangeThumbStyles}
    }
    &::-webkit-slider-thumb {
      ${rangeThumbStyles}
    }
  }
  & .max-range-input {
    &::-webkit-slider-thumb {
      ${rangeThumbStyles}
    }
    &::-webkit-slider-thumb {
      ${rangeThumbStyles}
    }
  }
  padding: 40px 10px;
`;
