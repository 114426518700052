import { Icon, styled } from '@innowise-group/mui-kit';

export const Modal = styled.form<{ insideModal?: boolean }>`
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: ${({ insideModal }) => (insideModal ? '80%' : '85%')};
  min-height: ${({ insideModal }) => (insideModal ? '80%' : '85%')};
  max-width: 1080px;
  min-width: 510px;
  margin: 20px 90px;
  position: relative;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    max-width: 80vw;
  }
`;

export const ZoomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

export const PDFViewerContainer = styled.div`
  min-width: 570px;
  height: 100%;
  max-height: 85vh;
  overflow: auto;
  & .rpv-core__page-layer,
  & .rpv-core__canvas-layer,
  & canvas {
    display: block;
    margin: 0 auto;
    width: auto !important;
    height: 100% !important;
  }
  & .rpv-core__text-layer {
    display: none;
  }
`;

export const ZoomIcon = styled(Icon)<{ disable: boolean }>`
  cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
  & svg {
    fill: ${({ theme, disable }) =>
      disable ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[100]};
  }
`;

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

export const ModalActions = styled.div`
  position: absolute;
  top: 0;
  right: -60px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ActionItem = styled.a`
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.paper};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & * {
    margin: 0;
    padding: 0;
  }

  & svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;
