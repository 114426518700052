import { BaseFilterType, VacanciesService, VacancyApiSearchFilterType } from '@innowise-group/core';
import { FC, memo } from 'react';
import { LifecycleStatuses, SortingValue } from '@constants';
import { EntityFilter, type GetEntitiesById, type GetEntitiesList } from '../entity';

interface VacancyFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: VacancyApiSearchFilterType['state'];
  onValueChange: (vacancyId: VacancyApiSearchFilterType['state']) => void;
}

const VacancyFilter: FC<VacancyFilterProps> = (props) => {
  const getVacanciesList: GetEntitiesList = async ({ pageSize, currentPage, searchBarRequest }) => {
    const body = {
      searchBarRequest,
      lifecycleStatus: LifecycleStatuses.Actual,
    };
    const params = { currentPage, pageSize, sort: SortingValue.IdASC };
    const { data } = await VacanciesService.searchAllVacancies({ params, body });
    return data;
  };

  const getVacancyById: GetEntitiesById = async (id) => {
    const { data } = await VacanciesService.getVacancyById(id);
    return data;
  };

  return <EntityFilter multiple {...props} getEntitiesList={getVacanciesList} getEntityById={getVacancyById} />;
};

export default memo(VacancyFilter);
