import { styled } from '@innowise-group/mui-kit';
import { Image } from '@shared-mui-components';

export const Container = styled.div<{ isPointer: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 3.25rem;
  cursor: ${({ isPointer }) => isPointer && 'pointer'};
`;

export const NoImageContainer = styled.div`
  height: 3.25rem;
  width: 3.25rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

export const Avatar = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  height: 3.25rem;
  width: 3.25rem;
  flex-shrink: 0;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  max-width: 100%;
  overflow-x: hidden;
  ${({ theme }) => theme.fonts.primary[20]}
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[40]}
  }
  -webkit-text-fill-color: ${({ theme }) => theme.palette.general.lightGrey[100]};
  color: ${({ theme }) => theme.palette.general.lightGrey[100]};
`;

export const FullName = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  -webkit-text-fill-color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[60]}
  }
`;

export const JobTitle = styled.div`
  -webkit-text-fill-color: ${({ theme }) => theme.palette.general.lightGrey[70]};
  color: ${({ theme }) => theme.palette.general.lightGrey[70]};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
