import { LocalizedRequestItemResponse, RequestItem } from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionsSectionFields, MainSectionFields, RequestStepsEnum, steps } from '../../request-form.data';
import * as Styled from './mobile-view.styles';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Input } from '@innowise-group/mui-kit';
import { MainSection } from '../main-section';
import { OptionalSection } from '../optional-section';
import { LabeledCheckbox, Stepper } from '@shared-mui-components';

interface MobileViewProps {
  isEdit?: boolean;
  isStatusDisabled?: boolean;
  requestStatusOptions: SelectOption[];
  disabled: boolean;
  locationCityOptions: SelectOption[];
  currentRequestEdition: LocalizedRequestItemResponse;
  locationCountryOptions: SelectOption[];
  offices: SelectOption[];
  onSubmit: (data: RequestItem) => void;
  handleClose: () => void;
  searchVacancyHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  groupBy: (value) => (option) => string;
  handleChange: (onChange: (value: string) => void) => (value: string) => void;
  requestAuthorOptions: SelectOption[];
  searchRequestAuthorOptions: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isVacancyDisabled: boolean;
}

const MobileView: React.FC<MobileViewProps> = ({ onSubmit, ...props }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<RequestStepsEnum>(RequestStepsEnum.Main);
  const { handleSubmit, control, trigger } = useFormContext();
  const { disabled } = props;

  const handleNextStep = useCallback(
    (fields: string[]) => async () => {
      const isValidated = await trigger(fields);
      if (isValidated) {
        setActiveStep((prev) => prev + 1);
      }
    },
    [],
  );

  const handlePrevStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  return (
    <Styled.FormContainer>
      <Stepper steps={steps} activeStep={activeStep} />
      <Styled.StepContainer>
        {activeStep === RequestStepsEnum.Main && (
          <MainSection handleNextStep={handleNextStep(MainSectionFields)} {...props} />
        )}
        {activeStep === RequestStepsEnum.Optional && (
          <OptionalSection
            handleNextStep={handleNextStep(ConditionsSectionFields)}
            handlePrevStep={handlePrevStep}
            {...props}
          />
        )}
        {activeStep === RequestStepsEnum.Comments && (
          <React.Fragment>
            <Controller
              name="comment"
              control={control}
              render={({ field: { onChange, value }, formState: { errors } }) => (
                <Input
                  fullWidth
                  value={value}
                  onChange={onChange}
                  label={t('pages.candidates.candidateCreation.comment')}
                  error={!!errors?.['comment']?.message}
                  helperText={errors?.['comment']?.message.toString()}
                  rows={7}
                  multiline
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name="isCommentSecured"
              control={control}
              render={({ field: { onChange, value } }) => (
                <LabeledCheckbox
                  onChange={onChange}
                  checked={value}
                  label={t('pages.requests.creationModal.restrictAccess')}
                />
              )}
            />
            <Styled.ButtonsSection>
              <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
                {t('buttons.back')}
              </Styled.GrayButton>
              <Button onClick={handleSubmit(onSubmit)}>{t('buttons.save')}</Button>
            </Styled.ButtonsSection>
          </React.Fragment>
        )}
      </Styled.StepContainer>
    </Styled.FormContainer>
  );
};

export default MobileView;
