import { FC } from 'react';
import { PrimitiveDataFields } from '@innowise-group/core';
import { DiffBaseProps, LabelTransformer, DiffBase } from '../diff-base';
import { useTranslation } from 'react-i18next';

interface PrimitiveFieldsDiff extends Omit<DiffBaseProps, 'labelTransformer'> {
  fieldName: PrimitiveDataFields;
}

const PrimitiveFieldsDiff: FC<PrimitiveFieldsDiff> = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<PrimitiveDataFields> = ({ data }) => data || t('buttons.leaveBlank');
  return <DiffBase labelTransformer={labelTransformer} {...props} />;
};

export default PrimitiveFieldsDiff;
