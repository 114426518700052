import { styled } from '@innowise-group/mui-kit';

type IconContainerProps = {
  size: number;
  disabled?: boolean;
  isPointer?: boolean;
};
export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'inherit')};
  padding: 2px;
  svg {
    flex-shrink: 0;
    &:hover {
      fill: ${({ theme, disabled }) => !disabled && theme.palette.general.darkGrey[80]};
    }
  }
`;
