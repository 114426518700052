import {
  CandidateChangesStatuses,
  FormattedCandidateDiffMerge,
  PrimitiveValuesUnionInCandidatesDiff,
} from '@innowise-group/core';

export class ValidationService {
  private static _validateMapItemField(args: {
    diff: FormattedCandidateDiffMerge;
    source: 'target' | 'updates' | 'preselected' | number;
    key: keyof Pick<FormattedCandidateDiffMerge, 'candidateExperiences' | 'candidateCourses' | 'candidateEducations'>;
  }) {
    const { source, diff, key } = args;
    if (source === 'target' || source === 'updates') {
      return !!diff?.[key]?.[source]?.length;
    }
    return (
      diff?.[key]?.preselected[source]?.comment?.status === CandidateChangesStatuses.Created ||
      diff?.[key]?.preselected[source]?.comment?.status === CandidateChangesStatuses.Updated
    );
  }

  private static _validatePrimitiveFields(args: {
    diff: FormattedCandidateDiffMerge;
    field: PrimitiveValuesUnionInCandidatesDiff;
  }) {
    const { diff, field } = args;
    if (diff?.[field]?.status) {
      if (
        !(
          diff?.[field]?.status === CandidateChangesStatuses.Unchanged ||
          diff?.[field]?.status === CandidateChangesStatuses.Resolved ||
          diff?.[field]?.status === CandidateChangesStatuses.Deleted
        )
      ) {
        return true;
      }
      return false;
    }
  }

  private static _validateStringArrDataField(args: {
    diff: FormattedCandidateDiffMerge;
    field: keyof Pick<
      FormattedCandidateDiffMerge,
      | 'candidateDomains'
      | 'candidateTechnologies'
      | 'candidateWorkFormats'
      | 'candidateWorkloads'
      | 'candidateProfessions'
      | 'candidateLanguages'
      | 'candidatePrioritizedVacancies'
      | 'employmentForms'
    >;
  }) {
    const { field, diff } = args;
    if (
      !(
        diff?.[field]?.status === CandidateChangesStatuses.Resolved ||
        (!diff?.[field]?.target?.length && !diff?.[field]?.updates?.length)
      )
    ) {
      return true;
    }
    return false;
  }

  static validateCandidateContacts(
    diff: FormattedCandidateDiffMerge,
    field: keyof FormattedCandidateDiffMerge['candidateContacts'],
  ) {
    if (diff?.candidateContacts[field]?.status) {
      if (
        !(
          diff?.candidateContacts[field]?.status === CandidateChangesStatuses.Unchanged ||
          diff?.candidateContacts[field]?.status === CandidateChangesStatuses.Resolved ||
          diff?.candidateContacts[field]?.status === CandidateChangesStatuses.Deleted
        )
      ) {
        return true;
      }
      return false;
    }
  }

  static validateCandidateVisas(diff: FormattedCandidateDiffMerge) {
    if (
      diff?.candidateVisas?.status === CandidateChangesStatuses.Created ||
      diff?.candidateVisas?.status === CandidateChangesStatuses.Deleted ||
      diff?.candidateVisas?.status === CandidateChangesStatuses.Updated
    ) {
      return true;
    }
    return false;
  }

  static validateCandidateVisaAvailability(diff: FormattedCandidateDiffMerge) {
    if (
      diff?.visaAvailability?.status === CandidateChangesStatuses.Created ||
      diff?.visaAvailability?.status === CandidateChangesStatuses.Updated ||
      diff?.visaAvailability?.status === CandidateChangesStatuses.Deleted
    ) {
      return true;
    }
    return false;
  }

  static validateIsBlocked(diff: FormattedCandidateDiffMerge) {
    if (
      diff?.isBlocked?.status === CandidateChangesStatuses.Updated ||
      diff?.isBlocked?.status === CandidateChangesStatuses.Deleted ||
      diff?.isBlocked?.status === CandidateChangesStatuses.Created
    ) {
      return true;
    }
    return false;
  }

  static validateCandidateLocation(
    diff: FormattedCandidateDiffMerge,
    field: keyof FormattedCandidateDiffMerge['candidateLocation'],
  ) {
    const status = diff?.candidateLocation?.[field]?.status;
    if (status === CandidateChangesStatuses.Updated || status === CandidateChangesStatuses.Created) {
      return true;
    }
    return false;
  }

  static validateCandidateRelocationAvailability(diff: FormattedCandidateDiffMerge) {
    const relocationStatus = diff?.relocationAvailability?.status;
    if (
      relocationStatus === CandidateChangesStatuses.Created ||
      relocationStatus === CandidateChangesStatuses.Deleted ||
      relocationStatus === CandidateChangesStatuses.Updated
    ) {
      return true;
    }
    return false;
  }

  static validateCandidateRelocationCountries(diff: FormattedCandidateDiffMerge) {
    if (diff?.candidateRelocationCountries?.target?.length || diff?.candidateRelocationCountries?.updates?.length) {
      return true;
    }
    return false;
  }

  static validateCandidateSalary(
    diff: FormattedCandidateDiffMerge,
    key: 'salaryGroup' | 'expectedSalaryMin' | 'expectedSalaryMax' | 'expectedSalaryComment',
  ) {
    if (key === 'expectedSalaryMin') {
      return (
        (diff?.candidateSalary?.expectedSalaryMin?.status === CandidateChangesStatuses.Updated ||
          diff?.candidateSalary?.expectedSalaryMin?.status === CandidateChangesStatuses.Created) &&
        diff?.candidateSalary?.currency?.status === CandidateChangesStatuses.Unchanged
      );
    }
    if (key === 'expectedSalaryMax') {
      return (
        (diff?.candidateSalary?.expectedSalaryMax?.status === CandidateChangesStatuses.Updated ||
          diff?.candidateSalary?.expectedSalaryMax?.status === CandidateChangesStatuses.Created) &&
        diff?.candidateSalary?.currency?.status === CandidateChangesStatuses.Unchanged
      );
    }
    if (key === 'expectedSalaryComment') {
      return (
        diff?.candidateSalary?.expectedSalaryComment?.status === CandidateChangesStatuses.Updated ||
        diff?.candidateSalary?.expectedSalaryMax?.status === CandidateChangesStatuses.Created
      );
    }
    if (key === 'salaryGroup') {
      return (
        diff?.candidateSalary?.currency?.status !== CandidateChangesStatuses.Unchanged &&
        diff?.candidateSalary?.currency?.status !== CandidateChangesStatuses.Resolved
      );
    }
  }

  static validateMapItemField(args: Parameters<typeof this._validateMapItemField>[0]) {
    return this._validateMapItemField(args);
  }

  static validatePrimitiveFields(args: Parameters<typeof this._validatePrimitiveFields>[0]) {
    return this._validatePrimitiveFields(args);
  }

  static validateStringArrDataField(args: Parameters<typeof this._validateStringArrDataField>[0]) {
    return this._validateStringArrDataField(args);
  }
}

export default ValidationService;
