import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import * as Styled from './candidate-deletion-modal.styles';
import { EditCandidateActions, useCandidateChangesSubscription, useCandidatesAPI } from '@innowise-group/core';

interface CandidateDeletionModalProps {
  id: number;
  onSubmit: () => void;
}

const CandidateDeletionModal: React.FC<ModalProps & CandidateDeletionModalProps> = ({ close, onSubmit }) => {
  const { t } = useTranslation();
  const { isLoading } = useCandidatesAPI();
  const { sendMessage } = useCandidateChangesSubscription();

  const onSubmitHandle = () => {
    onSubmit();
    sendMessage(EditCandidateActions.Deleted);
    close();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('pages.candidates.deletionModal.deletionProfile')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Title>{t('pages.candidates.deletionModal.sureDelete')}?</Styled.Title>
        <Styled.Description>{t('pages.candidates.deletionModal.archivedProfile')}</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.delete'),
            onClick: onSubmitHandle,
            highlighted: true,
            type: 'button',
            disabled: isLoading,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(CandidateDeletionModal, 'candidate-deletion');
