import { CandidateStatusColorsItem } from '@constants';
import { styled } from '@innowise-group/mui-kit';
import { InfoTooltip as BaseInfoTooltip } from '../../../info-tooltip';
import { Image } from '@shared-mui-components';

export const SectionContainer = styled.div`
  padding: 0 2rem 0.5rem 2rem;
  gap: 1.5rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const SectionColumnContainer = styled(SectionContainer)`
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem 2rem 0rem 2rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.25rem 1.25rem 0 1.25rem;
  }
`;

export const RowContainer = styled(SectionContainer)`
  align-items: center;
  padding: 0.75rem 2rem;
  gap: 0;
`;

export const OptionalInfoContainer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column-reverse;
  flex: 1;
  & > * {
    width: 100%;
  }
`;

export const CandidateInfoContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 1.25rem;
`;

export const NoImageContainer = styled.div`
  height: 9.375rem;
  width: 9.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

export const Avatar = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  height: 9.375rem;
  width: 9.375rem;
  flex-shrink: 0;
  margin-bottom: 17px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5625rem;
  flex: 1;
  gap: 1rem;
`;

export const StatusItemContainer = styled.div`
  width: 17rem;
  display: flex;
  align-items: center;
  & > *:first-child {
    width: 100%;
  }
`;

export const StatusItem = styled.div<{ statusColors: CandidateStatusColorsItem }>`
  color: ${({ statusColors }) => statusColors?.color};
  border-radius: 0.5rem;
  border: 1px solid ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
  background-color: ${({ statusColors, theme }) =>
    statusColors?.backgroundColor || theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.fonts.primary[40]}
  padding: 0.875rem;
  position: relative;
  line-height: 1;
  height: 1rem;
  box-sizing: content-box;
  margin-top: 10px;
  text-align: center;
  max-width: 100%;
  width: 17rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(0.6) translateX(-40%);
    content: '';
    height: 80%;
    width: 0.75rem;
    background-color: ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
    border-radius: 0px 2rem 2rem 0px;
  }
  margin-bottom: 15px;
`;

export const FullNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.primary[70]}
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
`;

export const DatesBlock = styled(Block)`
  justify-content: end;
`;

export const SectionRowContainer = styled.div`
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 6fr 6fr 5fr 1fr 3fr;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
  & .MuiTextField-root {
    width: 100%;
    input {
      padding-left: 0.75rem;
    }
  }
  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;

export const IconContainer = styled.div``;

export const InfoTooltip = styled(BaseInfoTooltip)`
  margin: 0 0 3px 5px;
`;
