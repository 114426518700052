import { ClaimTypes, DefaultCandidateGeneralStatusId } from '@constants';
import { StatusItem } from '@innowise-group/core';

export const CandidateStatusFormDefaultValues: StatusItem = {
  statusId: DefaultCandidateGeneralStatusId,
  hasReminder: false,
  vacancyId: null,
  hrmChecked: false,
  offerChecked: false,
  hrmProfile: '',
  link: {
    url: '',
    name: '',
  },
  claims: [
    { isActive: false, claimType: ClaimTypes.FEEDBACK, employeeId: '', deadline: null },
    { isActive: false, claimType: ClaimTypes.NOTIFICATION, employeeId: '', deadline: null },
  ],
  agreements: [{ isActive: false, claimType: ClaimTypes.AGREEMENT, employeeId: '', deadline: null }],
  comment: {
    comment: '',
    isProtected: false,
  },
};
