import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import * as Styled from './restore-candidate-modal.styles';
import { useCandidatesAPI } from '@innowise-group/core';

interface RestoreCandidateModalProps {
  onSubmit: () => void;
}

const RestoreCandidateModal: React.FC<ModalProps & RestoreCandidateModalProps> = ({ close, onSubmit }) => {
  const { t } = useTranslation();
  const { isLoading } = useCandidatesAPI();

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('pages.candidates.allCandidatesTable.restoreCandidate')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.candidates.allCandidatesTable.restoreCandidateDescription')}</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.no'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.yes'),
            onClick: onSubmit,
            highlighted: true,
            type: 'button',
            disabled: isLoading,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(RestoreCandidateModal, 'candidate-restore');
