import { styled } from '@innowise-group/mui-kit';

export const OptionWrapper = styled.span``;
export const Value = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
`;
export const Label = styled.span``;
export const HightLighted = styled.span`
  ${({ theme }) => theme.fonts.primary[80]}
`;
