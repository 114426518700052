import { Input, DropdownListItem, AvatarItem } from '@innowise-group/mui-kit';
import { SelectOption } from '@innowise-group/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmployeeSelect } from './employee-field.styles';
import {
  EmployeeFetches,
  EmployeesService,
  useControlFormField,
  useCurrentCandidate,
  useDebounce,
  useStatusConfigById,
  useValidationSchemaCandidateStatus,
} from '@innowise-group/core';

const renderEmployeeListItem = (props, value: SelectOption, { selected }) => {
  return (
    <DropdownListItem key={value.value} {...props} selected={selected}>
      <AvatarItem photoId={value.miniPhotoUrl} name={value.title} isOption />
    </DropdownListItem>
  );
};

interface EmployeeFieldProps {
  index: number;
  claimType: string;
}

const EmployeeField: React.FC<EmployeeFieldProps> = ({ index, claimType }) => {
  const { t } = useTranslation();
  const currentCandidate = useCurrentCandidate();
  const { control, watch, resetField, setValue } = useFormContext();
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);
  const { validationSchema } = useValidationSchemaCandidateStatus(statusConfig, currentCandidate);
  const { handleChangeSelectItem } = useControlFormField(validationSchema);

  const [employeesOptions, setEmployeesOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeValue, setEmployeeValue] = useState<string>('');
  const [debouncedEmployeeValue] = useDebounce<string>(employeeValue, 500);

  const currentValue = watch(`${claimType}[${index}].employeeId`);
  const currentTitle = watch(`${claimType}[${index}].employeeFullName`);

  const handleSearchEmployees = useCallback(
    async (value: string) => {
      setIsLoading(true);
      const now = new Date();
      EmployeeFetches.setLatest(now, index, claimType);
      const response = await EmployeesService.searchEmployees({ partOfName: value });
      const isLast = EmployeeFetches.getLatest(now, index, claimType);
      if (isLast) {
        setEmployeesOptions(
          response.data.content.map((item) => {
            return {
              title: `${item.firstNameEn} ${item.lastNameEn}`,
              value: item.employeeId.toString(),
              miniPhotoUrl: item.photoId?.toString() || null,
            };
          }) || [],
        );
      }
      setIsLoading(false);
    },
    [claimType],
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeValue(event.target.value);
  }, []);

  const transformValue = (value: string) => {
    return employeesOptions?.length ? employeesOptions.find((item) => item.value === value?.toString())?.title : '';
  };

  const handleChange = (onChange: (value: string) => void) => (value: string) => {
    if (!value) setEmployeeValue('');
    setValue('eventDate', null);
    handleChangeSelectItem(onChange, claimType)(value);
    setValue(`${claimType}[${index}].employeeFullName`, transformValue(value));
  };

  const handleBlur = () => {
    if (currentValue) return;
    setEmployeeValue('');
  };

  useEffect(() => {
    if (currentValue && currentTitle) {
      setEmployeeValue(currentTitle);
    }
  }, [currentValue, currentTitle]);

  useEffect(() => {
    handleSearchEmployees(debouncedEmployeeValue);
  }, [debouncedEmployeeValue, handleSearchEmployees]);

  useEffect(() => {
    resetField(`${claimType}[${index}].employeeId`);
  }, [resetField, statusId]);

  return (
    <Controller
      name={`${claimType}[${index}].employeeId`}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => {
        return (
          <EmployeeSelect
            // TODO: change to controller value
            value={currentValue}
            onChange={handleChange(onChange)}
            onBlur={handleBlur}
            disableClearable={!currentValue}
            options={employeesOptions || []}
            renderOption={renderEmployeeListItem}
            loading={isLoading}
            fullWidth
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  fullWidth
                  error={
                    !!(
                      errors?.[claimType]?.[index]?.employeeId?.message ||
                      errors?.[claimType]?.[index]?.message?.toString() ||
                      errors?.[claimType]?.message
                    )
                  }
                  helperText={
                    errors?.[claimType]?.[index]?.employeeId?.message.toString() ||
                    errors?.[claimType]?.[index]?.message?.toString() ||
                    errors?.[claimType]?.message?.toString() ||
                    ' '
                  }
                  onChange={handleInputChange}
                  label={t('pages.candidates.statuses.interestedPerson')}
                  placeholder={t('pages.candidates.statuses.selectAColleague')}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default EmployeeField;
