import { UnitItem } from '@innowise-group/core';
import { Input } from '@innowise-group/mui-kit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './unit-form.styles';

interface UnitFormProps {
  isLoading?: boolean;
}

const UnitForm: React.FC<UnitFormProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<UnitItem>();
  return (
    <React.Fragment>
      <Controller
        name="nameRu"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Input
            value={value}
            onChange={onChange}
            disabled={isLoading}
            label={t('pages.administration.departments.unitName')}
            placeholder={t('pages.administration.statuses.enterTheName')}
            error={!!errors?.nameRu?.message}
            helperText={errors?.nameRu?.message?.toString()}
          />
        )}
      />
      <Controller
        name="nameEn"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Input
            value={value}
            onChange={onChange}
            disabled={isLoading}
            label={`${t('pages.administration.departments.unitName')} (EN)`}
            placeholder={t('pages.administration.statuses.enterTheName')}
            error={!!errors?.nameEn?.message}
            helperText={errors?.nameEn?.message?.toString()}
          />
        )}
      />
      <Controller
        name="isActual"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Styled.Switch
            label={t('pages.administration.departments.showUsers')}
            labelPlacement="end"
            checked={value}
            onChange={onChange}
            disabled={isLoading}
          />
        )}
      />
      {isLoading ? <Styled.Loader size={20} /> : <div />}
    </React.Fragment>
  );
};

export default UnitForm;
