import {
  FileIdResponse,
  StatusItemResponse,
  useCandidateStatusAPI,
  useProfileAPI,
  useResizeObserver,
  useStatusConfigById,
} from '@innowise-group/core';
import React, { useMemo } from 'react';
import * as Styled from './notifications-section.styles';
import { useModal, Divider, useTheme, Icon } from '@innowise-group/mui-kit';
import {
  ClaimActionTypes,
  ClaimStatusTypes,
  ClaimTypes,
  DateFormats,
  FormItemTypes,
  ManagerRolesArray,
  RolesValues,
} from '@constants';
import { FeedbackModal, FilePreviewModal } from '@innowise-group/modals';
import { format } from 'date-fns';
import { calculateDateWithTimeZone } from '@innowise-group/utilities';
import { RoleGuard } from '@shared-components';
import { useTranslation } from 'react-i18next';

interface NotificationsSectionProps {
  status: StatusItemResponse;
  candidateId: number;
}

const NotificationSectionFormItems: string[] = [
  FormItemTypes.FILE,
  FormItemTypes.LINK,
  FormItemTypes.CALENDAR,
  FormItemTypes.REMINDER,
];

const NotificationsSection: React.FC<NotificationsSectionProps> = ({ candidateId, status }) => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const statusConfig = useStatusConfigById(status.status?.id);
  const { resolveClaim } = useCandidateStatusAPI();
  const [showFeedbackModal, hideFeedbackModal] = useModal(FeedbackModal);
  const [showFilePreview] = useModal(FilePreviewModal);

  const isLoading = useMemo(() => status.comment.isLoading, [status]);

  const isEmpty = useMemo(() => {
    return statusConfig.formItems.reduce((accum, curr) => {
      if (NotificationSectionFormItems.includes(curr.item)) {
        return false;
      }
      return accum;
    }, true);
  }, [statusConfig]);

  const handleOpenLink = () => {
    if (status.link?.url) window.open(status.link?.url, '_blank', 'noopener noreferrer');
  };

  const handleOpenFile = () => {
    if (status.file?.filename) {
      showFilePreview({
        fileName: status.file.filename,
        id: status.file.id,
      });
    }
  };

  const { isReminder, isCalendar, reminderDate, eventDate } = useMemo(() => {
    const data = {
      isReminder: !!statusConfig?.formItems.find((item) => item.item === FormItemTypes.REMINDER),
      reminderDate: status.reminderDate,
      isCalendar: !!statusConfig?.formItems.find((item) => item.item === FormItemTypes.CALENDAR),
      eventDate: status.eventDate,
    };
    return data;
  }, [status, statusConfig]);

  const agreementClaim = useMemo(() => {
    const agreementClaim = status.claims.find(
      (item) => item.claimType === ClaimTypes.AGREEMENT && item.active && item.employee?.employeeId === profile.id,
    );
    return agreementClaim;
  }, [profile?.id, status]);

  const { isFeedbackEmployee, feedbackClaim, isDisabled } = useMemo(() => {
    const feedbackClaim = status?.claims.find((item) => item.claimType === ClaimTypes.FEEDBACK && item.active);
    let isDisabled = false;
    if (status?.eventDate) {
      const eventDate = new Date(calculateDateWithTimeZone(status.eventDate));
      eventDate.setHours(0, 0, 0, 0);
      isDisabled = status?.eventDate ? eventDate.getTime() > new Date().getTime() : false;
    }

    return { feedbackClaim, isFeedbackEmployee: feedbackClaim?.employee?.employeeId === profile.id, isDisabled };
  }, [profile?.id, status]);

  const handleFeedback = () => {
    showFeedbackModal({
      status,
      successCallback: successFeedbackCallback,
    });
  };

  const successFeedbackCallback = (file: FileIdResponse) => async () => {
    await resolveClaim(status.id, ClaimActionTypes.FEEDBACK, feedbackClaim.id, file, candidateId);
    hideFeedbackModal();
  };

  const handleResolveClaim = (actionType: ClaimActionTypes, claimId: number) => () => {
    resolveClaim(status.id, actionType, claimId);
  };

  if (isEmpty) return null;
  return (
    <Styled.SectionContainer>
      <Styled.RowContainer>
        <Styled.Block>
          <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}>
            {isFeedbackEmployee &&
              !isDesktopView &&
              (feedbackClaim?.status === ClaimStatusTypes.PENDING ||
                feedbackClaim?.status === ClaimStatusTypes.OVERDUE) && (
                <Styled.FeedBackButton
                  variant="text"
                  onClick={handleFeedback}
                  disabled={isDisabled}
                  startIcon={
                    <Styled.CustomIcon
                      type="u_feedback"
                      viewBox="0 0 24 24"
                      isPointer={!isDisabled}
                      disabled={isDisabled}
                    />
                  }
                >
                  {t('buttons.giveFeedback')}
                </Styled.FeedBackButton>
              )}
          </RoleGuard>
          {status.file?.filename && (
            <Styled.FileItem onClick={handleOpenFile}>
              <span>{status.file.filename}</span>
              {status.file.secured && <Styled.CustomIcon type="u_lock_alt" />}
            </Styled.FileItem>
          )}
          {status.link?.name && (
            <Styled.LinkItem onClick={handleOpenLink}>
              <span>{status.link.name}</span>
              <Styled.CustomIcon type="u_link" />
            </Styled.LinkItem>
          )}
        </Styled.Block>
        <Styled.Block>
          {isReminder && (
            <Styled.DatePickerContainer>
              {reminderDate ? (
                <React.Fragment>
                  <span>{format(calculateDateWithTimeZone(reminderDate), DateFormats.DaySlashFirst)}</span>
                  <span>{format(calculateDateWithTimeZone(reminderDate), DateFormats.OnlyTime)}</span>
                </React.Fragment>
              ) : (
                <span>-</span>
              )}
              <Icon type="u_bell" size={16} viewBox="0 0 22 22" />
            </Styled.DatePickerContainer>
          )}
          {isCalendar && (
            <React.Fragment>
              <Styled.CalendarPickerContainer
                isPending={
                  agreementClaim?.status === ClaimStatusTypes.PENDING ||
                  agreementClaim?.status === ClaimStatusTypes.OVERDUE
                }
              >
                {eventDate ? (
                  <React.Fragment>
                    <span>{format(calculateDateWithTimeZone(eventDate), DateFormats.DaySlashFirst)}</span>
                    <span>{format(calculateDateWithTimeZone(eventDate), DateFormats.OnlyTime)}</span>
                  </React.Fragment>
                ) : (
                  <span>-</span>
                )}
                <Icon type="u_calendar" size={16} viewBox="0 0 22 22" />
              </Styled.CalendarPickerContainer>
              {!isDesktopView &&
                agreementClaim &&
                (agreementClaim.status === ClaimStatusTypes.PENDING ||
                  agreementClaim.status === ClaimStatusTypes.OVERDUE) && (
                  <RoleGuard
                    roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}
                  >
                    <Styled.AgreementButtonsContainer>
                      <Styled.DatePickerContainer>
                        <Styled.ClaimStatusItem
                          disabled={isLoading}
                          onClick={handleResolveClaim(ClaimActionTypes.DECLINE, agreementClaim.id)}
                          variant="text"
                          startIcon={<Styled.CustomIcon type="u_alert_circle" viewBox="0 0 24 24" isPointer />}
                        >
                          {t('buttons.reject')}
                        </Styled.ClaimStatusItem>
                      </Styled.DatePickerContainer>
                      <Styled.DatePickerContainer>
                        <Styled.ClaimStatusItem
                          disabled={isLoading}
                          onClick={handleResolveClaim(ClaimActionTypes.APPROVE, agreementClaim.id)}
                          variant="text"
                          startIcon={<Styled.CustomIcon type="u_check_square" viewBox="0 0 24 24" isPointer />}
                        >
                          {t('buttons.agreeOn')}
                        </Styled.ClaimStatusItem>
                      </Styled.DatePickerContainer>
                    </Styled.AgreementButtonsContainer>
                  </RoleGuard>
                )}
            </React.Fragment>
          )}
        </Styled.Block>
      </Styled.RowContainer>
      <Divider />
    </Styled.SectionContainer>
  );
};

export default NotificationsSection;
