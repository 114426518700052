import { AccordionSummary as InitialAccordionSummary, Icon, styled } from '@innowise-group/mui-kit';

export const AccordionSummary = styled(InitialAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0 2rem;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.fonts.primary[80]}
    width: fit-content;
  }
  & .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
  }
`;

export const ArrowIcon = styled(Icon)<{ isOpen: boolean }>`
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[100]};
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  }
`;
