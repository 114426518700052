import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import { UnitItem } from 'shared/core/store';

export default class UnitsService {
  static async getAllUnits(): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/structuralUnit/all`);
  }

  static async getActualUnits(): Promise<AxiosResponse<UnitItem[]>> {
    return $api.get('/recruiting-tool/api/v1/structuralUnit/actual');
  }

  static async createUnit(data: UnitItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/structuralUnit`, data);
  }

  static async updateUnit(data: UnitItem): Promise<AxiosResponse> {
    return $api.put(`/recruiting-tool/api/v1/structuralUnit`, data);
  }
}
