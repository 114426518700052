import i18n from '@localization';
import { calculateWordDeclension } from './calculate-word-declension.utility';

export const calculateAllExperience = (
  candidateOverallExperienceMonths: number,
  candidateItSphereExperienceMonths: number,
  isFullText = true,
) => {
  const calculate = (data: { years: number; month: number }) => {
    const translationYearKey = calculateWordDeclension('year', data.years);
    const translationMonthKey = calculateWordDeclension('month', data.month);

    return (
      (data.years ? `${data.years} ${i18n.t(`pages.candidates.candidateDetails.${translationYearKey}`)} ` : '') +
      (data.month ? `${data.month} ${i18n.t(`pages.candidates.candidateDetails.${translationMonthKey}`)} ` : '')
    );
  };

  const experienceYears = {
    years: Math.floor(candidateOverallExperienceMonths / 12),
    month: candidateOverallExperienceMonths % 12,
  };
  const experienceITYears = {
    years: Math.floor(candidateItSphereExperienceMonths / 12),
    month: candidateItSphereExperienceMonths % 12,
  };

  const experienceWithoutIT =
    (calculate(experienceYears) || 0) + (isFullText ? i18n.t('pages.candidates.candidateDetails.ofExperience') : '');
  const experienceOnlyIT = (calculate(experienceITYears) || 0) + i18n.t('pages.candidates.candidateDetails.inIT');

  return { experienceWithoutIT, experienceOnlyIT };
};
