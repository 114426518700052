import { createAction } from '@reduxjs/toolkit';

export const clearRequestsOptionsAction = createAction('requests/clearOptions');
export const clearRequestsAction = createAction('requests/clearRequests');
export const clearCurrentRequestsAction = createAction('requests/clearCurrentRequests');
export const clearLinkedCandidatesByRequestAction = createAction<number>('requests/clearLinkedCandidatesByRequest');
// TODO: Normalize implementation <<<BEGIN
// https://innowise-group.atlassian.net/jira/software/projects/ITSRT/boards/1455?selectedIssue=ITSRT-504&text=504
// US-6.2:
// АС-7
export const setTabToOpenAction = createAction<number | null>('requests/setTabToOpen');
export const clearLoggerDataAction = createAction('requests/clearLoggerData');
