import { styled } from '@innowise-group/mui-kit';

export const ModalTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const BodyContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;
