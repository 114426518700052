import { localizedNameObject } from '@innowise-group/utilities';
import {
  HightLightedField,
  SearchRequestResponse,
  SearchCandidateResponse,
  SearchEntities,
  SearchVacancyResponse,
} from './search.types';
import { AppLanguages, AppRoutes } from '@constants';

type Prefix = 'candidate' | 'vacancy' | 'request';

const highlightFieldsMapper = (fields: HightLightedField, prefix: Prefix): HightLightedField => {
  return Object.entries(fields).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [`${prefix}.${key.replace('.ngram', '')}`]: val,
    };
  }, {});
};

const redirectBaseUrlMapper = (prefix: Prefix) => {
  const redirectBaseUrlMap: {
    [key in Prefix]: AppRoutes;
  } = {
    candidate: AppRoutes.Candidates,
    vacancy: AppRoutes.Vacancies,
    request: AppRoutes.Requests,
  };

  return redirectBaseUrlMap[prefix];
};

export const candidateResponseMapper = (
  data: SearchCandidateResponse,
): Pick<SearchCandidateResponse, 'searchHits' | 'empty'> => {
  return {
    ...data,
    searchHits: data.searchHits.map((el) => ({
      ...el,
      title: localizedNameObject(el.content).localizedFullName,
      redirectBaseUrl: redirectBaseUrlMapper('candidate'),
      highlightFields: highlightFieldsMapper(el.highlightFields, 'candidate'),
      entityInfo: {
        redirectUrlBasePath: AppRoutes.Candidates,
        entityId: el.content.id,
        entity: SearchEntities.Candidates,
        title: {
          [AppLanguages.En]: `${el.content.firstNameEn} ${el.content.lastNameEn}`,
          [AppLanguages.Ru]: `${el.content.firstNameRu} ${el.content.lastNameRu}`,
        },
      },
    })),
  };
};

export const vacancyResponseMapper = (
  content: SearchVacancyResponse,
): Pick<SearchVacancyResponse, 'searchHits' | 'empty'> => {
  return {
    ...content,
    searchHits: content.searchHits.map((el) => ({
      ...el,
      title: el.content.name,
      redirectBaseUrl: redirectBaseUrlMapper('vacancy'),
      highlightFields: highlightFieldsMapper(el.highlightFields, 'vacancy'),
      entityInfo: {
        redirectUrlBasePath: AppRoutes.Vacancies,
        entityId: el.content.id,
        entity: SearchEntities.Vacancies,
        title: el.content.name,
      },
    })),
  };
};

export const requestsResponseMapper = (
  content: SearchRequestResponse,
): Pick<SearchRequestResponse, 'searchHits' | 'empty'> => {
  return {
    ...content,
    searchHits: content.searchHits.map((el) => ({
      ...el,
      title: el.id,
      redirectBaseUrl: redirectBaseUrlMapper('request'),
      highlightFields: highlightFieldsMapper(el.highlightFields, 'request'),
      entityInfo: {
        redirectUrlBasePath: AppRoutes.Requests,
        entityId: el.content.id,
        entity: SearchEntities.Requests,
        title: el.content.id,
      },
    })),
  };
};
