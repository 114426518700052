import { LabeledCheckbox, Stepper } from '@shared-mui-components';
import React, { useCallback, useState } from 'react';
import * as Styled from './mobile-view.styles';
import { RequirementsFields, StatusStepsEnum, VacancyFields, steps } from './mobile-view.data';
import { VacancyStep } from '../vacancy-step';
import { RequirementsStep } from '../requirements-step';
import { CommentForm } from '../../../comment-form';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from '@innowise-group/mui-kit';
import { CandidateStatusListItem, StatusItem } from '@innowise-group/core';

interface MobileViewProps {
  photoId?: number;
  localizedFullName: string;
  isEdit?: boolean;
  flags: {
    [key: string]: boolean;
  };
  statusConfig: CandidateStatusListItem;
  handleClose: () => void;
  onSubmit: (data: StatusItem) => void;
  eventId?: number;
}

const MobileView: React.FC<MobileViewProps> = ({ statusConfig, onSubmit, ...props }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<StatusStepsEnum>(StatusStepsEnum.Vacancy);
  const { control, handleSubmit, trigger } = useFormContext();

  const handleNextStep = useCallback(
    (fields: string[]) => async () => {
      const isValidated = await trigger(fields);
      if (isValidated) {
        setActiveStep((prev) => prev + 1);
      }
    },
    [],
  );

  const handlePrevStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  return (
    <Styled.FormContainer>
      <Stepper steps={steps} activeStep={activeStep} />
      <Styled.StepContainer>
        {activeStep === StatusStepsEnum.Vacancy && (
          <VacancyStep handleNextStep={handleNextStep(VacancyFields)} statusConfig={statusConfig} {...props} />
        )}
        {activeStep === StatusStepsEnum.Requirements && (
          <RequirementsStep
            handleNextStep={handleNextStep(RequirementsFields)}
            handlePrevStep={handlePrevStep}
            statusConfig={statusConfig}
            {...props}
          />
        )}
        {activeStep === StatusStepsEnum.Comments && (
          <React.Fragment>
            <CommentForm isThread isStatus />
            <Controller
              name={`comment.isProtected`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <LabeledCheckbox
                  onChange={onChange}
                  checked={value}
                  label={t('pages.candidates.comments.restrictAccess')}
                />
              )}
            />
            <Styled.ButtonsSection>
              <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
                {t('buttons.back')}
              </Styled.GrayButton>
              <Button onClick={handleSubmit(onSubmit)}>{t('buttons.save')}</Button>
            </Styled.ButtonsSection>
          </React.Fragment>
        )}
      </Styled.StepContainer>
    </Styled.FormContainer>
  );
};

export default MobileView;
