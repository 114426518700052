import { StatusItemResponse } from '@innowise-group/core';
import React from 'react';
import * as Styled from './mobile-view.styles';
import { Icon } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { CandidateStatusColors, DateFormats } from '@constants';
import { format } from 'date-fns';

interface MainInfoSectionProps {
  status: StatusItemResponse;
  photoId?: number;
  localizedFirstName: string;
  localizedLastName: string;
}

const MobileView: React.FC<MainInfoSectionProps> = ({ photoId, status, localizedFirstName, localizedLastName }) => {
  const { t } = useTranslation();
  return (
    <Styled.SectionContainer>
      <Styled.CandidateInfoContainer>
        <Styled.CandidateInfo>
          {photoId ? (
            <Styled.Avatar id={photoId} />
          ) : (
            <Styled.NoImageContainer>
              <Icon type="u_camera" size={45} viewBox="0 0 49 44" />
            </Styled.NoImageContainer>
          )}
          <Styled.FullNameContainer>
            <span>{localizedFirstName}</span>
            <span>{localizedLastName}</span>
          </Styled.FullNameContainer>
        </Styled.CandidateInfo>
        <Styled.VacancyContainer>
          <span>{t('pages.candidates.candidateDetails.vacancy')}</span>
          <Styled.VacancyValue>{status.vacancy?.name || '-'}</Styled.VacancyValue>
        </Styled.VacancyContainer>
      </Styled.CandidateInfoContainer>
      <Styled.StatusSection>
        <Styled.StatusRow>
          {status.status.id ? (
            <Styled.StatusContainer
              bgColor={CandidateStatusColors[status.status?.group]?.backgroundColor}
              color={CandidateStatusColors[status.status?.group]?.color}
            >
              <Styled.StatusTitle>
                <span>{status.status.localizedName}</span>
              </Styled.StatusTitle>
              {(status.subStatus?.id || status.vacancyClaim?.id) && (
                <Styled.SubStatusContainer bgColor={CandidateStatusColors[status.status?.group]?.subStatusColor}>
                  {status.subStatus?.localizedName ||
                    (status.vacancyClaim?.id && `${t('pages.requests.request')} №${status.vacancyClaim.id}`)}
                </Styled.SubStatusContainer>
              )}
            </Styled.StatusContainer>
          ) : (
            <div>-</div>
          )}
        </Styled.StatusRow>
        {status.periodFrom && (
          <Styled.PeriodContainer>{`${t('pages.candidates.comments.periodFrom')} ${format(
            new Date(status.periodFrom),
            DateFormats.DaySlashFirst,
          )} ${t('pages.candidates.comments.periodTo')} ${format(
            new Date(status.periodTo),
            DateFormats.DaySlashFirst,
          )}`}</Styled.PeriodContainer>
        )}
      </Styled.StatusSection>
    </Styled.SectionContainer>
  );
};

export default MobileView;
