import { SortingValue } from '@constants';
import { NotificationsState, NotificationTabs } from './notifications.types';

export const preloadedState: NotificationsState = {
  isLoading: false,
  isProcessing: false,
  items: {},
  ids: [],
  unsavedIds: [],
  totalElements: 0,
  totalPages: 0,
  selectedNotifications: [],
  tabs: {
    [NotificationTabs.All]: { id: NotificationTabs.All, name: 'pages.notifications.all', count: 0 },
    [NotificationTabs.Calendar]: { id: NotificationTabs.Calendar, name: 'pages.notifications.calendar', count: 0 },
    [NotificationTabs.RequiresResponse]: {
      id: NotificationTabs.RequiresResponse,
      name: 'pages.notifications.interested',
      count: 0,
    },
    [NotificationTabs.Mention]: { id: NotificationTabs.Mention, name: 'pages.notifications.mentions', count: 0 },
    [NotificationTabs.Responsible]: {
      id: NotificationTabs.Responsible,
      name: 'pages.notifications.responsible',
      count: 0,
    },
    [NotificationTabs.VacancyClaim]: {
      id: NotificationTabs.VacancyClaim,
      name: 'pages.notifications.vacancyClaim',
      count: 0,
    },
  },
  tabsIds: [
    NotificationTabs.All,
    NotificationTabs.Calendar,
    NotificationTabs.RequiresResponse,
    NotificationTabs.Mention,
    NotificationTabs.Responsible,
    NotificationTabs.VacancyClaim,
  ],
  currentTab: NotificationTabs.All,
  sort: SortingValue.CreatedDateDESC,
  pagable: {
    currentPage: 1,
    pageSizes: [
      { title: '10', value: '10' },
      { title: '20', value: '20' },
      { title: '50', value: '50' },
      { title: '70', value: '70' },
      { title: '100', value: '100' },
    ],
    pageSize: 10,
  },
  dndOrder: null,
};
