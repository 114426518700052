import { styled } from '@innowise-group/mui-kit';
import { Input as InputBase } from '@innowise-group/mui-kit';

const GAP_VALUE = '10px';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: ${GAP_VALUE};
  padding: 0 15px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${GAP_VALUE};
  & .MuiTextField-root {
    width: 100%;
    margin-top: 0;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.div`
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[90]};
`;

export const Input = styled(InputBase)`
  & .MuiFormHelperText-root {
    display: none;
  }
  &.MuiTextField-root {
    margin-top: 0;
  }
`;
