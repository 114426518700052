import { styled } from '@innowise-group/mui-kit';

export const SectionPanelContainer = styled.div`
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionName = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  line-height: 24px;
  padding-left: 60px;
  color: ${({ theme }) => theme.palette.state.error.pressed};
`;

export const SectionContent = styled.div`
  padding-left: 22px;
`;
