import { useTheme } from '@innowise-group/mui-kit';
import React, { useState } from 'react';
import * as Styled from './float-button.styles';
import { useResizeObserver } from '@innowise-group/core';

interface FloatButtonProps {
  children: React.ReactNode;
  label: string;
}

const FloatButton: React.FC<FloatButtonProps> = ({ children, label }) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return !isOpen ? (
    <Styled.FloatIcon
      type="u_float_btn"
      viewBox={isDesktopView ? '0 0 48 48' : '0 0 48 48'}
      size={isDesktopView ? 48 : 40}
      onClick={handleOpen}
    />
  ) : (
    <Styled.Container>
      <Styled.Label>
        <span>{label}</span>
        <Styled.CustomIcon type="u_multiply" onClick={handleClose} />
      </Styled.Label>
      {children}
    </Styled.Container>
  );
};

export default FloatButton;
