import { useMemo } from 'react';
import type { FC, PropsWithChildren } from 'react';

import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Wrapper } from './sortable.styles';
import { SortableItemContext } from '../sorable-context';
import { Draggable } from '../draggable';

export const Sortable: FC<PropsWithChildren<{ id: UniqueIdentifier }>> = ({ children, id }) => {
  const { attributes, isDragging, listeners, transform, transition, setNodeRef, setActivatorNodeRef } = useSortable({
    id,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  return (
    <SortableItemContext.Provider value={context}>
      <Draggable>
        <Wrapper transform={transform} transition={transition} isDragging={isDragging} ref={setNodeRef}>
          {children}
        </Wrapper>
      </Draggable>
    </SortableItemContext.Provider>
  );
};
