import { Loader, Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './create-candidate-status-modal.styles';
import {
  CandidateStatusListItem,
  StatusItem,
  useCandidateEventsAPI,
  useCandidateStatusAPI,
  useCandidatesAPI,
  useCurrentCandidate,
  useCurrentCandidateVacancyOptions,
  useEmployeesAPI,
  useResizeObserver,
  useStatusConfigById,
  useValidationSchemaCandidateStatus,
} from '@innowise-group/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CandidateStatusForm } from '@shared-components';
import { CandidateStatusFormDefaultValues, LifecycleStatuses, PrimaryInterviewStatusId } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { useTheme } from '@innowise-group/mui-kit';

interface CreateCandidateStatusModalProps {
  photoId?: number;
  localizedFullName: string;
  candidateId: number;
  handleClose?: () => void;
}

const CreateCandidateStatusModal: React.FC<CreateCandidateStatusModalProps & ModalProps> = ({
  close,
  candidateId,
  handleClose,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { fetchCandidateById } = useCandidatesAPI();
  const { createStatus, isLoading } = useCandidateStatusAPI();
  const vacanciesOptions = useCurrentCandidateVacancyOptions();
  const { fetchMentionedEmployees, mentionedIsLoading: isEmployeeLoading } = useEmployeesAPI();
  const currentCandidate = useCurrentCandidate();
  const { eventsIds, events } = useCandidateEventsAPI();

  const previousVacancyId = useMemo(() => {
    const id = eventsIds.find((item) => events[item].lifecycleStatus === LifecycleStatuses.Actual);
    if (!id) return null;
    if (!events[id].vacancy) return null;
    const isCandidateVacancy = vacanciesOptions.find((item) => item.value === events[id].vacancy.id.toString());
    if (!isCandidateVacancy) return null;
    return events[id].vacancy.id;
  }, [events, eventsIds]);

  const [config, setConfig] = useState<CandidateStatusListItem>();
  const { validationSchema } = useValidationSchemaCandidateStatus(config, currentCandidate);

  const methods = useForm<StatusItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: CandidateStatusFormDefaultValues,
  });

  const { watch, handleSubmit, control, reset, setValue, resetField } = methods;
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);

  const onSubmit = async (data: StatusItem) => {
    const response = await createStatus(data);
    if (response.meta.requestStatus !== 'rejected') {
      await fetchMentionedEmployees(candidateId);
      fetchCandidateById(candidateId);
      close();
    }
  };

  useEffect(() => {
    if (Number(candidateId)) {
      reset({
        ...CandidateStatusFormDefaultValues,
        candidateId: Number(candidateId),
        vacancyId: previousVacancyId || CandidateStatusFormDefaultValues.vacancyId,
      });
    }
  }, [candidateId, previousVacancyId]);

  useEffect(() => {
    setConfig(statusConfig);
    resetField('comment');
    resetField('file');
    // TODO: move to backend side
    if (statusConfig?.id === PrimaryInterviewStatusId) {
      setValue('comment.comment', t('pages.candidates.statuses.primaryInterviewStatusCommentPattern'));
    }
  }, [statusConfig]);

  if (!statusConfig) return null;
  return (
    <Styled.Modal size="standard">
      <Modal.Header onClose={handleClose || close}>
        <Styled.ModalTitle>
          <span>{t('pages.candidates.statuses.addStatus')}</span>
        </Styled.ModalTitle>
      </Modal.Header>
      {isLoading || isEmployeeLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Styled.ModalBody>
            <FormProvider {...methods}>
              <CandidateStatusForm
                {...props}
                candidateId={Number(candidateId)}
                handleClose={handleClose || close}
                onSubmit={onSubmit}
              />
            </FormProvider>
          </Styled.ModalBody>
          {isDesktopView && (
            <Styled.Footer>
              <Controller
                name={`comment.isProtected`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LabeledCheckbox
                    onChange={onChange}
                    checked={value}
                    label={t('pages.candidates.comments.restrictAccess')}
                  />
                )}
              />
              <Styled.ModalActions>
                <Button onClick={handleClose || close} variant="outlined">
                  {t('buttons.cancel')}
                </Button>
                <Button onClick={handleSubmit(onSubmit)}>{t('buttons.save')}</Button>
              </Styled.ModalActions>
            </Styled.Footer>
          )}
        </React.Fragment>
      )}
    </Styled.Modal>
  );
};

export default modalHoc(CreateCandidateStatusModal, 'create-candidate-status-modal');
