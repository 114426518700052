import { Typography } from '../typography';
import { Logo } from '../logo';
import { styled } from '../theme';
import { APP_HEADER_HEIGHT } from '@constants';

export const ApplicationLogo = styled(Logo)`
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.transition};
  &:hover {
    opacity: 0.6;
  }
`;

export const UserMenuItem = styled.li`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  transition: ${({ theme }) => theme.animation.transition};

  &:hover {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[20]};
  }
`;

export const ApplicationBarContainer = styled.nav`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px 0 0;
  min-height: ${APP_HEADER_HEIGHT}px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1305;

  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);

  background-color: ${({ theme }) => theme.palette.general.paper};
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 16.5rem;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 1rem;
    width: auto;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px 0 20px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    gap: 10px;
    justify-content: end;
  }
`;

export const UserPreviewContainer = styled.div`
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
  height: 100%;
  align-items: center;
  transition: ${({ theme }) => theme.animation.transition};
`;

export const UserPosition = styled(Typography)`
  color: ${({ theme }) => theme.palette.general.lightGrey[100]};
  ${({ theme }) => theme.fonts.primary[10]}
`;

export const UserInfoContainer = styled.div`
  padding: 0 20px 0 10px;
`;
