import { Radio as MuiRadio } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root {
    padding: 0;
    width: min-content;
    &.Mui-checked {
      color: ${({ theme, disabled, checked, defaultChecked }) => {
        if (disabled) return theme.palette.general.darkGrey[40];
        return checked || defaultChecked ? theme.palette.general.smart : theme.palette.general.darkGrey[60];
      }};
      svg {
        fill: ${({ theme, disabled, checked, defaultChecked }) => {
          if (disabled) return theme.palette.general.darkGrey[40];
          return checked || defaultChecked ? theme.palette.general.smart : theme.palette.general.darkGrey[60];
        }};
      }
    }
  }
  & svg {
    fill: ${({ theme, disabled }) => {
      if (disabled) return theme.palette.general.darkGrey[40];
    }};
  }
`;
