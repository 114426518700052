import { useAppDispatch, useAppSelector } from '@innowise-group/core';
import { useCallback } from 'react';
import { getSearchState, getHistoryState } from './search.selectors';
import {
  clearSearchAction,
  addHistoryQueryAction,
  addHistoryEntityAction,
  removeHistoryQueryAction,
  removeHistoryEntityAction,
  toggleXrayModeAction,
  manageXrayModeAction,
  addHistoryXrayQueryAction,
  removeHistoryXrayQueryAction,
} from './search.action';
import { searchCandidatesThunk, searchVacanciesThunk, searchRequestsThunk } from './search.thunk';

export const useSearchAPI = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(getSearchState);
  const history = useAppSelector(getHistoryState);

  const clearSearch = useCallback(() => dispatch(clearSearchAction()), [dispatch]);

  const toggleXrayMode = useCallback(() => dispatch(toggleXrayModeAction()), [dispatch]);

  const manageXrayMode = useCallback(
    (...args: Parameters<typeof manageXrayModeAction>) => dispatch(manageXrayModeAction(...args)),
    [dispatch],
  );

  const addHistoryQuery = useCallback(
    (...args: Parameters<typeof addHistoryQueryAction>) => dispatch(addHistoryQueryAction(...args)),
    [dispatch],
  );

  const addHistoryEntity = useCallback(
    (...args: Parameters<typeof addHistoryEntityAction>) => dispatch(addHistoryEntityAction(...args)),
    [dispatch],
  );

  const addHistoryXrayQuery = useCallback(
    (...args: Parameters<typeof addHistoryXrayQueryAction>) => dispatch(addHistoryXrayQueryAction(...args)),
    [dispatch],
  );

  const removeHistoryQuery = useCallback(
    (...args: Parameters<typeof removeHistoryQueryAction>) => dispatch(removeHistoryQueryAction(...args)),
    [dispatch],
  );

  const removeHistoryXrayQuery = useCallback(
    (...args: Parameters<typeof removeHistoryXrayQueryAction>) => dispatch(removeHistoryXrayQueryAction(...args)),
    [dispatch],
  );

  const removeHistoryEntity = useCallback(
    (...args: Parameters<typeof removeHistoryEntityAction>) => dispatch(removeHistoryEntityAction(...args)),
    [dispatch],
  );

  const searchCandidates = useCallback(
    (...args: Parameters<typeof searchCandidatesThunk>) => dispatch(searchCandidatesThunk(...args)),
    [dispatch],
  );

  const searchVacancies = useCallback(
    (...args: Parameters<typeof searchVacanciesThunk>) => dispatch(searchVacanciesThunk(...args)),
    [dispatch],
  );

  const searchRequests = useCallback(
    (...args: Parameters<typeof searchRequestsThunk>) => dispatch(searchRequestsThunk(...args)),
    [dispatch],
  );

  return {
    ...state,
    ...history,
    clearSearch,
    manageXrayMode,
    toggleXrayMode,
    addHistoryQuery,
    addHistoryEntity,
    removeHistoryQuery,
    removeHistoryEntity,
    removeHistoryXrayQuery,
    addHistoryXrayQuery,
    searchRequests,
    searchVacancies,
    searchCandidates,
  };
};
