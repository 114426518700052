import { Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const FormContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
`;

export const ButtonsSection = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  & > * {
    flex: 1;
  }
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;
