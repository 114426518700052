import { styled } from '../theme';

export const RegularText = styled.p`
  ${({ theme }) => theme.fonts.primary[20]}
  margin: 0;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const H3Text = styled(RegularText)`
  font-size: 1rem;
  font-weight: bold;
`;

export const H1Text = styled(RegularText)`
  font-size: 1.625rem;
  font-weight: bold;
`;
