import { styled } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const HightLightedUserName = styled.span`
  ${({ theme }) => theme.fonts.primary[60]}
`;

export const Description = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;
