import lzString from 'lz-string';

export default class CompressService {
  public static compressString(str: string): string {
    return lzString.compressToEncodedURIComponent(str);
  }

  static compressObject(object: object): string {
    return lzString.compressToEncodedURIComponent(JSON.stringify(object));
  }

  static decompressString(compressed: string): string {
    return lzString.decompressFromEncodedURIComponent(compressed);
  }

  static decompressStringAndParseToObject<TObject extends object>(compressed: string): TObject {
    return JSON.parse(lzString.decompressFromEncodedURIComponent(compressed));
  }
}
