import { styled } from '@innowise-group/mui-kit';
import { NumberInput } from '../../number-input';
import { Input } from '@innowise-group/mui-kit';

export const PaginationContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin: 3px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 10px;
    margin-bottom: 5px;
  }
`;

export const InputLabel = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[70]}
  }
`;

export const SelectInput = styled(Input)`
  &.MuiTextField-root {
    margin: 0;
    width: auto;
  }
  &.MuiTextField-root:hover {
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-root {
    min-height: 27px !important;
    padding: 3px 20px 3px 5px !important;
    background-color: ${({ theme }) => theme.palette.state.error.background} !important;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    &.Mui-disabled {
      opacity: 1;
      color: ${({ theme }) => theme.palette.general.smart};
    }
  }
  input {
    color: ${({ theme }) => theme.palette.general.smart};
    -webkit-text-fill-color: ${({ theme }) => theme.palette.general.smart} !important;
    padding: 3px 5px;
    max-width: 60px;
    text-align: center;
    ${({ theme }) => theme.fonts.primary[60]};
    ${({ theme }) => theme.breakpoints.down('sm')} {
      ${({ theme }) => theme.fonts.primary[80]}
    }
  }
  .MuiAutocomplete-endAdornment {
    right: 4px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .MuiAutocomplete-popupIndicator {
    width: 18px;
    height: 18px;
  }
`;

export const PageInput = styled(NumberInput)`
  &.MuiTextField-root {
    margin: 0;
    width: 40px !important;
  }
  &.MuiTextField-root:hover {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 5px;
    min-height: 27px;
    max-width: 40px;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
      }
    }
  }
  input {
    ${({ theme }) => theme.fonts.primary[60]}
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    padding: 3px 5px;
    max-width: 40px;
    text-align: center;
  }
`;
