import { useAppDispatch, useAppSelector } from '@innowise-group/core';
import { getImagesItems } from './images.selectors';
import { useCallback } from 'react';
import { addImageIdAction } from './images.actions';

export const useImagesData = (): { [key: string]: string } => useAppSelector(getImagesItems);

export const useImagesAPI = () => {
  const dispatch = useAppDispatch();
  const images = useImagesData();

  const addImageId = useCallback(
    (id: string, src: string) => {
      dispatch(addImageIdAction({ id, src }));
    },
    [dispatch],
  );

  return { images, addImageId };
};
