import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { NotificationContainer, LoadingFrame, GlobalThemeContextProvider, Portal } from '@innowise-group/mui-kit';
import { ThemeProvider } from '@shared-components';
import App from './app.component';
import './index.css';

import './i18n';
import {
  AuthService,
  InitInterceptors,
  InitBaseUrl,
  store,
  persistor,
  GoogleAnalyticsService,
} from '@innowise-group/core';

InitBaseUrl();
InitInterceptors();
GoogleAnalyticsService.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
AuthService.initKeycloak(() => {
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalThemeContextProvider>
          <ThemeProvider>
            <Suspense fallback={<LoadingFrame />}>
              <Router>
                <App />
                <Portal />
              </Router>
            </Suspense>
            <NotificationContainer />
          </ThemeProvider>
        </GlobalThemeContextProvider>
      </PersistGate>
    </Provider>,
  );
});
