import { styled } from '@innowise-group/mui-kit';
import { ModalContainer } from '@innowise-group/mui-kit';

export const ModalTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Modal = styled(ModalContainer)``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  overflow: hidden auto;
  min-height: 50px;
  box-sizing: border-box;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 1rem;
  }
`;

export const SectionContainer = styled.div`
  padding: 1.5rem 2.5rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
  }
`;
