import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { useStatisticFiltersState } from '../store';

const useSearchParamsState = <TState extends object>(
  initialState?: TState,
): readonly [searchParamsState: TState, setSearchParamsState: (newState: TState) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsState, setSearchParamsState] = useStatisticFiltersState(initialState);

  useEffect(() => {
    const stateFromQuery = qs.parse(searchParams.toString());
    const isExistsStateInQuery = Object.keys(stateFromQuery).length;
    if (isExistsStateInQuery) {
      setSearchParamsStateHandle(stateFromQuery as TState);
    }
  }, []);

  const setSearchParamsStateHandle = (state: TState) => {
    setSearchParams(qs.stringify(state));
    setSearchParamsState(state);
  };

  return [searchParamsState as TState, setSearchParamsStateHandle];
};

export default useSearchParamsState;
