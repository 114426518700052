import { FC } from 'react';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './relocation-diff.styles';
import { useTranslation } from 'react-i18next';

const RelocationDiff: FC = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'relocation'> = ({
    relocationAvailability,
    candidateRelocationCountries,
    readyForBusinessTrip,
  }) => {
    return (
      <Styled.OptionWrapper>
        <Styled.Label>
          {t('pages.candidates.candidateCreation.relocation')}:{' '}
          <Styled.HightLighted>{relocationAvailability ? t('buttons.yes') : t('buttons.no')}</Styled.HightLighted>
        </Styled.Label>
        <Styled.Label>
          {' '}
          {t('pages.candidates.candidateDetails.readyForBusinessTrip')}:{' '}
          <Styled.HightLighted>{readyForBusinessTrip ? t('buttons.yes') : t('buttons.no')}</Styled.HightLighted>
        </Styled.Label>
        <Styled.Value> / {candidateRelocationCountries.map(({ country }) => country.name).join()}</Styled.Value>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase fieldName="relocation" labelTransformer={labelTransformer} {...props} />;
};

export default RelocationDiff;
