import { AccordionDetails as BaseAccordionDetails } from '@mui/material';
import { styled, Button, Icon } from '@innowise-group/mui-kit';

export const Wrapper = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SettingButton = styled(Button)`
  &.MuiButton-root.MuiButton-contained {
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.general.darkGrey[70]};
    &:hover {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;

const baseColorSelect = ({ theme }) => theme.palette.general.darkGrey[60];

export const ExportButton = styled(Button)`
  & .MuiCircularProgress-root {
    margin: 0;
  }
  &.MuiButton-root.MuiButton-outlined {
    margin-left: auto;
    color: ${baseColorSelect};
    border-color: ${baseColorSelect};
    & svg {
      fill: ${baseColorSelect};
    }
    &:hover {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
      color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      & svg {
        fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
      }
    }
  }
`;

export const ArrowIcon = styled(Icon).attrs({
  type: 'u_angle-down',
})<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
`;

export const AccordionDetails = styled(BaseAccordionDetails)`
  display: flex;
  padding: 1.5rem;
  gap: 1.2rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  &.MuiAccordionDetails-root {
    padding: 1rem;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  gap: 1.2rem;
  width: 100%;
  grid-template: repeat(2, 1fr) / repeat(4, 1fr);
`;

export const PeriodText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.fonts.primary[50]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]}
`;

export const ReportsButtonsContainer = styled.div`
  display: grid;
  gap: 1.2rem 0.5rem;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  grid-template-areas:
    'a b'
    'c c';
`;

export const SubmitButton = styled(SettingButton)`
  max-height: 40px;
  grid-area: c;
  &.MuiButton-root.MuiButton-contained {
    justify-content: center;
    &:disabled {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;
