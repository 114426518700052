import { styled } from '@innowise-group/mui-kit';

export const OptionWrapper = styled.div`
  max-width: 350px;
  max-height: 250px;
`;

export const Field = styled.p`
  width: 100%;
  white-space: pre-line;
`;

export const FieldLabel = styled.p`
  width: 100%;
  height: 100%;
  max-height: 100px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
`;

export const FlexContainer = styled.div`
  display: flex;
  word-wrap: break-word;
`;

export const OptionSource = styled.span``;
export const OptionDetails = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
`;
