export default class SessionStorage {
  static getItem<T>(key: string): T | null {
    const item = sessionStorage.getItem(key);
    if (item) return JSON.parse(item);
    return null;
  }

  static setItem(key: string, value) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    return sessionStorage.removeItem(key);
  }
}
