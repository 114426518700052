import { LoadingFrame as BaseLoadingFrame, styled } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  max-width: 550px;
`;

export const BodyContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  max-width: 550px;
  white-space: pre-wrap;
`;

export const LoadingFrame = styled(BaseLoadingFrame)`
  background: ${({ theme }) => theme.palette.general.paper};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
