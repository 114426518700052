import { DateFormats } from '@constants';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from '../dates-range.styles';
import { format } from 'date-fns';
import { CustomDatePicker } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';

interface MobileViewProps {
  minName: string;
  maxName: string;
  disabled: boolean;
}

const MobileView: React.FC<MobileViewProps> = ({ minName, maxName, disabled }) => {
  const { t } = useTranslation();
  const { watch, control } = useFormContext();
  const startDate = watch('dateMin');
  const endDate = watch('dateMax');
  const inputStartValue = startDate ? format(new Date(startDate), DateFormats.DayFirst) : '';
  const inputEndValue = endDate ? format(new Date(endDate), DateFormats.DayFirst) : '';

  const [anchorStartEl, setAnchorStartEl] = useState(null);
  const [anchorEndEl, setAnchorEndEl] = useState(null);

  const onInputStartClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!disabled) {
      setAnchorStartEl(e.target);
    }
  };

  const closeStartFilter = () => setAnchorStartEl(null);

  const onInputEndClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!disabled) {
      setAnchorEndEl(e.target);
    }
  };

  const closeEndFilter = () => setAnchorEndEl(null);

  const minDatePickerProps = useMemo(() => {
    if (new Date().getTime > new Date(endDate).getTime) {
      return {
        maxDate: new Date(),
        maxYear: new Date().getFullYear(),
      };
    }
    return {
      maxDate: new Date(endDate),
      maxYear: new Date(endDate).getFullYear(),
    };
  }, [endDate]);

  return (
    <React.Fragment>
      <Styled.DateInput
        value={inputStartValue}
        onClick={onInputStartClick}
        label={t('pages.candidates.candidateCreation.from')}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: <Styled.CalendarIcon type={'u_calendar'} size={20} />,
        }}
      />
      <Styled.DatePickersWrapper
        onClose={closeStartFilter}
        disablePortal={true}
        anchorEl={anchorStartEl}
        open={!!anchorStartEl}
      >
        <Controller
          name={minName}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <CustomDatePicker
              inline
              name={minName}
              value={value}
              selected={value}
              {...rest}
              {...minDatePickerProps}
              {...{ startDate, endDate }}
            />
          )}
        />
      </Styled.DatePickersWrapper>
      <Styled.DateInput
        label={t('pages.candidates.candidateCreation.to')}
        value={inputEndValue}
        onClick={onInputEndClick}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: <Styled.CalendarIcon type={'u_calendar'} size={20} />,
        }}
      />
      <Styled.DatePickersWrapper
        onClose={closeEndFilter}
        disablePortal={true}
        anchorEl={anchorEndEl}
        open={!!anchorEndEl}
      >
        <Controller
          name={maxName}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <CustomDatePicker
              inline
              name={maxName}
              value={value}
              selected={value}
              {...rest}
              {...{ startDate, endDate }}
              {...{ maxDate: new Date(), maxYear: new Date().getFullYear() }}
              {...{ minDate: startDate, minYear: new Date(startDate).getFullYear() }}
            />
          )}
        />
      </Styled.DatePickersWrapper>
    </React.Fragment>
  );
};

export default MobileView;
