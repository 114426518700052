import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './candidate-form.styles';
import {
  ConditionSection,
  ContactSection,
  EducationSection,
  ExperienceSection,
  LocationSection,
  PersonalSection,
  SectionBlock,
  SkillSection,
} from './components';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@innowise-group/mui-kit';
import { useOptionsApi } from '@innowise-group/core';

interface CandidateFormProps {
  isEdit?: boolean;
  disabled?: boolean;
  actions?: React.ReactNode;
  isDiff?: boolean;
}

const CandidateForm: React.FC<CandidateFormProps> = ({ actions, ...props }) => {
  const { t } = useTranslation();
  const { getStatusAuthorsByRoles } = useOptionsApi();
  const [openedSections, setOpenedSections] = useState<number[]>(Array.from({ length: 6 }, (v, i) => i + 1));

  const sectionsArray = useMemo(
    () => [
      { label: t('pages.candidates.candidateCreation.contactInfoAndFiles'), component: ContactSection },
      {
        label: t('pages.candidates.candidateCreation.technologiesAndLanguages'),
        component: SkillSection,
      },
      { label: t('pages.candidates.candidateCreation.location'), component: LocationSection },
      { label: t('pages.candidates.candidateCreation.workExperience'), component: ExperienceSection },
      { label: t('pages.candidates.candidateCreation.educationAndSkills'), component: EducationSection },
      { label: t('pages.candidates.candidateDetails.workArrangements'), component: ConditionSection },
    ],
    [t],
  );

  const handleOpenAllSections = useCallback(() => {
    setOpenedSections(Array.from({ length: 6 }, (v, i) => i + 1));
  }, []);

  const handleCloseAllSections = useCallback(() => {
    setOpenedSections([]);
  }, []);

  useEffect(() => {
    getStatusAuthorsByRoles();
  }, []);

  return (
    <Styled.ContentContainer>
      <PersonalSection {...props} />
      <Divider />
      {sectionsArray.map(({ label, component }, index) => {
        const Component = component;
        return (
          <React.Fragment key={index}>
            <SectionBlock
              label={label}
              openedSections={openedSections}
              id={index + 1}
              setOpenedSections={setOpenedSections}
            >
              <Component {...props} key={index} />
            </SectionBlock>
            <Divider />
          </React.Fragment>
        );
      })}
      <Styled.FooterSection>
        <div>
          {openedSections.length < 6 && (
            <Button variant="text" onClick={handleOpenAllSections}>
              {t('pages.candidates.candidateCreation.expandAllBlocks')}
            </Button>
          )}
          {openedSections.length >= 1 && (
            <Button variant="text" onClick={handleCloseAllSections}>
              {t('pages.candidates.candidateCreation.collapseAllBlocks')}
            </Button>
          )}
        </div>
        {actions}
      </Styled.FooterSection>
    </Styled.ContentContainer>
  );
};

export default CandidateForm;
