import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import React from 'react';
import { format } from 'date-fns';
import { useReferralAPI, useValidationSchemaExportReferral } from '@innowise-group/core';
import { DateFormats } from '@constants';
import { useTranslation } from 'react-i18next';
import * as Styled from './export-referral.styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@shared-mui-components';

interface ExportItem {
  fromDate: Date | string;
  toDate: Date | string;
}

const ExportReferralModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { exportReferralsData, isExportLoading } = useReferralAPI();

  const { validationSchema } = useValidationSchemaExportReferral();

  const methods = useForm<ExportItem>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: { fromDate: null, toDate: null },
  });

  const { handleSubmit, control, trigger } = methods;

  const handleChangeData = (onChange: (value: Date) => void) => (value: Date) => {
    onChange(value);
    trigger();
  };

  const handleExport = async (data: ExportItem) => {
    const handledToMonth = new Date(data.toDate);
    handledToMonth.setMonth(handledToMonth.getMonth() + 1);
    handledToMonth.setDate(-1);
    await exportReferralsData({
      fromDate: format(new Date(data.fromDate), DateFormats.YearFirst),
      toDate: format(handledToMonth, DateFormats.YearFirst),
    });
    close();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={!isExportLoading ? close : null}>
        <Styled.Header>{t('buttons.export')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Styled.Content>
            {isExportLoading ? (
              <Styled.Loader />
            ) : (
              <React.Fragment>
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field: { value, onChange }, formState: { errors } }) => {
                    return (
                      <Styled.DatePickerContainer>
                        <DatePicker
                          selected={value ? new Date(value) : null}
                          onChange={handleChangeData(onChange)}
                          isConstrained={false}
                          withoutDays
                          label={t('pages.candidates.comments.periodFrom')}
                          placeholder={t('pages.candidates.comments.periodFrom')}
                          error={!!errors?.fromDate?.message}
                          helperText={errors?.fromDate?.message as string}
                        />
                      </Styled.DatePickerContainer>
                    );
                  }}
                />
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field: { value, onChange }, formState: { errors } }) => {
                    return (
                      <Styled.DatePickerContainer>
                        <DatePicker
                          selected={value ? new Date(value) : null}
                          onChange={handleChangeData(onChange)}
                          isConstrained={false}
                          withoutDays
                          label={t('pages.candidates.comments.periodTo')}
                          placeholder={t('pages.candidates.comments.periodTo')}
                          error={!!errors?.toDate?.message}
                          helperText={errors?.toDate?.message as string}
                        />
                      </Styled.DatePickerContainer>
                    );
                  }}
                />
              </React.Fragment>
            )}
          </Styled.Content>
        </FormProvider>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
            disabled: isExportLoading,
          },
          {
            title: t('buttons.approve'),
            onClick: handleSubmit(handleExport),
            type: 'button',
            highlighted: true,
            disabled: isExportLoading,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(ExportReferralModal, 'export-referral');
