import {
  EmployeeAuthDataResponse,
  LocalizedProfileState,
  OrgUnitItem,
  ProfileItem,
  logoutAction,
  useAppDispatch,
  useAppSelector,
} from '@innowise-group/core';
import { useCallback } from 'react';
import {
  createAvatarPhotoThunk,
  getHrmAvatarPhotoThunk,
  getProfileCurrentEventsCountThunk,
  getProfileThunk,
  updateProfileThunk,
} from './profile.thunk';
import { clearProfileAvatarAction, clearProfileDataAction, loadProfileAction } from './profile.actions';
import {
  getDefaultCreationProfileData,
  getDefaultEditionProfileData,
  getProfileDataState,
  getProfileOrgUnits,
} from './profile.selectors';

export const useProfileState = (): LocalizedProfileState => useAppSelector(getProfileDataState);
export const useProfileOrgUnits = (): OrgUnitItem[] => useAppSelector(getProfileOrgUnits);
export const useDefaultCreationProfileData = (): ProfileItem => useAppSelector(getDefaultCreationProfileData);
export const useDefaultEditionProfileData = (): ProfileItem => useAppSelector(getDefaultEditionProfileData);

export const useProfileAPI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, item: profile, avatarFile, currentStatusesCount } = useProfileState();
  const orgUnits = useProfileOrgUnits();

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const fetchCurrentEvents = useCallback(() => {
    return dispatch(getProfileCurrentEventsCountThunk());
  }, [dispatch]);

  const createAvatarPhoto = useCallback(
    (file: File, successCallback?: () => void) => {
      dispatch(createAvatarPhotoThunk({ file, successCallback }));
    },
    [dispatch],
  );

  const clearProfileAvatar = useCallback(() => {
    dispatch(clearProfileAvatarAction());
  }, [dispatch]);

  const fetchProfileData = useCallback(() => {
    dispatch(getProfileThunk());
  }, [dispatch]);

  const fetchHrmAvatarPhoto = useCallback(
    (url: string, successCallback: (file: File) => void) => {
      dispatch(getHrmAvatarPhotoThunk({ url, successCallback }));
    },
    [dispatch],
  );

  const updateProfileData = useCallback(
    (data: ProfileItem, id: number, authData: EmployeeAuthDataResponse, successCallback: () => void) => {
      return dispatch(updateProfileThunk({ data, id, authData, successCallback }));
    },
    [dispatch],
  );

  const clearProfileData = useCallback(() => {
    dispatch(clearProfileDataAction());
  }, [dispatch]);

  const loadProfile = useCallback(() => {
    dispatch(loadProfileAction());
  }, [dispatch]);

  return {
    isLoading,
    profile,
    avatarFile,
    orgUnits,
    currentStatusesCount,
    loadProfile,
    fetchCurrentEvents,
    logout,
    fetchHrmAvatarPhoto,
    createAvatarPhoto,
    clearProfileAvatar,
    fetchProfileData,
    updateProfileData,
    clearProfileData,
  };
};
