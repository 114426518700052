import { SelectOption, useResizeObserver } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import { PaginationProps } from '@mui/material';
import { FC, memo } from 'react';
import { PaginationBarDesktop } from './desktop';
import { PaginationBarMobile } from './mobile';

export interface PaginationBarProps extends Omit<PaginationProps, 'page'> {
  pageSizes: SelectOption[];
  currentPage: number;
  totalElements: number;
  pageSize: number;
  setPagable: (args: { pageSize?: number; currentPage?: number }) => void;
}

const PaginationBar: FC<PaginationBarProps> = (props) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  if (isDesktopView) return <PaginationBarDesktop {...props} />;
  return <PaginationBarMobile {...props} />;
};

export default memo(PaginationBar);
