import { PurposeEnum } from '@constants';
import { VacancyItem } from '@innowise-group/core';

export const vacancyFormDefaultValues: VacancyItem = {
  name: '',
  vacancyStatusId: '',
  cities: [],
  countries: [],
  comment: '',
  vacancyDomains: [],
  vacancyTechnologies: [],
  vacancyLanguages: [{ language: '', languageLevel: '' }],
  vacancyProfessions: [{ profession: '', grade: '' }],
  published: null,
  hiringPurpose: PurposeEnum.Null,
  structuralUnit: '',
};

export enum VacancyStepsEnum {
  Main = 0,
  Optional = 1,
  Comments = 2,
}

export const steps = [
  { title: 'pages.candidates.comments.vacancy', value: VacancyStepsEnum.Main },
  { title: 'pages.candidates.statuses.requirements', value: VacancyStepsEnum.Optional },
  { title: 'pages.candidates.comments.comments', value: VacancyStepsEnum.Comments },
];

export const MainSectionFields = ['name', 'vacancyStatusId', 'hiringPurpose', 'published'];
export const ConditionsSectionFields = [
  'vacancyProfessions',
  'vacancyLanguages',
  'vacancyTechnologies',
  'vacancyDomains',
  'countries',
  'cities',
  'structuralUnitId',
];
