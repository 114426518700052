import { SelectOption } from '@innowise-group/core';
import { VacancyItemResponse } from './vacancies.types';

interface ReturnTypeMapVacancyItemsResponseToSelectsOption extends SelectOption {
  id: string;
}

export const mapVacancyItemsResponseToSelectsOption = (
  items: VacancyItemResponse[],
): ReturnTypeMapVacancyItemsResponseToSelectsOption[] =>
  items.map(({ id, name }) => ({ value: id.toString(), title: name, id: id.toString() }));
