import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import qs from 'qs';

export default class CalendarService {
  static async getMeetings(data: { fromDate: string; toDate: string }): Promise<AxiosResponse> {
    return $api.get('/recruiting-tool/api/v1/meetings', { params: { ...data } });
  }

  static async getTimeSlots(data: {
    employeeIds: number[];
    fromDate: string;
    toDate: string;
    excludeEventId?: number;
  }): Promise<AxiosResponse> {
    return $api.get('/recruiting-tool/api/v1/meetings/timeslots', {
      params: { ...data },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  }

  static async getEventById(id: number): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/candidate-events/${id}`);
  }
}
