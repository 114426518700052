import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  margin: 1.75rem 2rem 0 2rem;
  display: grid;
  column-gap: 1.75rem;
  row-gap: 0.625rem;
  grid-template-columns: 12.5rem 1fr;
  align-items: stretch;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    margin: 1.25rem 1.5rem 0 1.5rem;
    column-gap: 1.25rem;
    row-gap: 0.5rem;
  }
`;

export const FieldsWrapper = styled.div`
  display: grid;
  column-gap: 1.75rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    column-gap: 1.25rem;
    row-gap: 0.25rem;
  }
`;

export const PaddingBlock = styled.div`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;
