import { styled, css } from '@innowise-group/mui-kit';

const FontStyles = css`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 100%;
`;

export const Label = styled.h3`
  ${FontStyles}
  font-weight: 600;
`;

export const ControlsContainer = styled.div`
  ${FontStyles}
  display: flex;
  gap: 20px;
  margin: 20px 0;
`;
