import React from 'react';
import * as Styled from './auth-mobile-view.styles';
import { AppIconsType, Icon, Logo, useTheme } from '@innowise-group/mui-kit';
import { MOBILE_EMOJIS } from '@constants';
import { useTranslation } from 'react-i18next';

interface AuthProps {
  handleSignIn: () => void;
}

const AuthMobileView: React.FC<AuthProps> = ({ handleSignIn }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const color = theme.palette.general.paper;

  return (
    <Styled.Container>
      <Styled.DividersGroup />
      <div>
        <Logo size="large" logoColor={color} />
        <Styled.HeaderText>ITS Recruiting Tool</Styled.HeaderText>
      </div>
      <Styled.AuthButton onClick={handleSignIn}>
        <Icon type="color_google_icon" size={24} viewBox="0 0 26 28" />
        <Styled.AuthButtonText>Continue with Google</Styled.AuthButtonText>
      </Styled.AuthButton>
      <Styled.EmojisContainer>
        {MOBILE_EMOJIS.map((emoji) => (
          <Styled.EmojiContainer key={emoji.name}>
            <Icon type={emoji.name as AppIconsType} size={emoji.size} viewBox={emoji.viewBox}></Icon>
          </Styled.EmojiContainer>
        ))}
      </Styled.EmojisContainer>
      <Styled.Version>
        {t('pages.version')}: {VERSION}
      </Styled.Version>
      <Styled.SupportLinkBlock>
        <Styled.SupportLink
          href="https://innowise-group.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('buttons.support')}
        </Styled.SupportLink>
        <Styled.SupportLinkBackground />
      </Styled.SupportLinkBlock>
    </Styled.Container>
  );
};

export default AuthMobileView;
