import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div``;

export const ChildContainer = styled.div<{ clickable: boolean; disabled: boolean }>`
  position: relative;
  cursor: ${({ clickable, disabled }) =>
    clickable && !disabled ? 'pointer' : clickable && disabled ? 'not-allowed' : 'default'};
`;

export const ChildContainerShield = styled.div<{ show: boolean; canBeHighlighted: boolean }>`
  &:hover {
    background: ${({ theme, canBeHighlighted }) =>
      canBeHighlighted ? theme.palette.state.warning.backgroundSecondary : 'transparent'};
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 18px;
  background: ${({ theme, show }) => (show ? theme.palette.state.error.default : 'transparent')};
  border-radius: 6px;
  transition: background 0.3s;
  opacity: 0.4;
  z-index: 1;
`;
