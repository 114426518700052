import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const DEFAULT_DELAY_VALUE = 500;

const useDebounce = <T>(value: T, delay?: number): [T, Dispatch<SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || DEFAULT_DELAY_VALUE);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return [debouncedValue, setDebouncedValue];
};

export default useDebounce;
