import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const getConsolidationReducer = (state: RootState) => state.consolidation;

export const consolidationStateSelector = createSelector(getConsolidationReducer, (state) => state);

export const unresolvedConflictsSelector = createSelector(consolidationStateSelector, (state) => {
  if (!state.diff) return [];
  return Object.entries(state.diff.result).reduce<string[]>((acc, [key, val]) => {
    if (val.possibleDuplicates.length) {
      return [...acc, key];
    }
    return acc;
  }, []);
});
