import { CandidateItem, LocalizedCandidateItemResponse } from '@innowise-group/core';
import { format } from 'date-fns';
import { ContactsType, DateFormats } from '@constants';

export const cleanCandidateDataFromEmptyEntities = (
  data: Pick<CandidateItem, 'candidateCourses' | 'candidateExperiences' | 'candidateEducations'>,
): CandidateItem => {
  return {
    ...data,
    candidateEducations: data.candidateEducations.reduce((accum, item) => {
      if (item.studyPlace) {
        return [...accum, { ...item }];
      }
      return accum;
    }, []),
    candidateExperiences: data.candidateExperiences.reduce((accum, item) => {
      if (item.workedFrom) {
        return [...accum, { ...item }];
      }
      return accum;
    }, []),
    candidateCourses: data.candidateCourses.reduce((accum, item) => {
      if (item.studyPlace) {
        return [...accum, { ...item }];
      }
      return accum;
    }, []),
  } as CandidateItem;
};

export const candidateDataThunkTransform = (data: CandidateItem, currentCandidate?: LocalizedCandidateItemResponse) => {
  if (data.birthDate) data.birthDate.setHours(23, 59, 59, 999);
  return {
    ...data,
    citizenshipId: data.citizenshipId.map((item) => item.value),
    vacancyIds: data.vacancy.map((item) => item.value),
    candidateProfessions: data.candidateProfessions.reduce((acc, curr) => {
      if (curr.professionId) {
        acc.push({ ...(curr.id ? { id: curr.id } : {}), grade: curr.gradeId || null, professionId: curr.professionId });
      }
      return acc;
    }, []),
    photoId: data.photoId || null,
    attachmentIds: data.filesMeta.map((item) => item.id),
    responsibleEmployee: data.responsibleEmployee,
    candidateLanguages: data.candidateLanguages.reduce((acc, curr) => {
      if (curr.languageId) {
        acc.push({ language: curr.languageId, languageLevel: curr.languageLevelId || null });
      }
      return acc;
    }, []),
    candidateSalary: {
      expectedSalaryMin: data?.candidateSalary?.expectedSalaryMin || '',
      expectedSalaryMax: data?.candidateSalary?.expectedSalaryMax || '',
      currency: data?.candidateSalary?.currencyId,
      expectedSalaryComment: data?.candidateSalary?.expectedSalaryComment || '',
    },
    candidateExperiences: data.candidateExperiences.reduce((accum, item) => {
      if (item.workedFrom) {
        accum.push({
          ...item,
          comment: item.comment || '',
          itSphere: item.itSphere || false,
          workedFrom: format(new Date(item.workedFrom), DateFormats.ISO),
          workedTo: !item.stillWorking && item.workedTo ? format(new Date(item.workedTo), DateFormats.ISO) : '',
        });
      }
      return accum;
    }, []),
    candidateLinks: data.candidateLinks.filter((item) => !!item.name),
    candidateCourses: data.candidateCourses.reduce((accum, item) => {
      if (item.studyPlace) {
        accum.push({
          ...item,
          comment: item.comment || '',
          studiedFrom: item.studiedFrom ? format(new Date(item.studiedFrom), DateFormats.ISO) : '',
          studiedTo: item.studiedTo ? format(new Date(item.studiedTo), DateFormats.ISO) : '',
        });
      }
      return accum;
    }, []),
    candidateEducations: data.candidateEducations.reduce((accum, item) => {
      if (item.studyPlace) {
        accum.push({
          ...item,
          comment: item.comment || '',
          studiedFrom: item.studiedFrom ? format(new Date(item.studiedFrom), DateFormats.ISO) : '',
          studiedTo: item.studiedTo ? format(new Date(item.studiedTo), DateFormats.ISO) : '',
        });
      }
      return accum;
    }, []),
    candidateRelocationCountries: data.candidateRelocationCountries.map((item) => {
      let id;
      if (currentCandidate) {
        const countries = currentCandidate.candidateRelocationCountries;
        id = countries.find((curr) => curr.country.id.toString() === item.value.toString())?.id;
      }
      return { dictionaryValueId: item.value, ...(id && { id }) };
    }),
    candidateTechnologies: data.candidateTechnologies.map((item) => {
      let id;
      if (currentCandidate) {
        const technologies = currentCandidate.candidateTechnologies;
        id = technologies.find((curr) => curr.dictionaryValue.valueId === item.value)?.id;
      }
      return { dictionaryValueId: item.value, ...(id && { id }) };
    }),
    candidateDomains: data.candidateDomains.map((item) => {
      let id;
      if (currentCandidate) {
        const domains = currentCandidate.candidateDomains;
        id = domains.find((curr) => curr.dictionaryValue.valueId === item.value)?.id;
      }
      return { dictionaryValueId: item.value, ...(id && { id }) };
    }),
    candidateWorkFormats: data.candidateWorkFormats.reduce((accum, item) => {
      if (item.checked) {
        accum.push(item.dictionaryValueId);
      }
      return accum;
    }, []),
    employmentForms: data.employmentForms.reduce((accum, item) => {
      if (item.checked) {
        accum.push(item.dictionaryValueId);
      }
      return accum;
    }, []),
    candidateWorkloads: data.candidateWorkloads.reduce((accum, item) => {
      if (item.checked) {
        accum.push(item.dictionaryValueId);
      }
      return accum;
    }, []),
    candidateVisas: data.candidateVisas.reduce((accum, item) => {
      if (item.validTo || item.visaTypeId) {
        const validTo = item.validTo ? new Date(item.validTo) : null;
        if (validTo && !item.isFullVisaDate && validTo.getDate() === 1) {
          validTo.setMonth(validTo.getMonth() + 1);
          validTo.setDate(0);
          validTo.setHours(23, 59, 59, 999);
        } else if (validTo && item.isFullVisaDate) {
          validTo.setHours(23, 59, 59, 999);
        }
        accum.push({
          id: item.id,
          visaTypeId: item.visaTypeId,
          validTo: item.validTo ? format(validTo, DateFormats.ISO) : '',
          isFullVisaDate: item.isFullVisaDate || false,
        });
      }
      return accum;
    }, []),
    candidateContacts: data.candidateContacts.reduce((accum, item) => {
      if (item.contact.value) {
        if (item.contactType === ContactsType.PHONE || item.contactType === ContactsType.ADDITIONAL_PHONE) {
          accum.push({
            id: item.id,
            contactType: item.contactType,
            contact: `+${item.contact.value.replace('+', '')}`,
          });
        } else {
          accum.push({ id: item.id, contactType: item.contactType, contact: item.contact.value });
        }
      }
      return accum;
    }, []),
    birthDate: data.birthDate
      ? format(
          new Date(
            data.birthDate.getTime() > new Date().getTime() ? data.birthDate.setHours(0, 0, 0, 0) : data.birthDate,
          ),
          DateFormats.ISO,
        )
      : '',
  };
};
