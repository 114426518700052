import { FC, useState } from 'react';
import { Outlet as RouterOutlet, useNavigate } from 'react-router-dom';
import { PageLayout, RoleGuard } from '@shared-components';
import { LabeledSwitch } from '@shared-mui-components';
import { Tab, Tabs, Button, Icon, Divider, useTheme } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { AppRoutes, RolesValues } from '@constants';
import * as Styled from './outlet.styles';
import { Collapse, InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MainForm, useResizeObserver, useSearchAPI } from '@innowise-group/core';
import { useSearchRoutes } from './outlet.hook';
import { XrayForm } from './components';
import XRayHistory from './components/x-ray-history/x-ray-history.component';

const Outlet: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [open, setOpen] = useState(true);
  const { toggleXrayMode, activeXrayMode } = useSearchAPI();
  const { register, watch } = useFormContext<MainForm>();

  const tabsUnion = useSearchRoutes();

  const globalSearch = watch('globalSearch');
  const xRaySearch = watch('xRaySearch');

  const query = activeXrayMode ? xRaySearch : globalSearch;

  const currentTab = window.location.pathname;

  const onTabClick = (currentTab: AppRoutes) => () => {
    if (query) return navigate(`${currentTab}?search=${query}`);
    return navigate(currentTab);
  };

  const onBackButtonClick = () => navigate(AppRoutes.Candidates);

  const xraySwitchHandle = () => toggleXrayMode();

  const toggleButtonClick = () => setOpen((prev) => !prev);

  return (
    <PageLayout>
      <Styled.HeaderContainer>
        <Button variant="text" onClick={onBackButtonClick} startIcon={<Styled.CustomIcon type="u_angle-down" />}>
          {t('buttons.back')}
        </Button>
      </Styled.HeaderContainer>
      <Divider />
      <Styled.TopContainer>
        <Styled.CustomInput
          {...register(activeXrayMode ? 'xRaySearch' : 'globalSearch')}
          placeholder={t(
            activeXrayMode ? 'pages.searchPage.xRayInputPlaceholder' : 'pages.searchPage.searchInputPlaceholder',
          )}
          disabled={activeXrayMode}
          fullWidth={activeXrayMode}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon type="u_search" />
              </InputAdornment>
            ),
          }}
        />
        {activeXrayMode && (
          <Styled.ToggleButton variant="text" $collapsed={open} onClick={toggleButtonClick}>
            <Icon type="u_angle-down" />
          </Styled.ToggleButton>
        )}
        {isDesktopView && (
          <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter]}>
            <LabeledSwitch
              label={t('buttons.xRaySwitch')}
              labelPlacement="start"
              checked={activeXrayMode}
              onChange={xraySwitchHandle}
            />
          </RoleGuard>
        )}
      </Styled.TopContainer>
      {activeXrayMode && (
        <Collapse in={open} collapsedSize={0}>
          <XrayForm />
        </Collapse>
      )}
      {activeXrayMode && <XRayHistory />}
      <Tabs value={currentTab}>
        {tabsUnion.map((tab) => (
          <Tab
            key={tab.value}
            label={t(tab.title)}
            value={tab.value}
            tabsvalue={currentTab}
            onClick={onTabClick(tab.value)}
          />
        ))}
      </Tabs>
      <RouterOutlet />
    </PageLayout>
  );
};

export default Outlet;
