import { createReducer } from '@reduxjs/toolkit';
import { preloadedOptionsState } from './options.preloaded-state';
import {
  getAllSelectOptionsThunk,
  getCountrySelectOptionsThunk,
  getCitySelectOptionsThunk,
  addCityToDictionaryThunk,
  getEmployeesByRolesOptionsThunk,
} from './options.thunk';
import { localizedNameObject } from '@innowise-group/utilities';

export const optionsReducer = createReducer(preloadedOptionsState, (builder) =>
  builder
    .addCase(getEmployeesByRolesOptionsThunk.pending, (state) => {
      state.employeesLoading = true;
    })
    .addCase(getEmployeesByRolesOptionsThunk.fulfilled, (state, { payload }) => {
      state.employeesLoading = false;
      state.options = {
        ...state.options,
        statusAuthorsOptions: payload.map(({ id, ...data }) => {
          return {
            title: localizedNameObject(data).localizedFullName,
            value: id.toString(),
            miniPhotoUrl: data.photoRt?.id?.toString() || null,
          };
        }),
      };
    })
    .addCase(getEmployeesByRolesOptionsThunk.rejected, (state) => {
      state.employeesLoading = false;
    })
    .addCase(getAllSelectOptionsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getAllSelectOptionsThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.options = {
        ...payload,
        statusAuthorsOptions: [...(state.options?.statusAuthorsOptions || [])],
        countryOptions: [...(state.options?.countryOptions || [])],
        citiesOptions: [...(state.options?.citiesOptions || [])],
        countriesOptions: [...(state.options?.countriesOptions || [])],
      };
    })
    .addCase(getAllSelectOptionsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(addCityToDictionaryThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(addCityToDictionaryThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.options.citiesOptions = [...state.options.citiesOptions, payload];
    })
    .addCase(addCityToDictionaryThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getCountrySelectOptionsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCountrySelectOptionsThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.options = { ...state.options, ...payload.options, countriesOptions: payload.items };
    })
    .addCase(getCountrySelectOptionsThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getCitySelectOptionsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCitySelectOptionsThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.options = {
        ...state.options,
        citiesOptions: payload.citiesOptions,
        locationOptions: payload.locationOptions,
      };
    })
    .addCase(getCitySelectOptionsThunk.rejected, (state) => {
      state.loading = false;
    }),
);
