import { CandidateEventCommentResponse, useComment } from '@innowise-group/core';
import { CommentText } from '@shared-components';
import React from 'react';
import * as Styled from './comment-section.styles';
import { useTranslation } from 'react-i18next';
import { Icon, useTheme } from '@innowise-group/mui-kit';

interface CommentSectionProps {
  comment: CandidateEventCommentResponse;
}

const CommentSection: React.FC<CommentSectionProps> = ({ comment }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { commentRef, isOpenComment, handleOpenComment } = useComment({
    commentId: comment.id.toString(),
    isProtected: comment.isProtected,
    authorId: comment.author.employeeId,
  });

  const renderCommentWithTags = (text: string, isHightLighted?: boolean) => {
    return (
      <CommentText
        text={text}
        commentRef={commentRef}
        handleOpenComment={handleOpenComment}
        isOpenComment={isOpenComment}
        isTopPosition
        {...(isHightLighted ? { shadowColor: theme.palette.general.darkGrey[20] } : {})}
      />
    );
  };

  return (
    <Styled.CommentContainer isHightLight={comment?.isProtected}>
      <Styled.CommentText>
        {comment?.comment === null ? (
          <Styled.CommentWithIcon>
            {comment?.isProtected && <Icon type="u_lock_alt" />}
            {t('pages.candidates.comments.commentHidden')}
          </Styled.CommentWithIcon>
        ) : comment?.comment ? (
          comment?.comment !== null && (
            <Styled.CommentWithIcon>
              {comment?.isProtected && <Icon type="u_lock_alt" />}
              <Styled.CommentTextContainer>
                {renderCommentWithTags(comment?.comment, comment?.isProtected)}
              </Styled.CommentTextContainer>
            </Styled.CommentWithIcon>
          )
        ) : (
          ''
        )}
      </Styled.CommentText>
    </Styled.CommentContainer>
  );
};

export default CommentSection;
