import { styled } from '@innowise-group/mui-kit';

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  grid-column: span 2;
  margin-bottom: 0.75rem;

  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;
