import * as Styled from './named-section.styles';

export interface NamedSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const NamedSection: React.FC<NamedSectionProps> = ({ title, children, ...props }) => {
  return (
    <Styled.NamedSectionContainer {...props}>
      <Styled.Title>{title}</Styled.Title>
      {children}
    </Styled.NamedSectionContainer>
  );
};

export default NamedSection;
