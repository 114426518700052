import { Icon, styled } from '@innowise-group/mui-kit';

export const SectionRowContainer = styled.div`
  gap: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 1rem;
  }
`;

export const RowContainer = styled(SectionRowContainer)`
  justify-content: end;
  ${({ theme }) => theme.fonts.primary[40]};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const CheckboxInfoContainer = styled.div`
  width: 13.3125rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const DeadlineInfoContainer = styled(RowContainer)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 11.5rem;
  }
  gap: 1rem;
  justify-content: end;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[60]};
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const ExpectedIcon = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -5px;
  background-color: transparent;
  & svg {
    flex-shrink: 0;
  }
`;
