import React from 'react';
import { ChipProps } from '@mui/material';
import { CustomChip } from './blue-chip.styles';

interface ReadOnlyChipProps {
  opened?: boolean;
}

const BlueChip: React.FC<ChipProps & ReadOnlyChipProps> = (props) => {
  return <CustomChip {...props} />;
};

export default React.memo(BlueChip);
