import { Pagination as MuiPagination } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const Pagination = styled(MuiPagination)`
  & .MuiPagination-ul {
    flex-wrap: nowrap;
  }
  .MuiPaginationItem-root {
    ${({ theme }) => theme.fonts.primary[60]}
    min-width: 1.6875rem;
    height: 1.6875rem;
    margin: 0;
    transition: 0.2s;
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    &:hover {
      background-color: ${({ theme }) => theme.palette.state.error.background};
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      min-width: 2.25rem;
      height: 2.25rem;
      ${({ theme }) => theme.fonts.primary[80]}
    }
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.state.error.backgroundSecondary};
    color: ${({ theme }) => theme.palette.general.smart};
    &:hover {
      background-color: ${({ theme }) => theme.palette.state.error.backgroundSecondary};
    }
  }
`;
