import React, { useEffect, useRef, useState } from 'react';
import { NotificationsManager, NotificationInstance } from './manager';
import Notification from './notification.component';
import * as Styled from './notification.styles';
import { useClickOutside, useResizeObserver } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';

export const notificationsManager = new NotificationsManager();

const NotificationContainer: React.FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [collection, setCollection] = useState<NotificationInstance[]>([]);
  const previousCollectionRef = useRef<NotificationInstance[]>();

  const closeNotification = (id: string) => () => {
    notificationsManager.close(id);
  };

  const handleCloseNotifications = () => {
    const difference = collection.filter((item) =>
      previousCollectionRef.current.map((collectionItem) => collectionItem.id).includes(item.id),
    );
    difference.forEach(({ id }) => {
      notificationsManager.close(id);
    });
  };

  const ref = useClickOutside<HTMLDivElement>(handleCloseNotifications, true);

  useEffect(() => {
    previousCollectionRef.current = collection;
  });

  useEffect(() => {
    const difference = collection.filter((item) =>
      previousCollectionRef.current.map((collectionItem) => collectionItem.id).includes(item.id),
    );
    difference.forEach(({ id }) => {
      setTimeout(() => {
        notificationsManager.close(id);
      }, 15000);
    });
  }, [collection]);

  useEffect(() => {
    notificationsManager.setDispatch(setCollection);
  }, [setCollection]);

  return (
    <Styled.NotificationsContainer ref={ref}>
      {isDesktopView
        ? collection.map(({ id, title, subtitle, type }, index) => (
            <Notification
              key={id}
              title={title}
              type={type}
              subtitle={subtitle}
              onClose={closeNotification(id)}
              index={index}
            />
          ))
        : collection
            .reverse()
            .slice(0, 3)
            .map(({ id, title, subtitle, type }, index) => (
              <Notification
                key={id}
                title={title}
                type={type}
                subtitle={subtitle}
                onClose={closeNotification(id)}
                isGrouped
                index={index}
              />
            ))}
    </Styled.NotificationsContainer>
  );
};

export default NotificationContainer;
