import { createReducer } from '@reduxjs/toolkit';
import { preloadedStatisticState } from './statistic.preloaded-state';
import {
  exportStatusesThunk,
  exportRequestsDataThunk,
  exportTeamWorkingThunk,
  exportExitsReportThunk,
  exportSummaryReportThunk,
  getOverallStatusesThunk,
  getStatusesByVacanciesThunk,
  getOverallTeamStatisticThunk,
  getVerticalTeamStatisticByVacancyThunk,
  getHorizontalTeamStatisticByVacancyThunk,
  getPersonalStatisticByVacancyThunk,
  getPersonalOverallStatisticThunk,
  getRequestStatisticThunk,
  getSummaryStatisticThunk,
  getExitsStatisticThunk,
} from './statistic.thunk';
import {
  clearOverallStatusesAction,
  clearStatusesByVacanciesAction,
  clearOverallTeamStatisticAction,
  clearHorizontalTeamStatisticByVacancyAction,
  clearVerticalTeamStatisticByVacancyAction,
  clearPersonalStatisticAction,
  clearRequestStatisticDataAction,
  setFiltersStateAction,
  clearFiltersStateAction,
  clearSummaryStatisticAction,
  clearExitsStatisticsAction,
} from './statistic.action';

export const statisticReducer = createReducer(preloadedStatisticState, (builder) =>
  builder
    .addCase(setFiltersStateAction, (state, { payload }) => {
      state.filtersState = payload;
      state.filtersStateLoaded = true;
    })
    .addCase(clearFiltersStateAction, (state) => {
      state.filtersState = null;
      state.filtersStateLoaded = false;
    })
    .addCase(getRequestStatisticThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.requests = payload;
    })
    .addCase(getRequestStatisticThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getRequestStatisticThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getOverallTeamStatisticThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.overallTeamWorking = payload;
    })
    .addCase(getOverallTeamStatisticThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getOverallTeamStatisticThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getOverallStatusesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.overallStatuses = payload;
    })
    .addCase(getOverallStatusesThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getOverallStatusesThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getSummaryStatisticThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.summary = payload;
    })
    .addCase(getSummaryStatisticThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSummaryStatisticThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(exportStatusesThunk.fulfilled, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportStatusesThunk.pending, (state) => {
      state.isExportLoading = true;
    })
    .addCase(exportStatusesThunk.rejected, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportRequestsDataThunk.fulfilled, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportRequestsDataThunk.pending, (state) => {
      state.isExportLoading = true;
    })
    .addCase(exportRequestsDataThunk.rejected, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportSummaryReportThunk.fulfilled, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportSummaryReportThunk.pending, (state) => {
      state.isExportLoading = true;
    })
    .addCase(exportSummaryReportThunk.rejected, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportExitsReportThunk.fulfilled, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportExitsReportThunk.pending, (state) => {
      state.isExportLoading = true;
    })
    .addCase(exportExitsReportThunk.rejected, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportTeamWorkingThunk.fulfilled, (state) => {
      state.isExportLoading = false;
    })
    .addCase(exportTeamWorkingThunk.pending, (state) => {
      state.isExportLoading = true;
    })
    .addCase(exportTeamWorkingThunk.rejected, (state) => {
      state.isExportLoading = false;
    })
    .addCase(getStatusesByVacanciesThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.pageNum === 1 || !meta.arg.pageNum) {
        state.statusesByVacancies.items = payload.content;
      } else {
        state.statusesByVacancies.items = [...state.statusesByVacancies.items, ...payload.content];
      }
      state.statusesByVacancies.isLoading = false;
      state.statusesByVacancies.totalElements = payload.totalElements;
      state.statusesByVacancies.totalPages = payload.totalPages;
      state.statusesByVacancies.last = payload.last;
    })
    .addCase(getStatusesByVacanciesThunk.pending, (state) => {
      state.statusesByVacancies.isLoading = true;
    })
    .addCase(getStatusesByVacanciesThunk.rejected, (state) => {
      state.statusesByVacancies.isLoading = false;
    })
    .addCase(getVerticalTeamStatisticByVacancyThunk.fulfilled, (state, { meta, payload }) => {
      if (meta.arg.pageNum === 1 || !meta.arg.pageNum) {
        state.verticalTeamWorking.items = payload.content;
      } else {
        state.verticalTeamWorking.items = [...state.verticalTeamWorking.items, ...payload.content];
      }
      state.verticalTeamWorking.isLoading = false;
      state.verticalTeamWorking.totalElements = payload.totalElements;
      state.verticalTeamWorking.totalPages = payload.totalPages;
      state.verticalTeamWorking.last = payload.last;
    })
    .addCase(getVerticalTeamStatisticByVacancyThunk.pending, (state) => {
      state.verticalTeamWorking.isLoading = true;
    })
    .addCase(getVerticalTeamStatisticByVacancyThunk.rejected, (state) => {
      state.verticalTeamWorking.isLoading = false;
    })
    .addCase(getHorizontalTeamStatisticByVacancyThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.pageNum === 1 || !meta.arg.pageNum) {
        state.horizontalTeamWorking.items = payload.content;
      } else {
        state.horizontalTeamWorking.items = [...state.horizontalTeamWorking.items, ...payload.content];
      }
      state.horizontalTeamWorking.isLoading = false;
      state.horizontalTeamWorking.totalElements = payload.totalElements;
      state.horizontalTeamWorking.totalPages = payload.totalPages;
      state.horizontalTeamWorking.last = payload.last;
    })
    .addCase(getHorizontalTeamStatisticByVacancyThunk.pending, (state) => {
      state.horizontalTeamWorking.isLoading = true;
    })
    .addCase(getHorizontalTeamStatisticByVacancyThunk.rejected, (state) => {
      state.horizontalTeamWorking.isLoading = false;
    })
    .addCase(getPersonalStatisticByVacancyThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.pageNum === 1 || !meta.arg.pageNum) {
        state.personalStatistic.items = payload.content;
      } else {
        state.personalStatistic.items = [...state.personalStatistic.items, ...payload.content];
      }
      state.personalStatistic.isLoading = false;
      state.personalStatistic.totalElements = payload.totalElements;
      state.personalStatistic.totalPages = payload.totalPages;
      state.personalStatistic.last = payload.last;
    })
    .addCase(getPersonalStatisticByVacancyThunk.pending, (state) => {
      state.personalStatistic.isLoading = true;
    })
    .addCase(getPersonalStatisticByVacancyThunk.rejected, (state) => {
      state.personalStatistic.isLoading = false;
    })
    .addCase(getPersonalOverallStatisticThunk.fulfilled, (state, { payload }) => {
      state.personalStatistic.overallItems = payload;
      state.isLoading = false;
    })
    .addCase(getPersonalOverallStatisticThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getPersonalOverallStatisticThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getExitsStatisticThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.pageNum === 1 || !meta.arg.pageNum) {
        state.exits.items = payload.content;
      } else {
        state.exits.items = [...state.exits.items, ...payload.content];
      }
      state.exits.isLoading = false;
      state.exits.totalElements = payload.totalElements;
      state.exits.totalPages = payload.totalPages;
      state.exits.last = payload.last;
    })
    .addCase(getExitsStatisticThunk.pending, (state) => {
      state.exits.isLoading = true;
    })
    .addCase(getExitsStatisticThunk.rejected, (state) => {
      state.exits.isLoading = false;
    })
    .addCase(clearOverallStatusesAction, (state) => {
      state.overallStatuses = preloadedStatisticState.overallStatuses;
    })
    .addCase(clearStatusesByVacanciesAction, (state) => {
      state.statusesByVacancies = preloadedStatisticState.statusesByVacancies;
    })
    .addCase(clearOverallTeamStatisticAction, (state) => {
      state.overallTeamWorking = preloadedStatisticState.overallTeamWorking;
    })
    .addCase(clearHorizontalTeamStatisticByVacancyAction, (state) => {
      state.horizontalTeamWorking = preloadedStatisticState.horizontalTeamWorking;
    })
    .addCase(clearVerticalTeamStatisticByVacancyAction, (state) => {
      state.verticalTeamWorking = preloadedStatisticState.verticalTeamWorking;
    })
    .addCase(clearPersonalStatisticAction, (state) => {
      state.personalStatistic = preloadedStatisticState.personalStatistic;
    })
    .addCase(clearRequestStatisticDataAction, (state) => {
      state.requests = preloadedStatisticState.requests;
    })
    .addCase(clearSummaryStatisticAction, (state) => {
      state.summary = preloadedStatisticState.summary;
    })
    .addCase(clearExitsStatisticsAction, (state) => {
      state.exits = preloadedStatisticState.exits;
    }),
);
