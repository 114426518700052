import { styled } from '@innowise-group/mui-kit';

export const VerticalContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  & *::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  & *::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 0.4;
    border: 2px solid ${({ theme }) => theme.palette.general.paper};
    background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & *::-webkit-scrollbar-thumb {
    width: 8px;
    border-left: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-right: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-top: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
    border-bottom: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
    background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
    border-radius: 10px;
  }
  & *::-webkit-scrollbar-button {
    display: none;
  }
`;

export const InformationModalDescription = styled.div`
  white-space: pre-wrap;
  text-align: justify;
`;

export const InformationModalDescriptionHighlight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationModalDescriptionHighlightLink = styled.a`
  color: ${({ theme }) => theme.palette.general.smart};
`;
