import {
  CandidateCourseElement,
  CandidateEducationElement,
  useControlFormField,
  useValidationSchemaCandidate,
} from '@innowise-group/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './education-section.styles';
import { Input } from '@innowise-group/mui-kit';
import { DatePicker } from '@shared-mui-components';
import { MaxCoursesCount, MaxEducationsCount } from '@constants';

interface EducationSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const EducationSection: React.FC<EducationSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { validationSchema } = useValidationSchemaCandidate();
  const { control, watch } = useFormContext();
  const { handleAddItem, handleRemoveItem, handleChangeInputItem, handleChangeDateItem, handleChangeBooleanItem } =
    useControlFormField(validationSchema);
  const educations = watch('candidateEducations');
  const courses = watch('candidateCourses');

  return (
    <React.Fragment>
      <Styled.DiffContainer>
        {isDiff && <Styled.CustomEducationDiff />}
        {!!educations?.length &&
          educations.map((item: CandidateEducationElement, index: number) => (
            <React.Fragment key={index}>
              <Styled.EducationPlaceCell>
                <Controller
                  name={`candidateEducations.${index}.studyPlace`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={t('pages.candidates.candidateCreation.studyPlace')}
                      error={
                        !!(
                          errors.candidateEducations?.[index]?.studyPlace?.message ||
                          errors.candidateEducations?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateEducations?.[index]?.studyPlace?.message ||
                        errors.candidateEducations?.[index]?.message
                      }
                      inputRef={ref}
                      value={value}
                      onChange={handleChangeInputItem(onChange, 'candidateEducations')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.EducationPlaceCell>
              <Styled.PositionCell>
                <Styled.DeleteGroupContainer>
                  {educations.length > 1 && (
                    <Styled.DeleteButton
                      startIcon={<Styled.DeleteIcon type="u_plus" />}
                      disabled={disabled}
                      onClick={handleRemoveItem<CandidateEducationElement>(index, 'candidateEducations', educations)}
                      variant="text"
                    >
                      {t('pages.candidates.candidateCreation.deleteStudyPlace')}
                    </Styled.DeleteButton>
                  )}
                </Styled.DeleteGroupContainer>
                <Controller
                  name={`candidateEducations.${index}.profession`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={t('pages.candidates.candidateCreation.specialty')}
                      error={
                        !!(
                          errors.candidateEducations?.[index]?.profession?.message ||
                          errors.candidateEducations?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateEducations?.[index]?.profession?.message ||
                        errors.candidateEducations?.[index]?.message
                      }
                      value={value}
                      inputRef={ref}
                      onChange={handleChangeInputItem(onChange, 'candidateEducations')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.PositionCell>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateEducations.${index}.studiedFrom`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled}
                      selected={value}
                      inputRef={ref}
                      onChange={handleChangeDateItem(onChange, 'candidateEducations')}
                      label={t('pages.candidates.candidateCreation.period')}
                      error={
                        !!(
                          errors.candidateEducations?.[index]?.studiedFrom?.message ||
                          errors.candidateEducations?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateEducations?.[index]?.studiedFrom?.message ||
                        errors.candidateEducations?.[index]?.message
                      }
                      withoutDays
                      placeholder={t('pages.candidates.candidateCreation.from')}
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateEducations.${index}.studiedTo`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled}
                      selected={value}
                      onChange={handleChangeDateItem(onChange, 'candidateEducations')}
                      withoutDays
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.to')}
                      placeholder={t('pages.candidates.candidateCreation.to')}
                      error={
                        !!(
                          errors.candidateEducations?.[index]?.studiedTo?.message ||
                          errors.candidateEducations?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateEducations?.[index]?.studiedTo?.message ||
                        errors.candidateEducations?.[index]?.message
                      }
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Controller
                name={`candidateEducations.${index}.withoutPeriod`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Styled.CustomCheckbox
                    disabled={disabled}
                    onChange={handleChangeBooleanItem(onChange, 'candidateEducations')}
                    checked={value}
                    label={t('pages.candidates.candidateCreation.withoutPeriod')}
                  />
                )}
              />
              <Styled.CommentCell>
                <Controller
                  name={`candidateEducations.${index}.comment`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      value={value}
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.comment')}
                      onChange={handleChangeInputItem(onChange, 'candidateEducations')}
                      error={!!errors.candidateEducations?.[index]?.comment?.message}
                      helperText={errors.candidateEducations?.[index]?.comment?.message}
                      placeholder={t('pages.candidates.candidateCreation.addAComment')}
                      rows={4}
                      multiline
                    />
                  )}
                />
              </Styled.CommentCell>
            </React.Fragment>
          ))}
      </Styled.DiffContainer>
      {educations?.length < MaxEducationsCount && (
        <Styled.AddButton
          disabled={disabled}
          onClick={handleAddItem('candidateEducations', educations, {
            studyPlace: '',
            profession: '',
            studiedFrom: '',
            studiedTo: '',
            comment: '',
            withoutPeriod: false,
          })}
          variant="text"
          startIcon={<Styled.PlusIcon type="u_plus" />}
        >
          {t('pages.candidates.candidateCreation.addAStudyPlace')}
        </Styled.AddButton>
      )}
      <Styled.DiffContainer>
        {isDiff && <Styled.CustomCoursesDiff />}
        {!!courses?.length &&
          courses.map((item: CandidateCourseElement, index: number) => (
            <React.Fragment key={index}>
              <Styled.EducationPlaceCell>
                <Controller
                  name={`candidateCourses.${index}.studyPlace`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={`${t('pages.candidates.candidateCreation.courses')}/${t(
                        'pages.candidates.candidateCreation.certificate',
                      )} `}
                      error={
                        errors.candidateCourses?.[index]?.studyPlace?.message ||
                        errors.candidateCourses?.[index]?.message
                      }
                      helperText={
                        errors.candidateCourses?.[index]?.studyPlace?.message ||
                        errors.candidateCourses?.[index]?.message
                      }
                      value={value}
                      inputRef={ref}
                      onChange={handleChangeInputItem(onChange, 'candidateCourses')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.EducationPlaceCell>
              <Styled.PositionCell>
                <Styled.DeleteGroupContainer>
                  {courses.length > 1 && (
                    <Styled.DeleteButton
                      startIcon={<Styled.DeleteIcon type="u_plus" />}
                      disabled={disabled}
                      onClick={handleRemoveItem<CandidateCourseElement>(index, 'candidateCourses', courses)}
                      variant="text"
                    >
                      {t('pages.candidates.candidateCreation.deleteCourse')}
                    </Styled.DeleteButton>
                  )}
                </Styled.DeleteGroupContainer>
                <Controller
                  name={`candidateCourses.${index}.courseName`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      label={t('pages.candidates.candidateCreation.name')}
                      error={
                        !!(
                          errors.candidateCourses?.[index]?.courseName?.message ||
                          errors.candidateCourses?.[index]?.message
                        )
                      }
                      inputRef={ref}
                      helperText={
                        errors.candidateCourses?.[index]?.courseName?.message ||
                        errors.candidateCourses?.[index]?.message
                      }
                      value={value}
                      onChange={handleChangeInputItem(onChange, 'candidateCourses')}
                      placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                    />
                  )}
                />
              </Styled.PositionCell>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateCourses.${index}.studiedFrom`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled}
                      selected={value}
                      onChange={handleChangeDateItem(onChange, 'candidateCourses')}
                      label={t('pages.candidates.candidateCreation.period')}
                      error={
                        !!(
                          errors.candidateCourses?.[index]?.studiedFrom?.message ||
                          errors.candidateCourses?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateCourses?.[index]?.studiedFrom?.message ||
                        errors.candidateCourses?.[index]?.message
                      }
                      inputRef={ref}
                      withoutDays
                      placeholder={t('pages.candidates.candidateCreation.from')}
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Styled.DatePickerContainer>
                <Controller
                  name={`candidateCourses.${index}.studiedTo`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <DatePicker
                      disabled={disabled}
                      selected={value}
                      onChange={handleChangeDateItem(onChange, 'candidateCourses')}
                      withoutDays
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.to')}
                      placeholder={t('pages.candidates.candidateCreation.to')}
                      error={
                        !!(
                          errors.candidateCourses?.[index]?.studiedTo?.message ||
                          errors.candidateCourses?.[index]?.message
                        )
                      }
                      helperText={
                        errors.candidateCourses?.[index]?.studiedTo?.message ||
                        errors.candidateCourses?.[index]?.message
                      }
                    />
                  )}
                />
              </Styled.DatePickerContainer>
              <Styled.CommentCell>
                <Controller
                  name={`candidateCourses.${index}.comment`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Input
                      disabled={disabled}
                      fullWidth
                      value={value}
                      label={t('pages.candidates.candidateCreation.comment')}
                      onChange={handleChangeInputItem(onChange, 'candidateCourses')}
                      error={!!errors.candidateCourses?.[index]?.comment?.message}
                      helperText={errors.candidateCourses?.[index]?.comment?.message}
                      placeholder={t('pages.candidates.candidateCreation.addAComment')}
                      rows={4}
                      inputRef={ref}
                      multiline
                    />
                  )}
                />
              </Styled.CommentCell>
            </React.Fragment>
          ))}
      </Styled.DiffContainer>
      {courses?.length < MaxCoursesCount && (
        <Styled.AddButton
          disabled={disabled}
          onClick={handleAddItem('candidateCourses', courses, {
            studyPlace: '',
            courseName: '',
            studiedFrom: '',
            studiedTo: '',
            comment: '',
          })}
          variant="text"
          startIcon={<Styled.PlusIcon type="u_plus" />}
        >
          {t('pages.candidates.candidateCreation.addCourses')}
        </Styled.AddButton>
      )}
      <Styled.CommentCell>
        {isDiff && <Styled.SkillsDiff fieldName="candidateSkills" />}
        <Controller
          name="candidateSkills"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              disabled={disabled}
              label={t('pages.candidates.candidateDetails.skills')}
              value={value}
              onChange={onChange}
              error={!!errors['candidateSkills']?.message}
              helperText={errors['candidateSkills']?.message.toString()}
              placeholder={t('pages.candidates.candidateCreation.addMainSkills')}
              rows={4}
              multiline
              inputRef={ref}
              fullWidth
            />
          )}
        />
      </Styled.CommentCell>
    </React.Fragment>
  );
};

export default React.memo(EducationSection);
