import { ImageFormats, MaxImageSizeInBytes, MaxPhotoSizeInMb } from '@constants';
import * as Styled from './avatar-section.styles';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileIdResponse, useProfileAPI } from '@innowise-group/core';
import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, notificationsManager, Icon } from '@innowise-group/mui-kit';

interface AvatarSectionProps {
  handleChangeIsImageLoading: (value: boolean) => void;
}

const AvatarSection: React.FC<AvatarSectionProps> = ({ handleChangeIsImageLoading }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const preview = watch('miniPhotoUrl');
  const photoRt = watch('photoRt');
  const { createAvatarPhoto, clearProfileAvatar, avatarFile } = useProfileAPI();
  const [photo, setPhoto] = useState<FileIdResponse>();
  const avatarInputRef = useRef<null | HTMLInputElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEditAvatar = () => {
    if (avatarInputRef.current) {
      avatarInputRef.current.click();
      if (anchorEl) {
        setAnchorEl(null);
      }
    }
  };

  const handleDeleteAvatar = () => {
    setValue('photoRt', null);
    setValue('miniPhotoUrl', null);
    setPhoto(null);
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const sizeError = !(event.target.files[0].size < MaxImageSizeInBytes);
      const formatError = !ImageFormats.includes(event.target.files[0].type);
      if (sizeError || formatError) {
        notificationsManager.error({
          subtitle: t('errors.invalidPhotoSize', { count: MaxPhotoSizeInMb }),
        });
      } else {
        createAvatarPhoto(event.target.files[0]);
        handleChangeIsImageLoading(true);
      }
    } else {
      clearProfileAvatar();
    }
  };

  const handleLoadImage = () => {
    handleChangeIsImageLoading(false);
  };

  useEffect(() => {
    if (avatarFile?.id) {
      setPhoto(avatarFile);
    } else {
      setPhoto(photoRt);
    }
  }, [avatarFile, photoRt]);

  useEffect(() => {
    if (photo) {
      setValue('miniPhotoUrl', photo);
    }
  }, [photo]);

  useEffect(() => {
    return () => {
      clearProfileAvatar();
    };
  }, [clearProfileAvatar]);

  return (
    <Styled.AvatarContainer>
      <Styled.Input type="file" ref={avatarInputRef} accept={ImageFormats.join(',')} onChange={handleChangeAvatar} />
      {preview?.id || preview?.url ? (
        <React.Fragment>
          <Styled.Avatar onClick={handlePopoverOpen}>
            <Styled.Image id={preview.id || preview.url} onLoad={handleLoadImage} onError={handleLoadImage} />
          </Styled.Avatar>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handlePopoverClose}
            disablePortal={true}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleEditAvatar}>{t('pages.candidates.candidateDetails.edit')}</MenuItem>
            <MenuItem onClick={handleDeleteAvatar}>{t('pages.candidates.candidateDetails.delete')}</MenuItem>
          </Menu>
        </React.Fragment>
      ) : (
        <Styled.NoImageContainer onClick={handleEditAvatar}>
          <Icon type="u_camera" size={50} viewBox="0 0 49 44" />
        </Styled.NoImageContainer>
      )}
    </Styled.AvatarContainer>
  );
};

export default React.memo(AvatarSection);
