import { styled, Typography } from '@innowise-group/mui-kit';

export const NotFoundContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Image = styled.div<{ background: string }>`
  background-image: url(${({ background }) => background});
  background-size: cover;
  width: 426px;
  height: 262px;
`;

export const Error = styled(Typography)`
  font-size: 2.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[90]};
  margin: 54px 0 10px 0;
`;

export const ErrorMessage = styled(Error)`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  margin: 0;
`;
