import { Dispatch, FC, SetStateAction, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { type DateRangeProps } from '../../../../types';
import { FiltersEntity } from '@innowise-group/core';
import * as Styled from './date-range.styles';
import { ResetButton } from '../reset-button';

interface MobileDateRangeFilterProps extends DateRangeProps {
  id: string;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
  addFilter: (filter: string) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
}

const DateRangeFilter: FC<MobileDateRangeFilterProps> = ({
  id,
  state,
  labelKey,
  addFilter,
  onValueChange,
  removeFilter,
  setOpenedFilter,
}) => {
  const { t } = useTranslation();

  const onFromChange = (from: Date) => {
    addFilter(id);
    if (from < new Date()) onValueChange({ ...state, from: from?.toISOString() });
  };

  const onToChange = (to: Date) => {
    addFilter(id);
    if (to < new Date()) onValueChange({ ...state, to: to?.toISOString() });
  };

  const removeFilterHandle = () => {
    setOpenedFilter('');
    return removeFilter({
      filter: id,
      updatedState: { ...state, from: null, to: null } as unknown as Parameters<typeof removeFilter>[0]['updatedState'],
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>{t(labelKey)}</Styled.Title>
      <Styled.DateRangeContainer>
        <Styled.DatePicker
          InputProps={{ readOnly: true }}
          startDate={state.from ? new Date(state.from) : null}
          endDate={state?.to || state.max ? new Date(state?.to || state.max) : null}
          selected={state.from ? new Date(state.from) : new Date()}
          maxDate={state?.to || state.max ? new Date(state?.to || state.max) : new Date()}
          maxYear={state?.to || state.max ? new Date(state?.to || state.max).getFullYear() : new Date().getFullYear()}
          minDate={state.min && new Date(state.min)}
          minYear={state.min && new Date(state.min).getFullYear()}
          onChange={onFromChange}
          label={`${t('pages.candidates.candidateCreation.dateOfBirth')} (${t('filters.from')})`}
          placeholder={t('pages.candidates.candidateCreation.date')}
        />

        <Styled.DatePicker
          InputProps={{ readOnly: true }}
          selected={state.to ? new Date(state.to) : null}
          maxDate={state.max ? new Date(state.max) : new Date()}
          startDate={state.from ? new Date(state.from) : null}
          endDate={state.to || state.max ? new Date(state.to || state.max) : null}
          minDate={(state?.from || state.min) && new Date(state?.from || state.min)}
          minYear={(state.from || state.min) && new Date(state.from || state.min).getFullYear()}
          maxYear={new Date(state.max).getFullYear() || new Date().getFullYear()}
          onChange={onToChange}
          label={`${t('pages.candidates.candidateCreation.dateOfBirth')} (${t('filters.to')})`}
          placeholder={t('pages.candidates.candidateCreation.date')}
        />
      </Styled.DateRangeContainer>
      <ResetButton onClick={removeFilterHandle}>{t('filters.resetFilter')}</ResetButton>
    </Styled.Wrapper>
  );
};

export default memo(DateRangeFilter);
