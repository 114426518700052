import React from 'react';
import * as Styled from './checkbox.styles';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material';

interface CheckboxProps extends MuiCheckboxProps {
  error?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ error, ...props }) => {
  return <Styled.Checkbox {...props} $error={error} />;
};

export default React.memo(Checkbox);
