import { useMemo } from 'react';
import { darkTheme, useGlobalThemeContext, ThemeProvider, lightTheme } from '@innowise-group/mui-kit';
import { ThemeVariants } from '@constants';

const light = lightTheme();
const dark = darkTheme();

interface ThemeWrapperProps {
  children: React.ReactNode;
}

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const currentTheme = useGlobalThemeContext();

  const theme = useMemo(() => {
    switch (currentTheme.theme) {
      case ThemeVariants.Light:
        return light;
      case ThemeVariants.Dark:
        return dark;
    }
  }, [currentTheme.theme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
