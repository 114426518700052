export enum SortingValue {
  FullNameASC = 'lastNameRu,ASC%firstNameRu,ASC',
  IdASC = 'id,ASC',
  FullNameDESC = 'lastNameRu,DESC%firstNameRu,DESC',
  IdDESC = 'id,DESC',
  Checked = 'checked',
  LastModifiedDateASC = 'lastModifiedDate,ASC',
  LastModifiedDateDESC = 'lastModifiedDate,DESC',
  CreatedDateASC = 'createdDate,ASC',
  CreatedDateDESC = 'createdDate,DESC',
  VacancyNameASC = 'name,ASC',
  VacancyNameDESC = 'name,DESC',
  VacancyPriority = '',
}
