export const calculateYearsOld = (dateOfBirth: string | Date) => {
  if (!dateOfBirth) return 0;
  const nowDate = new Date();
  const birthDate = new Date(dateOfBirth);
  let years = nowDate.getFullYear() - birthDate.getFullYear();
  if (
    nowDate.getMonth() < birthDate.getMonth() ||
    (nowDate.getMonth() == birthDate.getMonth() && nowDate.getDate() < birthDate.getDate())
  ) {
    years--;
  }
  return years;
};
