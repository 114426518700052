import { BaseFilterType, EmployeeFilterType, EmployeesService } from '@innowise-group/core';
import { FC, memo } from 'react';
import { EntityFilter, type GetEntitiesById, type GetEntitiesList } from '../entity';
import { localizedNameObject } from '@innowise-group/utilities';

interface EmployeeFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: EmployeeFilterType['state'];
  onValueChange: (vacancyId: EmployeeFilterType['state']) => void;
}

const EmployeeFilter: FC<EmployeeFilterProps> = (props) => {
  const getEmployeesList: GetEntitiesList = async ({ pageSize, currentPage, searchBarRequest }) => {
    const { data } = await EmployeesService.searchEmployees({
      partOfName: searchBarRequest,
      size: pageSize,
      page: currentPage,
    });
    return {
      ...data,
      content: data.content.map((el) => ({
        ...el,
        id: el.employeeId.toString(),
        name: localizedNameObject(el).localizedFullName,
      })),
    };
  };

  const getEmployeesById: GetEntitiesById = async (id) => {
    const { data } = await EmployeesService.getEmployeeById(id);
    return {
      ...data,
      id: data.id.toString(),
      name: localizedNameObject(data).localizedFullName,
    };
  };

  return <EntityFilter {...props} getEntitiesList={getEmployeesList} getEntityById={getEmployeesById} />;
};

export default memo(EmployeeFilter);
