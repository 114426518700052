import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localizedNameObject } from '@innowise-group/utilities';
import { LocalizedStatusItemResponse } from '../candidates';

export const getCalendarStateSelector = createSelector(
  (state: RootState) => state.calendar,
  (state) => {
    return state;
  },
);

export const getCalendarMeetingsItems = createSelector(getCalendarStateSelector, (state) => {
  return state.meetingsIds.flatMap((id) => {
    return state.meetings[id];
  });
});

export const getCalendarTimeSlots = createSelector(getCalendarStateSelector, (state) => {
  const ranges = state.timeSlots;

  const includedTimes: Date[] = [];
  for (let index = 0; index < ranges.length; index++) {
    const range = ranges[index];
    for (let curr = new Date(range.from); curr.getTime() < new Date(range.to).getTime(); curr) {
      includedTimes.push(new Date(curr));
      curr = new Date(curr.setMinutes(curr.getMinutes() + 15));
    }
  }
  return includedTimes;
});

export const getCurrentMeetState = createSelector(getCalendarStateSelector, (state) => {
  if (!state.currentMeet) return null;
  const meet: LocalizedStatusItemResponse = {
    ...state.currentMeet,
    author: { ...state.currentMeet.author, ...localizedNameObject(state.currentMeet.author) },
    claims: state.currentMeet.claims.map((curr) => {
      return { ...curr, employee: { ...curr.employee, ...localizedNameObject(curr.employee) } };
    }),
    comment: {
      ...state.currentMeet.comment,
      author: {
        ...state.currentMeet.comment.author,
        ...localizedNameObject(state.currentMeet.comment.author),
      },
      commentThread: [],
    },
  };
  return meet;
});
