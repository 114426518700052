import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const MAX_NAME_CHARACTERS = 30;

export const useValidationSchemaAdministrationUnit = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      nameRu: Yup.string()
        .trim()
        .required(t('errors.requiredField'))
        .max(MAX_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_NAME_CHARACTERS })),
      nameEn: Yup.string()
        .required(t('errors.requiredField'))
        .trim()
        .matches(/^[^а-яА-ЯёЁ]+$/, t('errors.invalidFormat'))
        .max(MAX_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_NAME_CHARACTERS })),
      isActual: Yup.boolean().required(t('errors.requiredField')),
    });
  }, [t]);

  return { validationSchema };
};
