import { BaseFilterType, FiltersEntity, MoneyFilterType } from '@innowise-group/core';
import * as Styled from './salary.styles';
import { Dispatch, SetStateAction, forwardRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LabeledRadioButton } from '@shared-mui-components';
import { defaultCurrency } from '@constants';
import { ResetButton } from '../reset-button';

export interface SalaryFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  id: string;
  state: MoneyFilterType['state'];
  onValueChange: (args: Omit<MoneyFilterType['state'], 'currency'> & { currency: string }) => void;
  addFilter: (filter: string) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
}

const SalaryFilter: React.FC<SalaryFilterProps> = forwardRef<HTMLDivElement, SalaryFilterProps>(
  ({ id, state, labelKey, addFilter, removeFilter, onValueChange, setOpenedFilter }, ref) => {
    const { t } = useTranslation();
    const [minInputValue, setMinInputValue] = useState('');
    const [maxInputValue, setMaxInputValue] = useState('');

    const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      addFilter(id);
      onValueChange({ ...state, from: Number(e.target.value) });
      setMinInputValue(e.target.value);
    };
    const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      addFilter(id);
      onValueChange({ ...state, to: Number(e.target.value) });
      setMaxInputValue(e.target.value);
    };

    const onReset = () => {
      setMinInputValue('');
      setMaxInputValue('');
      onValueChange({
        ...state,
        from: state?.variants?.[state?.currency]?.min,
        to: state?.variants?.[state?.currency]?.max,
        currency: defaultCurrency,
      });
    };

    const onMinInputBlur = () => {
      if (!minInputValue) return;
      return setMinInputValue(state.from?.toString());
    };

    const onMaxInputBlur = () => {
      if (!maxInputValue) {
        return;
      }
      return setMaxInputValue(state.to?.toString());
    };

    const onMinInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      addFilter(id);
      if (!e.target.value) {
        setMinInputValue('');
        onValueChange({ ...state, from: state?.variants?.[state?.currency]?.min });
        return;
      }
      if (Number(e.target.value) < state.to && Number(e.target.value) > state?.variants?.[state?.currency]?.min) {
        return onMinChange(e);
      }
      return setMinInputValue(e.target.value);
    };

    const onMaxInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      addFilter(id);
      if (!e.target.value) {
        setMaxInputValue('');
        onValueChange({ ...state, to: state?.variants?.[state?.currency]?.max });
        return;
      }
      if (Number(e.target.value) > state.from && Number(e.target.value) <= state?.variants?.[state?.currency]?.max) {
        return onMaxChange(e);
      }
      return setMaxInputValue(e.target.value);
    };

    const onCurrencyChangeHandler = (currency: string) => () => {
      addFilter(id);
      onValueChange({
        ...state,
        from: state?.variants?.[currency]?.min,
        to: state?.variants?.[currency]?.max,
        currency,
      });
      setMinInputValue(state?.variants?.[currency]?.min);
      setMaxInputValue(state?.variants?.[currency]?.max);
    };

    const onRemoveFilterHandler = () => {
      setOpenedFilter('');
      removeFilter({
        filter: id,
        updatedState: {
          ...state,
          from: state?.variants?.[defaultCurrency]?.min,
          to: state?.variants?.[defaultCurrency]?.max,
          currency: defaultCurrency,
        } as unknown as Parameters<typeof removeFilter>[0]['updatedState'],
      });
    };

    const currentCurrency = state.currency;

    return (
      <Styled.ControlsContainer>
        <Styled.Title>{t(labelKey)}</Styled.Title>
        <Styled.RangePrice>
          <Styled.RangePriceField>
            <Styled.RangePriceLabel htmlFor="min">{t('filters.from')}</Styled.RangePriceLabel>
            <Styled.RangePriceInput
              placeholder={t('filters.from')}
              onChange={onMinInputChangeHandler}
              min={minInputValue}
              value={minInputValue}
              onBlur={onMinInputBlur}
              inputMode="numeric"
              type="number"
              name="min"
            />
          </Styled.RangePriceField>
          <Styled.RangePriceField>
            <Styled.RangePriceLabel htmlFor="max">{t('filters.to')}</Styled.RangePriceLabel>
            <Styled.RangePriceInput
              placeholder={t('filters.to')}
              onChange={onMaxInputChangeHandler}
              max={maxInputValue}
              value={maxInputValue}
              onBlur={onMaxInputBlur}
              inputMode="numeric"
              type="number"
              name="max"
            />
          </Styled.RangePriceField>
        </Styled.RangePrice>
        <Styled.CustomDualSlider
          ref={ref}
          minValue={state.from}
          maxValue={state.to}
          step={state.step}
          minRange={state?.variants?.[currentCurrency]?.min}
          maxRange={state?.variants?.[currentCurrency]?.max}
          onMinChange={onMinChange}
          onMaxChange={onMaxChange}
          onReset={onReset}
        />
        <Styled.CurrencyLabel>{t('pages.candidates.candidateCreation.selectCurrency')}</Styled.CurrencyLabel>
        <Styled.CurrenciesSection>
          {Object.keys(state?.variants)?.map((currency) => (
            <Styled.CurrencyContainer key={currency}>
              <LabeledRadioButton
                value={'false'}
                label={currency}
                labelPlacement="start"
                onChange={onCurrencyChangeHandler(currency)}
                checked={state.currency === currency}
              />
            </Styled.CurrencyContainer>
          ))}
        </Styled.CurrenciesSection>
        <ResetButton onClick={onRemoveFilterHandler}>{t('filters.resetFilter')}</ResetButton>
      </Styled.ControlsContainer>
    );
  },
);

export default memo(SalaryFilter);
