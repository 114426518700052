import { Button, Icon, styled } from '@innowise-group/mui-kit';

export const TogglesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: auto;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.75rem;
  align-items: center;
  margin-left: auto;
`;

export const NotificationButton = styled.button`
  position: relative;
  background: none;
  border: none;
`;

export const CustomIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.palette.general.darkGrey[70],
}))`
  cursor: pointer;
  svg {
    transition: 0.2s;
    flex-shrink: 0;
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.general.smart};
  }
`;

export const NotificationCount = styled.p`
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 3px;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.general.smart};
  color: ${({ theme }) => theme.palette.general.paper};
  ${({ theme }) => theme.fonts.primary[10]}
`;

export const PlusIcon = styled(CustomIcon)`
  cursor: pointer;
  transition: 0.2s;
  border-radius: 3px;
  width: 48px;
  height: 44px;
  padding-bottom: 7px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.state.error.background};
  }
`;

export const SearchIcon = styled(CustomIcon)`
  cursor: pointer;
  transition: 0.2s;
  border-radius: 3px;
  padding-bottom: 7px;
`;

export const SearchButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    min-width: unset;
  }
`;
