import React, { HTMLAttributes, useEffect } from 'react';
import * as Styled from './hover-consumer.styles';
import { useResizeObserver } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';

interface ChildProps {
  role: 'input' | 'button';
  disabled?: boolean;
  disabledDelete?: boolean;
}

interface HoverConsumerProps {
  active: boolean;
  children: React.ReactElement<ChildProps>[];
  WrapperComponent: React.FC<{ children: React.ReactElement[] } & HTMLAttributes<HTMLElement>>;
  deniedIntervalsToChose: number[][];
  onSelectInputRange: (args: Coordinates) => void;
}

interface Coordinates {
  start: number | null;
  end: number | null;
}

interface HoveredChildren {
  hovered: Coordinates;
  selected: Coordinates;
}

type ReactEventHandler = React.MouseEventHandler<HTMLDivElement>;

type MouseHoverHandler = (idx: number) => ReactEventHandler;

const defaultCoordinatesState: HoveredChildren = {
  hovered: {
    start: null,
    end: null,
  },
  selected: {
    start: null,
    end: null,
  },
};

const HoverConsumer: React.FC<HoverConsumerProps> = ({
  active,
  children,
  WrapperComponent,
  deniedIntervalsToChose,
  onSelectInputRange,
}) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [{ hovered, selected }, setHoveredChildren] = React.useState<HoveredChildren>(defaultCoordinatesState);

  useEffect(() => {
    if (!active) setHoveredChildren(defaultCoordinatesState);
  }, [active]);

  useEffect(() => {
    const { start, end } = selected;
    if (start !== null && end !== null) {
      onSelectInputRange(selected);
    }
  }, [selected]);

  const onMouseEnterHandler: MouseHoverHandler = (idx) => () => {
    if (!isDesktopView) return;
    return setHoveredChildren((prev) => ({
      ...prev,
      ...(prev.hovered.start !== null
        ? {
            hovered: {
              ...prev.hovered,
              end: idx,
            },
          }
        : {}),
    }));
  };

  const checkIsDenied = (index: number) =>
    deniedIntervalsToChose.reduce((acc, [minIdx, maxIdx]) => {
      if (index >= minIdx && index <= maxIdx) {
        return true;
      }
      return acc;
    }, false);

  const onChoseChildren: MouseHoverHandler = (index) => () => {
    const isDenied = checkIsDenied(index);
    if (isDenied) return;
    return setHoveredChildren((prev) => ({
      ...prev,
      ...(prev.hovered.start !== null
        ? {
            hovered: {
              ...prev.hovered,
              end: index,
            },
            selected: {
              ...prev.hovered,
              end: index,
            },
          }
        : {
            hovered: {
              ...prev.hovered,
              start: index,
            },
            selected: {
              ...prev.hovered,
              start: index,
            },
          }),
    }));
  };

  if (!active) {
    return <WrapperComponent>{React.Children.map(children, (child) => child)}</WrapperComponent>;
  }

  return (
    <WrapperComponent>
      {React.Children.map(children, (child, index) => {
        const isDenied = checkIsDenied(index);
        return (
          <Styled.ChildContainer
            clickable={child.props.role === 'input'}
            disabled={isDenied}
            onClick={!isDenied ? onChoseChildren(index) : null}
            key={child.key}
            onMouseEnter={onMouseEnterHandler(index)}
          >
            <Styled.ChildContainerShield
              show={
                hovered.start !== null && hovered.start <= index && hovered.end >= index && child.props.role === 'input'
              }
              canBeHighlighted={child.props.role === 'input'}
            />
            {React.cloneElement(child, { disabled: true })}
          </Styled.ChildContainer>
        );
      })}
    </WrapperComponent>
  );
};

export default HoverConsumer;
