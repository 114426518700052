import React from 'react';
import * as Styled from './vacancy-step.styles';
import { Button, Input, Select, Icon } from '@innowise-group/mui-kit';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CandidateStatusListItem,
  SelectOption,
  useAllStatusesOptions,
  useCandidateOffersAPI,
  useCandidateStatusAPI,
} from '@innowise-group/core';
import { SubStatusSection } from '../substatus-section';
import { PeriodSection } from '../period-section';
import { VacancySection } from '../vacancy-section';
import { LinkSection } from '../link-section';
import { FileSection } from '../file-section';
import {
  CandidateStatusColors,
  CandidateStatusGroupEnum,
  LifecycleStatuses,
  StatusLifecycleStatuses,
} from '@constants';

interface VacancyStepProps {
  photoId?: number;
  localizedFullName: string;
  isEdit?: boolean;
  flags: {
    [key: string]: boolean;
  };
  handleNextStep: () => void;
  handleClose: () => void;
  statusConfig: CandidateStatusListItem;
}

const VacancyStep: React.FC<VacancyStepProps> = ({
  handleClose,
  photoId,
  handleNextStep,
  localizedFullName,
  isEdit,
  statusConfig,
  flags,
}) => {
  const { t } = useTranslation();
  const { actualStatusOptions: statusOptions } = useAllStatusesOptions();
  const { items: allStatusesItems, ids: allStatusesIds } = useCandidateStatusAPI();
  const { isLoading: isOffersLoading, ids, items } = useCandidateOffersAPI();

  const { control, watch } = useFormContext();
  const fileId = watch('file.id');
  const isNotEmpty = ids.find((id) => items[id].lifecycleStatus === LifecycleStatuses.Actual);

  const getOptionDisabledHandle = (option: SelectOption) => {
    if (isNotEmpty) return false;
    return allStatusesItems[option.value].group === CandidateStatusGroupEnum.OFFER;
  };

  const offerStatuses = allStatusesIds.reduce((acc, id) => {
    if (
      allStatusesItems[id].group === CandidateStatusGroupEnum.OFFER &&
      allStatusesItems[id].lifecycleStatus !== StatusLifecycleStatuses.Deleted
    ) {
      return acc + `${acc ? ',' : ''} ${allStatusesItems[id].localizedName}`;
    }
    return acc;
  }, '');

  return (
    <React.Fragment>
      <Styled.AvatarContainer>
        {photoId ? (
          <Styled.Avatar id={photoId} />
        ) : (
          <Styled.NoImageContainer>
            <Icon type="u_camera" size={30} viewBox="0 0 49 44" />
          </Styled.NoImageContainer>
        )}
        <Styled.FullNameContainer>
          <span>{localizedFullName}</span>
        </Styled.FullNameContainer>
      </Styled.AvatarContainer>
      {isEdit ? (
        <Styled.StatusItem statusColors={CandidateStatusColors[statusConfig.group]}>
          {statusConfig.localizedName}
        </Styled.StatusItem>
      ) : (
        <Controller
          name="statusId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.Wrapper>
              <Select
                value={value}
                onChange={onChange}
                options={statusOptions}
                disableClearable
                fullWidth
                loading={isOffersLoading}
                getOptionDisabled={getOptionDisabledHandle}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      fullWidth
                      label={t('pages.requests.creationModal.status')}
                      placeholder={t('pages.requests.creationModal.selectStatus')}
                    />
                  );
                }}
              />
              {!isNotEmpty && !isOffersLoading && (
                <Styled.IconContainer>
                  <Styled.InfoTooltip
                    title={t('tooltips.requiredOffer', { statusesStr: offerStatuses })}
                    placement="top-end"
                  />
                </Styled.IconContainer>
              )}
            </Styled.Wrapper>
          )}
        />
      )}
      {flags['isSubStatusField'] && <SubStatusSection />}
      {flags['isPeriodField'] && (
        <Styled.PeriodSection>
          <PeriodSection fieldName="periodFrom" />
          <PeriodSection fieldName="periodTo" />
        </Styled.PeriodSection>
      )}
      {flags['isVacancyField'] && <VacancySection disabled={isEdit} />}
      {(fileId || flags['isFileField'] || flags['isLinkField']) && (
        <Styled.FilesContainer>
          {fileId || flags['isFileField'] ? (
            <FileSection isFeedback={flags['isFeedbackField']} statusConfig={statusConfig} />
          ) : (
            <div />
          )}
          {flags['isLinkField'] && <LinkSection />}
        </Styled.FilesContainer>
      )}
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handleClose} variant="outlined">
          {t('buttons.cancel')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </React.Fragment>
  );
};

export default VacancyStep;
