import React from 'react';

export interface TabPanelProps extends React.HTMLAttributes<HTMLLIElement> {
  value: unknown;
  selectedValue: unknown;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, selectedValue, children }) => {
  return value === selectedValue ? <>{children}</> : null;
};

export default React.memo(TabPanel);
