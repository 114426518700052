export enum RequestStepsEnum {
  Main = 0,
  Optional = 1,
  Comments = 2,
}

export const steps = [
  { title: 'pages.candidates.statuses.requirements', value: RequestStepsEnum.Main },
  { title: 'pages.requests.creationModal.conditions', value: RequestStepsEnum.Optional },
  { title: 'pages.candidates.comments.comments', value: RequestStepsEnum.Comments },
];

export const MainSectionFields = [
  'authorId',
  'vacancyId',
  'vacancyRequestStatusId',
  'requiredCount',
  'cities',
  'countries',
  'offices',
  'hiringDeadline',
  'structuralUnit',
  'asEmployee',
  'onProject',
];
export const ConditionsSectionFields = [
  'expectedSalaryMin',
  'expectedSalaryMax',
  'currency',
  'employmentForms',
  'workFormats',
  'workloads',
];
