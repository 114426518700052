import React from 'react';
import { FormControlLabelProps, RadioProps as MuiRadioProps } from '@mui/material';
import * as Styled from './labeled-radio-button.styles';
import { RadioButton } from '@innowise-group/mui-kit';

type RadioProps = MuiRadioProps & Pick<FormControlLabelProps, 'label' | 'labelPlacement'>;

const LabeledRadioButton: React.FC<RadioProps> = ({ label, labelPlacement, ...props }) => {
  return <Styled.LabelContainer label={label} labelPlacement={labelPlacement} control={<RadioButton {...props} />} />;
};

export default React.memo(LabeledRadioButton);
