import { styled } from '@innowise-group/mui-kit';

export const SectionRowContainer = styled.div`
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 6fr 6fr 5fr 1fr 3fr;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
  & .MuiTextField-root {
    width: 100%;
    input {
      padding-left: 0.75rem;
    }
  }
  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[60]};
`;
