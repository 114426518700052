import * as Styled from './application-bar.styles';

export interface ApplicationBarProps {
  onLogoClick?: () => void;
  children: React.ReactNode;
}

const ApplicationBar: React.FC<ApplicationBarProps> = ({ onLogoClick: handleLogoClick, children }) => {
  return (
    <Styled.ApplicationBarContainer>
      <Styled.LogoContainer onClick={handleLogoClick}>
        <Styled.ApplicationLogo />
      </Styled.LogoContainer>
      <Styled.ActionsContainer>{children}</Styled.ActionsContainer>
    </Styled.ApplicationBarContainer>
  );
};

export default ApplicationBar;
