import { useEffect } from 'react';
import { EditCandidateMode, FormattedCandidateDiffMerge, useCandidateConsolidation, useCandidatesDiff } from '../store';
import { candidateFormDefaultValues } from '@constants';
import { DiffValidationService } from '../services';

const useEmptyDiffEntity = (
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateExperiences' | 'candidateCourses' | 'candidateEducations'>,
) => {
  const { candidatesDiffs } = useCandidatesDiff();
  const { manageCandidateMapData } = useCandidateConsolidation();

  useEffect(() => {
    const noItemsInTarget = !DiffValidationService.validateMapItemField({
      diff: candidatesDiffs,
      source: 'target',
      key: entity,
    });
    const noItemsInUpdates = !DiffValidationService.validateMapItemField({
      diff: candidatesDiffs,
      source: 'updates',
      key: entity,
    });
    if (!candidatesDiffs?.[entity]?.preselected?.length) {
      if (noItemsInTarget && noItemsInUpdates) {
        manageCandidateMapData({
          entity,
          data: candidateFormDefaultValues?.[entity][0],
          mode: EditCandidateMode.Add,
        });
      }
    }
  }, [candidatesDiffs?.[entity]]);
};

export default useEmptyDiffEntity;
