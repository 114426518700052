import { Select } from './components/select';
import { styled, css } from '@innowise-group/mui-kit';
import { Button } from './components/button';
import { Icon } from '../icon';

const ContainerConfig = css<{
  isCustomInput: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
  ${({ isCustomInput, disabled, error }) => {
    if (!isCustomInput)
      return css`
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 10px;
        transition: ${({ theme }) => theme.animation.transition};
        cursor: ${() => (disabled ? 'default' : 'pointer')};
        opacity: ${() => (disabled ? '0.3' : '1')};
        border: 2px solid
          ${({ theme }) => (error ? theme.palette.state.error.default : theme.palette.general.lightGrey[100])};
      `;
  }}
  position: relative;

  & .react-datepicker {
    width: 100%;
    border: 0;
    background-color: ${({ theme }) => theme.palette.general.paper};
    box-shadow: 0px -1px 2px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 8px 12px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 8px 12px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 4px 4px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 1px 4px ${({ theme }) => theme.palette.general.darkGrey[20]};
    border-radius: 4px;
    padding: 10px;
  }
  & .react-datepicker-popper {
    z-index: 3;
    &[data-placement='bottom-start'] {
      top: 18px !important;
    }
    &[data-placement='top-start'] {
      bottom: 18px !important;
    }
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month-container {
    width: 100%;
  }
  & .react-datepicker__header {
    border: none;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.fonts.primary[10]}
    font-weight: bold;
    cursor: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      ${({ theme }) => theme.fonts.primary[20]}
    }
  }
`;

const DatepickerMonth = css`
  margin: 0;
  position: relative;
  top: -0.5px;
  padding: 3px;
`;

const DatepickerSelectedItem = css`
  background-color: ${({ theme }) => theme.palette.state.error.pressed};
  color: ${({ theme }) => theme.palette.general.white} !important;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.general.white} !important;
    background-color: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:focus {
    border: none;
    outline: none;
  }
`;

const DatepickerItem = css`
  ${({ theme }) => theme.fonts.primary[30]}
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  flex: 1;
  margin: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.state.error.background};
  }
`;

export const ContainerTime = styled.div<{ disabled?: boolean; error?: boolean; inline?: boolean }>`
  ${({ inline }) => !inline && ContainerConfig}

  & .react-datepicker__month, & .react-datepicker__day-names {
    display: none;
  }

  & .react-datepicker__time-container {
    overflow-y: auto;
    max-height: 210px;
  }

  & .react-datepicker__time-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 5px;
    min-width: 220px;
    height: auto !important;
  }

  & .react-datepicker__time-list-item {
    display: block;
    ${DatepickerItem}
    line-height: 20px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    margin: 0;
  }
  & .react-datepicker__time-list-item--selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__time-list-item--disabled {
    cursor: default;
    color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    &:hover {
      background-color: inherit;
    }
  }
`;

export const ContainerWithoutDays = styled.div<{
  isCustomInput: boolean;
  disabled?: boolean;
  error?: boolean;
  inline?: boolean;
}>`
  ${({ inline }) => !inline && ContainerConfig}
  & .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & .react-datepicker__day--disabled {
    cursor: not-allowed !important;
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month {
    ${DatepickerMonth}
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  & .react-datepicker__month--selected,
  & .react-datepicker__month--keyboard-selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__month-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .react-datepicker__month-text {
    ${DatepickerItem}
    line-height: 20px;
    width: 46px;
    height: 46px;
  }
`;

export const ContainerFullDate = styled.div<{ disabled?: boolean; error?: boolean; inline?: boolean }>`
  ${({ inline }) => !inline && ContainerConfig}
  & .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & .react-datepicker__day--disabled {
    cursor: not-allowed !important;
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month {
    ${DatepickerMonth}
  }
  & .react-datepicker__week,
  & .react-datepicker__day-names {
    display: flex;
    align-items: center;
  }
  & .react-datepicker__day {
    ${DatepickerItem}
    line-height: 19px;
    width: 36px;
    height: 36px;
  }
  & .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    }
  }
  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__day-name {
    ${({ theme }) => theme.fonts.primary[30]}
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 1;
    margin-top: 5px;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;

export const CalendarHeader = styled.div<{ withoutGap?: boolean }>`
  display: flex;
  gap: ${({ withoutGap }) => (withoutGap ? '5px' : '30px')};
  justify-content: space-between;
`;

export const ChangeBlock = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  display: flex;
  align-items: center;
`;

export const ArrowButton = styled(Button)<{ disabled?: boolean }>`
  width: 15px;
  height: 25px;
  padding: 0;
  margin-right: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

export const Value = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 20px;
  color: ${({ theme }) => theme.palette.state.error.default};
`;

export const IconItem = styled(Icon)<{ isLeft?: boolean; isRight?: boolean; isOpen?: boolean; disabled?: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  transition: 0.2s;
  transform: rotate(${({ isLeft, isRight, isOpen }) => (isOpen ? -90 : isLeft ? 180 : isRight ? 0 : 90)}deg);
  & svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    flex-shrink: 0;
  }
  &:hover svg {
    fill: ${({ theme, disabled }) => theme.palette.general.darkGrey[disabled ? 80 : 100]};
  }
`;

export const DropdownListItem = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  padding: 0 9px 0 10px;
  align-items: center;
  min-height: 30px;
  width: 100%;
  ${({ theme }) => theme.fonts.primary[10]}
  font-weight: 600;
  line-height: 24px;
  user-select: 'initial';
  transition: 0.2s;
  overflow: hidden;
  color: ${({ theme, selected, disabled }) =>
    disabled
      ? theme.palette.general.darkGrey[40]
      : selected
      ? theme.palette.general.white
      : theme.palette.general.darkGrey[100]};
  border-radius: 4px;
  background-color: ${({ theme, selected }) => (selected ? theme.palette.state.error.pressed : 'initial')};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background-color: ${({ theme, selected, disabled }) =>
      disabled ? 'inherit' : selected ? theme.palette.state.error.default : theme.palette.general.lightGrey[60]};
  }
  &:last-child {
    margin: 0;
  }
`;

export const CustomSelect = styled(Select)`
  margin-right: 5px;
  width: auto !important;

  & > div {
    border: none;
    width: min-content;
    padding: 0 10px;
    background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
    & > div:first-child {
      width: ${({ size }) => (size === 'extra-small' ? '15px' : size === 'small' ? '35px' : '70px')} !important;
      & > div {
        right: -5px;
      }
    }
  }
  & > div:first-child {
    padding: 0 30px 0 10px;
    background-color: transparent;
  }
  & svg {
    flex-shrink: 0;
  }
  & input {
    ${({ theme }) => theme.fonts.primary[30]}
    line-height: 19px;
    width: ${({ size }) => (size === 'extra-small' ? '15px' : size === 'small' ? '35px' : '70px')} !important;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;

export const CalendarIcon = styled(Icon)`
  position: absolute;
  right: -3px;
`;
