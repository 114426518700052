import { FC } from 'react';
import { format } from 'date-fns';
import { es, ru } from 'date-fns/locale';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './courses-diff.styles';
import { AppLanguages, DateFormats } from '@constants';
import { getLanguageFromLS } from '@innowise-group/utilities';

const CoursesDiff: FC = (props) => {
  const ln = getLanguageFromLS();
  const locale = ln === AppLanguages.En ? es : ru;
  const labelTransformer: LabelTransformer<'candidateCourses'> = ({
    studiedFrom,
    studiedTo,
    studyPlace,
    courseName,
    comment,
  }) => {
    const workedFromDate = studiedFrom ? format(new Date(studiedFrom), DateFormats.LongLocalizedDate, { locale }) : '';
    const workedToDate = studiedTo ? format(new Date(studiedTo), DateFormats.LongLocalizedDate, { locale }) : '';
    return (
      <Styled.OptionWrapper>
        <Styled.Field>{`${studyPlace} / ${courseName}`}</Styled.Field>
        <Styled.Field>
          {workedFromDate ? workedFromDate : ''} {workedToDate ? ` - ${workedToDate}` : ''}
        </Styled.Field>
        <Styled.FieldLabel>{comment}</Styled.FieldLabel>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase multiple fieldName="candidateCourses" labelTransformer={labelTransformer} {...props} />;
};

export default CoursesDiff;
