import { css, styled, Select } from '@innowise-group/mui-kit';

import { LocationDiff, RelocationDiff, VisaDiff } from '../../../diff-variants';

export const RadioButtonsLabel = styled.div`
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  min-width: 90px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const PositionedSelect = styled(Select)`
  &.MuiAutocomplete-root {
    position: relative;
    top: 5px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  height: 72px;
`;

export const SwitchContainer = styled.div`
  position: relative;
  top: -5px;
  display: flex;
  align-items: center;
`;

export const DatePickerContainer = styled.div`
  & .MuiTextField-root {
    width: 100%;
    position: relative;
    top: 5px;
  }
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const BoxForDiffConsolidation = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.75rem;
  grid-column: 1/-1;
`;

const commonDiffElementsPosition = css`
  top: 15px;
`;

export const CustomLocationDiff = styled(LocationDiff)`
  ${commonDiffElementsPosition}
  grid-column: 1/-1;
`;

export const CustomRelocationDiff = styled(RelocationDiff)`
  ${commonDiffElementsPosition}
  grid-column: 1/-1;
`;

export const CustomVisaDiff = styled(VisaDiff)<{ $withVisa: boolean }>`
  ${commonDiffElementsPosition}
  grid-column: ${({ $withVisa }) => ($withVisa ? '1/-1' : '1/2')};
`;
