import React, { FC, PropsWithChildren, memo, useState } from 'react';
import { ApplicationBarContainer } from '../application-bar-container';
import { NavigationBarContainer } from '../navigation-bar-container';
import { ErrorBoundary } from '@modules';
import { LocalStorageService, useResizeObserver } from '@innowise-group/core';
import { StorageKeys } from '@constants';
import { Fade } from '@mui/material';
import * as Styled from './app-layout.styles';
import { useTheme } from '@innowise-group/mui-kit';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [isCollapsed, setCollapsed] = useState<boolean>(() => {
    const state = LocalStorageService.getItem(StorageKeys.NavigationMenuOpenState);
    return Boolean(state);
  });

  const close = () => {
    LocalStorageService.setItem(StorageKeys.NavigationMenuOpenState, true);
    setCollapsed(true);
  };

  return (
    <React.Fragment>
      <ApplicationBarContainer />
      <Styled.AppContainer>
        <NavigationBarContainer isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        <Styled.ContentContainer>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Styled.ContentContainer>
      </Styled.AppContainer>
      {!isCollapsed && isDesktopView && (
        <Fade in>
          <Styled.TonedBlock onClick={close} />
        </Fade>
      )}
    </React.Fragment>
  );
};

export default memo(AppLayout);
