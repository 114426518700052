import { getLanguageFromLS } from '@innowise-group/utilities';
import axios from 'axios';

const ln = getLanguageFromLS();
axios.defaults.headers['Accept-Language'] = ln;

export const $api = axios.create({
  headers: {},
  timeout: 180000,
});

export const $filesApi = axios.create({
  headers: {
    'Content-type': 'multipart/form-data',
  },
});
