import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import * as Styled from './x-ray-history.styles';
import { MainForm, useResizeObserver, useSearchAPI } from '@innowise-group/core';
import { useFormContext } from 'react-hook-form';
import Collapse from '@mui/material/Collapse';
import { Icon, useTheme } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@shared-components';

const XRayHistory: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);
  const { reset } = useFormContext<MainForm>();
  const { removeHistoryXrayQuery, manageXrayMode, historyXrayQueries } = useSearchAPI();

  const container = containerRef.current;

  const checkIsWrappingElements = () => {
    if (!container) return;
    const childrenUnion = [...container.children];
    const firstChild = childrenUnion.shift();
    const lastChild = childrenUnion.pop();
    const firstChildYCoordinate = firstChild?.getBoundingClientRect()?.y;
    const lastChildChildYCoordinate = lastChild?.getBoundingClientRect()?.y;
    const isWrapped = firstChildYCoordinate < lastChildChildYCoordinate;
    setIsWrapped(isWrapped);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkIsWrappingElements);
    if (container) resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  });

  const toggleButtonClick = () => setOpen((prev) => !prev);

  const deleteHistoryXrayQuery = (id: string) => (e: MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    removeHistoryXrayQuery(id);
  };

  const openGlobalXrayQuery =
    ({ query, xRaySearch }: Pick<MainForm, 'query' | 'xRaySearch'>) =>
    () => {
      reset((prev) => ({
        ...prev,
        query,
        xRaySearch,
      }));
      return manageXrayMode(true);
    };

  if (!historyXrayQueries.length) return null;

  return (
    <Collapse in={open} collapsedSize={isDesktopView ? '40px' : 50}>
      <Styled.XrayHistoryTitleContainer>
        <Styled.XrayHistoryTitle>{t('pages.searchPage.previouslySearchedFor')}</Styled.XrayHistoryTitle>
      </Styled.XrayHistoryTitleContainer>
      <Styled.Container>
        <Styled.QueryItemsWrapper ref={containerRef}>
          {historyXrayQueries.map(({ id, xRaySearch, query }) => {
            return (
              <Styled.QueryItemWrapper key={id}>
                <Tooltip title={xRaySearch}>
                  <Styled.QueryItemTitle onClick={openGlobalXrayQuery({ xRaySearch, query })}>
                    {xRaySearch}
                  </Styled.QueryItemTitle>
                </Tooltip>
                <Styled.DeleteIcon onClick={deleteHistoryXrayQuery(id)} type="u_decline_icon" viewBox="-6 -6 20 20" />
              </Styled.QueryItemWrapper>
            );
          })}
        </Styled.QueryItemsWrapper>
        {isWrapped && (
          <Styled.ToggleButton
            variant="text"
            $collapsed={open}
            onClick={toggleButtonClick}
            endIcon={<Icon type="u_angle-down" />}
          >
            {t('buttons.more')}
          </Styled.ToggleButton>
        )}
      </Styled.Container>
    </Collapse>
  );
};

export default XRayHistory;
