import { styled, Loader as BaseLoader, Select } from '@innowise-group/mui-kit';

export const Loader = styled(BaseLoader)`
  margin: 15px 10px 10px 10px;
`;

export const EmployeeSelect = styled(Select)`
  ${({ theme }) => theme.breakpoints.down('ss')} {
    & .MuiTextField-root {
      position: relative;
      top: 8px;
    }
  }
`;
