import { FileIdResponse } from '@innowise-group/core';
import type { AxiosResponse } from 'axios';
import { $filesApi } from '../axios/axios';
import { FileTypeConstants } from '@constants';
import axios from 'axios';

export default class FilesService {
  static async createFile(
    file: FormData,
    isSecured: boolean,
    type: FileTypeConstants,
  ): Promise<AxiosResponse<FileIdResponse>> {
    return $filesApi.post(`/recruiting-tool/api/v1/attachment/upload?type=${type}&secured=${isSecured}`, file);
  }

  static async deleteFile(attachmentId: number): Promise<AxiosResponse> {
    return $filesApi.delete(`/file-management/api/v1/attachments/${attachmentId}?attachmentId=${attachmentId}`);
  }

  static async getFileAttachment(attachmentId: number): Promise<AxiosResponse<ArrayBuffer>> {
    return $filesApi.get<ArrayBuffer>(`/recruiting-tool/api/v1/attachment?id=${attachmentId}`, {
      responseType: 'arraybuffer',
    });
  }

  static async checkFilePermission(attachmentId: number): Promise<AxiosResponse> {
    return $filesApi.get(`/recruiting-tool/api/v1/attachment?id=${attachmentId}`);
  }

  static async createFileFromHrm(url: string): Promise<AxiosResponse<ArrayBuffer>> {
    return axios.get<ArrayBuffer>(url, {
      responseType: 'arraybuffer',
    });
  }
}
