import { Dispatch, SetStateAction, memo } from 'react';
import { ActivityPlaceFilterType, BaseFilterType, FiltersEntity } from '@innowise-group/core';
import { DatePicker } from '@shared-mui-components';
import { useTranslation } from 'react-i18next';
import * as Styled from './activity-place.styles';
import { ResetButton } from '../reset-button';

export interface ActivityPlaceProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  id: string;
  state: ActivityPlaceFilterType['state'];
  onValueChange: (args: ActivityPlaceFilterType['state']) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
  addFilter: (filter: string) => void;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
}

const ActivityPlace: React.FC<ActivityPlaceProps & ActivityPlaceFilterType['filterProps']> = ({
  id,
  labelKey,
  state,
  activityPlaceKey,
  activityPositionKey,
  onValueChange,
  addFilter,
  setOpenedFilter,
  removeFilter,
}) => {
  const { t } = useTranslation();

  const onInputChange =
    (field: keyof Pick<ActivityPlaceFilterType['state'], 'place' | 'profession'>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      addFilter(id);
      return onValueChange({ ...state, [field]: e.target.value });
    };

  const onDateChange = (field: keyof Pick<ActivityPlaceFilterType['state'], 'from' | 'to'>) => (date: Date) => {
    addFilter(id);
    return onValueChange({ ...state, [field]: date?.toISOString() || null });
  };

  const onRemoveFilterHandler = () => {
    setOpenedFilter('');
    removeFilter({
      filter: id,
      updatedState: { ...state, place: '', profession: '', from: null, to: null } as unknown as Parameters<
        typeof removeFilter
      >[0]['updatedState'],
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.FieldLabel>{t(labelKey)}</Styled.FieldLabel>
      <Styled.FieldWrapper>
        <Styled.Input
          label={t(activityPlaceKey)}
          value={state.place}
          onChange={onInputChange('place')}
          placeholder={t('pages.candidates.candidateCreation.enterTheName')}
        />
      </Styled.FieldWrapper>
      <Styled.FieldWrapper>
        <Styled.Input
          label={t(activityPositionKey)}
          value={state.profession}
          onChange={onInputChange('profession')}
          placeholder={t('pages.candidates.candidateCreation.enterTheName')}
        />
      </Styled.FieldWrapper>
      <Styled.Row>
        <DatePicker
          InputProps={{ readOnly: true }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          selected={state.from ? new Date(state.from) : null}
          onChange={onDateChange('from')}
          maxDate={state?.to ? new Date(state?.to) : new Date()}
          maxYear={state?.to ? new Date(state?.to).getFullYear() : new Date().getFullYear()}
          withoutDays
          placeholder={t('pages.candidates.candidateCreation.from')}
          label={t('pages.candidates.candidateCreation.from')}
        />
        <DatePicker
          InputProps={{ readOnly: true }}
          selected={state.to ? new Date(state.to) : null}
          maxDate={new Date()}
          minDate={state?.from && new Date(state?.from)}
          minYear={state.from && new Date(state?.from).getFullYear()}
          maxYear={new Date().getFullYear()}
          onChange={onDateChange('to')}
          withoutDays
          placeholder={t('pages.candidates.candidateCreation.to')}
          label={t('pages.candidates.candidateCreation.to')}
        />
      </Styled.Row>
      <ResetButton onClick={onRemoveFilterHandler}>{t('filters.resetFilter')}</ResetButton>
    </Styled.Wrapper>
  );
};

export default memo(ActivityPlace);
