export interface CandidateStatusColorsItem {
  borderColor: string;
  backgroundColor: string;
  color: string;
  subStatusColor: string;
}

export enum CandidateStatusGroupEnum {
  ENCOUNTER = 'ENCOUNTER',
  OFFER = 'OFFER',
  JOB_ENTRY = 'JOB_ENTRY',
  QUITTING = 'QUITTING',
}

export const CandidateStatusColors: { [key: string]: CandidateStatusColorsItem } = {
  [CandidateStatusGroupEnum.ENCOUNTER]: {
    borderColor: '#F9803C',
    backgroundColor: '#FEF2EC',
    color: '#DA5922',
    subStatusColor: '#FFF9F5',
  },
  [CandidateStatusGroupEnum.OFFER]: {
    borderColor: '#2AB0A8',
    backgroundColor: '#EAF7F6',
    color: '#098C84',
    subStatusColor: '#F4FBFB',
  },
  [CandidateStatusGroupEnum.JOB_ENTRY]: {
    borderColor: '#3179E3',
    backgroundColor: '#EAF2FC',
    color: '#3179E3',
    subStatusColor: '#F5F8FE',
  },
  [CandidateStatusGroupEnum.QUITTING]: {
    borderColor: '#DF4472',
    backgroundColor: '#FCECF1',
    color: '#C2315C',
    subStatusColor: '#FDF6F8',
  },
  ['GOOGLE']: {
    borderColor: '#5D5D5D',
    backgroundColor: '#EBEBEB',
    color: '#5D5D5D',
    subStatusColor: '#F5F5F5',
  },
};
