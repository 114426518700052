import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CalendarService,
  MeetingItemResponse,
  MyKnownError,
  StatusItemResponse,
  TimeSlotItem,
} from '@innowise-group/core';
import { meetingsListItemMapper, timeSlotsItemMapper } from './calendar.mappers';

export const getCalendarMeetingsThunk = createAsyncThunk<
  { items: { [key: string]: MeetingItemResponse[] }; ids: string[] },
  Parameters<typeof CalendarService.getMeetings>['0'],
  { rejectValue: MyKnownError }
>('calendar/getMeetings', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await CalendarService.getMeetings(arg);
    return meetingsListItemMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCalendarTimeSlotsThunk = createAsyncThunk<
  TimeSlotItem[],
  Parameters<typeof CalendarService.getTimeSlots>['0'],
  { rejectValue: MyKnownError }
>('calendar/getTimeSlots', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await CalendarService.getTimeSlots(arg);
    return timeSlotsItemMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCalendarMeetingByIdThunk = createAsyncThunk<
  StatusItemResponse,
  Parameters<typeof CalendarService.getEventById>['0'],
  { rejectValue: MyKnownError }
>('calendar/getCalendarMeetingById', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await CalendarService.getEventById(arg);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
