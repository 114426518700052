import { yupResolver } from '@hookform/resolvers/yup';
import {
  RequestItem,
  useRequestsAPI,
  useResizeObserver,
  useProfileAPI,
  useValidationSchemaRequest,
  useVacanciesOptionsApi,
} from '@innowise-group/core';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { RequestForm } from '@shared-components';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requestFormDefaultValues } from './request-creation-form-modal.default-values';
import * as Styled from './request-creation-form-modal.styles';
import { useTheme } from '@innowise-group/mui-kit';

interface RequestCreationFormModalProps {
  vacancyId?: string;
  successCreationCallback?: (requestId: number) => void;
}

const RequestCreationFormModal: React.FC<ModalProps & RequestCreationFormModalProps> = ({
  close,
  vacancyId,
  successCreationCallback,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile } = useProfileAPI();
  const { validationSchema } = useValidationSchemaRequest();
  const { getVacanciesByIds } = useVacanciesOptionsApi();

  const { createRequest, isLoading: requestsIsLoading } = useRequestsAPI();

  const methods = useForm<RequestItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: requestFormDefaultValues,
  });
  const { handleSubmit, reset } = methods;

  const selectedAuthorsOptions = useMemo(() => [profile.id.toString()], [profile]);

  const onSubmit = (data: RequestItem) => {
    createRequest(data, successCallback);
  };

  const successCallback = (requestId: number) => {
    successCreationCallback?.(requestId);
    close();
  };

  useEffect(() => {
    if (profile) {
      reset((prev) => ({
        ...prev,
        vacancyId: vacancyId ? vacancyId.toString() : prev.vacancyId,
        authorId: [{ value: profile.id.toString(), title: profile.localizedFullName }],
      }));
    }
    if (vacancyId) {
      getVacanciesByIds([vacancyId]);
    }
  }, []);

  return (
    <Modal.Container size="large">
      {requestsIsLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={close}>
            <Styled.Header>
              <span>{t('pages.requests.creationModal.requestCreation')}</span>
            </Styled.Header>
          </Modal.Header>
          <Styled.Body>
            <RequestForm onSubmit={onSubmit} handleClose={close} selectedAuthorsOptions={selectedAuthorsOptions} />
          </Styled.Body>
          {isDesktopView && (
            <Modal.Actions
              noVerticalPadding={true}
              position={'full'}
              actions={[
                {
                  title: t('buttons.cancel'),
                  onClick: close,
                  type: 'button',
                },
                {
                  title: t('buttons.save'),
                  onClick: handleSubmit(onSubmit),
                  highlighted: true,
                  type: 'button',
                },
              ]}
            />
          )}
        </FormProvider>
      )}
    </Modal.Container>
  );
};

export default modalHoc(RequestCreationFormModal, 'request-creation-form');
