import { CommentRowHeight } from '@constants';
import { Icon, styled } from '@innowise-group/mui-kit';

export const CommentContainer = styled.div<{ isHightLight: boolean }>`
  padding: 0.75rem;
  min-height: 0;
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 0.5rem;
  background-color: ${({ isHightLight, theme }) => isHightLight && theme.palette.general.darkGrey[20]};
  ${({ theme }) => theme.fonts.primary[40]}
  line-height: ${CommentRowHeight}px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;

export const CommentWithIcon = styled.div`
  display: flex;
  align-items: start;
  gap: 1rem;
  & svg,
  & svg:hover {
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;

export const CommentTextContainer = styled.div`
  flex: 1;
`;

export const CommentText = styled.div<{ isOpen?: boolean }>`
  white-space: pre-wrap;
  word-break: break-all;
  ${({ theme }) => theme.fonts.primary[40]}
  line-height: 22px;
`;

export const CustomIcon = styled(Icon)`
  margin: 0;
  padding: 0;
`;
