import { AccordionDetails as BaseAccordionDetails } from '@mui/material';
import { styled, Button, Icon } from '@innowise-group/mui-kit';

export const Wrapper = styled.div``;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  row-gap: 1rem;
`;

export const SettingButton = styled(Button)`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  &.MuiButton-root.MuiButton-contained {
    width: 100%;
    gap: 1px;
    justify-content: start;
    background-color: ${({ theme }) => theme.palette.general.darkGrey[70]};
    &:hover {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
  & .MuiButton-endIcon {
    margin-left: auto;
  }
`;

const baseColorSelect = ({ theme }) => theme.palette.general.darkGrey[80];

export const ExportButton = styled(Button)`
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  & .MuiCircularProgress-root {
    margin: 0;
  }
  &.MuiButton-root.MuiButton-outlined {
    margin-left: auto;
    color: ${baseColorSelect};
    border-color: ${baseColorSelect};
    width: 100%;
    & svg {
      fill: ${baseColorSelect};
    }
    &:hover {
      fill: ${baseColorSelect};
      color: ${baseColorSelect};
      border-color: ${baseColorSelect};
      & svg {
        fill: ${baseColorSelect};
      }
    }
  }
`;

export const ArrowIcon = styled(Icon).attrs({
  type: 'u_angle-down',
})<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
`;

export const PeriodText = styled.span`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  justify-content: start;
  align-items: center;
  ${({ theme }) => theme.fonts.primary[70]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]}
`;

export const AccordionDetails = styled(BaseAccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.2rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  &.MuiAccordionDetails-root {
    padding: 1rem;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  gap: 0.75rem;
  row-gap: 1rem;
  width: 100%;
  grid-template: repeat(5, auto) / repeat(2, 1fr);
`;

export const SubmitButton = styled(SettingButton)`
  grid-row: unset;
  grid-column: unset;
  max-height: 40px;
  &.MuiButton-root.MuiButton-contained {
    justify-content: center;
    &:disabled {
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
      border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;
