export enum CandidateMergeQueryParams {
  Strategy = 'm',
  DuplicatesIds = 'duplicatesIds',
  TargetCandidate = 'targetCandidate',
}

export const candidateDiffDefaultValues = {
  firstNameEn: null,
  lastNameEn: null,
  firstNameRu: null,
  lastNameRu: null,
  candidateLanguages: [],
  citizenship: null,
  birthDate: null,
  gender: null,
  aboutMe: null,
  responsibleEmployee: null,
  sourceWithDetails: null,
  email: null,
  linkedIn: null,
  github: null,
  behance: null,
  phone: null,
  additionalPhone: null,
  telegram: null,
  skype: null,
  candidateProfessions: [],
  candidateTechnologies: [],
  candidateDomains: [],
  candidateLocation: null,
  candidateLinks: [],
  relocation: null,
  visaAvailabilityWithExpirationTime: null,
  candidateExperiences: [],
  candidateEducations: [],
  candidateCourses: [],
  candidateSkills: null,
  candidatePrioritizedVacancies: [],
  candidateWorkloads: null,
  employmentForms: null,
  candidateWorkFormats: null,
  candidateSalary: null,
  blockedWithReason: null,
};
