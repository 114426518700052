import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { modalHoc, ModalProps, Modal, useTheme } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { VacancyForm, vacancyFormDefaultValues } from '@shared-components';
import {
  useDefaultVacancy,
  useValidationSchemaVacancy,
  VacancyItem,
  useVacanciesAPI,
  useCurrentVacancy,
  useResizeObserver,
} from '@innowise-group/core';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Styled from './vacancy-edition-modal.styles';

interface VacancyEditionModalProps {
  id: string;
}

const VacancyEditionModal: React.FC<ModalProps & VacancyEditionModalProps> = ({ close, id }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { validationSchema } = useValidationSchemaVacancy();
  const methods = useForm<VacancyItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: vacancyFormDefaultValues,
  });
  const { handleSubmit, reset, watch } = methods;
  const published = watch('published');

  const vacancy = useDefaultVacancy();
  const vacancyResponse = useCurrentVacancy();

  const { isLoading: vacanciesIsLoading, updateVacancy, fetchVacancyItemById } = useVacanciesAPI();

  const onSubmit = (data: VacancyItem) => {
    if (parseInt(id)) {
      updateVacancy(data, parseInt(id), successCallback);
    }
  };

  const successCallback = () => {
    close();
    if (parseInt(id)) {
      fetchVacancyItemById(id);
    }
  };

  useEffect(() => {
    if (published && vacancy.published) {
      reset((prev) => {
        return { ...vacancy, name: prev.name };
      });
    }
  }, [published]);

  useEffect(() => {
    if (vacancy) {
      reset(vacancy);
    }
  }, [id, vacancy]);

  return (
    <Modal.Container size="large">
      {vacanciesIsLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={close}>
            <Styled.Header>
              <span>{t('pages.vacancies.creationModal.vacancyEdition')}</span>
            </Styled.Header>
          </Modal.Header>
          <Modal.Body>
            <VacancyForm onSubmit={onSubmit} handleClose={close} isRequests={!vacancyResponse.isUpdatable} isEdit />
          </Modal.Body>
          {isDesktopView && (
            <Modal.Actions
              noVerticalPadding={true}
              position={'right'}
              actions={[
                {
                  title: t('buttons.cancel'),
                  onClick: close,
                  type: 'button',
                },
                {
                  title: t('buttons.save'),
                  onClick: handleSubmit(onSubmit),
                  highlighted: true,
                  type: 'button',
                },
              ]}
            />
          )}
        </FormProvider>
      )}
    </Modal.Container>
  );
};

export default modalHoc(VacancyEditionModal, 'vacancy-edition');
