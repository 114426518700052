import { Button, css, Icon, styled } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { EducationDiff, PrimitiveFieldsDiff, CoursesDiff } from '../../../diff-variants';

export const PositionCell = styled.div`
  grid-column: 3 / span 2;
`;

export const EducationPlaceCell = styled.div`
  grid-column: 1 / span 2;
`;

export const CommentCell = styled.div`
  position: relative;
  grid-column: 1 / -1;
`;

export const AddButton = styled(Button)`
  grid-column: 4;
  justify-self: end;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
  &.MuiButton-root {
    position: absolute;
    top: -22px;
    right: 0;
  }
  &.MuiButton-text {
    padding: 6px 8px !important;
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const PlusIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const DeleteGroupContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DatePickerContainer = styled.div`
  & .MuiTextField-root {
    width: 100%;
  }
`;

export const CustomCheckbox = styled(LabeledCheckbox)`
  &.MuiCheckbox-root {
    margin: 10px 0;
  }
`;

export const DiffContainer = styled.div`
  position: relative;
  display: grid;
  grid-column: 1/-1;
  column-gap: 1.75rem;
  grid-template-columns: repeat(4, 1fr);
`;

const commonDiffElementsPosition = css`
  top: 15px;
`;

export const CustomEducationDiff = styled(EducationDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomCoursesDiff = styled(CoursesDiff)`
  ${commonDiffElementsPosition}
`;

export const SkillsDiff = styled(PrimitiveFieldsDiff)`
  ${commonDiffElementsPosition}
`;
