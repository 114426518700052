import * as Styled from './loader.styles';
import { CircularProgressProps } from '@mui/material';

export interface LoaderProps extends CircularProgressProps {
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({ size = 40, ...props }) => <Styled.Loader {...props} size={size} />;

export default Loader;
