import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const TECHNOLOGY_LIMIT = 10;
const DOMAIN_LIMIT = 10;

export const useValidationSchemaVacancy = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().trim().required(t('errors.requiredField')).max(50, t('errors.characterLimit')),
      vacancyStatusId: Yup.string().required(t('errors.requiredField')),
      hiringPurpose: Yup.string().required(t('errors.requiredField')),
      comment: Yup.string().trim().max(2000, t('errors.characterLimit')),
      countries: Yup.array(),
      cities: Yup.array(),
      vacancyDomains: Yup.array()
        .of(
          Yup.object().shape({
            title: Yup.string(),
            value: Yup.string(),
          }),
        )
        .max(DOMAIN_LIMIT, t('errors.domainLimit', { count: DOMAIN_LIMIT })),
      vacancyTechnologies: Yup.array()
        .of(
          Yup.object().shape({
            title: Yup.string(),
            value: Yup.string(),
          }),
        )
        .max(TECHNOLOGY_LIMIT, t('errors.technologyLimit', { count: TECHNOLOGY_LIMIT })),
      vacancyLanguages: Yup.array()
        .of(
          Yup.object()
            .shape({
              language: Yup.string().when('languageLevel', {
                is: (languageLevel: string) => !!languageLevel,
                then: (schema) => schema.required(t('errors.noLanguage')),
              }),
              languageLevel: Yup.string(),
            })
            .test('test', t('errors.languageAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (curr.language === item.language && item.language) {
                  duplicates?.push(idx);
                }
              });
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            }),
        )
        .max(5),
      vacancyProfessions: Yup.array()
        .of(
          Yup.object()
            .shape({
              profession: Yup.string().when('grade', {
                is: (grade: string) => !!grade,
                then: (schema) => schema.required(t('errors.noSpecialty')),
              }),
              grade: Yup.string(),
            })
            .test('test', t('errors.professionAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (curr.profession === item.profession && curr.grade === item.grade && item.profession) {
                  duplicates?.push(idx);
                }
              });
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            }),
        )
        .test('test', t('errors.requiredField'), function (professions) {
          return professions.some((item) => !!item.profession);
        })
        .max(5)
        .min(1),
    });
  }, [t]);

  return { validationSchema };
};
