import { ModalBase } from './modal.component';
import { FC } from 'react';

export interface ModalProps {
  close: () => void;
}

export const modalHoc = <T extends object>(Component: FC<T & ModalProps>, id: string) =>
  class ModalWrapper extends ModalBase<T> {
    constructor(data: T) {
      super(id || (data as { id?: string })?.id, data);
    }

    public renderModal = (): React.ReactNode => {
      return <Component {...this.getData()} close={this.close} />;
    };
  };
