import React, { ErrorInfo } from 'react';
import * as Styled from './error-boundary.styles';
import i18n from '@localization';
import { GoogleAnalyticsEvents, GoogleAnalyticsService } from '@innowise-group/core';

interface Props {
  children?: React.ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    GoogleAnalyticsService.event({
      category: 'Errors',
      action: GoogleAnalyticsEvents.ErrorBoundary,
    });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Styled.NotFoundContainer>
          <Styled.Image />
          <Styled.Error type="h1">{i18n.t('errors.wentWrong')}</Styled.Error>
        </Styled.NotFoundContainer>
      );
    } else return this.props.children;
  }
}

export default ErrorBoundary;
