import React from 'react';
import * as Styled from './desktop-view.styles';
import { StatusItemResponse } from '@innowise-group/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@innowise-group/mui-kit';
import { CandidateStatusColors, DateFormats } from '@constants';
import { format } from 'date-fns';

interface MainInfoSectionProps {
  status: StatusItemResponse;
  photoId?: number;
  localizedFullName: string;
}

const DesktopView: React.FC<MainInfoSectionProps> = ({ photoId, status, localizedFullName }) => {
  const { t } = useTranslation();
  return (
    <Styled.SectionContainer>
      <Styled.CandidateInfoContainer>
        {photoId ? (
          <Styled.Avatar id={photoId} />
        ) : (
          <Styled.NoImageContainer>
            <Icon type="u_camera" size={45} viewBox="0 0 49 44" />
          </Styled.NoImageContainer>
        )}
        <Styled.StatusContainer>
          <Styled.FullNameContainer>
            <span>{localizedFullName}</span>
          </Styled.FullNameContainer>
          <Styled.StatusItem statusColors={CandidateStatusColors[status.status?.group]}>
            {status.status?.localizedName}
          </Styled.StatusItem>
        </Styled.StatusContainer>
      </Styled.CandidateInfoContainer>
      <Styled.OptionalInfoContainer>
        <Styled.VacancyContainer>
          <span>{t('pages.candidates.candidateDetails.vacancy')}</span>
          <Styled.VacancyValue>{status.vacancy?.name || '-'}</Styled.VacancyValue>
        </Styled.VacancyContainer>
        <Styled.SubStatusValue statusColors={CandidateStatusColors[status.status.group]}>
          {status.vacancyClaim?.id ? (
            <div>{`${t('pages.requests.request')} №${status.vacancyClaim.id}`}</div>
          ) : (
            <div>{status.subStatus?.localizedName}</div> || null
          )}
          {status.periodFrom && (
            <div>{`${t('pages.candidates.comments.periodFrom')} ${format(
              new Date(status.periodFrom),
              DateFormats.DaySlashFirst,
            )} ${t('pages.candidates.comments.periodTo')} ${format(
              new Date(status.periodTo),
              DateFormats.DaySlashFirst,
            )}`}</div>
          )}
        </Styled.SubStatusValue>
      </Styled.OptionalInfoContainer>
    </Styled.SectionContainer>
  );
};

export default DesktopView;
