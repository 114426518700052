import { styled } from '@innowise-group/mui-kit';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: inherit !important;
    width: 100%;
  }
  &.MuiAccordion-root::before {
    display: none;
  }
`;
export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
    min-height: 0;
    &.Mui-focusVisible {
      background-color: transparent;
    }
  }
  & .MuiAccordionSummary-content {
    margin: 0;
    box-shadow: none;
    background-color: inherit;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;
