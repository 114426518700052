import { styled, Icon } from '@innowise-group/mui-kit';

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

export const FloatIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  z-index: 10;
  bottom: 20px;
  right: 40px;
  width: 50px;
  height: 50px;
  cursor: pointer;

  & > svg {
    flex-shrink: 0;
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    &:hover {
      fill: ${({ theme }) => theme.palette.state.error.default};
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    bottom: 5rem;
    width: 40px;
    height: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 105px;
  right: 35px;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.general.paper};
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  max-width: 400px;
  width: auto;
  max-height: 60%;
  padding: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 90px;
    right: 50%;
    transform: translateX(50%);
    padding: 15px;
    width: 90%;
    ${({ theme }) => theme.fonts.primary[40]}
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[70]}
  }
`;
