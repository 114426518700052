import React from 'react';
import * as Styled from './archives-viewer.styles';
import { ActionItem } from '../../file-preview-modal.styles';
import { RoleGuard } from '@shared-components';
import { RolesValues } from '@constants';

interface ArchivesViewerProps {
  fileName: string;
  currentFile: string;
  close: () => void;
}

const ArchivesViewer: React.FC<ArchivesViewerProps> = ({ currentFile, fileName, close }) => {
  return (
    <Styled.ModalWrapper>
      <Styled.ArchiveModal>
        <Styled.ModalArchiveActions>
          <ActionItem onClick={close}>
            <Styled.CustomIcon type="u_multiply" />
          </ActionItem>
          <RoleGuard
            roles={[
              RolesValues.Admin,
              RolesValues.Recruiter,
              RolesValues.Sourcer,
              RolesValues.ManagerFirstLevel,
              RolesValues.ManagerThirdLevel,
            ]}
          >
            <ActionItem href={currentFile} download={fileName}>
              <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
            </ActionItem>
          </RoleGuard>
        </Styled.ModalArchiveActions>
        <span>{fileName}</span>
      </Styled.ArchiveModal>
    </Styled.ModalWrapper>
  );
};

export default ArchivesViewer;
