import {
  ClosedVacanciesStatusId,
  DraftVacanciesStatusId,
  OpenVacanciesStatusId,
  PausedVacanciesStatusId,
} from './values-ids.constants';

export interface VacancyStatusColors {
  backgroundColor: string;
  color: string;
}

export const VacancyStatusColors: { [key: string]: VacancyStatusColors } = {
  [PausedVacanciesStatusId]: {
    backgroundColor: '#FEF2EC',
    color: '#DA5922',
  },
  [DraftVacanciesStatusId]: {
    backgroundColor: '#EBEBEB',
    color: '#767676',
  },
  [OpenVacanciesStatusId]: {
    backgroundColor: '#EAF7F6',
    color: '#098C84',
  },
  [ClosedVacanciesStatusId]: {
    backgroundColor: '#FCECF1',
    color: '#C2315C',
  },
};
