import { Input, Modal, ModalProps, Select, modalHoc } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import * as Styled from './choose-status.styles';
import { SelectOption } from '@innowise-group/core';
import { useAllStatusesOptions } from '@innowise-group/core';

interface ChooseStatusModalProps {
  handleSubmit: (status: SelectOption) => () => void;
  handleClose: () => void;
}

const ChooseStatusModal: React.FC<ModalProps & ChooseStatusModalProps> = ({ handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const { statusOptions } = useAllStatusesOptions();

  const [value, setValue] = useState<SelectOption | null>(null);

  const onChange = (value: string) => {
    setValue(statusOptions.find((item) => item.value === value));
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={handleClose}>
        <Styled.Header>{t('pages.analytics.statusReport')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.analytics.selectTheStatusDescription')}</Styled.Description>
        <Select
          value={value?.value || ''}
          onChange={onChange}
          disableClearable={!value?.value}
          options={statusOptions || []}
          renderInput={(params) => {
            return (
              <Input
                {...params}
                helperText={null}
                required
                label={t('pages.analytics.status')}
                placeholder={t('pages.vacancies.creationModal.selectStatus')}
              />
            );
          }}
        />
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: handleClose,
            type: 'button',
          },
          {
            title: t('buttons.generateReport'),
            onClick: handleSubmit(value),
            highlighted: true,
            type: 'button',
            disabled: !value?.value,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(ChooseStatusModal, 'choose-status-modal');
