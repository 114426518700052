import React from 'react';
import { ChipProps } from '@mui/material';
import * as Styled from './chip.styles';

const Chip: React.FC<ChipProps> = ({ label, variant = 'filled', disabled, onDelete, ...props }) =>
  label ? (
    <Styled.CustomChip
      {...props}
      label={label}
      variant={variant}
      disabled={disabled}
      onDelete={!disabled ? onDelete : null}
    />
  ) : null;

export default React.memo(Chip);
