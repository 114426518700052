import { FileTypesEnum } from '@constants';
import { FilesService, useImagesAPI } from '@innowise-group/core';
import React, { useEffect, useState } from 'react';
import { Loader } from './image.styles';

interface ImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'id'> {
  id: number | string;
}

const Image: React.FC<ImageProps> = ({ id, ...props }) => {
  const { addImageId, images } = useImagesAPI();
  const [src, setSrc] = useState<string | null>(null);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchImage = async () => {
      if (Number(id)) {
        if (images[id.toString()]) {
          setSrc(images[id.toString()]);
          return;
        }
        try {
          setIsImageLoading(true);
          const response = await FilesService.getFileAttachment(Number(id));
          if (response.data.byteLength) {
            const data = URL.createObjectURL(
              new Blob([response.data], {
                type: FileTypesEnum.jpg,
              }),
            );
            addImageId(id.toString(), data);
            setSrc(data);
          }
          setIsImageLoading(false);
        } catch (err) {
          setIsImageLoading(false);
        }
        return;
      }
      setSrc(id.toString());
    };
    fetchImage();
  }, [id]);

  if (isImageLoading) return <Loader style={props.style} />;
  return src ? <img {...props} src={src} /> : null;
};

export default React.memo(Image);
