import * as _ from 'lodash';
import {
  CandidateDiffForm,
  ContactsFields,
  EmitterService,
  MappedTargetCandidateDtoWithPossibleVariants,
  PrimitiveDataFields,
  SingleSelectDataFields,
  useDiffApi,
} from '@innowise-group/core';
import { FC, HTMLAttributes, memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './diff-variant-base.styles';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from '@shared-mui-components';
import { EmitterEvents } from '@constants';

type Field = PrimitiveDataFields | SingleSelectDataFields | ContactsFields;

export type LabelTransformer<TFiled extends Field = Field> = (
  args: MappedTargetCandidateDtoWithPossibleVariants[TFiled]['possibleDuplicates'][number],
  index: number,
) => string | React.ReactNode;

export type OptionClick<TFiled extends Field = Field> = (
  option: MappedTargetCandidateDtoWithPossibleVariants[TFiled]['possibleDuplicates'][number],
) => () => void;

export interface DiffBaseProps extends HTMLAttributes<HTMLDivElement> {
  fieldName: Field;
  multiple?: boolean;
  labelTransformer?: LabelTransformer;
}

const DiffBase: FC<DiffBaseProps> = ({ fieldName, multiple, labelTransformer, ...props }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<CandidateDiffForm>();
  const { diff, duplicatesInDiff, cleanUpFieldDiff } = useDiffApi();
  const options = diff?.result?.[fieldName]?.possibleDuplicates;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const sendUpdatesToMainForm = (isDirty: boolean, value: unknown) => {
    if (isDirty) {
      cleanUpFieldDiff(fieldName);
      EmitterService.emit(EmitterEvents.DiffUpdates, {
        fieldName,
        value,
      });
    }
  };

  const handleClose = (isDirty: boolean, value: unknown) => () => {
    setAnchorEl(null);
    sendUpdatesToMainForm(isDirty, value);
  };

  const cleanUpFieldDiffHandle = () => {
    const newValue = multiple ? [] : null;
    setValue(`duplicatesData.${fieldName}`, newValue);
    cleanUpFieldDiff(fieldName);
  };

  if (!options?.length) return null;

  const renderSource = (option) => (
    <Styled.NameContainer>
      {option?.candidateId ? (
        <>
          <Styled.CandidateLabel>{duplicatesInDiff[option?.candidateId]?.localizedFirstName}</Styled.CandidateLabel>
          <Styled.CandidateLabel>{duplicatesInDiff[option?.candidateId]?.localizedLastName}</Styled.CandidateLabel>
        </>
      ) : (
        <Styled.NewVariantLabel>{t('buttons.new')}</Styled.NewVariantLabel>
      )}
    </Styled.NameContainer>
  );

  return (
    <Controller
      control={control}
      name={`duplicatesData.${fieldName}`}
      render={({ field: { onChange, value } }) => {
        const isDirty = multiple ? value?.length : value;
        const onMenuItemClick: OptionClick = (option) => () => {
          let newValue;
          if (multiple) {
            const isOptionExistsInValue = value?.length ? !!value?.find((el) => _.isEqual(el, option)) : false;
            if (!isOptionExistsInValue) {
              newValue = value ? [...value, option] : [option];
            } else {
              newValue = value ? value.filter((el) => !_.isEqual(el, option)) : [option];
            }
          } else {
            newValue = option;
            sendUpdatesToMainForm(!!newValue, newValue);
          }
          onChange(newValue);
        };
        return (
          <Styled.Wrapper {...props}>
            <Styled.Button variant="text" id="basic-button" onClick={handleClick}>
              {t('buttons.checkFieldVariants', { count: options?.length })}
            </Styled.Button>
            <Styled.Menu
              id="basic-menu"
              disablePortal
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose(isDirty, value)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Styled.IgnoreMenuItem onClick={cleanUpFieldDiffHandle}>
                {t('buttons.ignoreDuplicates')}
              </Styled.IgnoreMenuItem>
              {options?.map((option, index) => {
                const isActiveOption = multiple ? value?.some((el) => _.isEqual(el, option)) : _.isEqual(value, option);
                return !multiple ? (
                  <Styled.MenuItem selected={isActiveOption} key={index} onClick={onMenuItemClick(option)}>
                    {labelTransformer(option, index)}
                    {renderSource(option)}
                  </Styled.MenuItem>
                ) : (
                  <Styled.MenuItem key={index}>
                    <LabeledCheckbox
                      checked={isActiveOption}
                      onChange={onMenuItemClick(option)}
                      label={labelTransformer(option, index)}
                    />
                    {renderSource(option)}
                  </Styled.MenuItem>
                );
              })}
            </Styled.Menu>
          </Styled.Wrapper>
        );
      }}
    />
  );
};

export default memo(DiffBase);
