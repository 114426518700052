import { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import { SearchCandidateResponse, SearchRequestResponse, SearchVacancyResponse } from '@innowise-group/core';

interface Params {
  query: string;
  page: number;
  size?: number;
  searchType: 'GLOBAL_SEARCH' | 'X_RAY';
  indexType: 'CANDIDATE' | 'VACANCY' | 'VACANCY_CLAIM';
}

export default class GlobalSearchService {
  static async search<TResponse extends object>(params: Params): Promise<AxiosResponse<TResponse>> {
    return $api.get<TResponse>('recruiting-tool/api/v1/search', { params });
  }

  static async searchCandidates(params: Params): Promise<AxiosResponse<SearchCandidateResponse>> {
    return $api.get<SearchCandidateResponse>('/recruiting-tool/api/v1/search/candidate', { params });
  }

  static async searchVacancies(params: Params): Promise<AxiosResponse<SearchVacancyResponse>> {
    return $api.get<SearchVacancyResponse>('/recruiting-tool/api/v1/search/vacancy', { params });
  }

  static async searchRequests(params: Params): Promise<AxiosResponse<SearchRequestResponse>> {
    return $api.get<SearchRequestResponse>('/recruiting-tool/api/v1/search/vacancyClaim', { params });
  }

  static async searchCandidatesXray(params: Params): Promise<AxiosResponse<SearchCandidateResponse>> {
    throw new Error(`Function not implemented: ${params}`);
  }

  static async searchVacanciesXray(params: Params): Promise<AxiosResponse<SearchVacancyResponse>> {
    throw new Error(`Function not implemented: ${params}`);
  }

  static async searchRequestsXray(params: Params): Promise<AxiosResponse<SearchRequestResponse>> {
    throw new Error(`Function not implemented: ${params}`);
  }
}
