import { Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const Description = styled.div`
  ${({ theme }) => theme.fonts.primary[40]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const ModalTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 10px 2.5rem 2.5rem 2.5rem;
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root {
    flex: 1;
  }
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const SubmitButton = styled(Button)`
  &.MuiButton-root {
    flex: 1;
  }
`;
