import React from 'react';
import { Fade, MenuProps } from '@mui/material';
import * as Styled from './menu.styles';

const Menu: React.FC<MenuProps> = ({
  TransitionComponent = Fade,
  disableScrollLock = true,
  disableAutoFocusItem = true,
  marginThreshold = 16,
  ...props
}) => {
  return (
    <Styled.BaseMenu
      {...props}
      disableScrollLock={disableScrollLock}
      disableAutoFocusItem={disableAutoFocusItem}
      TransitionComponent={TransitionComponent}
      marginThreshold={marginThreshold}
    />
  );
};

export default React.memo(Menu);
