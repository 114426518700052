import { Select } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const EmployeeSelect = styled(Select)`
  &.MuiAutocomplete-root,
  & .MuiTextField-root {
    width: 100%;
  }
  &.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 0;
  }
`;
