import { default as FormField } from './form-field.component';
import { default as FormErrorMessage } from './form-error-message.component';
import { FormActionsContainer, FormContainer } from './form.styles';

export const Form = {
  Container: FormContainer,
  Field: FormField,
  ErrorMessage: FormErrorMessage,
  Actions: FormActionsContainer,
};
