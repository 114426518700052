import { APP_HEADER_HEIGHT } from '@constants';
import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  height: 100%;
  max-height: calc(100vh - ${APP_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: calc(100vh - ${APP_HEADER_HEIGHT}px - 4rem);
  }
`;
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.5rem 0.75rem;
  gap: 0.75rem;
  background: ${({ theme }) => theme.palette.general.paper};
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 1.5rem 1.5rem 1rem 1.5rem;
    padding: 1.5rem;
    gap: 1.5rem;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
  }
`;
