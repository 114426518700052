import { FC, memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useClickOutside } from '@innowise-group/core';
import * as Styled from './pagination-bar.styles';
import { GAP_VALUE, INACTIVE_BORDER_WIDTH_VALUE, ACTIVE_BORDER_WIDTH_VALUE } from './pagination-bar.styles';
import { PaginationBarProps } from '../pagination-bar.component';

const PaginationBarMobile: FC<PaginationBarProps> = ({ setPagable, totalElements, pageSize, currentPage }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded((prev) => !prev);
  const closeMenu = () => setExpanded(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useClickOutside<HTMLDivElement>(closeMenu, expanded);

  const totalPageCount = Math.ceil(totalElements / pageSize);

  const buttonMap = useMemo(
    () => Array.from({ length: Math.ceil(totalElements / pageSize) }),
    [totalElements, pageSize],
  );

  const setPagableHandler = (currentPage: number) => () => setPagable({ pageSize, currentPage });

  useLayoutEffect(() => {
    setPagable({ currentPage, pageSize: 50 });
  }, []);

  useEffect(() => {
    if (expanded) {
      const containerWidth = ref.current?.clientWidth;
      const buttonWidth = buttonRef.current?.clientWidth;
      const buttonPosition =
        (buttonWidth + GAP_VALUE + INACTIVE_BORDER_WIDTH_VALUE * 2) * (currentPage - 1) -
        containerWidth / 2 +
        buttonWidth / 2 +
        ACTIVE_BORDER_WIDTH_VALUE * 2;
      containerRef?.current?.scrollTo({ left: buttonPosition });
    }
  }, [expanded, currentPage]);

  return (
    <Styled.Wrapper ref={ref} onClick={toggleExpanded} $expanded={expanded}>
      {!!totalPageCount && (
        <Styled.AnimationWrapper $expanded={expanded}>
          {!expanded && (
            <Styled.Label>
              <span className="hightLighted">{currentPage}</span> / {totalPageCount}
            </Styled.Label>
          )}
          {expanded && (
            <Styled.PageButtonsContainer ref={containerRef}>
              {buttonMap.map((_, idx) => (
                <Styled.PageButton
                  ref={buttonRef}
                  onClick={setPagableHandler(idx + 1)}
                  $current={idx + 1 === currentPage}
                  key={idx}
                >
                  {idx + 1}
                </Styled.PageButton>
              ))}
            </Styled.PageButtonsContainer>
          )}
        </Styled.AnimationWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default memo(PaginationBarMobile);
