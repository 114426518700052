import { useState, useRef, memo, ChangeEvent, useEffect } from 'react';
import * as Styled from './multi-select.styles';
import { useTranslation } from 'react-i18next';
import { Input } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { FilterButton } from '../shared';
import { type FilterSelectProps } from '../../../../types';

const MultiSelect: React.FC<FilterSelectProps> = ({
  state,
  labelKey,
  isOpen,
  search,
  singleSelect,
  commentIds,
  pinned,
  onRemoveFilter,
  onValueChange,
  onToggleFilter,
  onClickFilterOutside,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedItem, setCurrentExpandedItem] = useState('');

  const initialFilterState = Object.entries(state).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: {
        ...val,
        active: false,
      },
    };
  }, {});

  const { selected, rest, total } = Object.entries(state).reduce(
    (acc, [, { title, value, active }]) => {
      let key;
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && active) {
        key = 'selected';
      }
      if (title.toLowerCase().startsWith(filter.toLowerCase()) && !active) {
        key = 'rest';
      }
      if (!key) return acc;
      return {
        ...acc,
        total: acc.total + 1,
        [key]: acc.total + 1,
        [key]: {
          ...acc[key],
          count: active ? acc.selected.count + 1 : acc.rest.count + 1,
          ids: [...acc[key].ids, value],
        },
      };
    },
    {
      selected: {
        count: 0,
        ids: [],
      },
      rest: { count: 0, ids: [] },
      total: 0,
    },
  );

  const isEmpty = !selected.count && !filter;

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);

  const onClickHandler = (id: string) => (e: Pick<Event, 'stopPropagation'>) => {
    e.stopPropagation();
    onValueChange({ ...state, [id]: { ...state[id], active: !state[id].active, comment: '' } });
  };

  const toggleItemDetails = (id: string) => () => {
    setCurrentExpandedItem((prev) => (prev !== id ? id : ''));
  };

  const onItemDetailsInputChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    onValueChange({ ...state, [id]: { ...state[id], comment: e.target.value } });
  };

  const onReset = () => {
    onValueChange(initialFilterState);
  };

  const onRemoveFilterHandler = () => onRemoveFilter(initialFilterState);

  const onToggleFilterHandler = () => {
    setCurrentExpandedItem('');
    onToggleFilter();
  };

  const onClickFilterOutsideHandle = () => {
    if (isEmpty && !pinned) onRemoveFilterHandler();
    setFilter('');
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Wrapper ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={!pinned ? onRemoveFilterHandler : null}
        onClick={onToggleFilterHandler}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>
            {`${t(labelKey)}${selected.ids.length ? `: ${state[selected.ids[0]].title}` : ''}`}
          </Styled.FirstSelectedValue>
          {selected.ids.length > 1 && <Styled.SelectedCount>+{selected.ids.length - 1}</Styled.SelectedCount>}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          {search ? (
            <Styled.InputContainer>
              <Styled.CustomInput placeholder={t('pages.search')} onChange={onFilterInputChange} value={filter} />
            </Styled.InputContainer>
          ) : (
            <Styled.EmptyInputContainer />
          )}
          <Styled.VariantsList>
            <Styled.PreselectedVariantsGroupContainer>
              {selected.ids.map((id) => (
                <Styled.VariantWrapper key={id}>
                  <Styled.VariantContainer onClick={toggleItemDetails(id)}>
                    <LabeledCheckbox
                      onChange={onClickHandler(id)}
                      defaultChecked={!!state[id].active}
                      readOnly
                      id={id}
                      label={state[id].title}
                    />
                    {commentIds?.includes(id) && (
                      <Styled.CustomExpandIcon
                        expanded={expandedItem === id}
                        size={17}
                        viewBox="5 5 13 13"
                        type="u_angle-down"
                      />
                    )}
                  </Styled.VariantContainer>
                  {expandedItem === id && commentIds?.includes(id) && (
                    <Styled.CommentWrapper>
                      <Input
                        value={state[id]?.comment || ''}
                        onChange={onItemDetailsInputChange(id)}
                        placeholder={t('filters.union.sourceDetailsPlaceholder')}
                      />
                    </Styled.CommentWrapper>
                  )}
                </Styled.VariantWrapper>
              ))}
            </Styled.PreselectedVariantsGroupContainer>
            <Styled.CustomDivider />
            <Styled.UnselectedVariantsGroupContainer>
              {rest.ids.map((id, idx) => (
                <Styled.VariantWrapper key={id}>
                  <Styled.VariantContainer
                    onClick={onClickHandler(id)}
                    disabled={selected.ids.length && singleSelect}
                    even={(rest.ids.length + idx + 1) % 2 === 0}
                  >
                    <LabeledCheckbox
                      disabled={!!(selected.ids.length && singleSelect)}
                      onChange={onClickHandler(id)}
                      defaultChecked={!!state[id].active}
                      readOnly
                      id={id}
                      label={state[id].title}
                    />
                  </Styled.VariantContainer>
                </Styled.VariantWrapper>
              ))}
            </Styled.UnselectedVariantsGroupContainer>
          </Styled.VariantsList>
          <Styled.BottomControls>
            <Styled.CustomButton disabled={isEmpty} onClick={onReset} variant="text">
              {t('filters.resetFilter')}
            </Styled.CustomButton>
            {!filter && (
              <Styled.AmountLabel>
                {t('buttons.amountFrom', { from: selected.count, amount: total })}
              </Styled.AmountLabel>
            )}
          </Styled.BottomControls>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default memo(MultiSelect);
