import { createAction } from '@reduxjs/toolkit';
import { CandidateFilterOptions, FiltersEntity, FiltersState } from './filters.types';
import { SortingValue } from '@constants';
import { RequestOptions, VacancyOptions } from '@innowise-group/core';

export const setCandidateFiltersOptionsAction = createAction<{
  options?: CandidateFilterOptions;
  reset: boolean;
  profileId?: number;
}>('filters/setCandidateFiltersOptions');

export const setVacancyFiltersOptionsAction = createAction<{
  options?: VacancyOptions;
  reset: boolean;
  profileId?: number;
}>('filters/setVacancyFiltersOptions');

export const setRequestFiltersOptionsAction = createAction<{
  options?: RequestOptions;
  reset: boolean;
  profileId?: number;
}>('filters/setRequestFiltersOptions');

export const clearFiltersAction = createAction<(keyof FiltersState)[]>('filters/clearFilters');
export const addAndOpenFilterAction = createAction<{ entity: keyof FiltersEntity; filter: string }>(
  'filters/addAndOpenFilter',
);
export const addFilterAction = createAction<{ entity: keyof FiltersEntity; filter: string }>('filters/addFilter');
export const closeFilterAction = createAction<{ entity: keyof FiltersEntity; filter: string }>('filters/closeFilter');
export const removeFilterAction = createAction<{
  entity: keyof FiltersEntity;
  filter: string;
  updatedState: FiltersEntity[keyof FiltersEntity];
}>('filters/removeFilter');
export const toggleFilterAction = createAction<{ entity: keyof FiltersEntity; filter: string }>('filters/toggleFilter');
export const onFilterChangeAction = createAction<{
  entity: keyof FiltersEntity;
  filter: string;
  updatedState: unknown;
}>('filters/onFilterChange');

export const setPagableAction = createAction<Omit<FiltersState['pagable'], 'pageSizes'>>('filters/setPagable');

export const setSelectedAction = createAction<number>('filters/setSelectedAction');

export const resetSelectedAction = createAction('filters/resetSelectedAction');

export const setCurrentTabAction = createAction<FiltersState['currentTab']>('filters/setCurrentTab');

export const setPageSortAction = createAction<{ entity: keyof FiltersEntity; value: SortingValue }>(
  'filters/setPageSort',
);
