import React from 'react';
import * as Styled from './chip.styles';

export interface ChipProps {
  title: string;
  disabled?: boolean;
  clearable?: boolean;
  onDeleteClick?: (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => void;
}

const Chip: React.FC<ChipProps> = ({ title, clearable, onDeleteClick, disabled }) => {
  const handleClick = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    if (!disabled) {
      onDeleteClick(event);
    }
  };

  return title ? (
    <Styled.ChipContainer disabled={disabled}>
      <Styled.Title>{title}</Styled.Title>
      {clearable && !disabled ? (
        <Styled.IconContainer type="u_multiply" size={13} onClick={handleClick} isPointer />
      ) : null}
    </Styled.ChipContainer>
  ) : null;
};

export default Chip;
