import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const MAX_COUNT_LENGTH = 3;

export const useValidationSchemaAdministrationSourcing = (ids?: string[]) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      employeeId: Yup.string()
        .trim()
        .required(t('errors.requiredField'))
        .test('valueAlreadyExist', t('errors.valueAlreadyExist'), function (employeeId) {
          if (!ids) return true;
          return !ids.includes(employeeId);
        }),
      requiredNumberOfEvents: Yup.string()
        .trim()
        .required(t('errors.requiredField'))
        .matches(/(^$)|(^\d+$)/, t('errors.invalidFormat'))
        .max(MAX_COUNT_LENGTH, t('errors.maxCharacters', { count: MAX_COUNT_LENGTH }))
        .test('test', t('errors.moreThanZero'), function (amount) {
          return Number(amount) > 0;
        }),
    });
  }, [t, ids]);

  return { validationSchema };
};
