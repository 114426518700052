import { FC, memo, useState } from 'react';
import { Tabs, useFiltersData, useResizeObserver } from '@innowise-group/core';
import { RolesValues } from '@constants';
import { useTheme } from '@innowise-group/mui-kit';
import { DesktopView } from './desktop-view';
import { MobileView } from './mobile-view';

interface TableTabsProps {
  currentTab: Tabs;
  sort: string;
  tabs: { id: Tabs; name: string }[];
  sortItems: { text: string; value: string }[];
  setPageSort: (sort: string) => void;
  onChangeTab: (tab: Tabs) => void;
  isRecruiterMode?: boolean;
  myTabRoles: RolesValues[];
  ExportButtonComponent: FC<{ count: number }>;
}

const TableTabs: FC<TableTabsProps> = ({
  currentTab,
  sort,
  tabs,
  sortItems,
  setPageSort,
  onChangeTab,
  isRecruiterMode,
  myTabRoles,
  ExportButtonComponent,
}) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { selected } = useFiltersData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChangeTab = (tabId: Tabs) => () => {
    onChangeTab(tabId);
  };

  const openSortMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSortMenu = () => {
    setAnchorEl(null);
  };

  const setSortParamHandle = (sortItem: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setPageSort(sortItem);
    setAnchorEl(null);
  };

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component
      currentTab={currentTab}
      sort={sort}
      tabs={tabs}
      sortItems={sortItems}
      isRecruiterMode={isRecruiterMode}
      myTabRoles={myTabRoles}
      openSortMenu={openSortMenu}
      closeSortMenu={closeSortMenu}
      anchorEl={anchorEl}
      selected={selected}
      handleChangeTab={handleChangeTab}
      setSortParamHandle={setSortParamHandle}
      ExportButtonComponent={ExportButtonComponent}
    />
  );
};

export default memo(TableTabs);
