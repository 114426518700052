import { CurrencyEnum } from '@constants';
import { CandidateOfferItem } from '@innowise-group/core';

export const defaultOfferFormValues: CandidateOfferItem = {
  offerType: '',
  offerDate: new Date(),
  structuralUnitId: '',
  position: '',
  salaryGross: '',
  salaryProbationGross: '',
  salaryProjectGross: '',
  estimatedReleaseDate: null,
  probationPeriod: '',
  responseDate: null,
  city: '',
  country: '',
  office: '',
  project: '',
  specialWorkingConditions: '',
  fileId: 0,
  candidateId: 0,
  currency: CurrencyEnum.USD,
};
