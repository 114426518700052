import { Pagination, Select, useTheme } from '@innowise-group/mui-kit';
import React, { useCallback, useMemo, useState } from 'react';
import * as Styled from './pagination-bar.styles';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from '@innowise-group/core';
import { PaginationBarProps } from '../pagination-bar.component';

const PaginationBarDesktop: React.FC<PaginationBarProps> = ({
  pageSizes,
  pageSize,
  totalElements,
  currentPage,
  setPagable,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const [inputValue, setInputValue] = useState<string>('');

  const totalPageCount = useMemo(() => Math.ceil(totalElements / pageSize), [totalElements, pageSize]);

  const selectValueChangeHandler = useCallback((value: string) => {
    setPagable({ pageSize: Number(value), currentPage: 1 });
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPagable({ pageSize, currentPage: value });
    },
    [pageSize],
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  }, []);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === 'Enter' &&
        inputValue.length &&
        Number(inputValue) <= totalPageCount &&
        Number(inputValue) >= 1
      ) {
        setPagable({ pageSize, currentPage: Number(inputValue) });
      }
    },
    [pageSize, inputValue],
  );

  return (
    <Styled.PaginationContainer>
      <Pagination
        {...props}
        page={currentPage}
        count={totalPageCount}
        onChange={handleChange}
        siblingCount={isDesktopView ? 1 : 0}
      />
      <Select
        value={String(pageSize)}
        defaultValue={String(pageSize)}
        onChange={selectValueChangeHandler}
        options={pageSizes}
        disableClearable
        renderInput={(params) => <Styled.SelectInput {...params} helperText={null} disabled />}
      />
      <Styled.InputLabel>{t(isDesktopView ? 'buttons.goToPage' : 'buttons.goToPageMobile')}</Styled.InputLabel>
      <Styled.PageInput
        showArrows={false}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        min={1}
        max={totalPageCount}
        helperText={null}
      />
    </Styled.PaginationContainer>
  );
};

export default React.memo(PaginationBarDesktop);
