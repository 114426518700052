import { EmployeesState } from './employees.types';

export const preloadedEmployeesState: EmployeesState = {
  employees: {
    isLoading: false,
    items: {},
    ids: [],
  },
  employeesForCandidateDiffMerge: {
    isLoading: false,
    items: {},
    ids: [],
  },
  mentionedOptions: {
    isLoading: false,
    items: {},
    ids: [],
  },
  mentionedEmployees: {
    isLoading: false,
    items: {},
    ids: [],
  },
  responsibleEmployee: null,
};
