import { HTMLAttributes, PropsWithChildren, Ref, memo, useMemo } from 'react';
import * as Styled from './filter-button.styles';
import { AppIconsType, Icon } from '@innowise-group/mui-kit';
import React from 'react';

interface FilterButtonProps extends HTMLAttributes<HTMLButtonElement>, PropsWithChildren {
  onToggleFilter?: () => void;
  action: 'add' | 'delete';
  iconSize?: number;
  iconViewBox?: string;
  isActive?: boolean;
  ref?: Ref<HTMLButtonElement>;
}

const FilterButton: React.FC<FilterButtonProps> = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  (
    { children, onToggleFilter, onClick, action, iconSize = 10, isActive, iconViewBox = '0 0 12 12', className },
    ref,
  ) => {
    const iconTypesMap = {
      add: 'u_add',
      delete: 'u_del_vector',
    };
    const type = useMemo(() => {
      return iconTypesMap[action] as AppIconsType;
    }, [action]);

    return (
      <Styled.ToggleButtonWrapper className={className} onClick={onClick} ref={ref} isActive={isActive}>
        <Styled.ToggleButtonTitle>{children}</Styled.ToggleButtonTitle>
        {onToggleFilter ? (
          <Styled.RemoveFilterButton onClick={onToggleFilter}>
            <Icon viewBox={iconViewBox} size={iconSize} type={type} />
          </Styled.RemoveFilterButton>
        ) : (
          <Icon type="u_angle-down" size={iconSize} />
        )}
      </Styled.ToggleButtonWrapper>
    );
  },
);

export default memo(FilterButton);
