import { FC } from 'react';
import { format } from 'date-fns';
import { es, ru } from 'date-fns/locale';
import { AppLanguages, DateFormats } from '@constants';
import { getLanguageFromLS } from '@innowise-group/utilities';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './visa-diff.styles';
import { useTranslation } from 'react-i18next';

const VisaDiff: FC = (props) => {
  const { t } = useTranslation();
  const ln = getLanguageFromLS();
  const locale = ln === AppLanguages.En ? es : ru;
  const labelTransformer: LabelTransformer<'visaAvailabilityWithExpirationTime'> = ({
    validTo,
    visaAvailability,
    visaType,
    isFullVisaDate,
  }) => {
    const date = validTo ? format(new Date(validTo), DateFormats.LongLocalizedDate, { locale }) : '';
    return (
      <Styled.OptionWrapper>
        <Styled.Label>
          {t('pages.candidates.candidateCreation.visa')}:{' '}
          <Styled.HightLighted>{visaAvailability ? t('buttons.yes') : t('buttons.no')}</Styled.HightLighted>
        </Styled.Label>
        <Styled.Label>
          {' '}
          {t('pages.candidates.candidateCreation.knownExactDate')}:{' '}
          <Styled.HightLighted>{isFullVisaDate ? t('buttons.yes') : t('buttons.no')}</Styled.HightLighted>
        </Styled.Label>
        {visaAvailability && <Styled.Value> / {`${visaType.translation} (${date})`}</Styled.Value>}
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase fieldName="visaAvailabilityWithExpirationTime" labelTransformer={labelTransformer} {...props} />;
};

export default VisaDiff;
