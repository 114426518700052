import { styled, Typography } from '@innowise-group/mui-kit';
import descriptionContainerBackground from '../../assets/background.svg';
import top from '../../assets/top.svg';
import bottom from '../../assets/bottom.svg';

const iconsMap = Object.freeze({ top, bottom });

export const ContentSide = styled.section`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.general.paper};
`;

export const DescriptionContainer = styled(ContentSide)`
  background-image: url(${descriptionContainerBackground});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const DividersGroup = styled.div<{ position: 'top' | 'bottom' }>`
  background-image: url(${({ position }) => iconsMap[position]});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: ${({ position }) => (position === 'top' ? '372px' : '178px')};
  height: ${({ position }) => (position === 'top' ? '95px' : '98px')};
  z-index: 10;
  right: ${({ position }) => position === 'top' && '2%'};
  left: ${({ position }) => position === 'bottom' && '2%'};
  bottom: ${({ position }) => position === 'bottom' && '2%'};
  top: ${({ position }) => position === 'top' && '2%'};
`;

export const EmojisContainer = styled.div`
  display: flex;

  & > * {
    margin-right: 28px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EmojiContainer = styled.div`
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.palette.general.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0;
  }
`;

export const Divider = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.general.white};
`;

export const LightText = styled(Typography)`
  margin: 56px 0 70px 0;
  ${({ theme }) => theme.fonts.primary[90]}
  font-size: 1.875rem;
  line-height: 37px;
  color: ${({ theme }) => theme.palette.general.white};
`;

export const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const GoalText = styled.div`
  ${({ theme }) => theme.fonts.primary[70]}
  line-height: 25px;
  color: ${({ theme }) => theme.palette.general.white};
`;

export const BlackText = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[90]}
  font-size: 1.875rem;
  line-height: 37px;
`;

export const GrayText = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[70]}
  font-size: 1.875rem;
  line-height: 37px;
  margin-bottom: 8px;
  margin-top: 30px;
  align-self: end;
`;

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 350px;
  margin-top: 20px;
  border-radius: 29px;
  border: 2px solid ${({ theme }) => theme.palette.general.darkGrey[60]};
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  &:hover {
    border: 2px solid ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const AuthButtonText = styled(Typography)`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[70]}
  line-height: 22px;
`;

export const Version = styled(Typography)`
  position: absolute;
  bottom: 100px;
  ${({ theme }) => theme.fonts.primary[70]}
  line-height: 25px;
  color: ${({ theme }) => theme.palette.info.default};
`;

export const SupportLinkBlock = styled.div`
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
  text-align: center;
  position: absolute;
  bottom: 0;
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 25px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.info.default};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.info.hover};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
