import { FormControlLabel } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const LabelContainer = styled(FormControlLabel)`
  overflow-y: hidden;
  &.MuiFormControlLabel-root {
    margin: 0;
    gap: 1rem;
    width: fit-content;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      gap: 0.5rem;
    }
  }
  .MuiFormControlLabel-label {
    ${({ theme }) => theme.fonts.primary[60]};
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
