import { Input, Select, Button, Icon, styled, css } from '@innowise-group/mui-kit';

export const DatePickerInput = styled(Input)`
  &.MuiTextField-root {
    width: 150px;
  }
  & .MuiOutlinedInput-root {
    padding-right: 2px;
  }
  & .MuiInputLabel-root {
    padding-right: 18px;
  }
`;

const ContainerConfig = css<{
  disabled?: boolean;
  error?: boolean;
}>`
  position: relative;

  & .react-datepicker {
    width: 100%;
    border: 0;
    max-width: 320px;
    background-color: ${({ theme }) => theme.palette.general.paper};
    box-shadow: 0px -1px 2px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 8px 12px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 8px 12px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 4px 4px ${({ theme }) => theme.palette.general.darkGrey[20]},
      0px 1px 4px ${({ theme }) => theme.palette.general.darkGrey[20]};
    border-radius: 4px;
    padding: 10px;
  }
  & .react-datepicker-popper {
    z-index: 3;
    &[data-placement='bottom-start'] {
      top: 18px !important;
    }
    &[data-placement='top-start'] {
      bottom: 18px !important;
    }
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month-container {
    width: 100%;
    position: relative;
  }
  & .react-datepicker__header {
    border: none;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.fonts.primary[10]}
    font-weight: bold;
    cursor: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      ${({ theme }) => theme.fonts.primary[20]}
    }
  }
`;

const DatepickerMonth = css`
  margin: 0;
  position: relative;
  top: -0.5px;
  padding: 3px;
`;

const DatepickerSelectedItem = css`
  background-color: ${({ theme }) => theme.palette.state.error.pressed} !important;
  color: ${({ theme }) => theme.palette.general.white} !important;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.general.white} !important;
    background-color: ${({ theme }) => theme.palette.state.error.pressed} !important;
  }
  &:focus {
    border: none;
    outline: none;
  }
`;

const DatepickerItem = css`
  ${({ theme }) => theme.fonts.primary[30]}
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  flex: 1;
  margin: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.state.error.background};
  }
`;

export const ContainerTime = styled.div<{ disabled?: boolean; error?: boolean }>`
  ${ContainerConfig}

  & .react-datepicker__month,
  & .react-datepicker__day-names {
    display: none;
  }

  & .react-datepicker__time-container {
    position: relative;
  }

  & .react-datepicker__time-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 5px;
    min-width: 220px;
    height: auto !important;
    overflow-y: auto;
    max-height: 210px;
  }

  & .react-datepicker__time-list-item {
    display: block;
    ${DatepickerItem}
    line-height: 20px;
    min-width: 55px;
    max-width: 55px;
    height: 40px;
    margin: 0;
    &.already-taken {
      background-color: ${({ theme }) => theme.palette.state.error.background};
    }
  }
  & .react-datepicker__time-list-item--selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__time-list-item--disabled {
    cursor: default;
    color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    &:hover {
      background-color: inherit;
    }
  }
`;

export const ContainerWithoutDays = styled.div<{
  disabled?: boolean;
  error?: boolean;
}>`
  ${ContainerConfig}
  & .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & .react-datepicker__day--disabled {
    cursor: not-allowed !important;
    color: ${({ theme }) => theme.palette.general.darkGrey[40]} !important;
    &:hover {
      background-color: inherit !important;
    }
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month {
    ${DatepickerMonth}
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  & .react-datepicker__month--selected,
  & .react-datepicker__month-text--keyboard-selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__month-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .react-datepicker__month-text {
    ${DatepickerItem}
    line-height: 20px;
    width: 46px;
    height: 46px;
  }
`;

export const ContainerFullDate = styled.div<{ disabled?: boolean; error?: boolean }>`
  ${ContainerConfig}
  & .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & .react-datepicker__day--disabled {
    cursor: not-allowed !important;
    color: ${({ theme }) => theme.palette.general.darkGrey[40]} !important;
    &:hover {
      background-color: inherit !important;
    }
  }
  & .react-datepicker__aria-live {
    display: none;
  }
  & .react-datepicker__month {
    ${DatepickerMonth}
  }
  & .react-datepicker__week,
  & .react-datepicker__day-names {
    display: flex;
    align-items: center;
  }
  & .react-datepicker__day {
    ${DatepickerItem}
    line-height: 19px;
    width: 36px;
    height: 36px;
  }
  & .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[40]};
    }
  }
  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    ${DatepickerSelectedItem}
  }
  & .react-datepicker__day-name {
    ${({ theme }) => theme.fonts.primary[30]}
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 1;
    margin-top: 5px;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;

export const CalendarHeader = styled.div<{ withoutGap?: boolean }>`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

export const ChangeBlock = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  display: flex;
  align-items: center;
  .MuiAutocomplete-listbox {
    max-height: 200px;
  }
`;

export const ArrowButton = styled(Button)<{ disabled?: boolean }>`
  &.MuiButton-root.MuiButton-text {
    width: 15px;
    height: 25px;
    min-width: 15px;
    padding: 0;
    margin-right: 5px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
    &:hover svg {
      fill: ${({ theme, disabled }) => theme.palette.general.darkGrey[disabled ? 80 : 100]};
    }
  }
`;

export const Value = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 20px;
  color: ${({ theme }) => theme.palette.state.error.default};
`;

export const IconItem = styled(Icon)<{ isLeft?: boolean; isRight?: boolean; isOpen?: boolean; disabled?: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  transition: 0.2s;
  transform: rotate(${({ isLeft, isRight, isOpen }) => (isOpen ? -90 : isLeft ? 180 : isRight ? 0 : 90)}deg);
  & svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    flex-shrink: 0;
  }
  &:hover svg {
    fill: ${({ theme, disabled }) => theme.palette.general.darkGrey[disabled ? 80 : 100]};
  }
`;

export const MonthInput = styled(Input)`
  &.MuiTextField-root {
    margin: 0;
    width: auto !important;
  }
  &.MuiTextField-root:hover {
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-root {
    min-height: 35px !important;
    padding: 3px 20px 3px 5px !important;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
  & .MuiAutocomplete-input {
    width: 75px !important;
    min-width: 0 !important;
    ${({ theme }) => theme.fonts.primary[30]}
  }
`;

export const YearsInput = styled(MonthInput)`
  & .MuiAutocomplete-input {
    width: 40px !important;
  }
`;

export const DaysInput = styled(MonthInput)`
  & .MuiAutocomplete-input {
    width: 25px !important;
  }
`;

export const CustomSelect = styled(Select)`
  .MuiAutocomplete-popupIndicator {
    width: 18px;
    height: 18px;
  }
`;

export const CalendarIcon = styled(Icon)`
  cursor: pointer;
  position: relative;
  left: 0px;

  & svg {
    flex-shrink: 0;
  }
`;

export const ClearIcon = styled(Icon)`
  position: relative;
  left: 0px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  & svg {
    flex-shrink: 0;
  }
`;

export const IconsContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;
