import { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';

export default class DictionaryService {
  static async getDictionarySelectOptions(): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/translation/grouped`);
  }

  static async getCountrySelectOptions(body = {}): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/countries/getByFilter`, { ...body });
  }

  static async getCitySelectOptions(body: { hasCompanyOffice?: boolean }, lang?: string): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/cities/search`, body, !!lang && { headers: { 'Accept-Language': lang } });
  }

  static async addCityToDictionary(data: {
    countryId: string;
    cityNames: { [key: string]: string };
  }): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/cities', data);
  }

  static async mergeLocations(data: object): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/cities/merge', data);
  }

  static async createStatus(data: object): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/status', data);
  }

  static async updateStatusName(id: string, data: object): Promise<AxiosResponse> {
    return $api.put(`/recruiting-tool/api/v1/status/${id}`, data);
  }

  static async getStatusById(id: string): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/status/${id}`);
  }

  static async deleteStatusById(id: string): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/status/${id}`);
  }

  static async restoreStatusById(id: string): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/status/restore/${id}`);
  }

  static async addSubStatus(data: object): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/status/addSubStatus', data);
  }
}
