import { AppRoutes } from '@constants';
import { AuthService } from '@innowise-group/core';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const authenticatedValue = AuthService.isLoggedIn();

  return <>{authenticatedValue ? children : <Navigate to={AppRoutes.Auth} />}</>;
};

export default PrivateRoute;
