import { createAction } from '@reduxjs/toolkit';
import { HistoryEntitiesUnion, SearchState } from './search.types';

export const clearSearchAction = createAction('search/clear');
export const addHistoryQueryAction =
  createAction<SearchState['searchHistory']['queries'][number]>('search/addHistoryQuery');
export const addHistoryXrayQueryAction =
  createAction<SearchState['searchHistory']['xrayQuery'][number]>('search/addHistoryXrayQuery');
export const addHistoryEntityAction = createAction<HistoryEntitiesUnion>('search/addHistoryEntity');
export const removeHistoryQueryAction = createAction<number>('search/removeHistoryQuery');
export const removeHistoryXrayQueryAction = createAction<string>('search/removeHistoryXrayQuery');
export const removeHistoryEntityAction = createAction<number>('search/removeHistoryEntity');
export const toggleXrayModeAction = createAction<void>('search/toggleXrayMode');
export const manageXrayModeAction = createAction<boolean>('search/manageXrayMode');
