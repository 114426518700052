import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './languages-diff.styles';

const LanguagesDiff: FC = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'candidateLanguages'> = (el) => (
    <Styled.OptionWrapper key={el.id}>
      <Styled.OptionSource>{t(`options.languageOptions_${el.language}`)}</Styled.OptionSource>
      {el.languageLevel && (
        <>
          <b> / </b>
          <Styled.OptionDetails>{t(`options.languageLevelOptions_${el.languageLevel}`)}</Styled.OptionDetails>
        </>
      )}
    </Styled.OptionWrapper>
  );
  return <DiffBase multiple fieldName="candidateLanguages" labelTransformer={labelTransformer} {...props} />;
};

export default LanguagesDiff;
