import React from 'react';
import * as Styled from './proposal-section.styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { LabeledCheckbox } from '@shared-mui-components';
import { InfoTooltip } from '@shared-components';

const ProposalSection: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Styled.CheckBoxWrapper>
      <Controller
        name="offerChecked"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <LabeledCheckbox
              onChange={onChange}
              checked={value}
              label={t('tooltips.offerChecked')}
              error={!!errors?.offerChecked?.message}
            />
          );
        }}
      />
      <InfoTooltip placement="top-end" title={t('tooltips.offerStatusInfo')} />
    </Styled.CheckBoxWrapper>
  );
};

export default ProposalSection;
