import React from 'react';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import * as Styled from './not-duplicate-modal.styles';

interface NotDuplicateModalProps {
  submit: () => void;
}

const NotDuplicateModal: React.FC<NotDuplicateModalProps & ModalProps> = ({ close, submit }) => {
  const { t } = useTranslation();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.ModalTitle>{t('pages.candidates.consolidation.notDuplicate')}</Styled.ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('notifications.text.areYouSure')}</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.yes'),
            onClick: submit,
            highlighted: true,
            type: 'button',
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(NotDuplicateModal, 'not-duplicate-modal');
