import React from 'react';
import { FormControlLabelProps, SwitchProps as MuiSwitchProps } from '@mui/material';
import * as Styled from './labeled-switch.styles';
import { Switch } from '@innowise-group/mui-kit';

type SwitchProps = MuiSwitchProps & Pick<FormControlLabelProps, 'label' | 'labelPlacement'>;

const LabeledSwitch: React.FC<SwitchProps> = ({ label, labelPlacement, ...props }) => (
  <Styled.LabelContainer label={label} labelPlacement={labelPlacement} control={<Switch {...props} />} />
);

export default React.memo(LabeledSwitch);
