import { LifecycleStatuses, SortingValue } from '@constants';
import {
  ListItemsResponse,
  RequestItemResponse,
  RequestLoggerItemResponse,
  RequestsCandidateItemResponse,
} from '@innowise-group/core';
import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import qs from 'qs';

export default class RequestsService {
  static async createRequest(data: object): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/vacancy/requests`, data);
  }

  static async updateRequest(data: object, id: number): Promise<AxiosResponse> {
    return $api.put(`/recruiting-tool/api/v1/vacancy/requests/${id}`, data);
  }

  static async getVacancyRequests(
    id: number,
    page: number,
  ): Promise<AxiosResponse<{ content: RequestItemResponse[]; totalPages: number }>> {
    return $api.post<{ content: RequestItemResponse[]; totalPages: number }>(
      `/recruiting-tool/api/v1/vacancy/requests/search`,
      {
        vacancyIds: [id],
        lifecycleStatus: LifecycleStatuses.Actual,
      },
      {
        params: {
          page,
        },
      },
    );
  }

  static async getAllRequests(args: {
    params: { currentPage: number; pageSize: number; sort: SortingValue };
    body?: object;
  }): Promise<AxiosResponse<ListItemsResponse<RequestItemResponse>>> {
    const { body, params } = args;
    const sortItems = params.sort.split('%');
    const newData = {
      page: params.currentPage,
      size: params.pageSize,
      sort: sortItems,
    };
    return $api.post<ListItemsResponse<RequestItemResponse>>(
      `/recruiting-tool/api/v1/vacancy/requests/search`,
      { ...body },
      { params: newData, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) },
    );
  }

  static async getVacancyRequestById(id: number): Promise<AxiosResponse<RequestItemResponse>> {
    return $api.get<RequestItemResponse>(`/recruiting-tool/api/v1/vacancy/requests/${id}`);
  }

  static async deleteRequestById(id: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/vacancy/requests/${id}`);
  }

  static async requestsExport(body: object): Promise<AxiosResponse<Blob>> {
    return $api.post(
      '/recruiting-tool/api/v1/vacancy/requests/export',
      { ...body },
      {
        responseType: 'blob',
      },
    );
  }

  static async createVacancyResponses(vacancyRequestId: number, candidateId: number[]): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/vacancy/responses`, { vacancyRequestId, candidateId });
  }

  static async getSelectedCandidates(data: {
    vacancyRequestId: number;
  }): Promise<AxiosResponse<RequestsCandidateItemResponse[]>> {
    return $api.get<RequestsCandidateItemResponse[]>(
      `/recruiting-tool/api/v1/vacancy/responses/${data.vacancyRequestId}/all`,
    );
  }

  static async approveSelectedCandidatesFromVacancyResponse(params: {
    approval: boolean;
    vacancyResponseId: number;
  }): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/vacancy/responses/approval`, {}, { params });
  }

  static async deleteCandidateFromVacancyResponse(params: {
    candidateId: string;
    vacancyRequestId: string;
  }): Promise<AxiosResponse> {
    return $api.delete('/recruiting-tool/api/v1/vacancy/responses', { params });
  }

  static async getRequestByCandidateId(candidateId: string): Promise<AxiosResponse<RequestItemResponse>> {
    return $api.get(`/recruiting-tool/api/v1/vacancy/responses/candidate/${candidateId}`);
  }

  static async checkCandidateHistory(candidateId: number): Promise<AxiosResponse<boolean>> {
    return $api.get(`/recruiting-tool/api/v1/vacancy/requests/story/check`, { params: { candidateId } });
  }

  static async getHistoryByRequest(
    requestId: number,
    params: { page: number; size: number },
  ): Promise<AxiosResponse<ListItemsResponse<RequestLoggerItemResponse>>> {
    return $api.get(`/recruiting-tool/api/v1/vacancy/requests/story/${requestId}`, { params });
  }
}
