import { styled, Select as BaseSelect } from '@innowise-group/mui-kit';

export const Select = styled(BaseSelect)`
  &.MuiAutocomplete-root {
    min-width: 0;
  }
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.paper};
  }
  & .MuiFormHelperText-root {
    display: none;
  }
  & .MuiTextField-root {
    margin-top: 0;
  }
  & .MuiAutocomplete-tag.MuiButtonBase-root {
    margin: 0;
  }
  & .MuiInputLabel-root {
    padding-right: 10px;
  }
`;
