import { Button, Loader } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const FileItem = styled.div`
  position: relative;
  cursor: pointer;
  flex: 1;
  min-width: 0;
  ${({ theme }) => theme.fonts.primary[60]};
  padding: 0.6475rem 1.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  justify-content: center;
  max-width: 15rem;
  margin-top: 10px;
  margin-bottom: 1.0625rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[60]};
  & span {
    max-width: 9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const AddButton = styled(Button)<{ required: boolean }>`
  justify-content: start;
  width: auto;
  padding-left: 0;
  height: 2.5rem;
  position: relative;
  & > * {
    margin: 0;
  }
  &::after {
    display: block;
    content: ${({ required }) => (required ? '"*"' : '""')};
    color: ${({ theme }) => theme.palette.state.error.pressed};
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const FileItemLoader = styled(Loader)`
  margin: 1px 7px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  position: absolute;
  right: 85px;
  top: -2px;
  & button {
    width: fit-content;
    padding: 0;
    & > div {
      margin: 0;
    }
  }
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.state.error.default};
    & svg {
      fill: ${({ theme }) => theme.palette.state.error.default};
    }
  }
`;

export const FileButtonContainer = styled(ButtonContainer)`
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  & svg {
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;

export const SecureFileIconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  left: 0px;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;
