export enum CandidateTabsValues {
  'History' = 1,
  'Experience' = 2,
  'Education' = 3,
  'Files' = 4,
  'AboutMe' = 5,
  'Offer' = 6,
}

export enum CandidateGroupsValues {
  'MyCandidate' = 'myCandidates',
  'AllCandidates' = 'allCandidates',
  'Archive' = 'archive',
}

export enum CandidateCommentTabsValues {
  'Comments' = 1,
  'Changes' = 2,
}

export enum RequestGroupsValues {
  'MyRequests' = 'myRequests',
  'AllRequests' = 'allRequests',
  'Archive' = 'archive',
}
