export const calculateWordDeclension = (field: string, value: number) => {
  if (value % 100 === 11) {
    return field + 's';
  }
  if (value === 1 || value % 10 === 1) {
    return field;
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value < 10 || value > 20)) {
    return field + 'Declension';
  } else {
    return field + 's';
  }
};
