import { CandidateStatusColorsItem } from '@constants';
import { styled } from '@innowise-group/mui-kit';
import { Image } from '@shared-mui-components';

export const SectionContainer = styled.div`
  padding: 0 2.5rem 1.5rem 2.5rem;
  gap: 1.5rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const OptionalInfoContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1.5625rem;
  width: 20rem;
`;

export const CandidateInfoContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 1.25rem;
`;

export const NoImageContainer = styled.div`
  height: 9.375rem;
  width: 9.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

export const Avatar = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  height: 9.375rem;
  width: 9.375rem;
  flex-shrink: 0;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5625rem;
`;

export const StatusItem = styled.div<{ statusColors: CandidateStatusColorsItem }>`
  color: ${({ statusColors }) => statusColors?.color};
  border-radius: 0.5rem;
  border: 1px solid ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
  background-color: ${({ statusColors, theme }) =>
    statusColors?.backgroundColor || theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.fonts.primary[40]}
  padding: 0.875rem;
  position: relative;
  line-height: 1;
  height: 1rem;
  box-sizing: content-box;
  margin-top: 10px;
  text-align: center;
  max-width: 100%;
  width: 17rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(0.6) translateX(-40%);
    content: '';
    height: 80%;
    width: 0.75rem;
    background-color: ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
    border-radius: 0px 2rem 2rem 0px;
  }
`;

export const FullNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.primary[70]}
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const VacancyContainer = styled(FullNameContainer)`
  align-items: end;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const VacancyValue = styled.span`
  font-weight: 500;
`;

export const SubStatusValue = styled.div<{ statusColors: CandidateStatusColorsItem }>`
  ${({ theme }) => theme.fonts.primary[20]}
  color: ${({ statusColors }) => statusColors?.color};
`;
