import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, AppIconsType, Icon } from '@innowise-group/mui-kit';
import { EMOJIS } from '@constants';
import * as Styled from './auth-desktop-view.styles';

interface AuthProps {
  handleSignIn: () => void;
}

const AuthDesktopView: React.FC<AuthProps> = ({ handleSignIn }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Styled.DescriptionContainer>
        <Styled.DividersGroup position="top" />
        <Styled.EmojisContainer>
          {EMOJIS.map((emoji) => (
            <Styled.EmojiContainer key={emoji.name}>
              <Icon type={emoji.name as AppIconsType} size={emoji.size} viewBox={emoji.viewBox} />
            </Styled.EmojiContainer>
          ))}
        </Styled.EmojisContainer>
        <Styled.LightText type="h1">Innowise ITS Recruiting&Sourcing</Styled.LightText>
        <Styled.GoalsContainer>
          {t<string, string[]>('pages.auth.goals', { returnObjects: true }).map((goal, index, array) => (
            <React.Fragment key={index}>
              <Styled.GoalText key={goal}>{goal}</Styled.GoalText>
              {index !== array.length - 1 && <Styled.Divider />}
            </React.Fragment>
          ))}
        </Styled.GoalsContainer>
        <Styled.DividersGroup position="bottom" />
      </Styled.DescriptionContainer>
      <Styled.ContentSide>
        <Styled.ContentContainer>
          <Logo size="large" />
          <Styled.GrayText>&Sourcing</Styled.GrayText>
          <Styled.BlackText>ITS Recruiting </Styled.BlackText>
          <Styled.AuthButton onClick={handleSignIn}>
            <Icon type="color_google_icon" size={24} viewBox="0 0 26 28" />
            <Styled.AuthButtonText>Continue with Google</Styled.AuthButtonText>
          </Styled.AuthButton>
        </Styled.ContentContainer>
        <Styled.Version>
          {t('pages.version')}: {VERSION}
        </Styled.Version>
        <Styled.SupportLinkBlock>
          <Styled.SupportLink
            href="https://innowise-group.atlassian.net/servicedesk/customer/portals"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('buttons.support')}
          </Styled.SupportLink>
        </Styled.SupportLinkBlock>
      </Styled.ContentSide>
    </React.Fragment>
  );
};

export default AuthDesktopView;
