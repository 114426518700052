import { styled } from '@innowise-group/mui-kit';
import { DatePicker as DatePickerBase } from '@shared-mui-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .MuiPopover-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MuiPaper-root.MuiPaper-elevation {
    left: unset !important;
    top: unset !important;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[80]}
`;

export const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const DatePicker = styled(DatePickerBase)`
  .MuiPaper-root.MuiPaper-elevation {
    left: 0;
  }
`;
