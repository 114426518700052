import { styled, Icon } from '@innowise-group/mui-kit';

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

export const SecuredContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[40]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  max-width: 550px;
`;

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  max-width: 550px;
`;

export const ModalActions = styled.div`
  position: absolute;
  top: 0;
  right: -60px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ActionItem = styled.a`
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.paper};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & * {
    margin: 0;
    padding: 0;
  }

  & svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const ModalContainer = styled.form`
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 85%;
  max-width: 1080px;
  margin: 20px 90px;
  position: relative;
  min-width: 200px;
  width: auto;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    max-width: 80vw;
  }

  & .pg-viewer-wrapper img,
  & .photo-viewer-container {
    min-width: 200px;
    max-width: 700px;
    min-height: 200px;
    max-height: 700px;
    width: auto !important;
  }
  & .pg-viewer-wrapper img {
    object-fit: cover;
  }

  & .react-grid-Grid {
    min-height: 350px !important;
    min-width: 600px !important;
    max-width: 800px !important;
    max-height: 700px !important;
  }
  & .react-grid-Canvas {
    width: 100% !important;
    height: 100% !important;
  }
  & .react-grid-HeaderRow {
    width: 100% !important;
    height: 55px !important;
    cursor: default !important;
    pointer-events: none;
  }

  & .react-grid-Container {
    width: max-content !important;
  }
`;

export const BodyContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  max-width: 550px;
  white-space: pre-wrap;
`;

export const LinkItem = styled.a`
  text-decoration: none;
  transition: 0.2s;
  ${({ theme }) => theme.fonts.primary[50]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.info.default};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.info.hover};
  }
`;
