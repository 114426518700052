import { SortingValue } from '@constants';
import { VacancyItemResponse, ListItemsResponse, SearchAllVacanciesBody } from '@innowise-group/core';
import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import qs from 'qs';

export default class VacanciesService {
  static async getVacancyById(id: string): Promise<AxiosResponse<VacancyItemResponse>> {
    return $api.get<VacancyItemResponse>(`/recruiting-tool/api/v1/vacancies/${id}`);
  }

  static async getVacancyByIds(body: string[]): Promise<Pick<AxiosResponse<VacancyItemResponse[]>, 'data'>> {
    return $api.post<VacancyItemResponse[]>(`/recruiting-tool/api/v1/vacancies/getByIds`, body);
  }

  static async createVacancy(data: object): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/vacancies`, data);
  }

  static async updateVacancy(data: object, id: number): Promise<AxiosResponse> {
    return $api.put(`/recruiting-tool/api/v1/vacancies/${id}`, data);
  }

  static async getAllVacancies(): Promise<AxiosResponse<ListItemsResponse<VacancyItemResponse>>> {
    return $api.get<ListItemsResponse<VacancyItemResponse>>(`/recruiting-tool/api/v1/vacancies`);
  }

  static async searchAllVacancies(args: {
    params: { currentPage: number; pageSize: number; sort: SortingValue };
    body?: SearchAllVacanciesBody;
  }): Promise<AxiosResponse<ListItemsResponse<VacancyItemResponse>>> {
    const { body, params } = args;
    const sortItems = params.sort.split('%');
    const newData = {
      page: params.currentPage,
      size: params.pageSize,
      sort: sortItems,
    };
    return $api.post<ListItemsResponse<VacancyItemResponse>>(
      '/recruiting-tool/api/v1/vacancies/search',
      { ...body },
      { params: newData, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) },
    );
  }

  static async getCandidateVacancies(
    candidateId: string,
  ): Promise<AxiosResponse<ListItemsResponse<VacancyItemResponse>>> {
    return $api.post<ListItemsResponse<VacancyItemResponse>>(
      `/recruiting-tool/api/v1/vacancies/getOrderedByPriorityByVacancy`,
      {},
      { params: { candidateId } },
    );
  }

  static async exportVacancies(body: object): Promise<AxiosResponse<Blob>> {
    return $api.post(
      '/recruiting-tool/api/v1/vacancies/export',
      { ...body },
      {
        responseType: 'blob',
      },
    );
  }

  static async deleteVacancyById(id: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/vacancies/${id}`);
  }

  static async getCandidateLinkedVacancies(args: {
    params: { candidateId: number; currentPage: number; pageSize: number };
    body?: object;
  }): Promise<AxiosResponse<ListItemsResponse<VacancyItemResponse>>> {
    const { body, params } = args;
    const newData = {
      candidateId: params.candidateId,
      page: params.currentPage,
      size: params.pageSize,
      boosted: true,
    };
    return $api.post(
      `/recruiting-tool/api/v1/vacancies/getOrderedByPriorityByVacancy`,
      { ...body },
      { params: newData, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) },
    );
  }
}
