import { styled, Icon } from '@innowise-group/mui-kit';
import { ModalActions } from '../../file-preview-modal.styles';
import archiveBg from './archiveBG.svg';

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

export const ModalWrapper = styled.div`
  & > form {
    position: relative;
  }
`;

export const ArchiveModal = styled.div`
  width: 900px;
  height: 580px;
  background-image: url(${archiveBg});
  ${({ theme }) => theme.fonts.primary[100]}
  font-weight: 500;
  line-height: 27px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  & > span {
    position: absolute;
    top: 43%;
    left: 46%;
    transform: translate(-50%, -50%);
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 600px;
    height: 480px;
  }
`;

export const ModalArchiveActions = styled(ModalActions)`
  top: -40px;
  right: 120px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    top: -30px;
    right: 60px;
  }
`;
