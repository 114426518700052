import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationSchemaRecruiterMode = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      vacancyId: Yup.string().required(t('errors.requiredField')),
      vacancyName: Yup.string(),
      requestId: Yup.string(),
    });
  }, [t]);

  return { validationSchema };
};
