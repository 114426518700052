import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import {
  CandidateEventCommentResponse,
  CandidateStatusListItem,
  ListItemsResponse,
  StatusItem,
  StatusItemResponse,
} from '@innowise-group/core';
import { CandidateLifecycleStatuses } from '@constants';
import qs from 'qs';

export default class StatusesService {
  static async getStatusList(): Promise<AxiosResponse<CandidateStatusListItem[]>> {
    return $api.get<CandidateStatusListItem[]>(`/recruiting-tool/api/v1/status`);
  }

  static async createStatus(status: StatusItem): Promise<AxiosResponse<StatusItemResponse>> {
    return $api.post<StatusItemResponse>(`/recruiting-tool/api/v1/candidate-events`, status);
  }

  static async createThread(comment: object): Promise<AxiosResponse<CandidateEventCommentResponse>> {
    return $api.post(`/recruiting-tool/api/v1/candidate-comments`, comment);
  }

  static async updateStatus(status: StatusItem): Promise<AxiosResponse<StatusItemResponse>> {
    return $api.put<StatusItemResponse>(`/recruiting-tool/api/v1/candidate-events`, status);
  }

  static async restoreEvent(eventId: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/candidate-events/restore`, [eventId]);
  }

  static async updateThread(comment: object): Promise<AxiosResponse<CandidateEventCommentResponse>> {
    return $api.put(`/recruiting-tool/api/v1/candidate-comments`, comment);
  }

  static async restoreThread(commentId: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/candidate-comments/restore`, [commentId]);
  }

  static async getCandidateEvents(
    candidateId: number,
    page: number,
    candidateLifecycleStatus: CandidateLifecycleStatuses,
  ): Promise<AxiosResponse<ListItemsResponse<StatusItemResponse>>> {
    return $api.get<ListItemsResponse<StatusItemResponse>>(`/recruiting-tool/api/v1/candidate-events`, {
      params: {
        candidateId,
        page,
        candidateLifecycleStatus,
        sort: ['lifecycleStatus', 'createdDate,DESC'],
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  }

  static async deleteStatus(id: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/candidate-events/${id}`);
  }

  static async deleteThread(commentId: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/candidate-comments`, { params: { commentId } });
  }

  static async resolveStatusClaim(data: object): Promise<AxiosResponse<void>> {
    return $api.post(`/recruiting-tool/api/v1/claim`, data);
  }

  static async getActualStatus(candidateId: number): Promise<AxiosResponse<StatusItemResponse>> {
    return $api.get<StatusItemResponse>(`/recruiting-tool/api/v1/candidate-events/status/actual`, {
      params: { candidateId },
    });
  }

  static async setStatusesOrders(data: { [key: string]: number | null }): Promise<AxiosResponse<void>> {
    return $api.post(`/recruiting-tool/api/v1/status/order`, data);
  }
}
