import { styled, NamedSection as BaseNamedSection } from '@innowise-group/mui-kit';
import { Link } from 'react-router-dom';

export const NamedSection = styled(BaseNamedSection)`
  padding: 0.75rem;
  margin-top: 0.5rem;
`;

export const HRMTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[60]};
`;

export const HRMContainer = styled.div`
  display: flex;
  gap: 5rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[40]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
  }
`;

export const HRMLink = styled(Link)`
  ${({ theme }) => theme.fonts.primary[60]};
  text-decoration: underline;
  transition: 0.2s;
  &,
  &:visited {
    color: ${({ theme }) => theme.palette.info.default};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.info.hover};
  }
`;
