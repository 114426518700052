import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, NotificationMessage, NotificationsService, PushNotification } from '@innowise-group/core';
import { getUnreadNotificationsCountMapper } from './notifications.mappers';

export const getUnreadNotificationsCountThunk = createAsyncThunk<
  ReturnType<typeof getUnreadNotificationsCountMapper>,
  void,
  { rejectValue: MyKnownError }
>('notifications/getUnreadNotificationsCountThunk', async (_, { rejectWithValue }) => {
  try {
    const { data } = await NotificationsService.getUnreadNotificationsCount();
    return getUnreadNotificationsCountMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchAllNotificationsThunk = createAsyncThunk<
  Awaited<ReturnType<typeof NotificationsService.searchAllNotifications>>['data'],
  Parameters<typeof NotificationsService.searchAllNotifications>[0],
  { rejectValue: MyKnownError }
>('notifications/search', async (data, { rejectWithValue }) => {
  try {
    const response = await NotificationsService.searchAllNotifications(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const changePinnedNotificationsOrderThunk = createAsyncThunk<
  number[],
  {
    pinnedIds: number[];
    rest: number[];
  },
  { rejectValue: MyKnownError }
>('notifications/changePinnedNotificationsOrder', async ({ pinnedIds, rest }, { rejectWithValue }) => {
  try {
    const pinnedNotificationsWithPriority = pinnedIds.reduce(
      (acc, id, index) => {
        return {
          ...acc,
          notificationIdPriorityMap: {
            ...acc.notificationIdPriorityMap,
            [id]: index,
          },
        };
      },
      { notificationIdPriorityMap: {} },
    );
    await NotificationsService.changePinnedNotifications(pinnedNotificationsWithPriority);
    return [...pinnedIds, ...rest];
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getMessageByIdThunk = createAsyncThunk<
  Omit<PushNotification, 'newNotificationId'> & { data: NotificationMessage },
  PushNotification,
  { rejectValue: MyKnownError }
>('notifications/getMessageById', async ({ newNotificationId, ...rest }, { rejectWithValue }) => {
  try {
    const { data } = await NotificationsService.getMessageById(newNotificationId);
    return {
      ...rest,
      data,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteMessagesThunk = createAsyncThunk<
  Parameters<typeof NotificationsService.deleteMessages>[0],
  Parameters<typeof NotificationsService.deleteMessages>[0],
  { rejectValue: MyKnownError }
>('notifications/deleteMessages', async (args, { rejectWithValue }) => {
  try {
    await NotificationsService.deleteMessages(args);
    return args;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const changeMessagesPinStatusThunk = createAsyncThunk<
  Parameters<typeof NotificationsService.changeMessagesPinStatus>[0]['notifications'],
  Parameters<typeof NotificationsService.changeMessagesPinStatus>[0],
  { rejectValue: MyKnownError }
>('notifications/changeMessagesPinStatus', async (args, { rejectWithValue }) => {
  try {
    await NotificationsService.changeMessagesPinStatus(args);
    return args.notifications;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const changeMessagesViewedStatusThunk = createAsyncThunk<
  void,
  Parameters<typeof NotificationsService.changeMessagesViewedStatus>[0],
  { rejectValue: MyKnownError }
>('notifications/changeMessagesViewedStatus', async (args, { rejectWithValue }) => {
  try {
    await NotificationsService.changeMessagesViewedStatus(args);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
