import { createAction } from '@reduxjs/toolkit';
import { AdministrationState, ShortEmployeeItem } from './administration.types';
import { RolesValues } from '@constants';

export const resetEmployeesAdministrationsStateAction = createAction(
  'administration/resetEmployeesAdministrationsState',
);
export const setPagableAction = createAction<AdministrationState['employees']['pagable']>('administration/setPagable');
export const updateUserSelectedAuthDataPermissionsAction = createAction<{
  id: number;
  selectedIds: string[];
  entity: keyof Pick<
    ShortEmployeeItem['authData'],
    'permittedProfessions' | 'permittedVacancies' | 'permittedVacancyRequestIds'
  >;
}>('administration/updateUserSelectedAuthDataPermissions');

export const selectAllUserAuthDataPermissionsAction = createAction<{
  id: number;
  entity: keyof Pick<
    ShortEmployeeItem['authData'],
    'permittedProfessions' | 'permittedVacancies' | 'permittedVacancyRequestIds'
  >;
}>('administration/selectAllUserAuthDataPermissions');

export const unSelectAllUserAuthDataPermissionsAction = createAction<{
  id: number;
  entity: keyof Pick<
    ShortEmployeeItem['authData'],
    'permittedProfessions' | 'permittedVacancies' | 'permittedVacancyRequestIds'
  >;
}>('administration/unSelectAllUserAuthDataPermissions');

export const resetEmployeeFromHrmByEmailAction = createAction('administration/resetEmployeeFromHrmByEmail');

export const setNewEmployeeRoleFromHrmAction = createAction<RolesValues>('administration/setNewEmployeeRoleFromHrm');

export const updateExistingUserRoleAction = createAction<{ id: number; newRole: RolesValues }>(
  'administration/updateExistingUserRole',
);

export const clearCurrentStatusAction = createAction('administration/clearCurrentStatus');
export const clearReferralStateAction = createAction('administration/clearReferralState');
export const clearUnitsStateAction = createAction('administration/clearUnitsState');
export const clearSourcingStateAction = createAction('administration/clearSourcingState');
