import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './question-modal.styles';
import { useFaqAPI } from '@innowise-group/core';

interface QuestionModalProps {
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  title: string;
  description: string | ReactElement;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const QuestionModal: React.FC<ModalProps & QuestionModalProps> = ({
  close,
  title,
  description,
  submitButtonTitle,
  cancelButtonTitle,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { updating } = useFaqAPI();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{title}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.BodyContainer>{description}</Styled.BodyContainer>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'center'}
        actions={[
          {
            title: cancelButtonTitle || t('buttons.cancel'),
            onClick: onCancel || close,
            type: 'reset',
            highlighted: false,
          },
          {
            title: submitButtonTitle || t('buttons.ok'),
            onClick: onSubmit,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
      {updating && <Styled.LoadingFrame />}
    </Modal.Container>
  );
};

export default modalHoc(QuestionModal, 'question-modal');
