import { AllRolesArray, RolesValues } from '@constants';
import * as Styled from './tabs.styles';
import { useProfileAPI } from '@innowise-group/core';

export interface TabProps extends React.HTMLAttributes<HTMLLIElement> {
  label?: string;
  value?: unknown;
  tabsvalue?: unknown;
  path?: string;
  roles?: RolesValues[];
}

const Tab: React.FC<TabProps> = ({
  roles = AllRolesArray,
  label,
  children,
  tabsvalue,
  value,
  path = '#',
  ...props
}) => {
  const { profile } = useProfileAPI();
  const selected = tabsvalue === value;

  if (!roles.includes(profile?.role?.name)) return null;
  return (
    <Styled.TabContainer {...props} selected={selected}>
      <Styled.NavigationLink to={path} selected={selected}>
        <Styled.Label>{label || children}</Styled.Label>
      </Styled.NavigationLink>
    </Styled.TabContainer>
  );
};

export default Tab;
