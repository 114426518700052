export enum DateFormats {
  Default = 'yyyy/MM/dd HH:mm:ss',
  MonthFirst = 'MM/dd/yyyy',
  Short = 'MM-dd-yy',
  YearFirst = 'yyyy-MM-dd',
  YearFirstWithoutDay = 'yyyy-MM',
  DayFirst = 'dd.MM.yyyy',
  DaySlashFirst = 'dd/MM/yyyy',
  DayAndMonth = 'dd.MM',
  ISO = "yyyy-MM-dd'T'HH:mm:ss",
  WithTime = 'yyyy-MM-dd HH:mm:ss',
  OnlyTime = 'HH:mm',
  MonthAndYear = 'MM.yyyy',
  MonthAndYearSlash = 'MM/yyyy',
  DayAndMonthWithHoursAndMinutes = 'dd/MM HH:mm',
  LongLocalizedDate = 'PPP',
  LongWeekDate = 'cccccc',
  LongMonthDate = 'LLLL',
  ShortYearDate = 'yy',
}
