export const MaxJobsCount = 100;
export const MaxEducationsCount = 100;
export const MaxCoursesCount = 100;

export const MaxProfessionsCount = 5;
export const MaxLanguagesCount = 5;

export const MaxFilesCount = 14;
export const MaxRefsCount = 5;

export const MaxSearchCharactersCount = 60;
export const DatePickerFieldSize = 150;

export const MaxChipLength = 200;

export const MaxInterestedStatusPerson = 5;
export const MAX_CLAIMS_COUNT = 3;
export const MaxCityNameCharacters = 50;

export const MaxSubStatusesCount = 14;
