import { styled, Loader as BaseLoader } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const Loader = styled(BaseLoader)`
  margin: 9px;
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const DatePickerContainer = styled.div`
  & .MuiTextField-root {
    width: 100%;
  }
  flex: 1;
`;
