import { SelectOption, useCalculatePosition, useClickOutside } from '@innowise-group/core';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../chip';
import { Loader } from '../../../loader';
import * as Styled from './select.styles';
import { filteredSearchName, shortenText } from '@innowise-group/utilities';
import { MaxSearchCharactersCount } from '@constants';
import {
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  DropdownList,
} from '../../../dropdown-list-item';
import { Icon } from '../../../icon';

// TODO: focus bug
export interface SelectProps extends React.HTMLAttributes<HTMLDivElement> {
  wrapperRef?: MutableRefObject<HTMLDivElement>;
  options?: SelectOption[];
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  value: string;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  withSearch?: boolean;
  onNoValueFoundText?: string;
  renderCustomListItem?: (
    value: SelectOption,
    onClick: () => void,
    selected: boolean,
    parentRef: React.MutableRefObject<HTMLDivElement>,
  ) => React.ReactNode;
  onValueChange?: (value: string) => void;
  onValueListChange?: (value: string[]) => void;
  onChangeSearchValue?: (value: string) => void;
  asyncValueTransformer?: (value: string | undefined) => Promise<string>;
  valueTransformer?: (value: string) => string;
  clearable?: boolean;
  size?: 'default' | 'small' | 'extra-small' | 'large';
  isFieldChanged?: boolean;
  multiple?: boolean;
  maxChips?: number;
  position?: 'absolute' | 'fixed' | 'static';
  onClickOutside?: () => void;
}

const Select: React.FC<SelectProps> = ({
  options = [],
  error,
  disabled = false,
  onClick,
  onClickOutside,
  value,
  withSearch = true,
  placeholder = 'Select',
  onNoValueFoundText,
  onValueChange,
  onValueListChange,
  onChangeSearchValue,
  asyncValueTransformer,
  valueTransformer,
  clearable = false,
  defaultValue,
  size,
  multiple = false,
  maxChips,
  position = 'absolute',
  wrapperRef,
  className,
  renderCustomListItem,
  ...elementProps
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [transformedValue, setTransformedValue] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(!!asyncValueTransformer);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const dropDownListRef = useRef<HTMLDivElement>(null);
  const isMounted = useRef(false);

  const { verticalDirection } = useCalculatePosition(dropDownListRef, isOpen, wrapperRef);
  const { t } = useTranslation();

  const closeSelect = useCallback(() => {
    setIsOpen(false);
    onClickOutside?.();
  }, [onClickOutside]);

  const ref = useClickOutside<HTMLDivElement>(closeSelect, isOpen);

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
    setIsFocused(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (multiple && value) {
      setSelectedList(value.split(', '));
    }
  }, [value]);

  useEffect(() => {
    isMounted.current = true;
    if (valueTransformer) {
      setTransformedValue(multiple ? value : shortenText(valueTransformer(value || (defaultValue as string)), size));
      if (!value) {
        setSelectedList([]);
      }
      setIsLoading(false);
    } else if (asyncValueTransformer) {
      asyncValueTransformer(value)
        .then((value) => {
          if (isMounted.current) {
            setTransformedValue(shortenText(value, size));
            setIsLoading(false);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      return () => {
        isMounted.current = false;
        setIsLoading(false);
      };
    } else {
      setTransformedValue(multiple ? value : shortenText(value, size));
      setIsLoading(false);
    }
    return () => {
      isMounted.current = false;
      setIsLoading(false);
    };
  }, [asyncValueTransformer, valueTransformer, value, defaultValue, size]);

  const onClearSelectValueClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!disabled) {
      setTransformedValue('');
      onValueChange?.('');
    }
  };

  const handleInputFocus = () => {
    if (!disabled) {
      setIsOpen((value) => !value);
    }
  };

  const handleOnChangeSearchValue = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value;
    if (newValue.length <= MaxSearchCharactersCount) {
      setSearch(newValue);
      if (onChangeSearchValue) {
        onChangeSearchValue(newValue);
      } else if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  const removeTag = (value: string) => (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    event.stopPropagation();
    const newSelectedList = selectedList.filter((item) => item !== value);
    setSelectedList(newSelectedList.length ? newSelectedList : []);
    onValueListChange?.(newSelectedList.length ? newSelectedList : []);
  };

  const onOptionClicked = (value: string) => {
    if (!multiple) {
      setIsOpen(false);
      onValueChange?.(value);
      return;
    }
    const isItemSelected = selectedList.find((item) => item === value);
    const newSelectedList = isItemSelected ? selectedList.filter((item) => item !== value) : selectedList.concat(value);
    if (maxChips < newSelectedList.length) return;
    setSelectedList(newSelectedList.length ? newSelectedList : []);
    onValueListChange?.(newSelectedList.length ? newSelectedList : []);
  };

  const renderList = () => {
    const renderArray = options.filter((item) => {
      return (
        !withSearch ||
        (item.title || '').toLowerCase().includes(filteredSearchName(search)) ||
        (item.title || '').split(' ').reverse().join(' ').toLowerCase().includes(filteredSearchName(search))
      );
    });

    return !renderArray?.length ? (
      <DropdownListItem readonly type="button">
        {onNoValueFoundText ? onNoValueFoundText : t('buttons.noOptions')}
      </DropdownListItem>
    ) : (
      renderArray.map((item) => {
        const handleSelect = () => {
          onOptionClicked(item.value);
        };
        const isChecked = selectedList?.find((curr) => curr === item.value);
        const disabled = maxChips <= selectedList.length && !isChecked;
        return multiple ? (
          <Styled.ListItemContainer key={item.value} onClick={handleSelect}>
            <DropdownListItem selected={item.value === value} disabled={disabled} type="button">
              {item.title}
            </DropdownListItem>
            <Styled.InputCheckbox type="checkbox" readOnly disabled={disabled} checked={!!isChecked} />
          </Styled.ListItemContainer>
        ) : renderCustomListItem ? (
          renderCustomListItem(item, handleSelect, item.value === value, dropDownListRef)
        ) : (
          <ListItem
            key={item.value}
            handleSelect={handleSelect}
            selected={item.value === value}
            title={item.title}
            parentRef={dropDownListRef}
          />
        );
      })
    );
  };

  const renderSelectHeader = () => {
    if (withSearch && isOpen) {
      return (
        <>
          <Styled.DropDownSearchInput
            autoFocus
            onChange={handleOnChangeSearchValue}
            placeholder={placeholder}
            value={search}
            disabled={disabled}
          />
          {!disabled && <Styled.ExpandIcon isOpen={isOpen} isPointer type="u_angle-down" size={24} />}
        </>
      );
    }

    return transformedValue ? (
      <DropdownListItem readonly noPadding isHeader type="button">
        {onValueListChange ? (
          <Styled.TagsContainer>
            {transformedValue &&
              transformedValue
                .split(', ')
                .map((item) => (
                  <Chip
                    key={item}
                    title={valueTransformer ? valueTransformer(item) : item}
                    clearable
                    disabled={disabled}
                    onDeleteClick={removeTag(item)}
                  />
                ))}
          </Styled.TagsContainer>
        ) : (
          <Styled.DropDownSearchInput
            value={transformedValue}
            onChange={handleOnChangeSearchValue}
            placeholder={transformedValue}
            readOnly={disabled}
          />
        )}
        {clearable && !disabled ? (
          <Styled.ClearButton onClick={onClearSelectValueClick}>
            <Icon type="u_multiply" isPointer />
          </Styled.ClearButton>
        ) : (
          !disabled && <Styled.ExpandIcon isOpen={isOpen} isPointer type="u_angle-down" size={24} />
        )}
      </DropdownListItem>
    ) : (
      <>
        <Styled.DropDownSearchInput
          onChange={handleOnChangeSearchValue}
          placeholder={placeholder}
          value={search}
          disabled={disabled}
        />
        {!disabled && <Styled.ExpandIcon isOpen={isOpen} isPointer type="u_angle-down" size={24} />}
      </>
    );
  };

  return (
    <Styled.Select {...elementProps} ref={ref}>
      <DropdownContainer className={className} size={size}>
        <DropdownHeader disabled={disabled} isFocused={isFocused} onClick={onClick || handleInputFocus} error={error}>
          {isLoading ? <Loader size={20} /> : renderSelectHeader()}
        </DropdownHeader>
        {isOpen && !disabled && (
          <DropdownListContainer
            ref={dropDownListRef}
            vertical={verticalDirection}
            position={position}
            key={placeholder}
          >
            <DropdownList>{renderList()}</DropdownList>
          </DropdownListContainer>
        )}
      </DropdownContainer>
    </Styled.Select>
  );
};

const ListItem: React.FC<{
  handleSelect: () => void;
  title: string;
  selected: boolean;
  parentRef: React.MutableRefObject<HTMLDivElement>;
}> = ({ handleSelect, title, selected, parentRef }) => {
  const itemRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (selected && itemRef.current && parentRef.current) {
      const topPos = itemRef.current.offsetTop;
      parentRef.current.scrollTop = topPos;
    }
  }, [selected]);

  return (
    <DropdownListItem ref={itemRef} onClick={handleSelect} selected={selected} type="button">
      {title}
    </DropdownListItem>
  );
};

export default Select;
