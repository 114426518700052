import { Checkbox as MuiCheckbox } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const Checkbox = styled(MuiCheckbox)<{ $error?: boolean }>`
  &.MuiCheckbox-root {
    padding: 0;
    width: min-content;
    &.Mui-checked {
      color: ${({ theme, checked, defaultChecked }) => {
        return checked || defaultChecked ? theme.palette.general.smart : theme.palette.general.darkGrey[60];
      }};
      opacity: ${({ disabled }) => {
        return disabled ? 0.5 : 1;
      }};
    }
  }
  & svg {
    fill: ${({ theme, checked, defaultChecked, $error }) => {
      return $error || checked || defaultChecked ? theme.palette.general.smart : theme.palette.general.darkGrey[60];
    }};
  }
`;
