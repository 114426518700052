import { EmitterEvents } from '@constants';
import { EventEmitter } from 'events';

const emitter = new EventEmitter();

class EmitterService {
  static subscribe<TArgs>(eventName: EmitterEvents, listener: (args: TArgs) => void): EventEmitter {
    return emitter.addListener(eventName, listener);
  }

  static unsubscribe(eventName: EmitterEvents, listener: (...args: unknown[]) => void): EventEmitter {
    return emitter.removeListener(eventName, listener);
  }

  static emit(eventName: EmitterEvents, ...args: unknown[]): boolean {
    return emitter.emit(eventName, ...args);
  }
}

export default EmitterService;
