import { FC, HTMLAttributes, memo } from 'react';
import * as Styled from './no-results.styles';
import { useTranslation } from 'react-i18next';

interface NoresultsProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  text?: string;
}

const NoResults: FC<NoresultsProps> = ({ title, text, ...props }) => {
  const { t } = useTranslation();
  const defaultTitle = t('filters.notFoundDefaultTitle');
  const defaultText = t('filters.notFoundDefaultText');
  return (
    <Styled.NotFoundContainer {...props}>
      <Styled.NotFoundTitle>{title || defaultTitle}</Styled.NotFoundTitle>
      <Styled.NotFoundText>{text || defaultText}</Styled.NotFoundText>
    </Styled.NotFoundContainer>
  );
};

export default memo(NoResults);
