import { useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CurrencyEnum } from '@constants';

const MIN_REQUIRED_COUNT = 1;
const MAX_REQUIRED_COUNT = 50;
const MIN_REQUIRED_LENGTH_COUNT = 1;
const MAX_REQUIRED_LENGTH_COUNT = 2;
const MAX_COMMENT_LENGTH = 2500;
const MAX_EMPLOYEES = 10;
const MIN_CANDIDATE_SALARY = 1;
const MAX_CANDIDATE_SALARY_BYN = 100000;
const MAX_CANDIDATE_SALARY_EUR = 25000;
const MAX_CANDIDATE_SALARY_PLN = 120000;
const MAX_CANDIDATE_SALARY_USD = 30000;

export const useValidationSchemaRequest = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      {
        asEmployee: Yup.boolean(),
        onProject: Yup.boolean(),
        structuralUnit: Yup.string().required(t('errors.requiredField')),
        currency: Yup.string().required(t('errors.requiredField')),
        expectedSalaryMin: Yup.string()
          .trim()
          .transform((value) => value || '')
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.PLN,
            then: (schema) =>
              schema.when('expectedSalaryMin', {
                is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 6,
                then: (schema) =>
                  schema.matches(
                    /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.BYN,
            then: (schema) =>
              schema.when('expectedSalaryMin', {
                is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 6,
                then: (schema) =>
                  schema.matches(
                    /^100000$/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.USD,
            then: (schema) =>
              schema.when('expectedSalaryMin', {
                is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 5,
                then: (schema) =>
                  schema.matches(
                    /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.EUR,
            then: (schema) =>
              schema.when('expectedSalaryMin', {
                is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 5,
                then: (schema) =>
                  schema.matches(
                    /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                  ),
              }),
          })
          .test('test', t('errors.salaryDifferenceMin'), function (salaryMin: string) {
            if (!parseInt(salaryMin) || !parseInt(this.parent.expectedSalaryMax)) return true;
            return parseInt(salaryMin) <= parseInt(this.parent.expectedSalaryMax);
          }),
        expectedSalaryMax: Yup.string()
          .trim()
          .transform((value) => value || '')
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.PLN,
            then: (schema) =>
              schema.when('expectedSalaryMax', {
                is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 6,
                then: (schema) =>
                  schema.matches(
                    /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.BYN,
            then: (schema) =>
              schema.when('expectedSalaryMax', {
                is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 6,
                then: (schema) =>
                  schema.matches(
                    /^100000$/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.USD,
            then: (schema) =>
              schema.when('expectedSalaryMax', {
                is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 5,
                then: (schema) =>
                  schema.matches(
                    /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                  ),
              }),
          })
          .when(['currency'], {
            is: (currency: string) => currency === CurrencyEnum.EUR,
            then: (schema) =>
              schema.when('expectedSalaryMax', {
                is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 5,
                then: (schema) =>
                  schema.matches(
                    /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                  ),
                otherwise: (schema) =>
                  schema.matches(
                    /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                    t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                  ),
              }),
          })
          .test('test', t('errors.salaryDifferenceMax'), function (salaryMax: string) {
            if (!parseInt(salaryMax) || !parseInt(this.parent.expectedSalaryMin)) return true;
            return parseInt(salaryMax) >= parseInt(this.parent.expectedSalaryMin);
          }),
        authorId: Yup.array()
          .of(
            Yup.object({
              title: Yup.string().required(),
              value: Yup.string().required(),
            }),
          )
          .max(MAX_EMPLOYEES, t('errors.maxAuthorsCount', { count: MAX_EMPLOYEES }))
          .min(1, t('errors.requiredField'))
          .required(t('errors.requiredField')),
        vacancyId: Yup.string().required(t('errors.requiredField')),
        vacancyRequestStatusId: Yup.string().required(t('errors.requiredField')),
        requiredCount: Yup.string()
          .trim()
          .required(t('errors.requiredField'))
          .min(
            MIN_REQUIRED_LENGTH_COUNT,
            t('errors.betweenNumbers', { min: MIN_REQUIRED_COUNT, max: MAX_REQUIRED_COUNT }),
          )
          .max(
            MAX_REQUIRED_LENGTH_COUNT,
            t('errors.betweenNumbers', { min: MIN_REQUIRED_COUNT, max: MAX_REQUIRED_COUNT }),
          )
          .when('requiredCount', {
            is: (requiredCount: string) => requiredCount?.length === 1,
            then: (schema) =>
              schema.matches(
                /^[1-9]$/,
                t('errors.betweenNumbers', { min: MIN_REQUIRED_COUNT, max: MAX_REQUIRED_COUNT }),
              ),
            otherwise: (schema) =>
              schema.matches(
                /(^50$)|(^[1-4]\d$)/,
                t('errors.betweenNumbers', { min: MIN_REQUIRED_COUNT, max: MAX_REQUIRED_COUNT }),
              ),
          }),
        cities: Yup.array(),
        countries: Yup.array(),
        comment: Yup.string().trim().max(MAX_COMMENT_LENGTH, t('errors.characterLimit')),
        offices: Yup.array(),
        workloads: Yup.array(),
        workFormats: Yup.array(),
        employmentForms: Yup.array(),
        hiringDeadline: Yup.date()
          .min(new Date(new Date().setHours(0, 0, 0, 0)), t('errors.earlierDate'))
          .nullable(),
      },
      [['requiredCount', 'requiredCount']],
    );
  }, [t]);

  return { validationSchema };
};
