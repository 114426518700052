import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import localStorage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, PERSIST } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
import { candidatesReducer } from './candidates';
import { requestsReducer } from './requests';
import { vacanciesReducer } from './vacancies';
import { profileReducer } from './profile';
import { employeesReducer } from './employees';
import { filtersReducer } from './filters';
import { notificationsReducer } from './notifications';
import { optionsReducer } from './options';
import { administrationReducer } from './administration';
import { statisticReducer } from './statistic';
import { ThunkErrors } from '@constants';
import { imagesReducer } from './images';
import { faqsReducer } from './faq';
import { searchReducer } from './search';
import { consolidationReducer } from './consolidation';
import { calendarReducer } from './calendar';

const searchPersistConfig = getPersistConfig({
  key: 'search',
  storage: localStorage,
  whitelist: ['searchHistory'],
  rootReducer: searchReducer,
});

const searchPersistReducer = persistReducer(searchPersistConfig, searchReducer);

const combinedReducer = combineReducers({
  candidates: candidatesReducer,
  vacancies: vacanciesReducer,
  requests: requestsReducer,
  profile: profileReducer,
  employees: employeesReducer,
  filters: filtersReducer,
  notifications: notificationsReducer,
  options: optionsReducer,
  administration: administrationReducer,
  statistic: statisticReducer,
  images: imagesReducer,
  faq: faqsReducer,
  consolidation: consolidationReducer,
  search: searchPersistReducer,
  calendar: calendarReducer,
});

export type AppReducerType = ReturnType<typeof combinedReducer>;

const rootReducer = (state: AppReducerType | undefined, action: AnyAction) => {
  if (action?.error?.name === ThunkErrors.Abort) {
    return state;
  }
  return combinedReducer(state, action);
};

const rootPersistConfig = getPersistConfig({
  key: 'root',
  storage,
  whitelist: ['candidates.candidatesConsolidation', 'filters', 'consolidation.unsavedCandidate'],
  rootReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
