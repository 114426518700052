import { PayloadAction } from '@reduxjs/toolkit';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';

interface Thunk<TReturn extends object = any>
  extends Promise<Omit<PayloadAction, 'payload'>>,
    Pick<BaseThunkAPI<any, any>, 'abort'> {
  unwrap: () => TReturn;
}

export default class ThunkEffects {
  static prev: { [key: string]: Thunk } = {};
  static takeLatest<TReturnUnwrapped extends object = any>(
    thunk: Thunk<TReturnUnwrapped>,
    prefix: string,
  ): Thunk<TReturnUnwrapped> {
    if (this.prev[prefix]) this.prev[prefix]?.abort();
    this.prev[prefix] = thunk;
    return thunk;
  }
}
