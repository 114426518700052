import React from 'react';
import * as Styled from '../../date-picker.styles';

interface DateWithoutDaysHeaderProps {
  date: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
  startYear: number;
  endYear: number;
}

const DateWithoutDaysHeader: React.FC<DateWithoutDaysHeaderProps> = ({
  date,
  decreaseYear,
  increaseYear,
  startYear,
  endYear,
}) => {
  return (
    <Styled.CalendarHeader>
      <Styled.ArrowButton variant="text" onClick={decreaseYear} disabled={date.getFullYear() <= startYear}>
        <Styled.IconItem type="u_angle-right-b" size={16} isLeft />
      </Styled.ArrowButton>
      <Styled.Value>{date.getFullYear()}</Styled.Value>
      <Styled.ArrowButton variant="text" onClick={increaseYear} disabled={date.getFullYear() >= endYear}>
        <Styled.IconItem type="u_angle-right-b" size={16} isRight />
      </Styled.ArrowButton>
    </Styled.CalendarHeader>
  );
};

export default DateWithoutDaysHeader;
