import { DefaultCandidateGeneralStatusId } from '@constants';
import { CandidatesState } from './candidates.types';

export const preloadedCandidatesState: CandidatesState = {
  candidates: {
    isLoading: false,
    isNotPrioritizeLoading: false,
    isPrioritizeLoading: false,
    isExportLoading: false,
    items: {},
    ids: [],
    currentCandidate: null,
    currentAvatarFile: null,
    totalElements: 0,
    totalPages: 0,
  },
  candidatesConsolidation: {
    targetCandidate: null,
    updatesCandidate: null,
    candidatesDiffs: null,
    unsavedCandidate: null,
    candidateDuplicates: {},
    candidateDuplicatesIds: [],
    isCheckedDuplicates: false,
  },
  filesToAttach: {
    isLoading: false,
    items: {},
    ids: [],
  },
  files: {
    isLoading: false,
    isCurrentFileLoading: false,
    items: {},
    ids: [],
    currentFile: null,
    deletedIds: [],
  },
  history: {
    isLoading: false,
    items: {},
    idsWithComments: [],
    ids: [],
    totalPages: 0,
  },
  report: {
    isLoading: false,
    items: {
      content: [],
      totalPages: 0,
    },
    pagable: {
      currentPage: 1,
      pageSizes: [
        { title: '10', value: '10' },
        { title: '20', value: '20' },
        { title: '50', value: '50' },
      ],
      pageSize: 10,
    },
  },
  recruiterMode: {
    vacancyId: 0,
    requestId: 0,
    vacancyName: '',
    isActive: false,
  },
  allStatuses: {
    ids: [],
    isLoading: false,
    items: {},
  },
  currentStatus: {
    form: {
      hasReminder: false,
      statusId: DefaultCandidateGeneralStatusId,
      vacancyId: null,
      comment: {
        comment: '',
        isProtected: false,
      },
    },
  },
  events: {
    ids: [],
    isLoading: false,
    items: {},
    totalPages: 0,
    deletedIds: [],
    deletedThreadIds: [],
    isStatusAlone: false,
  },
  offers: {
    isLoading: false,
    isFormLoading: false,
    ids: [],
    items: {},
  },
  claim: {
    isLoading: false,
    withRequestClaim: null,
  },
};
