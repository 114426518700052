import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './add-city-to-dictionary-modal.styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useOptionsApi } from '@innowise-group/core';
import { Input, Select, Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { AppLanguages, AppNameLanguages, MaxCityNameCharacters } from '@constants';

interface AddCityToDictionaryModalProps {
  countryId?: string;
  submitHandler: (value: DictionaryProps) => void;
}

interface DictionaryProps {
  countryId: string;
  cityNames: {
    [key: string]: string;
  };
}

const AddCityToDictionaryModal: React.FC<ModalProps & AddCityToDictionaryModalProps> = ({
  close,
  countryId,
  submitHandler,
}) => {
  const { t } = useTranslation();
  const { countryOptions } = useOptionsApi();

  const methods = useForm<DictionaryProps>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object().shape({
        countryId: Yup.string().required(t('errors.requiredField')),
        cityNames: Yup.object().shape(
          Object.entries(AppNameLanguages).reduce((acc, [key, value]) => {
            acc[value] = Yup.string()
              .trim()
              .max(MaxCityNameCharacters, t('errors.maxCharacters', { count: MaxCityNameCharacters }))
              .required(t('errors.requiredField'))
              .matches(
                key === AppLanguages.En ? /(^$)|(^[a-zA-Z-'’\s]+$)/ : /^[а-яА-ЯЁё-\s]+$/,
                key === AppLanguages.En ? t('errors.latinLettersCity') : t('errors.rusLettersCity'),
              );
            return acc;
          }, {}),
        ),
      }),
    ),
    defaultValues: {
      countryId: '',
      cityNames: Object.values(AppNameLanguages).reduce((acc, value) => {
        acc[value] = '';
        return acc;
      }, {}),
    },
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data: DictionaryProps) => {
    submitHandler(data);
    close();
  };

  useEffect(() => {
    if (countryId) {
      setValue('countryId', countryId);
    }
  }, [countryId]);

  return (
    <Modal.Container size="small">
      <FormProvider {...methods}>
        <Modal.Header onClose={close}>
          <Styled.ModalTitle>{t('pages.candidates.candidateCreation.newCity')}</Styled.ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <Styled.BodyContainer>
            <Styled.Description>{t('pages.candidates.candidateCreation.newCityDescription')}</Styled.Description>
            <Styled.FieldsBlock>
              <Controller
                name="countryId"
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => {
                  return (
                    <Select
                      value={value}
                      onChange={onChange}
                      disableClearable={!value}
                      options={countryOptions || []}
                      disableSortByAlphabet={false}
                      renderInput={(params) => {
                        return (
                          <Input
                            {...params}
                            error={!!errors.countryId?.message}
                            helperText={errors.countryId?.message}
                            label={t('pages.candidates.candidateCreation.country')}
                            placeholder={t('pages.candidates.candidateCreation.selectTheCountry')}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
              {Object.entries(AppNameLanguages).map(([key, item]) => {
                return (
                  <Controller
                    name={`cityNames.${item}`}
                    control={control}
                    key={item}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          value={value}
                          onChange={onChange}
                          error={!!errors.cityNames?.[item]?.message}
                          helperText={errors.cityNames?.[item]?.message}
                          label={`${t('pages.candidates.candidateCreation.city')} (${key})`}
                          placeholder={t('pages.candidates.candidateCreation.enterTheCity')}
                        />
                      );
                    }}
                  />
                );
              })}
            </Styled.FieldsBlock>
          </Styled.BodyContainer>
        </Modal.Body>
        <Modal.Actions
          noVerticalPadding={true}
          position={'center'}
          actions={[
            {
              title: t('buttons.cancel'),
              onClick: close,
              type: 'button',
            },
            {
              title: t('buttons.addToDictionary'),
              onClick: handleSubmit(onSubmit),
              disabled: !!Object.keys(errors).length,
              highlighted: true,
              type: 'button',
            },
          ]}
        />
      </FormProvider>
    </Modal.Container>
  );
};

export default modalHoc(AddCityToDictionaryModal, 'add-city-to-dictionary-modal');
