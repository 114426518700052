import { styled } from '@innowise-group/mui-kit';

export const FileViewerContainer = styled.div<{ width: number; height: number }>`
  min-width: 200px;
  max-width: 700px;
  min-height: 200px;
  max-height: 640px;
  width: auto;
  height: auto;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 550px;
    max-height: 500px;
  }
`;

export const ImageItem = styled.img<{ width: number; height: number }>`
  min-width: 200px;
  max-width: 700px;
  min-height: 200px;
  max-height: 640px;
  width: ${({ width }) => width}px !important;
  height: ${({ height }) => height}px !important;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 550px;
    max-height: 500px;
  }
`;
