import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@innowise-group/mui-kit';
import {
  useControlFormField,
  useCurrentCandidate,
  useResizeObserver,
  useStatusConfigById,
  useValidationSchemaCandidateStatus,
} from '@innowise-group/core';
import { DesktopView } from './desktop-view';
import { MobileView } from './mobile-view';

const AgreementSection: React.FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const currentCandidate = useCurrentCandidate();
  const { setValue, watch, resetField, clearErrors, trigger } = useFormContext();
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);
  const { validationSchema } = useValidationSchemaCandidateStatus(statusConfig, currentCandidate);
  const { handleAddItem } = useControlFormField(validationSchema);

  const agreements = watch('agreements');

  const getIsActive = useCallback((idx: number) => {
    return watch(`agreements.${idx}.isActive`);
  }, []);

  const handleRemoveItem = useCallback(
    (idx: number, onChange: (arr: object[]) => void) => () => {
      clearErrors(`agreements[${idx}]`);
      onChange(agreements.filter((_, index: number) => index !== idx));
    },
    [agreements],
  );

  const handleChangeIsActive = useCallback(
    (idx: number, onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        onChange(event, value);
        if (!value) {
          setValue(`agreements.${idx}.deadline`, null);
          clearErrors(`agreements.${idx}.deadline`);
        }
        trigger(`agreements`);
        trigger('eventDate');
      },
    [],
  );

  useEffect(() => {
    resetField('agreements');
  }, [resetField, statusId]);

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component
      handleAddItem={handleAddItem}
      handleRemoveItem={handleRemoveItem}
      getIsActive={getIsActive}
      handleChangeIsActive={handleChangeIsActive}
    />
  );
};

export default AgreementSection;
