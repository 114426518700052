const breakpointSizes = ['xs', 'sm', 'ss', 'md', 'lg', 'xl'] as const;

export type BreakpointValue = (typeof breakpointSizes)[number];

export type BreakpointValues = {
  [key in BreakpointValue]: number;
};
export interface Breakpoints {
  values: BreakpointValues;
  down: (key: BreakpointValue | number) => string;
  up: (key: BreakpointValue | number) => string;
  between: (keyStart: BreakpointValue | number, keyEnd: BreakpointValue | number) => string;
}

export const breakpointUtils: Pick<Breakpoints, 'down' | 'up' | 'between'> = {
  down(this: Breakpoints, key: BreakpointValue | number) {
    return `@media (max-width: ${this.values[key as BreakpointValue] - 0.02 || key}px)`;
  },
  up(this: Breakpoints, key: BreakpointValue | number) {
    return `@media (min-width: ${this.values[key as BreakpointValue] || key}px)`;
  },
  between(this: Breakpoints, keyStart: BreakpointValue | number, keyEnd: BreakpointValue | number) {
    return `@media (max-width: ${this.values[keyEnd as BreakpointValue] - 0.02 || keyEnd}px) and (min-width: ${
      this.values[keyStart as BreakpointValue] || keyStart
    }px)`;
  },
};

export const defaultBreakpoints: Breakpoints['values'] = {
  xs: 320,
  ss: 450,
  sm: 768,
  md: 992,
  lg: 1170,
  xl: 1400,
};
