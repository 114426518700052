import { Icon } from '../icon';
import * as Styled from './modal.styles';

export interface ModalHeaderProps {
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose, children }) => (
  <Styled.ModalHeader>
    {title ? <h3>{title}</h3> : children}
    {onClose && <Icon type="u_multiply" onClick={onClose} />}
  </Styled.ModalHeader>
);

export default ModalHeader;
