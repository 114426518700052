import { styled } from '@innowise-group/mui-kit';

export const ToggleButtonWrapper = styled.button<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 0.6875rem 10px 0.6875rem 10px;
  background: ${({ theme }) => theme.palette.general.paper};
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  border: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.palette.general.smart : theme.palette.general.lightGrey[60])};
  transition: border ${({ theme }) => theme.animation.transition};
`;

export const RemoveFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export const ToggleButtonTitle = styled.span`
  font-size: 1rem;
`;
