/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import * as _ from 'lodash';
import {
  CandidateItemResponse,
  CandidateLinkItemResponse,
  CandidateLocationResponse,
  CandidateVisaResponse,
  CountriesResponse,
  ShortEmployeeItemResponse,
  LocalizedCandidateItemResponse,
  CandidateItem,
} from '@innowise-group/core';
import { localizedNameObject } from '@innowise-group/utilities';
import {
  ComparedCandidateWithSeveralCandidatesItems,
  MappedTargetCandidateDtoWithPossibleVariants,
  TargetCandidateFieldWithPossibleDuplicatesVariants,
  DictionaryArrayDataFields,
  PossibleDuplicatesVariantsMap,
  PrimitiveDataFields,
  StringArrayDataFields,
  TargetCandidateDtoWithPossibleVariants,
  FieldDataWithCandidateDuplicateId,
  CandidateDictionaryDuplicates,
  MappedCandidateContacts,
  ContactsFields,
  MappedTargetCandidateStringDataArrays,
  MappedTargetCandidateVisaAvailabilityWithExpirationTime,
  RelocationDiff,
} from './consolidation.types';
import { ContactsType } from '@constants';

const dateNormalizer = (dateStr: string, isFullVisaDate: boolean) => {
  if (isFullVisaDate) return dateStr || '';
  if (!dateStr) return '';
  const date = new Date(dateStr),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  return firstDay.toString();
};

const timeRemoverFromDateString = (dateString: string) => {
  if (!dateString) return '';
  const [datePart] = dateString.split('T');
  return `${datePart}T00:00:00`;
};

export const normalizeDatesInCandidateItemResponse = <T extends CandidateItemResponse | CandidateItem>(
  candidate: T,
): T => {
  return {
    ...candidate,
    ...(candidate.birthDate && { birthDate: candidate.birthDate.toString() }),
    candidateVisas: candidate.candidateVisas.map((v) => ({
      ...v,
      ...(v.validTo && { validTo: v.validTo.toString() }),
    })),
    candidateEducations: candidate.candidateEducations.map((e) => ({
      ...e,
      ...(e.studiedTo && { studiedTo: e.studiedTo.toString() }),
      ...(e.studiedFrom && { studiedFrom: e.studiedFrom.toString() }),
    })),
    candidateExperiences: candidate.candidateExperiences.map((e) => ({
      ...e,
      ...(e.workedTo && { workedTo: e.workedTo.toString() }),
      ...(e.workedFrom && { workedFrom: e.workedFrom.toString() }),
    })),
    candidateCourses: candidate.candidateCourses.map((e) => ({
      ...e,
      ...(e.studiedTo && { studiedTo: e.studiedTo.toString() }),
      ...(e.studiedFrom && { studiedFrom: e.studiedFrom.toString() }),
    })),
  };
};

export const possibleDuplicatesMapper = (args: CandidateItemResponse[]) =>
  args.reduce(
    (acc, val) => {
      return {
        ...acc,
        ids: [...acc.ids, val.id.toString()],
        items: {
          ...acc.items,

          [val.id]: {
            ...val,
            ...localizedNameObject(val),
          },
        },
      };
    },
    { ids: [], items: {} },
  );

// -------------------------- HELPERS --------------------------
/**
 * @param {[{ country: CountriesResponse }[], { country: CountriesResponse }[]]} args
 * @returns {boolean}
 * @description Compares two arrays by ISO code with countries
 */
export const diffCompareCountriesArrayByIsoCode = (
  ...args: [{ country: CountriesResponse }[], { country: CountriesResponse }[]]
): boolean => {
  const [a, b] = args;
  if (a.length === 0 && b.length === 0) return true;
  if (a.length !== b.length) return false;
  return a.reduce((acc, val) => {
    if (!b.some((el) => el.country?.isoCode === val.country?.isoCode)) return false;
    return acc;
  }, true);
};

/**
 * @param {CandidateItemResponse[]} args
 * @returns {{ [key: string]: CandidateItemResponse }}
 * @description Creates a map of compared candidates
 */
export const mapCandidateItemResponseToMap = (
  args: CandidateItemResponse[],
): { [key: string]: LocalizedCandidateItemResponse } => {
  return args.reduce(
    (acc, val) => ({
      ...acc,
      [val.id.toString()]: {
        ...val,
        ...localizedNameObject(val),
      },
    }),
    {},
  );
};

// -------------------------- HELPERS --------------------------

/**
 * @param {{
 * acc: Partial<PossibleDuplicatesVariantsMap>;
 * possibleDuplicate: Partial<CandidateItemResponse>;
 * key: keyof PossibleDuplicatesVariantsMap;
 * }} args
 * @returns {Pick<PossibleDuplicatesVariantsMap, typeof args.key> | object}
 * @description Collects all data from possible duplicates from field
 */
const fieldMapper = (args: {
  acc: Partial<PossibleDuplicatesVariantsMap>;
  possibleDuplicate: Partial<CandidateItemResponse>;
  key: keyof PossibleDuplicatesVariantsMap;
}): Pick<PossibleDuplicatesVariantsMap, typeof args.key> | object => {
  const { acc, possibleDuplicate, key } = args;
  const duplicateVariant = { candidateId: possibleDuplicate.id?.toString() || '', data: possibleDuplicate[key] };
  return Object.prototype.hasOwnProperty.call(possibleDuplicate, key)
    ? {
        [key]: acc[key] ? [...acc[key], duplicateVariant] : [duplicateVariant],
      }
    : {};
};

/**
 * @param {Partial<CandidateItemResponse>[]} possibleDuplicates
 * @returns {Partial<PossibleDuplicatesVariantsMap>}
 * @description Collects all data from possible duplicates
 */
const concatPossibleDuplicatesVariantsMapper = (
  possibleDuplicates: Partial<CandidateItemResponse>[],
): Partial<PossibleDuplicatesVariantsMap> => {
  return possibleDuplicates.reduce<Partial<PossibleDuplicatesVariantsMap>>(
    (acc, possibleDuplicate) => ({
      ...acc,
      ...fieldMapper({ acc, possibleDuplicate, key: 'photoId' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'firstNameRu' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'lastNameRu' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'firstNameEn' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'lastNameEn' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'gender' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'aboutMe' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'birthDate' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'email' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'citizenships' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'isBlocked' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'isBlockedReason' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'photo' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'sourceDetails' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'visaAvailability' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'relocationAvailability' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'source' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'filesMeta' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateContacts' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateExperiences' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateProfessions' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateLanguages' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'responsibleEmployee' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateVisas' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateSalary' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateLocation' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateDomains' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateTechnologies' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateWorkFormats' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateWorkloads' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'employmentForms' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateRelocationCountries' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateSkills' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidatePrioritizedVacancies' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateLinks' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateEducations' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateCourses' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateOverallExperienceMonths' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateItSphereExperienceMonths' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'lastModifiedDate' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'lifecycleStatus' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'vacancyIdWithRequestAttached' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'readyForBusinessTrip' }),
      ...fieldMapper({ acc, possibleDuplicate, key: 'candidateReferral' }),
    }),
    {},
  );
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants[PrimitiveDataFields]} args
 * @returns {TargetCandidateDtoWithPossibleVariants[PrimitiveDataFields]}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffStringDataFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants[PrimitiveDataFields],
): TargetCandidateDtoWithPossibleVariants[PrimitiveDataFields] => {
  return {
    ...args,
    possibleDuplicates: args.possibleDuplicates.reduce<FieldDataWithCandidateDuplicateId<string>[]>(
      (acc, val, index, originArray) => {
        const isElementExistsInTarget = (val.data || '') === (args.target || '');
        const isUniqueElementInArray = originArray.findIndex((el) => (el.data || '') === (val.data || '')) === index;
        if (!isElementExistsInTarget && isUniqueElementInArray) {
          return [...acc, val];
        }
        return acc;
      },
      [],
    ),
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['birthDate']} args
 * @returns {TargetCandidateDtoWithPossibleVariants['birthDate']}
 * @description Removes similar and equal to target date values from possible duplicates
 */
export const diffDateFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants['birthDate'],
): TargetCandidateDtoWithPossibleVariants['birthDate'] => {
  return {
    ...args,
    possibleDuplicates: args.possibleDuplicates.reduce<FieldDataWithCandidateDuplicateId<string>[]>(
      (acc, val, index, originArray) => {
        const isElementExistsInTarget = timeRemoverFromDateString(val.data) === timeRemoverFromDateString(args.target);
        const isUniqueElementInArray =
          originArray.findIndex((el) => timeRemoverFromDateString(el.data) === timeRemoverFromDateString(val.data)) ===
          index;
        if (!isElementExistsInTarget && isUniqueElementInArray) {
          return [...acc, val];
        }
        return acc;
      },
      [],
    ),
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants[DictionaryArrayDataFields]} args
 * @returns {TargetCandidateDtoWithPossibleVariants[DictionaryArrayDataFields]}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffDictionaryArrayDataFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants[DictionaryArrayDataFields],
): MappedTargetCandidateDtoWithPossibleVariants[DictionaryArrayDataFields] => {
  const uniqueOptionsAmongPossibleDuplicates = args.possibleDuplicates
    .flatMap<CandidateDictionaryDuplicates>(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce((acc, val, index, originArray) => {
      const isElementExistsInTarget = args.target.some(
        ({ dictionaryValue }) => dictionaryValue.valueId === val.dictionaryValue.valueId,
      );
      const isUniqueElementInArray =
        originArray.findIndex((el) => el.dictionaryValue.valueId === val.dictionaryValue.valueId) === index;
      if (!isElementExistsInTarget && isUniqueElementInArray) {
        return [...acc, val];
      }
      return acc;
    }, []);
  return {
    ...args,
    possibleDuplicates: uniqueOptionsAmongPossibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants[StringArrayDataFields]} args
 * @returns {TargetCandidateDtoWithPossibleVariants[StringArrayDataFields]}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffStringArrayDataFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants[StringArrayDataFields],
): MappedTargetCandidateDtoWithPossibleVariants['candidateWorkloads' | 'employmentForms' | 'candidateWorkFormats'] => {
  const uniqueOptionsAmongPossibleDuplicates = args.possibleDuplicates.filter((el, idx, originArray) => {
    const isElementExistsInTarget = _.isEqual(el.data.sort(), args.target.sort());
    const isUniqueElement =
      originArray.findIndex((originEl) => {
        return _.isEqual(el.data.sort(), originEl.data.sort());
      }) === idx;
    return !isElementExistsInTarget && isUniqueElement;
  });
  return {
    ...args,
    possibleDuplicates: uniqueOptionsAmongPossibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateLanguages']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateLanguages']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffLanguagesArrayDataFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidateLanguages'],
): MappedTargetCandidateDtoWithPossibleVariants['candidateLanguages'] => {
  const uniqueOptionsAmongPossibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce<MappedTargetCandidateDtoWithPossibleVariants['candidateLanguages']['possibleDuplicates']>(
      (acc, val, index, originArray) => {
        const isElementExistsInTarget = args.target.some(
          ({ language, languageLevel }) => language === val.language && languageLevel === val.languageLevel,
        );
        const isUniqueElementInArray =
          originArray.findIndex(
            ({ language, languageLevel }) => language === val.language && languageLevel === val.languageLevel,
          ) === index;
        if (!isElementExistsInTarget && isUniqueElementInArray) {
          return [...acc, val];
        }
        return acc;
      },
      [],
    );
  return {
    ...args,
    possibleDuplicates: uniqueOptionsAmongPossibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateProfessions']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateProfessions']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffProfessionsArrayDataFieldsMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidateProfessions'],
): MappedTargetCandidateDtoWithPossibleVariants['candidateProfessions'] => {
  const uniqueOptionsAmongPossibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce<MappedTargetCandidateDtoWithPossibleVariants['candidateProfessions']['possibleDuplicates']>(
      (acc, val, index, originArray) => {
        const isElementExistsInTarget = args.target.some(
          ({ profession, grade }) =>
            profession.valueId === val.profession.valueId && (grade || '') === (val.grade || ''),
        );
        const isUniqueElementInArray =
          originArray.findIndex(
            ({ profession, grade }) =>
              profession.valueId === val.profession.valueId && (grade || '') === (val.grade || ''),
          ) === index;
        if (!isElementExistsInTarget && isUniqueElementInArray) {
          return [...acc, val];
        }
        return acc;
      },
      [],
    );
  return {
    ...args,
    possibleDuplicates: uniqueOptionsAmongPossibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['citizenships']} args
 * @returns {TargetCandidateDtoWithPossibleVariants['citizenships']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffCitizenshipMapper = (
  args: TargetCandidateDtoWithPossibleVariants['citizenships'],
): MappedTargetCandidateDtoWithPossibleVariants['citizenships'] => {
  const uniqueOptionsAmongPossibleDuplicates = args.possibleDuplicates.reduce<
    MappedTargetCandidateDtoWithPossibleVariants['citizenships']['possibleDuplicates']
  >((acc, val, index, originArray) => {
    const normalizedIdsArrayFromTarget = args.target.map(({ id }) => id?.toString());
    const normalizedIdsArrayFromVal = val.data.map(({ id }) => id?.toString());
    const isElementExistsInTarget = _.isEqual(normalizedIdsArrayFromTarget, normalizedIdsArrayFromVal);
    const isUniqueElementInArray =
      originArray.findIndex((el) => _.isEqual(_.pick(val.data, ['id']), _.pick(el.data, ['id']))) === index;
    if (!isElementExistsInTarget && isUniqueElementInArray) {
      return [...acc, val];
    }
    return acc;
  }, []);
  return {
    ...args,
    possibleDuplicates: uniqueOptionsAmongPossibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateLocation']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateLocation']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffLocationMapper = (
  args: TargetCandidateFieldWithPossibleDuplicatesVariants<CandidateItemResponse['candidateLocation']>,
): MappedTargetCandidateDtoWithPossibleVariants['candidateLocation'] => {
  const possibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => ({ ...data, candidateId }))
    .reduce<MappedTargetCandidateDtoWithPossibleVariants['candidateLocation']['possibleDuplicates']>(
      (acc, val, index, originArray) => {
        const isLocationExistsInTarget =
          (args.target.locationCity?.id?.toString() || null) === (val.locationCity?.id?.toString() || null) &&
          (args.target.locationCountry?.id?.toString() || null) === (val.locationCountry?.id?.toString() || null);
        const isCurrentLocationExistsInTarget =
          (args.target.currentLocationCity?.id?.toString() || null) ===
            (val.currentLocationCity?.id?.toString() || null) &&
          (args.target.currentLocationCountry?.id?.toString() || null) ===
            (val.currentLocationCountry?.id?.toString() || null);
        const isUniqueLocationInArray =
          originArray.findIndex(
            ({ locationCity, locationCountry }) =>
              (locationCity?.id?.toString() || null) === (val.locationCity?.id?.toString() || null) &&
              (locationCountry?.id?.toString() || null) === (val.locationCountry?.id?.toString() || null),
          ) === index;
        const isUniqueCurrentLocationInArray =
          originArray.findIndex(({ currentLocationCity, currentLocationCountry }) => {
            return (
              (currentLocationCity?.id?.toString() || null) === (val.currentLocationCity?.id?.toString() || null) &&
              (currentLocationCountry?.id?.toString() || null) === (val.currentLocationCountry?.id?.toString() || null)
            );
          }) === index;
        return [
          ...acc,
          ...((!isLocationExistsInTarget || !isCurrentLocationExistsInTarget) &&
          isUniqueLocationInArray &&
          isUniqueCurrentLocationInArray
            ? [
                {
                  locationCity: val.locationCity,
                  locationCountry: val.locationCountry,
                  currentLocationCity: val.currentLocationCity,
                  currentLocationCountry: val.currentLocationCountry,
                  candidateId: val.candidateId,
                },
              ]
            : []),
        ];
      },
      [],
    );
  return {
    target: {
      locationCity: args.target.locationCity,
      locationCountry: args.target.locationCountry,
      currentLocationCity: args.target.currentLocationCity,
      currentLocationCountry: args.target.currentLocationCountry,
    },
    possibleDuplicates,
  };
};

/**
 * @param {Pick<TargetCandidateDtoWithPossibleVariants, 'isBlocked' | 'isBlockedReason'>} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['blockedWithReason']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffBlockedMapper = (
  args: Pick<TargetCandidateDtoWithPossibleVariants, 'isBlocked' | 'isBlockedReason'>,
): MappedTargetCandidateDtoWithPossibleVariants['blockedWithReason'] => {
  return {
    target: {
      isBlocked: args.isBlocked.target,
      isBlockedReason: args.isBlockedReason.target,
    },
    possibleDuplicates: args.isBlockedReason.possibleDuplicates.reduce((acc, val, index, originArray) => {
      const isUniqueBlockedReasonInArray = originArray.findIndex(({ data }) => data === val.data) === index;
      const isReasonTheSameAsInTarget = val.data === args.isBlockedReason.target;
      if (!isReasonTheSameAsInTarget && isUniqueBlockedReasonInArray) {
        return [...acc, val];
      }
      return acc;
    }, []),
  };
};

/**
 * @param {Pick<TargetCandidateDtoWithPossibleVariants, 'source' | 'sourceDetails'>} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['sourceWithDetails']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffSourceWithDetailsAndReferralProgramMapper = (
  args: Pick<TargetCandidateDtoWithPossibleVariants, 'source' | 'sourceDetails' | 'candidateReferral'>,
): MappedTargetCandidateDtoWithPossibleVariants['sourceWithDetailsAndReferralProgram'] => {
  const mappedSourceWithDetails = args.source.possibleDuplicates.map((source) => {
    const sourceDetails =
      args.sourceDetails.possibleDuplicates.find((el) => el.candidateId === source.candidateId)?.data || '';
    const candidateReferral =
      args.candidateReferral.possibleDuplicates.find((el) => el.candidateId === source.candidateId) || null;
    return {
      candidateId: source.candidateId,
      source,
      sourceDetails,
      candidateReferral,
    };
  });
  return {
    target: {
      source: args.source.target,
      sourceDetails: args.sourceDetails.target,
      candidateReferral: args.candidateReferral.target,
    },
    possibleDuplicates: mappedSourceWithDetails.reduce((acc, val, index, originArray) => {
      const isExistsSourceInTarget = args.source.target.valueId === val.source.data.valueId;
      const isExistsSourceDetailsInTarget = args.sourceDetails.target === val.sourceDetails;

      const isEqualReferralReward =
        (args.candidateReferral.target?.reward || '')?.toString() ===
        (val.candidateReferral.data?.reward || '')?.toString();
      const isEqualReferralStartGrade =
        (args.candidateReferral.target?.startGrade || '') === (val.candidateReferral.data?.startGrade || '');
      const isEqualReferralReferralComment =
        (args.candidateReferral.target?.referralComment || '') === (val.candidateReferral.data?.referralComment || '');
      const isEqualReferralReferralRecommenderEmail =
        (args.candidateReferral.target?.recommender?.email || '')?.toString() ===
        (val.candidateReferral.data?.recommender?.email || '')?.toString();
      const isEqualReferral =
        isEqualReferralReward &&
        isEqualReferralStartGrade &&
        isEqualReferralReferralComment &&
        isEqualReferralReferralRecommenderEmail;

      const isExistsInTarget = isExistsSourceInTarget && isExistsSourceDetailsInTarget && isEqualReferral;

      const isUniqueElementInArray =
        originArray.findIndex((el) => {
          const isEqualSource = el.source.data.valueId === val.source.data.valueId;
          const isEqualSourceDetails = el.sourceDetails === val.sourceDetails;
          const isEqualReferralReward =
            el.candidateReferral?.data?.reward?.toString() === val.candidateReferral.data?.reward?.toString();
          const isEqualReferralStartGrade =
            el.candidateReferral?.data?.startGrade === val.candidateReferral.data?.startGrade;
          const isEqualReferralReferralComment =
            el.candidateReferral?.data?.referralComment === val.candidateReferral?.data?.referralComment;
          const isEqualReferralReferralRecommenderEmail =
            el.candidateReferral?.data?.recommender?.email?.toString() ===
            val.candidateReferral.data?.recommender?.email?.toString();
          const isEqualReferral =
            isEqualReferralReward &&
            isEqualReferralStartGrade &&
            isEqualReferralReferralComment &&
            isEqualReferralReferralRecommenderEmail;
          const isAllParametersEqual = isEqualSource && isEqualSourceDetails && isEqualReferral;
          return isAllParametersEqual;
        }) === index;
      if (!isExistsInTarget && isUniqueElementInArray) {
        return [...acc, val];
      }
      return acc;
    }, []),
  };
};

/**
 * @param {Pick<TargetCandidateDtoWithPossibleVariants, 'visaAvailability' | 'candidateVisas'>} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['visaAvailabilityWithExpirationTime']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffVisaAvailabilityMapper = (
  args: Pick<TargetCandidateDtoWithPossibleVariants, 'visaAvailability' | 'candidateVisas'>,
): MappedTargetCandidateDtoWithPossibleVariants['visaAvailabilityWithExpirationTime'] => {
  const mappedVisasAvailabilityWithDetails = args.visaAvailability.possibleDuplicates.map((availability) => {
    const visa = args.candidateVisas.possibleDuplicates.find(
      ({ candidateId }) => candidateId === availability.candidateId,
    );
    return {
      candidateId: visa.candidateId,
      visaAvailability: availability.data,
      isFullVisaDate: visa.data?.[0]?.isFullVisaDate,
      visaType: visa.data?.[0]?.visaType || {
        valueId: '',
        translation: '',
        parentId: '',
      },
      validTo: visa.data?.[0]?.validTo || '',
    };
  });
  const possibleDuplicates = mappedVisasAvailabilityWithDetails.reduce((acc, val, index, originArray) => {
    const inUniqueElementInArray =
      originArray.findIndex((e) => {
        return (
          e.visaType?.valueId === val.visaType?.valueId &&
          e.validTo === val.validTo &&
          e.visaAvailability === val.visaAvailability &&
          e.isFullVisaDate === val.isFullVisaDate
        );
      }) === index;

    const targetIsFullVisaDate = args.candidateVisas?.target?.[0]?.isFullVisaDate || false;
    const possibleVariantIsFullVisaDate = val.isFullVisaDate || false;

    const normalizedTargetValidToDate = dateNormalizer(args.candidateVisas?.target?.[0]?.validTo, targetIsFullVisaDate);
    const normalizedPossibleVariantValidTo = dateNormalizer(val.validTo, possibleVariantIsFullVisaDate);

    const isElementExistsInTarget =
      (args.visaAvailability.target || false) === val.visaAvailability &&
      normalizedTargetValidToDate === normalizedPossibleVariantValidTo &&
      targetIsFullVisaDate === possibleVariantIsFullVisaDate &&
      (args.candidateVisas?.target?.[0]?.visaType?.valueId || '') === val.visaType?.valueId;
    if (!isElementExistsInTarget && inUniqueElementInArray) {
      return [...acc, val];
    }
    return acc;
  }, []);
  const target: MappedTargetCandidateVisaAvailabilityWithExpirationTime['target'] = {
    visaAvailability: args.visaAvailability.target,
    ...(args.candidateVisas.target?.[0]
      ? {
          ...args.candidateVisas.target?.[0],
          visaTypeId: args.candidateVisas.target?.[0]?.visaType?.valueId,
        }
      : {
          visaType: {
            valueId: '',
            translation: '',
            parentId: '',
          },
          validTo: '',
          visaTypeId: '',
        }),
  };
  return {
    target: target,
    possibleDuplicates,
  };
};

/**
 * @param {Pick<TargetCandidateDtoWithPossibleVariants, 'visaAvailability' | 'candidateVisas'>} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['visaAvailabilityWithExpirationTime']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffRelocationAvailabilityMapper = (
  args: Pick<
    TargetCandidateDtoWithPossibleVariants,
    'relocationAvailability' | 'candidateRelocationCountries' | 'readyForBusinessTrip'
  >,
): MappedTargetCandidateDtoWithPossibleVariants['relocation'] => {
  const { readyForBusinessTrip, candidateRelocationCountries, relocationAvailability } = args;

  let allNeededDuplicatesDataInCollection: {
    [key: string]: Omit<RelocationDiff, 'candidateId'>;
  } = {};

  const allNeededTargetDataInCollection = {
    relocationAvailability: relocationAvailability.target,
    readyForBusinessTrip: readyForBusinessTrip.target,
    candidateRelocationCountries: candidateRelocationCountries.target,
  };

  candidateRelocationCountries.possibleDuplicates.forEach((el) => {
    allNeededDuplicatesDataInCollection = {
      ...allNeededDuplicatesDataInCollection,
      [el.candidateId]: allNeededDuplicatesDataInCollection[el.candidateId]
        ? {
            ...allNeededDuplicatesDataInCollection[el.candidateId],
            candidateRelocationCountries: el.data,
          }
        : {
            candidateRelocationCountries: el.data,
          },
    };
  });

  readyForBusinessTrip.possibleDuplicates.forEach((el) => {
    allNeededDuplicatesDataInCollection = {
      ...allNeededDuplicatesDataInCollection,
      [el.candidateId]: allNeededDuplicatesDataInCollection[el.candidateId]
        ? {
            ...allNeededDuplicatesDataInCollection[el.candidateId],
            readyForBusinessTrip: el.data,
          }
        : {
            readyForBusinessTrip: el.data,
          },
    };
  });

  relocationAvailability.possibleDuplicates.forEach((el) => {
    allNeededDuplicatesDataInCollection = {
      ...allNeededDuplicatesDataInCollection,
      [el.candidateId]: allNeededDuplicatesDataInCollection[el.candidateId]
        ? {
            ...allNeededDuplicatesDataInCollection[el.candidateId],
            relocationAvailability: el.data,
          }
        : {
            relocationAvailability: el.data,
          },
    };
  });

  const possibleDuplicates = Object.entries(allNeededDuplicatesDataInCollection).reduce<
    MappedTargetCandidateDtoWithPossibleVariants['relocation']['possibleDuplicates']
  >((acc, [key, val], index, originArray) => {
    const isExistsInTarget =
      diffCompareCountriesArrayByIsoCode(
        allNeededTargetDataInCollection.candidateRelocationCountries,
        val.candidateRelocationCountries,
      ) &&
      allNeededTargetDataInCollection.readyForBusinessTrip === val.readyForBusinessTrip &&
      allNeededTargetDataInCollection.relocationAvailability === val.relocationAvailability;
    const isUniqueElementInArray =
      originArray.findIndex(([_k, e]) => {
        return (
          diffCompareCountriesArrayByIsoCode(e.candidateRelocationCountries, val.candidateRelocationCountries) &&
          e.readyForBusinessTrip === val.readyForBusinessTrip &&
          e.relocationAvailability === val.relocationAvailability
        );
      }) === index;
    if (!isExistsInTarget && isUniqueElementInArray) {
      return [...acc, { ...val, candidateId: key }];
    }
    return acc;
  }, []);
  return {
    target: {
      relocationAvailability: allNeededTargetDataInCollection.relocationAvailability,
      candidateRelocationCountries: allNeededTargetDataInCollection.candidateRelocationCountries,
      readyForBusinessTrip: allNeededTargetDataInCollection.readyForBusinessTrip,
    },
    possibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['responsibleEmployee']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['responsibleEmployee']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffResponsibleEmployeeMapper = (
  args: TargetCandidateDtoWithPossibleVariants['responsibleEmployee'],
): MappedTargetCandidateDtoWithPossibleVariants['responsibleEmployee'] => {
  const possibleDuplicates = args.possibleDuplicates.reduce<
    MappedTargetCandidateDtoWithPossibleVariants['responsibleEmployee']['possibleDuplicates']
  >((acc, val, index, originArray) => {
    const isExistsInTarget = args.target.employeeId === val.data.employeeId;
    const isUniqueElementInArray = originArray.findIndex((el) => el.data.employeeId === val.data.employeeId) === index;
    if (!isExistsInTarget && isUniqueElementInArray) {
      return [...acc, { ...val.data, candidateId: val.candidateId }];
    }
    return acc;
  }, []);
  return {
    target: args.target,
    possibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateLinks']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateLinks']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffCandidateLinksMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidateLinks'],
): MappedTargetCandidateDtoWithPossibleVariants['candidateLinks'] => {
  const possibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce<MappedTargetCandidateDtoWithPossibleVariants['candidateLinks']['possibleDuplicates']>(
      (acc, val, index, originalArray) => {
        const isExistsInTarget = args.target.some((el) => el.name === val.name && el.url === val.url);
        const isUniqueElementInArray =
          originalArray.findIndex((el) => el.name === val.name && el.url === val.url) === index;
        if (!isExistsInTarget && isUniqueElementInArray) {
          return [...acc, val];
        }
        return acc;
      },
      [],
    );
  return {
    target: args.target,
    possibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidatePrioritizedVacancies']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidatePrioritizedVacancies']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffCandidatePrioritizedVacanciesMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidatePrioritizedVacancies'],
): MappedTargetCandidateDtoWithPossibleVariants['candidatePrioritizedVacancies'] => {
  const possibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce((acc, val, index, originalArray) => {
      const isExistsInTarget = args.target.some((el) => el.id?.toString() === val.id?.toString());
      const isUniqueElementInArray =
        originalArray.findIndex((el) => el.id?.toString() === val.id?.toString()) === index;
      if (!isExistsInTarget && isUniqueElementInArray) {
        return [...acc, val];
      }
      return acc;
    }, []);
  return {
    target: args.target,
    possibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateSalary']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateSalary']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffCandidateSalaryMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidateSalary'],
): MappedTargetCandidateDtoWithPossibleVariants['candidateSalary'] => {
  const possibleDuplicates = args.possibleDuplicates.reduce<
    MappedTargetCandidateDtoWithPossibleVariants['candidateSalary']['possibleDuplicates']
  >((acc, val, index, originalArray) => {
    const isExistsInTarget =
      args.target.currency === val.data.currency &&
      args.target.expectedSalaryComment === val.data.expectedSalaryComment &&
      args.target.expectedSalaryMax === val.data.expectedSalaryMax &&
      args.target.expectedSalaryMin === val.data.expectedSalaryMin;
    const isUniqueElementInArray =
      originalArray.findIndex(
        (el) =>
          el.data.currency === val.data.currency &&
          el.data.expectedSalaryComment === val.data.expectedSalaryComment &&
          el.data.expectedSalaryMax === val.data.expectedSalaryMax &&
          el.data.expectedSalaryMin === val.data.expectedSalaryMin,
      ) === index;
    if (!isExistsInTarget && isUniqueElementInArray) {
      return [...acc, { ...val.data, candidateId: val.candidateId }];
    }
    return acc;
  }, []);
  return {
    target: args.target,
    possibleDuplicates,
  };
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateExperiences' | 'candidateEducations' | 'candidateCourses']} args
 * @returns {MappedTargetCandidateDtoWithPossibleVariants['candidateExperiences' | 'candidateEducations' | 'candidateCourses']}
 * @description Removes similar and equal to target values from possible duplicates
 */
export const diffActivityPlaceMapper = <
  TField extends 'candidateExperiences' | 'candidateEducations' | 'candidateCourses',
>(
  args: TargetCandidateDtoWithPossibleVariants[TField],
): MappedTargetCandidateDtoWithPossibleVariants[TField] => {
  const possibleDuplicates = args.possibleDuplicates
    .flatMap(({ data, candidateId }) => data.map((el) => ({ ...el, candidateId })))
    .reduce((acc, { id: _valId, candidateId: _candidateId, ...restVal }, index, originalArray) => {
      const isExistsInTarget = args.target.some(({ id = 0, candidateId: _candidateId, ...rest }) =>
        _.isEqual(rest, restVal),
      );
      const isUniqueElementInArray =
        originalArray.findIndex(({ id: _id, candidateId: _originCandidateId, ...rest }) => _.isEqual(rest, restVal)) ===
        index;
      if (!isExistsInTarget && isUniqueElementInArray) {
        return [...acc, { id: _valId, candidateId: _candidateId, ...restVal }];
      }
      return acc;
    }, []);
  return {
    target: args.target,
    possibleDuplicates,
  } as MappedTargetCandidateDtoWithPossibleVariants[TField];
};

/**
 * @param {TargetCandidateDtoWithPossibleVariants['candidateContacts']} args
 * @returns {DiffCandidateContactsMapperReturn}
 * @description Removes similar and equal to target values from possible duplicates
 */

type DiffCandidateContactsMapperReturn = Pick<
  MappedTargetCandidateDtoWithPossibleVariants,
  'telegram' | 'behance' | 'phone' | 'additionalPhone' | 'skype' | 'github' | 'linkedIn'
>;
export const diffCandidateContactsMapper = (
  args: TargetCandidateDtoWithPossibleVariants['candidateContacts'],
): DiffCandidateContactsMapperReturn => {
  const targetCandidateContactsMap = args.target.reduce(
    (acc, v) => {
      let contactType: keyof DiffCandidateContactsMapperReturn;
      if (v.contactType === ContactsType.ADDITIONAL_PHONE) contactType = 'additionalPhone';
      if (v.contactType === ContactsType.BEHANCE) contactType = 'behance';
      if (v.contactType === ContactsType.GITHUB) contactType = 'github';
      if (v.contactType === ContactsType.LINKEDIN) contactType = 'linkedIn';
      if (v.contactType === ContactsType.PHONE) contactType = 'phone';
      if (v.contactType === ContactsType.SKYPE) contactType = 'skype';
      if (v.contactType === ContactsType.TELEGRAM) contactType = 'telegram';
      return {
        ...acc,
        [contactType]: v.contact,
      };
    },
    { phone: '', telegram: '', additionalPhone: '', behance: '', github: '', linkedIn: '', skype: '' },
  );

  const possibleDuplicatesCollection = args.possibleDuplicates.reduce<
    Record<ContactsFields, MappedCandidateContacts['possibleDuplicates']>
  >(
    (acc, val) => {
      const onePossibleDuplicateContacts = val.data.reduce<
        Record<ContactsFields, MappedCandidateContacts['possibleDuplicates']>
      >(
        (acc, v) => {
          let contactType: keyof DiffCandidateContactsMapperReturn;
          if (v.contactType === ContactsType.ADDITIONAL_PHONE) contactType = 'additionalPhone';
          if (v.contactType === ContactsType.BEHANCE) contactType = 'behance';
          if (v.contactType === ContactsType.GITHUB) contactType = 'github';
          if (v.contactType === ContactsType.LINKEDIN) contactType = 'linkedIn';
          if (v.contactType === ContactsType.PHONE) contactType = 'phone';
          if (v.contactType === ContactsType.SKYPE) contactType = 'skype';
          if (v.contactType === ContactsType.TELEGRAM) contactType = 'telegram';

          if (targetCandidateContactsMap[contactType] === v.contact) return acc;
          const res = {
            data: v.contact,
            candidateId: val.candidateId,
          };
          return {
            ...acc,
            [contactType]: acc[contactType] ? [...acc[contactType], res] : [res],
          };
        },
        { phone: [], telegram: [], additionalPhone: [], behance: [], github: [], linkedIn: [], skype: [] },
      );
      return {
        ...acc,
        phone: [...new Set([...acc.phone, ...onePossibleDuplicateContacts.phone])],
        additionalPhone: [...new Set([...acc.additionalPhone, ...onePossibleDuplicateContacts.additionalPhone])],
        linkedIn: [...new Set([...acc.linkedIn, ...onePossibleDuplicateContacts.linkedIn])],
        github: [...new Set([...acc.github, ...onePossibleDuplicateContacts.github])],
        behance: [...new Set([...acc.behance, ...onePossibleDuplicateContacts.behance])],
        skype: [...new Set([...acc.skype, ...onePossibleDuplicateContacts.skype])],
        telegram: [...new Set([...acc.telegram, ...onePossibleDuplicateContacts.telegram])],
      };
    },
    { phone: [], telegram: [], additionalPhone: [], behance: [], github: [], linkedIn: [], skype: [] },
  );
  return {
    phone: {
      target: targetCandidateContactsMap.phone,
      possibleDuplicates: possibleDuplicatesCollection.phone,
    },
    telegram: {
      target: targetCandidateContactsMap.telegram,
      possibleDuplicates: possibleDuplicatesCollection.telegram,
    },
    additionalPhone: {
      target: targetCandidateContactsMap.additionalPhone,
      possibleDuplicates: possibleDuplicatesCollection.additionalPhone,
    },
    behance: {
      target: targetCandidateContactsMap.behance,
      possibleDuplicates: possibleDuplicatesCollection.behance,
    },
    github: {
      target: targetCandidateContactsMap.github,
      possibleDuplicates: possibleDuplicatesCollection.github,
    },
    linkedIn: {
      target: targetCandidateContactsMap.linkedIn,
      possibleDuplicates: possibleDuplicatesCollection.linkedIn,
    },
    skype: {
      target: targetCandidateContactsMap.skype,
      possibleDuplicates: possibleDuplicatesCollection.skype,
    },
  };
};

/**
 * @param {{
 *  targetCandidate: Partial<CandidateItemResponse>;
 *  possibleDuplicates: Partial<CandidateItemResponse>[];
 * }} args
 * @returns {ComparedCandidateWithSeveralCandidatesItems}
 * @description Creates result candidate DTO with preselected variants by field and possible duplicates options
 */
export const compareSeveralCandidatesItemsMapper = (args: {
  targetCandidate: Partial<CandidateItemResponse>;
  possibleDuplicates: Partial<CandidateItemResponse>[];
}): ComparedCandidateWithSeveralCandidatesItems => {
  const { targetCandidate, possibleDuplicates } = args;
  const possibleDuplicatesVariants = concatPossibleDuplicatesVariantsMapper(possibleDuplicates);
  const targetCandidateDtoWithPossibleVariants = Object.entries(targetCandidate).reduce<
    Partial<TargetCandidateDtoWithPossibleVariants>
  >(
    (acc, [key, val]) => ({
      ...acc,
      [key]: {
        ...acc[key],
        target: val,
        possibleDuplicates: acc[key]?.possibleDuplicates
          ? [...acc[key].possibleDuplicates, ...possibleDuplicatesVariants[key]]
          : possibleDuplicatesVariants[key],
      },
    }),
    {
      candidatePrioritizedVacancies: {
        possibleDuplicates: possibleDuplicates.map(({ id = '', vacancy = [] }) => ({
          candidateId: id,
          data: vacancy.map(({ title, value }) => ({ name: title, id: value })),
        })),
      },
    },
  );

  return {
    result: {
      filesMeta: {
        target: [
          ...targetCandidateDtoWithPossibleVariants.filesMeta.target,
          ...possibleDuplicates.flatMap(({ filesMeta }) => filesMeta),
        ],
        possibleDuplicates: [],
      },
      photoId: {
        target: targetCandidateDtoWithPossibleVariants.photoId.target,
        possibleDuplicates: [],
      },
      vacancyIdWithRequestAttached: {
        target: targetCandidateDtoWithPossibleVariants.vacancyIdWithRequestAttached.target,
        possibleDuplicates: [],
      },
      email: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.email),
      gender: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.gender),
      aboutMe: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.aboutMe),
      birthDate: diffDateFieldsMapper(targetCandidateDtoWithPossibleVariants.birthDate),
      lastNameEn: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.lastNameEn),
      lastNameRu: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.lastNameRu),
      firstNameEn: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.firstNameEn),
      firstNameRu: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.firstNameRu),
      candidateSkills: diffStringDataFieldsMapper(targetCandidateDtoWithPossibleVariants.candidateSkills),
      candidateDomains: diffDictionaryArrayDataFieldsMapper(targetCandidateDtoWithPossibleVariants.candidateDomains),
      candidateTechnologies: diffDictionaryArrayDataFieldsMapper(
        targetCandidateDtoWithPossibleVariants.candidateTechnologies,
      ),
      employmentForms: diffStringArrayDataFieldsMapper(targetCandidateDtoWithPossibleVariants.employmentForms),
      candidateWorkloads: diffStringArrayDataFieldsMapper(targetCandidateDtoWithPossibleVariants.candidateWorkloads),
      candidateWorkFormats: diffStringArrayDataFieldsMapper(
        targetCandidateDtoWithPossibleVariants.candidateWorkFormats,
      ),
      candidateLanguages: diffLanguagesArrayDataFieldsMapper(targetCandidateDtoWithPossibleVariants.candidateLanguages),
      candidateProfessions: diffProfessionsArrayDataFieldsMapper(
        targetCandidateDtoWithPossibleVariants.candidateProfessions,
      ),
      citizenships: diffCitizenshipMapper(targetCandidateDtoWithPossibleVariants.citizenships),
      candidateLocation: diffLocationMapper(targetCandidateDtoWithPossibleVariants.candidateLocation),
      blockedWithReason: diffBlockedMapper({
        isBlocked: targetCandidateDtoWithPossibleVariants.isBlocked,
        isBlockedReason: targetCandidateDtoWithPossibleVariants.isBlockedReason,
      }),
      sourceWithDetailsAndReferralProgram: diffSourceWithDetailsAndReferralProgramMapper({
        source: targetCandidateDtoWithPossibleVariants.source,
        sourceDetails: targetCandidateDtoWithPossibleVariants.sourceDetails,
        candidateReferral: targetCandidateDtoWithPossibleVariants.candidateReferral,
      }),
      visaAvailabilityWithExpirationTime: diffVisaAvailabilityMapper({
        visaAvailability: targetCandidateDtoWithPossibleVariants.visaAvailability,
        candidateVisas: targetCandidateDtoWithPossibleVariants.candidateVisas,
      }),
      relocation: diffRelocationAvailabilityMapper({
        relocationAvailability: targetCandidateDtoWithPossibleVariants.relocationAvailability,
        candidateRelocationCountries: targetCandidateDtoWithPossibleVariants.candidateRelocationCountries,
        readyForBusinessTrip: targetCandidateDtoWithPossibleVariants.readyForBusinessTrip,
      }),
      responsibleEmployee: diffResponsibleEmployeeMapper(targetCandidateDtoWithPossibleVariants.responsibleEmployee),
      candidateLinks: diffCandidateLinksMapper(targetCandidateDtoWithPossibleVariants.candidateLinks),
      candidatePrioritizedVacancies: diffCandidatePrioritizedVacanciesMapper(
        targetCandidateDtoWithPossibleVariants.candidatePrioritizedVacancies,
      ),
      candidateSalary: diffCandidateSalaryMapper(targetCandidateDtoWithPossibleVariants.candidateSalary),
      candidateExperiences: diffActivityPlaceMapper<'candidateExperiences'>(
        targetCandidateDtoWithPossibleVariants.candidateExperiences,
      ),
      candidateEducations: diffActivityPlaceMapper<'candidateEducations'>(
        targetCandidateDtoWithPossibleVariants.candidateEducations,
      ),
      candidateCourses: diffActivityPlaceMapper<'candidateCourses'>(
        targetCandidateDtoWithPossibleVariants.candidateCourses,
      ),
      ...diffCandidateContactsMapper(targetCandidateDtoWithPossibleVariants.candidateContacts),
      // ------------------------------------------------ READY ------------------------------------------------
      // // TODO: Activity place mapper
      // filesMeta: targetCandidateDtoWithPossibleVariants.filesMeta,
    },
    duplicatesCount: possibleDuplicates.length,
  };
};
