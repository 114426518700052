import { styled } from '@innowise-group/mui-kit';
import { MenuItem, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';

export const CustomTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.general.smart};
  }
  & .MuiTabs-scroller {
    overflow: visible !important;
    height: 100%;
  }
  & .MuiTabs-flexContainer {
    height: 100%;
  }
  &.MuiTabs-root {
    min-height: 40px;
    overflow: visible;
    border-bottom: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  }
`;

export const TabContainer = styled(MenuItem).withConfig({
  shouldForwardProp: (propName) => !['textColor', 'fullWidth', 'selectionFollowsFocus', 'indicator'].includes(propName),
})`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  &.MuiMenuItem-root.Mui-selected,
  &.MuiMenuItem-root.Mui-selected:hover {
    background-color: transparent;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[20]};
    opacity: 1;
  }
`;

export const NavigationLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  ${({ theme }) => theme.fonts.primary[80]}
  line-height: 22px;
  transition: ${({ theme }) => theme.animation.transition};
  width: 100%;
  color: ${({ selected, theme }) =>
    selected ? theme.palette.general.darkGrey[100] : theme.palette.general.darkGrey[60]};
`;

export const Label = styled.span`
  display: block;
  text-align: center;
  width: 100%;
`;
