import { Button, Icon, Input } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const HeaderContainer = styled.div`
  display: flex;
  height: 4.375rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 2.9375rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 0.75rem;
  }
`;

export const CustomIcon = styled(Icon)`
  margin: 0;
  padding: 0;
  & svg {
    transform: rotate(90deg);
  }
`;

export const CustomInput = styled(Input)`
  &.MuiFormControl-fullWidth {
    width: ${({ fullWidth }) => (fullWidth ? 'calc(100% - 350px)' : 'unset')};
  }
  & .MuiOutlinedInput-root {
    margin: 1rem 0;
    max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '200px')};
    background: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiFormHelperText-root {
    display: none;
  }

  &.MuiTextField-root {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiTextField-root {
      width: 100%;
    }
    & .MuiOutlinedInput-root {
      max-width: unset;
    }
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1.5rem;
  }
`;

export const ToggleButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    cursor: pointer;
    width: auto;
    min-width: unset;
    margin-right: auto;
    height: min-content;
    & svg {
      transform: ${({ $collapsed }: { $collapsed: boolean }) => ($collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`;
