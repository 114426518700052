import { Button, Input } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const AddButton = styled(Button)<{ required: boolean }>`
  justify-content: start;
  width: auto;
  height: 2.5rem;
  padding-left: 0;
  position: relative;
  & > * {
    margin: 0;
  }
  &::after {
    display: block;
    content: ${({ required }) => (required ? '"*"' : '""')};
    color: ${({ theme }) => theme.palette.state.error.pressed};
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const LinkInput = styled(Input)`
  &.MuiTextField-root {
    flex: 1;
  }
`;
