import { SelectOption } from '@innowise-group/core';
import React from 'react';
import * as Styled from '../../date-picker.styles';
import { getMonths } from '@innowise-group/core';

interface FullDateHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  startYear: number;
  endYear: number;
  yearsOptions: SelectOption[];
  changeMonth: (value: number) => void;
  changeYear: (value: number) => void;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const FullDateHeader: React.FC<FullDateHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  startYear,
  endYear,
  yearsOptions,
  changeMonth,
  changeYear,
  onChange,
  minDate,
  maxDate,
}) => {
  const months = getMonths();

  return (
    <Styled.CalendarHeader>
      <Styled.ChangeBlock>
        <Styled.ArrowButton
          variant="text"
          onClick={decreaseMonth}
          disabled={
            date.getFullYear() < (minDate?.getFullYear() || startYear) ||
            (date.getFullYear() === (minDate?.getFullYear() || startYear) &&
              (minDate ? date.getMonth() <= minDate.getMonth() : date.getMonth() === 0))
          }
        >
          <Styled.IconItem type="u_angle-right-b" size={16} isLeft />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.CustomSelect
          value={date.getMonth().toString()}
          options={months}
          disableClearable
          blurOnSelect
          filterOptions={(options) => options}
          onChange={(value: string) => {
            const newDate = new Date(date);
            newDate.setMonth(Number(value));
            changeMonth(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate.getMonth()
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate.getMonth()
                : Number(value),
            );
            onChange(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate
                : new Date(new Date(date).setMonth(Number(value))),
            );
          }}
          renderInput={(params) => {
            return (
              <Styled.MonthInput
                {...params}
                value={months[date.getMonth()?.toString()]?.title || ''}
                placeholder={months[date.getMonth()?.toString()]?.title || ''}
                helperText={null}
              />
            );
          }}
        />
        <Styled.CustomSelect
          value={date.getFullYear().toString()}
          options={yearsOptions}
          filterOptions={(options) => options}
          disableClearable
          blurOnSelect
          onChange={(value: string) => {
            const newDate = new Date(date);
            newDate.setFullYear(Number(value));
            changeYear(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate.getFullYear()
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate.getFullYear()
                : Number(value),
            );
            onChange(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate
                : new Date(new Date(date).setFullYear(Number(value))),
            );
          }}
          renderInput={(params) => (
            <Styled.YearsInput {...params} placeholder={date.getFullYear().toString()} helperText={null} />
          )}
        />
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.ArrowButton
          variant="text"
          onClick={increaseMonth}
          disabled={
            date.getFullYear() > (maxDate?.getFullYear() || endYear) ||
            (date.getFullYear() === (maxDate?.getFullYear() || endYear) &&
              (maxDate ? date.getMonth() >= maxDate.getMonth() : date.getMonth() === 11))
          }
        >
          <Styled.IconItem type="u_angle-right-b" size={16} isRight />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
    </Styled.CalendarHeader>
  );
};

export default FullDateHeader;
