import { styled } from '@innowise-group/mui-kit';

export const CheckboxWrapper = styled.div`
  margin-left: auto;
`;

export const ListItemText = styled.span`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  flex: 1;
`;

export const OptionalInfo = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const VerticalDivider = styled.div`
  display: inline-block;
  min-height: 12px;
  height: 100%;
  width: 1px;
  margin: 0 3px;
  position: relative;
  top: 2px;
  background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;
