import { ChangeActions, DateFormats } from '@constants';
import {
  CandidateChangeStoryItem,
  CandidateExperience,
  CandidateHistoryItemResponse,
  CandidateItemResponse,
  CandidateStoryItem,
  DictionaryValue,
} from '@innowise-group/core';
import { format } from 'date-fns';
import i18n from '@localization';
import { isEqual } from 'lodash';

const IgnoredPropertyList = [
  'candidateOverallExperienceMonths',
  'candidateItSphereExperienceMonths',
  'lastModifiedDate',
  'comment',
  'candidateSkills',
  'candidateStatus',
  'responsibleEmployee',
  'photoHrmUrl',
  'miniPhotoHrmUrl',
  'valueId',
  'id',
  'isFullVisaDate',
  'countryId',
  'isBlocked',
  'contactType',
  'photo',
  'secureType',
  'fileType',
  'isoCode',
  'phoneCode',
  'phoneCodeSuffixes',
  'aboutMe',
  'parentId',
];

const DateFields = ['birthDate', 'workedTo', 'workedFrom', 'validTo', 'studiedTo', 'studiedFrom'];
const OnlyUpdateFields = ['visaAvailability', 'relocationAvailability', 'readyForBusinessTrip'];
const GroupedFields = [
  'candidateLanguages',
  'candidateProfessions',
  'CandidateLanguage',
  'CandidateProfession',
  'candidateCourses',
  'CandidateCourse',
  'CandidateEducation',
  'candidateEducations',
  'CandidateExperience',
  'candidateExperiences',
  'candidateLocation',
  'CandidateLink',
  'candidateLinks',
];
const ArrayDictionaryValueFields = [
  'candidateWorkloads',
  'candidateWorkFormats',
  'employmentForms',
  'candidateDomains',
  'candidateTechnologies',
];

const IgnoredFieldActions = {
  sourceDetails: [ChangeActions.CREATE, ChangeActions.DELETE],
};

const FieldsWithoutValue = ['isBlockedReason', 'expectedSalaryComment'];

const GeneralFieldsInGroup: { [key: string]: { name: string[]; value: string } } = {
  candidateLanguages: { name: ['language'], value: '' },
  candidateProfessions: { name: ['profession'], value: 'translation' },
  candidateCourses: { name: ['studyPlace'], value: '' },
  candidateEducations: { name: ['studyPlace'], value: '' },
  candidateExperiences: { name: ['company', 'position'], value: '' },
  candidateLinks: { name: ['name'], value: '' },
};

export const isArray = <T>(previousValue: T, currentValue: T) => {
  return Array.isArray(previousValue) || Array.isArray(currentValue);
};

export const isObject = <T>(previousValue: T, currentValue?: T) => {
  return (
    (typeof previousValue === 'object' && !Array.isArray(previousValue) && previousValue !== null) ||
    (typeof currentValue === 'object' && !Array.isArray(currentValue) && currentValue !== null)
  );
};

export const checkValuesIsDate = (
  propertyName: string,
  previousValue: any,
  currentValue: any,
  withoutDay?: boolean,
) => {
  if (DateFields.includes(propertyName)) {
    return {
      current:
        currentValue && !isNaN(new Date(currentValue).getTime())
          ? format(new Date(currentValue), withoutDay ? DateFormats.MonthAndYear : DateFormats.DayFirst)
          : '',
      previous:
        previousValue && !isNaN(new Date(previousValue).getTime())
          ? format(new Date(previousValue), withoutDay ? DateFormats.MonthAndYear : DateFormats.DayFirst)
          : '',
    };
  }

  return {
    current: currentValue || '',
    previous: previousValue || '',
  };
};

export const checkVisaValuesIsDate = (
  propertyName: string,
  previousValue: any,
  currentValue: any,
  withoutPrevDay: boolean,
  withoutCurrDay: boolean,
) => {
  if (DateFields.includes(propertyName)) {
    return {
      current:
        currentValue && !isNaN(new Date(currentValue).getTime())
          ? format(new Date(currentValue), withoutCurrDay ? DateFormats.MonthAndYear : DateFormats.DayFirst)
          : '',
      previous:
        previousValue && !isNaN(new Date(previousValue).getTime())
          ? format(new Date(previousValue), withoutPrevDay ? DateFormats.MonthAndYear : DateFormats.DayFirst)
          : '',
    };
  }

  return {
    current: currentValue || '',
    previous: previousValue || '',
  };
};

export const calculatePrimitiveAction = <T>(previousValue: T, currentValue: T, field: string) => {
  if ((previousValue && currentValue) || OnlyUpdateFields.includes(field)) {
    return ChangeActions.UPDATE;
  }
  if (!previousValue && currentValue) {
    return ChangeActions.CREATE;
  }
  if (previousValue && !currentValue) {
    return ChangeActions.DELETE;
  }
  return null;
};

export const calculateGroupedPrimitiveData = (
  fieldName: string,
  propertyName: string,
  action: ChangeActions,
  arrayItem: any,
) => {
  const changeItem = {
    action,
    currentValue: '',
    previousValue: '',
  };

  if (!arrayItem || !arrayItem[propertyName] || !propertyName) return changeItem;
  if (arrayItem[propertyName] && action && !IgnoredFieldActions[propertyName]?.includes(action)) {
    if (fieldName === 'candidateExperiences' && propertyName === 'position' && arrayItem.company) {
      return changeItem;
    }
    if (action === ChangeActions.CREATE && GeneralFieldsInGroup[fieldName].name.includes(propertyName)) {
      changeItem.currentValue = checkValuesIsDate(propertyName, null, arrayItem[propertyName], true).current;
    }
    if (action === ChangeActions.DELETE && GeneralFieldsInGroup[fieldName].name.includes(propertyName)) {
      changeItem.previousValue = checkValuesIsDate(propertyName, arrayItem[propertyName], null, true).previous;
    }
  }
  return changeItem;
};

export const calculateNotUpdateDictionaryData = (action: ChangeActions, item: { dictionaryValue: DictionaryValue }) => {
  if (isObject(item)) {
    if (item.dictionaryValue?.translation) {
      return {
        action,
        previousValue: action === ChangeActions.CREATE ? '' : item.dictionaryValue.translation,
        currentValue: action === ChangeActions.DELETE ? '' : item.dictionaryValue.translation,
      };
    }
  }
  if (item) {
    return {
      action,
      previousValue: action === ChangeActions.CREATE ? '' : item,
      currentValue: action === ChangeActions.DELETE ? '' : item,
    };
  }
  return {
    previousValue: '',
    currentValue: '',
  };
};

export const findDifferenceInObject = (
  obj: { previousValue: object; currentValue: object },
  index: number,
  reverse?: boolean,
) => {
  const currKeys = obj.currentValue ? Object.keys(obj.currentValue) : [];
  const prevKeys = obj.previousValue ? Object.keys(obj.previousValue) : [];
  const keys = currKeys.length ? currKeys : prevKeys;
  if (index === null || isArray(obj.previousValue, obj.currentValue)) return { previousValue: {}, currentValue: {} };
  const difference = keys.reduce<{ previousValue: object; currentValue: object }>(
    (accum, curr) => {
      if (isObject(obj.currentValue?.[curr]) || isObject(obj.previousValue?.[curr])) {
        const objectDifference =
          curr === 'recommender'
            ? findDifferenceInObject(
                {
                  previousValue: { email: obj.previousValue?.[curr]?.email || '' },
                  currentValue: { email: obj.currentValue?.[curr]?.email || '' },
                },
                index,
                reverse,
              )
            : findDifferenceInObject(
                {
                  previousValue: obj.previousValue?.[curr] || '',
                  currentValue: obj.currentValue?.[curr] || '',
                },
                index,
                reverse,
              );
        if (objectDifference.currentValue || objectDifference.previousValue) {
          Object.keys(objectDifference.currentValue).map((item) => {
            if (objectDifference.currentValue?.[item] || objectDifference.previousValue?.[item]) {
              const name = `${curr}.${item}_${index}`;
              accum.previousValue[name] = reverse
                ? objectDifference.currentValue?.[item] || ''
                : objectDifference.previousValue?.[item] || '';
              accum.currentValue[name] = reverse
                ? objectDifference.previousValue?.[item] || ''
                : objectDifference.currentValue?.[item] || '';
            }
          });
        }
        return accum;
      }
      if (!IgnoredPropertyList.includes(curr)) {
        if (!obj.currentValue) {
          if (curr === 'validTo') {
            const { previous } = checkVisaValuesIsDate(
              curr,
              obj.previousValue[curr],
              null,
              obj.previousValue['isFullVisaDate'] !== null ? !obj.previousValue['isFullVisaDate'] : true,
              false,
            );
            accum.previousValue[curr] = previous;
            accum.currentValue[curr] = null;
            return accum;
          }
          const { previous } = checkValuesIsDate(curr, obj.previousValue[curr], null);
          accum.previousValue[curr] = previous;
          accum.currentValue[curr] = null;
          return accum;
        }
        if (!obj.previousValue) {
          if (curr === 'validTo') {
            const { current } = checkVisaValuesIsDate(
              curr,
              null,
              obj.currentValue[curr],
              false,
              obj.currentValue['isFullVisaDate'] !== null ? !obj.currentValue['isFullVisaDate'] : true,
            );

            accum.previousValue[curr] = null;
            accum.currentValue[curr] = current;
            return accum;
          }
          const { current } = checkValuesIsDate(curr, null, obj.currentValue[curr]);
          accum.previousValue[curr] = null;
          accum.currentValue[curr] = current;
          return accum;
        }
        if (obj.currentValue[curr] !== obj.previousValue[curr]) {
          if (curr === 'validTo') {
            const { current, previous } = checkVisaValuesIsDate(
              curr,
              obj.previousValue[curr],
              obj.currentValue[curr],
              obj.previousValue['isFullVisaDate'] !== null ? !obj.previousValue['isFullVisaDate'] : true,
              obj.currentValue['isFullVisaDate'] !== null ? !obj.currentValue['isFullVisaDate'] : true,
            );
            accum.previousValue[curr] = previous;
            accum.currentValue[curr] = current;
            return accum;
          }
          const { current, previous } = checkValuesIsDate(curr, obj.previousValue[curr], obj.currentValue[curr]);
          accum.previousValue[curr] = previous;
          accum.currentValue[curr] = current;
          return accum;
        }
      }
      return accum;
    },
    { previousValue: {}, currentValue: {} },
  );
  return difference;
};

export const calculateLocationData = (wholeObject: { previousValue: any; currentValue: any }, index: number) => {
  const difference = findDifferenceInObject(
    {
      previousValue: wholeObject.previousValue,
      currentValue: wholeObject.currentValue,
    },
    index,
  );

  const objectDiff = Object.keys(difference.currentValue).reduce<CandidateChangeStoryItem>((accum, curr) => {
    if (curr.startsWith('locationCity.name_')) {
      const translation = 'candidateLocation.locationCountry.name';
      if (wholeObject.currentValue.locationCountry?.name !== wholeObject.previousValue.locationCountry?.name) {
        if (difference.previousValue[curr]) {
          accum[`${curr}_${ChangeActions.DELETE}`] = {
            action: ChangeActions.DELETE,
            currentValue: '',
            previousValue: difference.previousValue[curr],
            parent: {
              value: wholeObject.previousValue.locationCountry.name,
              translation,
            },
          };
        }
        if (difference.currentValue[curr]) {
          accum[`${curr}_${ChangeActions.CREATE}`] = {
            action: ChangeActions.CREATE,
            currentValue: difference.currentValue[curr],
            previousValue: '',
            parent: {
              value: wholeObject.currentValue.locationCountry.name,
              translation,
            },
          };
        }
        return accum;
      }
      accum[curr] = {
        action: calculatePrimitiveAction(difference.previousValue[curr], difference.currentValue[curr], curr),
        currentValue: difference.currentValue[curr],
        previousValue: difference.previousValue[curr],
        parent: {
          value: wholeObject.currentValue.locationCountry.name,
          translation,
        },
      };
      return accum;
    }
    if (curr.startsWith('currentLocationCity.name')) {
      const translation = 'candidateLocation.currentLocationCountry.name';
      if (
        wholeObject.currentValue.currentLocationCountry?.name !== wholeObject.previousValue.currentLocationCountry?.name
      ) {
        if (difference.previousValue[curr]) {
          accum[`${curr}_${ChangeActions.DELETE}`] = {
            action: ChangeActions.DELETE,
            currentValue: '',
            previousValue: difference.previousValue[curr],
            parent: {
              value: wholeObject.previousValue.currentLocationCountry.name,
              translation,
            },
          };
        }
        if (difference.currentValue[curr]) {
          accum[`${curr}_${ChangeActions.CREATE}`] = {
            action: ChangeActions.CREATE,
            currentValue: difference.currentValue[curr],
            previousValue: '',
            parent: {
              value: wholeObject.currentValue.currentLocationCountry.name,
              translation,
            },
          };
        }
        return accum;
      }
      accum[curr] = {
        action: calculatePrimitiveAction(difference.previousValue[curr], difference.currentValue[curr], curr),
        currentValue: difference.currentValue[curr],
        previousValue: difference.previousValue[curr],
        parent: {
          value: wholeObject.currentValue.currentLocationCountry.name,
          translation,
        },
      };
      return accum;
    }
    accum[curr] = {
      action: calculatePrimitiveAction(difference.previousValue[curr], difference.currentValue[curr], curr),
      currentValue: difference.currentValue[curr],
      previousValue: difference.previousValue[curr],
    };
    return accum;
  }, {});
  return objectDiff;
};

export const calculateUpdatedExperienceData = (
  fieldName: string,
  propertyName: string,
  arrayItems: { previousValue: CandidateExperience; currentValue: CandidateExperience },
  index: number,
  isDeleteAndCreate: boolean,
) => {
  if (
    !fieldName ||
    !propertyName ||
    !GeneralFieldsInGroup[fieldName] ||
    (!arrayItems.previousValue && !arrayItems.currentValue) ||
    arrayItems.previousValue === null ||
    arrayItems.currentValue === null
  )
    return [];
  const { previous, current } = checkValuesIsDate(
    propertyName,
    arrayItems.previousValue?.[propertyName] || '',
    arrayItems.currentValue?.[propertyName] || '',
    true,
  );
  const action = calculatePrimitiveAction(
    arrayItems.previousValue?.[propertyName] || '',
    arrayItems.currentValue?.[propertyName] || '',
    propertyName,
  );
  const name = `${fieldName}.${propertyName}_${arrayItems.currentValue?.id || 0}_${index}_${action}_${Date.now()}`;
  if ((current || previous) && current !== previous) {
    // TODO: choose one of them after new requirements
    // if (!isDeleteAndCreate) {
    if (!isDeleteAndCreate || isDeleteAndCreate) {
      const parentName = arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]]
        ? GeneralFieldsInGroup[fieldName].name[0]
        : GeneralFieldsInGroup[fieldName].name[1];
      const parentValue = arrayItems.previousValue[parentName];
      const translation = `${fieldName}.${parentName}${
        GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
      }`;
      if (propertyName === 'stillWorking' || propertyName === 'itSphere') {
        return [
          {
            name,
            currentValue: '',
            previousValue: '',
            action,
            parent: {
              value: parentValue,
              translation,
            },
          },
        ];
      }
      if (propertyName === 'workedTo') {
        if (!arrayItems.currentValue.stillWorking) {
          return [
            {
              name,
              currentValue: current,
              previousValue: previous,
              action,
              parent: {
                value: parentValue,
                translation,
              },
            },
          ];
        }
      }
      if (propertyName === 'workedFrom') {
        return [
          {
            name,
            currentValue: current,
            previousValue: previous,
            action,
            parent: {
              value: parentValue,
              translation,
            },
          },
        ];
      }
      if (propertyName === 'company') {
        const parentName =
          arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]] && action !== ChangeActions.DELETE
            ? GeneralFieldsInGroup[fieldName].name[0]
            : GeneralFieldsInGroup[fieldName].name[1];
        const parentValue = arrayItems.previousValue[parentName];
        const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name[0]}${
          GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
        }`;

        return [
          {
            name,
            currentValue: current,
            previousValue: previous,
            action,
            parent: {
              value: parentValue,
              translation,
            },
          },
        ];
      }
      if (propertyName === 'position') {
        return [
          {
            name,
            currentValue: current,
            previousValue: previous,
            action,
            parent: {
              value: parentValue,
              translation,
            },
          },
        ];
      }
    } else {
      const changeItems = [];
      if (propertyName === 'company') {
        if (arrayItems.previousValue.company) {
          const deletedName = `${fieldName}.${propertyName}_${arrayItems.currentValue?.id || 0}_${index}_${
            ChangeActions.DELETE
          }_${Date.now()}`;
          changeItems.push({
            name: deletedName,
            action: ChangeActions.DELETE,
            previousValue: previous,
            currentValue: '',
          });
        }
        if (arrayItems.currentValue.company) {
          const createdName = `${fieldName}.${propertyName}_${arrayItems.currentValue?.id || 0}_${index}_${
            ChangeActions.CREATE
          }_${Date.now()}`;
          changeItems.push({
            name: createdName,
            action: ChangeActions.CREATE,
            previousValue: '',
            currentValue: current,
          });
        }
        return changeItems;
      }
      if (propertyName === 'position') {
        if (!arrayItems.previousValue.company) {
          const deletedName = `${fieldName}.${propertyName}_${arrayItems.currentValue?.id || 0}_${index}_${
            ChangeActions.DELETE
          }_${Date.now()}`;
          changeItems.push({
            name: deletedName,
            action: ChangeActions.DELETE,
            previousValue: previous,
            currentValue: '',
          });
        }
        if (!arrayItems.currentValue.company) {
          const createdName = `${fieldName}.${propertyName}_${arrayItems.currentValue?.id || 0}_${index}_${
            ChangeActions.CREATE
          }_${Date.now()}`;
          changeItems.push({
            name: createdName,
            action: ChangeActions.CREATE,
            previousValue: '',
            currentValue: current,
          });
        }
        return changeItems;
      }
    }
  }
  return [];
};

export const calculateUpdatedNotExperienceData = (
  fieldName: string,
  propertyName: string,
  arrayItems: { previousValue: any; currentValue: any },
  index: number,
) => {
  if (
    !fieldName ||
    !propertyName ||
    !arrayItems.previousValue ||
    !arrayItems.previousValue ||
    !GeneralFieldsInGroup[fieldName]
  )
    return {};
  const { previous, current } = checkValuesIsDate(
    propertyName,
    arrayItems.previousValue[propertyName],
    arrayItems.currentValue[propertyName],
    true,
  );
  if ((current || previous) && current !== previous) {
    const action = calculatePrimitiveAction(
      arrayItems.previousValue[propertyName],
      arrayItems.currentValue[propertyName],
      propertyName,
    );
    const name = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${action}_${Date.now()}`;
    const changeItem: {
      action: ChangeActions;
      previousValue: any;
      currentValue: any;
      parent?: { value: string; translation: string };
    } = {
      action,
      currentValue: current,
      previousValue: previous,
    };
    // TODO: choose one of them after new requirements
    // if (!GeneralFieldsInGroup[fieldName].name.includes(propertyName)) {
    const parent = arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]];
    const parentValue = parent?.[GeneralFieldsInGroup[fieldName].value] || parent;
    const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
      GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
    }`;
    changeItem.parent = {
      value: parentValue || null,
      translation,
    };
    // }
    return { name, item: changeItem };
  }
  return null;
};

export const calculateUpdateProfessionsData = (
  fieldName: string,
  propertyName: string,
  arrayItems: { previousValue: any; currentValue: any },
  index: number,
  isGeneralFieldUpdated: boolean,
) => {
  if (
    !fieldName ||
    !GeneralFieldsInGroup[fieldName] ||
    !propertyName ||
    !arrayItems.currentValue ||
    !arrayItems.previousValue
  )
    return {};
  if (propertyName === 'grade') {
    if (!arrayItems.previousValue[propertyName] && !arrayItems.currentValue[propertyName]) {
      return {};
    }
    if (arrayItems.previousValue[propertyName] === arrayItems.currentValue[propertyName] && !isGeneralFieldUpdated) {
      return {};
    }
    if (!isGeneralFieldUpdated) {
      const action = calculatePrimitiveAction(
        arrayItems.previousValue[propertyName],
        arrayItems.currentValue[propertyName],
        propertyName,
      );
      const name = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${action}_${Date.now()}`;
      const parentValue =
        arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]]?.[GeneralFieldsInGroup[fieldName].value];
      const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
        GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
      }`;
      return {
        [name]: {
          currentValue: arrayItems.currentValue[propertyName],
          previousValue: arrayItems.previousValue[propertyName],
          action,
          parent: {
            value: parentValue || '',
            translation,
          },
        },
      };
    }
    const deleteName = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${
      ChangeActions.DELETE
    }_${Date.now()}`;
    const createName = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${
      ChangeActions.CREATE
    }_${Date.now()}`;
    const data: {
      [key: string]: {
        action: ChangeActions;
        previousValue: any;
        currentValue: any;
        parent?: { value: string; translation: string };
      };
    } = {};
    data[deleteName] = {
      action: ChangeActions.DELETE,
      previousValue: arrayItems.previousValue[propertyName],
      currentValue: null,
    };
    if (arrayItems.currentValue[propertyName]) {
      data[createName] = {
        action: ChangeActions.CREATE,
        previousValue: null,
        currentValue: arrayItems.currentValue[propertyName],
      };
    }
    if (!GeneralFieldsInGroup[fieldName].name.includes(propertyName)) {
      const parentCurrentValue =
        arrayItems.currentValue[GeneralFieldsInGroup[fieldName].name[0]][GeneralFieldsInGroup[fieldName].value];
      const parentPreviousValue =
        arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]][GeneralFieldsInGroup[fieldName].value];
      const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
        GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
      }`;
      data[createName].parent = {
        translation,
        value: parentCurrentValue,
      };
      if (arrayItems.currentValue[propertyName]) {
        data[deleteName].parent = {
          translation,
          value: parentPreviousValue,
        };
      }
    }
    return data;
  }
  if (propertyName === 'profession') {
    const difference = findDifferenceInObject(
      {
        previousValue: isGeneralFieldUpdated ? null : arrayItems.previousValue[propertyName],
        currentValue: arrayItems.currentValue[propertyName],
      },
      index,
    );
    const data = Object.keys(difference.currentValue).reduce((accum, idx) => {
      if (difference.previousValue[idx] || difference.currentValue[idx]) {
        const deleteName = `${fieldName}.${propertyName}.${idx}_${arrayItems.currentValue.id || 0}_${index}_${
          ChangeActions.DELETE
        }_${Date.now()}`;
        const createName = `${fieldName}.${propertyName}.${idx}_${arrayItems.currentValue.id || 0}_${index}_${
          ChangeActions.CREATE
        }_${Date.now()}`;
        accum[deleteName] = {
          action: ChangeActions.DELETE,
          previousValue: difference.previousValue[idx] || arrayItems.previousValue[propertyName][idx],
          currentValue: null,
        };
        if (difference.currentValue[idx]) {
          accum[createName] = {
            action: ChangeActions.CREATE,
            previousValue: null,
            currentValue: difference.currentValue[idx],
          };
        }
        return accum;
      }
      return accum;
    }, {});
    return data;
  }
  return null;
};

export const calculateUpdateLanguagesData = (
  fieldName: string,
  propertyName: string,
  arrayItems: { previousValue: any; currentValue: any },
  index: number,
  isGeneralFieldUpdated: boolean,
) => {
  if (
    !fieldName ||
    !GeneralFieldsInGroup[fieldName] ||
    !propertyName ||
    !arrayItems.currentValue ||
    !arrayItems.previousValue
  )
    return {};
  if (propertyName === 'languageLevel') {
    if (!arrayItems.previousValue[propertyName] && !arrayItems.currentValue[propertyName]) {
      return {};
    }
    if (arrayItems.previousValue[propertyName] === arrayItems.currentValue[propertyName] && !isGeneralFieldUpdated) {
      return {};
    }
    if (!isGeneralFieldUpdated) {
      const action = calculatePrimitiveAction(
        arrayItems.previousValue[propertyName],
        arrayItems.currentValue[propertyName],
        propertyName,
      );
      const name = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${action}_${Date.now()}`;
      const parentValue = i18n.t(
        `options.languageOptions_${arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]]}`,
      );
      const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
        GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
      }`;
      return {
        [name]: {
          currentValue: arrayItems.currentValue[propertyName],
          previousValue: arrayItems.previousValue[propertyName],
          action,
          parent: {
            value: parentValue || '',
            translation,
          },
        },
      };
    }
    const deleteName = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${
      ChangeActions.DELETE
    }_${Date.now()}`;
    const createName = `${fieldName}.${propertyName}_${arrayItems.currentValue.id || 0}_${index}_${
      ChangeActions.CREATE
    }_${Date.now()}`;
    const data: {
      [key: string]: {
        action: ChangeActions;
        previousValue: any;
        currentValue: any;
        parent?: { value: string; translation: string };
      };
    } = {};
    data[deleteName] = {
      action: ChangeActions.DELETE,
      previousValue: arrayItems.previousValue[propertyName],
      currentValue: null,
    };
    if (arrayItems.currentValue[propertyName]) {
      data[createName] = {
        action: ChangeActions.CREATE,
        previousValue: null,
        currentValue: arrayItems.currentValue[propertyName],
      };
    }
    if (!GeneralFieldsInGroup[fieldName].name.includes(propertyName)) {
      const parentCurrentValue = arrayItems.currentValue[GeneralFieldsInGroup[fieldName].name[0]];
      const parentPreviousValue = arrayItems.previousValue[GeneralFieldsInGroup[fieldName].name[0]];
      const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
        GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
      }`;
      data[createName].parent = {
        translation,
        value: parentCurrentValue,
      };
      if (arrayItems.currentValue[propertyName]) {
        data[deleteName].parent = {
          translation,
          value: parentPreviousValue,
        };
      }
    }
    return data;
  }
  if (propertyName === 'language') {
    return {};
  }

  return null;
};

export const calculateUpdateGroupedData = (
  fieldName: string,
  arrayItems: { previousValue: any; currentValue: any },
  index: number,
  isGeneralFieldUpdated: boolean,
) => {
  if (
    !fieldName ||
    !GroupedFields.includes(fieldName) ||
    !isObject(arrayItems.previousValue) ||
    !isObject(arrayItems.currentValue)
  )
    return {};

  const difference = Object.keys(arrayItems.currentValue).reduce<CandidateChangeStoryItem>((accum, curr) => {
    if (!IgnoredPropertyList.includes(curr)) {
      if (fieldName === 'candidateProfessions') {
        const difference = calculateUpdateProfessionsData(fieldName, curr, arrayItems, index, isGeneralFieldUpdated);
        Object.keys(difference).map((item) => {
          accum[item] = difference[item];
        });
        return accum;
      }
      if (fieldName === 'candidateLanguages') {
        const difference = calculateUpdateLanguagesData(fieldName, curr, arrayItems, index, isGeneralFieldUpdated);
        Object.keys(difference).map((item) => {
          accum[item] = difference[item];
        });
        return accum;
      }
      if (fieldName === 'candidateExperiences') {
        if (!(arrayItems.currentValue.position || arrayItems.currentValue.company)) return accum;
        const isDeleteAndCreate = () => {
          const isOnlyPosition =
            arrayItems.currentValue.position !== arrayItems.previousValue.position && !arrayItems.previousValue.company;
          const isOnlyCompany =
            arrayItems.currentValue.company !== arrayItems.previousValue.company && !arrayItems.previousValue.position;
          const isBoth =
            arrayItems.currentValue.position !== arrayItems.previousValue.position &&
            arrayItems.currentValue.company !== arrayItems.previousValue.company;
          return isBoth || isOnlyCompany || isOnlyPosition;
        };
        const changeItems = calculateUpdatedExperienceData(fieldName, curr, arrayItems, index, isDeleteAndCreate());
        if (changeItems?.length) {
          changeItems.map(({ name, ...item }) => {
            accum[name] = item;
          });
        }
        return accum;
      }
      const changeItem = calculateUpdatedNotExperienceData(fieldName, curr, arrayItems, index);
      if (changeItem) {
        accum[changeItem.name] = changeItem.item;
      }
      return accum;
    }
    return accum;
  }, {});
  return difference;
};

export const calculateGroupedData = (action: ChangeActions, fieldName: string, arrayItem: any, index: number) => {
  if (!fieldName || !isObject(arrayItem)) return {};
  const difference = Object.keys(arrayItem).reduce<CandidateChangeStoryItem>((accum, curr) => {
    if (!IgnoredPropertyList.includes(curr)) {
      if (isObject(arrayItem[curr]) || EnumsFields.includes(curr)) {
        if (!arrayItem[curr]) return accum;

        if (action === ChangeActions.CREATE) {
          const difference = EnumsFields.includes(curr)
            ? { currentValue: arrayItem[curr], previousValue: null }
            : findDifferenceInObject({ previousValue: null, currentValue: arrayItem[curr] }, index);
          if (EnumsFields.includes(curr)) {
            const name = `${fieldName}.${curr}_${arrayItem.id || 0}_${index}_${action}`;
            accum[name] = {
              currentValue: difference.currentValue,
              previousValue: difference.previousValue,
              action,
            };
            if (!GeneralFieldsInGroup[fieldName].name.includes(curr)) {
              const parentValue =
                fieldName === 'candidateLanguages'
                  ? i18n.t(`options.languageOptions_${arrayItem.language}`)
                  : arrayItem[GeneralFieldsInGroup[fieldName].name[0]][GeneralFieldsInGroup[fieldName].value];
              const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
                GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
              }`;
              accum[name].parent = {
                value: parentValue,
                translation,
              };
            }
            return accum;
          }
          Object.keys(difference.currentValue).map((idx) => {
            if (difference.previousValue[idx] || difference.currentValue[idx]) {
              const name = `${fieldName}.${curr}.${idx}_${arrayItem.id || 0}_${index}_${action}`;
              accum[name] = {
                currentValue: difference.currentValue[idx],
                previousValue: difference.previousValue[idx],
                action,
              };
              if (!GeneralFieldsInGroup[fieldName].name.includes(curr)) {
                const parentValue =
                  arrayItem[GeneralFieldsInGroup[fieldName].name[0]][GeneralFieldsInGroup[fieldName].value];
                const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
                  GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
                }`;
                accum[name].parent = {
                  value: parentValue,
                  translation,
                };
              }
            }
          });
          return accum;
        }
        if (action === ChangeActions.DELETE) {
          const difference = EnumsFields.includes(curr)
            ? { previousValue: null, currentValue: arrayItem[curr] }
            : findDifferenceInObject({ previousValue: null, currentValue: arrayItem[curr] }, index, true);
          const temp = difference.currentValue;
          difference.currentValue = difference.previousValue;
          difference.previousValue = temp;
          if (EnumsFields.includes(curr)) {
            const name = `${fieldName}.${curr}_${arrayItem.id || 0}_${index}_${action}`;
            if (GeneralFieldsInGroup[fieldName].name.includes(curr)) {
              accum[name] = {
                currentValue: difference.currentValue,
                previousValue: difference.previousValue,
                action,
              };
              return accum;
            }
            if (curr === 'languageLevel' || curr === 'grade') {
              const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
                GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
              }`;
              const parentValue =
                curr === 'languageLevel'
                  ? i18n.t(`options.languageOptions_${arrayItem.language}`)
                  : arrayItem[GeneralFieldsInGroup[fieldName].name[0]].translation;
              accum[name] = {
                currentValue: difference.currentValue,
                previousValue: difference.previousValue,
                action,
                parent: {
                  translation,
                  value: parentValue,
                },
              };
              return accum;
            }
          }
          Object.keys(difference.currentValue).map((idx) => {
            if (difference.previousValue[idx] || difference.currentValue[idx]) {
              const name = `${fieldName}.${curr}.${idx}_${arrayItem.id || 0}_${index}_${action}`;
              if (GeneralFieldsInGroup[fieldName].name.includes(curr)) {
                accum[name] = {
                  currentValue: difference.currentValue[idx],
                  previousValue: difference.previousValue[idx],
                  action,
                };
                return accum;
              }
              if (curr === 'languageLevel' || curr === 'grade') {
                const translation = `${fieldName}.${GeneralFieldsInGroup[fieldName].name}${
                  GeneralFieldsInGroup[fieldName].value && '.' + GeneralFieldsInGroup[fieldName].value
                }`;
                const parentValue = arrayItem[GeneralFieldsInGroup[fieldName].name[0]].translation;
                accum[name] = {
                  currentValue: difference.currentValue[idx],
                  previousValue: difference.previousValue[idx],
                  action,
                  parent: {
                    translation,
                    value: parentValue,
                  },
                };
                return accum;
              }
            }
          });
          return accum;
        }
      }
      const changeItem = calculateGroupedPrimitiveData(fieldName, curr, action, arrayItem);
      const name = `${fieldName}.${curr}_${arrayItem.id || 0}_${index}_${action}`;
      if (changeItem.currentValue || changeItem.previousValue) {
        accum[name] = changeItem;
      }
    }
    return accum;
  }, {});
  return difference;
};

export const findDifferenceInArray = (
  currArray: any[],
  prevArray: any[],
  fieldName: string,
  currentCandidate: CandidateItemResponse,
) => {
  if (!fieldName || !isObject(currentCandidate) || !(currArray.length || prevArray.length)) return {};
  const createdItems = [];
  const updatedItems = [];

  currArray.map((item) => {
    if (fieldName === 'candidateLanguages') {
      if (!prevArray.find((curr) => item.language === curr.language)) {
        createdItems.push(item);
      } else {
        updatedItems.push(item);
      }
    } else {
      if (!prevArray.find((curr) => item.id === curr.id) && isObject(item)) {
        createdItems.push(item);
      } else if (isObject(item)) {
        updatedItems.push(item);
      }
      if (typeof item === 'string') {
        if (!prevArray.find((curr) => item === curr)) {
          createdItems.push(item);
        } else {
          updatedItems.push(item);
        }
      }
    }
  });

  const deletedItems = prevArray.filter((item) => {
    if (fieldName === 'candidateLanguages') {
      return !currArray.find((curr) => item.language === curr.language);
    } else {
      if (isObject(item)) {
        return !currArray.find((curr) => item.id === curr.id);
      }
      if (typeof item === 'string') {
        return !currArray.find((curr) => item === curr);
      }
    }
  });
  let [createdData, deletedData, updatedData]: CandidateChangeStoryItem[] = [{}, {}, {}];

  if (updatedItems.length && GroupedFields.includes(fieldName)) {
    updatedData = updatedItems.reduce<CandidateChangeStoryItem>((accum, item, index) => {
      const arrayItems = {
        previousValue: prevArray.find((curr) =>
          fieldName === 'candidateLanguages' ? item.language === curr.language : item.id === curr.id,
        ),
        currentValue: currArray.find((curr) =>
          fieldName === 'candidateLanguages' ? item.language === curr.language : item.id === curr.id,
        ),
      };

      const generalFields = GeneralFieldsInGroup[fieldName].name.map((general) => {
        return {
          previousValue: arrayItems.previousValue[general],
          currentValue: arrayItems.currentValue[general],
        };
      });
      const isGeneralFieldUpdated = generalFields.reduce<boolean>((accum, generalField) => {
        if (fieldName === 'candidateLanguages' && generalField.currentValue !== generalField.previousValue) return true;
        else {
          if (
            !isEqual(
              generalField.currentValue[GeneralFieldsInGroup[fieldName].value],
              generalField.previousValue[GeneralFieldsInGroup[fieldName].value],
            )
          ) {
            return true;
          }
        }

        return accum;
      }, false);
      const difference = calculateUpdateGroupedData(fieldName, arrayItems, index, isGeneralFieldUpdated);
      Object.keys(difference).map((item) => {
        accum[item] = difference[item];
      });
      return accum;
    }, {});
  }

  if (createdItems.length) {
    createdData = createdItems.reduce<CandidateChangeStoryItem>((accum, item, index) => {
      if (ArrayDictionaryValueFields.includes(fieldName)) {
        const changeItem = calculateNotUpdateDictionaryData(ChangeActions.CREATE, item);
        const name = `${fieldName}_${index}_${ChangeActions.CREATE}`;
        accum[name] = changeItem;
        return accum;
      }
      if (GroupedFields.includes(fieldName)) {
        const difference = calculateGroupedData(ChangeActions.CREATE, fieldName, item, index);
        Object.keys(difference).map((curr) => {
          accum[curr] = difference[curr];
        });
        return accum;
      }

      const difference = findDifferenceInObject({ previousValue: null, currentValue: item }, index);
      Object.keys(difference.currentValue).map((idx) => {
        if (difference.previousValue[idx] || difference.currentValue[idx]) {
          const action = calculatePrimitiveAction(difference.previousValue[idx], difference.currentValue[idx], item);
          accum[
            `${fieldName}.${idx}${fieldName === 'candidateContacts' ? '.' + item.contactType : ''}_${index}_${action}`
          ] = {
            currentValue: difference.currentValue[idx],
            previousValue: difference.previousValue[idx],
            action,
          };
        }
      });
      return accum;
    }, {});
  }
  if (deletedItems.length) {
    deletedData = deletedItems.reduce<CandidateChangeStoryItem>((accum, item, index) => {
      if (fieldName === 'candidateRelocationCountries' && !currentCandidate.relocationAvailability) {
        return accum;
      }
      if (ArrayDictionaryValueFields.includes(fieldName)) {
        const changeItem = calculateNotUpdateDictionaryData(ChangeActions.DELETE, item);
        const name = `${fieldName}_${index}_${ChangeActions.DELETE}`;
        accum[name] = changeItem;
        return accum;
      }
      if (GroupedFields.includes(fieldName)) {
        const difference = calculateGroupedData(ChangeActions.DELETE, fieldName, item, index);
        Object.keys(difference).map((curr) => {
          accum[curr] = difference[curr];
        });
        return accum;
      }
      if (fieldName === 'candidateVisas' && !currentCandidate.visaAvailability) {
        return accum;
      }
      const difference = findDifferenceInObject({ previousValue: null, currentValue: item }, index);
      const temp = difference.currentValue;
      difference.currentValue = difference.previousValue;
      difference.previousValue = temp;
      Object.keys(difference.currentValue).map((idx) => {
        if (difference.previousValue[idx] || difference.currentValue[idx]) {
          const action = calculatePrimitiveAction(difference.previousValue[idx], difference.currentValue[idx], item);
          accum[
            `${fieldName}.${idx}${fieldName === 'candidateContacts' ? '.' + item.contactType : ''}_${index}_${action}`
          ] = {
            currentValue: difference.currentValue[idx],
            previousValue: difference.previousValue[idx],
            action,
          };
        }
      });
      return accum;
    }, {});
  }
  return { deletedData, createdData, updatedData };
};

export const candidateHistoryMapper = (
  ids: number[],
  history: {
    [key: string]: CandidateHistoryItemResponse;
  },
  currentCandidate: CandidateItemResponse,
): { [key: string]: CandidateHistoryItemResponse } => {
  const addChangesAction = (
    obj: CandidateChangeStoryItem,
    entityName: string,
    entityId: number,
    historySession: CandidateHistoryItemResponse,
  ) => {
    const newObj = Object.keys(obj).reduce<CandidateChangeStoryItem>((accum, curr, index) => {
      const isFullVisaDateField = curr === 'isFullVisaDate' && entityName === 'CandidateVisa';
      if ((IgnoredPropertyList.includes(curr) || GroupedFields.includes(entityName)) && !isFullVisaDateField) {
        return accum;
      }
      if (isArray(obj[curr].previousValue, obj[curr].currentValue)) {
        const { deletedData, createdData, updatedData } = findDifferenceInArray(
          obj[curr].currentValue,
          obj[curr].previousValue,
          curr,
          currentCandidate,
        );
        Object.keys(deletedData).map((item) => {
          accum[`${item}`] = deletedData[item];
        });
        Object.keys(createdData).map((item) => {
          accum[`${item}`] = createdData[item];
        });
        Object.keys(updatedData).map((item) => {
          accum[`${item}`] = updatedData[item];
        });
        return accum;
      }
      if (isObject(obj[curr].previousValue, obj[curr].currentValue)) {
        if (GroupedFields.includes(curr)) {
          const difference = calculateLocationData(obj[curr], index);
          Object.keys(difference).map((item) => {
            accum[`${curr}.${item}`] = difference[item];
          });
          return accum;
        }
        const difference = findDifferenceInObject(obj[curr], index);
        Object.keys(difference.currentValue).map((item) => {
          if (FieldsWithoutValue.includes(item)) {
            if (difference.currentValue[item] || difference.previousValue[item]) {
              accum[`${curr}.${item}`] = {
                currentValue: '',
                previousValue: '',
                action: calculatePrimitiveAction(difference.previousValue[item], difference.currentValue[item], item),
              };
            }
            return accum;
          }
          if (difference.currentValue[item] || difference.previousValue[item]) {
            accum[`${curr}.${item}`] = {
              currentValue: difference.currentValue[item],
              previousValue: difference.previousValue[item],
              action: calculatePrimitiveAction(difference.previousValue[item], difference.currentValue[item], item),
            };
          }
        });
        return accum;
      }
      if (curr === 'sourceDetails') {
        const source = historySession.ownUpdateStory.find((item) => item.entityName === 'Candidate')?.changedFields
          .source;
        if (source && source.currentValue.valueId !== source.previousValue.valueId) {
          return accum;
        }
      }
      const getFieldName = () => {
        if (entityName === 'CandidateContact') {
          const getContactType = () => {
            const contactType = historySession.ownUpdateStory
              .find((item) => item.entityName === 'Candidate')
              ?.changedFields.candidateContacts.currentValue.find((item) => item.id === entityId).contactType;
            if (contactType) return contactType;
          };
          const contactType = getContactType();
          if (contactType) {
            return `candidateContacts.${curr}.${contactType}`;
          }
          return curr;
        }
        return curr;
      };
      const action = calculatePrimitiveAction(obj[curr].previousValue, obj[curr].currentValue, curr);
      if (entityName === 'CandidateVisa' && curr === 'isFullVisaDate') {
        if (obj['validTo']) return accum;
        const visas = historySession.ownUpdateStory.find((item) => item.entityName === 'Candidate')?.changedFields
          .candidateVisas;
        const prevVisa = visas?.previousValue?.[0] || null;
        const currVisa = visas?.currentValue?.[0] || null;

        const { previous, current } = checkVisaValuesIsDate(
          'validTo',
          prevVisa?.['validTo'],
          currVisa?.['validTo'],
          !obj[curr]?.previousValue,
          !obj[curr]?.currentValue,
        );

        const action = calculatePrimitiveAction(prevVisa?.['validTo'], currVisa?.['validTo'], 'validTo');

        if (action && !IgnoredFieldActions[curr]?.includes(action)) {
          accum['validTo'] = {
            currentValue: current,
            previousValue: previous,
            action,
          };
          return accum;
        }
        return accum;
      }
      if (entityName === 'CandidateVisa' && curr === 'validTo') {
        const visas = historySession.ownUpdateStory.find((item) => item.entityName === 'Candidate')?.changedFields
          .candidateVisas;
        const prevVisa = visas?.previousValue?.[0] || null;
        const currVisa = visas?.currentValue?.[0] || null;
        const isFullVisaDatePrev =
          obj['isFullVisaDate']?.previousValue === null || obj['isFullVisaDate']?.previousValue === undefined
            ? prevVisa?.isFullVisaDate
            : obj['isFullVisaDate'].previousValue;
        const isFullVisaDateCurr =
          obj['isFullVisaDate']?.currentValue === null || obj['isFullVisaDate']?.currentValue === undefined
            ? currVisa?.isFullVisaDate
            : obj['isFullVisaDate'].currentValue;
        const { previous, current } = checkVisaValuesIsDate(
          curr,
          obj[curr]?.previousValue,
          obj[curr]?.currentValue,
          !isFullVisaDatePrev,
          !isFullVisaDateCurr,
        );
        if (action && !IgnoredFieldActions[curr]?.includes(action)) {
          if (FieldsWithoutValue.includes(curr)) {
            accum[getFieldName()] = {
              currentValue: '',
              previousValue: '',
              action,
            };
            return accum;
          }

          accum[getFieldName()] = {
            currentValue: current,
            previousValue: previous,
            action,
          };
          return accum;
        }
        return accum;
      }
      const { previous, current } = checkValuesIsDate(curr, obj[curr].previousValue, obj[curr].currentValue);
      if (action && !IgnoredFieldActions[curr]?.includes(action)) {
        if (FieldsWithoutValue.includes(curr)) {
          accum[getFieldName()] = {
            currentValue: '',
            previousValue: '',
            action,
          };
          return accum;
        }

        accum[getFieldName()] = {
          currentValue: current,
          previousValue: previous,
          action,
        };
        return accum;
      }
      return accum;
    }, {});
    let order = [];
    if (Object.keys(newObj).length) {
      const groupedArrays = Object.keys(newObj).reduce<{ [key: string]: string[] }>(
        (accum, curr) => {
          let key = '';
          if (curr.includes(ChangeActions.CREATE)) {
            key = ChangeActions.CREATE;
            accum[key].push(curr);
            return accum;
          }
          if (curr.includes(ChangeActions.DELETE)) {
            key = ChangeActions.DELETE;
            accum[key].push(curr);
            return accum;
          }
          key = ChangeActions.UPDATE;
          accum[key].push(curr);
          return accum;
        },
        { [ChangeActions.DELETE]: [], [ChangeActions.UPDATE]: [], [ChangeActions.CREATE]: [] },
      );
      const sortedArrays = Object.keys(groupedArrays).map((item) => {
        return groupedArrays[item].sort((prev) => {
          const nameParts = prev.split('.');
          if (item === ChangeActions.DELETE) {
            if (
              GroupedFields.includes(nameParts[0]) &&
              GeneralFieldsInGroup[nameParts[0]]?.name.includes(nameParts[1])
            ) {
              return 1;
            }
            return -1;
          }
          if (item === ChangeActions.CREATE) {
            if (
              GroupedFields.includes(nameParts[0]) &&
              GeneralFieldsInGroup[nameParts[0]]?.name.includes(nameParts[1])
            ) {
              return -1;
            }
            return 1;
          }
          return 0;
        });
      });
      order = [...sortedArrays.flat()];
    }
    return { changedFields: newObj, order };
  };

  return ids.reduce((accum, curr) => {
    const currentItem = history[curr];
    if (!currentItem) return accum;
    const story = currentItem.ownUpdateStory.reduce((accum, item) => {
      const { changedFields, order } = addChangesAction(
        item.changedFields,
        item.entityName,
        item.entityId,
        history[curr],
      );
      if (Object.keys(changedFields).length) {
        accum.push({
          ...item,
          order,
          changedFields,
        });
      }
      return accum;
    }, []);
    if (story.length) {
      accum[curr] = {
        ...currentItem,
        ownUpdateStory: story,
      };
    }

    return accum;
  }, {} as { [key: string]: CandidateHistoryItemResponse });
};

const EnumsFields = [
  'gender',
  'candidateWorkloads',
  'candidateWorkFormats',
  'employmentForms',
  'candidateLanguages.language',
  'language',
  'candidateLanguages.languageLevel',
  'languageLevel',
  'candidateProfessions.grade',
  'grade',
  'candidateReferral.startGrade',
];

const FieldsTranslation = {
  'candidateReferral.startGrade': 'grade',
  'candidateProfessions.grade': 'grade',
  'candidateLanguages.language': 'language',
  'candidateLanguages.languageLevel': 'languageLevel',
};

export const getHistoryActionValue = (action: ChangeActions, changeField: string, item: CandidateStoryItem) => {
  const isCurrentBoolean = typeof item.changedFields[changeField].currentValue === 'boolean';
  const isPreviousBoolean = typeof item.changedFields[changeField].previousValue === 'boolean';
  if (isCurrentBoolean || isPreviousBoolean) {
    const curr = i18n.t(
      `pages.candidates.candidateDetails.${item.changedFields[changeField].currentValue ? 'yes' : 'no'}`,
    );
    const prev = i18n.t(
      `pages.candidates.candidateDetails.${item.changedFields[changeField].previousValue ? 'yes' : 'no'}`,
    );
    if (action === ChangeActions.CREATE) {
      return curr;
    }
    if (action === ChangeActions.DELETE) {
      return prev;
    }
    if (action === ChangeActions.UPDATE) {
      return `${prev}<border> -> ${curr}`;
    }
    return '';
  } else {
    let prev = '';
    let curr = '';
    if (EnumsFields.find((item) => changeField.startsWith(item))) {
      const field = changeField.split('_')[0];
      prev = item.changedFields[changeField].previousValue
        ? i18n.t(`options.${FieldsTranslation[field] || field}Options_${item.changedFields[changeField].previousValue}`)
        : '';
      curr = item.changedFields[changeField].currentValue
        ? i18n.t(`options.${FieldsTranslation[field] || field}Options_${item.changedFields[changeField].currentValue}`)
        : '';
    } else {
      prev = item.changedFields[changeField].previousValue?.toString() || '';
      curr = item.changedFields[changeField].currentValue?.toString() || '';
    }
    if (action === ChangeActions.CREATE) {
      return curr;
    }
    if (action === ChangeActions.UPDATE && (prev || curr)) {
      return `${prev}<border> -> ${curr}`;
    }
    if (action === ChangeActions.DELETE) {
      return prev;
    }
    return '';
  }
};
