import {
  useAppDispatch,
  useAppSelector,
  VacancyItem,
  useDebounce,
  LocalizedVacanciesItemsState,
  LocalizedVacancyItemResponse,
  ThunkEffects,
  useInfiniteScroll,
} from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  clearVacanciesItemsAction,
  clearCandidateVacanciesAction,
  updatesVacanciesOptionsAction,
  clearVacanciesOptionsAction,
} from './vacancies.action';
import {
  getVacanciesItemsState,
  getVacancyItemById,
  getDefaultVacancyItemById,
  getVacanciesOptions,
  getCurrentVacancy,
  getVacanciesState,
} from './vacancies.selectors';
import {
  createVacancyThunk,
  getAllVacanciesThunk,
  getVacancyItemByIdThunk,
  deleteVacancyThunk,
  updateVacancyThunk,
  searchAllVacanciesThunk,
  exportVacanciesThunk,
  getCandidateLinkedVacanciesThunk,
  getCandidateVacanciesThunk,
  getVacanciesByIdsThunk,
  searchVacancyOptionsThunk,
} from './vacancies.thunk';
import { LifecycleStatuses, SortingValue } from '@constants';

export const useVacancyById = (id?: string): LocalizedVacancyItemResponse | null =>
  useAppSelector(getVacancyItemById(id));

export const useDefaultVacancy = (): VacancyItem | null => useAppSelector(getDefaultVacancyItemById);
export const useVacanciesOptions = (isRequired: boolean, withoutClosed?: boolean): SelectOption[] =>
  useAppSelector(getVacanciesOptions(isRequired, withoutClosed));
export const useVacanciesItemsState = (): LocalizedVacanciesItemsState => useAppSelector(getVacanciesItemsState);
export const useCurrentVacancy = (): LocalizedVacancyItemResponse | null => useAppSelector(getCurrentVacancy);

export const useVacanciesAPI = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    items: vacancies,
    currentVacancy,
    ids: vacanciesIds,
    totalPages,
    totalElements,
    isExportLoading,
    currentCandidateVacancies,
  } = useVacanciesItemsState();

  const createVacancy = useCallback(
    (data: VacancyItem, successCallback?: (vacancyId: string, locationId?: string) => void) => {
      dispatch(createVacancyThunk({ data, successCallback }));
    },
    [dispatch],
  );

  const updateVacancy = useCallback(
    (data: VacancyItem, id: number, successCallback?: () => void) => {
      dispatch(updateVacancyThunk({ data, id, successCallback }));
    },
    [dispatch],
  );

  const deleteVacancy = useCallback(
    (id: number, successCallback?: () => void) => {
      dispatch(deleteVacancyThunk({ id, successCallback }));
    },
    [dispatch],
  );

  const fetchAllVacancies = useCallback(() => {
    dispatch(getAllVacanciesThunk());
  }, [dispatch]);

  const getCandidateLinkedVacancies = useCallback(
    (data: Parameters<typeof getCandidateLinkedVacanciesThunk>[0]) => {
      return dispatch(getCandidateLinkedVacanciesThunk(data));
    },
    [dispatch],
  );

  const searchAllVacancies = useCallback(
    (data: Parameters<typeof searchAllVacanciesThunk>[0]) => {
      return ThunkEffects.takeLatest(dispatch(searchAllVacanciesThunk(data)), searchAllVacanciesThunk.typePrefix);
    },
    [dispatch],
  );

  const exportVacancies = useCallback(
    (body: Parameters<typeof exportVacanciesThunk>[0]) => {
      dispatch(exportVacanciesThunk(body));
    },
    [dispatch],
  );

  const fetchVacancyItemById = useCallback(
    (id: string) => {
      return dispatch(getVacancyItemByIdThunk({ id })).unwrap();
    },
    [dispatch],
  );

  const getCandidateVacancies = useCallback(
    (args: Parameters<typeof getCandidateVacanciesThunk>[0]) => {
      return dispatch(getCandidateVacanciesThunk(args));
    },
    [dispatch],
  );

  const clearCandidateVacancies = useCallback(() => {
    return dispatch(clearCandidateVacanciesAction());
  }, [dispatch]);

  const clearVacanciesItems = useCallback(() => {
    dispatch(clearVacanciesItemsAction());
  }, [dispatch]);

  return {
    isLoading,
    vacancies,
    vacanciesIds,
    totalPages,
    currentVacancy,
    isExportLoading,
    totalElements,
    currentCandidateVacancies,
    clearVacanciesItems,
    createVacancy,
    updateVacancy,
    fetchAllVacancies,
    searchAllVacancies,
    exportVacancies,
    fetchVacancyItemById,
    deleteVacancy,
    getCandidateLinkedVacancies,
    getCandidateVacancies,
    clearCandidateVacancies,
  };
};

export const useVacanciesOptionsApi = () => {
  const PAGE_SIZE = 50;
  const dispatch = useAppDispatch();
  const { vacancyOptions } = useAppSelector(getVacanciesState);

  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedValue] = useDebounce<string>(searchValue, 500);
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<string[]>([]);
  const [empty, setEmpty] = useState<boolean>(false);

  const options = useMemo(() => {
    const { selectedOptions, restOptions } = vacancyOptions.ids.reduce(
      (acc, id) => {
        if (selectedOptionsIds.includes(id)) {
          return {
            ...acc,
            selectedOptions: [...acc.selectedOptions, vacancyOptions.options[id]],
          };
        }
        return {
          ...acc,
          restOptions: [...acc.restOptions, vacancyOptions.options[id]],
        };
      },
      { selectedOptions: [], restOptions: [] },
    );
    return [...selectedOptions, ...restOptions];
  }, [vacancyOptions, selectedOptionsIds]);
  const { optionsLoading, totalPages, additionalOptionsLoading } = vacancyOptions;

  const getInitialOptions = () => setSearchValue('');

  const onSelectVacancy = (selectedVacancies: string[]) => {
    setSelectedOptionsIds(selectedVacancies);
    setSearchValue('');
  };

  const handleSearchVacancyOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const searchVacancyOptions = useCallback(
    (...args: Parameters<typeof searchVacancyOptionsThunk>) =>
      ThunkEffects.takeLatest(dispatch(searchVacancyOptionsThunk(...args)), searchVacancyOptionsThunk.typePrefix),
    [dispatch],
  );

  const getVacanciesByIds = useCallback(
    (...args: Parameters<typeof getVacanciesByIdsThunk>) => dispatch(getVacanciesByIdsThunk(...args)),
    [dispatch],
  );

  const updatesVacanciesOptions = useCallback(
    (...args: Parameters<typeof updatesVacanciesOptionsAction>) => dispatch(updatesVacanciesOptionsAction(...args)),
    [dispatch],
  );

  const fetchOptions = useCallback(
    (currentPage: number) =>
      dispatch(
        searchVacancyOptionsThunk({
          params: { pageSize: PAGE_SIZE, currentPage, sort: SortingValue.VacancyNameASC },
          body: {
            searchBarRequest: debouncedValue,
            lifecycleStatus: LifecycleStatuses.Actual,
          },
        }),
      ),
    [dispatch, debouncedValue],
  );

  const clearVacanciesOptions = useCallback(() => dispatch(clearVacanciesOptionsAction()), [dispatch]);

  const searchVacancyOptionsHandle = async (...args: Parameters<typeof searchVacancyOptions>) => {
    try {
      const { empty } = await searchVacancyOptions(...args).unwrap();
      return setEmpty(empty);
    } catch (error) {
      return;
    }
  };

  const searchPagableVacanciesOptionsHandle = async (...args: Parameters<typeof fetchOptions>) => {
    try {
      const { empty } = await fetchOptions(...args).unwrap();
      return setEmpty(empty);
    } catch (error) {
      return;
    }
  };

  const {
    lastElementRef: _lastElementRef,
    resetPageNum,
    pageNum,
  } = useInfiniteScroll(additionalOptionsLoading, totalPages);

  useEffect(() => {
    if (pageNum > 1) {
      searchPagableVacanciesOptionsHandle(pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    resetPageNum();
  }, [searchValue]);

  useEffect(() => {
    searchVacancyOptionsHandle({
      params: { currentPage: 1, pageSize: PAGE_SIZE, sort: SortingValue.VacancyNameASC },
      body: {
        searchBarRequest: debouncedValue,
        lifecycleStatus: LifecycleStatuses.Actual,
      },
    });
  }, [debouncedValue]);

  const lastElementRef = useMemo(() => {
    return empty ? null : _lastElementRef;
  }, [empty]);

  const resetEmptyState = () => {
    setEmpty(false);
    setSearchValue('');
  };

  return {
    options,
    items: vacancyOptions.items,
    totalPages,
    optionsLoading,
    additionalOptionsLoading,
    lastElementRef,
    resetEmptyState,
    fetchOptions,
    onSelectVacancy,
    getVacanciesByIds,
    getInitialOptions,
    updatesVacanciesOptions,
    handleSearchVacancyOptions,
    searchVacancyOptions: setSearchValue,
    clearVacanciesOptions,
  };
};
