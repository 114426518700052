import React, { Reducer, useEffect, useRef, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { ModalBase } from './modal.component';
import { ModalElement, ModalWrapper, Overlay, Grow } from './modal.styles';

type Action = { type: 'add'; modal: ModalBase } | { type: 'remove'; modalId: string };

type State = ModalBase[];

export let portalDispatch: React.Dispatch<Action> = null;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'add':
      return !state.map((item) => item.id).includes(action.modal.id) ? [...state, action.modal] : state;
    case 'remove':
      return state.filter((instance) => instance.id !== action.modalId);

    default:
      throw new Error();
  }
}

export interface PortalProps extends React.HTMLAttributes<HTMLDivElement> {
  portalId?: string;
}

const Portal: React.FC<PortalProps> = ({ portalId = 'modal-portal', ...props }) => {
  const [collection, dispatch] = useReducer<Reducer<ModalBase[], Action>>(reducer, []);

  const portalRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const element = document.createElement('div');
    const portalElement = document.getElementById(portalId);
    if (portalElement) {
      portalElement.appendChild(element);
      portalRef.current = element;
    }

    return () => {
      element.remove();
    };
  }, []);

  useEffect(() => {
    portalDispatch = dispatch;
  }, [dispatch]);

  if (collection.length > 0) {
    const modalContent = (
      <ModalWrapper>
        {collection.map(({ id, renderModal }, index) => (
          <ModalElement key={id} {...props} index={index}>
            <Overlay />
            <Grow in={true}>
              <div>{renderModal()}</div>
            </Grow>
          </ModalElement>
        ))}
      </ModalWrapper>
    );
    return portalRef?.current ? ReactDOM.createPortal(modalContent, portalRef.current) : modalContent;
  }

  return null;
};

export default Portal;
