import { useRef } from 'react';
import { addModal, closeModal, ModalBase } from './modal.component';

export const useModal = <T extends object>(ModalClass: new (data: T) => ModalBase<T>) => {
  const instanceRef = useRef<ModalBase<T>>(null);

  return [
    (data: T = null) => {
      instanceRef.current = new ModalClass(data);
      addModal(instanceRef.current);
    },
    () => {
      if (instanceRef.current) {
        closeModal(instanceRef.current.id);
      }
    },
    (data: T = null) => {
      if (instanceRef.current) {
        closeModal(instanceRef.current.id);
        instanceRef.current = null;
        return;
      }
      instanceRef.current = new ModalClass(data);
      addModal(instanceRef.current);
    },
  ];
};
