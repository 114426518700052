import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MaxSearchCharactersCount } from '@constants';
import * as Styled from './comment-form.styles';
import { Mention } from 'react-mentions';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EmployeeOption,
  EmployeesService,
  LocalStorageService,
  useCurrentCandidateVacancyOptions,
  useEmployeesAPI,
  useMentionedEmployeesOptions,
  useResizeObserver,
} from '@innowise-group/core';
import { AvatarMenu } from '../avatar-menu';
import { Input, Select, Form, useTheme, AvatarItem, DropdownListContainer } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';

interface CommentFormProps {
  isThread?: boolean;
  photoId?: number;
  firstNameRu?: string;
  lastNameRu?: string;
  cancelHandler?: () => void;
  submitHandler?: () => void;
  saveDisable?: boolean;
  withPadding?: boolean;
  isStatus?: boolean;
  commentRef?: React.MutableRefObject<HTMLTextAreaElement>;
  persistCommentId?: string;
}

// TODO: use debouncedValue
// TODO: use redux for employeesOptions
const CommentForm: React.FC<CommentFormProps> = ({
  isThread,
  photoId,
  firstNameRu,
  lastNameRu,
  cancelHandler,
  submitHandler,
  saveDisable,
  isStatus,
  withPadding,
  commentRef,
  persistCommentId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const storeEmployees = useMentionedEmployeesOptions();
  const vacanciesOptions = useCurrentCandidateVacancyOptions();
  const { addMention } = useEmployeesAPI();
  const [employeeOptions, setEmployeeOptions] = useState<EmployeeOption[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [cachedStoreEmployees, setCachedStoreEmployees] = useState<Array<EmployeeOption>>([]);

  const parentFieldName = useMemo(() => {
    return isStatus ? 'comment.' : '';
  }, [isStatus]);

  const { control, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (persistCommentId) {
      const storedComment = LocalStorageService.getItem(persistCommentId) as string;
      const storedCommentCachedStore =
        (LocalStorageService.getItem(`${persistCommentId}-mentions`) as Array<EmployeeOption>) || [];

      if (storedComment) {
        setImmediate(() => {
          setValue(`${parentFieldName}comment`, storedComment);
        });
      }

      if (storedCommentCachedStore) {
        setImmediate(() => {
          setCachedStoreEmployees(storedCommentCachedStore);
        });
      }
    }
  }, [persistCommentId]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const mergedStoreEmployees = useMemo(() => {
    if (!persistCommentId) {
      return storeEmployees;
    }

    const ids = new Set(storeEmployees.map((employee) => employee.id));
    const mergedList = [...storeEmployees, ...cachedStoreEmployees.filter((employee) => !ids.has(employee.id))];

    return mergedList;
  }, [persistCommentId, storeEmployees, cachedStoreEmployees]);

  const handleBlur = () => {
    if (persistCommentId) {
      const enteredComment = getValues()[`${parentFieldName}comment`];

      if (enteredComment) {
        LocalStorageService.setItem(persistCommentId, enteredComment);
        LocalStorageService.setItem(`${persistCommentId}-mentions`, storeEmployees);
      } else {
        LocalStorageService.removeItem(persistCommentId);
        LocalStorageService.removeItem(`${persistCommentId}-mentions`);
        setCachedStoreEmployees([]);
      }
    }

    setIsFocused(false);
  };

  const getCurrentEmployeeTag = (id: number) => {
    const display = mergedStoreEmployees.find((item) => item.id.toString() === id.toString())?.display || '';
    return `@${display}`;
  };

  const handleAddMention = useCallback(
    (id: number, display: string, startPos: number) => {
      const employee = employeeOptions.find((item) => item.id.toString() === id.toString());
      if (employee) {
        addMention({ ...employee, pos: startPos + display.length + 2 });
      }
    },
    [employeeOptions],
  );

  const handleCancelClick = useCallback(() => {
    cancelHandler();

    if (persistCommentId) {
      LocalStorageService.removeItem(persistCommentId);
      LocalStorageService.removeItem(`${persistCommentId}-mentions`);
    }
  }, [cancelHandler, persistCommentId]);

  const handleSubmitClick = useCallback(() => {
    submitHandler();

    if (persistCommentId) {
      LocalStorageService.removeItem(persistCommentId);
      LocalStorageService.removeItem(`${persistCommentId}-mentions`);
    }
  }, [submitHandler, persistCommentId]);

  const getEmployees = async (query: string, callback) => {
    if (query.trim()) {
      if (query.trim().length <= MaxSearchCharactersCount) {
        const spacesCount = query.split(' ').length;
        if (spacesCount > 3) {
          return [];
        }
        const response = await EmployeesService.searchEmployees({ partOfName: query.trim() });
        const options = response.data.content.map((item) => {
          return {
            display: `${item.firstNameEn} ${item.lastNameEn}`,
            id: item.employeeId,
            photoId: item.photoRt?.id || 0,
          };
        });
        setEmployeeOptions(options);
        callback(options);
      } else {
        callback(employeeOptions);
      }
    } else {
      return [{ id: query, display: query }];
    }
  };

  useEffect(() => {
    if (mergedStoreEmployees.length && commentRef) {
      commentRef.current.setSelectionRange(
        mergedStoreEmployees[mergedStoreEmployees.length - 1].pos,
        mergedStoreEmployees[mergedStoreEmployees.length - 1].pos,
      );
    }
  }, [mergedStoreEmployees.length, commentRef]);

  return (
    <Styled.Container withPadding={withPadding}>
      {firstNameRu && lastNameRu && (
        <Styled.InfoContainer>
          <AvatarMenu firstNameRu={firstNameRu} lastNameRu={lastNameRu} photoId={photoId} inTwoColumn={isDesktopView} />
          {!isThread && (
            <Controller
              name="linkedVacancyId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  disableClearable
                  options={vacanciesOptions || []}
                  renderInput={(params) => {
                    return (
                      <Input
                        {...params}
                        fullWidth
                        label={t('pages.candidates.candidateCreation.vacancy')}
                        placeholder={t('pages.candidates.candidateCreation.selectTheVacancy')}
                      />
                    );
                  }}
                />
              )}
            />
          )}
        </Styled.InfoContainer>
      )}
      <Styled.ContentContainer withPadding={!isStatus}>
        <Controller
          name={`${parentFieldName}comment`}
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <Form.Field error={isStatus ? errors?.comment?.['comment']?.message : errors?.comment?.message}>
                <Styled.CommentContainer
                  isFocused={isFocused}
                  errors={!!(isStatus ? errors?.comment?.['comment']?.message : errors?.comment?.message)}
                >
                  <Styled.CommentTextareaContainer>
                    <Styled.TextAreaElement
                      placeholder={t('pages.candidates.comments.commentPlaceholder')}
                      value={value}
                      onChange={onChange}
                      inputRef={commentRef}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      allowSpaceInQuery
                      rows={4}
                      customSuggestionsContainer={(child: React.ReactNode) => {
                        return (
                          <DropdownListContainer position="absolute" vertical="top">
                            <Styled.TagsDropdownList>{child}</Styled.TagsDropdownList>
                          </DropdownListContainer>
                        );
                      }}
                    >
                      <Mention
                        trigger={/(@([a-zA-Z]*))$/}
                        markup="{{EMPLOYEE|__id__}}"
                        displayTransform={getCurrentEmployeeTag}
                        data={getEmployees}
                        onAdd={handleAddMention}
                        allowSuggestionsAboveCursor
                        renderSuggestion={(entry: { id: number; display: string; photoId?: number }) => {
                          return (
                            <Styled.ListItem>
                              <AvatarItem photoId={entry.photoId.toString()} name={entry.display} isOption />
                            </Styled.ListItem>
                          );
                        }}
                      />
                    </Styled.TextAreaElement>
                  </Styled.CommentTextareaContainer>
                </Styled.CommentContainer>
              </Form.Field>
            );
          }}
        />
      </Styled.ContentContainer>
      {(!isStatus || cancelHandler || submitHandler) && (
        <Styled.FooterContainer>
          <div>
            {!isStatus && (
              <Controller
                name={`${parentFieldName}isProtected`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LabeledCheckbox
                    onChange={onChange}
                    checked={value}
                    label={t('pages.candidates.comments.restrictAccess')}
                  />
                )}
              />
            )}
          </div>
          <Styled.ButtonsContainer>
            {cancelHandler && (
              <Styled.CancelButton variant="outlined" onClick={handleCancelClick}>
                {t('buttons.cancel')}
              </Styled.CancelButton>
            )}
            {submitHandler && (
              <Styled.PostButton onClick={handleSubmitClick} disabled={saveDisable}>
                {t('buttons.post')}
              </Styled.PostButton>
            )}
          </Styled.ButtonsContainer>
        </Styled.FooterContainer>
      )}
    </Styled.Container>
  );
};

export default CommentForm;
