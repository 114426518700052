import { styled } from '@innowise-group/mui-kit';
import { tooltipClasses } from '@mui/material';
import { Tooltip } from './tooltip.component';

export const StyledTooltip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    ${({ theme }) => theme.fonts.primary[40]};
    background-color: ${({ theme }) => theme.palette.general.paper};
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    border-radius: 0.625rem;
    width: max-content;
    padding: 0.625rem;
    white-space: pre-wrap;
    max-width: 25rem;
    white-space: pre-wrap;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  }
`;
