import { AnimationWrapper } from '../shared';
import { Button, Input as BaseInput, Divider, styled, css } from '@innowise-group/mui-kit';

const FontStyles = css`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const FilterWrapper = styled.div`
  max-height: 50vh;
  width: 350px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  background: ${({ theme }) => theme.palette.general.paper};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const VariantsList = styled.div`
  flex: 1;
  height: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &::-webkit-scrollbar-track {
    border: 2px solid ${({ theme }) => theme.palette.general.paper};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.general.lightGrey[80]};
    border-right: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-left: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-top: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
    border-bottom: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
`;

export const Preselected = styled.div``;

export const VariantContainer = styled.button<{ even?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  width: 100%;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background: ${({ theme, even }) => (even ? theme.palette.general.darkGrey[20] : theme.palette.general.paper)};
`;

export const Label = styled.span`
  font-family: Manrope;
  font-style: normal;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: inline-block;
  word-break: normal;
  overflow-wrap: normal;
`;

export const Select = styled.input`
  cursor: pointer;
  width: 17px;
  height: 17px;
  accent-color: ${({ theme }) => theme.palette.general.smart};
  font: inherit;
  font-size: 3px;
`;

export const CustomDivider = styled(Divider)`
  margin: 2px;
`;

export const BottomControls = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.general.lightGrey[100]};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const AmountLabel = styled(Label)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  ${FontStyles}
`;

export const CustomButton = styled(Button)`
  padding: 0;
  height: auto;
  width: auto;
  white-space: nowrap;
  ${FontStyles}
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const Input = styled(BaseInput)`
  width: 100%;
  &.MuiTextField-root {
    padding: 0 10px;
  }
  &.MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
