import { memo } from 'react';
import { Menu } from './animation-wrapper.styles';
import React from 'react';
import { MenuProps } from '@mui/material';

const AnimationWrapper: React.FC<MenuProps> = React.forwardRef(({ ...props }, ref) => (
  <Menu marginThreshold={16} {...props} ref={ref} />
));

export default memo(AnimationWrapper);
