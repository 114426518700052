import { SelectOption } from '@innowise-group/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getMonths = () => {
  const { t } = useTranslation();
  const months: SelectOption[] = useMemo(() => {
    return [
      { value: '0', title: t('pages.candidates.months.january') },
      { value: '1', title: t('pages.candidates.months.february') },
      { value: '2', title: t('pages.candidates.months.march') },
      { value: '3', title: t('pages.candidates.months.april') },
      { value: '4', title: t('pages.candidates.months.may') },
      { value: '5', title: t('pages.candidates.months.june') },
      { value: '6', title: t('pages.candidates.months.july') },
      { value: '7', title: t('pages.candidates.months.august') },
      { value: '8', title: t('pages.candidates.months.september') },
      { value: '9', title: t('pages.candidates.months.october') },
      { value: '10', title: t('pages.candidates.months.november') },
      { value: '11', title: t('pages.candidates.months.december') },
    ];
  }, [t]);
  return months;
};
