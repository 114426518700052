import {
  FormattedCandidateDiffMerge,
  PrimitiveValuesUnionInCandidatesDiff,
  StringArrDataFields,
} from '@innowise-group/core';

export const primitiveFields: PrimitiveValuesUnionInCandidatesDiff[] = [
  'birthDate',
  'candidateSkills',
  'firstNameEn',
  'firstNameRu',
  'isBlockedReason',
  'lastNameEn',
  'lastNameRu',
  'responsibleEmployee',
  'visaAvailability',
  'aboutMe',
  'gender',
];

export const activityPlaceFields: (keyof Pick<
  FormattedCandidateDiffMerge,
  'candidateExperiences' | 'candidateEducations' | 'candidateCourses'
>)[] = ['candidateCourses', 'candidateEducations', 'candidateExperiences'];

export const selectDataFields: (
  | keyof Pick<
      FormattedCandidateDiffMerge,
      | 'candidateTechnologies'
      | 'candidateDomains'
      | 'candidateRelocationCountries'
      | 'employmentForms'
      | 'candidateWorkFormats'
      | 'candidateWorkloads'
    >
)[] = [
  'candidateDomains',
  'candidateTechnologies',
  'candidateRelocationCountries',
  'candidateWorkFormats',
  'candidateWorkloads',
  'employmentForms',
];

export const autoMergeCandidateFields = [
  ...primitiveFields,
  'relocationAvailability',
  'isBlocked',
  'email',
  'candidateVisas',
] as const;

export const stringArrDataFields: StringArrDataFields[] = [
  'candidateDomains',
  'candidateTechnologies',
  'candidateWorkFormats',
  'candidateWorkloads',
  'candidateProfessions',
  'candidateLanguages',
  'employmentForms',
];
