import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChipProps } from '@mui/material';
import * as Styled from './read-only-chip.styles';
import { MaxChipLength } from '@constants';

interface ReadOnlyChipProps {
  opened?: boolean;
}

const ReadOnlyChip: React.FC<ChipProps & ReadOnlyChipProps> = ({
  opened,
  label,
  variant = 'filled',
  onClick,
  ...props
}) => {
  const [isHidden, setIsHidden] = useState<boolean | null>(null);
  const chipRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setIsHidden((prev) => !prev);
      onClick?.(event);
    },
    [onClick],
  );

  useEffect(() => {
    if (opened) {
      setIsHidden(null);
      return;
    }
    if (chipRef.current?.getBoundingClientRect().width > MaxChipLength) {
      setIsHidden(true);
    }
  }, [opened]);

  return label ? (
    <Styled.CustomChip
      {...props}
      ref={chipRef}
      label={label}
      variant={variant}
      onClick={isHidden !== null ? handleClick : onClick}
      ishidden={isHidden}
    />
  ) : null;
};

export default React.memo(ReadOnlyChip);
