import React from 'react';
import * as Styled from './part-of-grouped-notification.styles';

interface PartOfGroupedNotificationProps {
  title: string;
  message: string;
}

const PartOfGroupedNotification: React.FC<PartOfGroupedNotificationProps> = ({ title, message }) => {
  return (
    <Styled.Container>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Message>{message}</Styled.Message>
    </Styled.Container>
  );
};

export default PartOfGroupedNotification;
