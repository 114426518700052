import { useCurrentCandidateVacancyOptions, useVacanciesAPI } from '@innowise-group/core';
import { Input } from '@innowise-group/mui-kit';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './vacancy-section.styles';

interface VacancySectionProps {
  disabled: boolean;
}

const VacancySection: React.FC<VacancySectionProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const vacanciesOptions = useCurrentCandidateVacancyOptions(true);
  const { clearVacanciesItems } = useVacanciesAPI();

  const { control, watch, resetField } = useFormContext();
  const statusId = watch('statusId');

  useEffect(() => {
    resetField('vacancyId');
    return () => {
      clearVacanciesItems();
    };
  }, [resetField, statusId, clearVacanciesItems]);

  return (
    <Controller
      name="vacancyId"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <Styled.Wrapper>
            <Styled.Select
              value={value?.toString()}
              onChange={onChange}
              disableClearable={!value}
              disabled={disabled}
              options={vacanciesOptions || []}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    error={!!errors?.vacancyId?.message}
                    helperText={errors?.vacancyId?.message.toString()}
                    label={t('pages.candidates.candidateCreation.vacancy')}
                    placeholder={t('pages.candidates.candidateCreation.selectTheVacancy')}
                  />
                );
              }}
            />
            <Styled.IconContainer>
              <Styled.InfoTooltip title={t('tooltips.statusModalVacancy')} placement="top-end" />
            </Styled.IconContainer>
          </Styled.Wrapper>
        );
      }}
    />
  );
};

export default VacancySection;
