import { styled, Button as ButtonBase } from '@innowise-group/mui-kit';

export const Button = styled(ButtonBase)`
  &.MuiButton-root.MuiButton-outlined {
    margin: 0 auto;
    width: fit-content;
    padding: 15px 1.5rem;
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;
