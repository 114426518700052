import type { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { $api } from '../axios/axios';
import { CandidateOfferItem } from 'shared/core/store';
import { DateFormats } from '@constants';

export default class OffersService {
  static async getCandidateOffers(candidateId: number): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/offer/${candidateId}`);
  }

  static async createOffer(data: CandidateOfferItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/offer`, {
      ...data,
      responseDate: data.responseDate ? format(new Date(data.responseDate), DateFormats.ISO) : data.responseDate,
      offerDate: data.offerDate ? format(new Date(data.offerDate), DateFormats.ISO) : data.offerDate,
      estimatedReleaseDate: data.estimatedReleaseDate
        ? format(new Date(data.estimatedReleaseDate), DateFormats.ISO)
        : data.estimatedReleaseDate,
    });
  }

  static async updateOffer(data: CandidateOfferItem): Promise<AxiosResponse> {
    return $api.put(`/recruiting-tool/api/v1/offer`, {
      ...data,
      responseDate: data.responseDate ? format(new Date(data.responseDate), DateFormats.ISO) : data.responseDate,
      offerDate: data.offerDate ? format(new Date(data.offerDate), DateFormats.ISO) : data.offerDate,
      estimatedReleaseDate: data.estimatedReleaseDate
        ? format(new Date(data.estimatedReleaseDate), DateFormats.ISO)
        : data.estimatedReleaseDate,
    });
  }

  static async deleteOffer(id: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/offer/${id}`);
  }

  static async restoreOffer(id: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/offer/${id}`);
  }
}
