import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { useEmployeesAdministrationAPI } from '@innowise-group/core';
import * as Styled from './delete-user';

interface DeleteUserModalProps {
  id: number;
  reloadUsers: () => void;
}

const DeleteUserModal: React.FC<ModalProps & DeleteUserModalProps> = ({ close, reloadUsers, id }) => {
  const { t } = useTranslation();
  const { employees, deleteEmployee } = useEmployeesAdministrationAPI();

  const onCloseHandler = () => {
    close();
  };

  const onSubmitHandler = async () => {
    await deleteEmployee(id.toString());
    close();
    await reloadUsers();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={onCloseHandler}>
        <Styled.Header>{t('pages.administration.newUser.deleteUser')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>
          <Trans
            i18nKey="pages.administration.newUser.deleteUserText"
            values={{ userName: employees[id].localizedFullName }}
            components={{
              profile: <Styled.HightLightedUserName />,
            }}
          />
        </Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.no'),
            onClick: onCloseHandler,
            type: 'button',
          },
          {
            title: t('buttons.yes'),
            onClick: onSubmitHandler,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(DeleteUserModal, 'delete-user');
