import {
  ClaimActionTypes,
  ContactsIcons,
  ContactsType,
  StorageKeys,
  StatusConfigFlags,
  RolesValues,
  CandidateLifecycleStatuses,
  OffersType,
} from '@constants';
import {
  CandidateItem,
  CandidateItemResponse,
  SessionStorageService,
  useAppSelector,
  CandidateCommentItem,
  CandidateFilesIdsState,
  CandidateFilesState,
  FileIdResponse,
  CandidateContacts,
  LocalizedCandidateHistoryState,
  LocalizedCandidateItemResponse,
  HistoryCommentItem,
  LocalizedHistoryCommentsState,
  ShortCandidateItemResponse,
  CandidatesConsolidation,
  RecruiterModeState,
  RecruiterModeItem,
  useFiltersData,
  CandidateAllStatusesState,
  CandidateStatusListItem,
  StatusItem,
  LocalizedCandidateEventsState,
  LocalizedStatusItemResponse,
  LocalizedCurrentCandidateStatusState,
  CandidateItemDiff,
  ThunkEffects,
  useProfileState,
  useAppDispatch,
  CandidateOfferItem,
  CandidateOffersState,
  CandidateClaimState,
} from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import { useCallback, useEffect, useMemo } from 'react';
import {
  toggleCandidateDupleAction,
  saveNewCandidateInfoAction,
  clearCandidateAvatarAction,
  clearCandidateFileAttachmentAction,
  clearCandidateFilesIdsAction,
  clearCandidateHistoryAction,
  clearCandidatesAction,
  deleteHistoryCommentAction,
  saveCandidatesDiffAction,
  updateCandidatePrimitiveValuesAction,
  updateCandidatesContactsDiffAction,
  addProfessionInToCandidatesDiffAction,
  updateProfessionNameInCandidatesDiffAction,
  updateProfessionGradeInCandidatesDiffAction,
  addLanguageInToCandidatesDiffAction,
  updateLanguageNameInCandidatesDiffAction,
  updateLanguageLevelInCandidatesDiffAction,
  addORDeleteCandidateDiffMultiSelectElemAction,
  setCandidateDiffMultiSelectElemAction,
  updateLocalizationEntityAction,
  setLocalizationEntityAction,
  updateVisaEntityAction,
  resetCandidateRelocationCountriesAction,
  resetCandidateVisasAction,
  setVisaEntityAction,
  updateCandidateSalaryAction,
  updateCandidateSalaryFieldAction,
  setWorkFormatsAndLoadsAction,
  updateEntityFieldAction,
  manageCandidateMapDataAction,
  resetCandidatesStateAction,
  clearDuplicatesReportAction,
  restoreHistoryCommentAction,
  clearRecruiterCandidateAction,
  manageCandidateConsolidationFilesAction,
  setCandidateMapDataAction,
  clearStatusListAction,
  clearCurrentStatusAction,
  clearCandidateEventsAction,
  deleteCommentAction,
  restoreCommentAction,
  setCurrentStatusItemAction,
  mergeCandidateAction,
  setPagableAction,
  setRecruiterModeConfigAction,
  clearActualStatusAction,
  manageWorkFormatsLoadsEmploymentsAction,
  removeProfessionsIdsFromCandidatesDiffAction,
  removeProfessionsIdsFromVisaEntityAction,
  setCurrentCandidateAction,
  deleteCandidateLinkAction,
  clearCandidatesOffersAction,
  clearCandidatesClaimAction,
} from './candidates.action';
import {
  getCandidateAllExperience,
  getCandidateByIdState,
  getCandidateContactsItems,
  getCandidateDeletedFilesIds,
  getCandidatesFilesToAttachState,
  getCandidatesFilesState,
  getCandidatesHistoryState,
  getCandidatesItemsState,
  getCurrentCandidate,
  getCurrentCandidateAvatar,
  getDefaultCandidateById,
  getCandidateDuplicates,
  getUnsavedCandidate,
  getCandidatesDiff,
  getCandidatesConsolidation,
  getUnsavedCandidateFormValues,
  getCandidatesReportState,
  getHistoryCommentsState,
  getRecruiterCandidatesState,
  getCandidatesAllStatusesState,
  getStatusesOptions,
  getStatusConfigById,
  getCurrentStatusState,
  getCandidatesEventsState,
  getActualCandidateStatus,
  getCurrentCandidateVacancyOptions,
  getCandidatesOffersState,
  getCandidatesOffersFormData,
  getCandidateStatusesOptionsWithNestedStatuses,
  getCurrentStatusInfo,
  getCandidatesRequestClaimState,
} from './candidates.selectors';
import {
  createCandidateThunk,
  getAllCandidatesThunk,
  getCandidateByIdThunk,
  deleteCandidateByIdThunk,
  updateCandidateThunk,
  checkCandidatesDuplicatesThunk,
  createCandidateFileIdThunk,
  createCandidateCommentThunk,
  updateCandidateCommentThunk,
  deleteCandidateCommentsThunk,
  getCandidateFilesIdsThunk,
  deleteCandidateFileIdThunk,
  getCandidateFileDataThunk,
  linkCandidateFileThunk,
  createCandidateAvatarPhotoThunk,
  getCandidatePdfParsedInfoThunk,
  attachDuplicatesIdsToCandidateThunk,
  getCandidateChangeHistoryThunk,
  duplicatesReportThunk,
  fetchCandidatesPairByIdThunk,
  resolveCandidateDuplicatesThunk,
  mergeCandidateDuplicatesThunk,
  createHistoryCommentThunk,
  updateHistoryCommentThunk,
  getHistoryCommentThunk,
  deleteHistoryCommentsThunk,
  candidatesExportThunk,
  prioritizeCandidatesThunk,
  getPrioritizeCandidatesThunk,
  updateVacancyCandidatePriorityThunk,
  getStatusListThunk,
  updateCandidateStatusThunk,
  createCandidateStatusThunk,
  getCandidateEventsThunk,
  createCandidateThreadCommentThunk,
  updateCandidateThreadCommentThunk,
  resolveStatusClaimThunk,
  getCandidateActualStatusThunk,
  restoreAdminEventThunk,
  restoreAdminChangeCommentThunk,
  restoreCandidateByIdThunk,
  findExistingCandidateDuplicatesThunk,
  getPrioritizeCandidatesByVacancyIdThunk,
  getOffersByCandidateIdThunk,
  createCandidateOfferThunk,
  deleteCandidateOfferThunk,
  restoreCandidateOfferThunk,
  updateCandidateOfferThunk,
  fetchCandidateRequestClaimThunk,
  saveStatusesOrdersThunk,
} from './candidates.thunk';
import { GrabbedCandidate } from 'modules/candidates/pages/candidate-creation/candidate-creation.types';
import { UseFormReset, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { localizedNameObject } from '@innowise-group/utilities';
import { useTranslation } from 'react-i18next';

export const useCandidateItemsState = () => useAppSelector(getCandidatesItemsState);
export const useCandidateItemById = (id: number): ShortCandidateItemResponse | null =>
  useAppSelector(getCandidateByIdState(id));

export const useCurrentCandidate = (): LocalizedCandidateItemResponse | null => useAppSelector(getCurrentCandidate);
export const useCurrentCandidateVacancyOptions = (withoutJobOpening?: boolean): SelectOption[] =>
  useAppSelector(getCurrentCandidateVacancyOptions(withoutJobOpening));

export const useUnsavedCandidateFormValues = () => useAppSelector(getUnsavedCandidateFormValues);

export const useCandidatesDiff = (): CandidatesConsolidation => useAppSelector(getCandidatesDiff);

export const useDefaultCandidateById = (): CandidateItem | null => useAppSelector(getDefaultCandidateById);

export const useCandidateContactsItems = (): {
  socialNetworks: { value: string; icon: ContactsIcons; linkText?: string }[];
  phoneNumber: { value: string; title: string };
  additionalPhoneNumber: { value: string; title: string };
} => useAppSelector(getCandidateContactsItems);
export const useCandidateAllExperience = (): { experienceWithoutIT: string; experienceOnlyIT: string } =>
  useAppSelector(getCandidateAllExperience);

export const useCandidateDuplicates = (): {
  duplicates: { [key: string]: CandidateItemResponse & ReturnType<typeof localizedNameObject> };
  duplicatesIds: number[];
  isCheckedDuplicates: boolean;
} => useAppSelector(getCandidateDuplicates);

const useUnsavedCandidate = (): CandidateItemResponse | null => useAppSelector(getUnsavedCandidate);

export const useUnsavedCandidateAPI = () => {
  const dispatch = useAppDispatch();

  const unsavedCandidate = useUnsavedCandidate();

  const saveNewCandidateInfo = useCallback(
    (data: CandidateItemResponse) => {
      return dispatch(saveNewCandidateInfoAction(data));
    },
    [dispatch],
  );

  return {
    saveNewCandidateInfo,
    unsavedCandidate,
  };
};

export const useFillUnsavedCandidateForm = (args: {
  reset: UseFormReset<CandidateItemDiff>;
  trigger: UseFormTrigger<CandidateItemDiff>;
  watch: UseFormWatch<CandidateItemDiff>;
}) => {
  const { reset, trigger, watch } = args;
  const { candidatesDiffs } = useCandidatesDiff();

  const formats = watch('candidateWorkFormats');
  const loads = watch('candidateWorkloads');
  const employmentForms = watch('employmentForms');

  useEffect(() => {
    reset((prev) => ({
      ...prev,
      id: candidatesDiffs.id,
      photoId: candidatesDiffs.photoId,
      filesMeta: candidatesDiffs?.filesMeta,
      gender: candidatesDiffs?.gender?.preselected,
      aboutMe: candidatesDiffs?.aboutMe?.preselected,
      vacancy: candidatesDiffs?.candidatePrioritizedVacancies?.preselected,
      candidateLinks: candidatesDiffs?.candidateLinks?.preselected,
      firstNameRu: candidatesDiffs?.firstNameRu.preselected,
      lastNameRu: candidatesDiffs?.lastNameRu.preselected,
      firstNameEn: candidatesDiffs?.firstNameEn.preselected,
      lastNameEn: candidatesDiffs?.lastNameEn.preselected,
      birthDate: candidatesDiffs?.birthDate?.preselected ? new Date(candidatesDiffs.birthDate.preselected) : null,
      // TODO: update after package consolidation
      citizenshipId: candidatesDiffs?.citizenshipId?.preselected,
      email: candidatesDiffs?.candidateContacts[CandidateContacts.Email].preselected,
      candidateContacts: [
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.Telegram]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.Telegram].id,
          }),
          contactType: CandidateContacts.Telegram,
          contact: { value: candidatesDiffs?.candidateContacts[CandidateContacts.Telegram]?.preselected || '' },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.Linkedin]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.Linkedin].id,
          }),
          contactType: CandidateContacts.Linkedin,
          contact: { value: candidatesDiffs?.candidateContacts[CandidateContacts.Linkedin]?.preselected || '' },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.Github]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.Github].id,
          }),
          contactType: CandidateContacts.Github,
          contact: { value: candidatesDiffs?.candidateContacts[CandidateContacts.Github]?.preselected || '' },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.Phone]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.Phone].id,
          }),
          contactType: CandidateContacts.Phone,
          contact: {
            ...prev.candidateContacts[3].contact,
            value: candidatesDiffs?.candidateContacts?.[CandidateContacts.Phone]?.preselected?.replace?.('+', '') || '',
          },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.AdditionalPhone]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.AdditionalPhone].id,
          }),
          contactType: CandidateContacts.AdditionalPhone,
          contact: {
            ...prev.candidateContacts[4].contact,
            value:
              candidatesDiffs?.candidateContacts?.[CandidateContacts.AdditionalPhone]?.preselected?.replace?.(
                '+',
                '',
              ) || '',
          },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.BEHANCE]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.BEHANCE].id,
          }),
          contactType: CandidateContacts.BEHANCE,
          contact: { value: candidatesDiffs?.candidateContacts[CandidateContacts.BEHANCE]?.preselected || '' },
        },
        {
          ...(candidatesDiffs?.candidateContacts[CandidateContacts.SKYPE]?.id && {
            id: candidatesDiffs?.candidateContacts[CandidateContacts.SKYPE].id,
          }),
          contactType: CandidateContacts.SKYPE,
          contact: { value: candidatesDiffs?.candidateContacts[CandidateContacts.SKYPE]?.preselected || '' },
        },
      ],
      candidateProfessions: candidatesDiffs?.candidateProfessions?.preselected?.map(
        ({ professionId, gradeId, id }) => ({
          ...(id && { id }),
          professionId,
          gradeId,
        }),
      ),
      candidateLanguages: candidatesDiffs?.candidateLanguages?.preselected?.map(
        ({ languageId, languageLevelId, id }) => ({
          ...(id && { id }),
          languageId,
          languageLevelId,
        }),
      ),
      candidateTechnologies: candidatesDiffs?.candidateTechnologies.preselected,
      candidateDomains: candidatesDiffs?.candidateDomains.preselected,
      candidateLocation: {
        currentLocationCountryId: candidatesDiffs?.candidateLocation?.currentLocationCountry?.preselected || '',
        locationCountryId: candidatesDiffs?.candidateLocation?.locationCountry?.preselected || '',
        currentLocationCityId: candidatesDiffs?.candidateLocation?.currentLocationCity?.preselected || '',
        locationCityId: candidatesDiffs?.candidateLocation?.locationCity?.preselected || '',
      },
      relocationAvailability: candidatesDiffs?.relocationAvailability?.preselected,
      candidateRelocationCountries: candidatesDiffs?.candidateRelocationCountries?.preselected,
      candidateVisas: [
        {
          ...candidatesDiffs?.candidateVisas.preselected,
          visaTypeId: candidatesDiffs?.candidateVisas.preselected.visaType,
          ...(candidatesDiffs?.candidateVisas?.preselected?.validTo
            ? {
                validTo: new Date(candidatesDiffs?.candidateVisas?.preselected?.validTo),
              }
            : { validTo: null }),
        },
      ],
      visaAvailability: candidatesDiffs?.visaAvailability?.preselected,
      ...(candidatesDiffs?.responsibleEmployee?.preselected && {
        responsibleEmployee: candidatesDiffs?.responsibleEmployee?.preselected?.toString(),
      }),
      // TODO: update after package consolidation
      readyForBusinessTrip: candidatesDiffs?.readyForBusinessTrip?.preselected,
      sourceId: candidatesDiffs?.source?.preselected,
      sourceDetails: candidatesDiffs?.sourceDetails?.preselected,
      candidateWorkFormats: formats.map(({ dictionaryValueId }) => ({
        dictionaryValueId,
        checked: candidatesDiffs?.candidateWorkFormats?.preselected?.some((value) => value === dictionaryValueId),
      })),
      candidateWorkloads: loads.map(({ dictionaryValueId }) => ({
        dictionaryValueId,
        checked: candidatesDiffs?.candidateWorkloads?.preselected?.some((value) => value === dictionaryValueId),
      })),
      employmentForms: employmentForms.map(({ dictionaryValueId }) => ({
        dictionaryValueId,
        checked: candidatesDiffs?.employmentForms?.preselected?.some((value) => value === dictionaryValueId),
      })),

      candidateSalary: {
        expectedSalaryMin: candidatesDiffs?.candidateSalary?.expectedSalaryMin?.preselected || '',
        expectedSalaryMax: candidatesDiffs?.candidateSalary?.expectedSalaryMax?.preselected || '',
        currencyId: candidatesDiffs?.candidateSalary?.currency?.preselected,
        expectedSalaryComment: candidatesDiffs?.candidateSalary?.expectedSalaryComment?.preselected || '',
      },
      isBlocked: candidatesDiffs?.isBlocked?.preselected,
      isBlockedReason: candidatesDiffs?.isBlockedReason?.preselected,
      candidateSkills: candidatesDiffs?.candidateSkills?.preselected,
      candidateExperiences: candidatesDiffs?.candidateExperiences?.preselected?.map((experience) => ({
        ...(experience?.id && { id: Number(experience?.id) }),
        company: (experience?.company?.preselected || '') as string,
        position: (experience?.position?.preselected || '') as string,
        comment: (experience?.comment?.preselected || '') as string,
        itSphere: experience?.itSphere?.preselected as boolean,
        stillWorking: experience?.stillWorking?.preselected as boolean,
        ...(experience?.workedFrom?.preselected && {
          workedFrom: new Date(experience?.workedFrom?.preselected as unknown as string),
        }),
        ...(experience?.workedTo?.preselected && {
          workedTo: new Date(experience?.workedTo?.preselected as unknown as string),
        }),
      })),
      candidateEducations: candidatesDiffs?.candidateEducations?.preselected?.map((education) => ({
        ...(education?.id && { id: Number(education?.id) }),
        studyPlace: (education?.studyPlace?.preselected || '') as string,
        profession: (education?.profession?.preselected || '') as string,
        comment: (education?.comment?.preselected || '') as string,
        withoutPeriod: education?.withoutPeriod?.preselected as unknown as boolean,
        ...(education?.studiedFrom?.preselected && {
          studiedFrom: new Date(education?.studiedFrom?.preselected as unknown as string),
        }),
        ...(education?.studiedTo?.preselected && {
          studiedTo: new Date(education?.studiedTo?.preselected as unknown as string),
        }),
      })),
      candidateCourses: candidatesDiffs?.candidateCourses?.preselected?.map((course) => ({
        ...(course?.id && { id: Number(course?.id) }),
        studyPlace: (course?.studyPlace?.preselected || '') as string,
        courseName: (course?.courseName?.preselected || '') as string,
        comment: (course?.comment?.preselected || '') as string,
        ...(course?.studiedFrom?.preselected && {
          studiedFrom: new Date(course?.studiedFrom?.preselected as unknown as string),
        }),
        ...(course?.studiedTo?.preselected && {
          studiedTo: new Date(course?.studiedTo?.preselected as unknown as string),
        }),
      })),
    }));
    trigger();
  }, [candidatesDiffs]);
};

export const useCandidateConsolidation = () => {
  const dispatch = useAppDispatch();

  const candidateConsolidation = useAppSelector(getCandidatesConsolidation);

  const manageCandidateConsolidationFiles = useCallback(
    (...args: Parameters<typeof manageCandidateConsolidationFilesAction>) => {
      return dispatch(manageCandidateConsolidationFilesAction(...args));
    },
    [dispatch],
  );

  const deleteCandidateConsolidationLink = useCallback(
    (...args: Parameters<typeof deleteCandidateLinkAction>) => {
      return dispatch(deleteCandidateLinkAction(...args));
    },
    [dispatch],
  );

  const saveCandidatesDiff = useCallback(
    (args: Parameters<typeof saveCandidatesDiffAction>[0]) => {
      dispatch(saveCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const updateCandidatePrimitiveValues = useCallback(
    (args: Parameters<typeof updateCandidatePrimitiveValuesAction>[0]) => {
      dispatch(updateCandidatePrimitiveValuesAction(args));
    },
    [dispatch],
  );

  const updateCandidatesContactsDiff = useCallback(
    (args: Parameters<typeof updateCandidatesContactsDiffAction>[0]) => {
      dispatch(updateCandidatesContactsDiffAction(args));
    },
    [dispatch],
  );

  const addProfessionInToCandidatesDiff = useCallback(
    (args: Parameters<typeof addProfessionInToCandidatesDiffAction>[0]) => {
      dispatch(addProfessionInToCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const removeProfessionsIdsFromCandidatesDiff = useCallback(
    (args: Parameters<typeof removeProfessionsIdsFromCandidatesDiffAction>[0]) => {
      dispatch(removeProfessionsIdsFromCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const updateProfessionNameInCandidatesDiff = useCallback(
    (args: Parameters<typeof updateProfessionNameInCandidatesDiffAction>[0]) => {
      dispatch(updateProfessionNameInCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const updateProfessionGradeInCandidatesDiff = useCallback(
    (args: Parameters<typeof updateProfessionGradeInCandidatesDiffAction>[0]) => {
      dispatch(updateProfessionGradeInCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const addLanguageInToCandidatesDiff = useCallback(
    (args: Parameters<typeof addLanguageInToCandidatesDiffAction>[0]) => {
      dispatch(addLanguageInToCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const updateLanguageNameInCandidatesDiff = useCallback(
    (args: Parameters<typeof updateLanguageNameInCandidatesDiffAction>[0]) => {
      dispatch(updateLanguageNameInCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const updateLanguageLevelInCandidatesDiff = useCallback(
    (args: Parameters<typeof updateLanguageLevelInCandidatesDiffAction>[0]) => {
      dispatch(updateLanguageLevelInCandidatesDiffAction(args));
    },
    [dispatch],
  );

  const addORDeleteCandidateDiffMultiSelectElem = useCallback(
    (args: Parameters<typeof addORDeleteCandidateDiffMultiSelectElemAction>[0]) => {
      dispatch(addORDeleteCandidateDiffMultiSelectElemAction(args));
    },
    [dispatch],
  );

  const setCandidateDiffMultiSelectElem = useCallback(
    (args: Parameters<typeof setCandidateDiffMultiSelectElemAction>[0]) => {
      dispatch(setCandidateDiffMultiSelectElemAction(args));
    },
    [dispatch],
  );

  const updateLocalizationEntity = useCallback(
    (args: Parameters<typeof updateLocalizationEntityAction>[0]) => {
      dispatch(updateLocalizationEntityAction(args));
    },
    [dispatch],
  );

  const setLocalizationEntity = useCallback(
    (args: Parameters<typeof setLocalizationEntityAction>[0]) => {
      dispatch(setLocalizationEntityAction(args));
    },
    [dispatch],
  );

  const updateVisaEntity = useCallback(
    (args: Parameters<typeof updateVisaEntityAction>[0]) => {
      dispatch(updateVisaEntityAction(args));
    },
    [dispatch],
  );

  const removeProfessionsIdsFromVisa = useCallback(
    (args: Parameters<typeof removeProfessionsIdsFromVisaEntityAction>[0]) => {
      dispatch(removeProfessionsIdsFromVisaEntityAction(args));
    },
    [dispatch],
  );

  const setVisaEntity = useCallback(
    (args: Parameters<typeof setVisaEntityAction>[0]) => {
      dispatch(setVisaEntityAction(args));
    },
    [dispatch],
  );

  const resetCandidateRelocationCountries = useCallback(
    () => dispatch(resetCandidateRelocationCountriesAction()),
    [dispatch],
  );
  const resetCandidateVisas = useCallback(() => dispatch(resetCandidateVisasAction()), [dispatch]);

  const setWorkFormatsAndLoads = useCallback(
    (args: Parameters<typeof setWorkFormatsAndLoadsAction>[0]) => {
      dispatch(setWorkFormatsAndLoadsAction(args));
    },
    [dispatch],
  );

  const manageWorkFormatsLoadsEmployments = useCallback(
    (...args: Parameters<typeof manageWorkFormatsLoadsEmploymentsAction>) =>
      dispatch(manageWorkFormatsLoadsEmploymentsAction(...args)),
    [dispatch],
  );

  const updateCandidateSalary = useCallback(
    (args: Parameters<typeof updateCandidateSalaryAction>[0]) => {
      dispatch(updateCandidateSalaryAction(args));
    },
    [dispatch],
  );

  const updateCandidateSalaryField = useCallback(
    (args: Parameters<typeof updateCandidateSalaryFieldAction>[0]) => {
      dispatch(updateCandidateSalaryFieldAction(args));
    },
    [dispatch],
  );

  const manageCandidateMapData = useCallback(
    (args: Parameters<typeof manageCandidateMapDataAction>[0]) => {
      dispatch(manageCandidateMapDataAction(args));
    },
    [dispatch],
  );

  const updateEntityField = useCallback(
    (args: Parameters<typeof updateEntityFieldAction>[0]) => {
      dispatch(updateEntityFieldAction(args));
    },
    [dispatch],
  );

  const updateMapItemField = useCallback(
    (args: Parameters<typeof setCandidateMapDataAction>[0]) => {
      dispatch(setCandidateMapDataAction(args));
    },
    [dispatch],
  );

  const mergeCandidate = useCallback((args: Parameters<typeof mergeCandidateAction>[0]) => {
    const { source, blackListFields = [] } = args;
    dispatch(mergeCandidateAction({ source, blackListFields }));
  }, []);

  return {
    manageCandidateConsolidationFiles,
    deleteCandidateConsolidationLink,

    saveCandidatesDiff,
    updateCandidatePrimitiveValues,
    updateCandidatesContactsDiff,

    addProfessionInToCandidatesDiff,
    removeProfessionsIdsFromCandidatesDiff,
    updateProfessionNameInCandidatesDiff,
    updateProfessionGradeInCandidatesDiff,
    addLanguageInToCandidatesDiff,
    updateLanguageNameInCandidatesDiff,
    updateLanguageLevelInCandidatesDiff,
    addORDeleteCandidateDiffMultiSelectElem,
    setCandidateDiffMultiSelectElem,

    updateLocalizationEntity,
    setLocalizationEntity,
    updateVisaEntity,
    removeProfessionsIdsFromVisa,
    resetCandidateRelocationCountries,
    manageWorkFormatsLoadsEmployments,
    resetCandidateVisas,
    setVisaEntity,

    updateCandidateSalary,
    updateCandidateSalaryField,
    setWorkFormatsAndLoads,

    manageCandidateMapData,
    updateEntityField,

    updateMapItemField,

    mergeCandidate,

    candidateConsolidation,
  };
};

const useCandidatesRequestClaimState = (): CandidateClaimState => useAppSelector(getCandidatesRequestClaimState);

export const useCandidatesAPI = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isPrioritizeLoading,
    isNotPrioritizeLoading,
    items: candidates,
    ids: candidatesIds,
    totalElements,
    totalPages,
  } = useCandidateItemsState();
  const { ids: filesIds } = useCandidateFilesToAttachState();
  const deletedIds = useCandidateDeletedFilesIds();
  const { isLoading: isRequestClaimLoading, withRequestClaim } = useCandidatesRequestClaimState();

  const fetchRequestClaim = useCallback(
    (...args: Parameters<typeof fetchCandidateRequestClaimThunk>) => {
      dispatch(fetchCandidateRequestClaimThunk(...args));
    },
    [dispatch],
  );

  const clearRequestClaim = useCallback(() => {
    dispatch(clearCandidatesClaimAction());
  }, [dispatch]);

  const resetCandidatesState = useCallback(() => {
    dispatch(resetCandidatesStateAction());
  }, [dispatch]);

  const createCandidate = useCallback(
    (data: CandidateItem, successCallback?: (id: string) => void) => {
      return dispatch(createCandidateThunk({ data, successCallback }));
    },
    [dispatch, filesIds],
  );

  const attachDuplicatesIdsToCandidate = useCallback(
    (
      data: { candidateId: number | string; duplicateIds: (string | string)[] },
      successCallback?: (id: string) => void,
    ) => {
      dispatch(attachDuplicatesIdsToCandidateThunk({ ...data, successCallback }));
    },
    [dispatch, filesIds],
  );

  const checkCandidatesDuplicates = useCallback(
    (data: CandidateItem) => {
      return dispatch(checkCandidatesDuplicatesThunk(data)).unwrap();
    },
    [dispatch],
  );

  const findExistingCandidateDuplicates = useCallback(
    (args: Parameters<typeof findExistingCandidateDuplicatesThunk>['0']) => {
      return dispatch(findExistingCandidateDuplicatesThunk(args)).unwrap();
    },
    [dispatch],
  );

  const updateCandidate = useCallback(
    (args: Parameters<typeof updateCandidateThunk>[0]) => {
      return dispatch(updateCandidateThunk(args));
    },
    [dispatch, filesIds, deletedIds],
  );

  const deleteCandidateById = useCallback(
    (id: number, isSoftDelete: boolean, successCallback?: () => void) => {
      return dispatch(deleteCandidateByIdThunk({ id, isSoftDelete, successCallback }));
    },
    [dispatch],
  );

  const fetchAllCandidates = useCallback(
    (args: Parameters<typeof getAllCandidatesThunk>[0]) => {
      return ThunkEffects.takeLatest(dispatch(getAllCandidatesThunk(args)), getAllCandidatesThunk.typePrefix);
    },
    [dispatch],
  );

  const fetchPrioritizeCandidates = useCallback(
    (args: Parameters<typeof getPrioritizeCandidatesThunk>[0]) => {
      return ThunkEffects.takeLatest(
        dispatch(getPrioritizeCandidatesThunk(args)),
        getPrioritizeCandidatesThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const getPrioritizeCandidatesByVacancyId = useCallback(
    (...args: Parameters<typeof getPrioritizeCandidatesByVacancyIdThunk>) => {
      return ThunkEffects.takeLatest(
        dispatch(getPrioritizeCandidatesByVacancyIdThunk(...args)),
        getPrioritizeCandidatesByVacancyIdThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const fetchCandidateById = useCallback(
    (id: number) => {
      return dispatch(getCandidateByIdThunk(id)).unwrap();
    },
    [dispatch],
  );

  const restoreCandidate = useCallback(
    (ids: number[]) => {
      return dispatch(restoreCandidateByIdThunk({ ids }));
    },
    [dispatch],
  );

  const markDuplicate = useCallback(
    (id: number) => {
      dispatch(toggleCandidateDupleAction(id));
    },
    [dispatch],
  );

  const setCurrentCandidate = useCallback(
    (...args: Parameters<typeof setCurrentCandidateAction>) => dispatch(setCurrentCandidateAction(...args)),
    [dispatch],
  );

  const clearCandidates = useCallback(() => {
    return dispatch(clearCandidatesAction());
  }, [dispatch]);

  return {
    resetCandidatesState,
    clearCandidates,
    isLoading,
    candidates,
    candidatesIds,
    restoreCandidate,
    createCandidate,
    attachDuplicatesIdsToCandidate,
    updateCandidate,
    fetchAllCandidates,
    fetchCandidateById,
    deleteCandidateById,
    markDuplicate,
    checkCandidatesDuplicates,
    findExistingCandidateDuplicates,
    getPrioritizeCandidatesByVacancyId,
    setCurrentCandidate,
    fetchPrioritizeCandidates,
    fetchRequestClaim,
    clearRequestClaim,
    isRequestClaimLoading,
    withRequestClaim,
    totalElements,
    totalPages,
    isPrioritizeLoading,
    isNotPrioritizeLoading,
  };
};

export const useCandidateFilesToAttachState = (): CandidateFilesIdsState =>
  useAppSelector(getCandidatesFilesToAttachState);
export const useCandidateDeletedFilesIds = (): number[] => useAppSelector(getCandidateDeletedFilesIds);
export const useCandidateFilesState = (): CandidateFilesState => useAppSelector(getCandidatesFilesState);
export const useCurrentCandidateAvatar = (): FileIdResponse | null => useAppSelector(getCurrentCandidateAvatar);

export const useCandidateFilesAPI = () => {
  const dispatch = useAppDispatch();
  const avatar = useCurrentCandidateAvatar();
  const { isLoading: isFilesIdsLoading, ids: filesIds, items: files } = useCandidateFilesToAttachState();
  const {
    isLoading: isFilesLoading,
    ids: filesDataIds,
    items: filesData,
    currentFile,
    isCurrentFileLoading,
  } = useCandidateFilesState();

  const createAvatarPhoto = useCallback(
    (file: File, successCallback?: () => void) => {
      dispatch(createCandidateAvatarPhotoThunk({ file, successCallback }));
    },
    [dispatch],
  );

  const clearCandidateAvatar = useCallback(() => {
    dispatch(clearCandidateAvatarAction());
  }, [dispatch]);

  const createFileId = useCallback(
    (file: File, isProtected: boolean, successCallback?: (val?: FileIdResponse) => void, isStatusFile?: boolean) => {
      return dispatch(createCandidateFileIdThunk({ file, isProtected, successCallback, isStatusFile })).unwrap();
    },
    [dispatch],
  );

  const deleteFileId = useCallback(
    (attachmentId: number) => {
      dispatch(deleteCandidateFileIdThunk({ attachmentId }));
    },
    [dispatch],
  );

  const fetchFilesIds = useCallback(
    (candidateId: number, candidateLifecycleStatus: CandidateLifecycleStatuses) => {
      return dispatch(getCandidateFilesIdsThunk({ candidateId, candidateLifecycleStatus })).unwrap();
    },
    [dispatch],
  );

  const linkCandidateFile = useCallback(
    (fileId: number, currentCandidate: LocalizedCandidateItemResponse) => {
      dispatch(linkCandidateFileThunk({ fileId, currentCandidate }));
    },
    [dispatch],
  );

  const readFileAttachment = useCallback(
    (attachmentId: number, fileName: string, closeCallback?: () => void) => {
      return dispatch(getCandidateFileDataThunk({ attachmentId, fileName, closeCallback }));
    },
    [dispatch],
  );

  const clearFileAttachment = useCallback(() => {
    dispatch(clearCandidateFileAttachmentAction());
  }, [dispatch]);

  const clearFilesIds = useCallback(() => {
    dispatch(clearCandidateFilesIdsAction());
  }, [dispatch]);

  return {
    createFileId,
    deleteFileId,
    fetchFilesIds,
    clearFilesIds,
    linkCandidateFile,
    isFilesIdsLoading,
    isCurrentFileLoading,
    filesIds,
    files,
    isFilesLoading,
    filesDataIds,
    filesData,
    readFileAttachment,
    clearFileAttachment,
    currentFile,
    createAvatarPhoto,
    clearCandidateAvatar,
    avatar,
  };
};

export const useParsedPdfCandidateInfo = () => {
  const dispatch = useAppDispatch();

  const fetchParsedInfo = useCallback(
    (file: File, successCallback: (data: CandidateItem, fullResume: string) => void, errorCallback: () => void) => {
      dispatch(getCandidatePdfParsedInfoThunk({ file, successCallback, errorCallback }));
    },
    [dispatch],
  );

  return {
    fetchParsedInfo,
  };
};

export const useParsedCandidateInfo = (reset: UseFormReset<CandidateItem>) => {
  const { createFileId } = useCandidateFilesAPI();
  const uploadFileToStorage = async (base64: string) => {
    try {
      const res = await fetch(base64);
      const blob = await res.blob();
      const file = new File([blob], 'avatar.png', { type: 'image/png' });
      const { id: photoId } = await createFileId(file, false);
      reset((prev) => ({
        ...prev,
        photoId,
      }));
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const userParsedData = SessionStorageService.getItem<GrabbedCandidate>(StorageKeys.GrabbedUserData);
    if (userParsedData) {
      if (userParsedData.avatarBlobData) {
        uploadFileToStorage(userParsedData?.avatarBlobData);
      }
      reset((prev) => ({
        ...prev,
        firstNameRu: userParsedData?.contactInfo?.firstNameRu || '',
        lastNameRu: userParsedData?.contactInfo?.lastNameRu || '',
        firstNameEn: userParsedData?.contactInfo?.firstNameEn || '',
        lastNameEn: userParsedData?.contactInfo?.lastNameEn || '',
        sourceId: userParsedData?.contactInfo?.candidateDataSource,
        ...(userParsedData?.contactInfo?.birthDate && { birthDate: new Date(userParsedData?.contactInfo?.birthDate) }),
        candidateContacts: [
          {
            contact: { value: userParsedData?.contactInfo?.telegramProfileLink || '' },
            contactType: ContactsType.TELEGRAM,
          },
          {
            contact: { value: userParsedData?.contactInfo?.linkedInProfileLink || '' },
            contactType: ContactsType.LINKEDIN,
          },
          {
            contact: { value: userParsedData?.contactInfo?.gitHubProfileLink || '' },
            contactType: ContactsType.GITHUB,
          },
          {
            contact: { value: userParsedData?.contactInfo?.phone?.replace('+', '') || '' },
            contactType: ContactsType.PHONE,
          },
          { contact: { value: '' }, contactType: ContactsType.ADDITIONAL_PHONE },
          {
            contact: { value: '' },
            contactType: ContactsType.BEHANCE,
          },
          {
            contact: { value: '' },
            contactType: ContactsType.SKYPE,
          },
        ],
        email: userParsedData?.contactInfo?.emailProfileLink || '',
        candidateSkills: userParsedData?.contactInfo?.personalAboutCandidateInfo || '',
        ...(userParsedData?.experience?.length &&
          userParsedData?.experience?.reduce(
            (acc, val): Pick<CandidateItem, 'candidateExperiences'> =>
              val
                ? {
                    ...acc,
                    candidateExperiences: [
                      ...acc.candidateExperiences,
                      {
                        itSphere: false,
                        company: val.company || '',
                        position: val.position || '',
                        comment: val.additionalInfo || '',
                        ...(val.date?.startDate && { workedFrom: new Date(val.date.startDate) }),
                        ...(val.date?.endDate ? { workedTo: new Date(val.date.endDate) } : { stillWorking: true }),
                        stillWorking: !val.date.endDate,
                      },
                    ],
                  }
                : acc,
            { candidateExperiences: [] },
          )),
        ...(userParsedData?.education?.length &&
          userParsedData?.education?.reduce(
            (acc, val): Pick<CandidateItem, 'candidateEducations'> =>
              val
                ? {
                    ...acc,
                    candidateEducations: [
                      ...acc.candidateEducations,
                      {
                        withoutPeriod: false,
                        studyPlace: val.title,
                        profession: val.subTitle,
                        ...(val.date?.yearOfAdmission && {
                          studiedFrom: new Date(`${val.date.yearOfAdmission}-01`),
                        }),
                        ...(val.date?.graduationYear && {
                          studiedTo: new Date(`${val.date.graduationYear}-01`),
                        }),
                      },
                    ],
                  }
                : acc,
            { candidateEducations: [] },
          )),
        ...(userParsedData?.coursesOrCertificates?.length &&
          userParsedData?.coursesOrCertificates?.reduce(
            (acc, val): Pick<CandidateItem, 'candidateCourses'> =>
              val
                ? {
                    ...acc,
                    candidateCourses: [
                      ...acc.candidateCourses,
                      {
                        studyPlace: val.title,
                        courseName: val.subTitle,
                        ...(val.date?.yearOfAdmission && {
                          studiedFrom: new Date(`${val.date.yearOfAdmission}-01`),
                        }),
                        ...(val.date?.graduationYear && {
                          studiedTo: new Date(`${val.date.graduationYear}-01`),
                        }),
                      },
                    ],
                  }
                : acc,
            { candidateCourses: [] },
          )),
      }));
    }

    return () => SessionStorageService.removeItem(StorageKeys.GrabbedUserData);
  }, []);
  return;
};

export const useCandidatesEventsState = (): LocalizedCandidateEventsState => useAppSelector(getCandidatesEventsState);

export const useCandidateEventsAPI = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    totalPages,
    ids: eventsIds,
    items: events,
    deletedIds,
    deletedThreadIds,
    isStatusAlone,
  } = useCandidatesEventsState();

  const getConfigFlags = useCallback((statusConfig: CandidateStatusListItem) => {
    const flags = Object.entries(StatusConfigFlags).reduce<{ [key: string]: boolean }>((accum, [key, value]) => {
      if (statusConfig.formItems.find((item) => item.item === value)) {
        accum[key] = true;
      }
      return accum;
    }, {});
    return flags;
  }, []);

  const fetchCandidateEvents = useCallback(
    (candidateId: number, page: number, candidateLifecycleStatus: CandidateLifecycleStatuses) => {
      return ThunkEffects.takeLatest(
        dispatch(getCandidateEventsThunk({ candidateId, page, candidateLifecycleStatus })),
        getCandidateEventsThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const clearCandidateEvents = useCallback(() => {
    dispatch(clearCandidateEventsAction());
  }, [dispatch]);

  const createComment = useCallback(
    (data: CandidateCommentItem, successCallback?: () => void) => {
      return dispatch(createCandidateCommentThunk({ data, successCallback }));
    },
    [dispatch],
  );

  const createThreadComment = useCallback(
    (eventId: number, data: CandidateCommentItem, successCallback?: () => void) => {
      dispatch(createCandidateThreadCommentThunk({ eventId, data, successCallback }));
    },
    [dispatch],
  );

  const deleteComment = useCallback(
    (commentId: number, parentId?: number, isLoading?: boolean) => {
      return dispatch(deleteCommentAction({ commentId, parentId, isLoading }));
    },
    [dispatch],
  );

  const restoreComment = useCallback(
    (commentId: number, parentId?: number) => {
      return dispatch(restoreCommentAction({ commentId, parentId }));
    },
    [dispatch],
  );

  const restoreAdminComment = useCallback(
    (eventId: number, parentId?: number) => {
      return dispatch(restoreAdminEventThunk({ eventId, parentId }));
    },
    [dispatch],
  );

  const updateComment = useCallback(
    (eventId: number, data: CandidateCommentItem, successCallback: () => void) => {
      if (data.parentId) return dispatch(updateCandidateThreadCommentThunk({ eventId, data, successCallback }));
      return dispatch(updateCandidateCommentThunk({ eventId, data, successCallback }));
    },
    [dispatch],
  );

  const fetchDeleteComments = useCallback(
    (candidateId: number, successCallback?: () => void) => {
      return dispatch(deleteCandidateCommentsThunk({ candidateId, successCallback }));
    },
    [dispatch],
  );

  return {
    fetchCandidateEvents,
    clearCandidateEvents,
    createComment,
    deleteComment,
    restoreComment,
    restoreAdminComment,
    updateComment,
    createThreadComment,
    fetchDeleteComments,
    isLoading,
    totalPages,
    eventsIds,
    events,
    deletedIds,
    deletedThreadIds,
    isStatusAlone,
    getConfigFlags,
  };
};

const useCandidatesHistoryState = (role: RolesValues): LocalizedCandidateHistoryState =>
  useAppSelector(getCandidatesHistoryState(role));

export const useCandidateHistoryAPI = () => {
  const dispatch = useAppDispatch();
  const { item: profile } = useProfileState();
  const { isLoading, ids: historyIds, items: historyItems, totalPages } = useCandidatesHistoryState(profile.role.name);

  const fetchChangesHistory = useCallback(
    (candidateId: number, page: number) => {
      dispatch(getCandidateChangeHistoryThunk({ candidateId, page }));
    },
    [dispatch],
  );

  const clearCandidateHistory = useCallback(() => {
    dispatch(clearCandidateHistoryAction());
  }, [dispatch]);

  return { fetchChangesHistory, clearCandidateHistory, isLoading, historyItems, historyIds, totalPages };
};

export const useCandidatesReportState = () => useAppSelector(getCandidatesReportState);

export const useCandidateReportAPI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, items: reportItems, pagable } = useCandidatesReportState();

  const setPagable = useCallback(
    (args: Parameters<typeof setPagableAction>[0]) => dispatch(setPagableAction(args)),
    [dispatch],
  );

  const fetchDuplicatesReport = useCallback(
    (params: Parameters<typeof duplicatesReportThunk>[0]) => {
      dispatch(duplicatesReportThunk(params));
    },
    [dispatch],
  );
  const fetchCandidatesPairById = useCallback(
    (args: Parameters<typeof fetchCandidatesPairByIdThunk>[0]) => {
      return dispatch(fetchCandidatesPairByIdThunk(args)).unwrap();
    },
    [dispatch],
  );

  const resolveCandidateDuplicates = useCallback(
    (args: Parameters<typeof resolveCandidateDuplicatesThunk>[0]) => {
      dispatch(resolveCandidateDuplicatesThunk(args));
    },
    [dispatch],
  );

  const mergeCandidateDuplicates = useCallback(
    (args: Parameters<typeof mergeCandidateDuplicatesThunk>[0]) => {
      return dispatch(mergeCandidateDuplicatesThunk(args));
    },
    [dispatch],
  );

  const clearDuplicatesReport = useCallback(() => {
    dispatch(clearDuplicatesReportAction());
  }, [dispatch]);

  return {
    isLoading,
    reportItems,
    pagable,
    setPagable,
    fetchDuplicatesReport,
    clearDuplicatesReport,
    resolveCandidateDuplicates,
    fetchCandidatesPairById,
    mergeCandidateDuplicates,
  };
};
export const useCandidateHistoryCommentState = (historyId: number): LocalizedHistoryCommentsState =>
  useAppSelector(getHistoryCommentsState(historyId));

export const useCandidateHistoryCommentsAPI = (historyId: number) => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    ids: historyCommentIds,
    items: historyComments,
    deletedIds,
  } = useCandidateHistoryCommentState(historyId);

  const restoreAdminComment = useCallback(
    (commentId: number) => {
      return dispatch(restoreAdminChangeCommentThunk({ commentId, parentId: historyId }));
    },
    [dispatch],
  );

  const createHistoryComment = useCallback(
    (data: HistoryCommentItem, successCallback?: () => void) => {
      dispatch(createHistoryCommentThunk({ data, successCallback }));
    },
    [dispatch],
  );
  const updateHistoryComment = useCallback(
    (data: HistoryCommentItem, successCallback?: () => void) => {
      dispatch(updateHistoryCommentThunk({ data, successCallback }));
    },
    [dispatch],
  );

  const deleteComment = useCallback(
    (commentId: number, changeStoryGroupId: number) => {
      dispatch(deleteHistoryCommentAction({ changeStoryGroupId, commentId }));
    },
    [dispatch],
  );

  const restoreComment = useCallback(
    (commentId: number, changeStoryGroupId: number) => {
      dispatch(restoreHistoryCommentAction({ changeStoryGroupId, commentId }));
    },
    [dispatch],
  );

  const fetchDeleteHistoryComments = useCallback(
    (successCallback?: () => void) => {
      dispatch(deleteHistoryCommentsThunk({ successCallback }));
    },
    [dispatch],
  );

  const fetchHistoryCommentData = useCallback(
    (changeStoryGroupId: number) => {
      dispatch(getHistoryCommentThunk({ changeStoryGroupId }));
    },
    [dispatch],
  );

  return {
    isLoading,
    historyComments,
    historyCommentIds,
    deletedIds,
    restoreAdminComment,
    createHistoryComment,
    updateHistoryComment,
    restoreComment,
    deleteComment,
    fetchDeleteHistoryComments,
    fetchHistoryCommentData,
  };
};

export const useRecruiterCandidatesState = (): RecruiterModeState => useAppSelector(getRecruiterCandidatesState);

export const useRecruiterCandidatesAPI = () => {
  const dispatch = useAppDispatch();
  const { isExportLoading } = useCandidateItemsState();
  const {
    isActive,
    vacancyId: recruiterVacancyId,
    vacancyName: recruiterVacancyName,
    requestId: recruiterRequestId,
  } = useRecruiterCandidatesState();
  const { selected: selectedCandidates } = useFiltersData();

  const exportCandidates = useCallback(
    (args: Parameters<typeof candidatesExportThunk>[0]) => {
      dispatch(candidatesExportThunk(args));
    },
    [dispatch],
  );

  const setRecruiterModeConfig = useCallback(
    (vacancyId: number, vacancyName: string, requestId?: number) => {
      dispatch(setRecruiterModeConfigAction({ vacancyId, vacancyName, requestId }));
    },
    [dispatch],
  );

  const fetchRecruiterCandidates = useCallback(
    (data: RecruiterModeItem) => {
      return dispatch(prioritizeCandidatesThunk({ selectedCandidates, data }));
    },
    [dispatch, selectedCandidates],
  );

  const updateVacancyCandidatePriority = useCallback(
    (vacancyId, candidateId: number, newBoostedValue: boolean) => {
      return dispatch(updateVacancyCandidatePriorityThunk({ vacancyId, candidateId, newBoostedValue }));
    },
    [dispatch],
  );

  const clearRecruiterCandidate = useCallback(() => {
    dispatch(clearRecruiterCandidateAction());
  }, [dispatch]);

  return {
    clearRecruiterCandidate,
    setRecruiterModeConfig,
    fetchRecruiterCandidates,
    isActive,
    recruiterVacancyId,
    recruiterVacancyName,
    recruiterRequestId,
    exportCandidates,
    isExportLoading,
    updateVacancyCandidatePriority,
  };
};

export const useCandidatesAllStatusesState = (): CandidateAllStatusesState =>
  useAppSelector(getCandidatesAllStatusesState);

export const useAllStatusesOptions = (): { statusOptions: SelectOption[]; actualStatusOptions: SelectOption[] } =>
  useAppSelector(getStatusesOptions);
export const useCandidateStatusesOptionsWithNestedStatuses = () =>
  useAppSelector(getCandidateStatusesOptionsWithNestedStatuses);
export const useCurrentStatus = (): LocalizedCurrentCandidateStatusState => useAppSelector(getCurrentStatusState);
export const useCurrentStatusInfo = () => useAppSelector(getCurrentStatusInfo);
export const useActualStatus = (): LocalizedStatusItemResponse => useAppSelector(getActualCandidateStatus);

export const useStatusConfigById = (statusId: string): CandidateStatusListItem =>
  useAppSelector(getStatusConfigById(statusId));

export const useCandidateStatusAPI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, ids, items } = useCandidatesAllStatusesState();

  const saveStatusesOrders = useCallback(
    (ordersIds: string[]) => {
      return dispatch(saveStatusesOrdersThunk({ ordersIds }));
    },
    [dispatch],
  );

  const resolveClaim = useCallback(
    (eventId: number, actionType: ClaimActionTypes, claimId: number, file?: FileIdResponse, candidateId?: number) => {
      return dispatch(resolveStatusClaimThunk({ eventId, actionType, claimId, file, candidateId }));
    },
    [dispatch],
  );

  const createStatus = useCallback(
    (data: StatusItem) => {
      return dispatch(createCandidateStatusThunk(data));
    },
    [dispatch],
  );

  const updateStatus = useCallback(
    (data: StatusItem) => {
      return dispatch(updateCandidateStatusThunk(data));
    },
    [dispatch],
  );

  const setCurrentStatusItem = useCallback(
    (eventId: number) => {
      dispatch(setCurrentStatusItemAction({ eventId }));
    },
    [dispatch],
  );

  const getActualStatus = useCallback(
    (candidateId: number) => {
      return dispatch(getCandidateActualStatusThunk({ candidateId }));
    },
    [dispatch],
  );

  const clearActualStatus = useCallback(() => {
    dispatch(clearActualStatusAction());
  }, [dispatch]);

  const getStatusList = useCallback(() => {
    return dispatch(getStatusListThunk());
  }, [dispatch]);

  const clearStatusList = useCallback(() => {
    dispatch(clearStatusListAction());
  }, [dispatch]);

  const clearCurrentStatus = useCallback(() => {
    dispatch(clearCurrentStatusAction());
  }, [dispatch]);

  return {
    isLoading,
    ids,
    items,
    setCurrentStatusItem,
    updateStatus,
    createStatus,
    saveStatusesOrders,
    clearCurrentStatus,
    getStatusList,
    clearStatusList,
    resolveClaim,
    getActualStatus,
    clearActualStatus,
  };
};

const useCandidatesOffersState = (): CandidateOffersState => useAppSelector(getCandidatesOffersState);
export const useCandidatesOffersFormData = (id: string): CandidateOfferItem | null =>
  useAppSelector(getCandidatesOffersFormData(id));

export const useCandidateOffersAPI = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useCandidatesOffersState();

  const offersTypesOptions = useMemo(
    () => [
      { title: t('pages.candidates.offer.offer'), value: OffersType.Offer },
      { title: t('pages.candidates.offer.preOffer'), value: OffersType.PreOffer },
    ],
    [t],
  );

  const deleteOffer = useCallback(
    (id: number) => {
      return dispatch(deleteCandidateOfferThunk({ id }));
    },
    [dispatch],
  );

  const restoreOffer = useCallback(
    (id: number) => {
      return dispatch(restoreCandidateOfferThunk({ id }));
    },
    [dispatch],
  );

  const createOffer = useCallback(
    (data: CandidateOfferItem) => {
      return dispatch(createCandidateOfferThunk({ data }));
    },
    [dispatch],
  );

  const updateOffer = useCallback(
    (data: CandidateOfferItem) => {
      return dispatch(updateCandidateOfferThunk({ data }));
    },
    [dispatch],
  );

  const getOffersByCandidateId = useCallback(
    (candidateId: number) => {
      return dispatch(getOffersByCandidateIdThunk({ candidateId }));
    },
    [dispatch],
  );

  const clearCandidatesOffers = useCallback(() => {
    return dispatch(clearCandidatesOffersAction());
  }, [dispatch]);

  return {
    ...state,
    offersTypesOptions,
    restoreOffer,
    deleteOffer,
    createOffer,
    updateOffer,
    getOffersByCandidateId,
    clearCandidatesOffers,
  };
};
