import { styled, Icon, Button as BaseButton } from '@innowise-group/mui-kit';

export const Wrapper = styled.div``;

export const ArrowIcon = styled(Icon).attrs({
  type: 'u_angle-down',
})<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
  padding: 2px;
`;

export const ButtonText = styled.span`
  flex: 1;
  text-align: left;
  margin-left: 2px;
`;

export const Button = styled(BaseButton)`
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiButton-root.MuiButton-contained {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
