import { FC } from 'react';
import { DiffBaseProps, LabelTransformer, DiffBase } from '../diff-base';
import { useTranslation } from 'react-i18next';

const GenderDiff: FC<Omit<DiffBaseProps, 'labelTransformer' | 'fieldName'>> = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'gender'> = ({ data }) =>
    data ? t(`options.genderOptions_${data}`) : t('buttons.leaveBlank');
  return <DiffBase fieldName="gender" labelTransformer={labelTransformer} {...props} />;
};

export default GenderDiff;
