import React from 'react';
import { DateFormats } from '@constants';
import MaskedTextInput from 'react-text-mask';
import DatePicker, { ReactDatePicker, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import * as Styled from './custom-date-picker.styles';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DateWithoutDaysHeader } from './components/date-without-days-header';
import { FullDateHeader } from './components/full-date-header';
import { SelectOption, useClickOutside } from '@innowise-group/core';
import { DateWithTimeHeader } from './components/date-with-time-header';

registerLocale('ru', ru);

export interface CustomDatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  onChange?: (value: Date) => void;
  dateFormat?: string;
  placeholderText?: string;
  withoutDays?: boolean;
  withTime?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  minYear?: number;
  maxYear?: number;
  customInput?: React.ReactNode;
}
interface DatePickerContainerProps {
  ref: React.Ref<HTMLDivElement>;
  onClick?: () => void;
  withoutDays?: boolean;
  withTime?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  isCustomInput: boolean;
  withPortal?: boolean;
}

const DatePickerContainer: React.ForwardRefExoticComponent<DatePickerContainerProps> = React.forwardRef<
  HTMLDivElement,
  DatePickerContainerProps
>(({ onClick, withoutDays, withTime, children, disabled, error, ...props }, ref) => {
  return withTime ? (
    <Styled.ContainerTime {...props} disabled={disabled} error={error} ref={ref} onClick={onClick}>
      {children}
    </Styled.ContainerTime>
  ) : withoutDays ? (
    <Styled.ContainerWithoutDays {...props} disabled={disabled} error={error} ref={ref} onClick={onClick}>
      {children}
    </Styled.ContainerWithoutDays>
  ) : (
    <Styled.ContainerFullDate {...props} disabled={disabled} error={error} ref={ref} onClick={onClick}>
      {children}
    </Styled.ContainerFullDate>
  );
});

const CustomDatePicker: React.FC<CustomDatePickerProps> = React.forwardRef(
  (
    { onChange, selected, withoutDays, withTime, customInput, dateFormat, error, value, minYear, maxYear, ...props },
    ref,
  ) => {
    const datePickerRef = useRef<ReactDatePicker | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openSelect = useCallback(() => {
      if (!isOpen) {
        setIsOpen(true);
        datePickerRef.current?.setOpen(true);
        datePickerRef.current?.setBlur();
      }
    }, []);

    const closeSelect = useCallback(() => {
      setIsOpen(false);
      datePickerRef.current?.setOpen(false);
    }, []);

    const containerRef = useClickOutside<HTMLDivElement>(closeSelect, isOpen);

    const { yearsOptions, startYear, endYear } = useMemo(() => {
      const yearsOptions: SelectOption[] = [];
      const startYear = minYear || new Date().getFullYear() - 100;
      const endYear = maxYear || new Date().getFullYear() + 50;

      for (let i = startYear; i <= endYear; i++) {
        yearsOptions.push({ value: i.toString(), title: i.toString() });
      }

      return { yearsOptions, startYear, endYear };
    }, [minYear, maxYear]);

    const handleClickOutside = () => {
      datePickerRef.current?.setOpen(true);
    };

    const handleOnSelect = (date: Date, event?: React.SyntheticEvent) => {
      event?.stopPropagation();
      closeSelect();
    };

    const handleOnChange = (date: Date) => {
      if (withTime && !withoutDays) {
        const newDate = value ? new Date(value) : new Date();
        newDate.setHours(date.getHours());
        newDate.setMinutes(date.getMinutes());
        newDate.setSeconds(date.getSeconds());
        onChange(newDate);
        return closeSelect();
      }
      onChange(date);
      closeSelect();
    };

    return (
      <DatePickerContainer
        {...props}
        isCustomInput={!!customInput}
        withoutDays={withoutDays}
        withTime={withTime}
        disabled={props.disabled}
        error={error}
        ref={containerRef}
        onClick={!props.disabled && !isOpen ? openSelect : null}
      >
        <DatePicker
          {...props}
          value={value}
          selected={withTime && !withoutDays ? (value ? new Date(value) : null) : selected}
          ref={datePickerRef}
          onChange={handleOnChange}
          open={isOpen}
          onSelect={handleOnSelect}
          showMonthYearPicker={withoutDays && !withTime}
          showFourColumnMonthYearPicker={withoutDays && !withTime}
          showTimeSelect={withTime}
          showTimeSelectOnly={withTime && withoutDays}
          timeIntervals={15}
          timeCaption=""
          locale="ru"
          onClickOutside={handleClickOutside}
          dateFormat={
            dateFormat || (withTime && withoutDays)
              ? DateFormats.OnlyTime
              : withoutDays
              ? DateFormats.MonthAndYearSlash
              : DateFormats.DaySlashFirst
          }
          customInput={
            customInput || (
              <MaskedTextInput
                type="text"
                ref={ref}
                mask={
                  withTime && withoutDays
                    ? [/\d/, /\d/, ':', /\d/, /\d/]
                    : withoutDays
                    ? [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                    : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                }
              />
            )
          }
          renderCustomHeader={({ date, changeMonth, changeYear }) => {
            const decreaseYear = () => {
              if (date.getFullYear() > startYear) {
                if (props.minDate) {
                  const newDate = new Date(date);
                  newDate.setFullYear(date.getFullYear() - 1);
                  changeYear(
                    newDate.getTime() > props.minDate.getTime() ? date.getFullYear() - 1 : props.minDate.getFullYear(),
                  );
                  onChange(newDate.getTime() > props.minDate.getTime() ? newDate : props.minDate);
                } else {
                  changeYear(date.getFullYear() - 1);
                  const newDate = new Date(new Date(date).setFullYear(date.getFullYear() - 1));
                  if (withoutDays) {
                    newDate.setDate(1);
                    newDate.setHours(0);
                    newDate.setMinutes(0);
                    newDate.setSeconds(0);
                  }
                  onChange(newDate);
                }
              }
            };
            const increaseYear = () => {
              if (date.getFullYear() < endYear) {
                if (props.maxDate) {
                  const newDate = new Date(date);
                  newDate.setFullYear(date.getFullYear() + 1);
                  changeYear(
                    newDate.getTime() < props.maxDate.getTime() ? date.getFullYear() + 1 : props.maxDate.getFullYear(),
                  );
                  onChange(newDate.getTime() < props.maxDate.getTime() ? newDate : props.maxDate);
                } else {
                  changeYear(date.getFullYear() + 1);
                  const newDate = new Date(new Date(date).setFullYear(date.getFullYear() + 1));
                  if (withoutDays) {
                    newDate.setDate(1);
                    newDate.setHours(0);
                    newDate.setMinutes(0);
                    newDate.setSeconds(0);
                  }
                  onChange(newDate);
                }
              }
            };
            return withoutDays && withTime ? null : withoutDays ? (
              <DateWithoutDaysHeader
                date={date}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
                startYear={startYear}
                endYear={endYear}
              />
            ) : withTime ? (
              <DateWithTimeHeader
                date={value ? new Date(value) : new Date()}
                startYear={startYear}
                endYear={endYear}
                yearsOptions={yearsOptions}
                onChange={onChange}
                startDate={props.startDate}
              />
            ) : (
              <FullDateHeader
                date={date}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
                startYear={startYear}
                endYear={endYear}
                yearsOptions={yearsOptions}
                onChange={onChange}
                changeMonth={changeMonth}
                changeYear={changeYear}
                minDate={props.minDate}
                maxDate={props.maxDate}
              />
            );
          }}
        />
        {!props.inline && !customInput && (
          <Styled.CalendarIcon type={withTime && withoutDays ? 'u_time' : 'u_calendar'} size={22} />
        )}
      </DatePickerContainer>
    );
  },
);

export default CustomDatePicker;
