import { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';

export default class FaqService {
  static async updateFaq(content: string): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/faq', content, {
      headers: {
        'Content-Type': 'text/plain',
      },
      responseType: 'text',
    });
  }

  static async getFaq(): Promise<AxiosResponse<string>> {
    return $api.get<string>('/recruiting-tool/api/v1/faq');
  }
}
