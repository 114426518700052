import { useAppDispatch, useAppSelector } from '@innowise-group/core';
import { useCallback } from 'react';
import { getFaqState } from './faq.selectors';
import { clearFaqAction, updateContentAction } from './faq.action';
import { updateFaqThunk, getFaqThunk } from './faq.thunk';

export const useFaqAPI = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(getFaqState);

  const clearFaq = useCallback(() => dispatch(clearFaqAction()), [dispatch]);

  const updateUnsavedContent = useCallback(
    (...args: Parameters<typeof updateContentAction>) => dispatch(updateContentAction(...args)),
    [dispatch],
  );

  const getFaq = useCallback(() => dispatch(getFaqThunk()), [dispatch]);

  const updateFaq = useCallback(
    (...args: Parameters<typeof updateFaqThunk>) => dispatch(updateFaqThunk(...args)),
    [dispatch],
  );

  return {
    ...state,
    getFaq,
    clearFaq,
    updateFaq,
    updateUnsavedContent,
  };
};
