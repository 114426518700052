import { FC, useState, MouseEvent } from 'react';
import { AuthService, useProfileAPI, useResizeObserver } from '@innowise-group/core';
import { AvatarMenu } from '../../../avatar-menu';
import { Icon, Menu, MenuItem } from '@innowise-group/mui-kit';
import * as Styled from './profile-menu.styles';
import { useTranslation } from 'react-i18next';
import { languages } from './profile.menu.data';
import { getLanguageFromLS, setLanguageToLC } from '@innowise-group/utilities';
import { AppLanguages, AppRoutes, ManagerRolesArray, RolesValues, SupportLink } from '@constants';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@innowise-group/mui-kit';
import { RoleGuard } from '@shared-components';
import { EventsProgress } from '../events-progress';

interface ProfileMenuProps {
  showVacancyModal: () => void;
  showRequestModal: () => void;
}

const ProfileMenu: FC<ProfileMenuProps> = ({ showVacancyModal, showRequestModal }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedLanguageAnchorEl, setNestedLanguageAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile, logout } = useProfileAPI();
  const _navigate = useNavigate();
  const ln = getLanguageFromLS();
  const openMainMenu = Boolean(anchorEl);
  const openNestedLanguage = Boolean(nestedLanguageAnchorEl);

  const handleNestedLanguageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setNestedLanguageAnchorEl(event.currentTarget);
  };

  const handleNestedLanguageButtonClose = () => {
    setNestedLanguageAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenVacancyModal = () => {
    showVacancyModal();
    handleClose();
  };

  const handleOpenRequestModal = () => {
    showRequestModal();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeMenus = () => {
    handleClose();
    handleNestedLanguageButtonClose();
  };

  const onLanguageChange = (language: AppLanguages) => () => {
    closeMenus();
    setLanguageToLC(language);
    window.location.reload();
  };

  const navigate = (path: AppRoutes) => () => {
    closeMenus();
    _navigate(path);
  };

  const handleLogout = async () => {
    closeMenus();
    await AuthService.logout();
    logout();
  };

  if (profile) {
    return (
      <Styled.Wrapper>
        {isDesktopView ? (
          <AvatarMenu
            firstNameRu={profile.localizedFirstName}
            lastNameRu={profile.localizedLastName}
            jobTitle={profile.jobTitle.translation}
            photoId={profile.photoRt?.id}
            onClick={handleClick}
          />
        ) : (
          <Styled.BurgerMenu onClick={handleClick}>
            <Icon type="u_burger" size={24} viewBox="-1 -5 20 20" />
          </Styled.BurgerMenu>
        )}
        <Styled.Menu
          disablePortal
          anchorEl={anchorEl}
          marginThreshold={10}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openMainMenu}
          onClose={handleClose}
        >
          <Styled.MenuItem onClick={navigate(AppRoutes.Profile)}>
            <Icon type="u_square" size={20} />
            {t('pages.profile.profileDetails.personalAccount')}
          </Styled.MenuItem>
          {!isDesktopView && (
            <Styled.MenuItem>
              <EventsProgress />
              <div>{t('pages.administration.sourcing.todayLetters')}</div>
            </Styled.MenuItem>
          )}
          <Styled.Divider />
          <Styled.MenuItem $preventHoverStyles>
            {t('buttons.language')}
            <Styled.ChoseLanguageButton
              variant="outlined"
              onClick={handleNestedLanguageButtonClick}
              endIcon={<Styled.AngleIcon open={openNestedLanguage} type="u_angle-down" size={20} />}
            >
              {t(languages[ln].title)}
            </Styled.ChoseLanguageButton>
            <Menu
              transitionDuration={0}
              disablePortal
              open={openNestedLanguage}
              anchorEl={nestedLanguageAnchorEl}
              onClose={handleNestedLanguageButtonClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={onLanguageChange(languages.en.value)}>{t(languages.en.title)}</MenuItem>
              <MenuItem onClick={onLanguageChange(languages.ru.value)}>{t(languages.ru.title)}</MenuItem>
            </Menu>
          </Styled.MenuItem>
          {!isDesktopView && (
            <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
              <Styled.MenuItem $preventHoverStyles onClick={handleOpenVacancyModal}>
                <Icon type="u_plus_vacancy" size={20} viewBox="1 0 24 24" />
                {t('buttons.addVacancy')}
              </Styled.MenuItem>
            </RoleGuard>
          )}
          {!isDesktopView && (
            <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}>
              <Styled.MenuItem $preventHoverStyles onClick={handleOpenRequestModal}>
                <Icon type="u_plus_request" size={20} viewBox="-1 0 24 22" />
                {t('pages.vacancies.vacancyDetails.addARequest')}
              </Styled.MenuItem>
            </RoleGuard>
          )}
          <Styled.MenuItem>
            <Styled.RedirectLink href={SupportLink} target="_blank" rel="noopener noreferrer">
              <Icon type="u_globe" size={20} />
              {t('buttons.technicalSupport')}
            </Styled.RedirectLink>
          </Styled.MenuItem>
          <Styled.MenuItem onClick={navigate(AppRoutes.FaqView)}>
            <Icon type="u_question-circle" size={20} />
            {t('buttons.faq')}
          </Styled.MenuItem>
          <Styled.Divider />
          <Styled.LogOutMenuItem onClick={handleLogout}>
            <Styled.LogoutIcon type="u_log-out" size={20} />
            {t('pages.profile.profileDetails.logout')}
          </Styled.LogOutMenuItem>
        </Styled.Menu>
      </Styled.Wrapper>
    );
  }
  return null;
};

export default ProfileMenu;
