import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EmployeeOption } from './employees.types';
import { employeesMapper } from './employees.mappers';
import { localizedNameObject } from '@innowise-group/utilities';

export const getEmployeesState = (state: RootState) => state.employees;

export const getEmployeesItems = createSelector(getEmployeesState, (state) => {
  const items = state.employees.ids.reduce((accum, curr) => {
    accum[curr] = { ...state.employees.items[curr], ...localizedNameObject(state.employees.items[curr]) };
    return accum;
  }, {});
  return { ...state.employees, items };
});

export const getMentionedEmployeesItems = createSelector(getEmployeesState, (state) => {
  const items = state.mentionedEmployees.ids.reduce((accum, curr) => {
    accum[curr] = {
      ...state.mentionedEmployees.items[curr],
      ...localizedNameObject(state.mentionedEmployees.items[curr]),
    };
    return accum;
  }, {});
  return { ...state.mentionedEmployees, items };
});

export const getMentionedOptionsItems = createSelector(getEmployeesState, (state) => {
  return state.mentionedOptions;
});

export const getEmployeesOptions = createSelector(getEmployeesItems, (state) => {
  const options: EmployeeOption[] = state.ids?.map((id) => {
    return {
      id,
      display: `${state.items[id].firstNameEn} ${state.items[id].lastNameEn}`,
    };
  });
  return options;
});

export const getResponsibleEmployeesOptions = createSelector(getEmployeesState, (state) =>
  employeesMapper(state, 'employees'),
);

export const getEmployeesForCandidateDiffMergeOptions = createSelector(getEmployeesState, (state) =>
  employeesMapper(state, 'employeesForCandidateDiffMerge'),
);

export const getMentionedEmployeesOptions = createSelector(getMentionedOptionsItems, (state) => {
  const options: EmployeeOption[] = state.ids?.map((id) => {
    return state.items[id];
  });
  return options;
});
