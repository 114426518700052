import { styled } from '@innowise-group/mui-kit';
import { Checkbox as CheckboxBase, Icon, Input as InputBase } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  padding: 10px;
`;

export const Input = styled(InputBase)`
  .MuiOutlinedInput-root {
    height: 45px;
  }
  .MuiFormLabel-root {
    top: 0px;
  }
  .MuiFormHelperText-root {
    height: 0;
  }
`;

export const ScrollableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectItemWrapper = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.darkGrey[40]};
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[80]}
`;

export const Checkbox = styled(CheckboxBase)`
  & .MuiSvgIcon-root {
    font-size: 25px;
  }
`;

export const SelectItemLabel = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[80]}
`;

export const NestedContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const ExpandIcon = styled(Icon)`
  border-radius: 0;
  width: 50px;
  height: 50px;
`;

export const SelectItemPressContainer = styled.div`
  padding: 15px 0;
  width: 100%;
`;

export const NestedItemContainer = styled(SelectItemPressContainer)`
  padding: 15px 0;
  display: flex;
  align-items: center;
`;
