import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { roles } from './edit-user-role.data';
import { useEmployeesAdministrationAPI } from '@innowise-group/core';
import * as Styled from './edit-user-role';
import { LabeledCheckbox, LabeledRadioButton } from '@shared-mui-components';
import { RolesValues } from '@constants';

interface EditUserRoleModalProps {
  id: number;
  reloadUsers: () => void;
}

const EditUserRoleModal: React.FC<ModalProps & EditUserRoleModalProps> = ({ close, reloadUsers, id }) => {
  const { t } = useTranslation();
  const { employees, updateExistingUserRole, rolePermissionAssign } = useEmployeesAdministrationAPI();

  const onChangeHandler = (newRole: RolesValues) => () => updateExistingUserRole({ id, newRole });

  const onCloseHandler = () => {
    updateExistingUserRole({ id, newRole: employees[id].role?.name });
    close();
  };

  const onSubmitHandler = async () => {
    await rolePermissionAssign({ roleName: employees[id].newRole, employeeId: id });
    close();
    reloadUsers();
  };

  const getProps = (key: keyof typeof roles) => ({
    checked: employees[id].newRole === roles[key].value,
    value: (employees[id].newRole === roles[key].value).toString(),
    onChange: onChangeHandler(roles[key].value),
  });

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={onCloseHandler}>
        <Styled.Header>{t('pages.administration.newUser.userRoles')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.UserName>{employees[id].localizedFullName}</Styled.UserName>
        <Styled.OptionsContainer>
          <Styled.OptionsColumn>
            <LabeledRadioButton {...getProps(RolesValues.Admin)} label={t(roles.ADMIN.title)} />
            <LabeledRadioButton {...getProps(RolesValues.Supervisor)} label={t(roles.SUPERVISOR.title)} />
            <LabeledRadioButton {...getProps(RolesValues.Recruiter)} label={t(roles.RECRUITER.title)} />
            {/* <LabeledRadioButton {...getProps(RolesValues.Sourcer)} label={t(roles.SOURCER.title)} /> */}
          </Styled.OptionsColumn>
          <Styled.OptionsColumn>
            <LabeledRadioButton
              onChange={onChangeHandler(RolesValues.ManagerFourthLevel)}
              checked={
                employees[id].newRole === RolesValues.ManagerFirstLevel ||
                employees[id].newRole === RolesValues.ManagerSecondLevel ||
                employees[id].newRole === RolesValues.ManagerThirdLevel ||
                employees[id].newRole === RolesValues.ManagerFourthLevel
              }
              value={
                (employees[id].newRole === RolesValues.ManagerFirstLevel).toString() ||
                (employees[id].newRole === RolesValues.ManagerSecondLevel).toString() ||
                (employees[id].newRole === RolesValues.ManagerThirdLevel).toString() ||
                (employees[id].newRole === RolesValues.ManagerFourthLevel).toString()
              }
              label={t(roles.MANAGER_FOURTH_LEVEL_MANAGER_FOURTH_LEVEL.title)}
            />
            <Styled.SubOptionsColumn>
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerFirstLevel)}
                label={t(roles.MANAGER_FIRST_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerSecondLevel)}
                label={t(roles.MANAGER_SECOND_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerThirdLevel)}
                label={t(roles.MANAGER_THIRD_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerFourthLevel)}
                label={t(roles.MANAGER_FOURTH_LEVEL.title)}
              />
            </Styled.SubOptionsColumn>
          </Styled.OptionsColumn>
        </Styled.OptionsContainer>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: onCloseHandler,
            type: 'button',
          },
          {
            title: t('buttons.save'),
            onClick: onSubmitHandler,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(EditUserRoleModal, 'edit-user-role');
