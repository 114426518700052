import { styled } from '@innowise-group/mui-kit';
import { Menu as MenuBase } from '@innowise-group/mui-kit';

export const Menu = styled(MenuBase)`
  z-index: 2;
  & .MuiMenu-paper {
    max-height: 40vh !important;
  }
  & .MuiList-root {
    position: relative;
    height: fit-content;
    overflow-y: auto;
    padding: 0;
  }
  & .MuiMenu-paper {
    max-height: none !important;
  }
`;
