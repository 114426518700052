import {
  LocalizedRequestItemResponse,
  useProfileAPI,
  useUnitsAPI,
  useUnitsAdministrationOptions,
} from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './main-section.styles';
import { Button, Input, Select } from '@innowise-group/mui-kit';
import { DatePicker, VacancySelect } from '@shared-mui-components';
import { ManagerRolesArray, RolesValues } from '@constants';

interface MainSectionProps {
  handleNextStep: () => void;
  isEdit?: boolean;
  isStatusDisabled?: boolean;
  requestStatusOptions: SelectOption[];
  disabled: boolean;
  locationCityOptions: SelectOption[];
  currentRequestEdition: LocalizedRequestItemResponse;
  locationCountryOptions: SelectOption[];
  offices: SelectOption[];
  handleClose: () => void;
  searchVacancyHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  groupBy: (value) => (option) => string;
  handleChange: (onChange: (value: string) => void) => (value: string) => void;
  requestAuthorOptions: SelectOption[];
  searchRequestAuthorOptions: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isVacancyDisabled: boolean;
}

const MainSection: React.FC<MainSectionProps> = ({
  disabled,
  locationCountryOptions,
  offices,
  locationCityOptions,
  handleClose,
  handleNextStep,
  requestStatusOptions,
  isEdit,
  isStatusDisabled,
  currentRequestEdition,
  searchVacancyHandler,
  groupBy,
  handleChange,
  requestAuthorOptions,
  searchRequestAuthorOptions,
  isVacancyDisabled,
}) => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const { control, watch } = useFormContext();
  const countries = watch('countries');
  const cities = watch('cities');
  const vacancy = watch('vacancyId');
  const unitsOptions = useUnitsAdministrationOptions();
  const { isLoading: isUnitsLoading } = useUnitsAPI();

  return (
    <React.Fragment>
      <Controller
        name="authorId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Select
              value={value}
              onChange={onChange}
              disableClearable={!value?.length}
              options={requestAuthorOptions}
              disabled={[RolesValues.Supervisor, ...ManagerRolesArray].includes(profile.role.name)}
              fullWidth
              multiple
              disableCloseOnSelect
              limitTags={1}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    fullWidth
                    onChange={searchRequestAuthorOptions}
                    label={t('pages.requests.creationModal.customer')}
                    placeholder={t('pages.candidates.statuses.selectAColleague')}
                    error={!!errors?.authorId?.message?.toString()}
                    helperText={errors?.authorId?.message?.toString()}
                  />
                );
              }}
            />
          );
        }}
      />
      <Controller
        name="vacancyId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <VacancySelect
            value={value}
            onChange={handleChange(onChange)}
            disabled={
              disabled ||
              isVacancyDisabled ||
              (isEdit && [RolesValues.Supervisor, ...ManagerRolesArray].includes(profile.role.name))
            }
            groupBy={groupBy(value)}
            placeholder={t('pages.requests.creationModal.selectTheVacancyPlaceholder')}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  required
                  onChange={searchVacancyHandler}
                  label={t('pages.requests.creationModal.vacancy')}
                  placeholder={t('pages.requests.creationModal.selectTheVacancyPlaceholder')}
                  error={!!errors?.vacancyId?.message}
                  helperText={errors?.vacancyId?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Styled.RowContainer>
        <Controller
          name="structuralUnit"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Select
              value={value}
              onChange={onChange}
              options={unitsOptions}
              disabled={disabled}
              loading={isUnitsLoading}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    required
                    label={t('pages.profile.profileDetails.department')}
                    placeholder={t('pages.candidates.offer.orgUnitPlaceholder')}
                    error={!!errors?.structuralUnit?.message}
                    helperText={errors?.structuralUnit?.message.toString()}
                  />
                );
              }}
            />
          )}
        />
        <Controller
          name="vacancyRequestStatusId"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Select
              value={value}
              onChange={onChange}
              options={requestStatusOptions}
              disabled={isEdit && isStatusDisabled}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    required
                    fullWidth
                    label={t('pages.requests.creationModal.status')}
                    placeholder={t('pages.requests.creationModal.selectStatus')}
                    error={!!errors?.vacancyRequestStatusId?.message}
                    helperText={errors?.vacancyRequestStatusId?.message.toString()}
                  />
                );
              }}
            />
          )}
        />
      </Styled.RowContainer>
      <Styled.RowContainer>
        <Controller
          name="requiredCount"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Styled.FullNumberInput
              label={t('pages.requests.creationModal.fullCount')}
              required
              min={currentRequestEdition?.candidatesFound || 1}
              max={50}
              value={value}
              onChange={onChange}
              error={!!errors?.requiredCount?.message}
              helperText={errors?.requiredCount?.message.toString()}
              onStep={onChange}
            />
          )}
        />
        <Controller
          name="hiringDeadline"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Styled.DatePickerContainer>
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                error={!!errors?.hiringDeadline?.message}
                isConstrained={false}
                helperText={errors?.hiringDeadline?.message.toString()}
                label={t('pages.requests.creationModal.deadline')}
                placeholder={t('pages.requests.creationModal.date')}
                minDate={new Date()}
              />
            </Styled.DatePickerContainer>
          )}
        />
      </Styled.RowContainer>
      <Styled.RowContainer>
        <Controller
          name="asEmployee"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.CustomLabeledCheckbox
              disabled={disabled}
              onChange={onChange}
              checked={value}
              label={t('pages.requests.viewModal.asEmployee')}
            />
          )}
        />
        <Controller
          name="onProject"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.CustomLabeledCheckbox
              disabled={disabled}
              onChange={onChange}
              checked={value}
              label={t('pages.requests.viewModal.onProject')}
            />
          )}
        />
      </Styled.RowContainer>
      <Controller
        name="countries"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCountryOptions}
            disabled={disabled || !vacancy}
            multiple
            limitTags={1}
            disableCloseOnSelect
            disableSortByAlphabet={false}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.requests.creationModal.location')}
                  placeholder={t('pages.requests.creationModal.selectLocation')}
                  error={!!errors?.countries?.message}
                  helperText={errors?.countries?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="cities"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCityOptions}
            disabled={disabled || !countries?.length}
            limitTags={1}
            disableCloseOnSelect
            multiple
            disableSortByAlphabet={false}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.vacancies.creationModal.city')}
                  placeholder={t('pages.vacancies.creationModal.selectTheCity')}
                  error={!!errors?.cities?.message}
                  helperText={errors?.cities?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="offices"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={offices || []}
            disabled={disabled || !cities.length}
            disableSortByAlphabet={false}
            limitTags={1}
            disableCloseOnSelect
            multiple
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.requests.creationModal.office')}
                  placeholder={t('pages.requests.creationModal.selectOffice')}
                  error={!!errors?.officeId?.message}
                  helperText={errors?.officeId?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handleClose} variant="outlined">
          {t('buttons.cancel')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </React.Fragment>
  );
};

export default MainSection;
