import { useDebounce, BaseFilterType, useInfiniteScroll } from '@innowise-group/core';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './entity.styles';
import { LoadingFrame } from '@innowise-group/mui-kit';

interface EntityItemResponse {
  id: string;
  name: string;
}

type EntityApiSearchFilterState = number[];

export type GetEntitiesList = (args: {
  currentPage: number;
  pageSize: number;
  searchBarRequest: string;
}) => Promise<{ content: EntityItemResponse[]; totalPages: number }>;

export type GetEntitiesById = (id: string) => Promise<EntityItemResponse>;

type OnValueChange = (entityId: EntityApiSearchFilterState) => void;

interface EntityFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  multiple?: boolean;
  state: EntityApiSearchFilterState;
  onValueChange: OnValueChange;
  getEntityById: GetEntitiesById;
  getEntitiesList: GetEntitiesList;
}

interface EntityState {
  ids: string[];
  items: { [key: string]: EntityItemResponse };
}

const EntityFilter: FC<EntityFilterProps> = ({
  state,
  labelKey,
  multiple = false,
  getEntityById,
  onValueChange,
  getEntitiesList,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [{ ids, items }, setEntities] = useState<EntityState>({ ids: [], items: {} });
  const [totalPages, setTotalPages] = useState(0);
  const [isEntityLoading, setIsEntityLoading] = useState<boolean>(false);
  const [isEntitiesListLoading, setIsEntitiesListLoading] = useState<boolean>(false);
  const [debouncedValue] = useDebounce(search);

  const filteredItems = ids.reduce<EntityState['items']>((acc, id) => {
    const isIncludes = items[id]?.name?.toLowerCase()?.includes(debouncedValue.toLowerCase());
    if (isIncludes) {
      return {
        ...acc,
        [id.toString()]: items[id],
      };
    }
    return acc;
  }, {});

  const { lastElementRef, pageNum } = useInfiniteScroll(isEntitiesListLoading, totalPages);

  const getEntitiesListHandle = async (searchBarRequest: string, pageNum: number) => {
    setIsEntitiesListLoading(true);
    try {
      const data = await getEntitiesList({ currentPage: pageNum, pageSize: 50, searchBarRequest });
      const { ids, items } = data.content.reduce<EntityState>(
        (acc, { id, ...rest }) => {
          return {
            ...acc,
            ids: [...acc.ids, id?.toString()],
            items: {
              ...acc.items,
              [id?.toString()]: { id, ...rest },
            },
          };
        },
        { ids: [], items: {} },
      );
      setEntities((prev) => ({
        ...prev,
        ids: [...new Set([...prev.ids, ...ids])],
        items: {
          ...prev.items,
          ...items,
        },
      }));
      setTotalPages(data.totalPages);
    } finally {
      setIsEntitiesListLoading(false);
    }
  };

  const getCurrentEntityHandle = async (id: number) => {
    setIsEntityLoading(true);
    try {
      const data = await getEntityById(id.toString());
      setEntities((prev) => ({
        ...prev,
        ids: [data.id?.toString(), ...prev.ids],
        items: {
          ...prev.items,
          ...{ [id.toString()]: data },
        },
      }));
    } finally {
      setIsEntityLoading(false);
    }
  };

  useEffect(() => {
    const [currentEntityFromState] = state;
    if (currentEntityFromState) {
      getCurrentEntityHandle(currentEntityFromState);
    } else {
      getEntitiesListHandle('', 1);
    }
  }, []);

  useEffect(() => {
    getEntitiesListHandle(debouncedValue, pageNum);
  }, [debouncedValue, pageNum]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onValueChangeHandler = (entity: EntityItemResponse) => () => {
    if (!multiple) {
      const isSameEntity = state?.[0]?.toString() === entity.id?.toString();
      if (isSameEntity) {
        onValueChange([]);
      } else {
        onValueChange([Number(entity.id)]);
      }
    } else {
      const isExists = state.some((id) => id?.toString() === entity.id?.toString());
      let newValue;
      if (isExists) {
        newValue = state.filter((id) => id?.toString() !== entity.id?.toString());
      } else {
        newValue = [...state, Number(entity.id)];
      }
      onValueChange(newValue);
    }
    setSearch('');
  };

  return (
    <Styled.FilterWrapper>
      <Styled.Input placeholder={t(labelKey)} onChange={onInputChangeHandler} value={search} />
      <Styled.ListItemContainer>
        {isEntityLoading && <LoadingFrame />}
        {!isEntityLoading &&
          ids.map((id) => {
            const checked = state.some((e) => e?.toString() === id?.toString());
            return filteredItems[id] ? (
              <Styled.SelectItemWrapper ref={lastElementRef} key={id} onClick={onValueChangeHandler(filteredItems[id])}>
                <Styled.Checkbox readOnly defaultChecked={checked} checked={checked} id={id} />
                <Styled.SelectItemLabel>{filteredItems[id]?.name}</Styled.SelectItemLabel>
              </Styled.SelectItemWrapper>
            ) : null;
          })}
        {isEntitiesListLoading && !isEntityLoading && <Styled.Loader />}
      </Styled.ListItemContainer>
    </Styled.FilterWrapper>
  );
};

export default memo(EntityFilter);
