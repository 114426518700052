import { FieldOption_New } from '../common';
import { Options } from './options.types';
import {
  CurrencyEnum,
  GradeEnum,
  GradeValues,
  LanguageEnum,
  LanguageLevelEnum,
  LanguageLevelValues,
  OptionEntityEnum,
  SelectOptionEntityName,
  WorkLoadsEnum,
  deniedOfficesIds,
  WorkFormatsEnum,
  RequestStatusEnum,
  deniedVacancyRequestStatuses,
  GenderEnum,
  EmploymentFormsEnum,
  PurposeEnum,
} from '@constants';
import i18n from '@localization';

export const optionsMapper = (data: { [key: string]: FieldOption_New[] }) => {
  const result: Options = Object.keys(data).reduce(
    (acc, curr) => {
      if (curr === OptionEntityEnum.Office) {
        acc[SelectOptionEntityName[curr]] = data[curr].reduce((accum, item) => {
          if (!deniedOfficesIds.includes(item.valueId)) {
            accum.push({ value: item.valueId, title: item.translation, parent: item.parentId });
          }
          return accum;
        }, []);
        return acc;
      }
      acc[SelectOptionEntityName[curr]] = data[curr].map((item) => {
        return { value: item.valueId, title: item.translation, parent: null };
      });
      return acc;
    },
    {
      professionOptions: [],
      domainOptions: [],
      gradeOptions: [],
      technologyOptions: [],
      languageOptions: [],
      languageLevelOptions: [],
      countryOptions: [],
      citiesOptions: [],
      genderOptions: [],
      visaTypesOptions: [],
      currencyOptions: [],
      candidateSourceOptions: [],
      workFormatsOptions: [],
      workloadsOptions: [],
      employmentFormsOptions: [],
      vacancyStatus: [],
      locationOptions: [],
      officeOptions: [],
      statusOptions: [],
      requestFilterStatusOptions: [],
      vacancyPurposeOptions: [],
      statusAuthorsOptions: [],
      jobTitleOptions: [],
      countriesOptions: [],
    },
  );

  const vacancyPurposeOptions = Object.values(PurposeEnum);
  vacancyPurposeOptions.map((item) => {
    if (result.vacancyPurposeOptions) {
      result.vacancyPurposeOptions.push({
        title: i18n.t(`options.vacancyPurposeOptions_${item}`),
        value: item,
        parent: null,
      });
    }
  });

  const genderOptions = Object.values(GenderEnum);
  genderOptions.map((item) => {
    if (result.genderOptions) {
      result.genderOptions.push({
        title: i18n.t(`options.genderOptions_${item}`),
        value: item,
        parent: null,
      });
    }
  });

  const workLoadsArray = Object.values(WorkLoadsEnum);
  workLoadsArray.map((item) => {
    if (result.workloadsOptions) {
      result.workloadsOptions.push({
        title: i18n.t(`options.candidateWorkloadsOptions_${item}`),
        value: item,
        parent: null,
      });
      return;
    }
    result.workloadsOptions = [
      { title: i18n.t(`options.candidateWorkloadsOptions_${item}`), value: item, parent: null },
    ];
  });
  const employmentFormsArray = Object.values(EmploymentFormsEnum);
  employmentFormsArray.map((item) => {
    if (result.employmentFormsOptions) {
      result.employmentFormsOptions.push({
        title: i18n.t(`options.employmentFormsOptions_${item}`),
        value: item,
        parent: null,
      });
      return;
    }
    result.employmentFormsOptions = [
      { title: i18n.t(`options.employmentFormsOptions_${item}`), value: item, parent: null },
    ];
  });
  const workFormatsArray = Object.values(WorkFormatsEnum);
  workFormatsArray.map((item) => {
    if (result.workFormatsOptions) {
      result.workFormatsOptions.push({
        title: i18n.t(`options.candidateWorkFormatsOptions_${item}`),
        value: item,
        parent: null,
      });
      return;
    }
    result.workFormatsOptions = [
      { title: i18n.t(`options.candidateWorkFormatsOptions_${item}`), value: item, parent: null },
    ];
  });
  const languageLevelArray = Object.values(LanguageLevelEnum);
  languageLevelArray.map((item) => {
    if (result.languageLevelOptions) {
      result.languageLevelOptions.push({
        title: LanguageLevelValues[item],
        value: item,
        parent: null,
      });
      return;
    }
    result.languageLevelOptions = [{ title: LanguageLevelValues[item], value: item, parent: null }];
  });
  const currencyArray = Object.values(CurrencyEnum);
  currencyArray.map((item) => {
    if (result.currencyOptions) {
      result.currencyOptions.push({
        title: item,
        value: item,
        parent: null,
      });
      return;
    }
  });
  const gradeArray = Object.values(GradeEnum);
  gradeArray.map((item) => {
    if (result.gradeOptions) {
      result.gradeOptions.push({
        title: GradeValues[item],
        value: item,
        parent: null,
      });
      return;
    }
    result.gradeOptions = [{ title: GradeValues[item], value: item, parent: null }];
  });
  const languageArray = Object.values(LanguageEnum);
  languageArray.map((item) => {
    if (result.languageOptions) {
      result.languageOptions.push({
        title: i18n.t(`options.languageOptions_${item}`),
        value: item,
        parent: null,
      });
      return;
    }
    result.languageOptions = [{ title: i18n.t(`options.languageOptions_${item}`), value: item, parent: null }];
  });
  const requestStatusArray = Object.values(RequestStatusEnum);
  requestStatusArray.map((item) => {
    if (result.requestFilterStatusOptions) {
      result.requestFilterStatusOptions.push({
        title: i18n.t(`options.requestStatusOptions_${item}`),
        value: item,
        parent: null,
      });
    } else {
      result.requestFilterStatusOptions = [
        { title: i18n.t(`options.requestStatusOptions_${item}`), value: item, parent: null },
      ];
    }

    if (!deniedVacancyRequestStatuses.includes(item)) {
      if (result.statusOptions) {
        result.statusOptions.push({
          title: i18n.t(`options.requestStatusOptions_${item}`),
          value: item,
          parent: null,
        });
      } else {
        result.statusOptions = [{ title: i18n.t(`options.requestStatusOptions_${item}`), value: item, parent: null }];
      }
    }
  });
  return result;
};

export const citiesMapper = (arr) => {
  return arr.map((item) => {
    return { title: item.name, value: item.id, parent: item.countryId.toString() };
  });
};
