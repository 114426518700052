import React from 'react';
import * as Styled from './desktop-view.styles';
import {
  CandidateStatusColors,
  CandidateStatusGroupEnum,
  LifecycleStatuses,
  StatusLifecycleStatuses,
} from '@constants';
import { Input, Select, Divider, Icon } from '@innowise-group/mui-kit';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CandidateStatusListItem,
  SelectOption,
  useAllStatusesOptions,
  useCandidateOffersAPI,
  useCandidateStatusAPI,
} from '@innowise-group/core';
import { PeriodSection } from '../period-section';
import { RequestSection } from '../request-section';
import { SubStatusSection } from '../substatus-section';
import { VacancySection } from '../vacancy-section';
import { FileSection } from '../file-section';
import { LinkSection } from '../link-section';
import { ReminderSection } from '../reminder-section';
import { CalendarSection } from '../calendar-section';
import { NotificationSection } from '../notification-section';
import { AgreementSection } from '../agreement-section';
import { FeedbackSection } from '../feedback-section';
import { CommentForm } from '../../../comment-form';
import { HRMProfile } from '../hrm-profile';
import { ProposalSection } from '../proposal-section';

interface DesktopViewProps {
  photoId?: number;
  localizedFullName: string;
  statusConfig: CandidateStatusListItem;
  isEdit?: boolean;
  flags: {
    [key: string]: boolean;
  };
  isAttachments: boolean;
  eventId?: number;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  photoId,
  isAttachments,
  flags,
  isEdit,
  localizedFullName,
  statusConfig,
  eventId,
}) => {
  const { t } = useTranslation();
  const { actualStatusOptions: statusOptions } = useAllStatusesOptions();
  const { isLoading: isOffersLoading, ids, items } = useCandidateOffersAPI();
  const { items: allStatusesItems, ids: allStatusesIds } = useCandidateStatusAPI();
  const { control, watch } = useFormContext();
  const fileId = watch('file.id');
  const isNotEmpty = ids.find((id) => items[id].lifecycleStatus === LifecycleStatuses.Actual);

  const offerStatuses = allStatusesIds.reduce((acc, id) => {
    if (
      allStatusesItems[id].group === CandidateStatusGroupEnum.OFFER &&
      allStatusesItems[id].lifecycleStatus !== StatusLifecycleStatuses.Deleted
    ) {
      return acc + `${acc ? ',' : ''} ${allStatusesItems[id].localizedName}`;
    }
    return acc;
  }, '');

  const getOptionDisabledHandle = (option: SelectOption) => {
    if (isNotEmpty) return false;
    return allStatusesItems[option.value].group === CandidateStatusGroupEnum.OFFER;
  };

  return (
    <React.Fragment>
      <Styled.SectionContainer>
        <Styled.CandidateInfoContainer>
          {photoId ? (
            <Styled.Avatar id={photoId} />
          ) : (
            <Styled.NoImageContainer>
              <Icon type="u_camera" size={45} viewBox="0 0 49 44" />
            </Styled.NoImageContainer>
          )}
          <Styled.StatusContainer>
            <Styled.FullNameContainer>
              <span>{localizedFullName}</span>
            </Styled.FullNameContainer>
            <div>
              {isEdit ? (
                <Styled.StatusItem statusColors={CandidateStatusColors[statusConfig.group]}>
                  {statusConfig.localizedName}
                </Styled.StatusItem>
              ) : (
                <Styled.StatusItemContainer>
                  <Controller
                    name="statusId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        onChange={onChange}
                        options={statusOptions}
                        loading={isOffersLoading}
                        disableClearable
                        getOptionDisabled={getOptionDisabledHandle}
                        renderInput={(params) => {
                          return (
                            <Input
                              {...params}
                              label={t('pages.requests.creationModal.status')}
                              placeholder={t('pages.requests.creationModal.selectStatus')}
                            />
                          );
                        }}
                      />
                    )}
                  />
                  {!isNotEmpty && !isOffersLoading && (
                    <Styled.IconContainer>
                      <Styled.InfoTooltip
                        title={t('tooltips.requiredOffer', { statusesStr: offerStatuses })}
                        placement="top-end"
                      />
                    </Styled.IconContainer>
                  )}
                </Styled.StatusItemContainer>
              )}
              {flags['isPeriodField'] && <PeriodSection fieldName="periodFrom" />}
            </div>
          </Styled.StatusContainer>
        </Styled.CandidateInfoContainer>
        <Styled.OptionalInfoContainer>
          {flags['isPeriodField'] && <PeriodSection fieldName="periodTo" />}
          {flags['isRequestField'] && <RequestSection />}
          {flags['isSubStatusField'] && <SubStatusSection />}
          {flags['isVacancyField'] && <VacancySection disabled={isEdit} />}
        </Styled.OptionalInfoContainer>
      </Styled.SectionContainer>
      <Divider />
      {isAttachments && (
        <React.Fragment>
          <Styled.RowContainer>
            <Styled.Block>
              {(fileId || flags['isFileField']) && (
                <FileSection isFeedback={flags['isFeedbackField']} statusConfig={statusConfig} />
              )}
              {flags['isLinkField'] && <LinkSection />}
            </Styled.Block>
            <Styled.DatesBlock>
              {flags['isReminderField'] && <ReminderSection />}
              {flags['isCalendarField'] && <CalendarSection isEdit={isEdit} eventId={eventId} />}
            </Styled.DatesBlock>
          </Styled.RowContainer>
          <Divider />
        </React.Fragment>
      )}
      <Styled.SectionColumnContainer>
        {flags['isNotificationField'] && <NotificationSection />}
        {flags['isAgreementField'] && <AgreementSection />}
        {flags['isFeedbackField'] && <FeedbackSection />}
        {statusConfig.group === CandidateStatusGroupEnum.JOB_ENTRY && (
          <Styled.SectionRowContainer>
            <HRMProfile />
          </Styled.SectionRowContainer>
        )}
        {statusConfig?.group === CandidateStatusGroupEnum.OFFER && (
          <Styled.SectionRowContainer>
            <ProposalSection />
          </Styled.SectionRowContainer>
        )}
        <CommentForm isThread isStatus />
      </Styled.SectionColumnContainer>
    </React.Fragment>
  );
};

export default DesktopView;
