import { createReducer } from '@reduxjs/toolkit';
import { preloadedState } from './calendar.preloaded-state';
import { getCalendarMeetingsThunk, getCalendarMeetingByIdThunk, getCalendarTimeSlotsThunk } from './calendar.thunk';
import { clearMeetingsAction, clearTimeSlotsAction } from './calendar.actions';

export const calendarReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(getCalendarMeetingByIdThunk.pending, (state) => {
      state.isCurrentMeetLoading = true;
    })
    .addCase(getCalendarMeetingByIdThunk.fulfilled, (state, { payload }) => {
      state.isCurrentMeetLoading = false;
      state.currentMeet = payload;
    })
    .addCase(getCalendarMeetingByIdThunk.rejected, (state) => {
      state.currentMeet = preloadedState.currentMeet;
      state.isCurrentMeetLoading = false;
    })
    .addCase(getCalendarMeetingsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getCalendarMeetingsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.meetingsIds = payload.ids;
      state.meetings = payload.items;
    })
    .addCase(getCalendarMeetingsThunk.rejected, (state) => {
      state.isLoading = preloadedState.isLoading;
    })
    .addCase(getCalendarTimeSlotsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getCalendarTimeSlotsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.timeSlots = payload;
    })
    .addCase(getCalendarTimeSlotsThunk.rejected, (state) => {
      state.isLoading = preloadedState.isLoading;
    })
    .addCase(clearMeetingsAction, () => {
      return { ...preloadedState };
    })
    .addCase(clearTimeSlotsAction, (state) => {
      state.timeSlots = [];
    }),
);
