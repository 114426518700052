import { SelectOption } from '@innowise-group/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useDebounce from './use-debounce.hook';
import { LifecycleStatuses, OpenVacanciesStatusId, SortingValue } from '@constants';
import { VacanciesService } from '../services';
import { useTranslation } from 'react-i18next';
import { getArrayIds } from '@innowise-group/utilities';

class VacancyFetches {
  static prev: Date | null = null;
  static setLatest(fetch: Date): boolean {
    this.prev = fetch;
    return true;
  }

  static getLatest(fetch: Date): boolean {
    return this.prev === fetch;
  }
}

const useSearchVacancy = (
  vacancyIdFieldName: string,
  vacancyNameFieldName: string,
  isZeroVacancy?: boolean,
  disabled?: boolean,
) => {
  const [isBusy, setBusy] = useState(false);
  const { t } = useTranslation();
  const [vacanciesOptions, setVacanciesOptions] = useState<{ ids: number[]; items: SelectOption[] }>({
    ids: [],
    items: null,
  });
  const [vacancyValue, setVacancyValue] = useState<string>('');
  const [debouncedVacancyValue] = useDebounce<string>(vacancyValue, 500);
  const itemSet = useMemo(() => {
    return vacanciesOptions.ids.reduce(
      (acc, id) => (vacanciesOptions.items[id] ? [...acc, vacanciesOptions.items[id]] : acc),
      [],
    );
  }, [vacanciesOptions]);

  const { watch, setValue } = useFormContext();

  const currentVacancyId = watch(vacancyIdFieldName);
  const currentVacancyName = watch(vacancyNameFieldName);

  const transformValue = (value: string) => vacanciesOptions.items?.[value]?.title;

  const handleVacancyChange = useCallback(
    (onChange: (value: string) => void) => (value: string) => {
      onChange(value);
      setValue(vacancyNameFieldName, transformValue(value));
    },
    [vacancyNameFieldName],
  );

  const handleSearchVacancies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVacancyValue(event.target.value);
  };

  const fetchVacanciesOptions = useCallback(
    async (searchValue: string) => {
      if (!disabled) {
        const body = {
          searchBarRequest: searchValue,
          lifecycleStatus: LifecycleStatuses.Actual,
          statusIds: [OpenVacanciesStatusId],
        };
        const params = {
          currentPage: 1,
          pageSize: 10,
          sort: SortingValue.VacancyNameASC,
        };

        const now = new Date();
        VacancyFetches.setLatest(now);

        setBusy(true);
        const response = await VacanciesService.searchAllVacancies({ params, body }).finally(() => setBusy(false));
        const isLast = VacancyFetches.getLatest(now);
        if (isLast) {
          const ids = [...getArrayIds(response.data.content), ...(isZeroVacancy ? [0] : [])];
          setVacanciesOptions((prev) => {
            return {
              ...prev,
              ids: [...ids, ...(!ids.includes(Number(currentVacancyId)) ? [Number(currentVacancyId)] : [])],
              items: {
                ...prev.items,
                ...response.data.content.reduce(
                  (acc, { id, name }) => ({
                    ...acc,
                    [id]: {
                      value: id.toString(),
                      title: name,
                    },
                  }),
                  {},
                ),
                ...(isZeroVacancy && { 0: { value: '0', title: t('pages.candidates.comments.jobOpening') } }),
              },
            };
          });
        }
      }
    },
    [t, currentVacancyId],
  );

  const onCloseVacancySelect = useCallback(() => {
    setVacancyValue('');
    if (currentVacancyId) {
      setVacanciesOptions((prev) => ({
        ...prev,
        ids: [...prev.ids, ...(!prev.ids.includes(Number(currentVacancyId)) ? [Number(currentVacancyId)] : [])],
        items: {
          ...prev.items,
          [currentVacancyId]: {
            title: Number(currentVacancyId) ? currentVacancyName : t('pages.candidates.comments.jobOpening'),
            value: currentVacancyId.toString(),
          },
        },
      }));
    } else {
      fetchVacanciesOptions('');
    }
  }, [currentVacancyId, fetchVacanciesOptions, currentVacancyName, t]);

  useEffect(() => {
    onCloseVacancySelect();
  }, [onCloseVacancySelect, currentVacancyId, currentVacancyName]);

  useEffect(() => {
    fetchVacanciesOptions(debouncedVacancyValue);
  }, [debouncedVacancyValue, fetchVacanciesOptions]);

  return {
    transformValue,
    handleVacancyChange,
    onCloseVacancySelect,
    handleSearchVacancies,
    vacanciesOptions: itemSet,
    isBusy,
  };
};

export default useSearchVacancy;
