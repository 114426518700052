import React, { useEffect, useMemo, useRef } from 'react';
import * as Styled from '../../custom-date-picker.styles';
import { SelectOption } from '@innowise-group/core';
import { getMonths } from '../full-date-header/full-date-header.data';

interface DateWithTimeHeaderProps {
  date: Date;
  startYear: number;
  endYear: number;
  yearsOptions: SelectOption[];
  onChange: (value: Date) => void;
  startDate?: Date;
}

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);
today.setDate(today.getDate() + 1);

const DateWithTimeHeader: React.FC<DateWithTimeHeaderProps> = ({
  date,
  startYear,
  endYear,
  yearsOptions,
  onChange,
  startDate,
}) => {
  const months = getMonths();

  const days = useMemo(() => {
    const count = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const options: SelectOption[] = Array.from({ length: count }, (_, idx) => {
      return { title: (idx + 1).toString(), value: (idx + 1).toString() };
    });
    return options;
  }, [date]);

  const decreaseYear = () => {
    if (date.getFullYear() > startYear) {
      const newDate = new Date(new Date(date).setFullYear(date.getFullYear() - 1));
      newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
      onChange(newDate.getTime() > startDate.getTime() ? newDate : today);
    }
  };

  const increaseYear = () => {
    if (date.getFullYear() < endYear) {
      const newDate = new Date(new Date(date).setFullYear(date.getFullYear() + 1));
      newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
      onChange(newDate);
    }
  };

  return (
    <Styled.CalendarHeader withoutGap>
      <Styled.ChangeBlock>
        <Styled.CustomSelect
          size="extra-small"
          value={date.getDate().toString()}
          options={days || []}
          placeholder={days[date.getDate() - 1]?.title || ''}
          onValueChange={(value: string) => {
            const newDate = new Date(new Date(date).setDate(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            onChange(newDate.getTime() > startDate.getTime() ? newDate : today);
          }}
          valueTransformer={(value: string) => {
            return days.find((item) => item.value === value)?.title || '';
          }}
          renderCustomListItem={(
            value: SelectOption,
            onClick: () => void,
            selected: boolean,
            parentRef: React.MutableRefObject<HTMLDivElement>,
          ) => {
            return (
              <ListItem
                key={value.value}
                handleSelect={onClick}
                title={value.title}
                selected={selected}
                parentRef={parentRef}
                disabled={
                  Number(value.value) < startDate.getDate() &&
                  date.getFullYear() <= startDate.getFullYear() &&
                  date.getMonth() <= startDate.getMonth()
                }
              />
            );
          }}
        />
        <Styled.CustomSelect
          value={date.getMonth().toString()}
          options={months}
          placeholder={months[date.getMonth()]?.title || ''}
          onValueChange={(value: string) => {
            const newDate = new Date(new Date(date).setMonth(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            onChange(newDate.getTime() > startDate.getTime() ? newDate : today);
          }}
          valueTransformer={(value: string) => {
            return months.find((item) => item.value === value)?.title || '';
          }}
          renderCustomListItem={(
            value: SelectOption,
            onClick: () => void,
            selected: boolean,
            parentRef: React.MutableRefObject<HTMLDivElement>,
          ) => {
            return (
              <ListItem
                key={value.value}
                handleSelect={onClick}
                title={value.title}
                selected={selected}
                parentRef={parentRef}
                disabled={Number(value.value) < startDate.getMonth() && date.getFullYear() <= startDate.getFullYear()}
              />
            );
          }}
        />
        <Styled.CustomSelect
          value={date.getFullYear().toString()}
          options={yearsOptions}
          size="small"
          placeholder={date.getFullYear().toString()}
          onValueChange={(value: string) => {
            const newDate = new Date(new Date(date).setFullYear(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            onChange(newDate.getTime() > startDate.getTime() ? newDate : today);
          }}
          renderCustomListItem={(
            value: SelectOption,
            onClick: () => void,
            selected: boolean,
            parentRef: React.MutableRefObject<HTMLDivElement>,
          ) => {
            return (
              <ListItem
                key={value.value}
                handleSelect={onClick}
                title={value.title}
                selected={selected}
                parentRef={parentRef}
              />
            );
          }}
        />
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.ArrowButton variant="text" onClick={decreaseYear} disabled={date.getFullYear() <= startYear}>
          <Styled.IconItem type="u_angle-right-b" size={16} isLeft disabled={date.getFullYear() <= startYear} />
        </Styled.ArrowButton>
        <Styled.ArrowButton variant="text" onClick={increaseYear} disabled={date.getFullYear() >= endYear}>
          <Styled.IconItem type="u_angle-right-b" size={16} isRight disabled={date.getFullYear() >= endYear} />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
    </Styled.CalendarHeader>
  );
};

const ListItem: React.FC<{
  handleSelect: () => void;
  title: string;
  selected: boolean;
  parentRef: React.MutableRefObject<HTMLDivElement>;
  disabled?: boolean;
}> = ({ handleSelect, title, selected, parentRef, disabled }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selected && itemRef.current && parentRef.current) {
      const topPos = itemRef.current.offsetTop;
      parentRef.current.scrollTop = topPos;
    }
  }, [selected]);

  return (
    <Styled.DropdownListItem ref={itemRef} onClick={handleSelect} selected={selected} disabled={disabled}>
      {title}
    </Styled.DropdownListItem>
  );
};

export default DateWithTimeHeader;
