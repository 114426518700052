import {
  StatusItem,
  useCalendarAPI,
  useCandidateEventsAPI,
  useCandidateOffersAPI,
  useResizeObserver,
  useStatusConfigById,
} from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { DesktopView } from './components/desktop-view';
import { MobileView } from './components/mobile-view';

interface CandidateStatusFormProps {
  photoId?: number;
  localizedFullName: string;
  isEdit?: boolean;
  candidateId: number;
  eventId?: number;
  handleClose: () => void;
  onSubmit: (data: StatusItem) => void;
}

const CandidateStatusForm: React.FC<CandidateStatusFormProps> = ({
  photoId,
  handleClose,
  isEdit,
  localizedFullName,
  onSubmit,
  eventId,
  candidateId,
}) => {
  const theme = useTheme();
  const { clearTimeSlots } = useCalendarAPI();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  const { watch, clearErrors } = useFormContext();
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);
  const { getConfigFlags } = useCandidateEventsAPI();
  const { getOffersByCandidateId } = useCandidateOffersAPI();

  const { flags, isAttachments } = useMemo(() => {
    const flags = getConfigFlags(statusConfig);
    const isAttachments = flags.isFileField || flags.isLinkField || flags.isReminderField || flags.isCalendarField;
    return {
      flags,
      isAttachments,
    };
  }, [statusConfig]);

  useEffect(() => {
    clearErrors();
  }, [statusId]);

  useEffect(() => {
    getOffersByCandidateId(candidateId);
    return () => {
      clearTimeSlots();
    };
  }, [clearTimeSlots, candidateId]);

  if (!statusConfig) return null;
  return isDesktopView ? (
    <DesktopView
      photoId={photoId}
      localizedFullName={localizedFullName}
      statusConfig={statusConfig}
      isEdit={isEdit}
      flags={flags}
      isAttachments={isAttachments}
      eventId={eventId}
    />
  ) : (
    <MobileView
      photoId={photoId}
      statusConfig={statusConfig}
      localizedFullName={localizedFullName}
      isEdit={isEdit}
      flags={flags}
      handleClose={handleClose}
      onSubmit={onSubmit}
      eventId={eventId}
    />
  );
};

export default CandidateStatusForm;
