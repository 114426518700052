import { AppRoutes } from '@constants';
import { useVacanciesAPI } from '@innowise-group/core';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Styled from './vacancy-deletion-modal.styles';

interface VacancyDeletionModalProps {
  id: number;
}

const VacancyDeletionModal: React.FC<ModalProps & VacancyDeletionModalProps> = ({ close, id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteVacancy, isLoading } = useVacanciesAPI();

  const deleteVacancyItem = () => {
    deleteVacancy(id, successCallback);
  };

  const successCallback = () => {
    close();
    navigate(AppRoutes.Vacancies, { replace: true });
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.ModalTitle>{t('pages.vacancies.deletionModal.vacancyDeletion')}</Styled.ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.vacancies.deletionModal.sureDelete')}?</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.delete'),
            onClick: deleteVacancyItem,
            highlighted: true,
            type: 'button',
            disabled: isLoading,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(VacancyDeletionModal, 'vacancy-deletion');
