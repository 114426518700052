import { createReducer } from '@reduxjs/toolkit';
import { clearFaqAction, updateContentAction } from './faq.action';
import { preloadedState } from './faq.preloaded-state';
import { getFaqThunk, updateFaqThunk } from './faq.thunk';

export const faqsReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(updateFaqThunk.pending, (state) => {
      state.updating = true;
    })
    .addCase(updateFaqThunk.fulfilled, (state, { meta }) => {
      state.updating = false;
      state.content = meta.arg;
    })
    .addCase(updateFaqThunk.rejected, (state) => {
      state.updating = false;
    })
    .addCase(getFaqThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getFaqThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.content = payload;
    })
    .addCase(getFaqThunk.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updateContentAction, (state, { payload }) => {
      state.content = payload;
    })
    .addCase(clearFaqAction, () => preloadedState),
);
