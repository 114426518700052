import { Button, Icon, Input } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const FullInput = styled(Input)`
  &.MuiTextField-root {
    width: 100%;
  }
`;

export const ButtonsSection = styled.div`
  display: flex;
  gap: 0.75rem;
  & > * {
    flex: 1;
  }
  margin-top: 1rem;
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  padding: 0.75rem 0.75rem 0 0.75rem;
  position: relative;
  margin: 1rem 0 0.75rem 0;
`;

export const MoreButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    width: min-content;
    align-self: center;
  }
`;

export const DeleteButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined,
  &.MuiButton-root.MuiButton-outlined:hover {
    position: absolute;
    min-width: unset;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.general.white};
    transform: translateY(-50%);
    top: 0;
    right: 13px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[60]};

    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
  &.MuiButton-root.MuiButton-outlined:active {
    border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[80]};
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;
