import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './professions-diff.styles';
import { useOptionsApi } from '@innowise-group/core';

const ProfessionsDiff: FC = (props) => {
  const { t } = useTranslation();
  const { professionOptions } = useOptionsApi();
  const labelTransformer: LabelTransformer<'candidateProfessions'> = (el) => {
    const currentOptionItemTitle = professionOptions.find((option) => option.value === el.profession.valueId)?.title;
    return (
      <Styled.OptionWrapper key={el.id}>
        <Styled.OptionSource>{currentOptionItemTitle}</Styled.OptionSource>
        {el.grade && (
          <>
            <b> / </b>
            <Styled.OptionDetails>{t(`options.gradeOptions_${el.grade}`)}</Styled.OptionDetails>
          </>
        )}
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase multiple fieldName="candidateProfessions" labelTransformer={labelTransformer} {...props} />;
};

export default ProfessionsDiff;
