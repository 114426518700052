import {
  CandidateSourceNeedClarificationIds,
  CurrencyEnum,
  LifecycleStatuses,
  RolesValues,
  SortingValue,
  defaultCurrency,
} from '@constants';
import { FiltersState, FiltersTypes, Tabs, DateSegments } from './filters.types';

export const preloadedState: FiltersState = {
  candidateInitialOptions: null,
  lifecycleStatus: LifecycleStatuses.Actual,
  responsible: null,
  sort: {
    candidate: SortingValue.FullNameASC,
    request: SortingValue.IdASC,
    vacancy: SortingValue.VacancyNameASC,
  },
  selected: [],
  candidate: {
    name: {
      active: true,
      pinned: true,
      id: 'name',
      labelKey: 'filters.union.searchCandidateInputPlaceholder',
      type: FiltersTypes.Text,
      state: { value: '' },
    },
    professionFilter: {
      active: true,
      pinned: true,
      id: 'professionFilter',
      type: FiltersTypes.NestedSelect,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'pages.candidates.candidateCreation.specialty',
      state: {},
    },
    birthDate: {
      active: false,
      id: 'birthDate',
      type: FiltersTypes.DateRange,
      isOpen: false,
      labelKey: 'pages.candidates.candidateCreation.dateOfBirth',
      state: {
        from: null,
        to: null,
        min: null,
        max: null,
      },
    },
    age: {
      active: false,
      id: 'age',
      type: FiltersTypes.NumberRange,
      isOpen: false,
      labelKey: 'filters.union.age',
      state: {
        initialDataLoaded: false,
        from: 0,
        to: 100,
        step: 1,
        minValue: 0,
        maxValue: 100,
      },
    },
    gender: {
      active: false,
      pinned: false,
      id: 'gender',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: true },
      labelKey: 'filters.union.gender',
      state: {},
    },
    salary: {
      active: false,
      id: 'salary',
      type: FiltersTypes.Money,
      isOpen: false,
      availableForRoles: [RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, RolesValues.Supervisor],
      labelKey: 'pages.candidates.candidateCreation.salary',
      state: {
        from: 0,
        to: 150000,
        step: 1,
        currency: defaultCurrency,
        variants: {
          [CurrencyEnum.BYN]: {
            min: 0,
            max: 150000,
            initialDataLoaded: false,
          },
          [CurrencyEnum.EUR]: {
            min: 0,
            max: 150000,
            initialDataLoaded: false,
          },
          [CurrencyEnum.PLN]: {
            min: 0,
            max: 150000,
            initialDataLoaded: false,
          },
          [CurrencyEnum.USD]: {
            min: 0,
            max: 150000,
            initialDataLoaded: false,
          },
        },
      },
    },
    overallJobExperience: {
      active: false,
      id: 'overallJobExperience',
      type: FiltersTypes.NumberRange,
      isOpen: false,
      labelKey: 'filters.union.overallJobExperience',
      state: {
        initialDataLoaded: false,
        from: 0,
        to: 100,
        step: 1,
        minValue: 0,
        maxValue: 100,
      },
    },
    itSphereJobExperience: {
      active: false,
      id: 'itSphereJobExperience',
      type: FiltersTypes.NumberRange,
      isOpen: false,
      labelKey: 'filters.union.itSphereJobExperience',
      state: {
        initialDataLoaded: false,
        from: 0,
        to: 100,
        step: 1,
        minValue: 0,
        maxValue: 100,
      },
    },
    citizenship: {
      active: false,
      id: 'citizenship',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.citizenship',
      state: {},
    },
    countries: {
      active: false,
      id: 'countries',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.countries',
      state: {},
    },
    cities: {
      active: false,
      id: 'cities',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.cities',
      state: {},
    },
    statuses: {
      active: false,
      id: 'statuses',
      type: FiltersTypes.NestedSelect,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.statuses',
      state: {},
    },
    candidateStatusDate: {
      active: false,
      id: 'candidateStatusDate',
      type: FiltersTypes.DateRange,
      isOpen: false,
      labelKey: 'filters.union.candidateStatusDate',
      state: {
        from: null,
        to: null,
        min: null,
        max: null,
      },
    },
    sourceFilter: {
      active: false,
      id: 'sourceFilter',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, commentIds: CandidateSourceNeedClarificationIds },
      labelKey: 'filters.union.sourceFilter',
      state: {},
    },
    languageFilter: {
      active: false,
      id: 'languageFilter',
      type: FiltersTypes.NestedSelect,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.languageFilter',
      state: {},
    },
    technologies: {
      active: false,
      id: 'technologies',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.technologies',
      state: {},
    },
    domains: {
      active: false,
      id: 'domains',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.domains',
      state: {},
    },
    workFormats: {
      active: false,
      id: 'workFormats',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.workFormats',
      state: {},
    },
    isBlocked: {
      active: false,
      id: 'isBlocked',
      type: FiltersTypes.Boolean,
      isOpen: false,
      labelKey: 'filters.union.isBlocked',
      state: { checked: false },
    },
    readyForBusinessTrip: {
      active: false,
      id: 'readyForBusinessTrip',
      type: FiltersTypes.Boolean,
      isOpen: false,
      labelKey: 'filters.union.readyForBusinessTrip',
      state: { checked: false },
    },
    visaTypes: {
      active: false,
      id: 'visaTypes',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.visaTypes',
      state: {},
    },
    visaAvailability: {
      active: false,
      id: 'visaAvailability',
      type: FiltersTypes.Boolean,
      isOpen: false,
      labelKey: 'filters.union.visaAvailability',
      state: { checked: false },
    },
    relocationAvailability: {
      active: false,
      id: 'relocationAvailability',
      type: FiltersTypes.Boolean,
      isOpen: false,
      labelKey: 'filters.union.relocationAvailability',
      state: { checked: false },
    },
    relocationCountries: {
      active: false,
      id: 'relocationCountries',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.relocationCountries',
      state: {},
    },
    experienceFilter: {
      active: false,
      id: 'experienceFilter',
      type: FiltersTypes.ActivityPlace,
      isOpen: false,
      labelKey: 'pages.candidates.candidateCreation.workplace',
      filterProps: {
        activityPlaceKey: 'pages.candidates.candidateCreation.workplace',
        activityPositionKey: 'pages.candidates.candidateCreation.position',
        fromKey: 'pages.candidates.candidateCreation.period',
        toKey: 'pages.candidates.candidateCreation.to',
      },
      state: { place: '', profession: '', from: null, to: null },
    },
    courseFilter: {
      active: false,
      id: 'courseFilter',
      type: FiltersTypes.ActivityPlace,
      isOpen: false,
      labelKey: 'pages.candidates.candidateCreation.courses',
      filterProps: {
        activityPlaceKey: 'pages.candidates.candidateCreation.name',
        activityPositionKey: 'pages.candidates.candidateCreation.period',
        fromKey: 'pages.candidates.candidateCreation.period',
        toKey: 'pages.candidates.candidateCreation.to',
      },
      state: { place: '', profession: '', from: null, to: null },
    },
    educationFilter: {
      active: false,
      id: 'educationFilter',
      type: FiltersTypes.ActivityPlace,
      isOpen: false,
      labelKey: 'pages.candidates.candidateCreation.education',
      filterProps: {
        activityPlaceKey: 'pages.candidates.candidateCreation.studyPlace',
        activityPositionKey: 'pages.candidates.candidateCreation.specialty',
        fromKey: 'pages.candidates.candidateCreation.period',
        toKey: 'pages.candidates.candidateCreation.to',
      },
      state: { place: '', profession: '', from: null, to: null },
    },
    employe: {
      onlyActual: false,
      active: false,
      id: 'responsible',
      type: FiltersTypes.Employee,
      isOpen: false,
      labelKey: 'filters.union.candidateResponsibleEmployee',
      state: [],
    },
  },
  request: {
    vacancyIds: {
      active: true,
      pinned: true,
      id: 'vacancy',
      type: FiltersTypes.Vacancy,
      isOpen: true,
      labelKey: 'filters.union.vacancyIds',
      state: [],
    },
    structuralUnitId: {
      active: true,
      pinned: true,
      id: 'structuralUnitId',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: true },
      labelKey: 'filters.union.structuralUnitId',
      state: {},
    },
    hiringPurpose: {
      active: true,
      pinned: true,
      id: 'hiringPurpose',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: true },
      labelKey: 'filters.union.hiringPurpose',
      state: {},
    },
    searchBarValue: {
      active: false,
      pinned: false,
      id: 'name',
      filterProps: {
        placeholder: 'filters.union.searchRequestInputPlaceholder',
      },
      labelKey: 'filters.union.searchRequestInputLabel',
      type: FiltersTypes.UnpinnedSearch,
      state: { value: '' },
    },
    employe: {
      active: false,
      pinned: false,
      onlyActual: false,
      id: 'employe',
      type: FiltersTypes.Employee,
      isOpen: false,
      labelKey: 'pages.requests.allRequestsTable.author',
      state: [],
    },
    countries: {
      active: false,
      id: 'countries',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.locationIds',
      state: {},
    },
    officeIds: {
      active: false,
      id: 'officeIds',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.officeIds',
      state: {},
    },
    workload: {
      active: false,
      id: 'workload',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: true },
      labelKey: 'filters.union.workloadId',
      state: {},
    },
    workFormats: {
      active: false,
      id: 'workFormats',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.workLoads',
      state: {},
    },
    employmentForms: {
      active: false,
      id: 'employmentForms',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.employmentForms',
      state: {},
    },
    hiringDeadline: {
      active: false,
      id: 'hiringDeadline',
      type: FiltersTypes.DateSegment,
      isOpen: false,
      labelKey: 'filters.union.date',
      state: {
        date: null,
        segments: [DateSegments.Exact, DateSegments.Since, DateSegments.Until],
        currentSegment: DateSegments.Exact,
      },
    },
    statuses: {
      active: false,
      id: 'statuses',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.statusIds',
      state: {},
    },
  },
  vacancy: {
    searchBarRequest: {
      active: true,
      pinned: true,
      id: 'name',
      labelKey: 'filters.union.searchVacancyInputPlaceholder',
      type: FiltersTypes.Text,
      state: { value: '' },
    },
    professionFilter: {
      active: true,
      pinned: true,
      id: 'professionFilter',
      type: FiltersTypes.NestedSelect,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'pages.candidates.candidateCreation.specialty',
      state: {},
    },
    statusIds: {
      active: false,
      id: 'statusIds',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: false },
      labelKey: 'filters.union.statusIds',
      state: {},
    },
    technologyIds: {
      active: false,
      id: 'technologyIds',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: false },
      labelKey: 'filters.union.technologies',
      state: {},
    },
    domainIds: {
      active: false,
      id: 'domainIds',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true, singleSelect: false },
      labelKey: 'filters.union.domains',
      state: {},
    },
    languageFilter: {
      active: false,
      id: 'languageFilter',
      type: FiltersTypes.NestedSelect,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.languageFilter',
      state: {},
    },
    countries: {
      active: false,
      id: 'countries',
      type: FiltersTypes.Select,
      isOpen: false,
      filterProps: { search: true },
      labelKey: 'filters.union.locationIds',
      state: {},
    },
    employe: {
      active: false,
      id: 'employe',
      onlyActual: false,
      type: FiltersTypes.Employee,
      isOpen: false,
      labelKey: 'filters.union.vacancyAuthor',
      state: [],
    },
    published: {
      active: false,
      id: 'published',
      type: FiltersTypes.Boolean,
      isOpen: false,
      labelKey: 'filters.union.published',
      state: { checked: false },
    },
  },
  isLoading: false,
  candidateOptions: null,
  pagable: {
    currentPage: 1,
    pageSizes: [
      { title: '10', value: '10' },
      { title: '20', value: '20' },
      { title: '50', value: '50' },
    ],
    pageSize: 10,
  },
  currentTab: Tabs.All,
};
