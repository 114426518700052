import { SearchState } from './search.types';

export const preloadedState: SearchState = {
  candidatesSearchContent: [],
  vacanciesSearchContent: [],
  requestsSearchContent: [],
  loading: false,
  isLastPage: false,
  activeXrayMode: false,
  searchHistory: {
    entities: [],
    queries: [],
    xrayQuery: [],
  },
};
