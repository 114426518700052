import { styled } from '@innowise-group/mui-kit';
import { Select as BaseSelect } from '@innowise-group/mui-kit';
import { InfoTooltip as BaseInfoTooltip } from '../../../info-tooltip';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled.div``;

export const Select = styled(BaseSelect)`
  width: 100%;
`;

export const InfoTooltip = styled(BaseInfoTooltip)`
  margin: 0 0 3px 5px;
`;
