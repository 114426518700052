import { AppLanguages, DateFormats } from '@constants';
import { MeetingItemResponse, MeetingsListItem, TimeSlotItem } from '@innowise-group/core';
import { calculateDateWithTimeZone, getLanguageFromLS } from '@innowise-group/utilities';
import { format } from 'date-fns';

export const meetingsListItemMapper = (arr: MeetingsListItem[]) => {
  const lang = getLanguageFromLS();
  const items = arr.reduce<{ [key: string]: MeetingItemResponse[] }>((acc, val) => {
    const meetings = val.meetingResponses.map((item) => {
      const intersections = val.meetingResponses.filter((meet) => {
        return meet.startDate < item.endDate && item.startDate < meet.endDate;
      });
      const order =
        intersections.findIndex((meet) => (item.eventId || item.externalId) === (meet.eventId || meet.externalId)) + 1;
      return {
        ...item,
        endDate: format(new Date(calculateDateWithTimeZone(item.endDate)), DateFormats.WithTime),
        startDate: format(new Date(calculateDateWithTimeZone(item.startDate)), DateFormats.WithTime),
        localizedTitle: lang === AppLanguages.En ? item.titleEn : item.titleRu,
        intersectionsNumber: intersections.length,
        order,
      };
    });
    return {
      ...acc,
      [format(new Date(val.date), DateFormats.MonthFirst)]: meetings,
    };
  }, {});
  const ids = arr.map((item) => format(new Date(item.date), DateFormats.MonthFirst));
  return { ids, items };
};

export const timeSlotsItemMapper = (arr: TimeSlotItem[]) => {
  return arr.map(({ from, to }) => {
    return {
      from: format(new Date(calculateDateWithTimeZone(from)), DateFormats.ISO),
      to: format(new Date(calculateDateWithTimeZone(to)), DateFormats.ISO),
    };
  });
};
