import { styled } from '@innowise-group/mui-kit';

export const Container = styled.div`
  max-height: 100%;
  overflow-y: auto;

  & .docx-wrapper > section.docx {
    width: auto !important;
  }
`;
