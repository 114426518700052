import { createAction } from '@reduxjs/toolkit';

export const clearOverallStatusesAction = createAction('statistic/clearOverallStatuses');
export const clearStatusesByVacanciesAction = createAction('statistic/clearStatusesByVacancies');
export const clearOverallTeamStatisticAction = createAction('statistic/clearOverallTeamStatistic');
export const clearHorizontalTeamStatisticByVacancyAction = createAction(
  'statistic/clearHorizontalTeamStatisticByVacancy',
);
export const clearVerticalTeamStatisticByVacancyAction = createAction('statistic/clearVerticalTeamStatisticByVacancy');
export const clearPersonalStatisticAction = createAction('statistic/clearPersonalStatistic');
export const clearRequestStatisticDataAction = createAction('statistic/clearRequestStatistic');
export const setFiltersStateAction = createAction<object>('statistic/filtersStateAction');
export const clearFiltersStateAction = createAction('statistic/clearFiltersStateAction');
export const clearSummaryStatisticAction = createAction('statistic/clearSummaryStatisticAction');
export const clearExitsStatisticsAction = createAction('statistic/clearExitsStatisticsAction');
