import { styled } from '@innowise-group/mui-kit';
import { Icon } from '../../../icon';
import { Typography } from '../../../typography';

export const ChipContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[100]};
  border-radius: 10px;
  padding: 5px 7px;
  margin: 3px;
  ${({ theme }) => theme.fonts.primary[10]}
  opacity: ${({ disabled }) => disabled && '0.6'};
`;
export const IconContainer = styled(Icon)`
  margin: 0;
  margin-right: -3px;
  padding-top: 2px;
`;

export const Title = styled(Typography)`
  max-width: 140px;
  white-space: pre-wrap;
`;
