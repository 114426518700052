import { createAsyncThunk } from '@reduxjs/toolkit';
import { CandidateOptions, CandidatesService, MyKnownError, StatusesService } from '@innowise-group/core';
import { statusesMapper } from './filters.mappers';

export const getCandidatesStatusesSelectOptionsThunk = createAsyncThunk<
  CandidateOptions['statusOptions'],
  void,
  { rejectValue: MyKnownError }
>('filters/getCandidatesStatusesSelectOptions', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await StatusesService.getStatusList().then(statusesMapper);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getInitialCandidatesFilterThunk = createAsyncThunk<
  Awaited<ReturnType<typeof CandidatesService.getInitialCandidatesFilter>>['data'],
  Parameters<typeof CandidatesService.getInitialCandidatesFilter>[0],
  { rejectValue: MyKnownError }
>('filters/getInitialCandidatesFilter', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await CandidatesService.getInitialCandidatesFilter(arg);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
