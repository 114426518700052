import { Button, Icon, styled } from '@innowise-group/mui-kit';

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
`;

export const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  & > * {
    width: auto;
  }
`;

export const FilterIcon = styled(Icon)`
  margin-right: 4px;
  & > svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]} !important;
  }
`;

export const GrayButton = styled(Button)`
  position: relative;
  &.MuiButton-root.MuiButton-text {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    min-width: min-content;
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      & svg {
        fill: ${({ theme }) => theme.palette.general.darkGrey[80]} !important;
      }
    }
  }
`;

export const SelectIcon = styled(Icon)<{ isOpen: boolean }>`
  margin: 0;
  padding: 0;
  & svg {
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]} !important;
  }
`;

export const CurrentTab = styled(GrayButton)`
  &.MuiButton-root.MuiButton-text {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    ${({ theme }) => theme.fonts.primary[80]}
  }
`;
