import { CandidateStatusGroupEnum, FilesAcceptFormats, FilesFormats, MaxFileSize, RolesValues } from '@constants';
import { useModal, Icon, useTheme } from '@innowise-group/mui-kit';
import React, { useEffect, useRef } from 'react';
import * as Styled from './file-section.styles';
import { FileAccessModal, FilePreviewModal } from '@innowise-group/modals';
import { CandidateStatusListItem, useCandidateFilesAPI } from '@innowise-group/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notificationsManager } from '@innowise-group/mui-kit';
import { RoleGuard } from '@shared-components';

interface FileSectionProps {
  isFeedback: boolean;
  statusConfig: CandidateStatusListItem;
}

const FileSection: React.FC<FileSectionProps> = ({ isFeedback, statusConfig }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showFilePreview] = useModal(FilePreviewModal);
  const [showFileAccessModal, closeFileAccessModal] = useModal(FileAccessModal);
  const { createFileId } = useCandidateFilesAPI();
  const statusId = watch('statusId');
  const file = watch('file');

  const isOffer = statusConfig?.group === CandidateStatusGroupEnum.OFFER;

  const handleDeleteFile =
    (onChange: (fileId: number | null) => void) => async (event: React.MouseEvent<HTMLOrSVGElement>) => {
      event.stopPropagation();
      onChange(undefined);
    };

  const handleOpenFile = () =>
    showFilePreview({
      fileName: file.filename,
      id: file.id,
    });

  const handleAddStrictAccessFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const file = await createFileId(event.target.files[0], true, closeModal, true);
      setValue('file', file);
    } catch {
      setValue('file', undefined);
      closeModal();
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const file = await createFileId(event.target.files[0], false, closeModal, true);
      setValue('file', file);
    } catch {
      setValue('file', undefined);
      closeModal();
    }
  };

  const closeModal = () => {
    closeFileAccessModal();
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const openFileAccessModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const fileType = event.target.files[0].type || event.target.files[0].name.split('.').reverse()[0];
      if (event.target.files[0] && event.target.files[0].size <= MaxFileSize && FilesFormats.includes(fileType)) {
        showFileAccessModal({ successCallback: handleAddStrictAccessFile(event), closeCallback: handleAddFile(event) });
      } else {
        notificationsManager.error({
          subtitle: t(
            `notifications.text.${
              !(event.target.files[0]?.size <= MaxFileSize) ? 'invalidFileSize' : 'invalidFileFormat'
            }`,
          ),
        });
        fileInputRef.current.value = null;
      }
    }
  };

  const handleClickFile = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, [setValue, statusId]);

  return (
    <Controller
      name="file"
      control={control}
      render={({ field: { onChange } }) => (
        <React.Fragment>
          {file ? (
            <Styled.FileItem onClick={handleOpenFile}>
              {file.secured && (
                <Styled.SecureFileIconContainer>
                  <Icon type="u_lock_alt" />
                </Styled.SecureFileIconContainer>
              )}
              <span>{file.filename}</span>
              <RoleGuard roles={[RolesValues.Admin]}>
                {!isOffer && (
                  <Styled.FileButtonContainer>
                    <Icon type="u_plus" onClick={handleDeleteFile(onChange)} />
                  </Styled.FileButtonContainer>
                )}
              </RoleGuard>
            </Styled.FileItem>
          ) : (
            !isFeedback && (
              <Styled.AddButton onClick={handleClickFile} variant="text" required={!!errors?.file?.['id']?.message}>
                <Icon type="u_plus" fill={theme.palette.state.error.pressed} />
                {t('pages.candidates.candidateCreation.addFile')}
              </Styled.AddButton>
            )
          )}
          <Styled.FileInput
            type="file"
            ref={fileInputRef}
            accept={FilesAcceptFormats.join(', ')}
            onChange={openFileAccessModal}
          />
        </React.Fragment>
      )}
    />
  );
};

export default FileSection;
