import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  CurrencyEnum,
  MAX_CANDIDATE_SALARY_BYN,
  MAX_CANDIDATE_SALARY_EUR,
  MAX_CANDIDATE_SALARY_PLN,
  MAX_CANDIDATE_SALARY_USD,
  MIN_CANDIDATE_SALARY,
} from '@constants';

const MAX_COMMENT_LENGTH = 300;
const MAX_PROJECT_LENGTH = 50;
const MAX_PROBATION_LENGTH = 50;

export const useValidationSchemaOffer = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    const minDate = new Date();
    minDate.setHours(23, 59, 59);
    minDate.setDate(minDate.getDate() - 1);
    return Yup.object().shape({
      offerType: Yup.string().required(t('errors.requiredField')),
      offerDate: Yup.date().required(t('errors.requiredField')),
      structuralUnitId: Yup.string(),
      position: Yup.string().required(t('errors.requiredField')),
      currency: Yup.string().required(t('errors.requiredField')),
      salaryGross: Yup.string()
        .trim()
        .transform((value) => value || '')
        .matches(/(^$)|(^\d+$)/, t('errors.onlyNumbers'))
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.PLN,
          then: (schema) =>
            schema.when('salaryGross', {
              is: (salaryGross: string) => salaryGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.BYN,
          then: (schema) =>
            schema.when('salaryGross', {
              is: (salaryGross: string) => salaryGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /^100000$/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.USD,
          then: (schema) =>
            schema.when('salaryGross', {
              is: (salaryGross: string) => salaryGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.EUR,
          then: (schema) =>
            schema.when('salaryGross', {
              is: (salaryGross: string) => salaryGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
            }),
        }),
      salaryProbationGross: Yup.string()
        .trim()
        .transform((value) => value || '')
        .matches(/(^$)|(^\d+$)/, t('errors.onlyNumbers'))
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.PLN,
          then: (schema) =>
            schema.when('salaryProbationGross', {
              is: (salaryProbationGross: string) => salaryProbationGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.BYN,
          then: (schema) =>
            schema.when('salaryProbationGross', {
              is: (salaryProbationGross: string) => salaryProbationGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /^100000$/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.USD,
          then: (schema) =>
            schema.when('salaryProbationGross', {
              is: (salaryProbationGross: string) => salaryProbationGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.EUR,
          then: (schema) =>
            schema.when('salaryProbationGross', {
              is: (salaryProbationGross: string) => salaryProbationGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
            }),
        }),
      salaryProjectGross: Yup.string()
        .trim()
        .transform((value) => value || '')
        .matches(/(^$)|(^\d+$)/, t('errors.onlyNumbers'))
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.PLN,
          then: (schema) =>
            schema.when('salaryProjectGross', {
              is: (salaryProjectGross: string) => salaryProjectGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.BYN,
          then: (schema) =>
            schema.when('salaryProjectGross', {
              is: (salaryProjectGross: string) => salaryProjectGross?.length === 6,
              then: (schema) =>
                schema.matches(
                  /^100000$/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.USD,
          then: (schema) =>
            schema.when('salaryProjectGross', {
              is: (salaryProjectGross: string) => salaryProjectGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                ),
            }),
        })
        .when(['currency'], {
          is: (currency: string) => currency === CurrencyEnum.EUR,
          then: (schema) =>
            schema.when('salaryProjectGross', {
              is: (salaryProjectGross: string) => salaryProjectGross?.length === 5,
              then: (schema) =>
                schema.matches(
                  /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
              otherwise: (schema) =>
                schema.matches(
                  /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                  t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                ),
            }),
        }),
      estimatedReleaseDate: Yup.date()
        .transform((val) => (!val ? null : val))
        .min(minDate, t('errors.earlierTodayDate'))
        .nullable(),
      probationPeriod: Yup.string()
        .trim()
        .max(MAX_PROBATION_LENGTH, t('errors.maxCharacters', { count: MAX_PROBATION_LENGTH })),
      responseDate: Yup.date()
        .transform((val) => (!val ? null : val))
        .min(minDate, t('errors.earlierTodayDate'))
        .nullable(),
      city: Yup.string(),
      country: Yup.string(),
      office: Yup.string(),
      project: Yup.string()
        .trim()
        .max(MAX_PROJECT_LENGTH, t('errors.maxCharacters', { count: MAX_PROJECT_LENGTH })),
      specialWorkingConditions: Yup.string()
        .trim()
        .max(MAX_COMMENT_LENGTH, t('errors.maxCharacters', { count: MAX_COMMENT_LENGTH })),
      fileId: Yup.number().required().min(1),
    });
  }, [t]);

  return { validationSchema };
};
