import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import { ProfileItemResponse } from '@innowise-group/core';

export default class ProfileService {
  static async getProfile(): Promise<AxiosResponse<ProfileItemResponse>> {
    return $api.get<ProfileItemResponse>(`/recruiting-tool/api/v1/employees/profile`);
  }

  static async updateProfile(data: object, id: number): Promise<AxiosResponse<ProfileItemResponse>> {
    return $api.put(`/recruiting-tool/api/v1/employees/${id}`, data);
  }
}
