import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@innowise-group/mui-kit';
import { Tooltip } from '../../../tooltip';
import * as Styled from './priority-chart.styles';

interface PriorityChartProps {
  profession: number;
  language: number;
  technology: number;
  domain: number;
  location: number;
  isManuallyPrioritized: boolean;
  total: number;
}

const PriorityChart: FC<PriorityChartProps> = ({
  total,
  domain,
  language,
  location,
  technology,
  profession,
  isManuallyPrioritized,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        <Styled.TooltipWrapper>
          <Trans
            i18nKey="tooltips.specialization"
            values={{ value: profession }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.CriteriaWrapper />,
            }}
          />
          <Trans
            i18nKey="tooltips.languages"
            values={{ value: language }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.CriteriaWrapper />,
            }}
          />
          <Trans
            i18nKey="tooltips.technologies"
            values={{ value: technology }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.CriteriaWrapper />,
            }}
          />
          <Trans
            i18nKey="tooltips.domains"
            values={{ value: domain }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.CriteriaWrapper />,
            }}
          />
          <Trans
            i18nKey="tooltips.location"
            values={{ value: location }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.CriteriaWrapper />,
            }}
          />
          <Trans
            i18nKey="tooltips.total"
            values={{ value: total }}
            components={{
              label: <Styled.CriteriaLabel />,
              wrapper: <Styled.TotalCriteriaWrapper />,
            }}
          />
          {isManuallyPrioritized && (
            <Styled.TotalCriteriaWrapper>
              {t('tooltips.isManuallyPrioritized')}
              <Icon type="u_success_rounder_filled" />
            </Styled.TotalCriteriaWrapper>
          )}
        </Styled.TooltipWrapper>
      }
      placement="top-start"
    >
      <Styled.RatingWrapper>
        <Styled.RatingChart $value={total} $isManuallyPrioritized={isManuallyPrioritized}>
          <Styled.RatingIcon
            type={isManuallyPrioritized ? 'u_like' : 'u_percentage'}
            size={10}
            viewBox={isManuallyPrioritized ? '0 0 10 10' : '0 0 5 8'}
          />
          {Math.round(total)}
        </Styled.RatingChart>
      </Styled.RatingWrapper>
    </Tooltip>
  );
};

export default PriorityChart;
