import { NotificationType } from '@innowise-group/mui-kit';

export const getNotificationIconByType = (type: NotificationType) => {
  switch (type) {
    case 'error':
      return 'u_exclamation-triangle';
    case 'warning':
      return 'u_info-circle';
    default:
      return 'u_check';
  }
};
