import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './information-modal.styles';

interface InformationModalProps {
  title: string;
  description: string | ReactElement;
  handleClose?: () => void;
}

const InformationModal: React.FC<ModalProps & InformationModalProps> = ({ close, title, description, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{title}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.BodyContainer>{description}</Styled.BodyContainer>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'center'}
        actions={[
          {
            title: t('buttons.ok'),
            onClick: handleClose || close,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(InformationModal, 'information-modal');
