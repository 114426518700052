import { styled } from '@innowise-group/mui-kit';
import { Stepper as MuiStepper, Step as MuiStep, StepLabel as MuiStepLabel } from '@mui/material';

export const Stepper = styled(MuiStepper)`
  & .MuiStepConnector-root.Mui-active .MuiStepConnector-line,
  & .MuiStepConnector-root.Mui-completed .MuiStepConnector-line {
    background-color: ${({ theme }) => theme.palette.state.success.default};
  }
`;

export const Step = styled(MuiStep)``;

export const StepLabel = styled(MuiStepLabel)`
  & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
    ${({ theme }) => theme.fonts.primary[30]}
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;

export const StepIcon = styled.div<{ completed?: boolean; active?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.fonts.primary[50]}
  color: ${({ theme, completed, active }) =>
    active || completed ? theme.palette.general.white : theme.palette.general.darkGrey[60]};
  border-radius: 50%;
  border: 1px solid
    ${({ theme, completed }) => (!completed ? theme.palette.general.darkGrey[60] : theme.palette.state.success.default)};
  background-color: ${({ theme, completed, active }) =>
    active ? theme.palette.general.darkGrey[60] : completed ? theme.palette.state.success.default : 'transparent'};
`;
