import { styled } from '@innowise-group/mui-kit';

export const GAP_VALUE = 5;
export const ACTIVE_BORDER_WIDTH_VALUE = 1;
export const INACTIVE_BORDER_WIDTH_VALUE = 1;

export const Wrapper = styled.div<{ $expanded: boolean }>`
  position: fixed;
  bottom: 50px;
  height: 50px;
  width: ${({ $expanded }) => ($expanded ? '80vw' : '70px')};
`;

export const AnimationWrapper = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  width: ${({ $expanded }) => ($expanded ? 'fit-content' : '70px')};
  max-width: 80vw;
  height: 100%;
  background: ${({ theme }) => theme.palette.general.paper};
  padding: 0 5px;
  box-shadow: 0 0 5px 1px ${({ theme }) => `${theme.palette.general.darkGrey[60]}80`};
  transition: width 0.1s;
  border-radius: 0 25px 25px 0;
`;

export const Label = styled.span`
  ${({ theme }) => theme.fonts.primary[30]}
  & .hightLighted {
    color: ${({ theme }) => theme.palette.general.smart};
  }
`;

export const PageButtonsContainer = styled.div`
  scroll-behavior: smooth;
  width: 100%;
  display: flex;
  gap: ${GAP_VALUE}px;
  flex-direction: row;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  border-radius: 25px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PageButton = styled.button<{ $current: boolean }>`
  min-width: 35px;
  height: 35px;
  padding: 0 0.5rem;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ $current, theme }) => ($current ? theme.palette.general.smart : theme.palette.general.darkGrey[40])};
  box-shadow: 0 0 1px 1px ${({ $current, theme }) => ($current ? theme.palette.general.smart : 'transparent')};
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  background: none;
`;
