import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import { StatisticBodyItem } from '@innowise-group/core';
import { ReportTypeUnion } from '@modules';

const MAX_SIZE = 2147483647;

export default class StatisticService {
  static async getRequestStatistics(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/vacancy/claims`, args);
  }

  static async getOverallStatuses(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/event`, args);
  }

  static async exportOverallStatuses(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/event/export`, args, {
      responseType: 'blob',
    });
  }

  static async exportRequestsData(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/vacancy/claims/export`, args, {
      responseType: 'blob',
    });
  }

  static async exportExitsData(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/job-entry/export`, args, {
      responseType: 'blob',
    });
  }

  static async exportSummaryData(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/job-entry-grouped/export`, args, {
      responseType: 'blob',
    });
  }

  static async exportTeamWorking(args: StatisticBodyItem, tableType: ReportTypeUnion): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/team/export`, args, {
      responseType: 'blob',
      params: { tableType },
    });
  }

  static async getStatusesByVacancies(args: StatisticBodyItem, page?: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/event/vacancy`, args, {
      params: { page, size: page ? 100 : MAX_SIZE },
    });
  }

  static async getOverallTeamStatistic(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/team`, args);
  }

  static async getVerticalTeamStatisticByVacancy(args: StatisticBodyItem, page?: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/team/vacancy/vertical`, args, {
      params: { page, size: page ? 100 : MAX_SIZE },
    });
  }

  static async getHorizontalTeamStatisticByVacancy(args: StatisticBodyItem, page?: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/team/vacancy/horizontal`, args, {
      params: { page, size: page ? 100 : MAX_SIZE },
    });
  }

  static async getPersonalStatistic(args: StatisticBodyItem, page?: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/event/vacancy/personal`, args, {
      params: { page, size: page ? 100 : MAX_SIZE },
    });
  }

  static async getPersonalOverallStatistic(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/event/vacancy/overall`, args);
  }

  static async getSummaryStatistic(args: StatisticBodyItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/job-entry-grouped`, args);
  }

  static async getExitsStatistic(args: StatisticBodyItem, page?: number): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/statistics/job-entry`, args, {
      params: { page, size: page ? 100 : MAX_SIZE },
    });
  }
}
