import React from 'react';
import * as Styled from './vacancy-list-item.styles';
import { useTranslation } from 'react-i18next';
import { VacancyItemResponse } from '@innowise-group/core';

const VacancyListItem: React.FC<VacancyItemResponse> = ({
  countries,
  cities,
  vacancyProfessions,
  vacancyStatus,
  vacancyLanguages,
  vacancyTechnologies,
  vacancyDomains,
  name,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.ListItemText>
      {name || '-'}
      <Styled.VerticalDivider />
      <Styled.OptionalInfo>
        {!!countries?.length && (
          <React.Fragment>
            {countries.map((item, idx) => (
              <span key={item.id}>
                {item.name}
                {cities
                  .filter((curr) => curr.countryId === item.id)
                  .map(
                    (curr, index, arr) => `${!index ? ' (' : ', '}${curr.name}${index === arr.length - 1 ? ')' : ''}`,
                  )}
                {idx !== countries.length - 1 ? ', ' : ''}
              </span>
            ))}
            <Styled.VerticalDivider />
          </React.Fragment>
        )}
        {!!vacancyProfessions?.length && (
          <React.Fragment>
            {vacancyProfessions.map((elem, idx) => (
              <span key={`${elem.profession.valueId}_${elem.grade || ''}`}>
                {elem.profession?.translation && elem.grade
                  ? `${elem.profession.translation} (${t(`options.gradeOptions_${elem.grade}`)})`
                  : elem.profession?.translation
                  ? `${elem.profession.translation}`
                  : ''}
                {idx !== vacancyProfessions.length - 1 ? ', ' : ''}
              </span>
            ))}
            <Styled.VerticalDivider />
          </React.Fragment>
        )}
        {!!vacancyLanguages?.length && (
          <React.Fragment>
            {vacancyLanguages.map((elem, idx) => (
              <span key={`${elem.language}_${elem.languageLevel}`}>
                {`${t(`options.languageOptions_${elem.language}`)}${
                  elem.languageLevel && ' (' + t(`options.languageLevelOptions_${elem.languageLevel}`) + ')'
                }`}
                {idx !== vacancyLanguages.length - 1 ? ', ' : ''}
              </span>
            ))}
            <Styled.VerticalDivider />
          </React.Fragment>
        )}
        {!!vacancyTechnologies?.length && (
          <React.Fragment>
            {vacancyTechnologies.map((item, idx) => (
              <span key={item.technology?.valueId}>
                {item.technology?.translation}
                {idx !== vacancyTechnologies.length - 1 ? ', ' : ''}
              </span>
            ))}
            <Styled.VerticalDivider />
          </React.Fragment>
        )}
        {!!vacancyDomains?.length && (
          <React.Fragment>
            {vacancyDomains.map((item, idx) => (
              <span key={item.dictionaryValue?.valueId}>
                {item.dictionaryValue?.translation}
                {idx !== vacancyDomains.length - 1 ? ', ' : ''}
              </span>
            ))}
            <Styled.VerticalDivider />
          </React.Fragment>
        )}
        {!!vacancyStatus.valueId && <span>{vacancyStatus.translation}</span>}
      </Styled.OptionalInfo>
    </Styled.ListItemText>
  );
};

export default VacancyListItem;
