import { FC } from 'react';
import { LabelTransformer, DiffBase } from '../diff-base';
import * as Styled from './salary-diff.styles';
import { useTranslation } from 'react-i18next';

const SalaryDiff: FC = (props) => {
  const labelTransformer: LabelTransformer<'candidateSalary'> = (el) => {
    const { t } = useTranslation();
    return (
      <Styled.OptionWrapper key={el.id}>
        <Styled.SalaryValue>
          {el.expectedSalaryMin || el.expectedSalaryMax
            ? `${el.expectedSalaryMin} - ${el.expectedSalaryMax} (${el.currency})`
            : `${t('buttons.leaveBlank')} (${el.currency})`}
        </Styled.SalaryValue>
        <Styled.SalaryComment> / {el.expectedSalaryComment}</Styled.SalaryComment>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase fieldName="candidateSalary" labelTransformer={labelTransformer} {...props} />;
};

export default SalaryDiff;
