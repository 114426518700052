import { styled, Button, Loader } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: end;
  padding: 10px 2.5rem 2.5rem;
`;

export const GrayButton = styled(Button)<{ error?: boolean }>`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[60])};
    border-color: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[60])};
    transition: 0.2s;
    & svg {
      transition: 0.2s;
      fill: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[60])};
    }
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[80])};
    border-color: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[80])};
    & svg {
      fill: ${({ theme, error }) => (error ? theme.palette.general.smart : theme.palette.general.darkGrey[80])};
    }
  }
`;

export const FileButton = styled(GrayButton)`
  &.MuiButton-root.MuiButton-outlined {
    margin-right: auto;
  }
`;

export const FileItem = styled.div`
  position: relative;
  align-self: center;
  cursor: pointer;
  ${({ theme }) => theme.fonts.primary[60]};
  line-height: 1;
  padding: 0.875rem 22px;
  margin-right: auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  justify-content: center;
  max-width: 100%;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[60]};
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SecureFileIconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;

export const FileItemLoader = styled(Loader)`
  margin: 1px 7px;
`;

export const ButtonLoader = styled(Loader)`
  margin: 1px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  position: absolute;
  right: 85px;
  top: -2px;
  & button {
    width: fit-content;
    padding: 0;
    & > div {
      margin: 0;
    }
  }
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.state.error.default};
    & svg {
      fill: ${({ theme }) => theme.palette.state.error.default};
    }
  }
`;

export const FileButtonContainer = styled(ButtonContainer)`
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  & svg {
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;
