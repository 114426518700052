import { styled } from '@innowise-group/mui-kit';
import { CircularProgress as Progress } from '@mui/material';

export const CircularProgress = styled(Progress)`
  &.MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.general.smart};
    position: absolute;
    top: 0;
    left: 0;
    width: 2.75rem !important;
    height: 2.75rem !important;
    transform: translate(-50%, -50%);
    z-index: 2;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 2.25rem !important;
      height: 2.25rem !important;
    }
  }
`;

export const CircularProgressBG = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.general.lightGrey[60]};
    z-index: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
`;

export const ProgressValue = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[60]};
  line-height: 1;
`;
