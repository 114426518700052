import { FC } from 'react';
import { TooltipProps } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';

export const Tooltip: FC<TooltipProps> = ({ className, ...props }) => {
  return (
    <MuiTooltip
      {...props}
      classes={{ popper: className }}
      disableFocusListener
      enterTouchDelay={10}
      leaveTouchDelay={2500}
    />
  );
};
