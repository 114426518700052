import React, { useEffect, useMemo, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { RenderCurrentScaleProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import * as Styled from './pdf-viewer.styles';
import { RolesValues } from '@constants';
import { RoleGuard } from '@shared-components';

interface PDFViewerProps {
  currentFile: string;
  fileName: string;
  close: () => void;
  insideModal?: boolean;
}

const scaleStep = 0.1;
const maxScale = 2;
const minScale = 0.2;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewer: React.FC<PDFViewerProps> = ({ currentFile, fileName, insideModal, close }) => {
  const zoomPluginInstance = zoomPlugin({ enableShortcuts: true });
  const { CurrentScale, zoomTo } = zoomPluginInstance;
  const [scale, setScale] = useState<number>(0.7);

  const handlePdfScale = (type: 'in' | 'out') => () => {
    setScale((prev) => {
      if (type === 'in') {
        return Number((prev + scaleStep).toFixed(1)) <= maxScale ? Number((prev + scaleStep).toFixed(1)) : prev;
      } else {
        return Number((prev - scaleStep).toFixed(1)) >= minScale ? Number((prev - scaleStep).toFixed(1)) : prev;
      }
    });
  };

  const { zoomInDisable, zoomOutDisable } = useMemo(() => {
    return { zoomInDisable: scale === maxScale, zoomOutDisable: scale === minScale };
  }, [scale]);

  useEffect(() => {
    zoomTo(scale);
  }, [scale]);

  return (
    <Styled.Modal insideModal={insideModal}>
      <Styled.ModalActions>
        <Styled.ActionItem onClick={close}>
          <Styled.CustomIcon type="u_multiply" />
        </Styled.ActionItem>
        <RoleGuard
          roles={[
            RolesValues.Admin,
            RolesValues.Recruiter,
            RolesValues.Sourcer,
            RolesValues.ManagerFirstLevel,
            RolesValues.ManagerThirdLevel,
          ]}
        >
          <Styled.ActionItem href={currentFile} download={fileName}>
            <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
          </Styled.ActionItem>
        </RoleGuard>
      </Styled.ModalActions>
      <Styled.ZoomButtonsContainer>
        <Styled.ZoomIcon type="u_zoom_out" onClick={handlePdfScale('out')} disable={zoomOutDisable} />
        <CurrentScale>{(props: RenderCurrentScaleProps) => <>{`${Math.round(props.scale * 100)}%`}</>}</CurrentScale>
        <Styled.ZoomIcon type="u_zoom_in" onClick={handlePdfScale('in')} disable={zoomInDisable} />
      </Styled.ZoomButtonsContainer>
      <Styled.PDFViewerContainer>
        <Viewer fileUrl={currentFile} plugins={[zoomPluginInstance]} />
      </Styled.PDFViewerContainer>
    </Styled.Modal>
  );
};

export default PDFViewer;
