import { AnimationWrapper } from '../shared';
import { Button, styled, DualSlider } from '@innowise-group/mui-kit';

export const Container = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin: -3px 0 -3px 5px;
`;

export const FilterWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  background: ${({ theme }) => theme.palette.general.paper};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ControlsContainer = styled.div`
  padding: 15px 15px 0 15px;
`;

export const RangePrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

export const RangePriceField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RangePriceLabel = styled.label`
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const RangePriceInput = styled.input`
  ${({ theme }) => theme.fonts.primary[20]}
  width: 150px;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CustomButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    ${({ theme }) => theme.fonts.primary[30]}
  }
  font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
  padding: 0;
  width: auto;
`;

export const CustomDualSlider = styled(DualSlider)`
  padding: 40px 10px;
`;
