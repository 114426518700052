import { Menu, MenuItem } from '@innowise-group/mui-kit';
import React, { useEffect, useMemo, useState } from 'react';
import { CountryItem, countryData } from './phone-input.data';
import * as Styled from './phone-input.styles';
import { MaxSearchCharactersCount } from '@constants';
import { useDebounce, usePhoneAPI } from '@innowise-group/core';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';

interface PhoneInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: { value: string; country?: string };
  onChange: (value: { value: string; country?: string }) => void;
  name: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, variant = 'outlined', disabled, name, ...props }) => {
  const [filteredCountryCode, setFilteredCountryCode] = useState<CountryItem[]>(countryData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [debouncedValue] = useDebounce<string>(value.value, 400);
  const { fetchCountryByPhone, isPlus } = usePhoneAPI();
  const [country, setCountry] = useState<string | null>(null);
  const { setValue, trigger } = useFormContext();

  const currentCountry = useMemo(
    () =>
      countryData.find((item) => {
        return item.isoCode === value.country;
      }),
    [countryData, value?.country],
  );

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: CountryItem) => () => {
    if (item) {
      onChange({
        country: item.isoCode,
        value: item.dialCode.split('+')[1],
      });
    }
    setAnchorEl(null);
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value.length || e.target?.value === '+') {
      onChange({
        value: '',
        country: '',
      });
      setCountry(null);
    } else {
      if (
        e.target?.value.match(/(^[a-zA-Zа-яА-Я\d\-\\(\\)+]+$)/gi) &&
        e.target?.value.length <= MaxSearchCharactersCount
      ) {
        onChange({
          value: e.target.value.replace('+', ''),
          country: value.country,
        });
      }
    }
  };

  const inputPasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text').match(/\d/g);
    if (data?.length <= MaxSearchCharactersCount) {
      const selectionStart = e.currentTarget.selectionStart;
      const selectionEnd = e.currentTarget.selectionEnd;
      const newValue = value.value.substring(0, selectionStart) + data.join('') + value.value.substring(selectionEnd);
      onChange({
        value: newValue,
        country: value.country,
      });
    }
  };

  useEffect(() => {
    if (debouncedValue !== '' && debouncedValue !== '+') {
      const filteredCountryCode = countryData.filter((item) => {
        const regex = new RegExp(
          `(^${debouncedValue.split('')[0].match(/\d/) ? '\\+' : ''}${debouncedValue.replaceAll(/[()-+]/g, '\\$&')})`,
          'gi',
        );
        return item.isoCode.match(regex) || item.name.match(regex) || item.dialCode.match(regex);
      });
      if (filteredCountryCode) setFilteredCountryCode(filteredCountryCode);
    } else {
      setFilteredCountryCode(countryData);
    }

    if (debouncedValue.length >= 2 && debouncedValue.match(/^\d+$/)) {
      const handler = async () => {
        try {
          const country = await fetchCountryByPhone(debouncedValue);
          setCountry(country.payload.toString());
        } catch {
          setCountry(null);
        }
      };
      handler();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (country) {
      setValue(name, { value: value.value, country });
      trigger(name);
    }
  }, [country, name]);

  return (
    <React.Fragment>
      <Styled.PhoneInput
        onClick={handleClickListItem}
        value={value.value?.length || isPlus ? `+${value.value}` : ''}
        onChange={inputChangeHandler}
        onPaste={inputPasteHandler}
        variant={variant}
        disabled={disabled}
        InputProps={
          currentCountry?.flag && {
            startAdornment: (
              <InputAdornment position="start">
                <Styled.FlagIcon positions={currentCountry?.flag} />
              </InputAdornment>
            ),
          }
        }
        {...props}
      />
      {!!filteredCountryCode.length && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          disablePortal
          onClose={handleClose}
          autoFocus={false}
          disableAutoFocus
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {filteredCountryCode.map((option) => (
            <MenuItem
              key={option.isoCode}
              selected={option?.isoCode === currentCountry?.isoCode}
              onClick={handleMenuItemClick(option)}
            >
              <Styled.ListFlagIcon positions={option.flag} />
              {`${option.name} (${option.isoCode}) ${option.dialCode}`}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  );
};

export default React.memo(PhoneInput);
