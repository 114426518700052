import { AppLanguages } from '@constants';
import { SelectOption } from '@innowise-group/core';

type Languages = {
  [key in AppLanguages]: Pick<SelectOption, 'title'> & { value: AppLanguages };
};

export const languages: Languages = {
  [AppLanguages.En]: {
    title: 'buttons.english',
    value: AppLanguages.En,
  },
  [AppLanguages.Ru]: {
    title: 'buttons.russian',
    value: AppLanguages.Ru,
  },
};
