import {
  ExitsStatisticState,
  HorizontalTeamWorkingState,
  PersonalStatisticState,
  StatisticState,
  StatusesByVacanciesState,
  ThunkEffects,
  VerticalTeamWorkingState,
  useAppDispatch,
  useAppSelector,
} from '@innowise-group/core';
import { ReportTypeUnion, TinyState } from '@modules';
import { useCallback, useEffect } from 'react';
import {
  exportStatusesThunk,
  exportTeamWorkingThunk,
  getOverallStatusesThunk,
  getStatusesByVacanciesThunk,
  getOverallTeamStatisticThunk,
  getHorizontalTeamStatisticByVacancyThunk,
  getVerticalTeamStatisticByVacancyThunk,
  getPersonalStatisticByVacancyThunk,
  getPersonalOverallStatisticThunk,
  getRequestStatisticThunk,
  exportRequestsDataThunk,
  getSummaryStatisticThunk,
  getExitsStatisticThunk,
  exportSummaryReportThunk,
  exportExitsReportThunk,
} from './statistic.thunk';
import {
  getStatisticsData,
  getStatusesByVacancyStatisticsData,
  getVerticalTeamWorkingByVacancyStatisticsData,
  getHorizontalTeamWorkingByVacancyStatisticsData,
  getPersonalStatisticsData,
  getSummaryStatisticsData,
  getExitsStatisticsData,
} from './statistic.selectors';
import {
  clearOverallStatusesAction,
  clearStatusesByVacanciesAction,
  clearOverallTeamStatisticAction,
  clearHorizontalTeamStatisticByVacancyAction,
  clearVerticalTeamStatisticByVacancyAction,
  clearPersonalStatisticAction,
  setFiltersStateAction,
  clearRequestStatisticDataAction,
  clearFiltersStateAction,
  clearSummaryStatisticAction,
  clearExitsStatisticsAction,
} from './statistic.action';

export const useStatisticState = (): StatisticState => useAppSelector(getStatisticsData);

export const useStatusesByVacancyStatisticsData = (withoutVacancy: boolean): StatusesByVacanciesState =>
  useAppSelector(getStatusesByVacancyStatisticsData(withoutVacancy));

export const useVerticalTeamWorkingByVacancyStatisticsData = (withoutVacancy: boolean): VerticalTeamWorkingState =>
  useAppSelector(getVerticalTeamWorkingByVacancyStatisticsData(withoutVacancy));

export const useHorizontalTeamWorkingByVacancyStatisticsData = (): HorizontalTeamWorkingState =>
  useAppSelector(getHorizontalTeamWorkingByVacancyStatisticsData);

export const usePersonalStatisticsData = (withoutVacancy: boolean): PersonalStatisticState =>
  useAppSelector(getPersonalStatisticsData(withoutVacancy));

export const useSummaryStatisticsData = (): any => useAppSelector(getSummaryStatisticsData);

export const useExitsStatisticsData = (): ExitsStatisticState => useAppSelector(getExitsStatisticsData);

export const useStatisticFiltersState = <TState extends object>(
  initialState?: TState,
): readonly [searchParamsState: object, setSearchParamsState: (newState: TState) => void] => {
  useEffect(() => {
    if (initialState) {
      setFiltersState(initialState);
    }
  }, []);

  const dispatch = useAppDispatch();
  const { filtersState } = useStatisticState();
  const setFiltersState = useCallback(
    (...args: Parameters<typeof setFiltersStateAction>) => {
      dispatch(setFiltersStateAction(...args));
    },
    [dispatch],
  );
  return [filtersState, setFiltersState];
};

export const useStatisticsAPI = () => {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isExportLoading,
    overallStatuses: overallStatusesData,
    statusesByVacancies: statusesByVacanciesData,
    overallTeamWorking: overallTeamWorkingData,
    personalStatistic: personalStatisticData,
    requests: requestStatisticData,
    summary: summaryStaticsData,
  } = useStatisticState();

  const clearGlobalState = useCallback(() => {
    dispatch(clearFiltersStateAction());
  }, [dispatch]);

  const fetchRequestStatisticData = useCallback(
    (args: Partial<TinyState>) => {
      dispatch(getRequestStatisticThunk({ args }));
    },
    [dispatch],
  );

  const clearRequestStatisticData = useCallback(() => {
    dispatch(clearRequestStatisticDataAction());
  }, [dispatch]);

  const fetchOverallStatusesReport = useCallback(
    (args: Partial<TinyState>) => {
      return dispatch(getOverallStatusesThunk({ args }));
    },
    [dispatch],
  );

  const exportStatusesReport = useCallback(
    (args: Partial<TinyState>) => {
      return dispatch(exportStatusesThunk({ args }));
    },
    [dispatch],
  );

  const clearOverallStatuses = useCallback(() => {
    dispatch(clearOverallStatusesAction());
  }, [dispatch]);

  const clearStatusesByVacancies = useCallback(() => {
    dispatch(clearStatusesByVacanciesAction());
  }, [dispatch]);

  const fetchStatusesByVacanciesReport = useCallback(
    (args: Partial<TinyState>, pageNum?: number) => {
      return dispatch(getStatusesByVacanciesThunk({ args, pageNum }));
    },
    [dispatch],
  );

  const fetchOverallTeamStatistic = useCallback(
    (args: Partial<TinyState>) => {
      return ThunkEffects.takeLatest(
        dispatch(getOverallTeamStatisticThunk({ args })),
        getOverallTeamStatisticThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const exportTeamWorkingReport = useCallback(
    (args: Partial<TinyState>, tableType: ReportTypeUnion) => {
      return dispatch(exportTeamWorkingThunk({ args, tableType }));
    },
    [dispatch],
  );

  const exportSummaryReport = useCallback(
    (args: Partial<TinyState>) => {
      return dispatch(exportSummaryReportThunk({ args }));
    },
    [dispatch],
  );

  const exportExitsReport = useCallback(
    (args: Partial<TinyState>) => {
      return dispatch(exportExitsReportThunk({ args }));
    },
    [dispatch],
  );

  const exportRequestsReport = useCallback(
    (args: Partial<TinyState>) => {
      return dispatch(exportRequestsDataThunk({ args }));
    },
    [dispatch],
  );

  const clearOverallTeamStatistic = useCallback(() => {
    dispatch(clearOverallTeamStatisticAction());
  }, [dispatch]);

  const fetchHorizontalTeamWorkingByVacancyReport = useCallback(
    (args: Partial<TinyState>, pageNum?: number) => {
      return ThunkEffects.takeLatest(
        dispatch(getHorizontalTeamStatisticByVacancyThunk({ args, pageNum })),
        getHorizontalTeamStatisticByVacancyThunk.typePrefix,
      );
    },
    [dispatch],
  );

  const fetchVerticalTeamWorkingByVacancyReport = useCallback(
    (args: Partial<TinyState>, pageNum?: number) => {
      return dispatch(getVerticalTeamStatisticByVacancyThunk({ args, pageNum }));
    },
    [dispatch],
  );

  const fetchPersonalStatistic = useCallback(
    (args: Partial<TinyState>, pageNum?: number) => {
      return dispatch(getPersonalStatisticByVacancyThunk({ args, pageNum }));
    },
    [dispatch],
  );

  const fetchPersonalOverallStatistic = useCallback(
    (args: Partial<TinyState>) => {
      dispatch(getPersonalOverallStatisticThunk({ args }));
    },
    [dispatch],
  );

  const fetchSummaryStatistic = useCallback(
    (args: Partial<TinyState>) => {
      dispatch(getSummaryStatisticThunk({ args }));
    },
    [dispatch],
  );

  const fetchExitsStatisticsReport = useCallback(
    (args: Partial<TinyState>, pageNum: number) => {
      dispatch(getExitsStatisticThunk({ args, pageNum }));
    },
    [dispatch],
  );

  const clearPersonalStatistic = useCallback(() => {
    return dispatch(clearPersonalStatisticAction());
  }, [dispatch]);

  const clearHorizontalTeamStatisticByVacancy = useCallback(() => {
    dispatch(clearHorizontalTeamStatisticByVacancyAction());
  }, [dispatch]);

  const clearVerticalTeamStatisticByVacancy = useCallback(() => {
    dispatch(clearVerticalTeamStatisticByVacancyAction());
  }, [dispatch]);

  const clearSummaryStatistic = useCallback(() => {
    dispatch(clearSummaryStatisticAction());
  }, [dispatch]);

  const clearExitsStatistics = useCallback(() => {
    dispatch(clearExitsStatisticsAction());
  }, [dispatch]);

  return {
    clearGlobalState,
    fetchRequestStatisticData,
    clearRequestStatisticData,
    fetchOverallStatusesReport,
    exportStatusesReport,
    clearOverallStatuses,
    fetchStatusesByVacanciesReport,
    clearStatusesByVacancies,
    fetchOverallTeamStatistic,
    clearOverallTeamStatistic,
    exportTeamWorkingReport,
    fetchHorizontalTeamWorkingByVacancyReport,
    clearHorizontalTeamStatisticByVacancy,
    fetchVerticalTeamWorkingByVacancyReport,
    clearVerticalTeamStatisticByVacancy,
    fetchPersonalStatistic,
    fetchPersonalOverallStatistic,
    clearPersonalStatistic,
    exportRequestsReport,
    exportExitsReport,
    exportSummaryReport,
    fetchSummaryStatistic,
    clearSummaryStatistic,
    fetchExitsStatisticsReport,
    clearExitsStatistics,
    isLoading,
    isExportLoading,
    requestStatisticData,
    overallStatusesData,
    statusesByVacanciesData,
    overallTeamWorkingData,
    personalStatisticData,
    summaryStaticsData,
  };
};
