import React, { useEffect, useState } from 'react';
import * as Styled from './text-viewer.styles';
import { notificationsManager } from '@innowise-group/mui-kit';

interface TextViewerProps {
  currentFile: string;
}

const TextViewer: React.FC<TextViewerProps> = ({ currentFile }) => {
  const [text, setIsText] = useState<string>('');

  useEffect(() => {
    const readText = async () => {
      try {
        const response = await fetch(currentFile, { headers: { charset: 'utf-8' } });
        const text = await response.text();
        setIsText(text);
      } catch (err) {
        notificationsManager.error({ subtitle: err.message });
      }
    };
    readText();
  }, [currentFile]);

  return <Styled.Container>{text}</Styled.Container>;
};

export default TextViewer;
