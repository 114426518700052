import { styled, css } from '@innowise-group/mui-kit';

export const Block = styled.div<{ isOpen: boolean | null; shadowColor?: string }>`
  line-height: 22px;
  &::after {
    position: relative;
    display: block;
    content: '';
    width: -webkit-fill-available;
    height: 0;
    ${({ isOpen, theme, shadowColor }) => {
      if (!isOpen && isOpen !== null) {
        return css`
          -webkit-box-shadow: 0px -3px 10px 5px;
          -moz-box-shadow: 0px -3px 10px 5px;
          box-shadow: 0px -3px 10px 5px;
          color: ${shadowColor || theme.palette.general.white};
        `;
      }
    }}
  }
  & .MuiCollapse-root {
    cursor: ${({ isOpen }) => {
      return isOpen !== null ? 'pointer' : 'default';
    }} !important;
  }
`;
