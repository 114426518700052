import { styled } from '@innowise-group/mui-kit';
import { Input as BaseInput, Checkbox as CheckboxBase, Loader as LoaderBase } from '@innowise-group/mui-kit';

export const Container = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin-left: 5px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  padding: 5px;
  overflow-y: hidden;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
`;

export const ListItemContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
`;

export const Input = styled(BaseInput)`
  width: 100%;
`;

export const SelectItemWrapper = styled.li`
  list-style-type: none;
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.darkGrey[40]};
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Checkbox = styled(CheckboxBase)`
  & .MuiSvgIcon-root {
    font-size: 25px;
  }
`;

export const SelectItemLabel = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[80]}
`;

export const Loader = styled(LoaderBase)`
  margin: 10px auto;
`;
