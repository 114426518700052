export enum RequestLoggerType {
  Approval = 'APPROVAL',
  Disapproval = 'DISAPPROVAL',
  ResponseDelete = 'RESPONSE_DELETE',
}

export const RequestLoggerTypeNames = {
  [RequestLoggerType.Approval]: 'pages.requests.viewModal.approved',
  [RequestLoggerType.Disapproval]: 'pages.requests.viewModal.disapproved',
  [RequestLoggerType.ResponseDelete]: 'pages.requests.viewModal.removedReserve',
};
