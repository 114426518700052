import { Divider as BaseDivider } from '@mui/material';
import { css, styled, Menu as BaseMenu, MenuItem as BaseMenuItem, Button, Icon } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  z-index: 1310;
`;

const gap = css`
  gap: 10px;
`;

export const Menu = styled(BaseMenu)`
  &.MuiList-root,
  .MuiMenu-list {
    padding: 0;
  }
  &.MuiPaper-root {
    border-radius: 6px;
  }
`;

export const MenuItem = styled(BaseMenuItem)<{ $preventHoverStyles?: boolean }>`
  & > .MuiTouchRipple-root {
    opacity: 0;
  }
  &.MuiMenuItem-root {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    ${gap}
    font-size: 12px;
  }

  &.MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
    opacity: 1;
  }
  &.MuiMenuItem-root:hover {
    ${({ $preventHoverStyles, theme }) =>
      $preventHoverStyles && {
        background: `${theme.palette.general.paper} !important`,
        cursor: 'default',
      }}
  }
`;

export const Divider = styled(BaseDivider)`
  &.MuiDivider-root {
    margin: 0 !important;
  }
`;

export const ChoseLanguageButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined svg,
  &.MuiButton-root.MuiButton-outlined:hover svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
  &.MuiButton-root.MuiButton-outlined,
  &.MuiButton-root.MuiButton-outlined:hover {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    padding: 3px 10px;
    margin-left: 10px;
    border-radius: 20px;
  }
`;

export const RedirectLink = styled.a`
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${gap}
`;

export const AngleIcon = styled(Icon)<{ open: boolean }>`
  transform: rotate(${({ open }) => (!open ? '0deg' : '180deg')});
  transition: transform 0.3s;
`;

export const LogOutMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: ${({ theme }) => theme.palette.general.smart};
  }
`;

export const LogoutIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.palette.general.smart,
}))``;

export const BurgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 4px;
  width: 44px;
  height: 44px;
  transition: 0.2s;
  background-color: ${({ theme }) => theme.palette.info.backgroundSecondary};
  &:active {
    background-color: ${({ theme }) => theme.palette.info.background};
  }
`;
