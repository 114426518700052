import { FC, Fragment } from 'react';
import { DiffBaseProps, LabelTransformer, DiffBase } from '../diff-base';
import * as Styled from './source-with-details-diff.styles';
import { useOptionsApi } from '@innowise-group/core';
import { useTranslation } from 'react-i18next';
import { CandidateSourceNeedReferralIds } from '@constants';

type SourceWithDetailsDiffProps = Omit<DiffBaseProps, 'labelTransformer' | 'fieldName'>;

const SourceWithDetailsDiff: FC<SourceWithDetailsDiffProps> = (props) => {
  const { candidateSourceOptions } = useOptionsApi();
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'sourceWithDetailsAndReferralProgram'> = (el, idx) => {
    const sourceLabel = candidateSourceOptions.find((e) => e.value === el.source.data.valueId)?.title;
    const sourceDetails = el.sourceDetails;
    const referral =
      CandidateSourceNeedReferralIds === el.source?.data?.valueId
        ? `${el.candidateReferral?.data?.recommender?.email} / ${el.candidateReferral?.data?.referralComment} / ${
            el.candidateReferral?.data?.reward
          } / ${t(`options.gradeOptions_${el.candidateReferral?.data?.startGrade}`)}`
        : '';
    return (
      <Styled.OptionWrapper key={idx}>
        <Styled.OptionSource>{sourceLabel}</Styled.OptionSource>
        {sourceDetails && <b> / </b>}
        <Styled.OptionDetails>{sourceDetails}</Styled.OptionDetails>
        {referral && (
          <Fragment>
            <br />
            <Styled.OptionDetails>{referral}</Styled.OptionDetails>
          </Fragment>
        )}
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase fieldName="sourceWithDetailsAndReferralProgram" labelTransformer={labelTransformer} {...props} />;
};

export default SourceWithDetailsDiff;
