import { styled } from '@innowise-group/mui-kit';
import { Icon, Input, Button } from '@innowise-group/mui-kit';
import { Fade } from '@mui/material';

const MIN_WIDTH = 200;

export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled(Input)<{ width: number }>`
  width: ${({ width }) => (width > MIN_WIDTH ? `${width}px` : `${MIN_WIDTH}px`)};
  transition: width 0.3s;

  & .MuiFormHelperText-root {
    display: none;
  }

  &.MuiTextField-root {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: auto;
  }
`;

export const HistoryWrapper = styled(Fade)`
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.general.paper};
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
`;

export const HistoryChildrenWrapper = styled.div`
  min-width: ${MIN_WIDTH}px;
  width: fit-content;
  padding-bottom: 5px;
`;

export const Content = styled.div``;

export const HistoryContentTitle = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[40]}
`;

export const HistoryItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
`;

export const HistoryItemLabel = styled.p`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.palette.general.smart,
}))`
  margin-left: auto;
`;

export const XraySearchIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.palette.general.darkGrey[80],
}))`
  cursor: pointer;
  margin-left: 5px;
`;

export const XrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    padding: 0;
  }
`;
