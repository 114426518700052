import { CandidateStatusColorsItem } from '@constants';
import { Input as BaseInput, css, Icon, styled } from '@innowise-group/mui-kit';
import {
  PrimitiveFieldsDiff,
  CitizenshipDiff,
  GenderDiff,
  BirthdayDiff,
  ResponsibleDiff,
  SourceWithDetailsDiff,
} from '../../../diff-variants';
import { Image as BaseImage } from '@shared-mui-components';

export const Input = styled.input`
  display: none;
`;

export const Image = styled(BaseImage)``;

export const DatePickerContainer = styled.div`
  position: relative;
  & .MuiTextField-root {
    width: 100% !important;
  }
  & .MuiOutlinedInput-root {
    padding-right: 2px;
  }
`;

export const Wrapper = styled.div`
  margin: 1.75rem 2rem 0 2rem;
  display: grid;
  column-gap: 1.75rem;
  row-gap: 0.625rem;
  grid-template-columns: 12.5rem 1fr;
  align-items: stretch;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    margin: 1.25rem 1.5rem 0 1.5rem;
  }
`;

export const FieldsWrapper = styled.div`
  display: grid;
  column-gap: 1.75rem;
  row-gap: 0.625rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    column-gap: 1.25rem;
    row-gap: 0.5rem;
  }
`;

export const AvatarBlock = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-top: 10px;
  justify-content: space-between;
  margin-bottom: 1.0625rem;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 12.5rem;
  height: 12.5rem;
  & > img {
    object-fit: cover;
    border-radius: 50%;
    pointer-events: none;
    width: 12.5rem;
    height: 12.5rem;
  }
  cursor: pointer;
`;

export const StatusCell = styled.div`
  min-width: 0;
`;

export const StatusContainer = styled.div<{ statusColors: CandidateStatusColorsItem }>`
  color: ${({ statusColors }) => statusColors?.color};
  border-radius: 0.5rem;
  border: 1px solid ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
  background-color: ${({ statusColors, theme }) =>
    statusColors?.backgroundColor || theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.fonts.primary[40]}
  padding: 0.875rem;
  position: relative;
  line-height: 1;
  height: 1rem;
  box-sizing: content-box;
  margin-top: 10px;
  text-align: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(0.6) translateX(-40%);
    content: '';
    height: 80%;
    width: 0.75rem;
    background-color: ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
    border-radius: 0px 2rem 2rem 0px;
  }
`;

export const NoImageContainer = styled.label`
  width: 12.5rem;
  height: 12.5rem;
  align-self: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.transition};
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
`;

export const AvatarFieldError = styled.div`
  color: ${({ theme }) => theme.palette.state.error.default};
  position: absolute;
  ${({ theme }) => theme.fonts.primary[10]}
  min-height: 17px;
  bottom: -40px;
  text-align: center;
`;

export const CalendarIcon = styled(Icon)<{ isRelative?: boolean }>`
  position: relative;
  left: 0px;
  & svg {
    flex-shrink: 0;
  }
`;

export const ClearIcon = styled(Icon)`
  position: relative;
  cursor: pointer;
  left: 0px;
  & svg {
    flex-shrink: 0;
  }
`;

export const YearsContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  font-weight: 500;
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
  white-space: nowrap;
`;

export const UpdatedDate = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const SectionInput = styled(BaseInput)`
  &.MuiTextField-root {
    margin-top: 1.5rem;
  }
`;

export const CommentInput = styled(BaseInput)`
  &.MuiTextField-root {
    grid-column: span 2;
  }
`;

export const AboutMeContainer = styled.div`
  position: relative;
  grid-column: 1 / -1;
  grid-row: 3 / span 1;
  & .MuiTextField-root {
    width: 100%;
  }
`;

export const BoxForDiffConsolidation = styled.div`
  position: relative;
  display: grid;
`;

export const SourceBoxForDiffConsolidation = styled.div`
  position: relative;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row: 4 / span 1;
  column-gap: 1.75rem;
`;

const commonDiffElementsPosition = css`
  top: 20px;
`;

export const CustomPrimitiveFieldsDiff = styled(PrimitiveFieldsDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomCitizenshipDiff = styled(CitizenshipDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomGenderDiff = styled(GenderDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomBirthdayDiff = styled(BirthdayDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomResponsibleDiff = styled(ResponsibleDiff)`
  ${commonDiffElementsPosition}
  top: 35px;
  grid-column: 1 / span 1;
`;

export const CustomSourceWithDetailsDiff = styled(SourceWithDetailsDiff)<{
  $withClarification: boolean;
  $withReferralProgram: boolean;
}>`
  ${commonDiffElementsPosition}
  top: 35px;
  grid-column: ${({ $withClarification, $withReferralProgram }) => {
    if ($withClarification) return '2 / span 2';
    if ($withReferralProgram) return '2 / -1';
    return '2 / span 1';
  }};
`;

export const IconsContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;
