import React from 'react';
import * as Styled from './main-section.styles';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Select } from '@innowise-group/mui-kit';
import { SelectOption, useOptionsApi } from '@innowise-group/core';
import { PurposeEnum, VacancyStatusesWithoutRequests } from '@constants';
import { LabeledCheckbox } from '@shared-mui-components';
import { InfoTooltip } from '@shared-components';

interface MainSectionProps {
  handleNextStep: () => void;
  isRequests?: boolean;
  isEdit?: boolean;
  forRequestCreation?: boolean;
  handleClose: () => void;
}

const MainSection: React.FC<MainSectionProps> = ({
  handleNextStep,
  handleClose,
  isRequests,
  isEdit,
  forRequestCreation,
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const hiringPurpose = watch('hiringPurpose');
  const published = watch('published');
  const { vacancyStatus, vacancyPurposeOptions } = useOptionsApi();

  return (
    <React.Fragment>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Styled.FullInput
            label={t('pages.vacancies.creationModal.name')}
            required
            value={value}
            onChange={onChange}
            error={!!errors?.['name']?.message}
            helperText={errors?.['name']?.message.toString()}
            placeholder={t('pages.vacancies.creationModal.enterVacancyName')}
          />
        )}
      />
      <Controller
        name="vacancyStatusId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Select
              value={value}
              onChange={onChange}
              disabled={published}
              options={vacancyStatus}
              disableClearable
              getOptionDisabled={(option: SelectOption) =>
                forRequestCreation && VacancyStatusesWithoutRequests.includes(option.value)
              }
              renderInput={(params) => {
                return (
                  <Styled.FullInput
                    {...params}
                    fullWidth
                    required
                    label={t('pages.vacancies.creationModal.status')}
                    placeholder={t('pages.vacancies.creationModal.selectStatus')}
                    error={!!errors?.vacancyStatusId?.message}
                    helperText={errors?.vacancyStatusId?.message.toString()}
                  />
                );
              }}
            />
          );
        }}
      />
      <Controller
        name="hiringPurpose"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={vacancyPurposeOptions}
            disableClearable
            disabled={isEdit && isRequests}
            renderInput={(params) => {
              return (
                <Styled.FullInput
                  {...params}
                  fullWidth
                  required
                  label={t('pages.vacancies.creationModal.employmentPurpose')}
                  error={!!errors?.hiringPurpose?.message}
                  helperText={errors?.hiringPurpose?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      {hiringPurpose === PurposeEnum.Trainee && (
        <Styled.PublishCheckboxContainer>
          <Controller
            name="published"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LabeledCheckbox label={t('pages.vacancies.creationModal.publish')} onChange={onChange} checked={value} />
            )}
          />
          <InfoTooltip title={t('tooltips.vacancyForm')} placement="top-end" />
        </Styled.PublishCheckboxContainer>
      )}
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handleClose} variant="outlined">
          {t('buttons.cancel')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </React.Fragment>
  );
};

export default MainSection;
