import React, { ComponentProps } from 'react';
import * as Styled from './desktop-view.styles';
import { ChooseReport } from '../choose-report';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ReportFormValues } from '../../report-manager.types';
import { SelectOption } from '@innowise-group/core';
import { RolesValues } from '@constants';
import { RoleGuard } from '@shared-components';
import { Accordion, AccordionSummary, Loader, Icon } from '@innowise-group/mui-kit';
import { DateRangeFilter } from '../dates-range';
import { SelectFilter } from '../select';
import { ReportButton, ReportButtonProps, ReportUnion } from '../report-button';
import { FiltersConfig } from '../../use-report-manager.hook';
import { reportIcons } from '../../report-manager.data';
import { useStatisticsAPI } from '@innowise-group/core';
import { Reports, reports } from '../choose-report/choose-report.data';

interface DesktopViewProps {
  toggleButton: () => void;
  expanded: boolean;
  periodLabel: string;
  config: FiltersConfig<ReportFormValues>;
  applyFilters: (args: ReportFormValues) => void;
  reportTypeIcons: ReportButtonProps['icons'];
  onExportHandle: (args: ReportFormValues) => void;
  handleStatusChange: (
    onChange: (value: string | SelectOption[], reason?: string) => void,
  ) => (value: string | SelectOption[], reason?: string) => void;
  reportTypeVariants: ReportButtonProps['variants'];
  submitButtonDisabled: boolean;
  switcherTypeDisabled: boolean;
  switcherDisabled: boolean;
  statusAuthorDisabled: boolean;
  statusDisabled: boolean;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  toggleButton,
  applyFilters,
  handleStatusChange,
  onExportHandle,
  reportTypeIcons,
  statusDisabled,
  config,
  expanded,
  reportTypeVariants,
  periodLabel,
  submitButtonDisabled,
  switcherTypeDisabled,
  switcherDisabled,
  statusAuthorDisabled,
}) => {
  const { t } = useTranslation();
  const { isExportLoading } = useStatisticsAPI();
  const { handleSubmit, control } = useFormContext<ReportFormValues>();

  const currentReport = reports.reduce((acc, { path, value }) => {
    if (window.location.pathname.endsWith(path)) {
      return value;
    }
    return acc;
  }, '' as Reports);

  const shouldShowReportFilters = currentReport !== Reports.Duplicates;

  const shouldShowExportButton = ![Reports.TeamWork, Reports.Letters, Reports.Employee].includes(currentReport);

  return (
    <Styled.Wrapper>
      <Styled.ButtonsContainer>
        <ChooseReport />
        {shouldShowReportFilters && (
          <React.Fragment>
            <Styled.SettingButton
              onClick={toggleButton}
              startIcon={<Icon type="u_report_settings" />}
              endIcon={<Styled.ArrowIcon open={expanded} />}
            >
              {t('buttons.reportSettings')}
            </Styled.SettingButton>
            {periodLabel && <Styled.PeriodText>{periodLabel}</Styled.PeriodText>}
            <RoleGuard roles={[RolesValues.Admin, RolesValues.Supervisor]}>
              {shouldShowExportButton && (
                <Styled.ExportButton
                  onClick={handleSubmit(onExportHandle)}
                  variant="outlined"
                  startIcon={isExportLoading ? <Loader size={20} /> : <Icon type="u_export" />}
                  disabled={isExportLoading}
                >
                  {t('buttons.export')}
                </Styled.ExportButton>
              )}
            </RoleGuard>
          </React.Fragment>
        )}
      </Styled.ButtonsContainer>
      {shouldShowReportFilters && (
        <Accordion expanded={expanded}>
          <AccordionSummary />
          <Styled.AccordionDetails>
            <Styled.FiltersContainer>
              <DateRangeFilter
                minName={config.dateRange.minName}
                maxName={config.dateRange.maxName}
                disabled={(config.dateRange as unknown as ComponentProps<typeof SelectFilter>).disabled}
              />
              <Controller
                name={config.statuses.name}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <SelectFilter
                    onChange={handleStatusChange(onChange)}
                    {...{ ...field, ...(config.statuses as unknown as ComponentProps<typeof SelectFilter>) }}
                    disabled={
                      (config.statuses as unknown as ComponentProps<typeof SelectFilter>).disabled || statusDisabled
                    }
                  />
                )}
              />
              <Controller
                name={config.vacancies.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancies as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.sources.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.sources as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.vacancyClaims.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancyClaims as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.vacancyClaimAuthors.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.vacancyClaimAuthors as unknown as ComponentProps<typeof SelectFilter>) }}
                  />
                )}
              />
              <Controller
                name={config.statusAuthors.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{ ...field, ...(config.statusAuthors as unknown as ComponentProps<typeof SelectFilter>) }}
                    disabled={
                      (config.statusAuthors as unknown as ComponentProps<typeof SelectFilter>).disabled ||
                      statusAuthorDisabled
                    }
                  />
                )}
              />
              <Controller
                name={config.candidateResponsible.name}
                control={control}
                render={({ field }) => (
                  <SelectFilter
                    {...{
                      ...field,
                      ...(config.candidateResponsible as unknown as ComponentProps<typeof SelectFilter>),
                    }}
                  />
                )}
              />
            </Styled.FiltersContainer>
            <Styled.ReportsButtonsContainer>
              <Controller
                name="report"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ReportButton
                    value={value}
                    onChange={onChange}
                    icons={reportIcons}
                    disabled={switcherDisabled}
                    variants={{
                      primary: ReportUnion.Chart,
                      secondary: ReportUnion.Table,
                    }}
                  />
                )}
              />
              <Controller
                name="reportType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ReportButton
                    value={value}
                    onChange={onChange}
                    icons={reportTypeIcons}
                    variants={reportTypeVariants}
                    disabled={switcherDisabled || switcherTypeDisabled}
                  />
                )}
              />
              <Styled.SubmitButton onClick={handleSubmit(applyFilters)} disabled={submitButtonDisabled}>
                {t('buttons.apply')}
              </Styled.SubmitButton>
            </Styled.ReportsButtonsContainer>
          </Styled.AccordionDetails>
        </Accordion>
      )}
    </Styled.Wrapper>
  );
};

export default DesktopView;
