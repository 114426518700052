import { RolesValues } from '@constants';
import {
  EmployeesService,
  ListItemsResponse,
  EntityOptions,
  RequestItemResponse,
  VacancyItemResponse,
  FullEmployeeInfoResponse,
  FullEmployeeRequest,
  ShoShortEmployeeItemState,
  ReferralItemResponse,
} from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';

export const searchEmployeesThunkMapper = (
  arg: Awaited<ReturnType<typeof EmployeesService.searchEmployees>>['data'],
): ListItemsResponse<ShoShortEmployeeItemState> & {
  vacanciesOptions: EntityOptions;
  requestsOptions: EntityOptions;
} => {
  const allVacancies = {};
  const allRequests = {};
  return {
    ...arg,
    content: arg.content.map((el) => ({
      ...el,
      id: el.employeeId,
      newRole: el.role?.name,
      authData: {
        profile: {
          permittedVacancyIds:
            el.authData.profile.permittedVacancies?.map(({ id, name }) => {
              allVacancies[id] = { id: id.toString(), value: id.toString(), title: name };
              return id.toString();
            }) || null,
          permittedProfessionIds:
            el.authData.profile.permittedProfessions?.map(({ valueId }) => {
              return valueId.toString();
            }) || null,
          permittedVacancyRequestIds:
            el.authData.profile.permittedVacancyRequestIds?.map((id) => {
              allRequests[id] = { id: id.toString(), value: id.toString(), title: id.toString() };
              return id.toString();
            }) || null,
        },
        database: {
          permittedVacancyIds:
            el.authData.database.permittedVacancies?.map(({ id, name }) => {
              allVacancies[id] = { id: id.toString(), value: id.toString(), title: name };
              return id.toString();
            }) || null,
          permittedProfessionIds:
            el.authData.database.permittedProfessions?.map(({ valueId }) => {
              return valueId.toString();
            }) || null,
          permittedVacancyRequestIds:
            el.authData.database.permittedVacancyRequestIds?.map((id) => {
              allRequests[id] = { id: id.toString(), value: id.toString(), title: id.toString() };
              return id.toString();
            }) || null,
        },
        finance: {
          permittedVacancyIds:
            el.authData.finance.permittedVacancies?.map(({ id, name }) => {
              allVacancies[id] = { id: id.toString(), value: id.toString(), title: name };
              return id.toString();
            }) || null,
          permittedProfessionIds:
            el.authData.finance.permittedProfessions?.map(({ valueId }) => {
              return valueId.toString();
            }) || null,
          permittedVacancyRequestIds:
            el.authData.finance.permittedVacancyRequestIds?.map((id) => {
              allRequests[id] = { id: id.toString(), value: id.toString(), title: id.toString() };
              return id.toString();
            }) || null,
        },
        profileAccess: el.role?.permissions?.includes('CANDIDATE_READ'),
        databaseAccess: el.role?.permissions?.includes('CANDIDATE_LIST_READ'),
        financeAccess: el.role?.permissions?.includes('CANDIDATE_SALARY_READ'),
      },
    })),
    vacanciesOptions: {
      ids: Object.keys(allVacancies),
      options: allVacancies,
    },
    requestsOptions: {
      ids: Object.keys(allRequests),
      options: allRequests,
    },
  };
};

export const vacanciesOptionsMapper = (data: VacancyItemResponse[]): SelectOption[] =>
  data.map(({ name, id }) => ({
    title: name,
    value: id.toString(),
  }));

export const requestsOptionsMapper = (data: RequestItemResponse[]): SelectOption[] =>
  data.map(({ id }) => ({ title: id.toString(), value: id.toString() }));

export const findNewOptions = (selectedIds: (string | number)[], options: SelectOption[]) =>
  selectedIds.reduce(
    (acc, id) => {
      const newOption = options.find(({ value }) => value.toString() === id.toString());
      if (newOption) {
        return {
          ...acc,
          newIds: [...acc.newIds, id.toString()],
          newOptions: {
            ...acc.newOptions,
            [id]: { ...newOption, value: newOption.value.toString() },
          },
        };
      }
      return acc;
    },
    { newIds: [], newOptions: {} },
  );

export const newEmployeeMapper = (data: FullEmployeeInfoResponse): FullEmployeeRequest => ({
  ...data,
  photoRtId: data.phoneRt,
  genderId: data.gender?.valueId,
  jobTitleId: data.jobTitle.valueId,
  locationId: data.location,
  formatOfWorkId: data.formatOfWork?.valueId,
  employeeStatusId: data.employeeStatus,
  languageId: data.language,
  role: RolesValues.ManagerFourthLevel,
});

export const citiesMapper = (data: { id: string; name: string; countryId: number }[]) => {
  const arr: SelectOption[] = data.map(({ id, name, countryId }) => {
    return { title: name, value: id, parent: countryId.toString() };
  });
  return arr;
};

export const referralMapper = (data: ReferralItemResponse[]) => {
  return data.map((item) => {
    return {
      ...item,
      totalReward: item.candidateReferralDtos.reduce((acc, val) => {
        return (acc += val.reward);
      }, 0),
    };
  });
};
