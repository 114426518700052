import { Icon, styled } from '@innowise-group/mui-kit';

export const SectionRowContainer = styled.div`
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 6fr 6fr 5fr 1fr 3fr;
  align-items: center;
  & .MuiTextField-root {
    width: 100%;
    input {
      padding-left: 0.75rem;
    }
  }
  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    transition: 0.2s;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;
