import React, { forwardRef } from 'react';
import { TextFieldProps } from '@mui/material';
import * as Styled from './input.styles';

const Input: React.FC<TextFieldProps> = forwardRef(
  ({ type = 'text', variant = 'outlined', helperText = ' ', placeholder, disabled, ...props }, ref) => {
    return (
      <Styled.Input
        ref={ref}
        type={type}
        {...props}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        variant={variant}
        helperText={helperText}
        autoComplete="off"
      />
    );
  },
);

export default React.memo(Input);
