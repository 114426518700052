import { styled, css } from '@innowise-group/mui-kit';
import { Typography } from '../typography';
import { NotificationType } from '../notification';
import { Icon } from '../icon';

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

interface NotificationContainerProps {
  containerType: NotificationType;
}
export const NotificationContainer = styled.div<NotificationContainerProps>`
  border-radius: 10px;
  border: 1px solid;
  min-height: 76px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  ${({
    theme: {
      palette: { state },
    },
    containerType,
  }) => {
    const colorsMap: Record<NotificationType, { background: string; border: string }> = {
      error: {
        background: state.error.background,
        border: state.error.default,
      },
      warning: {
        background: state.warning.background,
        border: state.warning.default,
      },
      success: {
        background: state.success.background,
        border: state.success.default,
      },
    };

    return css`
      background-color: ${colorsMap[containerType].background};
      border-color: ${colorsMap[containerType].border};
    `;
  }}
`;

export const NotificationContentContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  gap: 5px;
`;

export const NotificationTitle = styled(Typography)`
  ${({ theme }) => theme.fonts.primary[30]}
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1px;
`;

export const NotificationSubTitle = styled(Typography)`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  letter-spacing: 1px;
  white-space: pre-wrap;
`;
