import { styled, css } from '@innowise-group/mui-kit';

export const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) => color || theme.palette.general.smart};
  color: ${({ theme }) => theme.palette.general.white};
  border-radius: 10px;
  padding: 0 15px;
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  border: none;
  transition: ${({ theme }) => theme.animation.transition};
  margin: 0;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.hover};
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const OutlinedContainer = styled(ButtonContainer)`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  opacity: 0.8;
  color: ${({ theme, color }) => color || theme.palette.general.smart};
  border: 2px solid ${({ theme, color }) => color || theme.palette.general.smart};

  &:hover {
    background-color: transparent;
    opacity: 1;
  }
`;

export const LinkContainer = styled(ButtonContainer)`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;

  border: 2px solid ${({ theme, color }) => color || theme.palette.general.smart};
`;

export const TextContainer = styled(ButtonContainer)`
  border: none;
  color: ${({ theme, color }) => color || theme.palette.state.error.pressed};
  background-color: transparent;
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
    background-color: transparent;
    & svg {
      fill: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
    }
  }
  &:hover:disabled {
    background-color: transparent;
  }
`;

export const IconContainer = styled.div<{ position?: 'left' | 'right'; disabled?: boolean }>`
  height: 100%;
  display: inline-flex;
  align-items: center;
  ${({ position }) => {
    return css`
      ${position === 'left' ? 'margin-right: 10px;' : 'margin-left: 10px;'}
    `;
  }};
`;

export const LoaderContainer = styled(IconContainer)`
  margin-left: 0;
  margin-right: 10px;
`;
// TODO: Will be removed with all old ui-kit components
export const ButtonLoader = styled('div')`
  margin: 0;
`;
