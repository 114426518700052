import { memo, useEffect, useRef, useState } from 'react';
import { ActivityPlaceFilterType, BaseFilterType } from '@innowise-group/core';
import { FilterButton } from '../shared';
import { useTranslation } from 'react-i18next';
import { Input } from '@innowise-group/mui-kit';
import * as Styled from './activity-place.styles';

export interface ActivityPlaceProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: ActivityPlaceFilterType['state'];
  onValueChange: (args: ActivityPlaceFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (updatedState: ActivityPlaceFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const ActivityPlace: React.FC<ActivityPlaceProps & ActivityPlaceFilterType['filterProps']> = ({
  isOpen,
  labelKey,
  state,
  activityPlaceKey,
  activityPositionKey,
  onValueChange,
  onClickFilterOutside,
  onRemoveFilter,
  onToggleFilter,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onInputChange =
    (field: keyof Pick<ActivityPlaceFilterType['state'], 'place' | 'profession'>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return onValueChange({ ...state, [field]: e.target.value });
    };

  const onDateChange = (field: keyof Pick<ActivityPlaceFilterType['state'], 'from' | 'to'>) => (date: Date) => {
    return onValueChange({ ...state, [field]: date?.toISOString() || null });
  };

  const onRemoveFilterHandler = () => {
    onRemoveFilter({ from: null, to: null, place: '', profession: '' });
  };

  const onClickOutsideHandler = () => {
    const isEmpty = !state.from && !state.to && !state.place.length && !state.profession.length;
    if (isEmpty) onRemoveFilterHandler();
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Wrapper ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandler}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          {(state.profession || state.place) && (
            <Styled.PlaceDescription>{`${state.place}${state.profession && state.place && '/'}${
              state.profession
            }`}</Styled.PlaceDescription>
          )}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickOutsideHandler}
      >
        <Styled.FilterWrapper>
          <Styled.FieldWrapper>
            <Input
              label={t(activityPlaceKey)}
              value={state.place}
              onChange={onInputChange('place')}
              placeholder={t('pages.candidates.candidateCreation.enterTheName')}
            />
          </Styled.FieldWrapper>
          <Styled.FieldWrapper>
            <Input
              label={t(activityPositionKey)}
              value={state.profession}
              onChange={onInputChange('profession')}
              placeholder={t('pages.candidates.candidateCreation.enterTheName')}
            />
          </Styled.FieldWrapper>
          <Styled.Row>
            <Styled.FieldWrapper>
              <Styled.DatePicker
                selected={state.from ? new Date(state.from) : null}
                onChange={onDateChange('from')}
                maxDate={state?.to ? new Date(state?.to) : new Date()}
                maxYear={state?.to ? new Date(state?.to).getFullYear() : new Date().getFullYear()}
                withoutDays
                placeholder={t('pages.candidates.candidateCreation.from')}
                label={t('pages.candidates.candidateCreation.from')}
              />
            </Styled.FieldWrapper>
            <Styled.FieldWrapper>
              <Styled.DatePicker
                selected={state.to ? new Date(state.to) : null}
                maxDate={new Date()}
                minDate={state?.from && new Date(state?.from)}
                minYear={state.from && new Date(state?.from).getFullYear()}
                maxYear={new Date().getFullYear()}
                onChange={onDateChange('to')}
                withoutDays
                placeholder={t('pages.candidates.candidateCreation.to')}
                label={t('pages.candidates.candidateCreation.to')}
              />
            </Styled.FieldWrapper>
          </Styled.Row>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default memo(ActivityPlace);
