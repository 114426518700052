import { SearchEntities } from '@innowise-group/core';
import { AppIconsType } from '@innowise-group/mui-kit';

type IconsData = {
  [key in SearchEntities]: {
    type: AppIconsType;
    viewBox: string;
    size: number;
  };
};

export const iconsData: IconsData = {
  [SearchEntities.Candidates]: {
    type: 'u_search_results_candidate',
    viewBox: '20 0 44 20',
    size: 20,
  },
  [SearchEntities.Requests]: {
    type: 'u_request',
    viewBox: '0 0 30 20',
    size: 20,
  },
  [SearchEntities.Vacancies]: {
    type: 'u_vacancy',
    viewBox: '24 0 40 20',
    size: 20,
  },
};
