import { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  CandidateSourceNeedClarificationIds,
  CandidateSourceNeedReferralIds,
  ContactsType,
  CurrencyEnum,
  DateFormats,
  LifecycleStatuses,
  MaxRefsCount,
  MAX_CANDIDATE_SALARY_BYN,
  MAX_CANDIDATE_SALARY_EUR,
  MAX_CANDIDATE_SALARY_PLN,
  MAX_CANDIDATE_SALARY_USD,
  MIN_CANDIDATE_SALARY,
} from '@constants';
import { countryData } from '../../mui-components/phone-input/phone-input.data';
import { useCurrentCandidate, useOptionsApi } from '../store';
import { useValidationSchemaTelegram } from './use-validation-schema-telegram';
import * as Yup from 'yup';

const MAX_FIRST_NAME_CHARACTERS = 35;
const MAX_LAST_NAME_CHARACTERS = 35;
const MAX_LINK_NAME_CHARACTERS = 15;
const MAX_SKYPE_CHARACTERS = 200;
const MAX_LINK_URL_CHARACTERS = 2000;
const MAX_ABOUT_ME_CHARACTERS = 5000;

const MAX_CANDIDATE_LANGUAGES = 5;
const MAX_CANDIDATE_PROFESSIONS = 5;

const TECHNOLOGY_LIMIT = 10;
const DOMAIN_LIMIT = 10;

const COUNTRIES_LIMIT = 3;
const CITIZENSHIPS_LIMIT = 5;

export const useValidationSchemaCandidate = () => {
  const { t } = useTranslation();
  const { statusAuthorsOptions: rolesEmployeesOptions } = useOptionsApi();

  const telegramSchema = useValidationSchemaTelegram();
  const currentCandidate = useCurrentCandidate();
  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      {
        firstNameEn: Yup.string()
          .trim()
          .max(MAX_FIRST_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_FIRST_NAME_CHARACTERS }))
          .matches(/(^$)|(^['’`a-zA-Z-\s()]+$)/, t('errors.latinLettersDash'))
          .when('lastNameEn', {
            is: (lastNameEn: string) => !!lastNameEn,
            then: (schema) => schema.required(t('errors.requiredField')),
          })
          .when(['firstNameRu', 'lastNameRu'], {
            is: (firstNameRu: string, lastNameRu: string) => !firstNameRu && !lastNameRu,
            then: (schema) => schema.required(t('errors.requiredField')),
          }),
        firstNameRu: Yup.string()
          .trim()
          .max(MAX_FIRST_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_FIRST_NAME_CHARACTERS }))
          .matches(/(^$)|(^['’`а-яА-ЯЁё-\s()]+$)/, t('errors.rusLettersDash'))
          .when('lastNameRu', {
            is: (lastNameRu: string) => !!lastNameRu,
            then: (schema) => schema.required(t('errors.requiredField')),
          })
          .when(['firstNameEn', 'lastNameEn'], {
            is: (firstNameEn: string, lastNameEn: string) => !firstNameEn && !lastNameEn,
            then: (schema) => schema.required(t('errors.requiredField')),
          }),
        lastNameRu: Yup.string()
          .trim()
          .max(MAX_LAST_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_LAST_NAME_CHARACTERS }))
          .matches(/(^$)|(^['’`а-яА-ЯЁё-\s()]+$)/, t('errors.rusLettersDash'))
          .when('firstNameRu', {
            is: (firstNameRu: string) => !!firstNameRu,
            then: (schema) => schema.required(t('errors.requiredField')),
          })
          .when(['firstNameEn', 'lastNameEn'], {
            is: (firstNameEn: string, lastNameEn: string) => !firstNameEn && !lastNameEn,
            then: (schema) => schema.required(t('errors.requiredField')),
          }),
        lastNameEn: Yup.string()
          .trim()
          .max(MAX_LAST_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_LAST_NAME_CHARACTERS }))
          .matches(/(^$)|(^['’`a-zA-Z-\s()]+$)/, t('errors.latinLettersDash'))
          .when('firstNameEn', {
            is: (firstNameEn: string) => !!firstNameEn,
            then: (schema) => schema.required(t('errors.requiredField')),
          })
          .when(['firstNameRu', 'lastNameRu'], {
            is: (firstNameRu: string, lastNameRu: string) => !firstNameRu && !lastNameRu,
            then: (schema) => schema.required(t('errors.requiredField')),
          }),
        gender: Yup.string().nullable(),
        readyForBusinessTrip: Yup.boolean().required(t('errors.requiredField')),
        aboutMe: Yup.string().max(MAX_ABOUT_ME_CHARACTERS, t('errors.characterLimit')).trim().nullable(),
        birthDate: Yup.date()
          .max(new Date(), t('errors.birthDateInvalid'))
          .min(
            new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
            t('errors.birthDateLimit'),
          )
          .nullable(),
        email: Yup.string().trim().email(t('errors.invalidFormat')).max(260, t('errors.invalidFormat')),
        citizenshipId: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
              value: Yup.string(),
            }),
          )
          .max(CITIZENSHIPS_LIMIT, t('errors.countriesLimit', { count: CITIZENSHIPS_LIMIT })),
        isBlocked: Yup.boolean().required(),
        isBlockedReason: Yup.string()
          .trim()
          .when('isBlocked', {
            is: (isBlocked: boolean) => isBlocked,
            then: (schema) => schema.max(500, t('errors.characterLimit')).required(t('errors.blackListReason')),
          }),
        relocationAvailability: Yup.boolean(),
        sourceId: Yup.string().required(t('errors.requiredField')),
        sourceDetails: Yup.string()
          .trim()
          .when('sourceId', {
            is: (sourceId: string) => CandidateSourceNeedClarificationIds.includes(sourceId),
            then: (schema) => schema.required(t('errors.sourceDetails')).max(200, t('errors.characterLimit')),
          }),
        candidateReferral: Yup.object()
          .shape({
            recommenderEmail: Yup.string()
              .transform((value) => (!value ? '' : value))
              .test('test', t('errors.requiredField'), function (recommenderEmail, context) {
                const sourceId = context.from[1]?.value?.['sourceId'] || '';
                if (sourceId !== CandidateSourceNeedReferralIds) return true;
                return !!recommenderEmail;
              }),
            reward: Yup.string()
              .trim()
              .matches(/(^$)|(^\d+$)/, t('errors.invalidFormat'))
              .max(10, t('errors.maxCharacters', { count: 10 }))
              .test('test', t('errors.requiredField'), function (amount, context) {
                const sourceId = context.from[1]?.value?.['sourceId'] || '';
                if (sourceId !== CandidateSourceNeedReferralIds) return true;
                return !!amount;
              })
              .test('test', t('errors.moreThanZero'), function (amount, context) {
                const sourceId = context.from[1]?.value?.['sourceId'] || '';
                if (sourceId !== CandidateSourceNeedReferralIds) return true;
                return Number(amount) > 0;
              }),
            referralComment: Yup.string().trim().max(300, t('errors.characterLimit')),
            startGrade: Yup.string()
              .trim()
              .test('test', t('errors.requiredField'), function (grade, context) {
                const sourceId = context.from[1]?.value?.['sourceId'] || '';
                if (sourceId !== CandidateSourceNeedReferralIds) return true;
                return !!grade;
              }),
          })
          .required(),
        candidateLinks: Yup.array()
          .of(
            Yup.object().shape(
              {
                name: Yup.string()
                  .trim()
                  .max(MAX_LINK_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_LINK_NAME_CHARACTERS }))
                  .when('url', {
                    is: (url: string) => !!url,
                    then: (schema) => schema.required(t('errors.requiredField')),
                  })
                  .test('test', t('errors.valueAlreadyExist'), function (name, context) {
                    const links = context.from[1].value.candidateLinks;
                    const index = parseInt(this.path.replace(/[^\d]/g, ''));
                    const duplicates: number[] = [];
                    links.map((curr, idx) => {
                      if (name && curr.name?.trim() === name.trim()) {
                        duplicates.push(idx);
                      }
                    });
                    return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
                  }),
                url: Yup.string()
                  .trim()
                  .max(MAX_LINK_URL_CHARACTERS, t('errors.characterLimit'))
                  .url(t('errors.invalidFormat'))
                  .when('name', {
                    is: (name: string) => !!name,
                    then: (schema) => schema.required(t('errors.requiredField')),
                  })
                  .test('test', t('errors.valueAlreadyExist'), function (url, context) {
                    const links = context.from[1].value.candidateLinks;
                    const index = parseInt(this.path.replace(/[^\d]/g, ''));
                    const duplicates: number[] = [];
                    links.map((curr, idx) => {
                      if (url && curr.url?.trim() === url.trim()) {
                        duplicates.push(idx);
                      }
                    });
                    return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
                  }),
              },
              [['url', 'name']],
            ),
          )
          .max(MaxRefsCount, t('errors.candidateLinksCount', { count: MaxRefsCount })),
        candidateContacts: Yup.array().of(
          Yup.object().shape({
            contactType: Yup.string().required(),
            contact: Yup.object()
              .when('contactType', {
                is: (contactType: string) => contactType === ContactsType.TELEGRAM,
                then: (schema) =>
                  schema.shape({
                    value: telegramSchema,
                  }),
              })
              .when('contactType', {
                is: (contactType: string) => contactType === ContactsType.LINKEDIN,
                then: (schema) =>
                  schema.shape({
                    value: Yup.string()
                      .trim()
                      .max(MAX_LINK_URL_CHARACTERS, t('errors.characterLimit'))
                      .matches(/(^$)|(^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/)/gim, t('errors.invalidFormat')),
                  }),
              })
              .when('contactType', {
                is: (contactType: string) => contactType === ContactsType.GITHUB,
                then: (schema) =>
                  schema.shape({
                    value: Yup.string()
                      .trim()
                      .max(MAX_LINK_URL_CHARACTERS, t('errors.characterLimit'))
                      .matches(/(^$)|^(.*?(\bgithub\b)[^$]*)$/g, t('errors.invalidFormat')),
                  }),
              })
              .when('contactType', {
                is: (contactType: string) => contactType === ContactsType.SKYPE,
                then: (schema) =>
                  schema.shape({
                    value: Yup.string()
                      .trim()
                      .max(MAX_SKYPE_CHARACTERS, t('errors.characterLimit'))
                      // eslint-disable-next-line no-useless-escape
                      .matches(/(^$)|(^[A-Za-z0-9_,\-\.\:]+$)/i, t('errors.invalidFormat')),
                  }),
              })
              .when('contactType', {
                is: (contactType: string) => contactType === ContactsType.BEHANCE,
                then: (schema) =>
                  schema.shape({
                    value: Yup.string()
                      .trim()
                      .max(MAX_LINK_URL_CHARACTERS, t('errors.characterLimit'))
                      .matches(
                        /(^$)|(^(http(s?):\/\/)?(www\.)?behance\.([a-z])+\/([A-Za-z0-9]{1,}))/i,
                        t('errors.invalidFormat'),
                      ),
                  }),
              })
              .when('contactType', {
                is: (contactType: string) =>
                  contactType === ContactsType.PHONE || contactType === ContactsType.ADDITIONAL_PHONE,
                then: (schema) =>
                  schema.shape({
                    value: Yup.string()
                      .trim()
                      .test('test', t('errors.invalidPhoneFormat'), function (value) {
                        if (value) {
                          if (!value.match(/^\d+$/)) return false;
                          const country = countryData.find((item) => item.isoCode === this.parent.country);
                          if (!country && value.length) return false;
                          if (!country) return true;
                          const formatsLength: number[] =
                            country.format.map((item) => {
                              return (
                                item.split('').filter((curr) => curr === '#' || +curr).length +
                                country.dialCode.length -
                                1
                              );
                            }) || [];
                          const isCorrectLength = !!formatsLength.find((item) => item === value.length);
                          return isCorrectLength && `+${value}`.startsWith(country.dialCode);
                        }
                        return true;
                      }),
                    country: Yup.string(),
                  }),
              }),
          }),
        ),
        candidateExperiences: Yup.array().of(
          Yup.object()
            .shape(
              {
                company: Yup.string()
                  .trim()
                  .max(200, t('errors.characterLimit'))
                  .when(['workedFrom', 'workedTo', 'stillWorking', 'itSphere', 'comment', 'position'], {
                    is: (
                      workedFrom: string,
                      workedTo: string,
                      stillWorking: boolean,
                      itSphere: boolean,
                      comment: string,
                      position: string,
                    ) => {
                      return (workedFrom || workedTo || comment || stillWorking || itSphere) && !position;
                    },
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                position: Yup.string()
                  .trim()
                  .max(150, t('errors.characterLimit'))
                  .when(['workedFrom', 'workedTo', 'stillWorking', 'comment', 'company', 'itSphere'], {
                    is: (
                      workedFrom: string,
                      workedTo: string,
                      stillWorking: boolean,
                      comment: string,
                      company: string,
                      itSphere: boolean,
                    ) => (workedFrom || workedTo || comment || stillWorking || itSphere) && !company,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                workedFrom: Yup.date()
                  .nullable()
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .max(new Date(), t('errors.laterDate'))
                  .when(['position', 'workedTo', 'stillWorking', 'comment', 'company', 'itSphere'], {
                    is: (
                      position: string,
                      workedTo: string,
                      stillWorking: boolean,
                      comment: string,
                      company: string,
                      itSphere: boolean,
                    ) => position || workedTo || comment || stillWorking || company || itSphere,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                workedTo: Yup.date()
                  .nullable()
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .max(new Date(), t('errors.laterDate'))
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .when(['workedFrom', 'position', 'stillWorking', 'comment', 'company', 'itSphere'], {
                    is: (
                      workedFrom: string,
                      position: string,
                      stillWorking: boolean,
                      comment: string,
                      company: string,
                      itSphere: boolean,
                    ) => (workedFrom || position || comment || company || itSphere) && !stillWorking,
                    then: (schema) => schema.required(t('errors.noValue')),
                  })
                  .test('test', t('errors.earlierStartDate'), function (value) {
                    return this.parent.workedFrom && value
                      ? new Date(this.parent.workedFrom).getTime() <= new Date(value).getTime()
                      : true;
                  }),
                stillWorking: Yup.boolean(),
                itSphere: Yup.boolean(),
                comment: Yup.string().trim().max(2000, t('errors.characterLimit')).trim(),
              },
              [
                ['company', 'position'],
                ['workedFrom', 'workedTo'],
                ['workedFrom', 'position'],
                ['position', 'workedTo'],
                ['workedFrom', 'company'],
                ['company', 'workedTo'],
              ],
            )
            .test('test', t('errors.valueAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (
                  curr.company?.trim() === item.company?.trim() &&
                  curr.position?.trim() === item.position?.trim() &&
                  item.workedFrom &&
                  (!isNaN(Date.parse(curr.workedFrom?.toString()))
                    ? format(new Date(curr.workedFrom), DateFormats.DayFirst)
                    : null) ===
                    (!isNaN(Date.parse(curr.workedFrom?.toString()))
                      ? format(new Date(item.workedFrom), DateFormats.DayFirst)
                      : null)
                ) {
                  duplicates.push(idx);
                }
              });
              if (!(duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0)) {
                return this.createError({
                  message: t('errors.valueAlreadyExist'),
                  path: `candidateExperiences.${index}.${item.company ? 'company' : 'position'}`,
                });
              }
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            }),
        ),
        candidateProfessions: Yup.array()
          .of(
            Yup.object()
              .shape({
                professionId: Yup.string().when('gradeId', {
                  is: (gradeId: string) => !!gradeId,
                  then: (schema) => schema.required(t('errors.noSpecialty')),
                }),
                gradeId: Yup.string(),
              })
              .test('test', t('errors.professionAlreadyExist'), function (item) {
                const index = parseInt(this.path.replace(/[^\d]/g, ''));
                const duplicates: number[] = [];
                this.parent.map((curr, idx) => {
                  if (curr.professionId === item.professionId && item.professionId) {
                    duplicates.push(idx);
                  }
                });
                if (!(duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0)) {
                  return this.createError({
                    message: t('errors.professionAlreadyExist'),
                    path: `candidateProfessions.${index}.professionId`,
                  });
                }
                return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
              })
              .test(
                'professionsCount',
                t('errors.maxProfessionsCount', { count: MAX_CANDIDATE_PROFESSIONS }),
                function () {
                  return this.parent.length <= MAX_CANDIDATE_PROFESSIONS;
                },
              ),
          )
          .test('test', t('errors.requiredField'), function (professions) {
            if (!professions?.some((item) => !!item.professionId)) {
              return this.createError({
                message: t('errors.requiredField'),
                path: 'candidateProfessions.0.professionId',
              });
            }
            return professions?.some((item) => !!item.professionId);
          }),
        candidateLanguages: Yup.array().of(
          Yup.object()
            .shape({
              languageId: Yup.string().when('languageLevelId', {
                is: (languageLevelId: string) => !!languageLevelId,
                then: (schema) => schema.required(t('errors.noLanguage')),
              }),
              languageLevelId: Yup.string(),
            })
            .test('test', t('errors.languageAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (curr.languageId === item.languageId && item.languageId) {
                  duplicates?.push(idx);
                }
              });
              if (!(duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0)) {
                return this.createError({
                  message: t('errors.languageAlreadyExist'),
                  path: `candidateLanguages.${index}.languageId`,
                });
              }
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            })
            .test('maxLanguages', t('errors.maxLanguagesCount', { count: MAX_CANDIDATE_LANGUAGES }), function () {
              return this.parent.length <= MAX_CANDIDATE_LANGUAGES;
            }),
        ),
        responsibleEmployee: Yup.number()
          .required(t('errors.requiredField'))
          .transform((value) => (!value ? null : value))
          .test('test', t('pages.candidates.candidateCreation.deletedEmployee'), function (responsibleEmployee) {
            if (
              currentCandidate?.responsibleEmployee.lifecycleStatus === LifecycleStatuses.Deleted &&
              currentCandidate?.responsibleEmployee.employeeId === responsibleEmployee
            ) {
              return false;
            } else {
              return true;
            }
          })
          .test('test', t('errors.candidateResponsibleRole'), function (responsibleEmployee) {
            if (!rolesEmployeesOptions.length) return true;
            return !!rolesEmployeesOptions.find((item) => item.value === responsibleEmployee.toString());
          }),
        candidateVisas: Yup.array().of(
          Yup.object().shape({
            visaTypeId: Yup.string().nullable(),
            validTo: Yup.date()
              .nullable()
              .test('min date', t('errors.visaPeriod'), function (validTo, context) {
                if (validTo === null) return true;
                if (context.parent.isFullVisaDate) {
                  return (
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() <=
                    new Date(validTo).getTime()
                  );
                }
                const newDate = new Date(validTo);
                newDate.setDate(1);
                newDate.setMonth(newDate.getMonth() + 1);
                newDate.setDate(-1);
                return (
                  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() <=
                  newDate.getTime()
                );
              }),
            isFullVisaDate: Yup.boolean(),
          }),
        ),
        candidateSalary: Yup.object().shape(
          {
            currencyId: Yup.string().required(t('errors.requiredField')),
            expectedSalaryMin: Yup.string()
              .trim()
              .transform((value) => value || '')
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.PLN,
                then: (schema) =>
                  schema.when('expectedSalaryMin', {
                    is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 6,
                    then: (schema) =>
                      schema.matches(
                        /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.BYN,
                then: (schema) =>
                  schema.when('expectedSalaryMin', {
                    is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 6,
                    then: (schema) =>
                      schema.matches(
                        /^100000$/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.USD,
                then: (schema) =>
                  schema.when('expectedSalaryMin', {
                    is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 5,
                    then: (schema) =>
                      schema.matches(
                        /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.EUR,
                then: (schema) =>
                  schema.when('expectedSalaryMin', {
                    is: (expectedSalaryMin: string) => expectedSalaryMin?.length === 5,
                    then: (schema) =>
                      schema.matches(
                        /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                      ),
                  }),
              })
              .test('test', t('errors.salaryDifferenceMin'), function (salaryMin: string) {
                if (!parseInt(salaryMin) || !parseInt(this.parent.expectedSalaryMax)) return true;
                return parseInt(salaryMin) <= parseInt(this.parent.expectedSalaryMax);
              }),
            expectedSalaryMax: Yup.string()
              .trim()
              .transform((value) => value || '')
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.PLN,
                then: (schema) =>
                  schema.when('expectedSalaryMax', {
                    is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 6,
                    then: (schema) =>
                      schema.matches(
                        /(^1[0-1]\d\d\d\d$)|(^120000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_PLN }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.BYN,
                then: (schema) =>
                  schema.when('expectedSalaryMax', {
                    is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 6,
                    then: (schema) =>
                      schema.matches(
                        /^100000$/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d?\d?$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_BYN }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.USD,
                then: (schema) =>
                  schema.when('expectedSalaryMax', {
                    is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 5,
                    then: (schema) =>
                      schema.matches(
                        /(^[1-2][0-9]\d\d\d$)|(^30000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_USD }),
                      ),
                  }),
              })
              .when(['currencyId'], {
                is: (currencyId: string) => currencyId === CurrencyEnum.EUR,
                then: (schema) =>
                  schema.when('expectedSalaryMax', {
                    is: (expectedSalaryMax: string) => expectedSalaryMax?.length === 5,
                    then: (schema) =>
                      schema.matches(
                        /(^[2][0-4]\d\d\d$)|(^1\d\d\d\d$)|(^25000$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                      ),
                    otherwise: (schema) =>
                      schema.matches(
                        /(^[1-9]\d?\d?\d??$)|(^$)|(^0$)/,
                        t('errors.betweenNumbers', { min: MIN_CANDIDATE_SALARY, max: MAX_CANDIDATE_SALARY_EUR }),
                      ),
                  }),
              })
              .test('test', t('errors.salaryDifferenceMax'), function (salaryMax: string) {
                if (!parseInt(salaryMax) || !parseInt(this.parent.expectedSalaryMin)) return true;
                return parseInt(salaryMax) >= parseInt(this.parent.expectedSalaryMin);
              }),
            expectedSalaryComment: Yup.string().trim().max(300, t('errors.characterLimit')),
          },
          [
            ['expectedSalaryMin', 'expectedSalaryMin'],
            ['expectedSalaryMax', 'expectedSalaryMax'],
          ],
        ),
        candidateLocation: Yup.object().shape({
          locationCountryId: Yup.string().when('locationCityId', {
            is: (locationCity: string) => !!locationCity,
            then: (schema) => schema.required(t('errors.noValue')),
          }),
          currentLocationCityId: Yup.string(),
          currentLocationCountryId: Yup.string().when('currentLocationCityId', {
            is: (currentLocationCity: string) => !!currentLocationCity,
            then: (schema) => schema.required(t('errors.noValue')),
          }),
          locationCityId: Yup.string(),
        }),
        candidateDomains: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
              value: Yup.string(),
            }),
          )
          .max(DOMAIN_LIMIT, t('errors.domainLimit', { count: DOMAIN_LIMIT })),
        candidateSkills: Yup.string().trim().max(2000, t('errors.characterLimit')),
        candidateTechnologies: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
              value: Yup.string(),
            }),
          )
          .max(TECHNOLOGY_LIMIT, t('errors.technologyLimit', { count: TECHNOLOGY_LIMIT })),
        candidateWorkFormats: Yup.array().of(
          Yup.object().shape({
            dictionaryValueId: Yup.string(),
            checked: Yup.boolean(),
          }),
        ),
        candidateWorkloads: Yup.array().of(
          Yup.object().shape({
            dictionaryValueId: Yup.string(),
            checked: Yup.boolean(),
          }),
        ),
        candidateRelocationCountries: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
              value: Yup.string(),
            }),
          )
          .max(COUNTRIES_LIMIT, t('errors.countriesLimit', { count: COUNTRIES_LIMIT })),
        candidateEducations: Yup.array().of(
          Yup.object()
            .shape(
              {
                studyPlace: Yup.string()
                  .trim()
                  .max(200, t('errors.characterLimit'))
                  .when(['profession', 'studiedFrom', 'studiedTo', 'comment', 'withoutPeriod'], {
                    is: (
                      profession: string,
                      studiedFrom: string,
                      studiedTo: string,
                      comment: string,
                      withoutPeriod: boolean,
                    ) => profession || studiedFrom || studiedTo || comment || withoutPeriod,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                profession: Yup.string().trim().max(150, t('errors.characterLimit')),
                studiedFrom: Yup.date()
                  .nullable()
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .max(
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    t('errors.laterDate'),
                  )
                  .when(['profession', 'studyPlace', 'studiedTo', 'comment', 'withoutPeriod'], {
                    is: (
                      profession: string,
                      studyPlace: string,
                      studiedTo: string,
                      comment: string,
                      withoutPeriod: boolean,
                    ) => (profession || studyPlace || studiedTo || comment) && !withoutPeriod,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                studiedTo: Yup.date()
                  .nullable()
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .max(
                    new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .when(['profession', 'studiedFrom', 'studyPlace', 'comment', 'withoutPeriod'], {
                    is: (
                      profession: string,
                      studiedFrom: string,
                      studyPlace: string,
                      comment: string,
                      withoutPeriod: boolean,
                    ) => (profession || studiedFrom || studyPlace || comment) && !withoutPeriod,
                    then: (schema) => schema.required(t('errors.noValue')),
                  })
                  .test('test', t('errors.earlierStartDate'), function (value) {
                    return this.parent.studiedFrom && value
                      ? new Date(this.parent.studiedFrom).getTime() <= new Date(value).getTime()
                      : true;
                  }),
                withoutPeriod: Yup.boolean().required(),
                comment: Yup.string().trim().max(2000, t('errors.characterLimit')).trim(),
              },
              [
                ['studiedFrom', 'studiedTo'],
                ['studiedFrom', 'studyPlace'],
                ['studiedTo', 'studyPlace'],
              ],
            )
            .test('test', t('errors.valueAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (
                  curr.studyPlace?.trim() === item.studyPlace?.trim() &&
                  item.studyPlace?.trim() &&
                  curr.profession?.trim() === item.profession?.trim() &&
                  item.studiedFrom &&
                  (!isNaN(Date.parse(curr.studiedFrom?.toString()))
                    ? format(new Date(curr.studiedFrom), DateFormats.DayFirst)
                    : null) ===
                    (!isNaN(Date.parse(curr.studiedFrom?.toString()))
                      ? format(new Date(item.studiedFrom), DateFormats.DayFirst)
                      : null)
                ) {
                  duplicates.push(idx);
                }
              });
              if (!(duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0)) {
                return this.createError({
                  message: t('errors.valueAlreadyExist'),
                  path: `candidateEducations.${index}.studyPlace`,
                });
              }
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            }),
        ),
        candidateCourses: Yup.array().of(
          Yup.object()
            .shape(
              {
                studyPlace: Yup.string()
                  .trim()
                  .max(200, t('errors.characterLimit'))
                  .when(['courseName', 'studiedFrom', 'studiedTo', 'comment'], {
                    is: (courseName: string, studiedFrom: string, studiedTo: string, comment: string) =>
                      courseName || studiedFrom || studiedTo || comment,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                courseName: Yup.string().trim().max(300, t('errors.characterLimit')),
                studiedFrom: Yup.date()
                  .nullable()
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .max(
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    t('errors.laterDate'),
                  )
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .when(['studiedTo'], {
                    is: (studiedTo: string) => !!studiedTo,
                    then: (schema) => schema.required(t('errors.noValue')),
                  }),
                studiedTo: Yup.date()
                  .nullable()
                  .min(
                    new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .max(
                    new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
                    t('errors.invalidDate'),
                  )
                  .transform((value) => (isNaN(Date.parse(value?.toString())) ? null : value))
                  .test('test', t('errors.earlierStartDate'), function (value) {
                    return this.parent.studiedFrom && value
                      ? new Date(this.parent.studiedFrom).getTime() <= new Date(value).getTime()
                      : true;
                  }),
                comment: Yup.string().trim().max(2000, t('errors.characterLimit')).trim(),
              },
              [
                ['studiedFrom', 'studiedTo'],
                ['studiedFrom', 'studyPlace'],
                ['studiedTo', 'studyPlace'],
              ],
            )
            .test('test', t('errors.valueAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (
                  curr.studyPlace?.trim() === item.studyPlace?.trim() &&
                  item.studyPlace?.trim() &&
                  curr.courseName?.trim() === item.courseName?.trim() &&
                  (!isNaN(Date.parse(curr.studiedFrom?.toString()))
                    ? format(new Date(curr.studiedFrom), DateFormats.DayFirst)
                    : null) ===
                    (!isNaN(Date.parse(curr.studiedFrom?.toString()))
                      ? format(new Date(item.studiedFrom), DateFormats.DayFirst)
                      : null)
                ) {
                  duplicates.push(idx);
                }
              });
              if (!(duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0)) {
                return this.createError({
                  message: t('errors.valueAlreadyExist'),
                  path: `candidateCourses.${index}.studyPlace`,
                });
              }
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            }),
        ),
      },
      [
        ['firstNameRu', 'lastNameRu'],
        ['firstNameEn', 'lastNameEn'],
        ['lastNameEn', 'lastNameRu'],
        ['firstNameEn', 'lastNameRu'],
        ['firstNameRu', 'lastNameEn'],
        ['firstNameRu', 'firstNameEn'],
      ],
    );
  }, [t, currentCandidate, rolesEmployeesOptions]);

  return { validationSchema };
};
