import React from 'react';
import * as Styled from './shadow-collapse.styles';
import { Collapse } from '@mui/material';

interface ShadowCollapseProps extends React.PropsWithChildren {
  isOpen: boolean | null;
  handleOpen: () => void;
  commentRef: React.MutableRefObject<HTMLDivElement>;
  collapsedSize?: number;
  shadowColor?: string;
}

const ShadowCollapse: React.FC<ShadowCollapseProps> = ({
  isOpen,
  shadowColor,
  handleOpen,
  commentRef,
  collapsedSize,
  children,
}) => {
  return (
    <Styled.Block isOpen={isOpen} shadowColor={shadowColor}>
      <Collapse
        in={isOpen === null ? true : isOpen}
        onClick={handleOpen}
        {...(isOpen !== null ? { collapsedSize } : {})}
      >
        <div ref={commentRef}>{children}</div>
      </Collapse>
    </Styled.Block>
  );
};

export default React.memo(ShadowCollapse);
