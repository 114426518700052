import { styled } from '@innowise-group/mui-kit';

export const ToggleContainer = styled.button<{ isLight: boolean }>`
  background-color: transparent;
  border: 1px solid
    ${({ theme, isLight }) => (isLight ? theme.palette.general.lightGrey[100] : theme.palette.general.darkGrey[20])};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  width: 62px;
  height: 32px;

  overflow: hidden;
  position: relative;
`;

export const IconsContainer = styled.div<{ isLight: boolean }>`
  position: absolute;
  display: inline-block;
  transition: ${({ theme }) => theme.animation.transition};
  left: ${({ isLight }) => (isLight ? '10px' : '32px')};
`;

export const Ball = styled.div<{ isLight: boolean }>`
  background-color: ${({ theme, isLight }) =>
    isLight ? theme.palette.general.lightGrey[100] : theme.palette.general.darkGrey[20]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  transition: all ${({ theme }) => theme.animation.transition} linear;
  position: relative;
  z-index: 10;
  transform: ${({ isLight }) => (!isLight ? 'translateX(4px)' : 'translateX(33px)')};
  -webkit-transform: ${({ isLight }) => (!isLight ? 'translate3d(4px,0,0)' : 'translate3d(33px,0,0)')};
`;
