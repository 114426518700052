import React, { useCallback, useEffect } from 'react';
import * as Styled from './condition-section.styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Select } from '@innowise-group/mui-kit';
import { SelectOption } from '@innowise-group/core';
import { Icon } from '@innowise-group/mui-kit';
import {
  useControlFormField,
  useCurrentCandidate,
  useOptionsApi,
  useVacanciesOptionsApi,
  useValidationSchemaCandidate,
} from '@innowise-group/core';
import { LabeledCheckbox, VacancySelect } from '@shared-mui-components';

interface ConditionSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const ConditionSection: React.FC<ConditionSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { currencyOptions, workFormatsOptions, workloadsOptions, employmentFormsOptions } = useOptionsApi();
  const candidate = useCurrentCandidate();
  const {
    handleSearchVacancyOptions,
    getInitialOptions,
    onSelectVacancy,
    updatesVacanciesOptions,
    clearVacanciesOptions,
  } = useVacanciesOptionsApi();

  const { validationSchema } = useValidationSchemaCandidate();
  const { handlerValidate } = useControlFormField(validationSchema);
  const { control, watch, setValue, clearErrors, trigger } = useFormContext();
  const isBlocked = watch('isBlocked');
  const currency = watch('candidateSalary.currencyId');
  const vacancyIdWithRequestAttached = watch('vacancyIdWithRequestAttached');
  const vacancyIdWithRequestAttachedString = vacancyIdWithRequestAttached
    ? vacancyIdWithRequestAttached?.toString()
    : '';

  const onVacancyChangeHandler = (onChange: (event: SelectOption[]) => void) => (val: SelectOption[]) => {
    const ids = val.map((item) => item.value);
    onSelectVacancy(ids);
    onChange(val);
  };

  const getOptionDisabledHandle = useCallback(
    (option) => {
      if (!vacancyIdWithRequestAttachedString) return false;
      return option.value?.toString() === vacancyIdWithRequestAttachedString;
    },
    [vacancyIdWithRequestAttachedString],
  );

  const handleChangeSalaryLimit =
    (fieldsName: string, onChange: (data: React.ChangeEvent<HTMLInputElement>) => void) =>
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange(data);
      handlerValidate(fieldsName);
    };

  useEffect(() => {
    if (!isBlocked) {
      setValue('isBlockedReason', '');
      clearErrors('isBlockedReason');
    }
  }, [isBlocked, setValue, clearErrors]);

  useEffect(() => {
    trigger('candidateSalary');
  }, [trigger, currency]);

  useEffect(() => {
    return () => {
      clearVacanciesOptions();
    };
  }, []);

  useEffect(() => {
    if (candidate?.candidatePrioritizedVacancies) {
      const ids = candidate.candidatePrioritizedVacancies.map(({ id }) => id.toString());
      updatesVacanciesOptions(candidate.candidatePrioritizedVacancies);
      onSelectVacancy(ids);
    }
  }, [candidate?.candidatePrioritizedVacancies]);

  return (
    <React.Fragment>
      <Styled.VacancyWrapper>
        {isDiff && <Styled.CustomVacanciesDiff />}
        <Controller
          name="vacancy"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => {
            return (
              <VacancySelect
                multiple
                value={value}
                disableCloseOnSelect
                limitTags={1}
                onClose={getInitialOptions}
                onChange={onVacancyChangeHandler(onChange)}
                disabled={disabled}
                getOptionDisabled={getOptionDisabledHandle}
                {...(vacancyIdWithRequestAttachedString && {
                  disabledChips: [vacancyIdWithRequestAttachedString],
                })}
                renderInput={(params) => (
                  <Input
                    {...params}
                    inputRef={ref}
                    onChange={handleSearchVacancyOptions}
                    label={t('pages.candidates.candidateCreation.vacancy')}
                    placeholder={t('pages.candidates.candidateCreation.selectTheVacancy')}
                    error={!!errors['vacancy']?.message}
                    helperText={errors['vacancy']?.message.toString()}
                  />
                )}
              />
            );
          }}
        />
      </Styled.VacancyWrapper>
      <Styled.BoxForDiffWorkConditions>
        <Styled.WorkFormatsContainer>
          {isDiff && (
            <Styled.CustomStringArrayDataDiff field="employmentForms" i18Prefix="options.employmentFormsOptions_" />
          )}
          <Styled.FormatsTitle>{t('pages.requests.viewModal.formOfEmployment')}</Styled.FormatsTitle>
          {employmentFormsOptions?.map((item, index) => (
            <Controller
              name={`employmentForms.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, onChange } }) => (
                <LabeledCheckbox disabled={disabled} onChange={onChange} checked={value} label={item.title} />
              )}
            />
          ))}
        </Styled.WorkFormatsContainer>

        <Styled.FormatsContainer>
          {isDiff && (
            <Styled.CustomStringArrayDataDiff
              field="candidateWorkFormats"
              i18Prefix="options.candidateWorkFormatsOptions_"
            />
          )}

          <Styled.FormatsTitle>{t('pages.candidates.candidateDetails.formatOfWork')}</Styled.FormatsTitle>
          {workFormatsOptions?.map((item, index) => (
            <Controller
              name={`candidateWorkFormats.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, onChange } }) => (
                <LabeledCheckbox disabled={disabled} onChange={onChange} checked={value} label={item.title} />
              )}
            />
          ))}
          <Styled.WorkLoadsContainer>
            {isDiff && (
              <Styled.CustomStringArrayDataDiff
                field="candidateWorkloads"
                i18Prefix="options.candidateWorkloadsOptions_"
              />
            )}
            <Styled.FormatsTitle>{t('pages.candidates.candidateDetails.employmentType')}</Styled.FormatsTitle>
            {workloadsOptions?.map((item, index) => (
              <Controller
                name={`candidateWorkloads.${index}.checked`}
                control={control}
                key={item.value}
                render={({ field: { value, onChange } }) => (
                  <LabeledCheckbox disabled={disabled} onChange={onChange} checked={value} label={item.title} />
                )}
              />
            ))}
          </Styled.WorkLoadsContainer>
        </Styled.FormatsContainer>
      </Styled.BoxForDiffWorkConditions>
      <Styled.BoxForSalaryDiffConsolidation>
        {isDiff && <Styled.CustomSalaryDiff />}
        <Styled.SalaryContainer>
          <Controller
            name="candidateSalary.expectedSalaryMin"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={`${t('pages.candidates.candidateCreation.salary')} min`}
                error={!!errors.candidateSalary?.['expectedSalaryMin']?.message}
                helperText={errors.candidateSalary?.['expectedSalaryMin']?.message}
                value={value}
                inputRef={ref}
                onChange={handleChangeSalaryLimit('candidateSalary.expectedSalaryMax', onChange)}
                placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                InputProps={{
                  endAdornment: (
                    <Styled.SalaryIconContainer>
                      <Icon type="u_lock" viewBox="0 0 19 15" fill="transparent" />
                    </Styled.SalaryIconContainer>
                  ),
                }}
              />
            )}
          />
        </Styled.SalaryContainer>
        <Styled.SalaryContainer>
          <Controller
            name="candidateSalary.expectedSalaryMax"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Input
                disabled={disabled}
                label={`${t('pages.candidates.candidateCreation.salary')} max`}
                error={!!errors.candidateSalary?.['expectedSalaryMax']?.message}
                helperText={errors.candidateSalary?.['expectedSalaryMax']?.message}
                value={value}
                inputRef={ref}
                onChange={handleChangeSalaryLimit('candidateSalary.expectedSalaryMin', onChange)}
                placeholder={t('pages.candidates.candidateCreation.enterAValue')}
                InputProps={{
                  endAdornment: (
                    <Styled.SalaryIconContainer>
                      <Icon type="u_lock" viewBox="0 0 19 15" fill="transparent" />
                    </Styled.SalaryIconContainer>
                  ),
                }}
              />
            )}
          />
        </Styled.SalaryContainer>
        <Styled.CurrencyContainer>
          <Controller
            name="candidateSalary.currencyId"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Select
                disabled={disabled}
                value={value}
                onChange={onChange}
                options={currencyOptions || []}
                disableClearable
                disableSortByAlphabet={false}
                renderInput={(params) => (
                  <Input
                    {...params}
                    required
                    inputRef={ref}
                    label={t('pages.candidates.candidateCreation.currency')}
                    placeholder={t('pages.candidates.candidateCreation.selectCurrency')}
                    helperText={errors?.candidateSalary?.['currencyId']?.message?.toString()}
                    error={!!errors?.candidateSalary?.['currencyId']?.message}
                  />
                )}
              />
            )}
          />
        </Styled.CurrencyContainer>

        <Styled.SalaryCommentContainer>
          <Controller
            name="candidateSalary.expectedSalaryComment"
            control={control}
            render={({ field: { onChange, value, ref }, formState: { errors } }) => (
              <Styled.CommentInput
                disabled={disabled}
                label={t('pages.candidates.candidateCreation.comment')}
                value={value}
                onChange={onChange}
                rows={3}
                multiline
                fullWidth
                inputRef={ref}
                error={!!errors?.candidateSalary?.['expectedSalaryComment']?.message}
                helperText={errors?.candidateSalary?.['expectedSalaryComment']?.message}
                placeholder={t('pages.candidates.candidateCreation.addAComment')}
              />
            )}
          />
        </Styled.SalaryCommentContainer>
      </Styled.BoxForSalaryDiffConsolidation>
      <Styled.IsBlockedContainer>
        {isDiff && <Styled.CustomIsBlockedDiff />}
        <Controller
          name="isBlocked"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Styled.CustomLabeledCheckbox
              disabled={disabled}
              onChange={onChange}
              checked={value}
              label={t('pages.candidates.candidateCreation.blackList')}
            />
          )}
        />
        <Controller
          name="isBlockedReason"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Styled.CommentInput
              disabled={disabled || !isBlocked}
              label={t('pages.candidates.candidateCreation.reason')}
              value={value}
              onChange={onChange}
              multiline
              rows={3}
              inputRef={ref}
              error={!!errors['isBlockedReason']?.message}
              helperText={errors['isBlockedReason']?.message.toString()}
              placeholder={t('pages.candidates.candidateCreation.addAReason')}
            />
          )}
        />
      </Styled.IsBlockedContainer>
    </React.Fragment>
  );
};

export default React.memo(ConditionSection);
