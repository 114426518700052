import { profileFormDefaultValues } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProfileItem,
  useDefaultEditionProfileData,
  usePhoneAPI,
  useProfileAPI,
  useValidationSchemaProfile,
} from '@innowise-group/core';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { ProfileForm } from '@shared-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './profile-edition-modal.styles';
import { useCallback, useEffect, useState } from 'react';

const ProfileEditionModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const { validationSchema } = useValidationSchemaProfile();
  const profile = useDefaultEditionProfileData();
  const { profile: profileResponse } = useProfileAPI();
  const { updateProfileData, orgUnits, isLoading, loadProfile, clearProfileAvatar } = useProfileAPI();
  const methods = useForm<ProfileItem>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: profileFormDefaultValues,
  });
  const { handleSubmit, reset, setValue } = methods;
  const [isReseted, setIsReseted] = useState<boolean>(false);
  const [isSendRequest, setIsSendRequest] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const { fetchCountryByPhone } = usePhoneAPI();

  const handleChangeIsImageLoading = useCallback((value: boolean) => {
    setIsImageLoading(value);
  }, []);

  const onSubmit = async (data: ProfileItem) => {
    setIsSendRequest(true);
    await updateProfileData(data, profile.id, profileResponse.authData, close);
    setIsSendRequest(false);
  };

  useEffect(() => {
    reset({ ...profile, orgUnits });
    const handler = async () => {
      if (profile.phoneRt.value) {
        const country = await fetchCountryByPhone(profile.phoneRt.value);
        setValue('phoneRt.country', country.payload.toString());
      }
      setIsReseted(true);
    };
    handler();
    return () => {
      loadProfile();
    };
  }, [reset, loadProfile, profile.phoneRt?.value, fetchCountryByPhone]);

  useEffect(() => {
    return () => {
      reset();
      clearProfileAvatar();
      setIsSendRequest(false);
    };
  }, [clearProfileAvatar]);

  return (
    <Styled.Container size="standard">
      {isLoading || !isReseted ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={close}>
            <Styled.Header>
              <span>{t('pages.profile.creationModal.editionProfile')}</span>
            </Styled.Header>
          </Modal.Header>
          <Modal.Body>
            <ProfileForm handleChangeIsImageLoading={handleChangeIsImageLoading} />
          </Modal.Body>
          <Modal.Actions
            noVerticalPadding={true}
            position={'right'}
            actions={[
              {
                title: t('buttons.cancel'),
                onClick: close,
                type: 'button',
                disabled: isSendRequest || isImageLoading,
              },
              {
                title: t('buttons.save'),
                onClick: handleSubmit(onSubmit),
                highlighted: true,
                type: 'button',
                disabled: isSendRequest || isImageLoading,
              },
            ]}
          />
        </FormProvider>
      )}
    </Styled.Container>
  );
};

export default modalHoc(ProfileEditionModal, 'profile-edition');
