import * as Styled from './date-range.styles';
import { CustomDatePicker } from '@innowise-group/mui-kit';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from '../shared';
import { type DateRangeProps } from '../../../../types';

const DateRange: React.FC<DateRangeProps> = ({
  state,
  labelKey,
  isOpen,
  onValueChange,
  onToggleFilter,
  onRemoveFilter,
  onClickFilterOutside,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onFromChange = (from: Date) => {
    if (from < new Date()) onValueChange({ ...state, from: from.toISOString() });
  };

  const onToChange = (to: Date) => {
    if (to < new Date()) onValueChange({ ...state, to: to.toISOString() });
  };

  const onRemoveFilterHandle = () => {
    onRemoveFilter({ ...state, from: null, to: null });
  };

  const onClickFilterOutsideHandle = () => {
    const isEmpty = !state.from && !state.to;
    if (isEmpty) onRemoveFilterHandle();
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Container ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandle}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          {(state.from || state.to) && (
            <Styled.SelectedCount>{`${
              state?.from ? new Date(state?.from)?.toLocaleDateString() : t('filters.notSelected')
            } - ${
              state?.to ? new Date(state?.to)?.toLocaleDateString() : t('filters.notSelected')
            }`}</Styled.SelectedCount>
          )}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          <CustomDatePicker
            startDate={state.from ? new Date(state.from) : null}
            endDate={state?.to || state.max ? new Date(state?.to || state.max) : null}
            selected={state.from ? new Date(state.from) : new Date()}
            maxDate={state?.to || state.max ? new Date(state?.to || state.max) : new Date()}
            maxYear={state?.to || state.max ? new Date(state?.to || state.max).getFullYear() : new Date().getFullYear()}
            minDate={state.min && new Date(state.min)}
            minYear={state.min && new Date(state.min).getFullYear()}
            onChange={onFromChange}
            inline
          />
        </Styled.FilterWrapper>
        <Styled.FilterWrapper>
          <CustomDatePicker
            selected={state.to ? new Date(state.to) : new Date()}
            maxDate={state.max ? new Date(state.max) : new Date()}
            startDate={state.from ? new Date(state.from) : null}
            endDate={state.to || state.max ? new Date(state.to || state.max) : null}
            minDate={(state?.from || state.min) && new Date(state?.from || state.min)}
            minYear={(state.from || state.min) && new Date(state.from || state.min).getFullYear()}
            maxYear={new Date(state.max).getFullYear() || new Date().getFullYear()}
            onChange={onToChange}
            inline
          />
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Container>
  );
};

export default memo(DateRange);
