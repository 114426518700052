import { ClaimStatusIcons, ClaimTypes, DateFormats } from '@constants';
import { LocalizedClaimItemType } from '@innowise-group/core';
import React from 'react';
import * as Styled from './employee-section.styles';
import { useTranslation } from 'react-i18next';
import { AvatarMenu } from '@shared-components';
import { format } from 'date-fns';
import { calculateDateWithTimeZone } from '@innowise-group/utilities';

interface EmployeeSectionProps {
  claims: LocalizedClaimItemType[];
}

const EmployeeSection: React.FC<EmployeeSectionProps> = ({ claims }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {claims.map(({ employee, claimType, active, deadline, id, status }) => (
        <Styled.SectionRowContainer key={id}>
          <Styled.AvatarContainer>
            <AvatarMenu
              photoId={employee.photoId}
              firstNameRu={employee.localizedFirstName}
              lastNameRu={employee.localizedLastName}
            />
            {active && <Styled.ExpectedIcon type={ClaimStatusIcons[status]} size={24} viewBox="0 0 32 32" />}
          </Styled.AvatarContainer>
          {claimType !== ClaimTypes.NOTIFICATION && (
            <Styled.RowContainer>
              {active && (
                <Styled.CheckboxInfoContainer>
                  {claimType === ClaimTypes.FEEDBACK
                    ? t('pages.candidates.statuses.requireFeedback')
                    : t('pages.candidates.statuses.requireAgreement')}
                </Styled.CheckboxInfoContainer>
              )}
              <Styled.DeadlineInfoContainer>
                {deadline && (
                  <React.Fragment>
                    <span>{t('pages.requests.viewModal.to')}</span>
                    <span>{format(calculateDateWithTimeZone(deadline), DateFormats.DaySlashFirst)}</span>
                    <span>{format(calculateDateWithTimeZone(deadline), DateFormats.OnlyTime)}</span>
                  </React.Fragment>
                )}
              </Styled.DeadlineInfoContainer>
            </Styled.RowContainer>
          )}
        </Styled.SectionRowContainer>
      ))}
    </React.Fragment>
  );
};

export default EmployeeSection;
