import { useMemo } from 'react';
import { DefaultTheme, StyledComponent } from 'styled-components';
import { useGlobalThemeContext, useTheme } from '../../../theme';
import { ThemeVariants } from '@constants';
import * as Styled from './button.styles';

export type ButtonVariant = 'normal' | 'outlined' | 'link' | 'text';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  variant?: ButtonVariant;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
  iconPosition?: 'left' | 'right';
  withLoader?: boolean;
}

const variantStyledMap: Record<ButtonVariant, StyledComponent<'button', DefaultTheme, object, never>> = {
  normal: Styled.ButtonContainer,
  link: Styled.LinkContainer,
  outlined: Styled.OutlinedContainer,
  text: Styled.TextContainer,
};

const Button: React.FC<ButtonProps> = ({
  icon,
  type = 'button',
  variant = 'normal',
  color,
  children,
  withLoader = false,
  iconPosition = 'left',
  disabled,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const Container = variantStyledMap[variant];
  const { theme: themeContext } = useGlobalThemeContext();

  const buttonColor = useMemo(() => {
    return themeContext === ThemeVariants.Dark && variant === 'outlined' ? theme.palette.general.darkGrey[100] : color;
  }, [themeContext, variant, color, theme]);

  return (
    <Container type={type} color={buttonColor} disabled={disabled} onClick={!disabled ? onClick : null} {...props}>
      {withLoader && (
        <Styled.LoaderContainer>
          <Styled.ButtonLoader />
        </Styled.LoaderContainer>
      )}
      {icon && iconPosition === 'left' && <Styled.IconContainer position={iconPosition}>{icon}</Styled.IconContainer>}
      {children}
      {icon && iconPosition === 'right' && <Styled.IconContainer position={iconPosition}>{icon}</Styled.IconContainer>}
    </Container>
  );
};

export default Button;
