import { styled } from '@innowise-group/mui-kit';
import descriptionContainerBackground from '../../assets/background.svg';
import top from '../../assets/top.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${descriptionContainerBackground});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DividersGroup = styled.div`
  width: 149px;
  height: 90px;
  background-image: url(${top});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  right: 2%;
  top: 2%;
`;

export const HeaderText = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.general.paper};
  text-align: center;
`;

export const AuthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 330px;
  margin: 3rem 0 3.75rem 0;
  border-radius: 29px;
  border: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.general.paper};
  cursor: pointer;
`;

export const AuthButtonText = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[70]}
  line-height: 22px;
  margin-left: 10px;
`;

export const EmojisContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 340px;
  background-color: transparent;
  align-items: center;
`;

export const EmojiContainer = styled.div`
  border-radius: 50%;
  height: 72px;
  width: 72px;
  border: 1px solid ${({ theme }) => theme.palette.general.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0;
  }
  @media (max-height: 550px) {
    display: none;
  }
`;

export const Version = styled.div`
  position: absolute;
  bottom: 80px;
  ${({ theme }) => theme.fonts.primary[70]};
  color: ${({ theme }) => theme.palette.general.paper};
`;

export const SupportLinkBlock = styled.div`
  width: 100%;
  height: 44px;
  border-top: 1px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.general.paper};
  ${({ theme }) => theme.fonts.primary[40]};
  position: relative;
  z-index: 3;
  &:hover {
    color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
`;

export const SupportLinkBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.general.paper};
  opacity: 0.3;
`;
