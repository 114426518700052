import { ProfileItem } from '@innowise-group/core';

export const profileFormDefaultValues: ProfileItem = {
  id: 0,
  miniPhotoUrl: null,
  phoneRt: {
    value: '',
    country: '',
  },
  firstNameRu: '',
  lastNameRu: '',
  orgUnits: [],
  position: '',
  telegramRt: '',
  email: '',
};
