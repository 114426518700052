import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { DateFormats } from '@constants';
import { CustomDatePicker } from '@innowise-group/mui-kit';
import * as Styled from '../dates-range.styles';

interface DesktopViewProps {
  minName: string;
  maxName: string;
  disabled: boolean;
}

const DesktopView: React.FC<DesktopViewProps> = ({ minName, maxName, disabled }) => {
  const { control, watch } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const startDate = watch('dateMin');
  const endDate = watch('dateMax');
  const formattedStartDate = startDate ? format(new Date(startDate), DateFormats.DayFirst) : '';
  const formattedEndDate = endDate ? format(new Date(endDate), DateFormats.DayFirst) : '';

  const inputValue = `${formattedStartDate} ${formattedEndDate && '-'} ${formattedEndDate}`.trim();

  const onInputClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!disabled) {
      setAnchorEl(e.target);
    }
  };

  const closeFilter = () => setAnchorEl(null);

  const minDatePickerProps = useMemo(() => {
    if (new Date().getTime > new Date(endDate).getTime) {
      return {
        maxDate: new Date(),
        maxYear: new Date().getFullYear(),
      };
    }
    return {
      maxDate: new Date(endDate),
      maxYear: new Date(endDate).getFullYear(),
    };
  }, [endDate]);

  return (
    <>
      <Styled.DateInput
        value={inputValue}
        onClick={onInputClick}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: <Styled.CalendarIcon type={'u_calendar'} size={20} />,
        }}
      />
      <Styled.DatePickersWrapper onClose={closeFilter} disablePortal={true} anchorEl={anchorEl} open={!!anchorEl}>
        <Styled.FilterWrapper>
          <Controller
            name={minName}
            control={control}
            render={({ field: { value, ...rest } }) => (
              <CustomDatePicker
                inline
                name={minName}
                value={value}
                selected={value}
                {...rest}
                {...minDatePickerProps}
                {...{ startDate, endDate }}
              />
            )}
          />
        </Styled.FilterWrapper>
        <Styled.FilterWrapper>
          <Controller
            name={maxName}
            control={control}
            render={({ field: { value, ...rest } }) => (
              <CustomDatePicker
                inline
                name={maxName}
                value={value}
                selected={value}
                {...rest}
                {...{ startDate, endDate }}
                {...{ maxDate: new Date(), maxYear: new Date().getFullYear() }}
                {...{ minDate: startDate, minYear: new Date(startDate).getFullYear() }}
              />
            )}
          />
        </Styled.FilterWrapper>
      </Styled.DatePickersWrapper>
    </>
  );
};

export default DesktopView;
