import {
  CandidateItem,
  CandidatesService,
  ConsolidationService,
  mapCandidateItemToCandidateItemResponse,
} from '@innowise-group/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  compareSeveralCandidatesItemsMapper,
  mapCandidateItemResponseToMap,
  normalizeDatesInCandidateItemResponse,
  possibleDuplicatesMapper,
} from './consolidation.mappers';

export const compareCandidatesByIdsThunk = createAsyncThunk<
  {
    diff: ReturnType<typeof compareSeveralCandidatesItemsMapper>;
    duplicates: ReturnType<typeof mapCandidateItemResponseToMap>;
  },
  { targetCandidateId: string | number; possibleDuplicatesIds: (string | number)[] }
>('consolidation/compareCandidatesByIds', async ({ targetCandidateId, possibleDuplicatesIds }) => {
  const { data: targetCandidate } = await CandidatesService.getCandidateById(Number(targetCandidateId));
  const { data: possibleDuplicates } = await CandidatesService.getCandidateByIds(possibleDuplicatesIds);
  const diff = compareSeveralCandidatesItemsMapper({ targetCandidate, possibleDuplicates });
  const duplicates = mapCandidateItemResponseToMap(possibleDuplicates);
  return { diff, duplicates };
});

export const compareCandidatesThunk = createAsyncThunk<
  {
    diff: ReturnType<typeof compareSeveralCandidatesItemsMapper>;
    duplicates: ReturnType<typeof mapCandidateItemResponseToMap>;
  },
  { targetCandidateId: string | number; possibleDuplicatesIds: (string | number)[]; newCandidateData: CandidateItem }
>('consolidation/compareCandidates', async ({ targetCandidateId, possibleDuplicatesIds, newCandidateData }) => {
  const newCandidateDataResponse = mapCandidateItemToCandidateItemResponse(newCandidateData);
  const newCandidateDateResponseWithNormalizedDates = normalizeDatesInCandidateItemResponse(newCandidateDataResponse);
  const { data: candidateItemResponse } = await CandidatesService.getCandidateById(Number(targetCandidateId));
  const { data: possibleDuplicates } = possibleDuplicatesIds.length
    ? await CandidatesService.getCandidateByIds(possibleDuplicatesIds)
    : { data: [] };
  const diff = compareSeveralCandidatesItemsMapper({
    targetCandidate: candidateItemResponse,
    possibleDuplicates: [...possibleDuplicates, newCandidateDateResponseWithNormalizedDates],
  });
  const duplicates = mapCandidateItemResponseToMap(possibleDuplicates);
  return { diff, duplicates };
});

export const getPossibleDuplicatesThunk = createAsyncThunk<
  ReturnType<typeof possibleDuplicatesMapper>,
  Parameters<typeof CandidatesService.getCandidateByIds>[number]
>('consolidation/getPossibleDuplicates', async (args) => {
  const { data } = await CandidatesService.getCandidateByIds(args);
  return possibleDuplicatesMapper(data);
});

export const mergeManyThunk = createAsyncThunk<
  ReturnType<Awaited<typeof ConsolidationService.mergeMany>>,
  Parameters<typeof ConsolidationService.mergeMany>[number]
>('consolidation/mergeMany', async (args) => {
  return ConsolidationService.mergeMany(args);
});
