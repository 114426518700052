import { Modal, ModalProps, modalHoc, useModal, Divider, useTheme, Loader } from '@innowise-group/mui-kit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './view-candidate-status-modal.styles';
import {
  useCalendarAPI,
  useCandidateEventsAPI,
  useCurrentMeetState,
  useCurrentStatus,
  useResizeObserver,
} from '@innowise-group/core';
import { MainInfoSection } from './components/main-info-section';
import { CommentSection } from './components/comment-section';
import { NotificationsSection } from './components/notifications-section';
import { InterestedPersonSection } from './components/interested-person-section';
import { FooterSection } from './components/footer-section';
import { ChangeCandidateStatusModal } from '../change-candidate-status-modal';

interface ViewCandidateStatusModalProps {
  photoId?: number;
  localizedFirstName: string;
  localizedLastName: string;
  candidateId: number;
  eventId: number;
  isDeleted?: boolean;
  fetchedEvent?: boolean;
}

const ViewCandidateStatusModal: React.FC<ViewCandidateStatusModalProps & ModalProps> = ({
  close,
  eventId,
  fetchedEvent = false,
  isDeleted,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { fetchEventById, isCurrentMeetLoading } = useCalendarAPI();
  const customEvent = useCurrentMeetState();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const actualStatus = useCurrentStatus()?.response;
  const { events } = useCandidateEventsAPI();
  const [showCandidateStatusEdition] = useModal(ChangeCandidateStatusModal);

  const openEditionModal = () => {
    close();
    showCandidateStatusEdition({
      ...props,
      eventId,
      localizedFullName: `${props.localizedLastName} ${props.localizedFirstName}`,
    });
  };

  useEffect(() => {
    if (fetchedEvent) {
      fetchEventById(eventId);
    }
  }, [fetchedEvent, eventId]);

  return (
    <Styled.Modal size="standard">
      <Modal.Header onClose={close}>
        <Styled.ModalTitle>
          <span>{t('pages.candidates.statuses.viewStatus')}</span>
        </Styled.ModalTitle>
      </Modal.Header>
      {isCurrentMeetLoading || (fetchedEvent && !customEvent) ? (
        <Styled.ModalBody>
          <Loader />
        </Styled.ModalBody>
      ) : (
        <Styled.ModalBody>
          <MainInfoSection status={customEvent || events[eventId] || actualStatus} {...props} />
          <Divider />
          <NotificationsSection
            status={customEvent || events[eventId] || actualStatus}
            candidateId={props.candidateId}
          />
          <Styled.SectionContainer>
            <InterestedPersonSection status={customEvent || events[eventId] || actualStatus} />
            {isDesktopView && <CommentSection comment={customEvent?.comment || events[eventId]?.comment} />}
          </Styled.SectionContainer>
        </Styled.ModalBody>
      )}
      {!isDeleted && (
        <FooterSection
          currentEvent={customEvent || events[eventId] || actualStatus}
          openEditionModal={openEditionModal}
          candidateId={props.candidateId}
          handleClose={close}
        />
      )}
    </Styled.Modal>
  );
};

export default modalHoc(ViewCandidateStatusModal, 'view-candidate-status-modal');
