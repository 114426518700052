import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getLanguageFromLS, localizedNameObject } from '@innowise-group/utilities';
import { LocalizedShortEmployeeItem } from './administration.types';
import { AppLanguages } from '@constants';

export const getAdministrationStateSelector = createSelector(
  (state: RootState) => state.administration,
  (state) => {
    return state;
  },
);

export const getLocationsAdministrationState = createSelector(getAdministrationStateSelector, (state) => {
  return state.locations;
});

export const getStatusesAdministrationState = createSelector(getAdministrationStateSelector, (state) => {
  return state.statuses;
});

export const getCitiesByLangAdministrationState = createSelector(getLocationsAdministrationState, (state) => {
  const lang = getLanguageFromLS();
  return [...(state.citiesDataByLanguage?.[lang] || [])].sort((a, b) => a.title.localeCompare(b.title));
});

export const getEmployeesEmailsOptions = createSelector(getAdministrationStateSelector, (state) => {
  return state.employees.employeesEmailsList.map((item) => ({ title: item, value: item }));
});

export const getEmployeesAdministrationStateSelector = createSelector(getAdministrationStateSelector, (state) => {
  const employees = state.employees.employeesIds.reduce<{ [key: number]: LocalizedShortEmployeeItem }>(
    (accum, curr) => {
      accum[curr] = { ...state.employees.employees[curr], ...localizedNameObject(state.employees.employees[curr]) };
      return accum;
    },
    {},
  );
  return { ...state.employees, employees };
});

export const getReferralsAdministrationState = createSelector(getAdministrationStateSelector, (state) => {
  return {
    ...state.referral,
    manager: {
      ...state.referral.manager,
      ...(state.referral.manager ? localizedNameObject(state.referral.manager) : {}),
    },
    items: state.referral.items.map((item) => {
      return {
        ...item,
        candidateReferralDtos: item.candidateReferralDtos.map((curr) => {
          return {
            ...curr,
            candidate: { ...curr.candidate, ...localizedNameObject(curr.candidate) },
            recommender: {
              ...curr.recommender,
              localizedFullName: localizedNameObject(curr.recommender).localizedFullName,
            },
          };
        }),
      };
    }),
  };
});

export const getUnitsAdministrationState = createSelector(getAdministrationStateSelector, (state) => {
  return state.units;
});

export const getSourcingAdministrationState = createSelector(getAdministrationStateSelector, (state) => {
  return state.sourcing;
});

export const getSourcingDefaultFormItemById = (id: string) =>
  createSelector(getSourcingAdministrationState, (state) => {
    const item = state.items[id];
    if (!item) return null;
    return {
      employeeId: item.id.toString(),
      requiredNumberOfEvents: item.requiredNumberOfEvents.toString(),
    };
  });

export const getUnitsAdministrationOptions = createSelector(getUnitsAdministrationState, (state) => {
  const lang = getLanguageFromLS();
  return state.ids.map((id) => {
    return {
      value: state.items[id].id.toString(),
      title: lang === AppLanguages.Ru ? state.items[id].nameRu : state.items[id].nameEn,
    };
  });
});
