import { FC, memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, MenuItem, Menu } from '@innowise-group/mui-kit';
import { reports } from './choose-report.data';
import * as Styled from './choose-report.styles';
import { RoleGuard } from '@shared-components';
import { defaultValues, makeStateTiny } from '../../index';
import { useCompressedStateInQuery } from '@innowise-group/core';
import { useFormContext } from 'react-hook-form';

const ChooseReport: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { reset } = useFormContext();
  const [, setState] = useCompressedStateInQuery();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelectReportType = (path: string) => () => {
    handleClose();
    const tinyState = makeStateTiny(defaultValues);
    setState(tinyState);
    reset(defaultValues);
    return navigate(path);
  };

  const currentReport = reports.reduce((acc, { path, title }) => {
    if (window.location.pathname.endsWith(path)) {
      return title;
    }
    return acc;
  }, '');

  return (
    <Styled.Wrapper>
      <Styled.Button
        onClick={handleClick}
        startIcon={<Icon type="u_report_team" />}
        endIcon={<Styled.ArrowIcon open={open} />}
      >
        <Styled.ButtonText>{t(currentReport)}</Styled.ButtonText>
      </Styled.Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {reports.map(({ title, value, path, roles }) => (
          <RoleGuard roles={roles} key={value}>
            <MenuItem onClick={handleSelectReportType(path)}>{t(title)}</MenuItem>
          </RoleGuard>
        ))}
      </Menu>
    </Styled.Wrapper>
  );
};

export default memo(ChooseReport);
