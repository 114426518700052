import React from 'react';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import * as Styled from './consolidation-modal.styles';

interface ConsolidationModalProps {
  submit: () => void;
}

const ConsolidationModal: React.FC<ConsolidationModalProps & ModalProps> = ({ close, submit }) => {
  const { t } = useTranslation();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.ModalTitle>{t('pages.candidates.consolidation.profileConsolidation')}</Styled.ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.candidates.consolidation.mergeCandidates')}?</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.yes'),
            onClick: submit,
            highlighted: true,
            type: 'button',
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(ConsolidationModal, 'consolidation-modal');
