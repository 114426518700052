import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NotificationTabs, useAppDispatch } from '@innowise-group/core';
import { getNotificationsIds, getNotificationsState } from './notifications.selectors';
import {
  changeMessagesPinStatusThunk,
  changeMessagesViewedStatusThunk,
  changePinnedNotificationsOrderThunk,
  deleteMessagesThunk,
  getMessageByIdThunk,
  getUnreadNotificationsCountThunk,
  searchAllNotificationsThunk,
} from './notifications.thunk';
import {
  changeTabAction,
  clearNotificationsAction,
  selectAllNotificationsAction,
  selectReadNotificationsAction,
  setNotificationAction,
  setPagableAction,
  setSortAction,
} from './notifications.actions';
import { SortingValue } from '@constants';
import { preloadedState } from './notifications.preloaded-state';

export const useNotificationsApiMethodds = () => {
  const dispatch = useAppDispatch();

  const getUnreadNotidicationsCount = useCallback(() => dispatch(getUnreadNotificationsCountThunk()), [dispatch]);

  const getMessageById = useCallback(
    (args: Parameters<typeof getMessageByIdThunk>[0]) => dispatch(getMessageByIdThunk(args)),
    [dispatch],
  );

  const deleteMessages = useCallback(
    (...args: Parameters<typeof deleteMessagesThunk>) => dispatch(deleteMessagesThunk(...args)),
    [dispatch],
  );

  const changeMessagesPinStatus = useCallback(
    (...args: Parameters<typeof changeMessagesPinStatusThunk>) => dispatch(changeMessagesPinStatusThunk(...args)),
    [dispatch],
  );

  const changeMessagesViewedStatus = useCallback(
    (...args: Parameters<typeof changeMessagesViewedStatusThunk>) => dispatch(changeMessagesViewedStatusThunk(...args)),
    [dispatch],
  );

  const searchAllNotifications = useCallback(
    (...args: Parameters<typeof searchAllNotificationsThunk>) => dispatch(searchAllNotificationsThunk(...args)),
    [dispatch],
  );

  const changePinnedNotificationsOrder = useCallback(
    (...args: Parameters<typeof changePinnedNotificationsOrderThunk>) =>
      dispatch(changePinnedNotificationsOrderThunk(...args)),
    [dispatch],
  );

  const changeTab = useCallback(
    (...args: Parameters<typeof changeTabAction>) => dispatch(changeTabAction(...args)),
    [dispatch],
  );

  const setPagable = useCallback(
    (...args: Parameters<typeof setPagableAction>) => dispatch(setPagableAction(...args)),
    [dispatch],
  );

  const selectAllNotifications = useCallback(
    (...args: Parameters<typeof selectAllNotificationsAction>) => dispatch(selectAllNotificationsAction(...args)),
    [dispatch],
  );

  const selectReadNotifications = useCallback(
    (...args: Parameters<typeof selectReadNotificationsAction>) => dispatch(selectReadNotificationsAction(...args)),
    [dispatch],
  );

  const setNotification = useCallback(
    (...args: Parameters<typeof setNotificationAction>) => dispatch(setNotificationAction(...args)),
    [dispatch],
  );

  const setSort = useCallback(
    (...args: Parameters<typeof setSortAction>) => dispatch(setSortAction(...args)),
    [dispatch],
  );

  const clearNotificationsState = useCallback(() => dispatch(clearNotificationsAction()), [dispatch]);

  return {
    setSort,
    changeTab,
    setPagable,
    deleteMessages,
    getMessageById,
    setNotification,
    searchAllNotifications,
    selectAllNotifications,
    changeMessagesPinStatus,
    selectReadNotifications,
    clearNotificationsState,
    changeMessagesViewedStatus,
    getUnreadNotificationsCount: getUnreadNotidicationsCount,
    changePinnedNotificationsOrder,
  };
};

export const useNotificationsApi = () => {
  const notifitionMethods = useNotificationsApiMethodds();
  const notificationsState = useSelector(getNotificationsState);
  const notificationsIds = useSelector(getNotificationsIds);

  const body = useMemo(() => {
    return {
      ...(notificationsState.currentTab !== NotificationTabs.All && { group: notificationsState.currentTab }),
      ...(notificationsState.sort === SortingValue.Checked &&
        notificationsState.selectedNotifications.length && { selected: notificationsState.selectedNotifications }),
    };
  }, [notificationsState.currentTab, notificationsState.sort]);

  const params = useMemo(() => {
    return {
      ...notificationsState.pagable,
      ...(notificationsState.sort !== SortingValue.Checked && {
        sort: notificationsState.sort,
      }),
    };
  }, [notificationsState.sort, notificationsState.pagable]);

  useEffect(() => {
    if (!notificationsState.selectedNotifications.length && notificationsState.sort === SortingValue.Checked)
      notifitionMethods.setSort(preloadedState.sort);
  }, [notificationsState.selectedNotifications]);

  return {
    body,
    params,
    ...notifitionMethods,
    ...notificationsState,
    ...notificationsIds,
  };
};
