import { TinyState } from '@modules';
import { StatisticBodyItem } from './statistic.types';

export const statisticBodyMapper = (args: Partial<TinyState>): StatisticBodyItem => {
  const startDate = new Date();
  startDate.setDate(1);
  const endDate = new Date();
  endDate.setDate(1);
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setHours(23, 99, 99, 99);
  return {
    ...args,
    dateMin: args?.dateMin || startDate,
    dateMax: args?.dateMax || endDate,
  };
};
