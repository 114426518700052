import { LocalizedRequestItemResponse, RequestItem, useOptionsApi } from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import React, { useEffect } from 'react';
import * as Styled from './optional-section.styles';
import { Button, Icon, Input, Select } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { LabeledCheckbox } from '@shared-mui-components';

interface OptionalSectionProps {
  handleNextStep: () => void;
  handlePrevStep: () => void;
  isEdit?: boolean;
  isStatusDisabled?: boolean;
  requestStatusOptions: SelectOption[];
  disabled: boolean;
  locationCityOptions: SelectOption[];
  currentRequestEdition: LocalizedRequestItemResponse;
  locationCountryOptions: SelectOption[];
  offices: SelectOption[];
  handleClose: () => void;
  searchVacancyHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  groupBy: (value) => (option) => string;
  handleChange: (onChange: (value: string) => void) => (value: string) => void;
}

const OptionalSection: React.FC<OptionalSectionProps> = ({ disabled, handleNextStep, handlePrevStep }) => {
  const { t } = useTranslation();
  const { workloadsOptions, workFormatsOptions, currencyOptions, employmentFormsOptions } = useOptionsApi();
  const { control, trigger, watch } = useFormContext();
  const currency = watch('currency');

  const handleChangeSalaryLimit =
    (fieldsName: keyof RequestItem, onChange: (data: React.ChangeEvent<HTMLInputElement>) => void) =>
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange(data);
      trigger(fieldsName);
    };

  useEffect(() => {
    trigger(['expectedSalaryMin', 'expectedSalaryMax']);
  }, [trigger, currency]);

  return (
    <Styled.StepContainer>
      <Styled.ConditionsItems>
        {' '}
        <Controller
          name="currency"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Select
              disabled={disabled}
              value={value}
              onChange={onChange}
              options={currencyOptions || []}
              disableClearable
              disableSortByAlphabet={false}
              renderInput={(params) => (
                <Input
                  {...params}
                  required
                  inputRef={ref}
                  label={t('pages.candidates.candidateCreation.currency')}
                  placeholder={t('pages.candidates.candidateCreation.selectCurrency')}
                  helperText={errors?.currency?.message?.toString()}
                  error={!!errors?.currency?.message}
                />
              )}
            />
          )}
        />
        <div />
        <Controller
          name="expectedSalaryMin"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              disabled={disabled}
              label={`${t('pages.candidates.candidateCreation.salary')} min`}
              error={!!errors?.expectedSalaryMin?.message}
              helperText={errors?.expectedSalaryMin?.message?.toString()}
              value={value}
              inputRef={ref}
              onChange={handleChangeSalaryLimit('expectedSalaryMax', onChange)}
              placeholder={t('pages.candidates.candidateCreation.enterAValue')}
              InputProps={{
                endAdornment: (
                  <Styled.SalaryIconContainer>
                    <Icon type="u_lock" viewBox="0 0 19 15" fill="transparent" />
                  </Styled.SalaryIconContainer>
                ),
              }}
            />
          )}
        />
        <Controller
          name="expectedSalaryMax"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              disabled={disabled}
              label={`${t('pages.candidates.candidateCreation.salary')} max`}
              error={!!errors?.expectedSalaryMax?.message}
              helperText={errors?.expectedSalaryMax?.message?.toString()}
              value={value}
              inputRef={ref}
              onChange={handleChangeSalaryLimit('expectedSalaryMin', onChange)}
              placeholder={t('pages.candidates.candidateCreation.enterAValue')}
              InputProps={{
                endAdornment: (
                  <Styled.SalaryIconContainer>
                    <Icon type="u_lock" viewBox="0 0 19 15" fill="transparent" />
                  </Styled.SalaryIconContainer>
                ),
              }}
            />
          )}
        />
      </Styled.ConditionsItems>
      <Styled.ConditionBlock>
        <Styled.ConditionTitle>{t('pages.candidates.candidateDetails.employmentType')}</Styled.ConditionTitle>
        <Styled.ConditionsItems>
          {workloadsOptions?.map((item, index) => (
            <Controller
              name={`workloads.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, onChange } }) => (
                <LabeledCheckbox disabled={disabled} onChange={onChange} checked={value} label={item.title} />
              )}
            />
          ))}
        </Styled.ConditionsItems>
      </Styled.ConditionBlock>
      <Styled.ConditionBlock>
        <Styled.ConditionTitle>{t('pages.candidates.candidateDetails.formatOfWork')}</Styled.ConditionTitle>
        <Styled.ConditionsItems>
          {workFormatsOptions?.map((item, index) => (
            <Controller
              name={`workFormats.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, ...props } }) => (
                <LabeledCheckbox disabled={disabled} checked={value} label={item.title} {...props} />
              )}
            />
          ))}
        </Styled.ConditionsItems>
      </Styled.ConditionBlock>
      <Styled.ConditionBlock>
        <Styled.ConditionTitle>{t('pages.requests.viewModal.formOfEmployment')}</Styled.ConditionTitle>
        <Styled.ConditionsItems>
          {employmentFormsOptions?.map((item, index) => (
            <Controller
              name={`employmentForms.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, ...props } }) => (
                <LabeledCheckbox disabled={disabled} checked={value} label={item.title} {...props} />
              )}
            />
          ))}
        </Styled.ConditionsItems>
      </Styled.ConditionBlock>
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
          {t('buttons.back')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </Styled.StepContainer>
  );
};

export default OptionalSection;
