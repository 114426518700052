import { styled } from '@innowise-group/mui-kit';

export const Input = styled.input`
  display: none;
`;

export const Avatar = styled.label`
  & > img {
    object-fit: cover;
    border-radius: 50%;
    pointer-events: none;
    width: 200px;
    height: 200px;
  }
  cursor: pointer;
`;

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 45px 40px 15px 40px;
`;

export const NoImageContainer = styled.label`
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.transition};
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
`;

export const AvatarFieldError = styled.div`
  color: ${({ theme }) => theme.palette.state.error.default};
  position: absolute;
  ${({ theme }) => theme.fonts.primary[10]}
  min-height: 17px;
  bottom: -40px;
  text-align: center;
`;
