import { SortingValue } from '@constants';

export enum NotificationsGroups {
  Calendar = 'CALENDAR',
  Mention = 'MENTION',
  Responsible = 'RESPONSIBLE',
  RequiresResponse = 'REQUIRES_RESPONSE',
  VacancyClaim = 'VACANCY_CLAIM',
}

export enum NotificationTabs {
  All = 'All',
  Calendar = 'CALENDAR',
  Mention = 'MENTION',
  Responsible = 'RESPONSIBLE',
  RequiresResponse = 'REQUIRES_RESPONSE',
  VacancyClaim = 'VACANCY_CLAIM',
}

export interface DedicatedEmployeeResponse {
  employeeId: number;
  firstNameEn: string;
  firstNameRu: string;
  jobTitle: { valueId: string; translation: string };
  lastNameEn: string;
  lastNameRu: string;
  localizedFullName: string;
  localizedFirstName: string;
  localizedLastName: string;
  lifecycleStatus: string;
  miniPhotoHrmUrl: string;
  photoRt: string | null;
}

export interface NotificationResponseItem {
  createdDate: Date;
  dedicatedTo: DedicatedEmployeeResponse;
  group: NotificationsGroups;
  id: number;
  message: string;
  pinned: boolean;
  priority: number | null;
  title: string;
  urls: string[];
  viewed: boolean;
}

export interface NotificationResponseItemState extends NotificationResponseItem {
  selected: boolean;
}

export interface NotificationMessage {
  id: number;
  group: string;
  title: string;
  message: string;
  viewed: boolean;
  pinned: boolean;
  priority: number;
  urls: string[];
  dedicatedTo: {
    employeeId: number;
    miniPhotoHrmUrl: string;
    photoRt: {
      id: number;
      url: string;
    };
    firstNameRu: string;
    lastNameRu: string;
    firstNameEn: string;
    lastNameEn: string;
    localizedFullName: string;
    localizedFirstName: string;
    localizedLastName: string;
    lifecycleStatus: string;
    jobTitle: {
      valueId: string;
      translation: string;
    };
  };
}

export interface PushNotification {
  dedicatedTo: number;
  newNotificationId: number;
  unreadNotificationCount: {
    CALENDAR: number;
    MENTION: number;
    RESPONSIBLE: number;
    REQUIRES_RESPONSE: number;
    VACANCY_CLAIM: number;
  };
  viewed?: boolean;
}

export interface NotificationTab {
  id: NotificationTabs;
  name: string;
}

export interface NotificationsState {
  isLoading: boolean;
  isProcessing: boolean;
  selectedNotifications: number[];
  dndOrder: number[] | null;
  items: { [key: string]: NotificationResponseItemState };
  ids: number[];
  unsavedIds: number[];
  totalElements: number;
  totalPages: number;
  tabs: {
    [key in NotificationTabs]: { id: NotificationTabs; name: string; count: number };
  };
  tabsIds: NotificationTabs[];
  sort: SortingValue;
  currentTab: NotificationTabs;
  pagable: {
    currentPage: number;
    pageSizes: { title: string; value: string }[];
    pageSize: number;
  };
}

export interface PinRequest {
  newPinnedValue: boolean;
  notifications: number[];
}

export interface ViewedRequest {
  newViewedValue: boolean;
  notifications: number[];
}

export interface PinnedOrder {
  notificationIdPriorityMap: {
    [key: number]: number;
  };
}
