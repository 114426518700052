import { Button as BaseButton } from '../button';
import { Grow as MuiGrow } from '@mui/material';
import styled, { css } from 'styled-components';

export const Grow = styled(MuiGrow)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CommonButtonStyles = css`
  min-width: 12.75rem;
  ${({ theme }) => theme.breakpoints.down('ss')} {
    min-width: auto;
    width: 100%;
  }
`;

const AbsoluteObject = css`
  position: fixed;
  z-index: 1310;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  ${AbsoluteObject}
  & *::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  & *::-webkit-scrollbar-track {
    border-radius: 10px;
    opacity: 0.4;
    background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
  & *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
    border-radius: 10px;
  }
  & *::-webkit-scrollbar-button {
    display: none;
  }
`;

export const ModalElement = styled.div<{ index: number }>`
  ${AbsoluteObject}

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ index }) => 105 + index};
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.15);
`;

const ModalSection = styled.div`
  box-sizing: border-box;
  padding: 1.25rem;
  width: 100%;
`;

export const ModalHeader = styled(ModalSection)`
  box-sizing: border-box;
  padding: 1.5rem 2.5rem 1.25rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
`;

export const ModalBody = styled(ModalSection)`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  min-height: 50px;
  padding: 2.5rem;
  padding-top: 2rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1.5rem;
  }
  ${({ theme }) => theme.breakpoints.down('ss')} {
    padding: 1rem;
  }
`;

export const ModalActionsContainer = styled(ModalSection)<{
  noVerticalPadding?: boolean;
  position?: 'left' | 'center' | 'right' | 'full';
}>`
  display: flex;
  justify-content: ${({ position }) =>
    position === 'center' ? 'space-around' : position === 'right' ? 'end' : 'start'};
  padding: 10px 2.5rem 2.5rem 2.5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 1.5rem 1.5rem 1.5rem;
  }
  gap: 1rem;
  margin-top: ${({ noVerticalPadding }) => (noVerticalPadding ? '0' : '20px')};
  & > * {
    min-width: 200px;
    margin-right: 10px;
    ${({ position }) =>
      position === 'full' &&
      css`
        flex: 1;
      `}
  }
`;

export const Button = styled(BaseButton)<{ $highlighted: boolean }>`
  &.MuiButton-root.MuiButton-outlined,
  &.MuiButton-root.MuiButton-contained {
    ${CommonButtonStyles}
  }
  &.MuiButton-root.MuiButton-outlined {
    ${({ theme, $highlighted }) => ({
      color: $highlighted ? theme.palette.general.smart : theme.palette.general.darkGrey[60],
      borderColor: $highlighted ? theme.palette.general.smart : theme.palette.general.darkGrey[60],
    })}
    &:hover {
      ${({ theme, $highlighted }) => ({
        color: $highlighted ? theme.palette.general.smart : theme.palette.general.darkGrey[80],
        borderColor: $highlighted ? theme.palette.general.smart : theme.palette.general.darkGrey[80],
      })}
    }
  }
`;

export const Modal = styled.form<{ size?: 'small' | 'standard' | 'large' }>`
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  max-width: ${({ size }) => {
    if (!size) return;
    return size === 'small' ? '32.125rem' : size === 'standard' ? '54.75rem' : '60.5rem';
  }};
  width: ${({ size }) => {
    if (!size) return 'auto';
    return size === 'small' ? '32.125rem' : size === 'standard' ? '54.75rem' : '60.5rem';
  }};
  min-width: 510px;
  margin: 1.25rem 3rem;
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: 20vw;
    max-width: 90vw;
  }
`;
