import { MaxChipLength } from '@constants';
import { styled } from '@innowise-group/mui-kit';
import { Chip } from '@mui/material';

export const CustomChip = styled(Chip)<{ ishidden: boolean | null }>`
  .MuiChip-label {
    ${({ theme }) => theme.fonts.primary[10]};
    color: ${({ theme }) => theme.palette.specials.avatars.eleventh};
    display: block;
    max-width: ${({ ishidden }) => ishidden && `${MaxChipLength}px`};
    overflow: ${({ ishidden }) => ishidden && 'hidden'};
    text-overflow: ${({ ishidden }) => ishidden && 'ellipsis'};
    ${({ theme }) => theme.breakpoints.down('sm')} {
      ${({ theme }) => theme.fonts.primary[20]};
    }
  }
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.specials.timelines.eleventh};
    margin: 0 3px;
    min-height: 27px;
    height: auto;
    width: min-content;
    &:hover {
      background-color: ${({ theme }) => theme.palette.specials.timelines.eleventh};
    }
  }
`;
