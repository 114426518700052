import { appearAnimation, css, styled } from '@innowise-group/mui-kit';
import { Collapse as MuiCollapse } from '@mui/material';

export const TagContainer = styled.span<{ isDeleted?: boolean }>`
  display: inline-block;
  text-decoration: ${({ isDeleted }) => isDeleted && 'line-through'};
  color: ${({ theme }) => theme.palette.info.default};
`;

export const EmployeeContainer = styled.div`
  position: absolute;
  z-index: 5;
`;

export const EmployeeInfo = styled.div<{ isTopPosition?: boolean }>`
  width: max-content;
  display: block;
  position: absolute;
  top: ${({ isTopPosition }) => !isTopPosition && '-5px'};
  bottom: ${({ isTopPosition }) => isTopPosition && '15px'};
  left: 0;

  margin: 4px 0;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 6px 4px 4px 6px;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[100]};
  animation: ${appearAnimation} 0.175s ease-in-out;
  padding: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.general.lightGrey[60]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.general.lightGrey[80]};
  }
`;

export const Block = styled.div<{ isOpen: boolean | null }>`
  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 0;
    ${({ isOpen, theme }) => {
      if (!isOpen && isOpen !== null) {
        return css`
          -webkit-box-shadow: 0px -3px 10px 5px ${theme.palette.general.white};
          -moz-box-shadow: 0px -3px 10px 5px ${theme.palette.general.white};
          box-shadow: 0px -3px 10px 5px ${theme.palette.general.white};
        `;
      }
    }}
  }
  & .MuiCollapse-root {
    cursor: ${({ isOpen }) => {
      return isOpen !== null ? 'pointer' : 'default';
    }} !important;
  }
`;

export const TextWrapper = styled.p`
  display: inline-block;
  word-break: break-word;
`;

export const Collapse = styled(MuiCollapse)``;
