import { Accordion as MuiAccordion, Button, Divider, styled, Icon } from '@innowise-group/mui-kit';
import { ReadOnlyChip } from '@shared-mui-components';
import { Link } from 'react-router-dom';
import { CandidateStatusColors } from '@constants';

const TableColumn = styled.div`
  align-self: center;
  padding: 0 3px;
`;

export const RestoreIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    margin: 0;
    padding: 0;
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const Accordion = styled(MuiAccordion)<{ $even: boolean; $isArchive?: boolean }>`
  &.MuiAccordion-root {
    background: ${({ theme, $even, $isArchive }) => {
      if ($isArchive) {
        return $even ? theme.palette.general.lightGrey[50] : theme.palette.general.darkGrey[20];
      }
      return $even ? theme.palette.general.lightGrey[20] : theme.palette.general.paper;
    }} !important;
  }
`;

export const TableRow = styled.div<{ even: boolean; isArchive?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 5fr 4fr 1fr;
  min-height: 65px;
  width: 100%;
  cursor: default;
  background: ${({ theme, even, isArchive }) => {
    if (isArchive) {
      return even ? theme.palette.general.lightGrey[50] : theme.palette.general.darkGrey[20];
    }
    return even ? theme.palette.general.lightGrey[20] : theme.palette.general.paper;
  }};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ApproveIcon = styled(Icon)`
  & svg {
    fill: ${({ theme }) => theme.palette.general.white} !important;
    & path {
      transition: 0.2s;
      stroke: ${({ theme }) => theme.palette.info.default};
    }
  }
  margin: 0;
  padding: 0;
`;

export const RejectButton = styled(Button)<{ isNotSuitable: boolean }>`
  &.MuiButton-root.MuiButton-text {
    color: ${({ isNotSuitable, theme }) => !isNotSuitable && theme.palette.state.success.default};
    &:hover {
      color: ${({ isNotSuitable, theme }) => !isNotSuitable && theme.palette.state.success.pressed};
    }
  }
`;

export const RejectIcon = styled(Icon)`
  & svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  margin: 0;
  padding: 0;
`;

export const CopyIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.info.default};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.info.default};
  }
  &:active svg {
    fill: ${({ theme }) => theme.palette.info.hover};
  }
  margin: 0;
  padding: 0;
`;

export const OpenButtonContainer = styled(TableColumn)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OpenButton = styled.button`
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  border: none;
  background: none;
`;

export const ArrowIcon = styled(Icon)<{ isOpen: boolean; isHighlighted?: boolean }>`
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: ${({ theme, isHighlighted }) => isHighlighted && theme.palette.main.hover};
  }
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.palette.main.background : theme.palette.general.lightGrey[40]};
`;

export const PersonalContainer = styled.div`
  display: flex;
  min-width: 0;
`;

export const PersonalInfoColumn = styled(TableColumn)`
  align-self: center;
  padding-right: 5px;
  flex: 1;
  min-width: 0;
`;

const PersonalInfo = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const FullNameContainer = styled(PersonalInfo)`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[30]}
`;

export const FullName = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;

export const BirthInfoContainer = styled(PersonalInfo)``;

export const BirthDate = styled.span``;

export const Location = styled.span`
  ${({ theme }) => theme.fonts.primary[20]}
  white-space: pre-wrap;
`;

export const ProfessionContainer = styled(TableColumn)``;

export const Profession = styled.p`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 180%;
  margin: 0;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;

export const GradeTitle = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export const FullDivider = styled(Divider)`
  margin: 4px auto;
`;

export const TableOptionalRow = styled(TableRow)`
  min-height: 0px;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 0.75rem 0.5rem 0.75rem;
`;

export const OptionalHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: start;
`;

export const VacanciesContainer = styled(TableColumn)`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  align-self: start;
  flex: 3;
`;

export const Title = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
`;

export const ResponsibleContainer = styled(VacanciesContainer)`
  flex: 2;
`;

export const StatusTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.75rem;
  position: relative;
  width: 100%;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FieldName = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 19px;
`;

export const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const GreenChip = styled(ReadOnlyChip)`
  .MuiChip-label {
    color: ${({ theme }) => theme.palette.state.success.default};
  }
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.state.success.backgroundSecondary};
    &:hover {
      background-color: ${({ theme }) => theme.palette.state.success.backgroundSecondary};
    }
  }
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const RejectButtonContainer = styled(TableColumn)`
  & > * {
    margin: 0;
    padding: 0;
  }
  & .MuiButton-root.MuiButton-outlined {
    width: 100%;
    color: ${({ theme }) => theme.palette.info.default};
    border-color: ${({ theme }) => theme.palette.info.default};
    transition: 0.2s;
    & svg {
      transition: 0.2s;
      fill: ${({ theme }) => theme.palette.info.default};
    }
  }
  & .MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.info.hover};
    border-color: ${({ theme }) => theme.palette.info.hover};
    & svg {
      transition: 0.2s;
      fill: ${({ theme }) => theme.palette.info.hover};
    }
  }
  margin: 3px;
  width: 100%;
  display: flex;
`;

export const StatusEventDate = styled.span`
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const StatusEventHeader = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const StatusContainer = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 0;
  height: min-content;
  gap: 4px;
  margin: auto;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  ${({ theme }) => theme.fonts.primary[40]}
  color: ${({ status }) => CandidateStatusColors[status].color};
  background-color: ${({ status }) => CandidateStatusColors[status].backgroundColor};
  white-space: nowrap;
`;
