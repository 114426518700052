import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppLayout, PrivateRoute } from '@shared-components';
import { AppPages } from './app.router';
import { AppRoutes, ManagerRolesArray } from '@constants';
import { Auth, NotFound } from '@modules';
import { getDocumentTitleInUpperCase, getLanguageFromLS } from '@innowise-group/utilities';
import * as Styled from './app.styles';
import {
  AuthService,
  useNotificationsApi,
  useOptionsApi,
  useProfileAPI,
  useNotificationSubscription,
  useCandidateStatusAPI,
  GoogleAnalyticsService,
  useReferralAPI,
} from '@innowise-group/core';
import { ProfileCreationModal } from './modules/profile/modals/profile-creation-modal';
import { useModal, LoadingFrame } from '@innowise-group/mui-kit';
import i18n from './i18n';

const App: React.FC = () => {
  useNotificationSubscription();
  const location = useLocation();
  const navigate = useNavigate();
  const [appIsReady, setAppIsReady] = useState(false);
  const [showProfileCreationModal] = useModal(ProfileCreationModal);
  const { fetchProfileData, profile, fetchCurrentEvents } = useProfileAPI();
  const { getReferralsManager } = useReferralAPI();
  const { getUnreadNotificationsCount } = useNotificationsApi();
  const { getAllSelectOptions, getStatusAuthorsByRoles, getCitiesSelectOptions, getCountrySelectOptions, options } =
    useOptionsApi();
  const { getStatusList } = useCandidateStatusAPI();

  useEffect(() => {
    document.title = `ITS RS: ${getDocumentTitleInUpperCase(location.pathname)}`;
  }, [location.pathname]);

  useEffect(() => {
    GoogleAnalyticsService.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [location.pathname]);

  const openRegistrationModal = () => {
    showProfileCreationModal();
  };

  useEffect(() => {
    const lang = getLanguageFromLS();
    i18n.changeLanguage(lang);
    if (profile?.id && i18n?.isInitialized) {
      getAllSelectOptions();
      getStatusAuthorsByRoles();
      getCountrySelectOptions();
      getCitiesSelectOptions();
      getStatusList();
      if (!ManagerRolesArray.includes(profile.role.name)) {
        getReferralsManager();
      }

      if (!profile?.registrationCompleted) {
        openRegistrationModal();
        navigate(AppRoutes.Candidates);
      }
    }
  }, [profile?.id, i18n?.isInitialized]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchProfileData();
      fetchCurrentEvents();
      getUnreadNotificationsCount();
    }
  }, [fetchProfileData, fetchCurrentEvents, getUnreadNotificationsCount, AuthService.isLoggedIn]);

  useEffect(() => {
    const isUserAuthenticated = AuthService.isLoggedIn();
    if (!isUserAuthenticated) setAppIsReady(true);
    if (isUserAuthenticated && options && profile) setAppIsReady(true);
  }, [profile, options]);

  if (!appIsReady) return <LoadingFrame />;
  return (
    <Styled.VerticalContainer>
      <Routes>
        <Route path={`${AppRoutes.Auth}`} element={<Auth />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <AppLayout>
                <Routes>
                  {AppPages.map(({ Component, path }) => (
                    <Route
                      key={path}
                      path={`${path}/*`}
                      element={
                        <Suspense fallback={<LoadingFrame />}>
                          <Component />
                        </Suspense>
                      }
                    />
                  ))}
                  <Route path={`${AppRoutes.Home}/*`} element={<Navigate to={AppRoutes.Candidates} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AppLayout>
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </Styled.VerticalContainer>
  );
};

export default App;
