import { useOptionsApi } from '@innowise-group/core';
import { Button, Input, Select, useModal } from '@innowise-group/mui-kit';
import { DatePicker, LabeledRadioButton, LabeledSwitch, SelectWithFooter } from '@shared-mui-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './location-section.styles';
import { Icon } from '@innowise-group/mui-kit';
import { AddCityToDictionaryModal } from '@innowise-group/modals';

interface LocationSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const LocationSection: React.FC<LocationSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { countryOptions, allCitiesOptions: citiesOptions, visaTypesOptions, addCityToDictionary } = useOptionsApi();
  const [showCreationCityModal] = useModal(AddCityToDictionaryModal);

  const { control, watch, setValue, trigger } = useFormContext();
  const isRelocate = watch('relocationAvailability');
  const hasVisa = watch('visaAvailability');
  const isFullVisaDate = watch('candidateVisas.0.isFullVisaDate');
  const country = watch('candidateLocation.locationCountryId');
  const currentCountry = watch('candidateLocation.currentLocationCountryId');
  const city = watch('candidateLocation.locationCityId');
  const currentCity = watch('candidateLocation.currentLocationCityId');

  const cities = useMemo(() => {
    return country ? citiesOptions?.filter((item) => item.parent === country) : citiesOptions;
  }, [citiesOptions?.length, country]);
  const currentCities = useMemo(() => {
    return currentCountry ? citiesOptions?.filter((item) => item.parent === currentCountry) : citiesOptions;
  }, [citiesOptions?.length, currentCountry]);

  const onRelocationChange = (value: boolean, originOnChangeFunction: (value: boolean) => void) => () => {
    if (!value) {
      setValue('candidateRelocationCountries', []);
    }
    originOnChangeFunction(value);
  };

  const onTripsChange = (value: boolean, originOnChangeFunction: (value: boolean) => void) => () => {
    originOnChangeFunction(value);
  };

  const onHasVisaChange = (value: boolean, originOnChangeFunction: (value: boolean) => void) => () => {
    setValue('candidateVisas.0', { visaTypeId: '', validTo: null, isFullVisaDate: false });
    originOnChangeFunction(value);
  };

  const submitCreationCity = useCallback(
    (isReal: boolean) => async (data: { countryId: string; cityNames: { [key: string]: string } }) => {
      const option = await addCityToDictionary({ ...data }).unwrap();
      setValue(
        isReal ? 'candidateLocation.currentLocationCountryId' : 'candidateLocation.locationCountryId',
        option.parent,
      );
      setValue(isReal ? 'candidateLocation.currentLocationCityId' : 'candidateLocation.locationCityId', option.value);
    },
    [],
  );

  const addCityToDictionaryHandler = () => {
    showCreationCityModal({ countryId: country, submitHandler: submitCreationCity(false) });
  };

  const addRealCityToDictionaryHandler = () => {
    showCreationCityModal({
      countryId: currentCountry,
      submitHandler: submitCreationCity(true),
    });
  };

  const handleVisaDateSwitch =
    (onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      setValue('candidateVisas.0.validTo', null);
      trigger('candidateVisas.0');
      onChange(event, value);
    };

  useEffect(() => {
    if (!cities?.find((item) => item.value === city) || !country) {
      setValue('candidateLocation.locationCityId', '');
    }
  }, [country]);

  useEffect(() => {
    if (city && !country) {
      setValue('candidateLocation.locationCountryId', citiesOptions.find((item) => item.value === city)?.parent || '');
    }
  }, [city]);

  useEffect(() => {
    if (!currentCities?.find((item) => item.value === currentCity) || !currentCountry) {
      setValue('candidateLocation.currentLocationCityId', '');
    }
  }, [currentCountry]);

  useEffect(() => {
    if (currentCity && !currentCountry) {
      setValue(
        'candidateLocation.currentLocationCountryId',
        currentCities.find((item) => item.value === currentCity)?.parent || '',
      );
    }
  }, [currentCity]);

  return (
    <React.Fragment>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomLocationDiff />}
        <Controller
          name="candidateLocation.locationCountryId"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => {
            return (
              <Select
                disabled={disabled}
                value={value}
                onChange={onChange}
                disableSortByAlphabet={false}
                options={countryOptions}
                renderInput={(params) => {
                  return (
                    <Input
                      {...params}
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.country')}
                      placeholder={t('pages.candidates.candidateCreation.selectCountry')}
                      error={!!errors['candidateLocation.locationCountryId']?.message}
                      helperText={errors['candidateLocation.locationCountryId']?.message.toString()}
                    />
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="candidateLocation.locationCityId"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <SelectWithFooter
              disabled={disabled}
              value={value}
              onChange={onChange}
              options={cities}
              disableSortByAlphabet={false}
              footer={
                <Button
                  variant="text"
                  disabled={disabled}
                  onClick={addCityToDictionaryHandler}
                  startIcon={<Icon type="u_plus" />}
                >
                  {t('buttons.addToDictionary')}
                </Button>
              }
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    inputRef={ref}
                    label={t('pages.candidates.candidateCreation.city')}
                    placeholder={t('pages.candidates.candidateCreation.selectCity')}
                    error={!!errors['candidateLocation.locationCity']?.message}
                    helperText={errors['candidateLocation.locationCity']?.message.toString()}
                  />
                );
              }}
            />
          )}
        />
        <Controller
          name="candidateLocation.currentLocationCountryId"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Select
              disabled={disabled}
              value={value}
              onChange={onChange}
              disableSortByAlphabet={false}
              options={countryOptions}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    inputRef={ref}
                    label={`${t('pages.candidates.candidateCreation.country')} (${t(
                      'pages.candidates.candidateCreation.realW',
                    )})`}
                    placeholder={t('pages.candidates.candidateCreation.selectCountry')}
                    error={!!errors['candidateLocation.currentLocationCountryId']?.message}
                    helperText={errors['candidateLocation.currentLocationCountryId']?.message.toString()}
                  />
                );
              }}
            />
          )}
        />
        <Controller
          name="candidateLocation.currentLocationCityId"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <SelectWithFooter
              disabled={disabled}
              value={value}
              onChange={onChange}
              disableSortByAlphabet={false}
              options={currentCities}
              footer={
                <Button
                  variant="text"
                  disabled={disabled}
                  onClick={addRealCityToDictionaryHandler}
                  startIcon={<Icon type="u_plus" />}
                >
                  {t('buttons.addToDictionary')}
                </Button>
              }
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    inputRef={ref}
                    label={`${t('pages.candidates.candidateCreation.city')} (${t(
                      'pages.candidates.candidateCreation.realM',
                    )})`}
                    placeholder={t('pages.candidates.candidateCreation.selectCity')}
                    error={!!errors['candidateLocation.currentLocationCity']?.message}
                    helperText={errors['candidateLocation.currentLocationCity']?.message.toString()}
                  />
                );
              }}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomRelocationDiff />}
        <Styled.RadioContainer>
          <Styled.RadioButtonsLabel>{t('pages.candidates.candidateCreation.relocation')}</Styled.RadioButtonsLabel>
          <Styled.RadioButtonsContainer>
            <Controller
              name="relocationAvailability"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LabeledRadioButton
                  disabled={disabled}
                  onChange={onRelocationChange(true, onChange)}
                  checked={`${value}` === 'true'}
                  value={'true'}
                  label={t('buttons.yes')}
                />
              )}
            />
            <Controller
              name="relocationAvailability"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LabeledRadioButton
                  disabled={disabled}
                  onChange={onRelocationChange(false, onChange)}
                  checked={`${value}` === 'false'}
                  value={'false'}
                  label={t('buttons.no')}
                />
              )}
            />
          </Styled.RadioButtonsContainer>
        </Styled.RadioContainer>
        <div>
          {isRelocate && (
            <Controller
              name="candidateRelocationCountries"
              control={control}
              render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                <Styled.PositionedSelect
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  limitTags={1}
                  multiple
                  disableCloseOnSelect
                  options={countryOptions}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      inputRef={ref}
                      label={t('pages.candidates.candidateCreation.relocationCountry')}
                      placeholder={t('pages.candidates.candidateCreation.selectDomain')}
                      error={!!errors['candidateRelocationCountries']?.message}
                      helperText={errors['candidateRelocationCountries']?.message?.toString()}
                    />
                  )}
                />
              )}
            />
          )}
        </div>
        <Styled.RadioButtonsLabel>
          {t('pages.candidates.candidateDetails.readyForBusinessTrip')}
        </Styled.RadioButtonsLabel>
        <Styled.RadioButtonsContainer>
          <Controller
            name="readyForBusinessTrip"
            control={control}
            render={({ field: { value, onChange } }) => (
              <LabeledRadioButton
                disabled={disabled}
                onChange={onTripsChange(true, onChange)}
                checked={`${value}` === 'true'}
                value={'true'}
                label={t('buttons.yes')}
              />
            )}
          />
          <Controller
            name="readyForBusinessTrip"
            control={control}
            render={({ field: { value, onChange } }) => (
              <LabeledRadioButton
                disabled={disabled}
                onChange={onTripsChange(false, onChange)}
                checked={`${value}` === 'false'}
                value={'false'}
                label={t('buttons.no')}
              />
            )}
          />
        </Styled.RadioButtonsContainer>
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        <Styled.RadioContainer>
          <Styled.RadioButtonsLabel>{t('pages.candidates.candidateCreation.visa')}</Styled.RadioButtonsLabel>
          <Styled.RadioButtonsContainer>
            <Controller
              name="visaAvailability"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LabeledRadioButton
                  disabled={disabled}
                  onChange={onHasVisaChange(true, onChange)}
                  checked={`${value}` === 'true'}
                  value={'true'}
                  label={t('buttons.yes')}
                />
              )}
            />
            <Controller
              name="visaAvailability"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LabeledRadioButton
                  disabled={disabled}
                  onChange={onHasVisaChange(false, onChange)}
                  checked={`${value}` === 'false'}
                  value={'false'}
                  label={t('buttons.no')}
                />
              )}
            />
          </Styled.RadioButtonsContainer>
        </Styled.RadioContainer>
        {isDiff && <Styled.CustomVisaDiff $withVisa={hasVisa} />}
        {hasVisa && (
          <React.Fragment>
            <Controller
              name="candidateVisas.0.visaTypeId"
              control={control}
              render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                <Styled.PositionedSelect
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  disableSortByAlphabet={false}
                  options={visaTypesOptions}
                  renderInput={(params) => {
                    return (
                      <Input
                        {...params}
                        inputRef={ref}
                        label={t('pages.candidates.candidateCreation.typeOfVisa')}
                        placeholder={t('pages.candidates.candidateCreation.selectTypeOfVisa')}
                        error={!!errors?.candidateVisas?.[0]?.visaTypeId?.message}
                        helperText={errors?.candidateVisas?.[0]?.visaTypeId?.message.toString()}
                      />
                    );
                  }}
                />
              )}
            />
            <Styled.DatePickerContainer>
              <Controller
                name="candidateVisas.0.validTo"
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <DatePicker
                    disabled={disabled}
                    selected={value}
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    isConstrained={false}
                    withoutDays={!isFullVisaDate}
                    error={!!errors?.candidateVisas?.[0]?.validTo?.message}
                    helperText={errors?.candidateVisas?.[0]?.validTo?.message}
                    label={t('pages.candidates.candidateCreation.visaPeriod')}
                    placeholder={t('pages.candidates.candidateCreation.to')}
                  />
                )}
              />
            </Styled.DatePickerContainer>
            <Controller
              name="candidateVisas.0.isFullVisaDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Styled.SwitchContainer>
                  <LabeledSwitch
                    disabled={disabled}
                    label={t('pages.candidates.candidateCreation.knownExactDate')}
                    labelPlacement="end"
                    checked={value}
                    onChange={handleVisaDateSwitch(onChange)}
                  />
                </Styled.SwitchContainer>
              )}
            />
          </React.Fragment>
        )}
      </Styled.BoxForDiffConsolidation>
    </React.Fragment>
  );
};

export default React.memo(LocationSection);
