import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';

export default class ReferralService {
  static async getReferrals(args: { paidOff: boolean; fromDate: string; toDate: string }): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/referrals`, {
      params: {
        ...args,
      },
    });
  }

  static async resolveReferralStatus(id: string, accepted: boolean): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/referrals/${id}/${accepted}`);
  }

  static async exportReferrals(args: { fromDate: string; toDate: string }): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/referrals/export`, null, {
      params: {
        ...args,
      },
      responseType: 'blob',
    });
  }
}
