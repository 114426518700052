import { createAction } from '@reduxjs/toolkit';
import { SortingValue } from '@constants';
import { NotificationTabs, NotificationsState } from './notifications.types';

export const changeTabAction = createAction<NotificationTabs>('notifications/changeTab');
export const setPagableAction =
  createAction<Pick<NotificationsState['pagable'], 'currentPage' | 'pageSize'>>('notifications/setPagable');
export const clearNotificationsAction = createAction('notifications/clearNotifications');
export const selectAllNotificationsAction = createAction<boolean>('notifications/selectAllNotifications');
export const selectReadNotificationsAction = createAction<boolean>('notifications/selectReadNotifications');
export const setNotificationAction = createAction<number>('notifications/setNotification');
export const setSortAction = createAction<SortingValue>('notifications/setSort');
