import { Icon, Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 2rem;
`;

export const QueryItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QueryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 2rem 0 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1.5rem 0 0;
  }
`;

export const QueryItemTitle = styled.span`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.main.default};
`;

export const DeleteIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.palette.general.smart,
}))``;

export const XrayHistoryTitleContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0.75rem 1.5rem 0.25rem 1.5rem;
  }
`;

export const XrayHistoryTitle = styled.span``;

export const ToggleButton = styled(Button)<{ $collapsed: boolean }>`
  &.MuiButton-root.MuiButton-text {
    cursor: pointer;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    & svg {
      transform: ${({ $collapsed }: { $collapsed: boolean }) => ($collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`;
