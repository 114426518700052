import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@innowise-group/mui-kit';
import { Icon } from '../icon';
import * as Styled from './notification.styles';
import { NotificationType } from './notification.types';
import { TFunction } from 'i18next';
import { getNotificationIconByType } from '@innowise-group/utilities';
import { Slide } from '@mui/material';
import { useResizeObserver } from '@innowise-group/core';

export interface NotificationProps {
  type?: NotificationType;
  title?: string;
  subtitle?: React.ReactNode;
  onClose?: () => void;
  isGrouped?: boolean;
  index: number;
}

const getTextByType = (type: NotificationType, t: TFunction) => {
  return t(`notifications.titles.${type}`);
};

const Notification: React.FC<NotificationProps> = ({
  type = 'success',
  title,
  subtitle,
  onClose,
  isGrouped,
  index,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  return (
    <Slide direction={isDesktopView ? 'left' : 'down'} in timeout={500}>
      <Styled.NotificationContainer containerType={type} isGrouped={isGrouped} index={index}>
        {isGrouped && !!index ? null : (
          <React.Fragment>
            <Icon type={getNotificationIconByType(type)} size={30} />
            <Styled.NotificationContentContainer>
              <Styled.NotificationTitleContainer>
                <Styled.NotificationTitle>{title ? title : getTextByType(type, t)}</Styled.NotificationTitle>
                <Icon type="u_multiply" onClick={onClose} />
              </Styled.NotificationTitleContainer>
              {<Styled.SubTitle>{subtitle}</Styled.SubTitle>}
            </Styled.NotificationContentContainer>
          </React.Fragment>
        )}
      </Styled.NotificationContainer>
    </Slide>
  );
};
export default Notification;
