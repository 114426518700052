import { styled } from '@innowise-group/mui-kit';

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    top: 24px;
    left: 0;
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.state.error.backgroundSecondary : theme.palette.state.error.pressed};
  }
`;

export const AddMoreLabel = styled.div<{ disabled?: boolean }>`
  min-width: 35%;
  padding: 0 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.state.error.backgroundSecondary : theme.palette.state.error.pressed};
  background-color: ${({ theme }) => theme.palette.general.paper};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: ${({ theme }) => theme.animation.transition};
  & svg {
    fill: ${({ theme, disabled }) =>
      disabled ? theme.palette.state.error.backgroundSecondary : theme.palette.state.error.pressed};
  }
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.state.error.backgroundSecondary : theme.palette.state.error.default};
    & svg {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.palette.state.error.backgroundSecondary : theme.palette.state.error.default};
    }
  }
`;
