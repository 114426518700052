import { Input, notificationsManager, Icon, useModal } from '@innowise-group/mui-kit';
import { PhoneInput } from '@shared-mui-components';
import React, { useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Styled from './contact-section.styles';
import {
  FileIdResponse,
  useCandidateFilesAPI,
  useControlFormField,
  useValidationSchemaCandidate,
} from '@innowise-group/core';
import { FileTypesIcons, FilesAcceptFormats, FilesFormats, MaxFileSize, MaxFilesCount, MaxRefsCount } from '@constants';
import { FileAccessModal, FilePreviewModal } from '@innowise-group/modals';

interface ContactSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const ContactSection: React.FC<ContactSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { createFileId } = useCandidateFilesAPI();
  const [showFileAccessModal, closeFileAccessModal] = useModal(FileAccessModal);
  const [showFilePreview] = useModal(FilePreviewModal);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { control, watch, setValue } = useFormContext();
  const links = watch('candidateLinks');
  const files = watch('filesMeta');

  const { validationSchema } = useValidationSchemaCandidate();
  const { handleAddItem, handleRemoveItem, handleChangeInputItem, handlerValidate } =
    useControlFormField(validationSchema);

  const handleRemoveLink = (index: number) => () => {
    handleRemoveItem<{ name: string; url: string }>(index, 'candidateLinks', links)();
    handlerValidate('candidateLinks');
  };

  const fileInputColumnStart = useMemo(() => {
    const attachmentsCount = files.length;
    if (attachmentsCount > 10) return 1;
    if (attachmentsCount > 5) return 3;
    if (attachmentsCount <= 5 && attachmentsCount) return 2;
    if (!attachmentsCount) return 0;
    return 1;
  }, [files]);

  const inputStatus = useMemo(() => {
    if (files.length < MaxFilesCount) return 'default';
    if (files.length >= MaxFilesCount) return 'limit';
  }, [files]);

  const isFilesInputDisabled = useMemo(() => disabled || files.length >= MaxFilesCount, [disabled, files]);

  const handleOpenFile = (file: FileIdResponse) => () => {
    showFilePreview({
      fileName: file.filename,
      id: file.id,
    });
  };

  const openFileAccessModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const fileType = event.target.files[0].type || event.target.files[0].name.split('.').reverse()[0];
      if (event.target.files[0] && event.target.files[0].size <= MaxFileSize && FilesFormats.includes(fileType)) {
        showFileAccessModal({ successCallback: handleAddStrictAccessFile(event), closeCallback: handleAddFile(event) });
      } else {
        notificationsManager.error({
          subtitle: t(
            `notifications.text.${
              !(event.target.files[0]?.size <= MaxFileSize) ? 'invalidFileSize' : 'invalidFileFormat'
            }`,
          ),
        });
        fileInputRef.current.value = null;
      }
    }
  };

  const handleDeleteFile = (id: number) => (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();
    setValue(
      'filesMeta',
      files.filter((item) => item.id !== id),
    );
  };

  const handleAddStrictAccessFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const filesResponse = await createFileId(event.target.files[0], true);
      setValue('filesMeta', [...files, filesResponse]);
      closeModal();
      fileInputRef.current.value = null;
    } catch {
      closeModal();
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => async () => {
    try {
      const filesResponse = await createFileId(event.target.files[0], false);
      setValue('filesMeta', [...files, filesResponse]);
      closeModal();
      fileInputRef.current.value = null;
    } catch {
      closeModal();
    }
  };

  const closeModal = () => {
    closeFileAccessModal();
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDragEnter = () => {
    wrapperRef.current.classList.add('dragover');
  };
  const handleDragLeave = () => {
    wrapperRef.current.classList.remove('dragover');
  };

  const renderFileItem = (file: FileIdResponse, index) => {
    const icon = FileTypesIcons[file.filename.split('.').reverse()[0].toLowerCase()] || 'u_wrong_file';
    return (
      <Styled.FileItem
        key={index}
        disabled={disabled}
        onClick={!disabled ? handleOpenFile(file) : null}
        withPadding={file.secured}
      >
        <Styled.FileTitle>
          <Icon type={icon} />
          <span>{file.filename}</span>
        </Styled.FileTitle>
        <Styled.IconsContainer>
          {file.secured && <Styled.LinkIcon type="u_lock_alt" />}
          <Styled.DeleteFileIcon type="u_plus" onClick={!disabled ? handleDeleteFile(file.id) : null} />
        </Styled.IconsContainer>
      </Styled.FileItem>
    );
  };

  return (
    <React.Fragment>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomPrimitiveFieldsDiff fieldName="email" />}
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="E-mail"
              disabled={disabled}
              error={!!errors['email']?.message}
              helperText={errors['email']?.message.toString()}
              value={value}
              onChange={onChange}
              inputRef={ref}
              placeholder={t('pages.candidates.candidateCreation.enterTheEmail')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="linkedIn" />}
        <Controller
          name="candidateContacts.1.contact.value"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="LinkedIn"
              disabled={disabled}
              error={!!errors?.candidateContacts?.[1]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[1]?.contact?.value?.message}
              value={value}
              onChange={onChange}
              inputRef={ref}
              placeholder={t('pages.candidates.candidateCreation.enterTheURL')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="github" />}
        <Controller
          name="candidateContacts.2.contact.value"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="GitHub"
              disabled={disabled}
              error={!!errors?.candidateContacts?.[2]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[2]?.contact?.value?.message}
              value={value}
              inputRef={ref}
              onChange={onChange}
              placeholder={t('pages.candidates.candidateCreation.enterTheURL')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="behance" />}
        <Controller
          name="candidateContacts.5.contact.value"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="Behance"
              disabled={disabled}
              inputRef={ref}
              error={!!errors?.candidateContacts?.[5]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[5]?.contact?.value?.message}
              value={value}
              onChange={onChange}
              placeholder={t('pages.candidates.candidateCreation.enterTheURL')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="phone" />}
        <Controller
          name="candidateContacts.3.contact"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <PhoneInput
              name="candidateContacts.3.contact"
              value={value}
              onChange={onChange}
              fullWidth
              inputRef={ref}
              disabled={disabled}
              label={t('pages.candidates.candidateCreation.phoneNumber')}
              error={!!errors?.candidateContacts?.[3]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[3]?.contact?.value?.message}
              placeholder={t('pages.candidates.candidateCreation.enterTheNumber')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="additionalPhone" />}
        <Controller
          name="candidateContacts.4.contact"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <PhoneInput
              name="candidateContacts.4.contact"
              value={value}
              onChange={onChange}
              fullWidth
              inputRef={ref}
              disabled={disabled}
              label={t('pages.candidates.candidateCreation.optionalPhoneNumber')}
              error={!!errors?.candidateContacts?.[4]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[4]?.contact?.value?.message}
              placeholder={t('pages.candidates.candidateCreation.enterTheNumber')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="telegram" />}
        <Controller
          name="candidateContacts.0.contact.value"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="Telegram"
              disabled={disabled}
              error={!!errors?.candidateContacts?.[0]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[0]?.contact?.value?.message}
              value={value}
              onChange={onChange}
              inputRef={ref}
              placeholder={t('pages.candidates.candidateCreation.enterTheNickname')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForDiffConsolidation>
        {isDiff && <Styled.CustomContactsFieldsDiff fieldName="skype" />}
        <Controller
          name="candidateContacts.6.contact.value"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Input
              label="Skype"
              disabled={disabled}
              error={!!errors?.candidateContacts?.[6]?.contact?.value?.message}
              helperText={errors?.candidateContacts?.[6]?.contact?.value?.message}
              value={value}
              inputRef={ref}
              onChange={onChange}
              placeholder={t('pages.candidates.candidateCreation.enterTheNickname')}
            />
          )}
        />
      </Styled.BoxForDiffConsolidation>
      <Styled.BoxForLinksDiffConsolidation $fullWidth={links?.length > 1}>
        {isDiff && <Styled.CustomLinksDataDiff />}
        {links?.map((_, index) => (
          <React.Fragment key={index}>
            <Styled.DeleteLinkContainer>
              <Controller
                name={`candidateLinks.${index}.name`}
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Input
                    disabled={disabled}
                    value={value}
                    inputRef={ref}
                    label={t('pages.candidates.candidateCreation.linkName')}
                    onChange={handleChangeInputItem(onChange, 'candidateLinks')}
                    error={!!(errors?.candidateLinks?.[index]?.name?.message || errors?.candidateLinks?.message)}
                    helperText={errors?.candidateLinks?.[index]?.name?.message || errors?.candidateLinks?.message}
                    placeholder={t('pages.candidates.candidateCreation.enterTheName')}
                  />
                )}
              />
            </Styled.DeleteLinkContainer>
            <Styled.DeleteLinkContainer>
              {links.length > 1 && (
                <Styled.DeleteButton
                  startIcon={<Styled.DeleteIcon type="u_plus" />}
                  disabled={disabled}
                  onClick={handleRemoveLink(index)}
                  variant="text"
                >
                  {t('buttons.delete')}
                </Styled.DeleteButton>
              )}
              <Controller
                name={`candidateLinks.${index}.url`}
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Input
                    disabled={disabled}
                    label={t('pages.candidates.candidateCreation.link')}
                    value={value}
                    inputRef={ref}
                    onChange={handleChangeInputItem(onChange, 'candidateLinks')}
                    error={
                      !!errors?.candidateLinks?.[index]?.url?.message ||
                      errors?.candidateLinks?.[index]?.message ||
                      errors?.candidateLinks?.message
                    }
                    helperText={
                      errors?.candidateLinks?.[index]?.url?.message ||
                      errors?.candidateLinks?.[index]?.message ||
                      errors?.candidateLinks?.message
                    }
                    placeholder={t('pages.candidates.candidateCreation.addTheLink')}
                  />
                )}
              />
            </Styled.DeleteLinkContainer>
          </React.Fragment>
        ))}
      </Styled.BoxForLinksDiffConsolidation>
      {(links?.length < MaxRefsCount || !links) && (
        <Styled.AddButton
          disabled={disabled}
          onClick={handleAddItem('candidateLinks', links, { name: '', url: '' })}
          variant="text"
          startIcon={<Styled.PlusIcon type="u_plus" />}
        >
          {t('pages.candidates.candidateCreation.addLink')}
        </Styled.AddButton>
      )}
      <Styled.Divider />
      <Styled.FilesContainer column={fileInputColumnStart}>
        {!!files.length && files.map((item, index) => renderFileItem(item, index))}
        <Styled.InputContainer
          ref={wrapperRef}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
          onDrop={handleDragLeave}
          status={inputStatus}
          columnStart={fileInputColumnStart}
          disabled={isFilesInputDisabled}
        >
          {files.length < MaxFilesCount ? (
            <React.Fragment>
              <Styled.FileInput
                type="file"
                onChange={openFileAccessModal}
                ref={fileInputRef}
                accept={FilesAcceptFormats.join(', ')}
                disabled={isFilesInputDisabled}
              />
              <Styled.FileInputPlaceholder>
                <Icon type="u_download_files" size={100} viewBox="0 0 100 100" />
                <Styled.FileInputBlockDefault>
                  <Styled.DefaultTitle>{t('pages.candidates.candidateDetails.downloadTheFiles')}</Styled.DefaultTitle>
                  <span>
                    <Trans
                      i18nKey="pages.candidates.candidateDetails.filePlaceholder"
                      components={{
                        highlight: <Styled.DefaultHightLighted />,
                      }}
                    />
                  </span>
                </Styled.FileInputBlockDefault>
              </Styled.FileInputPlaceholder>
            </React.Fragment>
          ) : (
            <Styled.FileInputPlaceholder>
              <Icon type="u_files_count_limit" size={100} viewBox="0 0 100 100" />
              <Styled.FileInputBlockLimit>
                <Styled.LimitTitle>{t('pages.candidates.candidateDetails.downloadingFiles')}</Styled.LimitTitle>
                <Styled.LimitDescriptionBlock>
                  <span>{t('pages.candidates.candidateDetails.limitFilesDescription')}</span>
                  <Styled.LimitDescriptionText>
                    {t('pages.candidates.candidateDetails.limitFilesText')}
                  </Styled.LimitDescriptionText>
                </Styled.LimitDescriptionBlock>
              </Styled.FileInputBlockLimit>
            </Styled.FileInputPlaceholder>
          )}
        </Styled.InputContainer>
      </Styled.FilesContainer>
    </React.Fragment>
  );
};

export default React.memo(ContactSection);
