import { RequestStatusEnum } from './options-values.constants';

export interface RequestStatusColors {
  backgroundColor: string;
  color: string;
}

export const RequestStatusColors: { [key: string]: RequestStatusColors } = {
  [RequestStatusEnum.HighPriority]: {
    backgroundColor: '#EAF2FC',
    color: '#3179E3',
  },
  [RequestStatusEnum.MediumPriority]: {
    backgroundColor: '#FEF2EC',
    color: '#DA5922',
  },
  [RequestStatusEnum.LowPriority]: {
    backgroundColor: '#EBEBEB',
    color: '#767676',
  },
  [RequestStatusEnum.Closed]: {
    backgroundColor: '#EAF7F6',
    color: '#098C84',
  },
  [RequestStatusEnum.Overdue]: {
    backgroundColor: '#FCECF1',
    color: '#C2315C',
  },
};
