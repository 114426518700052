import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { preloadedState } from './consolidation.preloaded-state';
import {
  cleanUpFieldDiffAction,
  clearDiffAction,
  ignoreAllDiffsAction,
  removeDuplicateItemFromListAction,
  saveMappedCandidateDiffsAction,
  saveUnsavedCandidateAction,
} from './consolidation.actions';
import {
  compareCandidatesByIdsThunk,
  compareCandidatesThunk,
  getPossibleDuplicatesThunk,
  mergeManyThunk,
} from './consolidation.thunk';

export const consolidationReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(saveMappedCandidateDiffsAction, (state, { payload }) => {
      state.diff = payload;
    })

    .addCase(getPossibleDuplicatesThunk.pending, (state) => {
      state.loadingDuplicates = true;
    })
    .addCase(getPossibleDuplicatesThunk.fulfilled, (state, { payload }) => {
      state.loadingDuplicates = false;
      state.possibleDuplicates.ids = payload.ids;
      state.possibleDuplicates.items = payload.items;
    })
    .addCase(getPossibleDuplicatesThunk.rejected, (state) => {
      state.loadingDuplicates = false;
    })
    .addCase(cleanUpFieldDiffAction, (state, { payload }) => {
      state.diff.result[payload].possibleDuplicates = [];
    })
    .addCase(saveUnsavedCandidateAction, (state, { payload }) => {
      state.unsavedCandidate = payload;
    })
    .addCase(removeDuplicateItemFromListAction, (state, { payload }) => {
      state.possibleDuplicates.ids = state.possibleDuplicates.ids.filter((id) => id.toString() !== payload.toString());
    })
    .addCase(ignoreAllDiffsAction, (state) => {
      state.diff = preloadedState.diff;
    })
    .addCase(clearDiffAction, () => preloadedState)
    .addCase(mergeManyThunk.pending, (state) => {
      state.loadingMergeMany = true;
    })
    .addCase(mergeManyThunk.fulfilled, (state) => {
      state.loadingMergeMany = false;
    })
    .addCase(mergeManyThunk.rejected, (state) => {
      state.loadingMergeMany = false;
    })
    .addMatcher(isAnyOf(compareCandidatesByIdsThunk.pending, compareCandidatesThunk.pending), (state) => {
      state.loadingDiff = true;
    })
    .addMatcher(
      isAnyOf(compareCandidatesByIdsThunk.fulfilled, compareCandidatesThunk.fulfilled),
      (state, { payload }) => {
        state.loadingDiff = false;
        state.diff = payload.diff;
        state.duplicatesInDiff = payload.duplicates;
      },
    )
    .addMatcher(isAnyOf(compareCandidatesByIdsThunk.rejected, compareCandidatesThunk.rejected), (state) => {
      state.loadingDiff = false;
    }),
);
