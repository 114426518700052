import { FC } from 'react';
import { LabelTransformer, DiffBase } from '../diff-base';
import * as Styled from './links-data-diff.styles';

const LinksDataDiff: FC = (props) => {
  const labelTransformer: LabelTransformer<'candidateLinks'> = (el) => {
    return (
      <>
        {el.name}
        <Styled.HightLighted>{` / ${el.url}`}</Styled.HightLighted>
      </>
    );
  };
  return <DiffBase multiple fieldName="candidateLinks" labelTransformer={labelTransformer} {...props} />;
};

export default LinksDataDiff;
