import { styled } from '@innowise-group/mui-kit';

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.75rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    gap: 1.25rem;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2.5rem;
  padding-left: 2rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-right: 1.5rem;
    padding-left: 1rem;
  }
`;
