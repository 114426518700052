import { BaseFilterType, DateSegmentFilterType } from '@innowise-group/core';
import * as Styled from './date-segment.styles';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton } from '../shared';
import { getDateStringWithoutTimezone } from '@innowise-group/utilities';
import { DatePicker, LabeledRadioButton } from '@shared-mui-components';

export interface DateSegmentProps extends Pick<BaseFilterType, 'isOpen' | 'labelKey'> {
  state: DateSegmentFilterType['state'];
  onValueChange: (args: DateSegmentFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: DateSegmentFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const DateSegment: React.FC<DateSegmentProps> = ({
  state,
  labelKey,
  isOpen,
  onValueChange,
  onToggleFilter,
  onRemoveFilter,
  onClickFilterOutside,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onDateChange = (date: Date) => {
    if (!date) return onValueChange({ ...state, date: null });
    onValueChange({ ...state, date: getDateStringWithoutTimezone(date) });
  };

  const onSegmentChange = (currentSegment: DateSegmentFilterType['state']['currentSegment']) => () => {
    onValueChange({ ...state, currentSegment });
  };

  const onRemoveFilterHandle = () => {
    onRemoveFilter({ ...state, date: null, currentSegment: state.segments[0] });
  };

  const onClickFilterOutsideHandle = () => {
    const isEmpty = !state.date;
    if (isEmpty) onRemoveFilterHandle();
    onClickFilterOutside();
  };

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Wrapper ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandle}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          {state.date && (
            <Styled.FilterLabelDate>
              {`${t(`filters.union.${state.currentSegment.toLowerCase()}`)} ${new Date(state.date).toLocaleDateString(
                undefined,
                { month: '2-digit', year: 'numeric' },
              )}`}
            </Styled.FilterLabelDate>
          )}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          <DatePicker
            selected={state.date ? new Date(state.date) : null}
            onChange={onDateChange}
            // TODO: Get min date and year according first "request" in database
            minDate={new Date('2023-01-01T00:00:00')}
            minYear={2023}
            withoutDays
            placeholderText={t('pages.candidates.candidateCreation.from')}
          />
          <Styled.SegmentsContainer>
            {state.segments.map((segment) => (
              <Styled.Segment key={segment}>
                <LabeledRadioButton
                  value={'false'}
                  onChange={onSegmentChange(segment)}
                  checked={state.currentSegment === segment}
                  label={t(`filters.union.${segment.toLowerCase()}`)}
                />
              </Styled.Segment>
            ))}
          </Styled.SegmentsContainer>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default memo(DateSegment);
