import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import i18n from '@localization';
import { localizedNameObject } from '@innowise-group/utilities';

export const getStatisticsState = (state: RootState) => state.statistic;
export const getBaseState = (state: RootState) => state;

export const getStatisticsData = createSelector(getStatisticsState, (state) => {
  return state;
});

export const getStatusesByVacancyStatisticsData = (withoutVacancy: boolean) =>
  createSelector(getStatisticsState, (state) => {
    if (withoutVacancy && state.statusesByVacancies.last) {
      const totalData = state.statusesByVacancies.items.reduce<{ [key: string]: number }>((acc, { data }) => {
        Object.entries(data).map(([key, val]) => {
          if (!acc[key]) {
            acc[key] = val;
          } else {
            acc = { ...acc, [key]: acc[key] + val };
          }
        });
        return acc;
      }, {});
      const overallData = state.overallStatuses || {};

      const data = Object.entries(overallData).reduce<{ [key: string]: number }>((acc, [key, val]) => {
        acc = { ...acc, [key]: val - (totalData[key] || 0) };
        return acc;
      }, {});

      const items = [
        ...state.statusesByVacancies.items,
        { vacancy: { id: 0, name: i18n.t('pages.candidates.comments.jobOpening') }, data },
      ].filter(({ data }) => {
        return Object.values(data).find((item) => item);
      });
      return { ...state.statusesByVacancies, items };
    }
    const items = state.statusesByVacancies.items.filter(({ data }) => {
      return Object.values(data).find((item) => item);
    });
    return { ...state.statusesByVacancies, items };
  });

export const getVerticalTeamWorkingByVacancyStatisticsData = (withoutVacancy: boolean) =>
  createSelector(getBaseState, (state) => {
    if (withoutVacancy && state.statistic.verticalTeamWorking.last) {
      const totalData = state.statistic.verticalTeamWorking.items.reduce<{
        [key: number]: { employeeName: string; statusAmount: { [key: string]: number } };
      }>((acc, { data }) => {
        data.map((item) => {
          if (!acc[item.employee.id]) {
            acc = { ...acc, [item.employee.id]: { employeeName: item.employee.name, statusAmount: item.statusAmount } };
          } else {
            Object.entries(item.statusAmount).map(([key, val]) => {
              if (!acc[item.employee.id].statusAmount[key]) {
                acc[item.employee.id].statusAmount = { ...acc[item.employee.id].statusAmount, [key]: val };
              } else {
                acc[item.employee.id].statusAmount = {
                  ...acc[item.employee.id].statusAmount,
                  [key]: acc[item.employee.id].statusAmount[key] + val,
                };
              }
            });
          }
        });
        return acc;
      }, {});
      const overallData = state.statistic.overallTeamWorking;
      const employees = state.options.options.statusAuthorsOptions;

      const totalDataArray = overallData
        ? Object.entries(overallData).map(([employeeId, value]) => {
            if (!totalData[employeeId]) {
              const employeeName = employees.find((item) => item.value === employeeId)?.title || '';
              return { employee: { id: Number(employeeId), name: employeeName }, statusAmount: value };
            }
            const statusesData: { [key: string]: number } = Object.entries(value).reduce((accum, [status, count]) => {
              accum = { ...accum, [status]: count - (totalData[employeeId]?.[status] || 0) };
              return accum;
            }, {});
            return {
              employee: { id: Number(employeeId), name: totalData[employeeId].employeeName },
              statusAmount: statusesData,
            };
          })
        : [];
      const items = [
        ...state.statistic.verticalTeamWorking.items,
        { vacancy: { id: 0, name: i18n.t('pages.candidates.comments.jobOpening') }, data: totalDataArray },
      ].filter(({ data }) => {
        const isNotEmpty = data.find(({ statusAmount }) => {
          return Object.values(statusAmount).find((item) => item);
        });
        return isNotEmpty;
      });
      return { ...state.statistic.verticalTeamWorking, items };
    }
    const items = state.statistic.verticalTeamWorking.items.filter(({ data }) => {
      const isNotEmpty = data.find(({ statusAmount }) => {
        return Object.values(statusAmount).find((item) => item);
      });
      return isNotEmpty;
    });
    return { ...state.statistic.verticalTeamWorking, items };
  });

export const getHorizontalTeamWorkingByVacancyStatisticsData = createSelector(getStatisticsState, (state) => {
  return state.horizontalTeamWorking;
});

export const getSummaryStatisticsData = createSelector(getStatisticsState, (state) => {
  return state.summary;
});

export const getExitsStatisticsData = createSelector(getStatisticsState, (state) => {
  return {
    ...state.exits,
    items: state.exits.items.map((item) => {
      return {
        ...item,
        candidateShortInfoDto: {
          ...item.candidateShortInfoDto,
          localizedFullName: localizedNameObject(item.candidateShortInfoDto).localizedFullName,
        },
        employeeShortInfoDto: {
          ...item.employeeShortInfoDto,
          localizedFullName: localizedNameObject(item.employeeShortInfoDto).localizedFullName,
        },
      };
    }),
  };
});

export const getPersonalStatisticsData = (withoutVacancy: boolean) =>
  createSelector(getStatisticsState, (state) => {
    if (withoutVacancy && state.personalStatistic.last) {
      const totalData = state.personalStatistic.items.reduce<{ [key: string]: number }>((acc, { data }) => {
        Object.entries(data).map(([key, val]) => {
          if (!acc[key]) {
            acc[key] = val;
          } else {
            acc = { ...acc, [key]: acc[key] + val };
          }
        });
        return acc;
      }, {});
      const overallData = state.overallStatuses || {};
      const data = Object.entries(overallData).reduce<{ [key: string]: number }>((acc, [key, val]) => {
        acc = { ...acc, [key]: val - (totalData[key] || 0) };
        return acc;
      }, {});
      const items = [
        ...state.personalStatistic.items,
        { vacancy: { id: 0, name: i18n.t('pages.candidates.comments.jobOpening') }, data },
      ].filter(({ data }) => {
        return Object.values(data).find((item) => item);
      });
      return { ...state.personalStatistic, items };
    }
    const items = state.personalStatistic.items.filter(({ data }) => {
      return Object.values(data).find((item) => item);
    });
    return { ...state.personalStatistic, items };
  });
