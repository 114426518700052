import { CandidateStatusColorsItem } from '@constants';
import { Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';
import { Image } from '@shared-mui-components';
import { InfoTooltip as BaseInfoTooltip } from '../../../info-tooltip';

export const AvatarContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const NoImageContainer = styled.div`
  height: 4.375rem;
  width: 4.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

export const Avatar = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  height: 4.375rem;
  width: 4.375rem;
  flex-shrink: 0;
`;

export const FullNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.primary[70]}
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const PeriodSection = styled.div`
  display: flex;
  gap: 0.75rem;
  & > * {
    flex: 1;
  }
`;

export const ButtonsSection = styled(PeriodSection)`
  margin-top: 1rem;
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const FilesContainer = styled.div`
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  & > * {
    min-width: 0;
  }
`;

export const StatusItem = styled.div<{ statusColors: CandidateStatusColorsItem }>`
  color: ${({ statusColors }) => statusColors?.color};
  border-radius: 0.5rem;
  border: 1px solid ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
  background-color: ${({ statusColors, theme }) =>
    statusColors?.backgroundColor || theme.palette.general.lightGrey[60]};
  ${({ theme }) => theme.fonts.primary[40]}
  padding: 0.875rem;
  margin-top: 7px;
  position: relative;
  line-height: 1;
  height: 1rem;
  box-sizing: content-box;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(0.6) translateX(-40%);
    content: '';
    height: 80%;
    width: 0.75rem;
    background-color: ${({ statusColors, theme }) => statusColors?.borderColor || theme.palette.general.lightGrey[60]};
    border-radius: 0px 2rem 2rem 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled.div``;

export const InfoTooltip = styled(BaseInfoTooltip)`
  margin: 0 0 3px 5px;
`;
