import { FC } from 'react';
import { DiffBase, DiffBaseProps, LabelTransformer } from '../diff-base';
import { AvatarItem } from '@innowise-group/mui-kit';

const ResponsibleDiff: FC<Omit<DiffBaseProps, 'labelTransformer' | 'fieldName'>> = (props) => {
  const labelTransformer: LabelTransformer<'responsibleEmployee'> = (employee) => (
    <AvatarItem photoId={employee.photoId?.toString()} name={employee.firstNameEn} isOption />
  );
  return <DiffBase fieldName="responsibleEmployee" labelTransformer={labelTransformer} {...props} />;
};

export default ResponsibleDiff;
