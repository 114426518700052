import React from 'react';
import { StatusItemResponse, useResizeObserver } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import DesktopView from './desktop-view/desktop-view.component';
import MobileView from './mobile-view/mobile-view.component';

interface MainInfoSectionProps {
  status: StatusItemResponse;
  photoId?: number;
  localizedFirstName: string;
  localizedLastName: string;
}

const MainInfoSection: React.FC<MainInfoSectionProps> = ({ localizedFirstName, localizedLastName, ...props }) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  return isDesktopView ? (
    <DesktopView {...props} localizedFullName={`${localizedLastName} ${localizedFirstName}`} />
  ) : (
    <MobileView {...props} localizedFirstName={localizedFirstName} localizedLastName={localizedLastName} />
  );
};

export default MainInfoSection;
