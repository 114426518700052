import { styled, Input, Icon } from '@innowise-group/mui-kit';

export const CustomInput = styled(Input)`
  &.MuiTextField-root {
    width: 90px;
  }
`;

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
  width: auto;
`;

export const ArrowsContainer = styled.div`
  position: absolute;
  height: calc(100% - 1rem - 12px);
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ArrowUp = styled.div`
  & > * {
    transform: rotate(90deg);
    margin: 0;
    padding: 0;
  }
`;
export const ArrowDown = styled.div`
  & > * {
    transform: rotate(-90deg);
    margin: 0;
    padding: 0;
  }
`;
