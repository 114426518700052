import { styled } from '@innowise-group/mui-kit';

export const FilterWrapper = styled.div`
  background: ${({ theme }) => theme.palette.general.paper};
  height: 100%;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[80]}
`;

export const SegmentsContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: space-around;
`;

export const Segment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
