import { StatusLifecycleStatuses } from '@constants';
import { useStatusConfigById } from '@innowise-group/core';
import { Input, Select } from '@innowise-group/mui-kit';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SubStatusSection: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch, resetField } = useFormContext();
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);

  const subStatusOptions = useMemo(() => {
    return statusConfig.subStatus.reduce((acc, item) => {
      if (item.lifecycleStatus !== StatusLifecycleStatuses.Deleted) {
        acc.push({
          title: item.localizedName,
          value: item.id,
        });
      }
      return acc;
    }, []);
  }, [statusConfig]);

  useEffect(() => {
    resetField('subStatusId');
  }, [resetField, statusId]);

  return (
    <Controller
      name="subStatusId"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <Select
          value={value}
          onChange={onChange}
          options={subStatusOptions || []}
          placeholder={t('pages.candidates.statuses.selectASubStatus')}
          renderInput={(params) => {
            return (
              <Input
                {...params}
                label={t('pages.candidates.statuses.subStatus')}
                placeholder={t('pages.candidates.candidateCreation.selectGrade')}
                error={!!errors?.subStatusId?.message}
                helperText={errors?.subStatusId?.message.toString()}
              />
            );
          }}
        />
      )}
    />
  );
};

export default SubStatusSection;
