import { FC } from 'react';
import { format } from 'date-fns';
import { es, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './experience-diff.styles';
import { AppLanguages, DateFormats } from '@constants';
import { getLanguageFromLS } from '@innowise-group/utilities';

const ExperienceDiff: FC = (props) => {
  const { t } = useTranslation();
  const ln = getLanguageFromLS();
  const locale = ln === AppLanguages.En ? es : ru;
  const labelTransformer: LabelTransformer<'candidateExperiences'> = ({
    company,
    position,
    workedFrom,
    workedTo,
    stillWorking,
    itSphere,
    comment,
  }) => {
    const workedFromDate = workedFrom ? format(new Date(workedFrom), DateFormats.LongLocalizedDate, { locale }) : '';
    const workedToDate = workedTo ? format(new Date(workedTo), DateFormats.LongLocalizedDate, { locale }) : '';
    return (
      <Styled.OptionWrapper>
        <Styled.Field>{`${company} / ${position}`}</Styled.Field>
        <Styled.Field>
          {workedFromDate ? workedFromDate : ''} {workedToDate ? ` - ${workedToDate}` : ''}
        </Styled.Field>
        <Styled.Field>
          {`${itSphere ? t('pages.candidates.candidateCreation.itSphere') : ''} ${
            stillWorking ? t('pages.candidates.candidateCreation.workingNow') : ''
          }`}
        </Styled.Field>
        <Styled.FieldLabel>{comment}</Styled.FieldLabel>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase multiple fieldName="candidateExperiences" labelTransformer={labelTransformer} {...props} />;
};

export default ExperienceDiff;
