import { VacanciesState } from './vacancies.types';

export const preloadedVacanciesState: VacanciesState = {
  vacancies: {
    isLoading: false,
    items: {},
    ids: [],
    totalPages: 0,
    totalElements: 0,
    isExportLoading: false,
    currentVacancy: null,
    currentCandidateVacancies: null,
    overallFound: 0,
    overallNeeded: 0,
    requestCount: 0,
  },
  vacancyOptions: {
    ids: [],
    items: null,
    options: null,
    optionsLoading: false,
    additionalOptionsLoading: false,
    totalPages: 0,
  },
};
