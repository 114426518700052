import { createAction } from '@reduxjs/toolkit';
import {
  CandidateCourseElement,
  CandidateDiffElem,
  CandidateEducationElement,
  CandidateExperience,
  CandidateFileItemResponse,
  CandidateItemResponse,
  CandidateLanguage,
  CandidateLinkItem,
  CandidateProfession,
  CandidatesConsolidation,
  CandidatesState,
  EditCandidateMode,
  FormattedCandidateDiffMerge,
  PrimitiveValuesUnionInCandidatesDiff,
} from './candidates.types';
import { SelectOption } from '@innowise-group/core';

export const resetCandidatesStateAction = createAction('candidates/resetCandidatesStateAction');
export const clearCandidatesOptionsAction = createAction('candidates/clearOptions');
export const saveNewCandidateInfoAction = createAction<CandidateItemResponse>('candidates/saveNewCandidateInfo');
export const toggleCandidateDupleAction = createAction<number>('candidates/toggleCandidateDuple');
export const clearCandidatesAction = createAction('candidates/clear');
export const clearCandidateFilesIdsAction = createAction('candidates/clearFiles');
export const clearCandidateFileAttachmentAction = createAction('candidates/clearCurrentFile');
export const clearCandidateAvatarAction = createAction('candidates/clearAvatar');

export const setPagableAction =
  createAction<Omit<CandidatesState['report']['pagable'], 'pageSizes'>>('candidatesReport/setPagable');

export const manageCandidateConsolidationFilesAction = createAction<{
  mode: EditCandidateMode;
  file: CandidateFileItemResponse;
}>('candidates/manageCandidateConsolidationFiles');

export const deleteCandidateLinkAction = createAction<CandidateLinkItem>('candidates/deleteCandidateLinkAction');

export const clearDuplicatesReportAction = createAction('candidates/clearReport');

export const updateCandidatePrimitiveValuesAction = createAction<{
  field: PrimitiveValuesUnionInCandidatesDiff;
  value: string | number | boolean;
}>('candidates/updateCandidatePrimitiveValuesAction');

export const updateCandidatesContactsDiffAction = createAction<{
  field: keyof FormattedCandidateDiffMerge['candidateContacts'];
  value: string;
}>('candidates/updateCandidatesContactsDiff');

export const saveCandidatesDiffAction = createAction<
  Pick<CandidatesConsolidation, 'targetCandidate' | 'updatesCandidate' | 'candidatesDiffs'>
>('candidates/saveCandidatesDiffAction');

export const addProfessionInToCandidatesDiffAction = createAction<{
  updates: CandidateProfession;
  mode: EditCandidateMode;
  source?: keyof FormattedCandidateDiffMerge['candidateProfessions'];
}>('candidates/addProfessionInToCandidatesDiff');
export const removeProfessionsIdsFromCandidatesDiffAction = createAction<{
  ids: number[];
  source?: keyof FormattedCandidateDiffMerge['candidateProfessions'];
}>('candidates/removeProfessionsIdsFromCandidatesDiffAction');
export const updateProfessionNameInCandidatesDiffAction = createAction<{
  professionId: string;
  index: number;
}>('candidates/updateProfessionNameInCandidatesDiff');
export const updateProfessionGradeInCandidatesDiffAction = createAction<{
  gradeId: string;
  index: number;
}>('candidates/updateProfessionGradeInCandidatesDiff');

export const addLanguageInToCandidatesDiffAction = createAction<{
  updates: CandidateLanguage;
  mode: EditCandidateMode;
  source?: keyof FormattedCandidateDiffMerge['candidateLanguages'];
}>('candidates/addLanguageInToCandidatesDiff');
export const updateLanguageNameInCandidatesDiffAction = createAction<{
  languageId: string;
  index: number;
}>('candidates/updateLanguageNameInCandidatesDiff');
export const updateLanguageLevelInCandidatesDiffAction = createAction<{
  languageLevel: string;
  index: number;
}>('candidates/updateLanguageLevelInCandidatesDiff');

export const addORDeleteCandidateDiffMultiSelectElemAction = createAction<{
  updates: SelectOption;
  mode: EditCandidateMode;
  // TODO: update after package consolidation
  entity: keyof Pick<
    FormattedCandidateDiffMerge,
    | 'candidateTechnologies'
    | 'candidateDomains'
    | 'candidateRelocationCountries'
    | 'candidatePrioritizedVacancies'
    | 'citizenshipId'
  >;
  source: keyof CandidateDiffElem<string>;
}>('candidates/addORDeleteCandidateDiffMultiSelectElemAction');

export const setCandidateDiffMultiSelectElemAction = createAction<{
  updates: SelectOption[];
  // TODO: update after package consolidation
  entity: keyof Pick<
    FormattedCandidateDiffMerge,
    | 'candidateTechnologies'
    | 'candidateDomains'
    | 'candidateRelocationCountries'
    | 'candidatePrioritizedVacancies'
    | 'citizenshipId'
  >;
}>('candidates/setCandidateDiffMultiSelectElemAction');

export const setLocalizationEntityAction = createAction<{
  entityName: keyof FormattedCandidateDiffMerge['candidateLocation'];
  val: string;
}>('candidates/setLocalizationEntityAction');

export const updateLocalizationEntityAction = createAction<{
  entityName: keyof FormattedCandidateDiffMerge['candidateLocation'];
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
}>('candidates/updateLocalizationEntityAction');

export const updateVisaEntityAction = createAction<{
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
  mode: EditCandidateMode;
}>('candidates/updateVisaEntityAction');

export const removeProfessionsIdsFromVisaEntityAction = createAction<
  keyof Pick<CandidateDiffElem, 'target' | 'updates'>
>('candidates/removeProfessionsIdsFromVisaEntityAction');

export const setVisaEntityAction = createAction<{
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateVisas'>['candidateVisas']['preselected'];
  val: string;
}>('candidates/setVisaEntityAction');

export const resetCandidateRelocationCountriesAction = createAction(
  'candidates/resetCandidateRelocationCountriesAction',
);
export const resetCandidateVisasAction = createAction('candidates/resetCandidateVisasAction');

export const setWorkFormatsAndLoadsAction = createAction<{
  value: string;
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateWorkFormats' | 'candidateWorkloads'>;
}>('candidates/setWorkFormatsAndLoadsAction');

export const manageWorkFormatsLoadsEmploymentsAction = createAction<{
  mode: EditCandidateMode;
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateWorkFormats' | 'candidateWorkloads' | 'employmentForms'>;
  value: string;
}>('candidates/manageWorkFormatsLoadsEmploymentsAction');

export const updateCandidateSalaryAction = createAction<
  Record<keyof Omit<FormattedCandidateDiffMerge['candidateSalary'], 'currencyId' | 'expectedSalaryComment'>, string>
>('candidates/updateCandidateSalaryAction');

export const updateCandidateSalaryFieldAction = createAction<{
  field: keyof Omit<FormattedCandidateDiffMerge['candidateSalary'], 'currencyId'>;
  value: string;
}>('candidates/updateCandidateSalaryFieldAction');

export const manageCandidateMapDataAction = createAction<{
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateExperiences' | 'candidateCourses' | 'candidateEducations'>;
  source?: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
  data: CandidateExperience | CandidateCourseElement | CandidateEducationElement;
  mode: EditCandidateMode;
  index?: number;
}>('candidates/manageCandidateMapDataAction');

export const setCandidateMapDataAction = createAction<{
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateRelocationCountries'>;
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
}>('candidates/setCandidateMapData');

export const mergeCandidateAction = createAction<{
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
  blackListFields?: (keyof FormattedCandidateDiffMerge | 'sourceId' | 'vacancy')[];
}>('candidate/mergeCandidate');

export const updateEntityFieldAction = createAction<{
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateExperiences' | 'candidateCourses' | 'candidateEducations'>;
  value: string | boolean;
  field: string;
  index: number;
}>('candidates/updateEntityFieldAction');

export const clearCandidateHistoryAction = createAction('candidates/clearHistory');
export const deleteHistoryCommentAction = createAction<{ changeStoryGroupId: number; commentId: number }>(
  'candidates/deleteHistoryCommentAction',
);
export const restoreHistoryCommentAction = createAction<{ changeStoryGroupId: number; commentId: number }>(
  'candidates/restoreHistoryCommentAction',
);
export const clearRecruiterCandidateAction = createAction('candidates/clearRecruiterCandidate');
export const setRecruiterModeConfigAction = createAction<{ vacancyId: number; vacancyName: string; requestId: number }>(
  'candidates/setRecruiterModeConfig',
);

export const clearStatusListAction = createAction('candidates/clearStatusesList');
export const clearCurrentStatusAction = createAction('candidates/clearCurrentStatus');
export const clearCandidateEventsAction = createAction('candidates/clearCandidateEvents');
export const deleteCommentAction = createAction<{ commentId: number; parentId?: number; isLoading?: boolean }>(
  'candidateEvents/deleteComment',
);
export const restoreCommentAction = createAction<{ commentId: number; parentId?: number }>(
  'candidateEvents/restoreComment',
);
export const setCurrentStatusItemAction = createAction<{ eventId: number }>('candidate/setCurrentStatusItem');
export const clearActualStatusAction = createAction('candidates/clearActualStatus');

export const setCurrentCandidateAction = createAction<CandidateItemResponse>('candidates/setCurrentCandidate');
export const clearCandidatesOffersAction = createAction('candidates/clearCandidatesOffersAction');
export const clearCandidatesClaimAction = createAction('candidates/clearCandidatesClaim');
