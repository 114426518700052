import React from 'react';
import { FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';
import * as Styled from './form.styles';

export interface FormFieldProps {
  label?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl>;
  errorType?: string;
  labelTooltip?: React.ReactNode;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  size?: number;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  error,
  children,
  size,
  required,
  labelTooltip,
  disabled,
  ...props
}) => {
  return (
    <Styled.FormFieldContainer {...props}>
      {(label || labelTooltip) && (
        <Styled.FormFieldLabel required={required} disabled={disabled}>
          {label}
          {labelTooltip}
        </Styled.FormFieldLabel>
      )}
      <div>
        {children}
        <Styled.FormFieldError width={size}>{error as string}</Styled.FormFieldError>
      </div>
    </Styled.FormFieldContainer>
  );
};

export default FormField;
