import { Button, Loader } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const Header = styled.div`
  display: flex;
  gap: 10px;
  ${({ theme }) => theme.fonts.primary[90]}
  align-items: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  & svg {
    flex-shrink: 0;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  ${({ theme }) => theme.fonts.primary[40]}
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const InputLabel = styled.span`
  transition: 0.2s;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.state.error.pressed};
  &:hover {
    color: ${({ theme }) => theme.palette.state.error.default};
    text-decoration: underline;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  ${({ theme }) => theme.fonts.primary[60]}
  line-height: 22px;
  position: absolute;
  right: 85px;
  top: -2px;
  & button {
    width: fit-content;
    padding: 0;
    & > div {
      margin: 0;
    }
  }
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.state.error.default};
    & svg {
      fill: ${({ theme }) => theme.palette.state.error.default};
    }
  }
`;

export const FileButtonContainer = styled(ButtonContainer)`
  top: 12px;
  right: 0;
  & svg {
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;

export const SecureFileIconContainer = styled.div`
  position: absolute;
  top: 11px;
  left: 2px;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;

export const FileItemLoader = styled(Loader)`
  margin: 1px 7px;
`;

export const FileItem = styled.div`
  position: relative;
  align-self: center;
  cursor: pointer;
  ${({ theme }) => theme.fonts.primary[60]};
  padding: 0.8125rem 1.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  justify-content: center;
  max-width: 15rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[60]};
  & span {
    max-width: 9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SubmitButton = styled(Button)`
  min-width: 10rem;
  width: auto;
  align-self: end;
`;
