import React from 'react';
import { AuthService, useResizeObserver } from '@innowise-group/core';
import { AppRoutes } from '@constants';
import * as Styled from './auth.styles';
import { AuthDesktopView } from './components/auth-desktop-view';
import { AuthMobileView } from './components/auth-mobile-view';
import { useTheme } from '@innowise-group/mui-kit';

const Auth: React.FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.lg);

  const handleSignIn = () => {
    AuthService.login({ redirectUri: window.location.origin + AppRoutes.Home });
  };

  return (
    <Styled.AuthContainer>
      {isDesktopView ? <AuthDesktopView handleSignIn={handleSignIn} /> : <AuthMobileView handleSignIn={handleSignIn} />}
    </Styled.AuthContainer>
  );
};

export default Auth;
