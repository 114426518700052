import { styled } from '@innowise-group/mui-kit';
import { Modal } from '@innowise-group/mui-kit';

export const Header = styled.div`
  display: flex;
  gap: 10px;
  ${({ theme }) => theme.fonts.primary[90]}
  align-items: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const Container = styled(Modal.Container)`
  & button {
    margin-right: 0;
  }
`;
