import React from 'react';
import * as Styled from './desktop-view.styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SelectOption,
  VacancyItem,
  VacancyLanguage,
  VacancyPosition,
  useControlFormField,
  useOptionsApi,
  useUnitsAPI,
  useUnitsAdministrationOptions,
  useValidationSchemaVacancy,
} from '@innowise-group/core';
import { Button, Select } from '@innowise-group/mui-kit';
import { LabeledCheckbox } from '@shared-mui-components';
import { InfoTooltip } from '@shared-components';
import { MaxLanguagesCount, MaxProfessionsCount, PurposeEnum, VacancyStatusesWithoutRequests } from '@constants';

interface DesktopViewProps {
  isRequests?: boolean;
  isEdit?: boolean;
  forRequestCreation?: boolean;
  locationCountryOptions: SelectOption[];
  locationCityOptions: SelectOption[];
  handleClose: () => void;
  onSubmit: (data: VacancyItem) => void;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  isEdit,
  locationCountryOptions,
  locationCityOptions,
  isRequests,
  forRequestCreation,
}) => {
  const { t } = useTranslation();
  const unitsOptions = useUnitsAdministrationOptions();
  const { isLoading: isUnitsLoading } = useUnitsAPI();
  const { control, watch } = useFormContext();
  const hiringPurpose = watch('hiringPurpose');
  const published = watch('published');
  const countries = watch('countries');
  const languages = watch('vacancyLanguages');
  const professions = watch('vacancyProfessions');
  const {
    domainOptions,
    gradeOptions,
    professionOptions,
    technologyOptions,
    vacancyStatus,
    languageLevelOptions,
    languageOptions,
    vacancyPurposeOptions,
  } = useOptionsApi();

  const { validationSchema } = useValidationSchemaVacancy();
  const { handleAddItem, handleRemoveItem, handleChangeSelectItem } = useControlFormField(validationSchema);

  return (
    <Styled.BodyContainer>
      <Styled.RowContainer>
        <Styled.VacancyContainer>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Styled.FullInput
                label={t('pages.vacancies.creationModal.name')}
                required
                value={value}
                onChange={onChange}
                error={!!errors?.['name']?.message}
                helperText={errors?.['name']?.message.toString()}
                placeholder={t('pages.vacancies.creationModal.enterVacancyName')}
              />
            )}
          />
        </Styled.VacancyContainer>
        <Styled.StatusContainer>
          <Controller
            name="vacancyStatusId"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => {
              return (
                <Select
                  value={value}
                  onChange={onChange}
                  disabled={published}
                  options={vacancyStatus}
                  disableClearable
                  getOptionDisabled={(option: SelectOption) =>
                    forRequestCreation && VacancyStatusesWithoutRequests.includes(option.value)
                  }
                  renderInput={(params) => {
                    return (
                      <Styled.FullInput
                        {...params}
                        fullWidth
                        required
                        label={t('pages.vacancies.creationModal.status')}
                        placeholder={t('pages.vacancies.creationModal.selectStatus')}
                        error={!!errors?.vacancyStatusId?.message}
                        helperText={errors?.vacancyStatusId?.message.toString()}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Styled.StatusContainer>
      </Styled.RowContainer>
      <Styled.RowContainer>
        <Styled.VacancyContainer>
          <Controller
            name="hiringPurpose"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <Select
                value={value}
                onChange={onChange}
                options={vacancyPurposeOptions}
                disableClearable
                disabled={isEdit && isRequests}
                renderInput={(params) => {
                  return (
                    <Styled.FullInput
                      {...params}
                      fullWidth
                      required
                      label={t('pages.vacancies.creationModal.employmentPurpose')}
                      error={!!errors?.hiringPurpose?.message}
                      helperText={errors?.hiringPurpose?.message.toString()}
                    />
                  );
                }}
              />
            )}
          />
        </Styled.VacancyContainer>
        <Styled.StatusContainer>
          {hiringPurpose === PurposeEnum.Trainee && (
            <Styled.PublishCheckboxContainer>
              <Controller
                name="published"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Styled.LabeledCheckboxContainer>
                    <LabeledCheckbox
                      label={t('pages.vacancies.creationModal.publish')}
                      onChange={onChange}
                      checked={value}
                    />
                  </Styled.LabeledCheckboxContainer>
                )}
              />
              <InfoTooltip title={t('tooltips.vacancyForm')} placement="top-end" />
            </Styled.PublishCheckboxContainer>
          )}
        </Styled.StatusContainer>
      </Styled.RowContainer>
      <Styled.FieldsContainer>
        {!!professions?.length &&
          professions.map((item: VacancyPosition, index: number) => (
            <Styled.RowContainer key={index}>
              <Styled.DoubleContainer>
                <Styled.ProfessionContainer>
                  <Controller
                    name={`vacancyProfessions.${index}.profession`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Select
                        value={value}
                        onChange={handleChangeSelectItem(onChange, 'vacancyProfessions')}
                        options={professionOptions}
                        disabled={isRequests || (isEdit && published)}
                        disableSortByAlphabet={false}
                        renderInput={(params) => {
                          return (
                            <Styled.FullInput
                              {...params}
                              required={!index}
                              fullWidth
                              label={`${t('pages.vacancies.creationModal.specialty')} ${index ? index + 1 : ''}`}
                              placeholder={t('pages.vacancies.creationModal.selectSpecialty')}
                              error={
                                !!(
                                  errors?.vacancyProfessions?.[index]?.profession?.message ||
                                  errors?.vacancyProfessions?.[index]?.message ||
                                  errors?.vacancyProfessions?.message
                                )
                              }
                              helperText={(
                                errors?.vacancyProfessions?.[index]?.profession?.message ||
                                errors?.vacancyProfessions?.[index]?.message ||
                                errors?.vacancyProfessions?.message
                              )?.toString()}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Styled.ProfessionContainer>
                <Styled.GradeContainer>
                  <Controller
                    name={`vacancyProfessions.${index}.grade`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Select
                        value={value}
                        onChange={handleChangeSelectItem(onChange, 'vacancyProfessions')}
                        options={gradeOptions}
                        placeholder={t('pages.vacancies.creationModal.selectGrade')}
                        disabled={isRequests || (isEdit && published)}
                        renderInput={(params) => {
                          return (
                            <Styled.FullInput
                              {...params}
                              fullWidth
                              label={`${t('pages.vacancies.creationModal.grade')} ${index ? index + 1 : ''}`}
                              placeholder={t('pages.candidates.candidateCreation.selectGrade')}
                              error={
                                !!(
                                  errors?.vacancyProfessions?.[index]?.grade?.message ||
                                  errors?.vacancyProfessions?.[index]?.message
                                )
                              }
                              helperText={(
                                errors?.vacancyProfessions?.[index]?.grade?.message ||
                                errors?.vacancyProfessions?.[index]?.message
                              )?.toString()}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Styled.GradeContainer>
              </Styled.DoubleContainer>
              <Styled.ToolsContainer>
                {professions.length > 1 && !isRequests && !(isEdit && published) ? (
                  <Styled.DeleteIcon
                    type="u_plus"
                    onClick={handleRemoveItem<VacancyPosition>(index, 'vacancyProfessions', professions)}
                  />
                ) : (
                  <div />
                )}
                {professions?.length < MaxProfessionsCount && !isRequests && index === professions.length - 1 ? (
                  <Button
                    onClick={handleAddItem('vacancyProfessions', professions, { profession: '', grade: '' })}
                    variant="outlined"
                    disabled={published}
                    startIcon={<Styled.Icon type="u_plus" size={14} />}
                  >
                    {t('buttons.more')}
                  </Button>
                ) : (
                  <div />
                )}
              </Styled.ToolsContainer>
            </Styled.RowContainer>
          ))}
        {!!languages?.length &&
          languages.map((item: VacancyLanguage, index: number) => (
            <Styled.RowContainer key={index}>
              <Styled.DoubleContainer>
                <Styled.LanguageContainer>
                  <Controller
                    name={`vacancyLanguages.${index}.language`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Select
                        value={value}
                        onChange={handleChangeSelectItem(onChange, 'vacancyLanguages')}
                        options={languageOptions}
                        disabled={isRequests || (isEdit && published)}
                        disableSortByAlphabet={false}
                        renderInput={(params) => {
                          return (
                            <Styled.FullInput
                              {...params}
                              fullWidth
                              label={`${t('pages.vacancies.creationModal.language')} ${index ? index + 1 : ''}`}
                              placeholder={t('pages.vacancies.creationModal.selectLanguage')}
                              error={
                                !!(
                                  errors?.vacancyLanguages?.[index]?.language?.message ||
                                  errors?.vacancyLanguages?.[index]?.message
                                )
                              }
                              helperText={(
                                errors?.vacancyLanguages?.[index]?.language?.message ||
                                errors?.vacancyLanguages?.[index]?.message
                              )?.toString()}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Styled.LanguageContainer>
                <Styled.LanguageLevelContainer>
                  <Controller
                    name={`vacancyLanguages.${index}.languageLevel`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Select
                        value={value}
                        onChange={handleChangeSelectItem(onChange, 'vacancyLanguages')}
                        options={languageLevelOptions}
                        disabled={isRequests || (isEdit && published)}
                        renderInput={(params) => {
                          return (
                            <Styled.FullInput
                              {...params}
                              fullWidth
                              label={`${t('pages.vacancies.creationModal.level')} ${index ? index + 1 : ''}`}
                              placeholder={t('pages.vacancies.creationModal.selectLevel')}
                              error={!!errors?.vacancyLanguages?.[index]?.languageLevel?.message}
                              helperText={errors?.vacancyLanguages?.[index]?.languageLevel?.message.toString()}
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Styled.LanguageLevelContainer>
              </Styled.DoubleContainer>
              <Styled.ToolsContainer>
                {languages.length > 1 && !isRequests && !(isEdit && published) ? (
                  <Styled.DeleteIcon
                    onClick={handleRemoveItem<VacancyLanguage>(index, 'vacancyLanguages', languages)}
                    type="u_plus"
                  />
                ) : (
                  <div />
                )}
                {languages?.length < MaxLanguagesCount && !isRequests && index === languages.length - 1 ? (
                  <Button
                    onClick={handleAddItem('vacancyLanguages', languages, { language: '', languageLevel: '' })}
                    variant="outlined"
                    disabled={isEdit && published}
                    startIcon={<Styled.Icon type="u_plus" size={14} />}
                  >
                    {t('buttons.more')}
                  </Button>
                ) : (
                  <div />
                )}
              </Styled.ToolsContainer>
            </Styled.RowContainer>
          ))}
        <Styled.RowContainer>
          <Styled.DoubleContainer>
            <Styled.TechnologyContainer>
              <Controller
                name="vacancyTechnologies"
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    multiple
                    limitTags={1}
                    options={technologyOptions}
                    disabled={isRequests || (isEdit && published)}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <Styled.FullInput
                        {...params}
                        label={t('pages.vacancies.creationModal.technology')}
                        placeholder={t('pages.vacancies.creationModal.selectTechnology')}
                        error={!!errors?.['vacancyTechnologies']?.message}
                        fullWidth
                        helperText={errors?.['vacancyTechnologies']?.message.toString()}
                      />
                    )}
                  />
                )}
              />
            </Styled.TechnologyContainer>
            <Styled.DomainContainer>
              <Controller
                name="vacancyDomains"
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    multiple
                    limitTags={1}
                    options={domainOptions}
                    disabled={isRequests || (isEdit && published)}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <Styled.FullInput
                        {...params}
                        label={t('pages.vacancies.creationModal.domain')}
                        placeholder={t('pages.vacancies.creationModal.selectDomain')}
                        error={!!errors?.['vacancyDomains']?.message}
                        fullWidth
                        helperText={errors?.['vacancyDomains']?.message.toString()}
                      />
                    )}
                  />
                )}
              />
            </Styled.DomainContainer>
          </Styled.DoubleContainer>
          <Styled.LocationContainer />
        </Styled.RowContainer>
        <Styled.RowContainer>
          <Styled.DoubleContainer>
            <Styled.LocationContainer>
              <Controller
                name="countries"
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={locationCountryOptions}
                    multiple
                    limitTags={1}
                    disabled={isRequests || (isEdit && published)}
                    disableCloseOnSelect
                    disableSortByAlphabet={false}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={t('pages.vacancies.creationModal.location')}
                          placeholder={t('pages.vacancies.creationModal.selectLocation')}
                          error={!!errors?.['countries']?.message}
                          helperText={errors?.['countries']?.message.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
            </Styled.LocationContainer>
            <Styled.LocationContainer>
              <Controller
                name="cities"
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={locationCityOptions}
                    limitTags={1}
                    multiple
                    disabled={isRequests || !countries?.length || (isEdit && published)}
                    disableSortByAlphabet={false}
                    disableCloseOnSelect
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={t('pages.vacancies.creationModal.city')}
                          placeholder={t('pages.vacancies.creationModal.selectTheCity')}
                          error={!!errors?.['cities']?.message}
                          helperText={errors?.['cities']?.message.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
            </Styled.LocationContainer>
          </Styled.DoubleContainer>
          <Styled.LocationContainer>
            <Controller
              name="structuralUnit"
              control={control}
              render={({ field: { onChange, value }, formState: { errors } }) => {
                return (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={unitsOptions}
                    disabled={isRequests || (isEdit && published)}
                    loading={isUnitsLoading}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={t('pages.profile.profileDetails.department')}
                          placeholder={t('pages.candidates.offer.orgUnitPlaceholder')}
                          error={!!errors?.structuralUnitId?.message}
                          helperText={errors?.structuralUnitId?.message.toString()}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Styled.LocationContainer>
        </Styled.RowContainer>
      </Styled.FieldsContainer>
      <Styled.CommentContainer>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Styled.FullInput
              value={value}
              onChange={onChange}
              label={t('pages.vacancies.creationModal.specialRequirements')}
              error={!!errors?.['comment']?.message}
              helperText={errors?.['comment']?.message.toString()}
              placeholder={t('pages.vacancies.creationModal.specialRequirementsPlaceholder')}
              rows={7}
              multiline
              disabled={isRequests || (isEdit && published)}
            />
          )}
        />
      </Styled.CommentContainer>
    </Styled.BodyContainer>
  );
};

export default DesktopView;
