import { useCallback, useState } from 'react';
import { useAppDispatch } from './redux';
import { getCountryByPhoneThunk } from '../store';

const usePhoneAPI = () => {
  const dispatch = useAppDispatch();
  const [isPlus, setIsPlus] = useState<boolean>(false);

  const fetchCountryByPhone = useCallback(
    (phone: string) => {
      return dispatch(getCountryByPhoneThunk({ phone }));
    },
    [dispatch],
  );

  const focusHandler = useCallback(() => {
    setIsPlus(true);
  }, []);

  const blurHandler = useCallback(() => {
    setIsPlus(false);
  }, []);

  return { fetchCountryByPhone, focusHandler, blurHandler, isPlus };
};

export default usePhoneAPI;
