import { createReducer } from '@reduxjs/toolkit';
import { preloadedProfileState } from './profile.preloaded-state';
import {
  getProfileThunk,
  updateProfileThunk,
  createAvatarPhotoThunk,
  getProfileCurrentEventsCountThunk,
} from './profile.thunk';
import { clearProfileDataAction, clearProfileAvatarAction, loadProfileAction } from './profile.actions';
import { logoutAction } from '../common';
import { updateSourcingEmployeeThunk } from '../administration/administration.thunk';

export const profileReducer = createReducer(preloadedProfileState, (builder) =>
  builder
    .addCase(updateSourcingEmployeeThunk.fulfilled, (state, { payload }) => {
      if (payload.employeeId.toString() === state.item.id.toString()) {
        state.item = { ...state.item, requiredNumberOfEvents: Number(payload.requiredNumberOfEvents) };
      }
    })
    .addCase(getProfileCurrentEventsCountThunk.fulfilled, (state, { payload }) => {
      state.currentStatusesCount = payload;
    })
    .addCase(createAvatarPhotoThunk.fulfilled, (state, { payload }) => {
      state.avatarFile = payload;
    })
    .addCase(createAvatarPhotoThunk.rejected, (state) => {
      state.avatarFile = null;
    })
    .addCase(clearProfileAvatarAction, (state) => {
      state.avatarFile = null;
    })
    .addCase(getProfileThunk.fulfilled, (state, { payload }) => {
      state.item = payload;
      state.isLoading = false;
    })
    .addCase(getProfileThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getProfileThunk.rejected, (state) => {
      state.isLoading = false;
      state.item = null;
    })
    .addCase(loadProfileAction, (state) => {
      state.isLoading = false;
    })
    .addCase(updateProfileThunk.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.item = payload;
    })
    .addCase(updateProfileThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(updateProfileThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(clearProfileDataAction, (state) => {
      state.item = null;
    })
    .addCase(logoutAction, () => preloadedProfileState),
);
