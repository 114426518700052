import { styled } from '@innowise-group/mui-kit';

export const CheckboxWrapper = styled.div`
  margin-left: auto;
`;

export const CheckboxOptionItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 15px;
  padding: 9px 16px;
  ${({ theme }) => theme.fonts.primary[20]};
  background-color: transparent !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;

export const OptionItem = styled.li<{ selected: boolean }>`
  ${({ theme, selected }) => (selected ? theme.fonts.primary[30] : theme.fonts.primary[20])};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  padding: 9px 16px;
  background-color: ${({ theme, selected }) => selected && theme.palette.state.error.background} !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;
