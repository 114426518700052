import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localizedNameObject } from '@innowise-group/utilities';

export const getNotificationsState = createSelector(
  (state: RootState) => state.notifications,
  (state) => {
    const items = state.ids.reduce((accum, curr) => {
      accum[curr] = {
        ...state.items[curr],
        dedicatedTo: { ...state.items[curr].dedicatedTo, ...localizedNameObject(state.items[curr].dedicatedTo) },
      };
      return accum;
    }, {});
    return { ...state, items };
  },
);

export const getNotificationsIds = createSelector(getNotificationsState, (state) =>
  state.ids.reduce(
    (acc, id) => ({
      ...acc,
      ...(state.items[id].pinned
        ? { pinnedNotifications: [...acc.pinnedNotifications, id] }
        : { unpinnedNotifications: [...acc.unpinnedNotifications, id] }),
    }),
    { pinnedNotifications: [], unpinnedNotifications: [] },
  ),
);
