import React, { useState } from 'react';
import * as Styled from './reminder-section.styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { DateFormats } from '@constants';
import { format } from 'date-fns';
import { DatePicker } from '@shared-mui-components';

const ReminderSection: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [startDate, setStartDate] = useState<Date>(new Date());

  const resetDate = (onChange: (value: Date) => void) => () => {
    onChange(null);
  };

  const filterPassedTime = (time: Date) => {
    return startDate.getTime() < time.getTime();
  };

  const renderCustomInput =
    (value: Date, errors: FieldErrors<FieldValues>, resetDate: () => void) =>
    (handleClick: (event: React.MouseEvent<HTMLInputElement>) => void) => {
      const handleResetDate = (event: React.MouseEvent<HTMLInputElement>) => {
        resetDate();
        event.stopPropagation();
      };

      const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
        setStartDate(new Date());
        handleClick(event);
      };
      return (
        <Styled.RequiredBlock required={!value && !!errors?.reminderDate?.message} onClick={handleOpen}>
          {!!value && (
            <React.Fragment>
              <Styled.ResetIcon type="u_multiply" onClick={handleResetDate} size={18} />
              <span>{format(new Date(value), DateFormats.DaySlashFirst)}</span>
              <span>{format(new Date(value), DateFormats.OnlyTime)}</span>
            </React.Fragment>
          )}
          {value ? (
            <Styled.CalendarIcon type="u_bell" size={18} />
          ) : (
            <Styled.CustomButton variant="text">
              <Styled.CalendarIcon type="u_bell" size={18} />
              {t('buttons.remind')}
            </Styled.CustomButton>
          )}
        </Styled.RequiredBlock>
      );
    };

  return (
    <Controller
      name="reminderDate"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <Styled.DateValueContainer>
            <DatePicker
              withTime
              value={value}
              selected={value}
              onChange={onChange}
              isConstrained={false}
              startDate={startDate}
              minYear={startDate.getFullYear()}
              filterTime={filterPassedTime}
              renderCustomInput={renderCustomInput(value, errors, resetDate(onChange))}
            />
          </Styled.DateValueContainer>
        );
      }}
    />
  );
};

export default ReminderSection;
