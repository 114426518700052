import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from '@innowise-group/core';
import { ChangeEventHandler } from 'react';
import { SelectOption } from '@innowise-group/core';

export type SearchOptionsHandler = ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;

type ReturnHook = {
  selectedOptionsLoaded?: boolean;
  selectOptions: SelectOption[];
  searchOptions: SearchOptionsHandler;
};

export type UseStaticSelectOptions = (args: {
  getInitialOptions: () => Promise<SelectOption[]>;
}) => ReturnHook & { onClose: () => void };

export type UseSelectOptions = (args: {
  selectedOptions?: string[];
  getOptionsById: (str: string) => Promise<SelectOption>;
  getOptionsByName: (str: string) => Promise<SelectOption[]>;
  initialOptions?: SelectOption[];
}) => ReturnHook;

const useSelectOptionsApi: UseSelectOptions = ({
  getOptionsById,
  getOptionsByName,
  selectedOptions,
  initialOptions = [],
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedValue] = useDebounce<string>(searchValue, 500);
  const [{ ids, options }, setOptions] = useState<{ ids: string[]; options: { [key: string]: SelectOption } }>(
    initialOptions.reduce(
      (acc, val) => {
        return { ...acc, ids: [...acc.ids, val.value], options: { ...acc.options, [val.value]: val } };
      },
      { ids: [], options: {} },
    ),
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    getOptions(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (selectedOptions?.length) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        getOptionsByIds(selectedOptions);
      }
    }
  }, [selectedOptions]);

  const createOptionsCollection = (options: SelectOption[]) =>
    options.reduce(
      (acc, val) => ({
        ...acc,
        [val.value]: val,
      }),
      {},
    );

  const createIdsSetFromOptions = (options: SelectOption[]) => options.map(({ value }) => value);

  const updateOptions = (options: SelectOption[]) => {
    return setOptions((prev) => ({
      ...prev,
      ids: [...new Set([...prev.ids, ...createIdsSetFromOptions(options)])],
      options: {
        ...prev.options,
        ...createOptionsCollection(options),
      },
    }));
  };

  const getOptions = async (str: string) => {
    const data = await getOptionsByName(str);
    return updateOptions(data);
  };

  const getOptionsByIds = async (ids: string[]) => {
    const actualIds = ids.filter((id) => id !== 'null');
    const optionsResponse = await Promise.all(actualIds.map(getOptionsById));
    const options = optionsResponse.map((el) => el);
    return updateOptions(options);
  };

  const searchOptions = useCallback<SearchOptionsHandler>(
    (event) => {
      const { value } = event.currentTarget;
      return setSearchValue(value);
    },
    [setSearchValue],
  );

  const selectOptions = useMemo(() => ids.map((id) => options[id]), [ids, options]);

  const selectedOptionsLoaded = useMemo(() => {
    if (selectedOptions && !selectedOptions?.length) return true;
    const res = selectedOptions?.length && selectedOptions.every((id) => ids.includes(id));
    return res;
  }, [ids, selectedOptions?.length]);

  return {
    selectedOptionsLoaded,
    selectOptions,
    searchOptions,
  };
};

export default useSelectOptionsApi;
