import ReactGA from 'react-ga4';

export default class GoogleAnalyticsService {
  private static configUrl = '/config/config.json';
  private static analyticProvider = ReactGA;

  static async init(): Promise<ReturnType<typeof this.analyticProvider.initialize>> {
    const response = await fetch(this.configUrl);
    const { GOOGLE_TRACKING_ID } = await response.json();
    const measurementId = GOOGLE_TRACKING_ID;
    if (!measurementId) throw new Error('You must provide GOOGLE_TRACKING_ID variable in config.json');
    return this.analyticProvider.initialize(measurementId);
  }

  static send(...args: Parameters<typeof this.analyticProvider.send>): ReturnType<typeof this.analyticProvider.send> {
    return this.analyticProvider.send(...args);
  }

  static event(
    ...args: Parameters<typeof this.analyticProvider.event>
  ): ReturnType<typeof this.analyticProvider.event> {
    return this.analyticProvider.event(...args);
  }
}
