import { AppRoutes, ManagerRolesArray } from '@constants';
import { useProfileAPI } from '@innowise-group/core';

interface Tab {
  title: string;
  value: AppRoutes;
}

const tabsUnion: Tab[] = [
  {
    title: 'pages.candidates.pageName',
    value: AppRoutes.SearchCandidates,
  },
  {
    title: 'pages.vacancies.pageName',
    value: AppRoutes.SearchVacancies,
  },
  {
    title: 'pages.requests.pageName',
    value: AppRoutes.SearchRequests,
  },
];

export const useSearchRoutes = (): Tab[] => {
  const { profile } = useProfileAPI();

  const privateTabsUnion = tabsUnion.reduce((acc, val) => {
    if (val.value === AppRoutes.SearchCandidates && ManagerRolesArray.includes(profile.role.name)) {
      return acc;
    }
    return [...acc, val];
  }, []);

  return privateTabsUnion;
};
