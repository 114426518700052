export enum StatusStepsEnum {
  Vacancy = 0,
  Requirements = 1,
  Comments = 2,
}

export const steps = [
  { title: 'pages.candidates.candidateDetails.vacancy', value: StatusStepsEnum.Vacancy },
  { title: 'pages.candidates.statuses.requirements', value: StatusStepsEnum.Requirements },
  { title: 'pages.candidates.comments.comments', value: StatusStepsEnum.Comments },
];

export const VacancyFields = [
  'file',
  'link',
  'statusId',
  'subStatusId',
  'vacancyId',
  'vacancyRequestId',
  'periodFrom',
  'periodTo',
];
export const RequirementsFields = [
  'eventDate',
  'reminderDate',
  'claims',
  'agreements',
  'hrmProfile',
  'hrmChecked',
  'offerChecked',
];
