import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { MappedEntity, SearchEntities } from './search.types';
import { getLanguageFromLS } from '@innowise-group/utilities';

export const getSearchState = createSelector(
  (state: RootState) => state.search,
  (state) => state,
);

export const getHistoryState = createSelector(
  (state: RootState) => state.search,
  (state) => {
    const ln = getLanguageFromLS();
    return {
      historyEntities: state.searchHistory.entities.map<MappedEntity>((el) => {
        if (el.entity === SearchEntities.Candidates) {
          return {
            ...el,
            title: el.title[ln],
          };
        }
        return el;
      }),
      historyQueries: state.searchHistory.queries,
      historyXrayQueries: state.searchHistory.xrayQuery,
    };
  },
);
