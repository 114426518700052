import { CurrencyEnum, LifecycleStatuses, RolesValues, SortingValue } from '@constants';
import { CandidateOptions } from '@innowise-group/core';

export enum FiltersTypes {
  Text = 'TEXT',
  DateRange = 'DATE_RANGE',
  DateSegment = 'DATE_SEGMENT',
  NumberRange = 'NUMBER_RANGE',
  Boolean = 'BOOLEAN',
  Select = 'SELECT',
  NestedSelect = 'NESTED_SELECT',
  Money = 'Money',
  Employee = 'EMPLOYE',
  ActivityPlace = 'ACTIVITY_PLACE',
  Vacancy = 'VACANCY',
  UnpinnedSearch = 'UNPINNED_SEARCH',
}

export enum DateSegments {
  Until = 'UNTIL',
  Exact = 'EXACT',
  Since = 'SINCE',
}

export interface BaseFilterType {
  active?: boolean;
  id: string;
  pinned?: boolean;
  isOpen: boolean;
  labelKey?: string;
  availableForRoles?: Array<RolesValues>;
}

export interface BooleanFilterType extends BaseFilterType {
  type: FiltersTypes.Boolean;
  state: { checked: boolean };
}

export interface ActivityPlaceFilterType extends BaseFilterType {
  type: FiltersTypes.ActivityPlace;
  filterProps: {
    activityPlaceKey: string;
    activityPositionKey: string;
    fromKey: string;
    toKey: string;
  };
  state: { place: string; profession: string; from: null | Date; to: null | Date };
}

export interface SelectFilterType extends BaseFilterType {
  type: FiltersTypes.Select;
  filterProps: { search: boolean; singleSelect?: boolean; commentIds?: string[] };
  state: {
    [key: string]: {
      parentId: string;
      title: string;
      value;
      active?: boolean;
      comment?: string;
    };
  };
}

export interface TextSearchFilterType {
  labelKey?: string;
  active: boolean;
  pinned: boolean;
  id: string;
  type: FiltersTypes.Text;
  state: { value: string };
}

export interface UnpinnedSearchFilterType extends Omit<TextSearchFilterType, 'type'> {
  type: FiltersTypes.UnpinnedSearch;
  filterProps: {
    placeholder: string;
  };
}

export interface NestedSelectFilterType extends BaseFilterType {
  type: FiltersTypes.NestedSelect;
  filterProps: { search?: boolean };
  state: {
    [key: string]: {
      title: string;
      value: string;
      active: boolean;
      nested: { [key: string]: { title: string; value: string; active: boolean } };
    };
  };
}

export interface NumberRangeFilterType extends BaseFilterType {
  type: FiltersTypes.NumberRange;
  state: {
    initialDataLoaded: boolean;
    from: number;
    to: number;
    step: number;
    minValue: number;
    maxValue: number;
  };
}

export interface MoneyFilterType extends BaseFilterType {
  type: FiltersTypes.Money;
  state: {
    from: number;
    to: number;
    step: number;
    variants: {
      [key in CurrencyEnum]: {
        min: number;
        max: number;
        initialDataLoaded: boolean;
      };
    };
    currency: CurrencyEnum;
  };
}

export interface DateRangeFilterType extends BaseFilterType {
  type: FiltersTypes.DateRange;
  state: {
    from: null | string;
    to: null | string;
    min: null | string;
    max: null | string;
  };
}

export interface DateSegmentFilterType extends BaseFilterType {
  type: FiltersTypes.DateSegment;
  state: {
    date: null | string;
    segments: DateSegments[];
    currentSegment: DateSegments;
  };
}

export interface EmployeeFilterType extends BaseFilterType {
  type: FiltersTypes.Employee;
  state: number[];
  onlyActual?: boolean;
}

export interface VacancyApiSearchFilterType extends BaseFilterType {
  type: FiltersTypes.Vacancy;
  state: number[];
}

export type FiltersUnion =
  | BooleanFilterType
  | ActivityPlaceFilterType
  | SelectFilterType
  | NestedSelectFilterType
  | NumberRangeFilterType
  | MoneyFilterType
  | DateRangeFilterType
  | DateSegmentFilterType
  | EmployeeFilterType
  | VacancyApiSearchFilterType;

export enum Tabs {
  All = 'ALL',
  My = 'MY',
  Archive = 'ARCHIVE',
}

export interface FiltersState {
  candidateInitialOptions: CandidateFilterOptions;
  lifecycleStatus: LifecycleStatuses;
  responsible: null | number;
  sort: {
    candidate: SortingValue;
    request: SortingValue;
    vacancy: SortingValue;
  };
  selected: number[];
  candidate: {
    name: TextSearchFilterType;
    gender: SelectFilterType;
    birthDate: DateRangeFilterType;
    candidateStatusDate: DateRangeFilterType;
    age: NumberRangeFilterType;
    salary: MoneyFilterType;
    overallJobExperience: NumberRangeFilterType;
    itSphereJobExperience: NumberRangeFilterType;
    citizenship: SelectFilterType;
    countries: SelectFilterType;
    cities: SelectFilterType;
    relocationCountries: SelectFilterType;
    visaTypes: SelectFilterType;
    statuses: NestedSelectFilterType;
    sourceFilter: SelectFilterType;
    professionFilter: NestedSelectFilterType;
    languageFilter: NestedSelectFilterType;
    technologies: SelectFilterType;
    domains: SelectFilterType;
    workFormats: SelectFilterType;
    isBlocked: BooleanFilterType;
    readyForBusinessTrip: BooleanFilterType;
    visaAvailability: BooleanFilterType;
    relocationAvailability: BooleanFilterType;
    experienceFilter: ActivityPlaceFilterType;
    courseFilter: ActivityPlaceFilterType;
    educationFilter: ActivityPlaceFilterType;
    employe: EmployeeFilterType;
  };
  request: {
    countries: SelectFilterType;
    officeIds: SelectFilterType;
    vacancyIds: VacancyApiSearchFilterType;
    workFormats: SelectFilterType;
    employmentForms: SelectFilterType;
    workload: SelectFilterType;
    hiringDeadline: DateSegmentFilterType;
    statuses: SelectFilterType;
    employe: EmployeeFilterType;
    hiringPurpose: SelectFilterType;
    structuralUnitId: SelectFilterType;
    searchBarValue: UnpinnedSearchFilterType;
  };
  vacancy: {
    searchBarRequest: TextSearchFilterType;
    professionFilter: NestedSelectFilterType;
    statusIds: SelectFilterType;
    technologyIds: SelectFilterType;
    domainIds: SelectFilterType;
    languageFilter: NestedSelectFilterType;
    countries: SelectFilterType;
    employe: EmployeeFilterType;
    published: BooleanFilterType;
  };
  isLoading: boolean;
  candidateOptions: CandidateFilterOptions | null;
  pagable: {
    pageSizes: { title: string; value: string }[];
    pageSize: number;
    currentPage: number;
  };
  currentTab: Tabs;
}

export type FiltersEntity = Pick<FiltersState, 'candidate' | 'request' | 'vacancy'>;

export interface CandidateFilterOptions extends Omit<CandidateOptions, 'statusOptions'> {
  statusOptions: {
    [key: string]: {
      title: string;
      value: string;
      active: boolean;
      nested: { [key: string]: { title: string; value: string; active: boolean } };
    };
  };
}
