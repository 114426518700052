// TODO: move to backend

import { OpenVacanciesStatusId } from './values-ids.constants';

export const VacancyStatusesIcons = {
  [OpenVacanciesStatusId]: 'u_vacancy_status_opened',
  'd1f3000f-93ce-4cbd-bbb9-39bb82e72d4c': 'u_vacancy_status_closed',
  '05cdb8b2-73ee-4678-b27e-e91f8c820e7d': 'u_vacancy_status_paused',
  '8974b1b6-5cae-4c5e-bfeb-060609654e9b': 'u_vacancy_status_draft',
};
