import { LogoSize } from '@constants';
import { useTheme } from '../theme';
import useResizeObserver from '../../core/hooks/use-resize-observer.hook';

const Logo: React.FC<React.HTMLAttributes<HTMLOrSVGElement> & { logoColor?: string; size?: 'large' | 'default' }> = ({
  logoColor,
  size = 'default',
  ...props
}) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.md);
  const color = theme.palette.general.darkGrey[100];

  return isDesktopView || size === 'large' ? (
    <svg
      width={LogoSize[size].width}
      height={LogoSize[size].height}
      viewBox="0 0 292 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.876953 10.999H9.77551V41.7434H0.876953V10.999Z" fill={logoColor || color} />
      <path
        d="M49.4245 24.1439V41.7432H40.526V25.5144C40.526 20.5436 38.2457 18.2594 34.3095 18.2594C30.0312 18.2594 26.9528 20.8862 26.9528 26.5423V41.7432H18.0488V10.9988H26.5456V14.5992C28.9454 11.9723 32.5341 10.542 36.7038 10.542C43.9464 10.542 49.4245 14.7732 49.4245 24.1439Z"
        fill={logoColor || color}
      />
      <path
        d="M89.1413 24.1439V41.7432H80.2428V25.5144C80.2428 20.5436 77.9625 18.2594 74.0263 18.2594C69.748 18.2594 66.6642 20.8862 66.6642 26.5423V41.7432H57.7656V10.9988H66.2678V14.5992C68.6621 11.9723 72.2563 10.542 76.4206 10.542C83.6632 10.542 89.1413 14.7732 89.1413 24.1439Z"
        fill={logoColor || color}
      />
      <path
        d="M97.334 26.3737C97.334 17.1172 104.468 10.5474 114.219 10.5474C123.97 10.5474 131.05 17.1172 131.05 26.3737C131.05 35.6302 123.975 42.2 114.219 42.2C104.468 42.2 97.334 35.6302 97.334 26.3737ZM122.037 26.3737C122.037 21.0602 118.671 17.8568 114.224 17.8568C109.772 17.8568 106.352 21.0547 106.352 26.3737C106.352 31.6872 109.772 34.8905 114.224 34.8905C118.671 34.8851 122.037 31.6872 122.037 26.3737Z"
        fill={logoColor || color}
      />
      <path
        d="M188.653 10.999L177.529 41.7434H168.972L162.066 22.6539L154.938 41.7434H146.381L135.316 10.999H143.699L150.947 31.8017L158.478 10.999H166.008L173.31 31.8017L180.781 10.999H188.653Z"
        fill={logoColor || color}
      />
      <path d="M195.451 10.999H204.35V41.7434H195.451V10.999Z" fill={logoColor || color} />
      <path
        d="M211.68 38.888L214.644 32.4867C217.38 34.2597 221.55 35.4562 225.313 35.4562C229.422 35.4562 230.959 34.3685 230.959 32.6553C230.959 27.6246 212.418 32.7695 212.418 20.4837C212.418 14.6536 217.668 10.542 226.621 10.542C230.845 10.542 235.52 11.5101 238.43 13.2232L235.465 19.5646C232.441 17.8515 229.417 17.2804 226.621 17.2804C222.625 17.2804 220.915 18.5367 220.915 20.1357C220.915 25.3894 239.456 20.3043 239.456 32.4215C239.456 38.1374 234.151 42.1946 224.965 42.1946C219.78 42.2001 214.53 40.7751 211.68 38.888Z"
        fill={logoColor || color}
      />
      <path
        d="M277.449 28.8862H254.228C255.086 32.715 258.338 35.0591 263.012 35.0591C266.264 35.0591 268.604 34.091 270.711 32.0896L275.445 37.2345C272.595 40.4922 268.317 42.2054 262.784 42.2054C252.176 42.2054 245.27 35.5159 245.27 26.3791C245.27 17.1769 252.29 10.5527 261.644 10.5527C270.657 10.5527 277.617 16.6113 277.617 26.4933C277.622 27.1731 277.508 28.1466 277.449 28.8862ZM254.119 23.6869H269.24C268.61 19.7984 265.705 17.2857 261.709 17.2857C257.654 17.2857 254.744 19.744 254.119 23.6869Z"
        fill={logoColor || color}
      />
      <path
        d="M291.765 5.82672C291.765 8.63304 289.495 10.9064 286.694 10.9064C283.892 10.9064 281.623 8.63304 281.623 5.82672C281.623 3.0204 283.892 0.74707 286.694 0.74707C289.495 0.74707 291.765 3.0204 291.765 5.82672Z"
        fill={logoColor || '#C63031'}
      />
    </svg>
  ) : (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21_30616)">
        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="#F3F3F3" stroke="#DBDBDB" />
        <g filter="url(#filter0_i_21_30616)">
          <rect
            x="21.3152"
            y="8.25"
            width="28.7209"
            height="8"
            rx="2"
            transform="rotate(90 21.3152 8.25)"
            fill="#191B1D"
          />
        </g>
        <rect
          x="20.8152"
          y="8.75"
          width="27.7209"
          height="7"
          rx="1.5"
          transform="rotate(90 20.8152 8.75)"
          stroke="black"
        />
        <path
          d="M24.6862 14.7344C24.6862 17.2197 26.7009 19.2344 29.1862 19.2344C31.6714 19.2344 33.6862 17.2197 33.6862 14.7344C33.6862 12.2491 31.6714 10.2344 29.1862 10.2344C26.7009 10.2344 24.6862 12.2491 24.6862 14.7344Z"
          fill="#D13B3B"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_21_30616"
          x="13.3152"
          y="1.25"
          width="12"
          height="35.7207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="-17" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_21_30616" />
        </filter>
        <clipPath id="clip0_21_30616">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
