import { Loader } from '../loader';
import * as Styled from './loading-frame.styles';
import { HTMLAttributes } from 'react';

const LoadingFrame: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Styled.Wrapper {...props}>
      <Loader />
    </Styled.Wrapper>
  );
};

export default LoadingFrame;
