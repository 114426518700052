import { HTMLAttributes, memo, useEffect, useState } from 'react';
import * as Styled from './text.styles';
import { useTranslation } from 'react-i18next';
import { BaseFilterType, useDebounce } from '@innowise-group/core';
import { InputAdornment, StandardTextFieldProps } from '@mui/material';

export interface TextProps
  extends Pick<BaseFilterType, 'labelKey' | 'isOpen'>,
    HTMLAttributes<HTMLInputElement>,
    Pick<StandardTextFieldProps, 'InputProps'> {
  state: { value: string };
  onValueChange: (args: { value: string }) => void;
}

const Text: React.FC<TextProps> = ({ onValueChange, labelKey, InputProps, className, ...props }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(props.state.value);
  const [debouncedValue] = useDebounce<string>(value, 500);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    onValueChange({ value: debouncedValue });
  }, [debouncedValue]);

  useEffect(() => {
    if (!props.state.value) setValue(props.state.value);
  }, [props.state.value]);
  return (
    <Styled.CustomInput
      value={value}
      className={className}
      onChange={onInputChangeHandler}
      placeholder={props.placeholder || t(labelKey)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Styled.Icon type="u_search" />
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
};

export default memo(Text);
