import { createAsyncThunk } from '@reduxjs/toolkit';
import { FaqService, MyKnownError } from '@innowise-group/core';

export const getFaqThunk = createAsyncThunk<
  Awaited<ReturnType<typeof FaqService.getFaq>>['data'],
  void,
  { rejectValue: MyKnownError }
>('faq/getFaq', async (_data, { rejectWithValue }) => {
  try {
    const response = await FaqService.getFaq();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateFaqThunk = createAsyncThunk<
  Awaited<ReturnType<typeof FaqService.updateFaq>>['data'],
  Parameters<typeof FaqService.updateFaq>[0],
  { rejectValue: MyKnownError }
>('faq/updateFaq', async (data, { rejectWithValue }) => {
  try {
    const response = await FaqService.updateFaq(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
