import { PropsWithChildren, useState } from 'react';
import * as Styled from './avatar-item.styles';

interface AvatarItemProps extends PropsWithChildren {
  name?: string;
  photoId?: string;
  isPointer?: boolean;
  onClick?: () => void;
  isOption?: boolean;
  lastElementRef?: (node: Element) => void;
  className?: string;
}

const AvatarItem: React.FC<AvatarItemProps> = ({
  lastElementRef,
  name,
  photoId,
  isPointer,
  isOption,
  children,
  onClick,
  ...props
}) => {
  const [isValidUrl, setIsValidUrl] = useState<boolean>(true);

  const handleErrorImage = () => {
    setIsValidUrl(false);
  };

  return (
    <Styled.Container {...props} isPointer={isPointer} onClick={onClick} ref={lastElementRef}>
      {photoId && isValidUrl ? (
        <Styled.Avatar id={Number(photoId)} onError={handleErrorImage} $isOption={isOption} />
      ) : (
        <Styled.NoImageContainer isOption={isOption}>
          <Styled.NoImageIcon type="u_camera" size={20} viewBox="0 0 49 44" />
        </Styled.NoImageContainer>
      )}
      {children ? children : name && <Styled.AvatarName>{name}</Styled.AvatarName>}
      {isPointer && <Styled.ArrowIcon type="u_angle-right-b" />}
    </Styled.Container>
  );
};

export default AvatarItem;
