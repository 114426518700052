import { RolesValues, SortingValue } from '@constants';
import { Tabs } from '@innowise-group/core';
import React, { useState } from 'react';
import * as Styled from './mobile-view.styles';
import { RoleGuard } from '@shared-components';
import { Menu, MenuItem } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';

interface MobileViewProps {
  currentTab: Tabs;
  sort: string;
  tabs: { id: Tabs; name: string }[];
  sortItems: { text: string; value: string }[];
  isRecruiterMode?: boolean;
  myTabRoles: RolesValues[];
  openSortMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeSortMenu: () => void;
  anchorEl: HTMLElement | null;
  selected: number[];
  handleChangeTab: (tabId: Tabs) => () => void;
  setSortParamHandle: (sortItem: string) => (event: React.MouseEvent) => void;
  ExportButtonComponent: React.FC;
}

const MobileView: React.FC<MobileViewProps> = ({
  anchorEl,
  sortItems,
  selected,
  sort,
  tabs,
  currentTab,
  myTabRoles,
  isRecruiterMode,
  ExportButtonComponent,
  closeSortMenu,
  openSortMenu,
  handleChangeTab,
  setSortParamHandle,
}) => {
  const { t } = useTranslation();
  const [tabsAnchor, setTabsAnchor] = useState<null | HTMLElement>();

  const openTabsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setTabsAnchor(event.currentTarget);
  };

  const closeTabsMenu = () => {
    setTabsAnchor(null);
  };

  const onChangeTab = (tabId: Tabs) => () => {
    handleChangeTab(tabId)();
    closeTabsMenu();
  };

  const currentTabName = tabs.find((item) => item.id === currentTab)?.name || '';

  return (
    <Styled.TabsContainer>
      <Styled.CurrentTab onClick={openTabsMenu} variant="text">
        <span>{t(currentTabName) || ''}</span>
        <Styled.SelectIcon type="u_angle-down" isOpen={!!tabsAnchor} />
      </Styled.CurrentTab>
      <Menu anchorEl={tabsAnchor} open={!!tabsAnchor} onClose={closeTabsMenu}>
        {tabs.map((item) => {
          if (item.id === Tabs.My) {
            return (
              <RoleGuard roles={myTabRoles} key={item.id}>
                <MenuItem
                  selected={item.id === currentTab}
                  {...(currentTab !== item.id && { onClick: onChangeTab(item.id) })}
                >
                  {t(item.name) || ''}
                </MenuItem>
              </RoleGuard>
            );
          }
          if (item.id === Tabs.All) {
            return (
              <MenuItem
                key={item.id}
                selected={item.id === currentTab}
                {...(currentTab !== item.id && { onClick: onChangeTab(item.id) })}
              >
                {t(item.name) || ''}
              </MenuItem>
            );
          }
          if (!isRecruiterMode) {
            return (
              <RoleGuard roles={[RolesValues.Admin]} key={item.id}>
                <MenuItem
                  selected={item.id === currentTab}
                  {...(currentTab !== item.id && { onClick: onChangeTab(item.id) })}
                >
                  {t(item.name) || ''}
                </MenuItem>
              </RoleGuard>
            );
          }
        })}
      </Menu>
      <Styled.TableFilters>
        {!isRecruiterMode && <ExportButtonComponent />}
        <Styled.GrayButton onClick={openSortMenu} variant="text">
          <Styled.FilterIcon type="u_filter" />
        </Styled.GrayButton>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeSortMenu}>
          {sortItems.map((item, index) => (
            <MenuItem
              key={index}
              selected={sort === item.value}
              onClick={!selected.length && item.value === SortingValue.Checked ? null : setSortParamHandle(item.value)}
              disabled={!selected.length && item.value === SortingValue.Checked}
            >
              {t(item.text)}
            </MenuItem>
          ))}
        </Menu>
      </Styled.TableFilters>
    </Styled.TabsContainer>
  );
};

export default MobileView;
