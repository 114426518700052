import React from 'react';
import * as Styled from './number-input.styles';
import { TextFieldProps } from '@mui/material';

interface NumberInputProps extends Omit<TextFieldProps, 'type'> {
  min?: number;
  max?: number;
  showArrows?: boolean;
  onStep?: (value: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  onChange,
  onStep,
  variant = 'outlined',
  value,
  min,
  max,
  showArrows = true,
  ...props
}) => {
  const handleNumberUpChange = () => {
    if (!max || !(Number(value) >= Number(max))) {
      onStep?.(Number(value) + 1);
    }
  };

  const handleNumberDownChange = () => {
    if (!min || !(Number(value) <= Number(min))) {
      onStep?.(Number(value) - 1);
    }
  };

  return (
    <Styled.Container>
      <Styled.CustomInput type="number" {...props} value={value} onChange={onChange} variant={variant} />
      {showArrows && (
        <Styled.ArrowsContainer>
          <Styled.ArrowUp onClick={handleNumberUpChange}>
            <Styled.CustomIcon type="u_angle-left-b" size={17} />
          </Styled.ArrowUp>
          <Styled.ArrowDown onClick={handleNumberDownChange}>
            <Styled.CustomIcon type="u_angle-left-b" size={17} />
          </Styled.ArrowDown>
        </Styled.ArrowsContainer>
      )}
    </Styled.Container>
  );
};

export default React.memo(NumberInput);
