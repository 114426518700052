import { styled, NamedSection as BaseNamedSection } from '@innowise-group/mui-kit';
import { Image } from '@shared-mui-components';

export const SectionContainer = styled.div`
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0.75rem 1rem;
`;

export const NamedSection = styled(BaseNamedSection)`
  padding: 0.75rem;
`;

export const CandidateInfoContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const CandidateInfo = styled.div`
  display: flex;
  align-items: stretch;
  gap: 0.75rem;
`;

export const NoImageContainer = styled.div`
  height: 3.375rem;
  width: 3.375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

export const Avatar = styled(Image)`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  height: 3.375rem;
  width: 3.375rem;
  flex-shrink: 0;
`;

export const FullNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.primary[70]}
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const VacancyContainer = styled(FullNameContainer)`
  ${({ theme }) => theme.fonts.primary[30]};
  justify-content: center;
`;

export const VacancyValue = styled.span`
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StatusRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StatusContainer = styled.div<{ bgColor: string; color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  width: 100%;
  flex: 1;
  min-height: 3.375rem;
  ${({ onClick }) => onClick && { cursor: 'pointer' }}
`;

export const StatusTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.75rem;
  position: relative;
  width: 100%;
  text-align: center;
`;

export const SubStatusContainer = styled.div<{ bgColor: string }>`
  ${({ theme }) => theme.fonts.primary[20]}
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: ${({ bgColor }) => bgColor};
  flex: 1;
  width: 100%;
  text-align: center;
`;

export const ReminderDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.fonts.primary[80]};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  & svg {
    flex-shrink: 0;
  }
`;

export const PeriodContainer = styled.div`
  margin-top: 4px;
  ${({ theme }) => theme.fonts.primary[40]};
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;
