import { LocalizedMessage } from '@innowise-group/core';
import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Trans, useTranslation } from 'react-i18next';
import * as Styled from './another-candidate-updated-modal.styles';

interface AnotherCandidateUpdatedModalProps {
  message: LocalizedMessage;
  onSubmit: () => void;
}

const AnotherCandidateUpdatedModal: React.FC<ModalProps & AnotherCandidateUpdatedModalProps> = ({
  message,
  close,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const onSubmitModal = () => {
    onSubmit();
    close();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('buttons.profileDelete')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>
          <Trans
            i18nKey="notifications.text.anotherUserUpdatedCandidate"
            values={{ fullName: message.employee.localizedFullName }}
            components={{
              profile: <Styled.Author />,
            }}
          />
        </Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'center'}
        actions={[
          {
            title: t('buttons.ok'),
            onClick: onSubmitModal,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(AnotherCandidateUpdatedModal, 'another-candidate-updated');
