import {
  CandidateLanguage,
  CandidateProfession,
  useControlFormField,
  useOptionsApi,
  useValidationSchemaCandidate,
} from '@innowise-group/core';
import { Input, Select } from '@innowise-group/mui-kit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './skill-section.styles';
import { MaxLanguagesCount, MaxProfessionsCount } from '@constants';

interface SkillSectionProps {
  disabled?: boolean;
  isDiff?: boolean;
}

const SkillSection: React.FC<SkillSectionProps> = ({ disabled, isDiff }) => {
  const { t } = useTranslation();
  const { validationSchema } = useValidationSchemaCandidate();
  const { handleAddItem, handleRemoveItem, handleChangeSelectItem } = useControlFormField(validationSchema);
  const { professionOptions, domainOptions, gradeOptions, technologyOptions, languageOptions, languageLevelOptions } =
    useOptionsApi();

  const { watch, control } = useFormContext();
  const languages = watch('candidateLanguages');
  const professions = watch('candidateProfessions');

  return (
    <React.Fragment>
      <Styled.BoxForDiffConsolidation $multiProfessions={!!(professions?.length > 1)}>
        {isDiff && <Styled.CustomProfessionsDiff />}
      </Styled.BoxForDiffConsolidation>
      <Styled.GroupFieldsContainer>
        {professions?.length &&
          professions.map((item: CandidateProfession, index: number) => (
            <React.Fragment key={index}>
              <Controller
                name={`candidateProfessions.${index}.professionId`}
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => {
                  return (
                    <Select
                      disabled={disabled}
                      value={value}
                      onChange={handleChangeSelectItem(onChange, 'candidateProfessions')}
                      options={professionOptions}
                      disableSortByAlphabet={false}
                      renderInput={(params) => {
                        return (
                          <Input
                            {...params}
                            label={`${t('pages.candidates.candidateCreation.specialty')} ${index ? index + 1 : ''}`}
                            placeholder={t('pages.candidates.candidateCreation.selectSpecialty')}
                            required={!index}
                            error={
                              !!(
                                errors?.candidateProfessions?.[index]?.professionId?.message ||
                                errors?.candidateProfessions?.[index]?.message ||
                                errors?.candidateProfessions?.message
                              )
                            }
                            inputRef={ref}
                            helperText={(
                              errors?.candidateProfessions?.[index]?.professionId?.message ||
                              errors?.candidateProfessions?.[index]?.message ||
                              errors?.candidateProfessions?.message
                            )?.toString()}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
              <Styled.DeleteGroupContainer>
                {professions.length > 1 && (
                  <Styled.DeleteButton
                    disabled={disabled}
                    variant="text"
                    startIcon={<Styled.DeleteIcon type="u_plus" />}
                    onClick={handleRemoveItem<CandidateProfession>(index, 'candidateProfessions', professions)}
                  >
                    {t('buttons.delete')}
                  </Styled.DeleteButton>
                )}
                <Controller
                  name={`candidateProfessions.${index}.gradeId`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Select
                      disabled={disabled}
                      value={value}
                      onChange={handleChangeSelectItem(onChange, 'candidateProfessions')}
                      options={gradeOptions}
                      renderInput={(params) => {
                        return (
                          <Input
                            {...params}
                            inputRef={ref}
                            label={`${t('pages.candidates.candidateCreation.grade')} ${index ? index + 1 : ''}`}
                            placeholder={t('pages.candidates.candidateCreation.selectGrade')}
                            error={!!errors?.candidateProfessions?.[index]?.gradeId?.message}
                            helperText={errors?.candidateProfessions?.[index]?.gradeId?.message.toString()}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Styled.DeleteGroupContainer>
            </React.Fragment>
          ))}
        {professions?.length < MaxProfessionsCount && (
          <Styled.AddButton
            disabled={disabled}
            onClick={handleAddItem('candidateProfessions', professions, { professionId: '', gradeId: '' })}
            variant="text"
            startIcon={<Styled.PlusIcon type="u_plus" />}
          >
            {t('pages.candidates.candidateCreation.addProfession')}
          </Styled.AddButton>
        )}
      </Styled.GroupFieldsContainer>
      <Styled.BoxForDiffConsolidation $multiProfessions={!!(languages?.length > 1)}>
        {isDiff && <Styled.CustomLanguagesDiff />}
      </Styled.BoxForDiffConsolidation>
      <Styled.GroupFieldsContainer>
        {languages?.length &&
          languages.map((item: CandidateLanguage, index: number) => (
            <React.Fragment key={index}>
              <Controller
                name={`candidateLanguages.${index}.languageId`}
                control={control}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                  <Select
                    disabled={disabled}
                    value={value}
                    disableSortByAlphabet={false}
                    onChange={handleChangeSelectItem(onChange, 'candidateLanguages')}
                    options={languageOptions}
                    renderInput={(params) => {
                      return (
                        <Input
                          {...params}
                          inputRef={ref}
                          label={`${t('pages.candidates.candidateCreation.language')} ${index ? index + 1 : ''}`}
                          placeholder={t('pages.candidates.candidateCreation.selectLanguage')}
                          error={
                            !!(
                              errors?.candidateLanguages?.[index]?.languageId?.message ||
                              errors?.candidateLanguages?.[index]?.message
                            )
                          }
                          helperText={(
                            errors?.candidateLanguages?.[index]?.languageId?.message ||
                            errors?.candidateLanguages?.[index]?.message
                          )?.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
              <Styled.DeleteGroupContainer>
                {languages.length > 1 && (
                  <Styled.DeleteButton
                    disabled={disabled}
                    variant="text"
                    startIcon={<Styled.DeleteIcon type="u_plus" />}
                    onClick={handleRemoveItem<CandidateLanguage>(index, 'candidateLanguages', languages)}
                  >
                    {t('buttons.delete')}
                  </Styled.DeleteButton>
                )}
                <Controller
                  name={`candidateLanguages.${index}.languageLevelId`}
                  control={control}
                  render={({ field: { onChange, value, ref }, formState: { errors } }) => (
                    <Select
                      disabled={disabled}
                      value={value}
                      onChange={handleChangeSelectItem(onChange, 'candidateLanguages')}
                      options={languageLevelOptions}
                      renderInput={(params) => {
                        return (
                          <Input
                            {...params}
                            inputRef={ref}
                            label={`${t('pages.candidates.candidateCreation.level')} ${index ? index + 1 : ''}`}
                            placeholder={t('pages.candidates.candidateCreation.selectLevel')}
                            error={!!errors?.candidateLanguages?.[index]?.languageLevelId?.message}
                            helperText={errors?.candidateLanguages?.[index]?.languageLevelId?.message.toString()}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Styled.DeleteGroupContainer>
            </React.Fragment>
          ))}
        {languages?.length < MaxLanguagesCount && (
          <Styled.AddButton
            disabled={disabled}
            onClick={handleAddItem('candidateLanguages', languages, { languageId: '', languageLevelId: '' })}
            variant="text"
            startIcon={<Styled.PlusIcon type="u_plus" />}
          >
            {t('pages.candidates.candidateCreation.addLanguage')}
          </Styled.AddButton>
        )}
      </Styled.GroupFieldsContainer>
      <Styled.BoxForDiffConsolidationBase>
        <Styled.CustomDictionaryValuesDiff field="candidateTechnologies" />
        <Controller
          name="candidateTechnologies"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => {
            return (
              <Select
                disabled={disabled}
                value={value}
                onChange={onChange}
                limitTags={1}
                multiple
                disableCloseOnSelect
                options={technologyOptions}
                renderInput={(params) => (
                  <Input
                    {...params}
                    inputRef={ref}
                    label={t('pages.candidates.candidateCreation.technology')}
                    placeholder={t('pages.candidates.candidateCreation.selectTechnology')}
                    error={!!errors['candidateTechnologies']?.message}
                    helperText={errors['candidateTechnologies']?.message.toString()}
                  />
                )}
              />
            );
          }}
        />
      </Styled.BoxForDiffConsolidationBase>
      <Styled.BoxForDiffConsolidationBase>
        <Styled.CustomDictionaryValuesDiff field="candidateDomains" />
        <Controller
          name="candidateDomains"
          control={control}
          render={({ field: { onChange, value, ref }, formState: { errors } }) => (
            <Select
              disabled={disabled}
              value={value}
              onChange={onChange}
              limitTags={1}
              multiple
              disableCloseOnSelect
              options={domainOptions}
              renderInput={(params) => (
                <Input
                  {...params}
                  inputRef={ref}
                  label={t('pages.candidates.candidateCreation.domain')}
                  placeholder={t('pages.candidates.candidateCreation.selectDomain')}
                  error={!!errors['candidateDomains']?.message}
                  helperText={errors['candidateDomains']?.message.toString()}
                />
              )}
            />
          )}
        />
      </Styled.BoxForDiffConsolidationBase>
    </React.Fragment>
  );
};

export default React.memo(SkillSection);
