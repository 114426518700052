import { NavLink } from 'react-router-dom';
import { styled, Typography, css } from '@innowise-group/mui-kit';

export const linkStyles = css`
  position: absolute;
  bottom: 120px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.info.default};
  &:hover {
    background: ${({ theme }) => theme.palette.general.lightGrey[20]};
  }
`;

export const AppContainer = styled.section`
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
`;

export const SupportLink = styled.a`
  ${linkStyles}
`;

export const HelpLink = styled.a`
  ${linkStyles}
  bottom: 120px;
`;

export const FaqLink = styled(NavLink)`
  ${linkStyles}
  bottom: 70px;
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.info.default};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.info.hover};
  }
`;

export const HelpText = styled(Typography)`
  position: absolute;
  left: 55px;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.info.default};
`;

export const VersionText = styled(Typography)`
  position: absolute;
  bottom: 30px;
  left: 32%;
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
  white-space: nowrap;
`;
