import { styled, Icon, Input, Menu } from '@innowise-group/mui-kit';
import { DatePicker as BaseDatePicker } from '@shared-mui-components';

export const DatePicker = styled(BaseDatePicker)`
  &.MuiTextField-root {
    width: 100%;
    margin-top: 0;
  }
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.paper};
  }
  & .MuiFormHelperText-root {
    display: none;
  }
`;

export const ClearIcon = styled(Icon)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  position: relative;
  & svg {
    flex-shrink: 0;
  }
`;

export const CalendarIcon = styled(Icon)<{ isRelative?: boolean }>`
  position: relative;
  left: 3px;
  & svg {
    flex-shrink: 0;
  }
`;

export const CustomInput = styled(Input)`
  &.MuiTextField-root {
    margin-top: 0;
  }
  & .MuiFormHelperText-root {
    display: none;
  }
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.paper};
  }
`;

export const DateInput = styled(Input)`
  &.MuiTextField-root {
    margin-top: 0;
  }
  & .MuiFormHelperText-root {
    display: none;
  }
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.paper};
  }
`;

export const DatePickersWrapper = styled(Menu)`
  & .MuiMenu-paper > .MuiMenu-list {
    display: flex;
    gap: 20px;
  }
`;

export const FilterWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
