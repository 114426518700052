import { AppIconsType } from '@innowise-group/mui-kit';

export enum ClaimTypes {
  FEEDBACK = 'FEEDBACK',
  NOTIFICATION = 'NOTIFICATION',
  AGREEMENT = 'AGREEMENT',
}

export enum FormItemTypes {
  SUB_STATUS = 'SUB_STATUS',
  VACANCY = 'VACANCY',
  AGREEMENT = 'AGREEMENT',
  FEEDBACK = 'FEEDBACK',
  NOTIFICATION = 'NOTIFICATION',
  FILE = 'FILE',
  LINK = 'LINK',
  REMINDER = 'REMINDER',
  CALENDAR = 'CALENDAR',
  VACANCY_REQUEST = 'VACANCY_REQUEST',
  // SERVICE_DESK = 'SERVICE_DESK',
  PERIOD = 'PERIOD',
}

export const AdministrationFormItemTypes = [
  FormItemTypes.VACANCY,
  FormItemTypes.SUB_STATUS,
  FormItemTypes.REMINDER,
  FormItemTypes.CALENDAR,
  FormItemTypes.FILE,
  FormItemTypes.LINK,
  FormItemTypes.NOTIFICATION,
  FormItemTypes.AGREEMENT,
  FormItemTypes.FEEDBACK,
];

export const PrimaryInterviewStatusId = 'eb7168b3-1792-41e1-ad34-750fc4008e43';
export const NewStatusId = '0e36e30b-9594-40c2-9a6d-73135c6ad6dd';
export const FirstContactStatusId = '8860cf2e-deb4-43d5-8026-8ad472ba298f';
export const HandedToRecruiterStatusId = '408f7b77-3a30-4893-a764-040e11db399d';

export const LettersStatusesIds = [NewStatusId, FirstContactStatusId, HandedToRecruiterStatusId];

export enum ClaimStatusTypes {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  DECLINED = 'DECLINED',
  OVERDUE = 'OVERDUE',
}

export enum MeetingStatusTypes {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  MAYBE = 'MAYBE',
}

export enum ClaimActionTypes {
  FEEDBACK = 'FEEDBACK',
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
}

export const MeetingStatusIcons: { [key: string]: AppIconsType } = {
  [MeetingStatusTypes.PENDING]: 'u_pending_status',
  [MeetingStatusTypes.ACCEPTED]: 'u_accepted_status',
  [MeetingStatusTypes.DECLINED]: 'u_pending_status',
  [MeetingStatusTypes.MAYBE]: 'u_maybe_status',
};

export const ClaimStatusIcons: { [key: string]: AppIconsType } = {
  [ClaimStatusTypes.PENDING]: 'u_claim_pending',
  [ClaimStatusTypes.RESOLVED]: 'u_claim_resolved',
  [ClaimStatusTypes.DECLINED]: 'u_claim_declined',
  [ClaimStatusTypes.OVERDUE]: 'u_claim_overdue',
};

export const StatusConfigFlags = {
  isSubStatusField: FormItemTypes.SUB_STATUS,
  isVacancyField: FormItemTypes.VACANCY,
  isAgreementField: FormItemTypes.AGREEMENT,
  isFeedbackField: FormItemTypes.FEEDBACK,
  isNotificationField: FormItemTypes.NOTIFICATION,
  isFileField: FormItemTypes.FILE,
  isLinkField: FormItemTypes.LINK,
  isCalendarField: FormItemTypes.CALENDAR,
  isReminderField: FormItemTypes.REMINDER,
  isRequestField: FormItemTypes.VACANCY_REQUEST,
  isPeriodField: FormItemTypes.PERIOD,
  //TODO: return when we integrate
  // isServiceDeskField = FormItemTypes.SERVICE_DESK,
};

// TODO: move to backend side with dynamic statuses
export const FeedbackFileNames = {
  ['501b94fb-690a-4312-ac3a-7fe8c5809403']: 'TI',
  ['3f8adf1d-09a8-4c8f-a4ff-3ea3895af779']: 'ToR',
  ['6c8be902-bc95-4f56-b72d-f64699929a87']: 'RMI',
  ['9169a5f5-b835-488a-93a0-5711f9741347']: 'FI',
  ['d29a229b-6c37-4787-af9d-e8a6f93ae473']: 'OP',
};
