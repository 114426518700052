export enum RolesValues {
  Admin = 'ADMIN',
  Supervisor = 'SUPERVISOR',
  Sourcer = 'SOURCER',
  Recruiter = 'RECRUITER',
  ManagerFirstLevel = 'MANAGER_FIRST_LEVEL',
  ManagerSecondLevel = 'MANAGER_SECOND_LEVEL',
  ManagerThirdLevel = 'MANAGER_THIRD_LEVEL',
  ManagerFourthLevel = 'MANAGER_FOURTH_LEVEL',
}

export const RolesNames = {
  [RolesValues.Admin]: 'roles.admin',
  [RolesValues.Supervisor]: 'roles.supervisor',
  [RolesValues.Sourcer]: 'roles.sourcer',
  [RolesValues.Recruiter]: 'roles.recruiter',
  [RolesValues.ManagerFirstLevel]: 'roles.managerFirstLevel',
  [RolesValues.ManagerSecondLevel]: 'roles.managerSecondLevel',
  [RolesValues.ManagerThirdLevel]: 'roles.managerThirdLevel',
  [RolesValues.ManagerFourthLevel]: 'roles.managerFourthLevel',
};

export const ManagerRolesArray = [
  RolesValues.ManagerFirstLevel,
  RolesValues.ManagerSecondLevel,
  RolesValues.ManagerThirdLevel,
  RolesValues.ManagerFourthLevel,
];

export const AllRolesArray = [
  RolesValues.Admin,
  RolesValues.Recruiter,
  RolesValues.Sourcer,
  RolesValues.Supervisor,
  ...ManagerRolesArray,
];

export const ManagerRolesIcons = {
  [RolesValues.ManagerFirstLevel]: 'manager_1_level',
  [RolesValues.ManagerSecondLevel]: 'manager_2_level',
  [RolesValues.ManagerThirdLevel]: 'manager_3_level',
  [RolesValues.ManagerFourthLevel]: 'manager_4_level',
};
