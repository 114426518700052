import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { RolesValues } from '@constants';
import { useTranslation } from 'react-i18next';
import { roles } from './set-new-user-role.data';
import { useEmployeesAdministrationAPI } from '@innowise-group/core';
import * as Styled from './set-new-user-role';
import { LabeledCheckbox, LabeledRadioButton } from '@shared-mui-components';

interface SetNewUserRoleModalProps {
  userData: {
    firstName: string;
    lastName: string;
  };
}

const SetNewUserRoleModal: React.FC<ModalProps & SetNewUserRoleModalProps> = ({ close, userData }) => {
  const { t } = useTranslation();
  const { setNewEmployeeRoleFromHrm, userToAdd } = useEmployeesAdministrationAPI();
  const userName = `${userData.firstName} ${userData.lastName}:`;

  const onChangeHandler = (role: RolesValues) => () => setNewEmployeeRoleFromHrm(role);

  const getProps = (key: keyof typeof roles) => ({
    checked: userToAdd.role === roles[key].value,
    value: (userToAdd.role === roles[key].value).toString(),
    onChange: onChangeHandler(roles[key].value),
  });

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('pages.administration.newUser.userRoles')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.UserName>{userName}</Styled.UserName>
        <Styled.OptionsContainer>
          <Styled.OptionsColumn>
            <LabeledRadioButton {...getProps(RolesValues.Admin)} label={t(roles.ADMIN.title)} />
            <LabeledRadioButton {...getProps(RolesValues.Supervisor)} label={t(roles.SUPERVISOR.title)} />
            <LabeledRadioButton {...getProps(RolesValues.Recruiter)} label={t(roles.RECRUITER.title)} />
            {/* <LabeledRadioButton {...getProps(RolesValues.Sourcer)} label={t(roles.SOURCER.title)} /> */}
          </Styled.OptionsColumn>
          <Styled.OptionsColumn>
            <LabeledRadioButton
              onChange={onChangeHandler(RolesValues.ManagerFourthLevel)}
              checked={
                userToAdd.role === RolesValues.ManagerFirstLevel ||
                userToAdd.role === RolesValues.ManagerSecondLevel ||
                userToAdd.role === RolesValues.ManagerThirdLevel ||
                userToAdd.role === RolesValues.ManagerFourthLevel
              }
              value={
                (userToAdd.role === RolesValues.ManagerFirstLevel).toString() ||
                (userToAdd.role === RolesValues.ManagerSecondLevel).toString() ||
                (userToAdd.role === RolesValues.ManagerThirdLevel).toString() ||
                (userToAdd.role === RolesValues.ManagerFourthLevel).toString()
              }
              label={t(roles.MANAGER_FOURTH_LEVEL_MANAGER_FOURTH_LEVEL.title)}
            />
            <Styled.SubOptionsColumn>
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerFirstLevel)}
                label={t(roles.MANAGER_FIRST_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerSecondLevel)}
                label={t(roles.MANAGER_SECOND_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerThirdLevel)}
                label={t(roles.MANAGER_THIRD_LEVEL.title)}
              />
              <LabeledCheckbox
                {...getProps(RolesValues.ManagerFourthLevel)}
                label={t(roles.MANAGER_FOURTH_LEVEL.title)}
              />
            </Styled.SubOptionsColumn>
          </Styled.OptionsColumn>
        </Styled.OptionsContainer>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.ok'),
            onClick: close,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(SetNewUserRoleModal, 'set-new-user-role');
