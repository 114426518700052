import { FC } from 'react';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './location-diff.styles';
import { useOptionsApi } from '@innowise-group/core';
import { t } from 'i18next';

const LocationDiff: FC = (props) => {
  const labelTransformer: LabelTransformer<'candidateLocation'> = (el) => {
    const { countriesOptions, allCitiesOptions } = useOptionsApi();
    const locationCountryName = countriesOptions.find(
      (c) => c.id?.toString() === el.locationCountry?.id?.toString(),
    )?.name;
    const currentLocationsCountryName = countriesOptions.find(
      (c) => c.id?.toString() === el.currentLocationCountry?.id?.toString(),
    )?.name;
    const locationCityName = allCitiesOptions.find((c) => c.value === el.locationCity?.id)?.title;
    const currentLocationCityName = allCitiesOptions.find((c) => c.value === el.currentLocationCity?.id)?.title;
    if (!locationCountryName && !currentLocationsCountryName && !locationCityName && !currentLocationCityName) {
      return t('buttons.leaveBlank');
    }
    return (
      <Styled.OptionWrapper>
        <Styled.Location>{`${locationCountryName || ''} ${
          locationCityName ? `(${locationCityName})` : ''
        }`}</Styled.Location>
        <Styled.CurrentLocation>
          {' '}
          / {`${currentLocationsCountryName || ''} ${currentLocationCityName ? `(${currentLocationCityName})` : ''}`}
        </Styled.CurrentLocation>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase fieldName="candidateLocation" labelTransformer={labelTransformer} {...props} />;
};

export default LocationDiff;
