import { BaseFilterType, VacancyApiSearchFilterType, useVacanciesOptionsApi, SelectOption } from '@innowise-group/core';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './vacancy.styles';
import { Checkbox, Select } from '@innowise-group/mui-kit';
import { VacancyListItem } from '@shared-mui-components';

interface VacancyFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: VacancyApiSearchFilterType['state'];
  pinned?: boolean;
  labelKey: string;
  onValueChange: (vacancyId: VacancyApiSearchFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: VacancyApiSearchFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const VacancyFilter: FC<VacancyFilterProps> = ({ state, onValueChange }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const {
    items,
    options,
    optionsLoading,
    additionalOptionsLoading,
    lastElementRef,
    getInitialOptions,
    getVacanciesByIds,
    handleSearchVacancyOptions,
  } = useVacanciesOptionsApi();

  useEffect(() => {
    const ids = state.map((id) => id.toString());
    getVacanciesByIds(ids);
  }, []);

  const onVacancyChangeHandler = (vacancies: SelectOption[]) => {
    const vacanciesIds = vacancies.map(({ value }) => Number(value));
    onValueChange(vacanciesIds);
  };
  const value = useMemo<SelectOption[]>(
    () =>
      state.reduce((acc, id) => (items?.[id] ? [...acc, { value: id.toString(), title: items[id]?.name }] : acc), []),
    [state],
  );

  const onOpen = () => {
    setOpened(true);
  };
  const onClose = () => {
    setOpened(false);
    getInitialOptions();
  };

  const shouldShowLabel = !!value.length && !opened;

  return (
    <Select
      multiple
      open={opened}
      options={options}
      value={value}
      disableCloseOnSelect
      loading={optionsLoading}
      loadingAdditionalOptions={additionalOptionsLoading}
      onChange={onVacancyChangeHandler}
      renderOption={(props, option: SelectOption) => {
        const vacancy = items[option.value];
        if (!vacancy) return null;
        return (
          <Styled.CheckboxOptionItem ref={lastElementRef} {...props} key={option.value}>
            <VacancyListItem {...vacancy} />
            <Styled.CheckboxWrapper>
              <Checkbox checked={!!value.find((item) => item.value?.toString() === option.value?.toString())} />
            </Styled.CheckboxWrapper>
          </Styled.CheckboxOptionItem>
        );
      }}
      renderInput={(params) => (
        <Styled.InputWrapper>
          {shouldShowLabel && (
            <Styled.SelectedInfoContainer>
              <Styled.FirstSelectedValue>{`${value?.[0]?.title}`}</Styled.FirstSelectedValue>
              {state.length > 1 && <Styled.SelectedCount>{`+ ${state.length - 1}`}</Styled.SelectedCount>}
            </Styled.SelectedInfoContainer>
          )}
          <Styled.VacancySearchInput
            onChange={handleSearchVacancyOptions}
            {...params}
            InputProps={{
              ...params.InputProps,
              onFocus: onOpen,
              onBlur: onClose,
            }}
            {...(!shouldShowLabel && {
              placeholder: t('pages.candidates.candidateCreation.selectTheVacancy'),
            })}
          />
        </Styled.InputWrapper>
      )}
    />
  );
};

export default memo(VacancyFilter);
