import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ElementsTypes } from '../store';

const MIN_LETTERS_COUNT = 1;
const MAX_LETTERS_COUNT = 100;

export const MAX_QUERY_ITEMS = 10;

export const useValidationSchemaXray = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      xRayString: Yup.string().max(MAX_LETTERS_COUNT),
      query: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string(),
            value: Yup.string()
              .matches(/^(?:[^'"\n]*(?:(['"])[^'"\n]*\1[^'"\n]*)*)*$/gi, t('errors.invalidFormat'))
              .min(MIN_LETTERS_COUNT, t('errors.minCharacters', { count: MIN_LETTERS_COUNT }))
              .max(MAX_LETTERS_COUNT, t('errors.maxCharacters', { count: MAX_LETTERS_COUNT })),
          }),
        )
        .test('test', t('errors.xrayInputsCount', { count: MAX_QUERY_ITEMS }), function (elements) {
          return elements.filter(({ type }) => type === ElementsTypes.Input)?.length <= MAX_QUERY_ITEMS;
        }),
    });
  }, [t]);

  return validationSchema;
};
