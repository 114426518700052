import { LocalizedShortCandidateItemResponse, useResizeObserver } from '@innowise-group/core';
import React from 'react';
import { useTheme } from '@innowise-group/mui-kit';
import { MobileView } from './components/mobile-view';
import { DesktopView } from './components/desktop-view';

interface CandidateTableRowProps {
  item: LocalizedShortCandidateItemResponse;
  idx: number;
  isRecruiterMode?: boolean;
  disableReject?: boolean;
  withReject?: boolean;
  handleReject?: (event: React.SyntheticEvent) => void;
  handleHardDelete?: () => void;
  lastElementRef?: (node: Element) => void;
  customOptionalRow?: React.ReactNode;
  isArchive?: boolean;
  handleRestore?: () => void;
}

const CandidateTableRow: React.FC<CandidateTableRowProps> = (props) => {
  const theme = useTheme();
  const isTabletView = useResizeObserver(theme.breakpoints.values.sm);
  if (isTabletView) return <DesktopView {...props} />;
  return <MobileView {...props} />;
};

export default CandidateTableRow;
