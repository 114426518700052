import { useEmployeesAPI } from '@innowise-group/core';
import React, { useState } from 'react';
import * as Styled from './comment-text.styles';
import { CommentRowHeight, LifecycleStatuses, MaxCommentRowCount, mentionRegexp } from '@constants';
import { AvatarMenu } from '../avatar-menu';
import { useTranslation } from 'react-i18next';
import { ShadowCollapse } from '@shared-mui-components';

interface CommentTextProps {
  text: string;
  isOpenComment: boolean | null;
  handleOpenComment: () => void;
  isTopPosition?: boolean;
  shadowColor?: string;
  commentRef: React.MutableRefObject<HTMLDivElement>;
}

const CommentText: React.FC<CommentTextProps> = ({
  text,
  isTopPosition,
  isOpenComment,
  handleOpenComment,
  commentRef,
  shadowColor,
}) => {
  const matchValues = Array.from(text.matchAll(mentionRegexp), (item) => item[0]);

  return (
    <ShadowCollapse
      isOpen={isOpenComment}
      handleOpen={handleOpenComment}
      commentRef={commentRef}
      shadowColor={shadowColor}
      collapsedSize={CommentRowHeight * MaxCommentRowCount}
    >
      <Styled.TextWrapper>
        {matchValues
          ? text.split(mentionRegexp).map((item, index, array) => {
              return (
                <TextPart
                  key={index}
                  item={item}
                  index={index}
                  array={array}
                  matchValues={matchValues}
                  isTopPosition={isTopPosition}
                />
              );
            })
          : text}
      </Styled.TextWrapper>
    </ShadowCollapse>
  );
};

export default CommentText;

interface TextPartProps {
  index: number;
  array: string[];
  item: string;
  isTopPosition?: boolean;
  matchValues: string[];
}

export const TextPart: React.FC<TextPartProps> = ({ index, matchValues, item, array, isTopPosition }) => {
  const { t } = useTranslation();
  const { mentionedEmployees } = useEmployeesAPI();
  const [isHover, setIsHover] = useState<boolean>(false);

  const employeeId = matchValues[index]?.match(/\d+/)[0];

  if (index < array.length - 1) {
    const handleTagHover = () => {
      setIsHover(true);
    };

    const handleTagLeave = () => {
      setIsHover(false);
    };
    return (
      <React.Fragment>
        {item}
        {mentionedEmployees[employeeId] ? (
          <Styled.TagContainer
            isDeleted={mentionedEmployees[employeeId].lifecycleStatus === LifecycleStatuses.Deleted}
            onMouseEnter={handleTagHover}
            onMouseLeave={handleTagLeave}
          >
            {`@${mentionedEmployees[employeeId].firstNameEn} ${mentionedEmployees[employeeId].lastNameEn}`}
            {isHover && (
              <Styled.EmployeeContainer>
                <Styled.EmployeeInfo isTopPosition={isTopPosition}>
                  <AvatarMenu
                    firstNameRu={mentionedEmployees[employeeId].firstNameEn}
                    lastNameRu={mentionedEmployees[employeeId].lastNameEn}
                    photoId={mentionedEmployees[employeeId].photoRt?.id}
                    jobTitle={
                      mentionedEmployees[employeeId].lifecycleStatus === LifecycleStatuses.Deleted
                        ? t('pages.candidates.comments.deleted')
                        : mentionedEmployees[employeeId].jobTitle?.translation || '-'
                    }
                  />
                </Styled.EmployeeInfo>
              </Styled.EmployeeContainer>
            )}
          </Styled.TagContainer>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
  return <React.Fragment key={index}>{item}</React.Fragment>;
};
