import { FC } from 'react';
import { format } from 'date-fns';
import { es, ru } from 'date-fns/locale';
import { DiffBase, DiffBaseProps, LabelTransformer } from '../diff-base';
import { AppLanguages, DateFormats } from '@constants';
import { getLanguageFromLS } from '@innowise-group/utilities';
import { useTranslation } from 'react-i18next';

const BirthdayDiff: FC<Omit<DiffBaseProps, 'labelTransformer' | 'fieldName'>> = (props) => {
  const { t } = useTranslation();
  const ln = getLanguageFromLS();
  const locale = ln === AppLanguages.En ? es : ru;
  const labelTransformer: LabelTransformer<'birthDate'> = ({ data }) =>
    data ? format(new Date(data), DateFormats.LongLocalizedDate, { locale }) : t('buttons.leaveBlank');
  return <DiffBase fieldName="birthDate" labelTransformer={labelTransformer} {...props} />;
};

export default BirthdayDiff;
