import { $filesApi } from '@innowise-group/core';
import { Loader, Tab, Tabs } from '@innowise-group/mui-kit';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import * as Styled from './excel-viewer.styles';

interface ExcelViewerProps {
  id: number;
}

const ExcelViewer: React.FC<ExcelViewerProps> = ({ id }) => {
  const [__html, setHTML] = useState('');
  const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [workSheet, setWorSheet] = useState<{ sheet: XLSX.WorkSheet; name: string } | null>(null);

  const handleChangeTab = (sheetName: string) => () => {
    setWorSheet({ sheet: workBook.Sheets[sheetName], name: sheetName });
  };

  useEffect(() => {
    const fetchFile = async () => {
      const response = await $filesApi.get(`/recruiting-tool/api/v1/attachment?id=${id}`, {
        responseType: 'arraybuffer',
      });
      const wb = XLSX.read(response.data);
      setWorkBook(wb);
      const ws = wb.Sheets[wb.SheetNames[0]];
      setWorSheet({ sheet: ws, name: wb.SheetNames[0] });
      setIsLoading(false);
    };
    fetchFile();
  }, [id]);

  useEffect(() => {
    if (workSheet) {
      const allCells = Object.keys(workSheet.sheet).filter((item) => !item.startsWith('!'));
      const firstColumn = allCells[0].match(/^[a-zA-Z]+/)?.[0] || 'A';
      const firstRow = allCells[0].match(/[0-9]+$/)?.[0] || 1;

      const range = allCells.reduce(
        (accum, curr) => {
          const column = curr.match(/^[a-zA-Z]+/)?.[0];
          const row = curr.match(/[0-9]+$/)?.[0];
          if (row && Number(row) > accum.lastRow) accum.lastRow = Number(row);
          if (row && Number(row) < accum.firstRow) accum.firstRow = Number(row);
          if (column && column < accum.firstColumn) accum.firstColumn = column;
          if (column && column > accum.lastColumn) accum.lastColumn = column;
          return accum;
        },
        { firstColumn, firstRow: Number(firstRow), lastColumn: 'A', lastRow: 1 },
      );
      workSheet.sheet['!ref'] = `${range.firstColumn}${range.firstRow}:${range.lastColumn}${range.lastRow}`;
      setHTML(XLSX.utils.sheet_to_html(workSheet.sheet));
    }
  }, [workSheet]);

  return isLoading ? (
    <Loader />
  ) : (
    <Styled.Container>
      {workSheet && (
        <React.Fragment>
          <Styled.SheetNamesContainer>
            <Tabs value={workSheet.name}>
              {workBook.SheetNames.map((sheetName, index) => (
                <Tab
                  key={index}
                  label={sheetName}
                  value={sheetName}
                  tabsvalue={workSheet.name}
                  onClick={handleChangeTab(sheetName)}
                />
              ))}
            </Tabs>
          </Styled.SheetNamesContainer>
          {__html && <Styled.SheetContainer dangerouslySetInnerHTML={{ __html }} />}
        </React.Fragment>
      )}
    </Styled.Container>
  );
};

export default React.memo(ExcelViewer);
