import { styled, css } from '@innowise-group/mui-kit';
import { NotificationType } from './notification.types';

export const NotificationsContainer = styled.div`
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 11000;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 0;
    padding: 0 1rem;
    width: 100%;
    display: flex;
    gap: 0;
    flex-direction: column;
  }
`;

interface NotificationContainerProps {
  containerType: NotificationType;
  isGrouped?: boolean;
  index: number;
}
export const NotificationContainer = styled.div<NotificationContainerProps>`
  border-radius: 10px;
  border: 1px solid;
  min-height: 76px;
  width: 380px;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: auto;
    width: 100%;
    transform: scaleX(${({ index, isGrouped }) => isGrouped && 1 - index * 0.07}) !important;
    margin-bottom: ${({ isGrouped }) => isGrouped && 0};
    ${({ isGrouped, index }) =>
      isGrouped &&
      !!index &&
      css`
        height: 10px;
        border-radius: 0 0 10px 10px;
        padding: 0;
      `}
  }

  ${({
    theme: {
      palette: { state },
    },
    containerType,
  }) => {
    const colorsMap: Record<NotificationType, { background: string; border: string }> = {
      error: {
        background: state.error.background,
        border: state.error.default,
      },
      warning: {
        background: state.warning.background,
        border: state.warning.default,
      },
      success: {
        background: state.success.background,
        border: state.success.default,
      },
    };

    return css`
      background-color: ${colorsMap[containerType].background};
      border-color: ${colorsMap[containerType].border};
    `;
  }}
`;

export const NotificationTitleContainer = styled.div`
  display: flex;
`;

export const NotificationContentContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 10px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: 6px;
  }
  flex-direction: column;
`;

export const NotificationTitle = styled.p`
  ${({ theme }) => theme.fonts.primary[20]}
  margin: 0;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  font-weight: bold;
  flex: 1;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 6px;
    ${({ theme }) => theme.fonts.primary[40]}
  }
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.fonts.primary[20]}
  margin: 0;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[40]}
  }
`;
