import React from 'react';
import { portalDispatch } from './portal.component';
import { ModalBody, Modal as ModalContainer } from './modal.styles';
import ModalHeader from './modal-header.component';
import ModalActions from './modal-actions.component';

export abstract class ModalBase<T = object> {
  public id: string;
  public data: T;

  constructor(id: string, data: T = null) {
    this.id = id;
    this.data = data;
  }

  public abstract renderModal: () => React.ReactNode;

  public close = (): void => {
    closeModal(this.id);
  };

  public getData = (): T => this.data;
}

const checkIfComponentIsMounted = () => {
  if (!portalDispatch) {
    throw new Error('Portal container element is not mounted');
  }
};

export const addModal = (modal: ModalBase) => {
  checkIfComponentIsMounted();
  portalDispatch?.({ type: 'add', modal });
};

export const closeModal = (id: string) => {
  checkIfComponentIsMounted();
  portalDispatch?.({ type: 'remove', modalId: id });
};

export default {
  Container: ModalContainer,
  Header: ModalHeader,
  Actions: ModalActions,
  Body: ModalBody,
};
