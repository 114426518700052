import { Icon, styled } from '@innowise-group/mui-kit';

export const RatingWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RatingChart = styled.span<{ $value: number; $isManuallyPrioritized: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid
    ${({ $value }) => {
      if ($value < 60) return '#C2315C';
      if ($value >= 60 && $value <= 90) return '#DA5922';
      if ($value > 90) return '#098C84';
    }};
  position: relative;
  margin: 0 4px;
`;

export const RatingIcon = styled(Icon)`
  position: absolute;
  top: -4px;
  right: -2px;
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CriteriaWrapper = styled.p`
  display: flex;
  justify-content: space-between;
`;

export const TotalCriteriaWrapper = styled(CriteriaWrapper)`
  margin-top: 5px;
`;

export const CriteriaLabel = styled.span`
  ${({ theme }) => theme.fonts.primary[30]}
`;
