export enum FileTypeConstants {
  CandidatePhoto = 'RT_CANDIDATE_PHOTO',
  CandidateFiles = 'RT_CANDIDATE_FILE',
  ProfilePhoto = 'RT_EMPLOYEE_PHOTO',
}

export enum FileSecureTypeConstants {
  NotSecured = 'NOT_SECURED',
  Jwt = 'JWT',
}

export enum FileStatusTypeConstants {
  Deleted = 'DELETED',
  UnAttached = 'UNATTACHED',
  Attached = 'ATTACHED',
}
