import { Button, Icon, styled } from '@innowise-group/mui-kit';

export const SectionContainer = styled.div`
  padding-top: 0.75rem;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  padding: 0 2rem;
  gap: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: baseline;
    align-items: stretch;
    padding: 0 1.25rem;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    row-gap: 0;
  }
`;

export const FileItem = styled.div`
  cursor: pointer;
  ${({ theme }) => theme.fonts.primary[60]};
  padding: 0.8125rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  max-width: 15rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[60]};
  & span {
    max-width: 9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: unset;
    flex: 1;
    grid-column: 1;
  }
`;

export const LinkItem = styled(FileItem)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 2;
  }
`;

export const CustomIcon = styled(Icon)`
  margin: 0 2px !important;
  & svg {
    flex-shrink: 0;
  }
`;

export const ClaimStatusItem = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    cursor: default;
    opacity: 1 !important;
    & svg,
    &:hover svg {
      fill: ${({ theme }) => theme.palette.state.error.pressed} !important;
    }
    &:hover,
    & {
      color: ${({ theme }) => theme.palette.state.error.pressed};
    }
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0.5rem;
  gap: 0.5rem;
  ${({ theme }) => theme.fonts.primary[80]};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  & svg {
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]} !important;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 1;
    gap: 3px;
  }
`;

export const CalendarPickerContainer = styled(DatePickerContainer)<{ isPending: boolean }>`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 2;
    border-radius: 8px 8px 0 0;
    background-color: ${({ theme, isPending }) => (isPending ? theme.palette.general.darkGrey[20] : 'transparent')};
  }
`;

export const AgreementButtonsContainer = styled(Block)`
  grid-column: 1 / -1;
  border-radius: 8px 0px 8px 8px;
  background-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  display: flex;
  align-items: center;
  & > * {
    flex: 1;
  }
`;

export const FeedBackButton = styled(Button)`
  &.MuiButton-root {
    margin-right: auto;
  }
`;
