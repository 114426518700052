import { Button, Icon, styled, css } from '@innowise-group/mui-kit';
import { ProfessionsDiff, LanguagesDiff, DictionaryValuesDiff } from '../../../diff-variants';

export const GroupFieldsContainer = styled.div`
  grid-column: 1 / span 4;
  display: grid;
  column-gap: 1.75rem;
  row-gap: 0.625rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    column-gap: 1.25rem;
    row-gap: 0.5rem;
  }
`;

export const DeleteGroupContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const AddButton = styled(Button)`
  grid-column: 4;
  justify-self: end;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
  &.MuiButton-root {
    position: absolute;
    top: -22px;
    right: 0;
  }
  &.MuiButton-text {
    padding: 6px 8px !important;
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const PlusIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const BoxForDiffConsolidationBase = styled.div`
  position: relative;
  display: grid;
`;

export const BoxForDiffConsolidation = styled(BoxForDiffConsolidationBase)<{ $multiProfessions: boolean }>`
  grid-column: ${({ $multiProfessions }) => ($multiProfessions ? '1 / -1' : '1 / 3')};
`;

const commonDiffElementsPosition = css`
  top: 20px;
`;

export const CustomProfessionsDiff = styled(ProfessionsDiff)`
  ${commonDiffElementsPosition}
  top: -5px;
`;

export const CustomLanguagesDiff = styled(LanguagesDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomDictionaryValuesDiff = styled(DictionaryValuesDiff)`
  ${commonDiffElementsPosition}
`;
