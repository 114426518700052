import { SelectOption, VacancyItem } from '@innowise-group/core';
import React, { useCallback, useState } from 'react';
import * as Styled from './mobile-view.styles';
import { Stepper } from '@shared-mui-components';
import { ConditionsSectionFields, MainSectionFields, VacancyStepsEnum, steps } from '../../vacancy-form.data';
import { Controller, useFormContext } from 'react-hook-form';
import { MainSection } from '../main-section';
import { OptionalSection } from '../optional-section';
import { useTranslation } from 'react-i18next';
import { Button } from '@innowise-group/mui-kit';

interface MobileViewProps {
  isRequests?: boolean;
  isEdit?: boolean;
  forRequestCreation?: boolean;
  locationCountryOptions: SelectOption[];
  locationCityOptions: SelectOption[];
  handleClose: () => void;
  onSubmit: (data: VacancyItem) => void;
}

const MobileView: React.FC<MobileViewProps> = ({
  locationCountryOptions,
  locationCityOptions,
  handleClose,
  onSubmit,
  forRequestCreation,
  isEdit,
  isRequests,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<VacancyStepsEnum>(VacancyStepsEnum.Main);
  const { trigger, control, watch, handleSubmit } = useFormContext();
  const published = watch('published');

  const handleNextStep = useCallback(
    (fields: string[]) => async () => {
      const isValidated = await trigger(fields);
      if (isValidated) {
        setActiveStep((prev) => prev + 1);
      }
    },
    [],
  );

  const handlePrevStep = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  return (
    <Styled.FormContainer>
      <Stepper steps={steps} activeStep={activeStep} />
      <Styled.StepContainer>
        {activeStep === VacancyStepsEnum.Main && (
          <MainSection
            handleClose={handleClose}
            handleNextStep={handleNextStep(MainSectionFields)}
            isEdit={isEdit}
            isRequests={isRequests}
            forRequestCreation={forRequestCreation}
          />
        )}
        {activeStep === VacancyStepsEnum.Optional && (
          <OptionalSection
            isEdit={isEdit}
            isRequests={isRequests}
            handleNextStep={handleNextStep(ConditionsSectionFields)}
            handlePrevStep={handlePrevStep}
            locationCityOptions={locationCityOptions}
            locationCountryOptions={locationCountryOptions}
          />
        )}
        {activeStep === VacancyStepsEnum.Comments && (
          <React.Fragment>
            <Controller
              name="comment"
              control={control}
              render={({ field: { onChange, value }, formState: { errors } }) => (
                <Styled.FullInput
                  value={value}
                  onChange={onChange}
                  label={t('pages.vacancies.creationModal.specialRequirements')}
                  error={!!errors?.['comment']?.message}
                  helperText={errors?.['comment']?.message.toString()}
                  placeholder={t('pages.vacancies.creationModal.specialRequirementsPlaceholder')}
                  rows={7}
                  multiline
                  disabled={isRequests || (isEdit && published)}
                />
              )}
            />
            <Styled.ButtonsSection>
              <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
                {t('buttons.back')}
              </Styled.GrayButton>
              <Button onClick={handleSubmit(onSubmit)}>{t('buttons.save')}</Button>
            </Styled.ButtonsSection>
          </React.Fragment>
        )}
      </Styled.StepContainer>
    </Styled.FormContainer>
  );
};

export default MobileView;
