import axios, { AxiosError } from 'axios';
import { EnvConfiguration } from './env-configuration.types';

export class EnvConfigurationService {
  private static configUrl = '/config/config.json';

  static async getEnvConfig(): Promise<EnvConfiguration> {
    try {
      const response = await axios.get(this.configUrl);
      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
    }
  }
}
