import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { countryData } from '../../mui-components/phone-input/phone-input.data';
import { useValidationSchemaTelegram } from './use-validation-schema-telegram';
import * as Yup from 'yup';

export const useValidationSchemaProfile = () => {
  const { t } = useTranslation();
  const telegramSchema = useValidationSchemaTelegram();
  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      {
        phoneRt: Yup.object().shape({
          value: Yup.string()
            .trim()
            .test('test', t('errors.noValue'), function (value) {
              const telegramRt = this.from[1].value.telegramRt;
              return !(!telegramRt && !value);
            })
            .test('test', t('errors.invalidPhoneFormat'), function (value) {
              if (value) {
                if (!value.match(/^\d+$/)) return false;
                const country = countryData.find((item) => item.isoCode === this.parent.country);
                if (!country && value.length) return false;
                if (!country) return true;
                const formatsLength: number[] =
                  country.format.map((item) => {
                    return item.split('').filter((curr) => curr === '#' || +curr).length + country.dialCode.length - 1;
                  }) || [];
                const isCorrectLength = !!formatsLength.find((item) => item === value.length);
                return isCorrectLength && `+${value}`.startsWith(country.dialCode);
              }
              return true;
            }),
          country: Yup.string(),
        }),
        telegramRt: telegramSchema.when('phoneRt.value', {
          is: (phoneRt: string) => !phoneRt,
          then: (schema) => schema.required(t('errors.noValue')),
          otherwise: (schema) => schema.notRequired(),
        }),
      },
      [['phoneRt', 'telegramRt']],
    );
  }, [t, countryData]);

  return { validationSchema };
};
