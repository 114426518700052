import { styled, Button as ButtonBase } from '@innowise-group/mui-kit';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  max-height: 40px;
  border-radius: 6px;
  overflow: hidden;
  width: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid ${({ theme, disabled }) => theme.palette.general.darkGrey[disabled ? 40 : 60]};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;

export const Button = styled(ButtonBase)<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  &.MuiButton-root {
    min-width: 0;
  }
  &.MuiButton-root.MuiButton-contained {
    border-radius: 0;
    padding: 0;
    background: ${({ theme, $active }) =>
      $active ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[20]};
    &:hover {
      background: ${({ theme, $active }) =>
        $active ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[20]};
    }
    & svg {
      fill: ${({ theme, $active }) =>
        $active ? theme.palette.general.darkGrey[20] : theme.palette.general.darkGrey[60]};
    }
    &:disabled {
      background: ${({ theme, $active }) =>
        $active ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[20]};
    }
  }
`;
