import { StatisticState } from './statistic.types';

export const preloadedStatisticState: StatisticState = {
  filtersState: null,
  filtersStateLoaded: false,
  isLoading: false,
  isExportLoading: false,
  overallStatuses: null,
  exits: { totalElements: 0, totalPages: 0, items: [], last: false, isLoading: false },
  summary: {
    groupedByResponsibleEmployeeStatistics: [],
    groupedBySourceStatistics: [],
    groupedBySpecialityStatistics: [],
    groupedByStatusStatistics: [],
  },
  statusesByVacancies: {
    totalElements: 0,
    totalPages: 0,
    items: [],
    last: false,
    isLoading: false,
  },
  overallTeamWorking: null,
  verticalTeamWorking: {
    totalElements: 0,
    totalPages: 0,
    items: [],
    last: false,
    isLoading: false,
  },
  horizontalTeamWorking: {
    totalElements: 0,
    totalPages: 0,
    items: [],
    last: false,
    isLoading: false,
  },
  personalStatistic: {
    totalElements: 0,
    totalPages: 0,
    items: [],
    overallItems: [],
    last: false,
    isLoading: false,
  },
  requests: {
    candidatesFound: 0,
    data: [],
    requiredCount: 0,
    totalClaimsCount: 0,
    totalVacanciesCount: 0,
  },
};
