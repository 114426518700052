import { FC, memo, useCallback } from 'react';
import { Outlet as RouterOutlet } from 'react-router-dom';
import { ReportManager, ReportTypeUnion, TinyState } from '../report-manager';
import * as Styled from './outlet.styles';
import { useFormContext } from 'react-hook-form';
import { AppRoutes } from '@constants';
import { useCandidateStatusAPI, useOptionsApi, useResizeObserver, useStatisticsAPI } from '@innowise-group/core';
import { Loader, useTheme } from '@innowise-group/mui-kit';

const Outlet: FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { employeesLoading, loading } = useOptionsApi();
  const { isLoading: statusesLoading } = useCandidateStatusAPI();
  const {
    fetchOverallStatusesReport,
    fetchOverallTeamStatistic,
    fetchStatusesByVacanciesReport,
    exportStatusesReport,
    exportRequestsReport,
    exportTeamWorkingReport,
    exportExitsReport,
    exportSummaryReport,
    fetchVerticalTeamWorkingByVacancyReport,
    fetchHorizontalTeamWorkingByVacancyReport,
    fetchPersonalStatistic,
    fetchPersonalOverallStatistic,
    fetchRequestStatisticData,
    fetchExitsStatisticsReport,
    fetchSummaryStatistic,
  } = useStatisticsAPI();
  const { watch } = useFormContext();
  const reportType = watch('reportType');

  const onFiltersSubmit = useCallback(
    (args: TinyState) => {
      if (window.location.pathname.includes(`${AppRoutes.AnalyticsStatusPage}`)) {
        if (reportType === ReportTypeUnion.Round || !isDesktopView) {
          fetchStatusesByVacanciesReport(args);
          fetchOverallStatusesReport(args);
          return;
        }
        if (reportType === ReportTypeUnion.Linear) {
          fetchOverallStatusesReport(args);
          return;
        }
        if (reportType === ReportTypeUnion.Vertical || reportType === ReportTypeUnion.Horizontal) {
          fetchOverallStatusesReport(args);
          fetchStatusesByVacanciesReport(args, 1);
          return;
        }
      }
      if (
        window.location.pathname.includes(AppRoutes.AnalyticsTeamWorkingPage) ||
        window.location.pathname.includes(AppRoutes.AnalyticsLettersPage)
      ) {
        if (reportType === ReportTypeUnion.Round || !isDesktopView) {
          fetchHorizontalTeamWorkingByVacancyReport(args, 1);
          fetchOverallTeamStatistic(args);
          return;
        }
        if (reportType === ReportTypeUnion.Linear) {
          fetchOverallTeamStatistic(args);
          return;
        }
        if (reportType === ReportTypeUnion.Vertical) {
          fetchVerticalTeamWorkingByVacancyReport(args, 1);
          fetchOverallTeamStatistic(args);
          return;
        }
        if (reportType === ReportTypeUnion.Horizontal) {
          fetchHorizontalTeamWorkingByVacancyReport(args, 1);
          fetchOverallTeamStatistic(args);
          return;
        }
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsEmployeePage)) {
        if (!isDesktopView) fetchPersonalStatistic(args, 1);
        fetchOverallStatusesReport(args);
        fetchPersonalOverallStatistic(args);
      }
      if (window.location.pathname.endsWith(AppRoutes.AnalyticsRequestsPage)) {
        fetchRequestStatisticData(args);
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsExitsPage)) {
        fetchExitsStatisticsReport(args, 1);
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsSummaryPage)) {
        fetchSummaryStatistic(args);
      }
    },
    [window.location.pathname],
  );

  const onExport = useCallback(
    (args: TinyState) => {
      if (window.location.pathname.includes(AppRoutes.AnalyticsStatusPage)) {
        exportStatusesReport(args);
        return;
      }
      if (
        window.location.pathname.includes(AppRoutes.AnalyticsTeamWorkingPage) ||
        window.location.pathname.includes(AppRoutes.AnalyticsLettersPage)
      ) {
        exportTeamWorkingReport(
          args,
          args.reportType === ReportTypeUnion.Vertical ? ReportTypeUnion.Vertical : ReportTypeUnion.Horizontal,
        );
        return;
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsEmployeePage)) {
        exportTeamWorkingReport(args, ReportTypeUnion.Horizontal);
        return;
      }
      if (window.location.pathname.endsWith(AppRoutes.AnalyticsRequestsPage)) {
        exportRequestsReport(args);
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsExitsPage)) {
        exportExitsReport(args);
      }
      if (window.location.pathname.includes(AppRoutes.AnalyticsSummaryPage)) {
        exportSummaryReport(args);
      }
    },
    [window.location.pathname],
  );

  if (statusesLoading || employeesLoading || loading)
    return (
      <Styled.Wrapper>
        <Styled.Content>
          <Loader />
        </Styled.Content>
      </Styled.Wrapper>
    );
  return (
    <Styled.Wrapper>
      <Styled.Content>
        <ReportManager onExport={onExport} onFiltersSubmit={onFiltersSubmit} />
        <RouterOutlet />
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default memo(Outlet);
