import { UseFormReturn, useFormContext } from 'react-hook-form';
import { ObjectSchema } from 'yup';

const useControlFormField = <T,>(validationSchema?: ObjectSchema<T>, methods?: UseFormReturn<any>) => {
  const { setValue, clearErrors, getValues, setError } = methods || useFormContext();

  const handlerValidate = (fieldsName: string) => {
    validationSchema
      ?.validate(getValues(), { abortEarly: false, recursive: true })
      .then(() => {
        clearErrors();
      })
      .catch((err) => {
        clearErrors(fieldsName);
        const errorsArrays = err.inner?.filter((item) => item.path.includes(fieldsName));
        errorsArrays?.forEach((element) => {
          setError(element.path, { message: element.message });
        });
      });
  };

  const handleChangeBooleanItem =
    (onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void, fieldsName: string) =>
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onChange(event, value);
      handlerValidate(fieldsName);
    };

  const handleChangeDateItem = (onChange: (newValue: Date) => void, fieldsName: string) => (value: Date) => {
    onChange(value);
    handlerValidate(fieldsName);
  };

  const handleChangeInputItem =
    (onChange: (newValue: string) => void, fieldsName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target.value);
      handlerValidate(fieldsName);
    };

  const handleChangeSelectItem = (onChange: (newValue: string) => void, fieldsName: string) => (value: string) => {
    onChange(value);
    handlerValidate(fieldsName);
  };

  const handleAddItem =
    <T,>(fieldName: string, fieldsArray: T[], emptyItem: T) =>
    () => {
      setValue(fieldName, [...fieldsArray, emptyItem]);
    };

  const handleRemoveItem =
    <T,>(idx: number, fieldName: string, fieldsArray: T[]) =>
    () => {
      clearErrors(`${fieldName}[${idx}]`);
      setValue(
        fieldName,
        fieldsArray.filter((_, index: number) => index !== idx),
      );
    };

  return {
    handlerValidate,
    handleAddItem,
    handleRemoveItem,
    handleChangeSelectItem,
    handleChangeInputItem,
    handleChangeDateItem,
    handleChangeBooleanItem,
  };
};

export default useControlFormField;
