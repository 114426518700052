import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { modalHoc, ModalProps, Modal, useTheme } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { VacancyForm, vacancyFormDefaultValues } from '@shared-components';
import * as Styled from './vacancy-creation-modal.styles';
import { useResizeObserver, useVacanciesAPI, useValidationSchemaVacancy, VacancyItem } from '@innowise-group/core';
import { yupResolver } from '@hookform/resolvers/yup';

interface VacancyCreationModalProps {
  saveCallback: (vacancyId?: string, locationId?: string) => void;
  forRequestCreation?: boolean;
}

const VacancyCreationModal: React.FC<ModalProps & VacancyCreationModalProps> = ({
  close,
  saveCallback,
  forRequestCreation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { validationSchema } = useValidationSchemaVacancy();
  const { createVacancy, isLoading: vacanciesIsLoading } = useVacanciesAPI();

  const methods = useForm<VacancyItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: vacancyFormDefaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: VacancyItem) => {
    createVacancy(data, successCallback);
  };

  const successCallback = (vacancyId: string, locationId?: string) => {
    close();
    saveCallback?.(vacancyId, locationId);
  };

  const onCancel = () => {
    close();
  };

  return (
    <Modal.Container size="large">
      {vacanciesIsLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={close}>
            <Styled.Header>
              <span>{t('pages.vacancies.creationModal.vacancyCreation')}</span>
            </Styled.Header>
          </Modal.Header>
          <Modal.Body>
            <VacancyForm onSubmit={onSubmit} handleClose={close} forRequestCreation={forRequestCreation} />
          </Modal.Body>
          {isDesktopView && (
            <Modal.Actions
              noVerticalPadding={true}
              position={'right'}
              actions={[
                {
                  title: t('buttons.cancel'),
                  onClick: onCancel,
                  type: 'button',
                },
                {
                  title: t('buttons.save'),
                  onClick: handleSubmit(onSubmit),
                  highlighted: true,
                  type: 'button',
                },
              ]}
            />
          )}
        </FormProvider>
      )}
    </Modal.Container>
  );
};

export default modalHoc(VacancyCreationModal, 'vacancy-creation');
