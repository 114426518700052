import { useSelector } from 'react-redux';
import { optionsState } from './options.selector';
import { useCallback } from 'react';
import {
  addCityToDictionaryThunk,
  getAllSelectOptionsThunk,
  getCitySelectOptionsThunk,
  getCountrySelectOptionsThunk,
  getEmployeesByRolesOptionsThunk,
} from './options.thunk';
import { useAppDispatch } from '@innowise-group/core';
import { RolesValues } from '@constants';

export const useOptionsApi = () => {
  const dispatch = useAppDispatch();
  const state = useSelector(optionsState);

  const getAllSelectOptions = useCallback(() => {
    return dispatch(getAllSelectOptionsThunk());
  }, [dispatch]);

  const getCountrySelectOptions = useCallback(() => {
    return dispatch(getCountrySelectOptionsThunk());
  }, [dispatch]);

  const getCitiesSelectOptions = useCallback(() => {
    return dispatch(getCitySelectOptionsThunk());
  }, [dispatch]);

  const addCityToDictionary = useCallback(
    (data: { countryId: string; cityNames: { [key: string]: string } }) => {
      return dispatch(addCityToDictionaryThunk({ ...data }));
    },
    [dispatch],
  );

  const getStatusAuthorsByRoles = useCallback(() => {
    return dispatch(getEmployeesByRolesOptionsThunk({ roles: [RolesValues.Admin, RolesValues.Recruiter] })).unwrap();
  }, [dispatch]);

  const getOptions = useCallback(
    (options: string[]) => {
      if (!state.options) return {};
      return Object.entries(state.options).reduce((acc, [key, val]) => {
        if (options.includes(val.groupId)) {
          return {
            ...acc,
            [key]: val.options,
          };
        }
        return acc;
      }, {});
    },
    [state],
  );

  return {
    ...state.options,
    ...state,
    addCityToDictionary,
    getAllSelectOptions,
    getCitiesSelectOptions,
    getCountrySelectOptions,
    getStatusAuthorsByRoles,
    getOptions,
  };
};
