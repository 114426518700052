import { Button, Icon, styled } from '@innowise-group/mui-kit';

export const CalendarIcon = styled(Icon)`
  cursor: pointer;
  margin: 0;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    & path {
      fill: ${({ theme }) => theme.palette.state.error.pressed};
    }
  }
  &:hover path {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const ResetIcon = styled(CalendarIcon)`
  position: relative;
  left: 5px;
  margin: 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: 3px;
  }
`;

export const CustomButton = styled(Button)`
  width: auto;
  padding-left: 0;
  height: 2.5rem;
  & > div {
    margin: 0;
  }

  &:hover path {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const RequiredBlock = styled.div<{ required?: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 2px;
  }
  &::after {
    display: block;
    content: ${({ required }) => (required ? '"*"' : '""')};
    color: ${({ theme }) => theme.palette.state.error.pressed};
    position: absolute;
    top: 0;
    right: 5px;
  }
`;

export const DateValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  position: relative;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[80]};
  font-weight: 600;

  & .react-datepicker__time-list-item--selected + .react-datepicker__time-list-item,
  & .react-datepicker__time-list-item--selected + .react-datepicker__time-list-item + .react-datepicker__time-list-item,
  &
    .react-datepicker__time-list-item--selected
    + .react-datepicker__time-list-item
    + .react-datepicker__time-list-item
    + .react-datepicker__time-list-item {
    border: 1px solid ${({ theme }) => theme.palette.state.error.default};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

export const DateContainer = styled.span<{ isError: boolean }>`
  color: ${({ theme, isError }) => isError && theme.palette.state.error.pressed};
`;
