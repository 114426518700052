import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GlobalSearchService,
  MyKnownError,
  SearchCandidateResponse,
  SearchRequestResponse,
  SearchVacancyResponse,
} from '@innowise-group/core';
import { candidateResponseMapper, vacancyResponseMapper, requestsResponseMapper } from './search.mappers';

export const searchCandidatesThunk = createAsyncThunk<
  ReturnType<typeof candidateResponseMapper>,
  Omit<Parameters<typeof GlobalSearchService.search>, 'indexType'>[number],
  { rejectValue: MyKnownError }
>('globalSearch/candidates', async (args, { rejectWithValue, getState }) => {
  try {
    const rootState = getState() as any;
    const { activeXrayMode: isActiveXrayMode } = rootState.search;
    const searchType: Parameters<typeof GlobalSearchService.search>[number]['searchType'] = isActiveXrayMode
      ? 'X_RAY'
      : 'GLOBAL_SEARCH';
    const response = await GlobalSearchService.search<SearchCandidateResponse>({
      ...args,
      searchType,
      indexType: 'CANDIDATE',
    });
    return candidateResponseMapper(response.data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchVacanciesThunk = createAsyncThunk<
  ReturnType<typeof vacancyResponseMapper>,
  Omit<Parameters<typeof GlobalSearchService.search>, 'indexType'>[number],
  { rejectValue: MyKnownError }
>('globalSearch/vacancies', async (args, { rejectWithValue, getState }) => {
  try {
    const rootState = getState() as any;
    const { activeXrayMode: isActiveXrayMode } = rootState.search;
    const searchType: Parameters<typeof GlobalSearchService.search>[number]['searchType'] = isActiveXrayMode
      ? 'X_RAY'
      : 'GLOBAL_SEARCH';
    const response = await GlobalSearchService.search<SearchVacancyResponse>({
      ...args,
      searchType,
      indexType: 'VACANCY',
    });
    return vacancyResponseMapper(response.data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchRequestsThunk = createAsyncThunk<
  ReturnType<typeof requestsResponseMapper>,
  Omit<Parameters<typeof GlobalSearchService.search>, 'indexType' | 'searchType'>[number],
  { rejectValue: MyKnownError }
>('globalSearch/requests', async (args, { rejectWithValue, getState }) => {
  try {
    const rootState = getState() as any;
    const { activeXrayMode: isActiveXrayMode } = rootState.search;
    const searchType: Parameters<typeof GlobalSearchService.search>[number]['searchType'] = isActiveXrayMode
      ? 'X_RAY'
      : 'GLOBAL_SEARCH';
    const response = await GlobalSearchService.search<SearchRequestResponse>({
      ...args,
      searchType,
      indexType: 'VACANCY_CLAIM',
    });
    return requestsResponseMapper(response.data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
