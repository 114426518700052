import { styled } from '@innowise-group/mui-kit';
import { Menu } from '@mui/material';

export const BaseMenu = styled(Menu)`
  &.MuiMenu-root {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      z-index: 2000;
    }
  }
`;
