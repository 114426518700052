import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationSchemaExportReferral = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    const minDate = new Date();
    minDate.setDate(1);
    minDate.setHours(0, 0, 0, 0);
    minDate.setFullYear(minDate.getFullYear() - 1);
    const maxDate = new Date();
    maxDate.setDate(-1);
    return Yup.object().shape({
      fromDate: Yup.date()
        .required(t('errors.requiredField'))
        .min(minDate, t('errors.invalidDate'))
        .max(maxDate, t('errors.invalidDate')),
      toDate: Yup.date()
        .required(t('errors.requiredField'))
        .min(minDate, t('errors.invalidDate'))
        .max(maxDate, t('errors.invalidDate'))
        .test('fromDate', t('errors.earlierStartDate'), function (toDate, context) {
          if (!toDate || !context.parent.fromDate) return true;
          const fromDate = new Date(context.parent.fromDate);
          return fromDate.getTime() <= new Date(toDate).getTime();
        }),
    });
  }, [t]);

  return { validationSchema };
};
