import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const Switch = styled(MuiSwitch)<SwitchProps>`
  &.MuiSwitch-root {
    padding: 0;
    width: 60px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[80]};
    background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      transform: scale(0.8);
    }
  }

  & .MuiSwitch-input {
    padding: 0;
    height: 30px;
  }

  & .MuiSwitch-switchBase {
    padding: 8px;
    top: -4px;
    left: -4px;
    color: ${({ theme }) => theme.palette.general.white} !important;
    &.Mui-checked {
      right: 4px;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.general.smart};
    opacity: 1;
  }

  & .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: ${({ checked }) => (checked ? '1' : '0')};
  }

  & .MuiSwitch-track {
    opacity: 0;
  }
`;
