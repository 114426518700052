import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@innowise-group/mui-kit';
import { FiltersEntity, FiltersTypes, useGestureDetection } from '@innowise-group/core';
import { FiltersProps } from '../../filters.types';
import {
  ActivityPlaceFilter,
  BooleanFilter,
  DateRangeFilter,
  MultiSelectFilter,
  NestedSelectFilter,
  NumberRangeFilter,
  SalaryFilter,
  VacancyFilter,
  EmployeeFilter,
  FilterLabel,
  DateSegmentFilter,
} from './components';
import * as Styled from './mobile-view.styles';

const DENIED_FILTERS_TYPE = [FiltersTypes.UnpinnedSearch, FiltersTypes.Text];

const MobileView: FC<FiltersProps> = ({
  removeFilter,
  onResetFilters,
  onSubmitFilter,
  onFilterChange,
  addFilter,
  filters,
}) => {
  const { t } = useTranslation();
  const swipeRef = useRef(null);
  const mainContentContainerRef = useRef();
  const disabledToSwipeElementRef = useRef<HTMLDivElement>();
  const { isRightSwipe: isRightSwipeOnOpenedFilter, touchCoordinatesStart } = useGestureDetection(swipeRef, 70);
  const { isRightSwipe: isRightSwipeOnMainContentContainer } = useGestureDetection(mainContentContainerRef, 70);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openedFilter, setOpenedFilter] = useState('');

  useEffect(() => {
    if (isRightSwipeOnOpenedFilter) {
      if (disabledToSwipeElementRef.current) {
        const { clientX, clientY } = touchCoordinatesStart;
        const { top, bottom, left, right } = disabledToSwipeElementRef.current.getBoundingClientRect();
        const isInYRange = clientY > top && clientY < bottom;
        const isInXRange = clientX > left && clientX < right;
        if (!(isInYRange && isInXRange)) {
          setOpenedFilter('');
        }
      } else {
        setOpenedFilter('');
      }
    }
  }, [isRightSwipeOnOpenedFilter]);

  useEffect(() => {
    if (isRightSwipeOnMainContentContainer) {
      closeMenu();
    }
  }, [isRightSwipeOnMainContentContainer]);

  const filterMap = {
    [FiltersTypes.Money]: SalaryFilter,
    [FiltersTypes.Select]: MultiSelectFilter,
    [FiltersTypes.Vacancy]: VacancyFilter,
    [FiltersTypes.Boolean]: BooleanFilter,
    [FiltersTypes.Employee]: EmployeeFilter,
    [FiltersTypes.DateRange]: DateRangeFilter,
    [FiltersTypes.DateSegment]: DateSegmentFilter,
    [FiltersTypes.NumberRange]: NumberRangeFilter,
    [FiltersTypes.NestedSelect]: NestedSelectFilter,
    [FiltersTypes.ActivityPlace]: ActivityPlaceFilter,
  };

  const filter = filters[openedFilter];
  const filterData = filter || {};
  const filterProps = filters[filter?.id]?.filterProps || {};
  const FilterComponent = filterMap[filter?.type] || null;

  const closeMenu = () => {
    setOpenMenu(false);
    setOpenedFilter('');
  };

  const goBack = () => {
    if (openedFilter) return setOpenedFilter('');
    return setOpenMenu(false);
  };

  const onSubmitFiltersHandler = () => {
    onSubmitFilter();
    closeMenu();
  };

  const onResetFiltersHandler = () => {
    onResetFilters();
    setOpenedFilter('');
    setOpenMenu(false);
  };

  const onFilterChangeHandler = (filter: string) => (updatedState: FiltersEntity[keyof FiltersEntity]) =>
    onFilterChange({
      updatedState,
      filter,
    });

  const onFilterClick = (name: string) => () => setOpenedFilter(name);

  const openFilters = () => setOpenMenu(true);

  return (
    <Styled.Wrapper>
      <Styled.ToggleIcon viewBox="-1 -1 20 20" size={26} type="u_filters" onClick={openFilters} />
      <Styled.MobileBottomMenu
        disableCloseOnSwipe
        open={openMenu}
        goBack={goBack}
        closeMenu={closeMenu}
        onAccept={onSubmitFiltersHandler}
        onCancel={onResetFiltersHandler}
        acceptButtonTitle={t('buttons.apply')}
        cancelButtonTitle={t('buttons.reset')}
      >
        <Styled.ContentContainer ref={mainContentContainerRef}>
          <Styled.FiltersListContainer>
            {Object.entries(filters)?.map(([key, filterData]) => {
              if (DENIED_FILTERS_TYPE.includes(filterData.type)) return null;
              return (
                <Styled.FilterButton
                  onClick={onFilterClick(key)}
                  variant="text"
                  key={key}
                  endIcon={<Icon type="u_angle-right-b" />}
                >
                  <FilterLabel {...filterData} />
                </Styled.FilterButton>
              );
            })}
          </Styled.FiltersListContainer>
        </Styled.ContentContainer>
        <Styled.CustomSlide ref={swipeRef} direction="left" in={!!openedFilter}>
          <div>
            {FilterComponent && (
              <FilterComponent
                {...filterProps}
                {...filterData}
                {...((filterData.type === FiltersTypes.NumberRange || filterData.type === FiltersTypes.Money) && {
                  ref: disabledToSwipeElementRef,
                })}
                setOpenedFilter={setOpenedFilter}
                removeFilter={removeFilter}
                addFilter={addFilter}
                onValueChange={onFilterChangeHandler(openedFilter)}
              />
            )}
          </div>
        </Styled.CustomSlide>
      </Styled.MobileBottomMenu>
    </Styled.Wrapper>
  );
};

export default memo(MobileView);
