import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const MIN_TELEGRAM_CHARACTERS = 6;
const MAX_TELEGRAM_CHARACTERS = 33;

export const useValidationSchemaTelegram = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      Yup.string()
        .trim()
        .max(MAX_TELEGRAM_CHARACTERS, t('errors.maxCharacters', { count: MAX_TELEGRAM_CHARACTERS }))
        .transform((value) => value || '')
        .matches(/(^$)|(^(@[a-zA-Z-\d_]+$))/gi, t('errors.invalidFormat'))
        .test('minLength', t('errors.minCharacters', { count: MIN_TELEGRAM_CHARACTERS }), function (val) {
          if (val && val.length < MIN_TELEGRAM_CHARACTERS) return false;
          return true;
        }),
    [t],
  );
};
