import { Button, Loader, Divider as BaseDivider, styled, Icon, css } from '@innowise-group/mui-kit';
import { ContactsFieldsDiff, PrimitiveFieldsDiff, LinksDataDiff } from '../../../diff-variants';

export const DeleteLinkContainer = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const AddButton = styled(Button)`
  grid-column: 4;
  justify-self: end;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
  &.MuiButton-root {
    position: absolute;
    top: -22px;
    right: 0;
  }
  &.MuiButton-text {
    padding: 6px 8px !important;
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const PlusIcon = styled(Icon)`
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const Divider = styled(BaseDivider)`
  grid-column: 1 / span 4;
`;

export const FilesContainer = styled.div<{ column: number }>`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  align-items: stretch;
  gap: 0.5rem 2rem;
  grid-auto-flow: column;
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  status: 'default' | 'limit';
  columnStart: number;
}>`
  grid-column: ${({ columnStart }) => columnStart || 1} / -1;
  grid-row: ${({ columnStart }) => (!columnStart ? '1 / -1' : columnStart > 1 ? '1 / -1' : '6 / span 1')};
  flex: 1;
  min-height: 10rem;
  border: 1px dashed
    ${({ theme, disabled, status }) => {
      if (disabled) {
        return theme.palette.general.darkGrey[40];
      }
      if (status === 'default') {
        return theme.palette.info.pressed;
      }
      if (status === 'limit') {
        return theme.palette.general.darkGrey[60];
      }
    }};
  background-color: ${({ theme, status }) => {
    if (status === 'default') {
      return theme.palette.info.backgroundSecondary;
    }
    if (status === 'limit') {
      return theme.palette.general.lightGrey[20];
    }
  }};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
  transition: 0.2s;
  cursor: ${({ disabled }) => disabled && 'default'};

  & * {
    color: ${({ theme, disabled }) => disabled && `${theme.palette.general.darkGrey[60]} !important`};
    cursor: ${({ disabled }) => disabled && 'default !important'};
  }

  &:hover,
  &.dragover {
    background-color: ${({ theme, disabled, status }) => {
      if (status === 'limit') {
        return theme.palette.general.lightGrey[20];
      }
      if (disabled) {
        return 'transparent';
      }
      if (status === 'default') {
        return theme.palette.info.background;
      }
    }};
  }
`;

export const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
`;

export const FileItem = styled.div<{ disabled?: boolean; withPadding?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  border-radius: 0 0.5rem 0.5rem 0;
  flex: 1;
  width: 100%;
  min-width: 0;
  overflow-x: hidden;
  ${({ theme }) => theme.fonts.primary[60]}
  background-color: ${({ theme }) => theme.palette.info.backgroundSecondary};
  color: ${({ theme }) => theme.palette.info.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding: 0.875rem;
  padding-right: ${({ withPadding }) => (withPadding ? '3.25rem' : '1.5rem')};
  box-sizing: border-box;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1.0')};

  & span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scaleX(0.6) translateX(-40%);
    content: '';
    height: 100%;
    width: 0.5rem;
    background-color: ${({ theme }) => theme.palette.info.default};
    border-radius: 0px 3rem 3rem 0px;
  }
`;

export const IconsContainer = styled.div`
  position: absolute;
  top: 11px;
  right: 0px;
  display: flex;
  align-items: center;
  gap: 1px;
`;

export const LinkIcon = styled(Icon)`
  position: relative;
  left: 3px;
  & > svg,
  &:hover > svg {
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.info.default};
  }
`;

export const DeleteFileIcon = styled(LinkIcon)`
  left: 0;
  & > svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
    transform: rotate(45deg);
    transition: 0.2s;
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.hover};
  }
`;

export const FileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const FileButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  ${({ theme }) => theme.fonts.primary[60]}
  position: absolute;
  top: 13px;
  right: 0;
  & button {
    width: fit-content;
    padding: 0;
    & > div {
      margin: 0;
    }
  }
  & svg {
    flex-shrink: 0;
    transform: rotate(45deg);
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.state.error.default};
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;

export const FileItemLoader = styled(Loader)`
  margin: 1px 7px;
`;

export const FileInputPlaceholder = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  white-space: pre-line;
`;

export const FileInputBlockDefault = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[60]};
`;
export const DefaultTitle = styled.span`
  ${({ theme }) => theme.fonts.primary[80]};
  color: ${({ theme }) => theme.palette.info.default};
`;
export const DefaultHightLighted = styled.span`
  color: ${({ theme }) => theme.palette.info.default};
`;

export const FileInputBlockLimit = styled(FileInputBlockDefault)`
  text-align: start;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;
export const LimitTitle = styled.span`
  ${({ theme }) => theme.fonts.primary[80]};
`;
export const LimitDescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${({ theme }) => theme.fonts.primary[60]};
`;
export const LimitDescriptionText = styled.span`
  ${({ theme }) => theme.fonts.primary[20]};
`;

export const BoxForDiffConsolidation = styled.div`
  position: relative;
  display: grid;
`;

export const BoxForLinksDiffConsolidation = styled(BoxForDiffConsolidation)<{ $fullWidth: boolean }>`
  gap: 1.75rem;
  ${({ $fullWidth }) => ({
    gridColumn: $fullWidth ? '1/-1' : '1/3',
    gridTemplateColumns: $fullWidth ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',
  })}
`;

const commonDiffElementsPosition = css`
  top: 15px;
`;

export const CustomContactsFieldsDiff = styled(ContactsFieldsDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomPrimitiveFieldsDiff = styled(PrimitiveFieldsDiff)`
  ${commonDiffElementsPosition}
`;

export const CustomLinksDataDiff = styled(LinksDataDiff)`
  ${commonDiffElementsPosition}
`;
