import { SelectOption, SourcingItem, useOptionsApi } from '@innowise-group/core';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './sourcing-form.styles';
import { AvatarItem, Input, MenuItem } from '@innowise-group/mui-kit';

interface SourcingFormProps {
  isLoading: boolean;
  isEdit?: boolean;
}

const SourcingForm: React.FC<SourcingFormProps> = ({ isLoading, isEdit }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<SourcingItem>();
  const { statusAuthorsOptions: rolesEmployeesOptions, getStatusAuthorsByRoles, employeesLoading } = useOptionsApi();

  const renderEmployeeListItem = (props, value: SelectOption, { selected }) => {
    return (
      <MenuItem key={value.value} {...props} selected={selected}>
        <AvatarItem photoId={value.miniPhotoUrl} name={value.title} isOption />
      </MenuItem>
    );
  };

  useEffect(() => {
    getStatusAuthorsByRoles();
  }, []);

  return (
    <React.Fragment>
      <Controller
        name="employeeId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Styled.EmployeeSelect
              value={value}
              disabled={isLoading || isEdit}
              onChange={onChange}
              disableClearable={!value}
              options={rolesEmployeesOptions || []}
              renderOption={renderEmployeeListItem}
              loading={employeesLoading}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    error={!!errors?.employeeId?.message}
                    helperText={errors?.employeeId?.message.toString()}
                    required
                    label={t('pages.candidates.comments.employee')}
                    placeholder={t('pages.candidates.candidateCreation.selectTheEmployee')}
                  />
                );
              }}
            />
          );
        }}
      />
      <Controller
        name="requiredNumberOfEvents"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Input
            value={value}
            onChange={onChange}
            disabled={isLoading}
            required
            placeholder={t('pages.candidates.candidateCreation.enterAValue')}
            error={!!errors?.requiredNumberOfEvents?.message}
            helperText={errors?.requiredNumberOfEvents?.message?.toString()}
          />
        )}
      />
      {isLoading ? <Styled.Loader size={20} /> : <div />}
      <div />
    </React.Fragment>
  );
};

export default SourcingForm;
