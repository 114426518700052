import {
  FiltersTypes,
  FiltersUnion,
  SelectFilterType,
  EmployeesService,
  VacanciesService,
  NestedSelectFilterType,
} from '@innowise-group/core';
import { localizedNameObject } from '@innowise-group/utilities';
import i18n from '@localization';

const { t } = i18n;

const findSelectedEntitiesInMultiSelect = (state: SelectFilterType['state'] | NestedSelectFilterType['state']) =>
  Object.values(state).reduce((acc, { title, active }) => (active ? [...acc, title] : acc), []);

const getEmployeeNameById = async (id: number) => {
  const { data } = await EmployeesService.getEmployeeById(id);
  return localizedNameObject(data).localizedFullName;
};

const getVacancyById = async (id: number) => {
  const { data } = await VacanciesService.getVacancyById(id.toString());
  return data.name;
};

export const generateFilterLabel = async ({
  state,
  type,
  labelKey,
  active,
}: FiltersUnion): Promise<{ title: string; value: string }> => {
  const title = t(labelKey);
  if (type === FiltersTypes.Select || type === FiltersTypes.NestedSelect) {
    const selectedItems = findSelectedEntitiesInMultiSelect(state);
    const value = selectedItems.length
      ? `${selectedItems[0]} ${selectedItems.length > 1 ? `+${selectedItems.length - 1}` : ''}`
      : '';
    return { title, value };
  }

  if (type === FiltersTypes.DateRange) {
    const value =
      state.from || state.to
        ? `${state?.from ? new Date(state?.from)?.toLocaleDateString() : t('filters.notSelected')} - ${
            state?.to ? new Date(state?.to)?.toLocaleDateString() : t('filters.notSelected')
          }`
        : '';
    return { title, value };
  }

  if (type === FiltersTypes.NumberRange) {
    return { title, value: active ? `${state.from} - ${state.to}` : '' };
  }

  if (type === FiltersTypes.Money) {
    return { title, value: active ? `${state.from} - ${state.to} ${state.currency}` : '' };
  }
  if (type === FiltersTypes.Boolean) {
    return { title, value: active ? (state.checked ? t('buttons.yes') : t('buttons.no')) : '' };
  }

  if (type === FiltersTypes.ActivityPlace) {
    return {
      title,
      value:
        state.profession || state.place
          ? `${state.place}${state.profession && state.place && '/'}${state.profession}`
          : '',
    };
  }

  if (type === FiltersTypes.Employee) {
    if (!state.length) return { title, value: '' };
    const [employeeId] = state;
    const value = await getEmployeeNameById(employeeId);
    return { title, value };
  }

  if (type === FiltersTypes.Vacancy) {
    if (!state.length) return { title, value: '' };
    const [vacancyId, ...rest] = state;
    const vacancy = await getVacancyById(vacancyId);
    const value = `${vacancy} ${rest.length ? `+${rest.length}` : ''}`;
    return { title, value };
  }
  if (type === FiltersTypes.DateSegment) {
    const value = state.date
      ? `${t(`filters.union.${state.currentSegment.toLowerCase()}`)} ${new Date(state.date).toLocaleDateString(
          undefined,
          { month: '2-digit', year: 'numeric' },
        )}`
      : '';
    return { title, value };
  }
  throw new Error(`Unexpected filter type: ${type}`);
};
