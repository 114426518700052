import { FC, memo, forwardRef } from 'react';
import { TextFieldProps } from '@mui/material';
import { Input, SelectProps } from '@innowise-group/mui-kit';
import { Select } from './select.styles';

export interface SelectFilterProps
  extends Pick<TextFieldProps, 'label' | 'placeholder' | 'helperText' | 'error' | 'disabled'>,
    Pick<
      SelectProps,
      'value' | 'options' | 'onClose' | 'onChange' | 'getOptionDisabled' | 'disableSortByAlphabet' | 'loading'
    > {
  onSearch: TextFieldProps['onChange'];
}

const SelectFilter: FC<SelectFilterProps> = forwardRef(
  (
    {
      onClose,
      onChange,
      onSearch,
      value,
      options,
      disabled,
      disableSortByAlphabet = true,
      loading = false,
      getOptionDisabled,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <Select
        multiple
        value={value}
        options={options}
        disableSortByAlphabet={disableSortByAlphabet}
        disabled={disabled}
        loading={loading}
        disableCloseOnSelect
        limitTags={1}
        onClose={onClose}
        onChange={onChange}
        getOptionDisabled={getOptionDisabled}
        renderInput={(params) => <Input {...params} {...inputProps} inputRef={ref} onChange={onSearch} />}
      />
    );
  },
);

export default memo(SelectFilter);
