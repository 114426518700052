import { Input } from '@innowise-group/mui-kit';
import { Icon as BaseIcon } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const FullInput = styled(Input)`
  &.MuiTextField-root {
    width: 100%;
  }
`;

export const LabeledCheckboxContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 1.0625rem;
`;

export const Icon = styled(BaseIcon)``;
export const DeleteIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    transition: 0.2s;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: stretch;
`;

export const FieldsContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 5px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const VacancyContainer = styled.div`
  flex: 2;
`;
export const DoubleContainer = styled(VacancyContainer)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StatusContainer = styled.div`
  flex: 1;
`;

export const CommentContainer = styled(StatusContainer)``;
export const GradeContainer = styled(StatusContainer)``;
export const ProfessionContainer = styled(StatusContainer)``;
export const LanguageContainer = styled(StatusContainer)``;
export const LanguageLevelContainer = styled(StatusContainer)``;
export const LocationContainer = styled(StatusContainer)``;
export const DomainContainer = styled(StatusContainer)``;
export const TechnologyContainer = styled(StatusContainer)``;

export const ToolsContainer = styled(StatusContainer)`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 1.0625rem;
`;

export const PublishCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
