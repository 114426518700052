import { ReportButtonProps, ReportTypeUnion, ReportUnion } from './components';
import type { ReportFormValues } from './report-manager.types';

export const reportIcons: ReportButtonProps['icons'] = {
  primary: {
    type: 'u_line_chart',
    viewBox: '-4 -4 23 23',
  },
  secondary: {
    type: 'u_table',
    viewBox: '-4 -4 23 23',
  },
};

export const chartReportIcons: ReportButtonProps['icons'] = {
  primary: {
    type: 'u_bar_chart',
    viewBox: '3 2 20 20',
  },
  secondary: {
    type: 'u_bar_lines',
    viewBox: '3 2 20 20',
  },
};

export const tableReportIcons: ReportButtonProps['icons'] = {
  primary: {
    type: 'u_arrow_right',
    viewBox: '-4 -6 23 23',
  },
  secondary: {
    type: 'u_arrow_down',
    viewBox: '-4 -4 23 23',
  },
};

const endMonthDate = new Date();
endMonthDate.setDate(1);
endMonthDate.setMonth(endMonthDate.getMonth() + 1);

const startDate = new Date(new Date().setDate(1));
const endDate = new Date().getTime() < endMonthDate.getTime() ? new Date() : endMonthDate;

export const defaultValues: ReportFormValues = {
  report: ReportUnion.Chart,
  reportType: ReportTypeUnion.Linear,
  dateMin: startDate,
  dateMax: endDate,
  vacancyClaimAuthors: [],
  vacancyClaims: [],
  candidateResponsible: [],
  sources: [],
  statuses: [],
  statusAuthors: [],
  vacancies: [],
};
