import { css, styled } from '@innowise-group/mui-kit';
import { Icon } from '../../../icon';
import { Typography } from '../../../typography';

export const Select = styled.div``;

export const DropDownSearchInput = styled.input`
  flex: 1;
  outline: none;
  border: none;
  ${({ theme }) => theme.fonts.primary[20]}
  margin: 0;
  height: 100%;
  max-width: 100%;
  background-color: transparent;
  color: ${({ theme, disabled, readOnly }) =>
    disabled || readOnly ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[100]};
  cursor: ${({ disabled, readOnly }) => (disabled || readOnly) && 'default'};
`;

export const ListItemContainer = styled.div`
  display: flex;
`;

export const Placeholder = styled(Typography)`
  color: ${({ theme }) => theme.palette.general.lightGrey[100]};
`;

export const ClearButton = styled.div`
  position: absolute;
  right: 5px;
`;

export const AngleButton = styled(ClearButton)``;

export const ExpandIcon = styled(Icon)<{ isOpen: boolean }>`
  position: absolute;
  right: 5px;
  transition: ${({ theme }) => theme.animation.transition};
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InputCheckbox = styled.input`
  accent-color: ${({ theme }) => theme.palette.general.smart};
`;

export const ListItem = styled.li<{
  readonly?: boolean;
  selected?: boolean;
  noPadding?: boolean;
  isHeader?: boolean;
  redItemColor?: boolean;
  redItemBackground?: boolean;
}>`
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 9px 0 10px')};
  align-items: center;
  min-height: 30px;
  max-height: 55px;
  width: 100%;
  font-size: ${({ isHeader }) => (isHeader ? '0.75rem' : '0.875rem')};
  user-select: ${({ readonly = false }) => (readonly ? 'none' : 'initial')};
  color: ${({ theme, redItemColor }) =>
    redItemColor ? theme.palette.general.smart : theme.palette.general.darkGrey[100]};
  transition: 0.2s;
  overflow: hidden;

  ${({ selected, redItemBackground }) => {
    if (selected && redItemBackground) {
      return css`
        background-color: rgba(198, 48, 49, 0.08);
      `;
    }
    if (selected && !redItemBackground) {
      return css`
        background-color: ${({ theme }) => theme.palette.state.error.background};
      `;
    } else {
      return css`
        background-color: initial;
      `;
    }
  }}

  &:hover {
    cursor: ${({ readonly = false }) => (readonly ? 'default' : 'pointer')};
    background-color: ${({ theme, selected, readonly = false }) =>
      readonly ? 'inherit' : selected ? theme.palette.state.error.background : theme.palette.general.darkGrey[20]};
  }
  &:last-child {
    margin: 0;
  }

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;
