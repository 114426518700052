import { AppRoutes } from '@constants';
import { LocalizedMessage } from '@innowise-group/core';
import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Styled from './another-candidate-deleted-modal.styles';

interface AnotherCandidateDeletedModalProps {
  message: LocalizedMessage;
}

const AnotherCandidateDeletedModal: React.FC<ModalProps & AnotherCandidateDeletedModalProps> = ({ message, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCloseModal = () => {
    close();
    navigate(AppRoutes.Candidates);
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={onCloseModal}>
        <Styled.Header>{t('buttons.profileDelete')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>
          <Trans
            i18nKey="notifications.text.anotherUserDeleteCandidate"
            values={{ fullName: message.employee.localizedFullName }}
            components={{
              profile: <Styled.Author />,
            }}
          />
        </Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'center'}
        actions={[
          {
            title: t('buttons.ok'),
            onClick: onCloseModal,
            type: 'button',
            highlighted: true,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(AnotherCandidateDeletedModal, 'another-candidate-deleted');
