import { FC, memo } from 'react';
import { useTheme } from '@innowise-group/mui-kit';
import { useResizeObserver } from '@innowise-group/core';
import { DesktopView } from './desktop-view';
import { MobileView } from './mobile-view';

interface DateRangeFilterProps {
  minName: string;
  maxName: string;
  disabled: boolean;
}

const DateRangeFilter: FC<DateRangeFilterProps> = (props) => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const Component = isDesktopView ? DesktopView : MobileView;
  return <Component {...props} />;
};

export default memo(DateRangeFilter);
