import { createReducer } from '@reduxjs/toolkit';
import { preloadedState } from './notifications.preloaded-state';
import {
  changeTabAction,
  setNotificationAction,
  clearNotificationsAction,
  selectAllNotificationsAction,
  setPagableAction,
  setSortAction,
  selectReadNotificationsAction,
} from './notifications.actions';
import {
  changeMessagesPinStatusThunk,
  changeMessagesViewedStatusThunk,
  changePinnedNotificationsOrderThunk,
  deleteMessagesThunk,
  getMessageByIdThunk,
  getUnreadNotificationsCountThunk,
  searchAllNotificationsThunk,
} from './notifications.thunk';
import { getArrayIds, transformArrayToObject } from '@innowise-group/utilities';
import { logoutAction } from '../common';

export const notificationsReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(clearNotificationsAction, (state) => {
      const allCount = state.tabs.All.count;
      const calendarCount = state.tabs.CALENDAR.count;
      const mentionCount = state.tabs.MENTION.count;
      const requiresResponseCount = state.tabs.REQUIRES_RESPONSE.count;
      const vacancyClaimsCount = state.tabs.VACANCY_CLAIM.count;
      const responsibleCount = state.tabs.RESPONSIBLE.count;
      return {
        ...preloadedState,
        tabs: {
          ...preloadedState.tabs,
          All: {
            ...preloadedState.tabs.All,
            count: allCount,
          },
          CALENDAR: {
            ...preloadedState.tabs.CALENDAR,
            count: calendarCount,
          },
          MENTION: {
            ...preloadedState.tabs.MENTION,
            count: mentionCount,
          },
          REQUIRES_RESPONSE: {
            ...preloadedState.tabs.REQUIRES_RESPONSE,
            count: requiresResponseCount,
          },
          RESPONSIBLE: {
            ...preloadedState.tabs.RESPONSIBLE,
            count: responsibleCount,
          },
          VACANCY_CLAIM: {
            ...preloadedState.tabs.VACANCY_CLAIM,
            count: vacancyClaimsCount,
          },
        },
      };
    })

    .addCase(getUnreadNotificationsCountThunk.fulfilled, (state, { payload }) => {
      state.tabs.All.count = payload.All;
      state.tabs.CALENDAR.count = payload.CALENDAR;
      state.tabs.MENTION.count = payload.MENTION;
      state.tabs.REQUIRES_RESPONSE.count = payload.REQUIRES_RESPONSE;
      state.tabs.RESPONSIBLE.count = payload.RESPONSIBLE;
      state.tabs.VACANCY_CLAIM.count = payload.VACANCY_CLAIM;
    })

    .addCase(getMessageByIdThunk.pending, (state) => {
      state.isLoading = false;
    })
    .addCase(getMessageByIdThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tabs.All.count += 1;
      state.tabs.CALENDAR.count = payload.unreadNotificationCount.CALENDAR || 0;
      state.tabs.MENTION.count = payload.unreadNotificationCount.MENTION || 0;
      state.tabs.RESPONSIBLE.count = payload.unreadNotificationCount.RESPONSIBLE || 0;
      state.tabs.REQUIRES_RESPONSE.count = payload.unreadNotificationCount.REQUIRES_RESPONSE || 0;
      state.tabs.VACANCY_CLAIM.count = payload.unreadNotificationCount.VACANCY_CLAIM || 0;
    })
    .addCase(getMessageByIdThunk.rejected, (state) => {
      state.isLoading = false;
    })

    .addCase(changeTabAction, (state, { payload }) => {
      state.currentTab = payload;
      state.pagable.currentPage = 1;
    })
    .addCase(setPagableAction, (state, { payload }) => {
      if (payload?.currentPage) state.pagable.currentPage = payload.currentPage;
      if (payload?.pageSize) state.pagable.pageSize = payload.pageSize;
    })
    .addCase(selectAllNotificationsAction, (state, { payload: select }) => {
      state.selectedNotifications = select ? state.ids : preloadedState.selectedNotifications;
    })
    .addCase(selectReadNotificationsAction, (state, { payload: viewed }) => {
      const notificationsIds = state.ids.filter((id) => state.items[id].viewed === viewed);
      state.selectedNotifications = notificationsIds;
    })
    .addCase(setNotificationAction, (state, { payload: id }) => {
      state.selectedNotifications = state.selectedNotifications.includes(id)
        ? state.selectedNotifications.filter((selectedId) => selectedId !== id)
        : [...state.selectedNotifications, id];
    })
    .addCase(setSortAction, (state, { payload }) => {
      state.sort = payload;
    })
    .addCase(searchAllNotificationsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(searchAllNotificationsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ids = getArrayIds(payload.content);
      state.unsavedIds = getArrayIds(payload.content);
      state.items = transformArrayToObject(payload.content);
      state.totalElements = payload.totalElements;
      state.totalPages = payload.totalPages;
      if (state.pagable.currentPage > payload.totalPages) {
        state.pagable.currentPage = 1;
      }
    })
    .addCase(searchAllNotificationsThunk.rejected, (state) => {
      state.isLoading = false;
    })

    .addCase(deleteMessagesThunk.pending, (state) => {
      state.isProcessing = true;
    })
    .addCase(deleteMessagesThunk.fulfilled, (state, { payload }) => {
      state.isProcessing = false;
      state.selectedNotifications = state.selectedNotifications.filter((id) => !payload.includes(id));
    })
    .addCase(deleteMessagesThunk.rejected, (state) => {
      state.isProcessing = false;
    })

    .addCase(changeMessagesPinStatusThunk.pending, (state) => {
      state.isProcessing = true;
    })
    .addCase(changeMessagesPinStatusThunk.fulfilled, (state, { payload }) => {
      state.isProcessing = false;
      state.selectedNotifications = state.selectedNotifications.filter((id) => !payload.includes(id));
    })
    .addCase(changeMessagesPinStatusThunk.rejected, (state) => {
      state.isProcessing = false;
    })

    .addCase(changeMessagesViewedStatusThunk.pending, (state) => {
      state.isProcessing = true;
    })
    .addCase(changeMessagesViewedStatusThunk.fulfilled, (state, { meta }) => {
      state.isProcessing = false;
      state.selectedNotifications = preloadedState.selectedNotifications;
      meta.arg.notifications.forEach((id) => {
        state.items[id].viewed = meta.arg.newViewedValue;
      });
    })
    .addCase(changeMessagesViewedStatusThunk.rejected, (state) => {
      state.isProcessing = false;
    })

    .addCase(changePinnedNotificationsOrderThunk.pending, (state, { meta }) => {
      state.isProcessing = true;
      state.ids = [...meta.arg.pinnedIds, ...meta.arg.rest];
    })
    .addCase(changePinnedNotificationsOrderThunk.fulfilled, (state, { payload }) => {
      state.isProcessing = false;
      state.dndOrder = preloadedState.dndOrder;
      state.ids = payload;
      state.unsavedIds = payload;
    })
    .addCase(changePinnedNotificationsOrderThunk.rejected, (state) => {
      state.dndOrder = preloadedState.dndOrder;
      state.ids = state.unsavedIds;
      state.isProcessing = false;
    })
    .addCase(logoutAction, () => preloadedState),
);
