export enum WorkLoadsEnum {
  PartTime = 'PART_TIME',
  FullTime = 'FULL_TIME',
}

export enum WorkFormatsEnum {
  Office = 'OFFICE',
  Mixed = 'MIXED',
  Remote = 'REMOTE',
}

export enum EmploymentFormsEnum {
  Employee = 'EMPLOYEE',
  Contractor = 'CONTRACTOR',
  Entrepreneur = 'ENTREPRENEUR',
  Internship = 'INTERNSHIP',
}

export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum LanguageLevelEnum {
  AOne = 'A_ONE',
  ATwo = 'A_TWO',
  BOne = 'B_ONE',
  BTwo = 'B_TWO',
  COne = 'C_ONE',
  CTwo = 'C_TWO',
}

export const LanguageLevelValues = {
  [LanguageLevelEnum.AOne]: 'A1',
  [LanguageLevelEnum.ATwo]: 'A2',
  [LanguageLevelEnum.BOne]: 'B1',
  [LanguageLevelEnum.BTwo]: 'B2',
  [LanguageLevelEnum.COne]: 'C1',
  [LanguageLevelEnum.CTwo]: 'C2',
};

export enum GradeEnum {
  Trainee = 'TRAINEE',
  Intern = 'INTERN',
  JuniorMinus = 'JUNIOR_MINUS',
  Junior = 'JUNIOR',
  JuniorPlus = 'JUNIOR_PLUS',
  MiddleMinus = 'MIDDLE_MINUS',
  Middle = 'MIDDLE',
  MiddlePlus = 'MIDDLE_PLUS',
  SeniorMinus = 'SENIOR_MINUS',
  Senior = 'SENIOR',
  SeniorPlus = 'SENIOR_PLUS',
  Lead = 'LEAD',
  HEAD = 'HEAD',
  ARCHITECT = 'ARCHITECT',
}

export const GradeValues = {
  [GradeEnum.Trainee]: 'Trainee',
  [GradeEnum.Intern]: 'Intern',
  [GradeEnum.JuniorMinus]: 'Junior-',
  [GradeEnum.Junior]: 'Junior',
  [GradeEnum.JuniorPlus]: 'Junior+',
  [GradeEnum.MiddleMinus]: 'Middle-',
  [GradeEnum.Middle]: 'Middle',
  [GradeEnum.MiddlePlus]: 'Middle+',
  [GradeEnum.SeniorMinus]: 'Senior-',
  [GradeEnum.Senior]: 'Senior',
  [GradeEnum.SeniorPlus]: 'Senior+',
  [GradeEnum.Lead]: 'Lead',
  [GradeEnum.HEAD]: 'Head',
  [GradeEnum.ARCHITECT]: 'Architect',
};

export enum CurrencyEnum {
  'PLN' = 'PLN',
  'EUR' = 'EUR',
  'USD' = 'USD',
  'BYN' = 'BYN',
}

export const defaultCurrency = CurrencyEnum.USD;

export enum IntervalFiltersType {
  OverallExperience = 'OVERALL_EXPERIENCE',
  Salary = 'SALARY',
  BirthDate = 'BIRTH_DATE',
  ItExperience = 'IT_EXPERIENCE',
  Age = 'AGE',
}

export enum PurposeEnum {
  Employment = 'EMPLOYMENT',
  Trainee = 'TRAINEE',
  Null = 'NULL',
}

export enum LanguageEnum {
  Dutch = 'DUTCH',
  Irish = 'IRISH',
  Hindi = 'HINDI',
  Greek = 'GREEK',
  Czech = 'CZECH',
  Korean = 'KOREAN',
  Danish = 'DANISH',
  Arabic = 'ARABIC',
  French = 'FRENCH',
  Polish = 'POLISH',
  Slovac = 'SLOVAK',
  English = 'ENGLISH',
  Russian = 'RUSSIAN',
  Deutsch = 'DEUTSCH',
  Chinese = 'CHINESE',
  Spanish = 'SPANISH',
  Italian = 'ITALIAN',
  Latvian = 'LATVIAN',
  Maltese = 'MALTESE',
  Finnish = 'FINNISH',
  Swedish = 'SWEDISH',
  Estonian = 'ESTONIAN',
  Japanese = 'JAPANESE',
  Romanian = 'ROMANIAN',
  Croatian = 'CROATIAN',
  Bulgarian = 'BULGARIAN',
  Hungarian = 'HUNGARIAN',
  Ukrainian = 'UKRAINIAN',
  Slovenian = 'SLOVENIAN',
  Indonesian = 'INDONESIAN',
  Lithuanian = 'LITHUANIAN',
  Portuguese = 'PORTUGUESE',
  Belarusian = 'BELARUSIAN',
}

export enum RequestStatusEnum {
  HighPriority = 'HIGH_PRIORITY',
  MediumPriority = 'MEDIUM_PRIORITY',
  LowPriority = 'LOW_PRIORITY',
  Overdue = 'OVERDUE',
  Closed = 'CLOSED',
}

export enum OptionEntityEnum {
  Domain = 'DOMAIN',
  Technology = 'TECHNOLOGY',
  Profession = 'PROFESSION',
  VisaType = 'VISA_TYPE',
  JobTitle = 'JOB_TITLE',
  VacancyStatus = 'VACANCY_STATUS',
  CandidateSource = 'CANDIDATE_SOURCE',
  Office = 'OFFICE',
}

export const SelectOptionEntityName = {
  [OptionEntityEnum.Domain]: 'domainOptions',
  [OptionEntityEnum.Technology]: 'technologyOptions',
  [OptionEntityEnum.Profession]: 'professionOptions',
  [OptionEntityEnum.VisaType]: 'visaTypesOptions',
  [OptionEntityEnum.VacancyStatus]: 'vacancyStatus',
  [OptionEntityEnum.CandidateSource]: 'candidateSourceOptions',
  [OptionEntityEnum.Office]: 'officeOptions',
  [OptionEntityEnum.JobTitle]: 'jobTitleOptions',
};

export const GradeColors = {
  [GradeEnum.Trainee]: '#5D5D5D',
  [GradeEnum.Intern]: '#5D5D5D',
  [GradeEnum.JuniorMinus]: '#086862',
  [GradeEnum.Junior]: '#086862',
  [GradeEnum.JuniorPlus]: '#086862',
  [GradeEnum.MiddleMinus]: '#0B4174',
  [GradeEnum.Middle]: '#0B4174',
  [GradeEnum.MiddlePlus]: '#0B4174',
  [GradeEnum.SeniorMinus]: '#B14320',
  [GradeEnum.Senior]: '#B14320',
  [GradeEnum.SeniorPlus]: '#B14320',
  [GradeEnum.Lead]: '#9F2147',
};
