import React, { useEffect } from 'react';
import { EmployeeField } from '../employee-field';
import { useFormContext } from 'react-hook-form';
import { ClaimTypes } from '@constants';
import * as Styled from './notification-section.styles';
import { useResizeObserver } from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';

const NotificationSection: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { setValue, watch, resetField } = useFormContext();
  const statusId = watch('statusId');

  useEffect(() => {
    resetField('claims.1.isActive');
  }, [resetField, statusId]);

  useEffect(() => {
    setValue('claims.1.claimType', ClaimTypes.NOTIFICATION);
  }, [setValue]);

  return (
    <Styled.SectionRowContainer>
      {!isDesktopView && <Styled.Title>{t('pages.candidates.statuses.notification')}</Styled.Title>}
      <EmployeeField index={1} claimType="claims" />
    </Styled.SectionRowContainer>
  );
};

export default NotificationSection;
