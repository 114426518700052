import { Select, SelectProps, Divider } from '@innowise-group/mui-kit';
import React from 'react';
import { Paper } from '@mui/material';

interface SelectWithFooterProps extends SelectProps {
  footer: React.ReactNode;
}

const SelectWithFooter: React.FC<SelectWithFooterProps> = ({ footer, ...rest }) => {
  return (
    <Select
      {...rest}
      PaperComponent={(props) => {
        return (
          <Paper {...props}>
            {props.children}
            <Divider />
            <div
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {footer}
            </div>
          </Paper>
        );
      }}
    />
  );
};

export default SelectWithFooter;
