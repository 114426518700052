import { ThemeVariants } from '@constants';
import { createContext } from 'react';

export interface GlobalTheme {
  theme: ThemeVariants;
  themeSwitchHandler: (theme: ThemeVariants) => void;
}

const globalTheme: GlobalTheme = {
  theme: ThemeVariants.Light,
  themeSwitchHandler: () => {},
};

export const GlobalThemeContext = createContext<GlobalTheme>(globalTheme);
