import React from 'react';
import * as Styled from './mobile-view.styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LabeledCheckbox } from '@shared-mui-components';
import { EmployeeField } from '../../employee-field';
import { DateFormats } from '@constants';
import { InfoTooltip } from '../../../../info-tooltip';

interface MobileViewProps {
  isActive: boolean;
  handleChangeBooleanItem: (
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    fieldsName: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
  filterPassedTime: (time) => boolean;
}

const MobileView: React.FC<MobileViewProps> = ({ filterPassedTime, isActive, handleChangeBooleanItem }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Styled.SectionRowContainer>
      <Styled.RowContainer>
        <EmployeeField index={0} claimType="claims" />
      </Styled.RowContainer>
      <Styled.RowContainer>
        <Controller
          name="claims.0.isActive"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabeledCheckbox
              checked={value}
              onChange={handleChangeBooleanItem(onChange, 'claims')}
              label={t('pages.administration.statuses.feedback')}
            />
          )}
        />
        <Controller
          name="claims.0.deadline"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            return (
              <React.Fragment>
                <DatePicker
                  selected={value ? new Date(value) : null}
                  value={value}
                  onChange={onChange}
                  disabled={!isActive}
                  withTime
                  dateFormat={DateFormats.DayAndMonthWithHoursAndMinutes}
                  isConstrained={false}
                  filterTime={filterPassedTime}
                  startDate={new Date()}
                  minDate={new Date()}
                  minYear={new Date().getFullYear()}
                  label={t('pages.candidates.candidateCreation.date')}
                  placeholder={t('pages.candidates.candidateCreation.date')}
                  error={!!errors?.claims?.['0']?.deadline?.message}
                  helperText={errors?.claims?.['0']?.deadline?.message}
                />
                <InfoTooltip title={t('tooltips.statusCreationFeedback')} placement="top-end" />
              </React.Fragment>
            );
          }}
        />
      </Styled.RowContainer>
    </Styled.SectionRowContainer>
  );
};

export default MobileView;
