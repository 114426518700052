import { useState } from 'react';
import { ThemeVariants, LOCAL_STORAGE_THEME_KEY } from '@constants';
import { GlobalThemeContext } from './theme-context.component';

interface GlobalThemeContextProviderProps {
  children: React.ReactNode;
}

const defaultTheme = (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ThemeVariants) || ThemeVariants.Light;

const GlobalThemeContextProvider: React.FC<GlobalThemeContextProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeVariants>(defaultTheme);

  const themeSwitchHandler = (themeType: ThemeVariants) => {
    setCurrentTheme(themeType);
  };

  return (
    <GlobalThemeContext.Provider
      value={{
        theme: currentTheme,
        themeSwitchHandler,
      }}
    >
      {children}
    </GlobalThemeContext.Provider>
  );
};

export default GlobalThemeContextProvider;
