import { styled, css } from '@innowise-group/mui-kit';
import { Input as BaseInput, Icon } from '@innowise-group/mui-kit';
import { Image as BaseImage } from '@shared-mui-components';
import { AnimationWrapper } from '../shared';

const ContainerStyled = css`
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const Container = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin: -3px 0 -3px 5px;
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
  .MuiPaper-root {
    height: fit-content;
  }
`;

export const FilterWrapper = styled.div`
  ${ContainerStyled}
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-end;
  height: fit-content;
`;

export const Input = styled(BaseInput)`
  width: 225px;
`;

export const ListItemContiner = styled.div`
  ${ContainerStyled}
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Image = styled(BaseImage)`
  width: 43.75px;
  height: 43.75px;
  border-radius: 50%;
`;

export const ItemText = styled.span`
  margin-left: 10px;
  ${({ theme }) => theme.fonts.primary[30]}
`;

export const NoImageContainer = styled.div<{ isOption?: boolean }>`
  width: ${({ isOption }) => (isOption ? '35px' : '3.125rem')};
  flex-shrink: 0;
  height: ${({ isOption }) => (isOption ? '35px' : '3.125rem')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  margin: ${({ isOption }) => isOption && '3px 0'};
  justify-content: center;
`;

export const NoImageIcon = styled(Icon)`
  margin: 0;
`;
