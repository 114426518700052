import { Dispatch, SetStateAction, memo } from 'react';
import * as Styled from './boolean.styles';
import { BaseFilterType, BooleanFilterType, FiltersEntity } from '@innowise-group/core';
import { useTranslation } from 'react-i18next';
import { LabeledRadioButton } from '@shared-mui-components';
import { ResetButton } from '../reset-button';

export interface BooleanFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  id: string;
  state: BooleanFilterType['state'];
  onValueChange: (args: BooleanFilterType['state']) => void;
  onRemoveFilter: (state: BooleanFilterType['state']) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
  addFilter: (filter: string) => void;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
}

const BooleanFilter: React.FC<BooleanFilterProps> = ({
  id,
  state,
  labelKey,
  addFilter,
  removeFilter,
  onValueChange,
  setOpenedFilter,
}) => {
  const { t } = useTranslation();

  const onSubmitHandler = () => {
    addFilter(id);
    onValueChange({ ...state, checked: true });
  };
  const onDeclineHandler = () => {
    addFilter(id);
    onValueChange({ ...state, checked: false });
  };
  const onRemoveFilterHandler = () => {
    setOpenedFilter('');
    removeFilter({
      filter: id,
      updatedState: { checked: false } as unknown as Parameters<typeof removeFilter>[0]['updatedState'],
    });
  };

  return (
    <Styled.FilterWrapper>
      <Styled.Label>{t(labelKey)}</Styled.Label>
      <Styled.ControlsContainer>
        <LabeledRadioButton
          onChange={onSubmitHandler}
          checked={state.checked}
          value={state.checked.toString()}
          label={t('buttons.yes')}
        />
        <LabeledRadioButton
          onChange={onDeclineHandler}
          checked={!state.checked}
          value={(!state.checked).toString()}
          label={t('buttons.no')}
        />
      </Styled.ControlsContainer>
      <ResetButton onClick={onRemoveFilterHandler}>{t('filters.resetFilter')}</ResetButton>
    </Styled.FilterWrapper>
  );
};

export default memo(BooleanFilter);
