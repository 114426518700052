import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { RequestForm } from '@shared-components';
import * as Styled from './request-edition-modal.styles';
import {
  RequestItem,
  useDefaultRequestById,
  useRequestsAPI,
  useVacanciesAPI,
  useValidationSchemaRequest,
  useVacanciesOptionsApi,
  useResizeObserver,
} from '@innowise-group/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { requestFormDefaultValues } from '@innowise-group/modals';
import { useCallback, useEffect, useMemo } from 'react';
import { useTheme } from '@innowise-group/mui-kit';

interface RequestEditionModalProps {
  requestId: number;
  successEditionCallback?: () => void;
}

const RequestEditionModal: React.FC<ModalProps & RequestEditionModalProps> = ({
  close,
  requestId,
  successEditionCallback,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  const requestDefault = useDefaultRequestById();
  const { getVacanciesByIds } = useVacanciesOptionsApi();

  const { isLoading: requestsIsLoading, updateRequest } = useRequestsAPI();
  const { fetchAllVacancies, isLoading: vacanciesIsLoading } = useVacanciesAPI();

  const { validationSchema } = useValidationSchemaRequest();
  const methods = useForm<RequestItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: requestFormDefaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = useCallback((data: RequestItem) => {
    updateRequest(data, requestId, successCallback);
  }, []);

  const successCallback = () => {
    close();
    successEditionCallback?.();
  };

  useEffect(() => {
    fetchAllVacancies();
  }, [fetchAllVacancies]);

  useEffect(() => {
    if (requestDefault?.vacancyId) {
      getVacanciesByIds([requestDefault.vacancyId]);
    }
    reset(requestDefault);
  }, [requestDefault?.vacancyId]);

  const selectedAuthorsOptions = useMemo(() => {
    if (!requestDefault) return [];
    return requestDefault.authorId.map((item) => item.value);
  }, [requestDefault?.vacancyId]);

  return (
    <Modal.Container size="large">
      {requestsIsLoading || vacanciesIsLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={close}>
            <Styled.Header>
              <span>{t('pages.requests.creationModal.requestEdition')}</span>
            </Styled.Header>
          </Modal.Header>
          <Styled.Body>
            <RequestForm
              isEdit
              onSubmit={onSubmit}
              handleClose={close}
              selectedAuthorsOptions={selectedAuthorsOptions}
            />
          </Styled.Body>
          {isDesktopView && (
            <Styled.Footer>
              <Styled.CancelButton onClick={close} variant="outlined">
                {t('buttons.cancel')}
              </Styled.CancelButton>
              <Styled.SubmitButton onClick={handleSubmit(onSubmit)}>{t('buttons.save')}</Styled.SubmitButton>
            </Styled.Footer>
          )}
        </FormProvider>
      )}
    </Modal.Container>
  );
};

export default modalHoc(RequestEditionModal, 'request-edition');
