import { profileFormDefaultValues } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AuthService,
  ProfileItem,
  useClickOutside,
  useDefaultCreationProfileData,
  useProfileAPI,
  useValidationSchemaProfile,
} from '@innowise-group/core';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import { ProfileForm } from '@shared-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './profile-creation-modal.styles';
import { useCallback, useEffect, useState } from 'react';
import { notificationsManager } from '@innowise-group/mui-kit';

const ProfileCreationModal: React.FC<ModalProps> = ({ close }) => {
  const { t } = useTranslation();
  const profile = useDefaultCreationProfileData();
  const { profile: profileResponse, logout } = useProfileAPI();
  const {
    updateProfileData,
    orgUnits,
    isLoading,
    loadProfile,
    createAvatarPhoto,
    fetchHrmAvatarPhoto,
    clearProfileAvatar,
  } = useProfileAPI();
  const { validationSchema } = useValidationSchemaProfile();
  const methods = useForm<ProfileItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: profileFormDefaultValues,
  });
  const { handleSubmit, reset } = methods;
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [isSendRequest, setIsSendRequest] = useState<boolean>(false);

  const handleChangeIsImageLoading = useCallback((value: boolean) => {
    setIsImageLoading(value);
  }, []);

  const onSubmit = async (data: ProfileItem) => {
    setIsSendRequest(true);
    await updateProfileData(data, profile.id, profileResponse.authData, close);
    setIsSendRequest(false);
  };

  const onError = () => {
    notificationsManager.error({
      subtitle: t('pages.profile.creationModal.creationValidationError'),
    });
  };

  const handleCancel = () => {
    logout();
    AuthService.logout();
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    event?.stopPropagation();
  };

  const ref = useClickOutside<HTMLFormElement>(handleClose, true);

  useEffect(() => {
    reset({ ...profile, orgUnits });
    if (profile.miniPhotoUrl && 'url' in profile.miniPhotoUrl && !profileResponse.photoRt?.id) {
      fetchHrmAvatarPhoto(profile.miniPhotoUrl.url, createAvatarPhoto);
    }
    return () => {
      loadProfile();
    };
  }, [reset, loadProfile, clearProfileAvatar, profile.miniPhotoUrl?.id, profileResponse.photoRt, fetchHrmAvatarPhoto]);

  useEffect(() => {
    return () => {
      reset();
      clearProfileAvatar();
    };
  }, [clearProfileAvatar]);

  return (
    <Styled.Container ref={ref} size="standard">
      {isLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <Modal.Header onClose={handleCancel}>
            <Styled.Header>
              <span>{t('pages.profile.creationModal.creationProfile')}</span>
            </Styled.Header>
          </Modal.Header>
          <Modal.Body>
            <ProfileForm handleChangeIsImageLoading={handleChangeIsImageLoading} />
          </Modal.Body>
          <Modal.Actions
            noVerticalPadding={true}
            position={'right'}
            actions={[
              {
                title: t('buttons.cancel'),
                onClick: handleCancel,
                type: 'button',
                disabled: isSendRequest || isImageLoading,
              },
              {
                title: t('buttons.save'),
                onClick: handleSubmit(onSubmit, onError),
                highlighted: true,
                type: 'button',
                disabled: isSendRequest || isImageLoading,
              },
            ]}
          />
        </FormProvider>
      )}
    </Styled.Container>
  );
};

export default modalHoc(ProfileCreationModal, 'profile-creation');
