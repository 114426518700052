import { styled, Button as ButtonBase, MenuItem as MenuItemBase, Menu as MenuBase } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  position: absolute;
  align-self: center;
  border-top: 1px solid red;
  border-radius: 15px;
  width: 100%;
  bottom: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  &.MuiButton-root.MuiButton-text,
  &.MuiButton-root.MuiButton-text:hover {
    padding: 0 0.825rem;
    min-width: unset;
    top: -8px;
    background: ${({ theme }) => theme.palette.general.paper};
  }
`;

export const MenuItem = styled(MenuItemBase)`
  max-width: 70vw;
  max-height: 70vw;
  word-break: normal;
  &.MuiMenuItem-root {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    white-space: pre-line;
  }
`;

export const NameContainer = styled.p`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const CandidateLabel = styled.span`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.general.smart};
  ${({ theme }) => theme.fonts.primary[10]}
`;

export const NewVariantLabel = styled.span`
  color: ${({ theme }) => theme.palette.info.default};
`;

export const IgnoreMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.palette.general.smart};
    ${({ theme }) => theme.fonts.primary[30]};
  }
`;

export const Menu = styled(MenuBase)``;
