export const calculateDateWithTimeZone = (value: string | Date) => {
  const date = new Date(value);
  return date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

export const calculateDateWithoutTimeZone = (value: string | Date) => {
  const date = new Date(value);
  return date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

export const getTimeZoneMilliSeconds = () => {
  const date = new Date();
  return date.getTimezoneOffset() * -60000;
};
