import { primitiveFields, stringArrDataFields } from '@constants';
import { CandidateContacts, CandidateDuffMerge, FormattedCandidateDiffMerge } from './candidates.types';
import { CandidateChangesStatuses } from './candidates.types';
import { CandidatesService, DiffValidationService } from '@innowise-group/core';

const isObjectHasThisKey = (obj: object, key: string | number) =>
  obj ? Object.prototype.hasOwnProperty.call(obj, key) : false;
const isArray = (val: unknown): boolean => Array.isArray(val);
const isObject = (val: unknown): boolean => typeof val === 'object' && !isArray(val);
const isPrimitive = (val: unknown): boolean => !(isArray(val) || isObject(val));

export const getUpdatesStatus = (target: string, updates: string) => {
  if (target && updates) {
    if (target !== updates) {
      return CandidateChangesStatuses.Updated;
    }
    if (target === updates) {
      return CandidateChangesStatuses.Unchanged;
    }
  }
  if (!target && !updates) {
    return CandidateChangesStatuses.Unchanged;
  }
  if (!target && updates) {
    return CandidateChangesStatuses.Created;
  }
  if (target && !updates) {
    return CandidateChangesStatuses.Deleted;
  }
};

export const compareCandidates = ({ target, updates }): CandidateDuffMerge => {
  const blackListKeys = ['contactType', 'photo'];
  const result = {};
  if (!target && updates) return { ...updates, status: CandidateChangesStatuses.Created };
  if (target && !updates) return { ...target, status: CandidateChangesStatuses.Deleted };

  for (const key in target) result[key] = null;
  for (const key in updates) result[key] = null;

  for (const key in result) {
    if (blackListKeys.indexOf(key) !== -1) {
      result[key] = target?.[key] || updates?.[key];
      continue;
    }
    if (isObjectHasThisKey(target, key) && !isObjectHasThisKey(updates, key)) {
      result[key] = {
        target: target[key],
        updates: null,
        preselected: target[key],
        status: CandidateChangesStatuses.Deleted,
      };
      continue;
    }
    if (!isObjectHasThisKey(target, key) && isObjectHasThisKey(updates, key)) {
      result[key] = {
        target: null,
        updates: updates[key],
        preselected: null,
        status: CandidateChangesStatuses.Created,
      };
      continue;
    }
    if (isObjectHasThisKey(target, key) && isObjectHasThisKey(updates, key)) {
      if (isPrimitive(target[key]) || isPrimitive(updates[key])) {
        if (target[key] === updates[key]) {
          result[key] = {
            target: target[key],
            updates: updates[key],
            preselected: target[key],
            status: CandidateChangesStatuses.Unchanged,
          };
          continue;
        }
        if (target[key] !== updates[key] && !!target[key] && !!updates[key]) {
          result[key] = {
            target: target[key],
            updates: updates[key],
            preselected: target[key],
            status: CandidateChangesStatuses.Updated,
          };
          continue;
        }
        if (target[key] !== updates[key] && !target[key]) {
          result[key] = {
            target: target[key],
            updates: updates[key],
            preselected: target[key],
            status: CandidateChangesStatuses.Created,
          };
          continue;
        }
        if (target[key] !== updates[key] && !updates[key]) {
          result[key] = {
            target: target[key],
            updates: updates[key],
            preselected: target[key],
            status: CandidateChangesStatuses.Deleted,
          };
          continue;
        }
      }
      if (isArray(target[key]) || isArray(updates[key])) {
        result[key] = {
          target: target[key],
          updates: updates[key],
        };
        continue;
      }
    }
    result[key] = compareCandidates({
      target: target[key],
      updates: updates[key],
    });
  }
  return result as CandidateDuffMerge;
};

export const candidatesDiffsValidator = (diff: FormattedCandidateDiffMerge) => {
  const unresolvedConflicts = [];
  primitiveFields.forEach((key) => {
    const shouldShowAlert = DiffValidationService.validatePrimitiveFields({
      diff,
      field: key,
    });
    if (shouldShowAlert) {
      unresolvedConflicts.push(key);
    }
  });
  const shouldShowCandidateContactsAlert =
    diff?.candidateContacts &&
    Object.keys(diff.candidateContacts)
      ?.map((contact) => {
        return DiffValidationService.validateCandidateContacts(diff, contact as CandidateContacts);
      })
      .some((el) => !!el);
  if (shouldShowCandidateContactsAlert) unresolvedConflicts.push('candidateContacts');
  stringArrDataFields.forEach((field) => {
    const shouldShowAlert = DiffValidationService.validateStringArrDataField({
      diff,
      field,
    });
    if (shouldShowAlert) {
      unresolvedConflicts.push(field);
    }
  });
  const isShouldShowCandidatePrioritizedVacanciesAlert = DiffValidationService.validateStringArrDataField({
    diff,
    field: 'candidatePrioritizedVacancies',
  });
  if (isShouldShowCandidatePrioritizedVacanciesAlert) unresolvedConflicts.push('vacancy');
  const shouldShowCandidateVisasAlert = DiffValidationService.validateCandidateVisas(diff);
  if (shouldShowCandidateVisasAlert) unresolvedConflicts.push('candidateVisas');
  const shouldShowCandidateVisasAvailabilityAlert = DiffValidationService.validateCandidateVisaAvailability(diff);
  if (shouldShowCandidateVisasAvailabilityAlert) unresolvedConflicts.push('visaAvailability');

  const shouldShowIsBlockedAlert = DiffValidationService.validateIsBlocked(diff);
  if (shouldShowIsBlockedAlert) {
    unresolvedConflicts.push('isBlocked');
  }

  const isShouldShowLocationCountry = DiffValidationService.validateCandidateLocation(diff, 'locationCountry');
  const isShouldShowLocationCity = DiffValidationService.validateCandidateLocation(diff, 'locationCity');
  const isShouldShowCurrentLocationCountry = DiffValidationService.validateCandidateLocation(
    diff,
    'currentLocationCountry',
  );
  const isShouldShowCurrentLocationCity = DiffValidationService.validateCandidateLocation(diff, 'currentLocationCity');
  if (
    isShouldShowLocationCountry ||
    isShouldShowLocationCity ||
    isShouldShowCurrentLocationCountry ||
    isShouldShowCurrentLocationCity
  ) {
    unresolvedConflicts.push('candidateLocation');
  }

  const shouldRenderCandidateExpectedSalaryMin = DiffValidationService.validateCandidateSalary(
    diff,
    'expectedSalaryMin',
  );
  const shouldRenderCandidateExpectedSalaryMax = DiffValidationService.validateCandidateSalary(
    diff,
    'expectedSalaryMax',
  );
  const shouldRenderCandidateExpectedSalaryGroup = DiffValidationService.validateCandidateSalary(diff, 'salaryGroup');
  const shouldRenderCandidateExpectedSalaryComment = DiffValidationService.validateCandidateSalary(
    diff,
    'expectedSalaryComment',
  );
  if (
    shouldRenderCandidateExpectedSalaryComment ||
    shouldRenderCandidateExpectedSalaryMin ||
    shouldRenderCandidateExpectedSalaryMax ||
    shouldRenderCandidateExpectedSalaryGroup
  ) {
    unresolvedConflicts.push('candidateSalary');
  }

  const needToResolveCandidateExperienceMergeCommentField = diff?.candidateExperiences?.preselected?.reduce(
    (acc, _, index) => {
      if (
        DiffValidationService.validateMapItemField({
          diff,
          source: index,
          key: 'candidateExperiences',
        })
      )
        return true;
      return acc;
    },
    false,
  );
  const needToResolveCandidateExperienceTargetValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'target',
    key: 'candidateExperiences',
  });
  const needToResolveCandidateExperienceUpdatesValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'updates',
    key: 'candidateExperiences',
  });
  if (
    needToResolveCandidateExperienceMergeCommentField ||
    needToResolveCandidateExperienceTargetValues ||
    needToResolveCandidateExperienceUpdatesValues
  ) {
    unresolvedConflicts.push('candidateExperiences');
  }

  const needToResolveCandidateEducationsMergeCommentField = diff?.candidateEducations?.preselected?.reduce(
    (acc, _, index) => {
      if (
        DiffValidationService.validateMapItemField({
          diff,
          source: index,
          key: 'candidateEducations',
        })
      )
        return true;
      return acc;
    },
    false,
  );
  const needToResolveCandidateEducationsTargetValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'target',
    key: 'candidateEducations',
  });
  const needToResolveCandidateEducationsUpdatesValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'updates',
    key: 'candidateEducations',
  });
  if (
    needToResolveCandidateEducationsMergeCommentField ||
    needToResolveCandidateEducationsTargetValues ||
    needToResolveCandidateEducationsUpdatesValues
  ) {
    unresolvedConflicts.push('candidateEducations');
  }

  const needToResolveCandidateCoursesMergeCommentField = diff?.candidateCourses?.preselected?.reduce(
    (acc, _, index) => {
      if (
        DiffValidationService.validateMapItemField({
          diff,
          source: index,
          key: 'candidateCourses',
        })
      )
        return true;
      return acc;
    },
    false,
  );
  const needToResolveCandidateCoursesTargetValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'target',
    key: 'candidateCourses',
  });
  const needToResolveCandidateCoursesUpdatesValues = DiffValidationService.validateMapItemField({
    diff,
    source: 'updates',
    key: 'candidateCourses',
  });
  if (
    needToResolveCandidateCoursesMergeCommentField ||
    needToResolveCandidateCoursesTargetValues ||
    needToResolveCandidateCoursesUpdatesValues
  ) {
    unresolvedConflicts.push('candidateCourses');
  }

  const isNeedToResolveCandidateRelocationAvailability =
    DiffValidationService.validateCandidateRelocationAvailability(diff);
  if (isNeedToResolveCandidateRelocationAvailability) {
    unresolvedConflicts.push('relocationAvailability');
  }
  const isNeedToResolveCandidateRelocationCountries = DiffValidationService.validateCandidateRelocationCountries(diff);

  if (isNeedToResolveCandidateRelocationCountries) {
    unresolvedConflicts.push('candidateRelocationCountries');
  }

  const isNeedToResolveSource = diff?.source?.status === CandidateChangesStatuses.Updated;
  const isNeedToResolveSourceDetails = diff?.sourceDetails?.status === CandidateChangesStatuses.Updated;

  if (isNeedToResolveSource) unresolvedConflicts.push('source');
  if (isNeedToResolveSourceDetails) unresolvedConflicts.push('sourceDetails');

  return unresolvedConflicts;
};

export const candidatesExportDownloadFileJob = async (fileId: number) => {
  const { data: fileData } = await CandidatesService.candidatesExportGetFile(fileId);

  const data = URL.createObjectURL(fileData);
  const fileUrl = document.createElement('a');
  fileUrl.href = data;
  fileUrl.download = 'export-results.xlsx';
  document.body.appendChild(fileUrl);
  fileUrl.click();
  URL.revokeObjectURL(fileUrl.href);
};

export const candidatesExportDataGetProgressJob = async (jobId: number) => {
  const {
    data: { status: attemptJobStatus, fileId },
  } = await CandidatesService.candidatesExportGetStatus(jobId);

  switch (attemptJobStatus) {
    case 'PENDING':
    default:
      return null;
    case 'SUCCESS':
      return fileId;
    case 'FAILED':
      throw new Error("File can't be exported");
  }
};

export const candidatesExportRunJobWithDelay = async (callback: () => Promise<number | null>, delay: number) => {
  await new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

  return await callback();
};
