import { FormControlLabel } from '@mui/material';
import { styled } from '@innowise-group/mui-kit';

export const LabelContainer = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    gap: 3px;
  }
  .MuiFormControlLabel-label {
    ${({ theme }) => theme.fonts.primary[40]};
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;
