import { useRequestsAPI } from '@innowise-group/core';
import { Modal, modalHoc, ModalProps } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import * as Styled from './request-deletion-modal.styles';

interface RequestDeletionModalProps {
  id: number;
  requestCloseCallback: () => void;
}

const RequestDeletionModal: React.FC<ModalProps & RequestDeletionModalProps> = ({
  close,
  id,
  requestCloseCallback,
}) => {
  const { t } = useTranslation();
  const { deleteRequest, isLoading } = useRequestsAPI();

  const deleteRequestItem = async () => {
    await deleteRequest(id);
    await requestCloseCallback();
    close();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.ModalTitle>{t('pages.requests.deletionModal.requestDeletion')}</Styled.ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.requests.deletionModal.sureDelete')}?</Styled.Description>
      </Modal.Body>
      <Styled.Footer>
        <Styled.CancelButton onClick={close} variant="outlined">
          {t('buttons.cancel')}
        </Styled.CancelButton>
        <Styled.SubmitButton onClick={deleteRequestItem} disabled={isLoading}>
          {t('buttons.delete')}
        </Styled.SubmitButton>
      </Styled.Footer>
    </Modal.Container>
  );
};

export default modalHoc(RequestDeletionModal, 'request-deletion');
