import React, { memo, useCallback, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import type { Active, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SortableOverlay } from '../sortable-overlay';

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  ids: UniqueIdentifier[];
  onChange(items: T[]): void;
  renderItem(item: T): ReactNode;
}

const SortableList: FC<Props<any>> = ({ ids, onChange, renderItem }) => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => ids?.find((id) => id === active?.id), [active, ids]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragCancel = useCallback(() => setActive(null), [setActive]);
  const onDragStart = useCallback(({ active }: DragStartEvent) => setActive(active), [setActive]);
  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = ids.findIndex((id) => id === active.id);
        const overIndex = ids.findIndex((id) => id === over.id);
        onChange(arrayMove(ids, activeIndex, overIndex));
      }
      setActive(null);
    },
    [setActive, onChange, arrayMove, ids],
  );

  return (
    <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
      <SortableContext items={ids}>
        <div>
          {ids?.map((id) => (
            <React.Fragment key={id}>{renderItem(id)}</React.Fragment>
          ))}
        </div>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
    </DndContext>
  );
};

export default memo(SortableList);
