import { Icon, useTheme } from '@innowise-group/mui-kit';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './link-section.styles';
import { useTranslation } from 'react-i18next';
import { DateFormats } from '@constants';
import { format } from 'date-fns';
import { useResizeObserver } from '@innowise-group/core';

const LinkSection: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const {
    control,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [isOptionalField, setIsOptionalField] = useState<boolean>(false);
  const statusId = watch('statusId');
  const linkName = watch('link.name');

  const handleClick = () => {
    setIsOptionalField((prev) => !prev);
  };

  const handleChange = (onChange: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value);
    setValue(
      'link.name',
      event.currentTarget.value
        ? t('pages.candidates.statuses.testTask', { date: format(new Date(), DateFormats.DayFirst) })
        : '',
    );
  };

  useEffect(() => {
    resetField('link');
  }, [resetField, statusId]);

  useEffect(() => {
    if (linkName) {
      setIsOptionalField(true);
    }
  }, [linkName]);

  return isOptionalField || !isDesktopView ? (
    <Controller
      name="link.url"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <Styled.LinkInput
          label={t('pages.candidates.candidateCreation.link')}
          value={value}
          onChange={handleChange(onChange)}
          placeholder={t('pages.candidates.candidateCreation.link')}
          error={!!errors?.link?.['url']?.message}
          helperText={errors?.link?.['url']?.message}
        />
      )}
    />
  ) : (
    <Styled.AddButton onClick={handleClick} variant="text" required={!!errors?.link?.['url']?.message}>
      <Icon type="u_link" fill={theme.palette.state.error.pressed} />
      {t('pages.candidates.candidateCreation.addLink')}
    </Styled.AddButton>
  );
};

export default LinkSection;
