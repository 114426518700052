import { styled } from '@innowise-group/mui-kit';
import { MenuItem as MuiMenuItem } from '@mui/material';

export const MenuItem = styled(MuiMenuItem).withConfig({
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})`
  &.MuiMenuItem-root {
    pointer-events: ${({ disabled }) => disabled && 'none'};
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[100]};
    padding: 9px 16px;
    cursor: ${({ disabled }) => disabled && 'default'};
    background-color: ${({ theme, selected, disabled }) =>
      disabled
        ? theme.palette.general.lightGrey[40]
        : selected
        ? theme.palette.state.error.background
        : 'inherit'} !important;
    &:hover {
      background-color: ${({ theme, selected, disabled }) =>
        disabled
          ? theme.palette.general.lightGrey[40]
          : selected
          ? theme.palette.state.error.background
          : theme.palette.general.darkGrey[20]} !important;
    }
    ${({ theme, selected }) => (selected ? theme.fonts.primary[30] : theme.fonts.primary[20])};
  }
`;
