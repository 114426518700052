import { createReducer } from '@reduxjs/toolkit';
import { preloadedEmployeesState } from './employees.preloaded-state';
import {
  clearEmployeesAction,
  clearMentionedEmployeesAction,
  setMentionedEmployeesItemsAction,
} from './employees.actions';
import {
  addEmployeesForCandidateDiffMergeThunk,
  getEmployeeByIdThunk,
  getMentionedEmployeesThunk,
  searchEmployeesListThunk,
} from './employees.thunk';
import { getArrayIds, transformArrayToObject } from '@innowise-group/utilities';
import { EmployeeOption } from './employees.types';
import { logoutAction } from '../common';

export const employeesReducer = createReducer(preloadedEmployeesState, (builder) =>
  builder
    .addCase(searchEmployeesListThunk.fulfilled, (state, { payload }) => {
      state.employees.items = transformArrayToObject(payload);
      state.employees.ids = getArrayIds(payload);
      state.employees.isLoading = false;
    })
    .addCase(searchEmployeesListThunk.pending, (state) => {
      state.employees.isLoading = true;
    })
    .addCase(searchEmployeesListThunk.rejected, (state) => {
      state.employees.isLoading = false;
    })
    .addCase(setMentionedEmployeesItemsAction, (state, { payload }) => {
      state.mentionedOptions.items[payload.id] = payload;
      state.mentionedOptions.ids.push(payload.id);
    })
    .addCase(getMentionedEmployeesThunk.fulfilled, (state, { payload }) => {
      const mentionedOptions: { [key: string]: EmployeeOption } = {};
      for (const key in payload) {
        mentionedOptions[key] = {
          id: payload[key].employeeId,
          display: `${payload[key].firstNameEn} ${payload[key].lastNameEn}`,
          photoId: payload[key].photoRt?.id || 0,
        };
      }
      const newIds = Object.keys(payload).map((id) => parseInt(id));
      state.mentionedOptions = {
        ...state.mentionedOptions,
        items: { ...state.mentionedOptions.items, ...mentionedOptions },
        ids: [...new Set([...state.mentionedOptions.ids, ...newIds])],
      };
      state.mentionedEmployees.items = payload;
      state.mentionedEmployees.ids = Object.keys(payload).map((id) => parseInt(id));
      state.mentionedEmployees.isLoading = false;
    })
    .addCase(getMentionedEmployeesThunk.pending, (state) => {
      state.mentionedEmployees.isLoading = true;
    })
    .addCase(getMentionedEmployeesThunk.rejected, (state) => {
      state.mentionedEmployees.isLoading = false;
    })
    .addCase(getEmployeeByIdThunk.fulfilled, (state, { payload }) => {
      state.responsibleEmployee = payload;
      state.employees.isLoading = false;
    })
    .addCase(getEmployeeByIdThunk.pending, (state) => {
      state.employees.isLoading = true;
    })
    .addCase(getEmployeeByIdThunk.rejected, (state) => {
      state.responsibleEmployee = preloadedEmployeesState.responsibleEmployee;
      state.employees.isLoading = false;
    })
    .addCase(addEmployeesForCandidateDiffMergeThunk.fulfilled, (state, { payload }) => {
      if (!state?.employeesForCandidateDiffMerge?.ids?.includes(payload.id)) {
        state.employeesForCandidateDiffMerge.ids.push(payload.id);
        state.employeesForCandidateDiffMerge.items[payload.id] = payload;
      }
    })
    .addCase(addEmployeesForCandidateDiffMergeThunk.pending, (state) => {
      state.employeesForCandidateDiffMerge.isLoading = true;
    })
    .addCase(addEmployeesForCandidateDiffMergeThunk.rejected, (state) => {
      state.employeesForCandidateDiffMerge = preloadedEmployeesState.employeesForCandidateDiffMerge;
    })
    .addCase(clearEmployeesAction, (state) => {
      state.employees = preloadedEmployeesState.employees;
      state.responsibleEmployee = preloadedEmployeesState.responsibleEmployee;
    })
    .addCase(clearMentionedEmployeesAction, (state) => {
      state.mentionedEmployees = preloadedEmployeesState.mentionedEmployees;
      state.mentionedOptions = preloadedEmployeesState.mentionedOptions;
    })
    .addCase(logoutAction, () => preloadedEmployeesState),
);
