import type { FC, PropsWithChildren } from 'react';
import { type DropAnimation, DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.3',
      },
    },
  }),
};

const SortableOverlay: FC<PropsWithChildren> = ({ children }) => {
  return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
};

export default SortableOverlay;
