import { RefObject, useLayoutEffect, useState } from 'react';

export interface PositionOptions {
  horizontalDirection: string;
  verticalDirection: string;
}

const useCalculatePosition = (
  ref: RefObject<HTMLElement | null>,
  active: boolean,
  wrapperRef?: RefObject<HTMLElement | null>,
  settings: PositionOptions = {
    horizontalDirection: 'left',
    verticalDirection: 'bottom',
  },
): PositionOptions => {
  const { horizontalDirection, verticalDirection } = settings;
  const [horizontal, setHorizontal] = useState<string>(horizontalDirection);
  const [vertical, setVertical] = useState<string>(verticalDirection);

  useLayoutEffect(() => {
    if (ref.current) {
      const coords = ref.current.getBoundingClientRect();
      const { width, left, bottom } = coords;
      if (wrapperRef?.current) {
        const coordsWrapper = wrapperRef.current.getBoundingClientRect();
        setVertical(bottom >= coordsWrapper.bottom ? 'top' : 'bottom');
      } else {
        setVertical(bottom >= window.innerHeight ? 'top' : 'bottom');
      }
      setHorizontal(width < left ? 'right' : 'left');
    } else {
      setHorizontal(horizontalDirection);
      setVertical(verticalDirection);
    }
  }, [ref, wrapperRef, active, horizontalDirection, verticalDirection]);
  return { horizontalDirection: horizontal, verticalDirection: vertical };
};

export default useCalculatePosition;
