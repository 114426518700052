import { SelectOption, useVacanciesOptionsApi } from '@innowise-group/core';
import { Checkbox, Select, SelectProps } from '@innowise-group/mui-kit';
import React from 'react';
import * as Styled from './vacancy-select.styles';
import { VacancyListItem } from './components/vacancy-list-item';

type VacancySelectProps = Omit<
  SelectProps,
  'options' | 'loading' | 'loadingAdditionalOptions' | 'lastElementRef' | 'onBlur'
>;

const VacancySelect: React.FC<VacancySelectProps> = ({ multiple, value, ...props }) => {
  const {
    options: vacanciesOptions,
    items: vacanciesItems,
    resetEmptyState,
    lastElementRef,
    optionsLoading,
    additionalOptionsLoading,
  } = useVacanciesOptionsApi();

  const renderVacancyListItem = (props, option: SelectOption) => {
    const vacancy = vacanciesItems[option.value];
    if (!vacancy) return null;
    return multiple && Array.isArray(value) ? (
      <Styled.CheckboxOptionItem ref={lastElementRef} {...props} key={option.value}>
        <VacancyListItem {...vacancy} />
        <Styled.CheckboxWrapper>
          <Checkbox checked={!!value.find((item) => item.value === option.value)} />
        </Styled.CheckboxWrapper>
      </Styled.CheckboxOptionItem>
    ) : (
      <Styled.OptionItem
        {...props}
        ref={lastElementRef}
        key={option.value}
        selected={option.value === value}
        autoFocus={option.value === value}
      >
        <VacancyListItem {...vacancy} />
      </Styled.OptionItem>
    );
  };

  return (
    <Select
      {...props}
      value={value}
      multiple={multiple}
      options={vacanciesOptions || []}
      loading={optionsLoading}
      onBlur={resetEmptyState}
      loadingAdditionalOptions={additionalOptionsLoading}
      renderOption={renderVacancyListItem}
    />
  );
};

export default VacancySelect;
