import { RefObject, useEffect, useState } from 'react';

const useResizeObserver = (widthScreen: number, container?: RefObject<HTMLElement>) => {
  const [isVisible, setIsVisible] = useState(() => window.innerWidth >= widthScreen);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
          if (contentBoxSize.inlineSize <= widthScreen || entry.contentRect.width <= widthScreen) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        }
      }
    });

    const resizeHandler = () => {
      if (container?.current) {
        resizeObserver.observe(container.current);
        return () => resizeObserver.unobserve(container.current);
      } else {
        setIsVisible(window.innerWidth >= widthScreen);
      }
    };

    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [container, widthScreen]);

  return isVisible;
};

export default useResizeObserver;
