import React, { forwardRef } from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import * as Styled from './button.styles';

const Button: React.FC<MuiButtonProps> = forwardRef<HTMLButtonElement, MuiButtonProps>(
  ({ variant = 'contained', ...props }, ref) => {
    return <Styled.Button ref={ref} variant={variant} {...props} />;
  },
);

export default React.memo(Button);
