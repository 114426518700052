import {
  CalendarState,
  MeetingItemResponse,
  useAppDispatch,
  useAppSelector,
  LocalizedStatusItemResponse,
} from '@innowise-group/core';
import { useCallback } from 'react';
import { getCalendarMeetingByIdThunk, getCalendarMeetingsThunk, getCalendarTimeSlotsThunk } from './calendar.thunk';
import { clearMeetingsAction, clearTimeSlotsAction } from './calendar.actions';
import {
  getCalendarMeetingsItems,
  getCalendarStateSelector,
  getCalendarTimeSlots,
  getCurrentMeetState,
} from './calendar.selectors';

export const useCalendarStateSelector = (): CalendarState => useAppSelector(getCalendarStateSelector);
export const useCalendarMeetingsItems = (): MeetingItemResponse[] => useAppSelector(getCalendarMeetingsItems);
export const useCalendarTimeSlots = (): Date[] => useAppSelector(getCalendarTimeSlots);
export const useCurrentMeetState = (): LocalizedStatusItemResponse => useAppSelector(getCurrentMeetState);

export const useCalendarAPI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, currentMeet, ...state } = useCalendarStateSelector();

  const fetchEventById = useCallback(
    (...args: Parameters<typeof getCalendarMeetingByIdThunk>) => {
      dispatch(getCalendarMeetingByIdThunk(...args));
    },
    [dispatch],
  );

  const fetchMeetings = useCallback(
    (...args: Parameters<typeof getCalendarMeetingsThunk>) => {
      return dispatch(getCalendarMeetingsThunk(...args));
    },
    [dispatch],
  );

  const fetchTimeSlots = useCallback(
    (...args: Parameters<typeof getCalendarTimeSlotsThunk>) => {
      return dispatch(getCalendarTimeSlotsThunk(...args));
    },
    [dispatch],
  );

  const clearMeetings = useCallback(() => {
    dispatch(clearMeetingsAction());
  }, [dispatch]);

  const clearTimeSlots = useCallback(() => {
    dispatch(clearTimeSlotsAction());
  }, [dispatch]);

  return {
    fetchEventById,
    fetchMeetings,
    clearMeetings,
    clearTimeSlots,
    isLoading,
    currentMeet,
    fetchTimeSlots,
    ...state,
  };
};
