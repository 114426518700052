import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AvatarSection } from './components/avatar-section';
import * as Styled from './profile-form.styles';
import React from 'react';
import { getLanguageFromLS } from '@innowise-group/utilities';
import { AppLanguages } from '@constants';
import { ProfileItem } from '@innowise-group/core';
import { Input } from '@innowise-group/mui-kit';
import { PhoneInput } from '@shared-mui-components';

interface ProfileFormProps {
  handleChangeIsImageLoading: (value: boolean) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<ProfileItem>();
  const language = getLanguageFromLS();
  const orgUnits = watch('orgUnits');
  const phoneRt = watch('phoneRt');

  return (
    <Styled.FormContainer>
      <Styled.AvatarContainer>
        <AvatarSection {...props} />
      </Styled.AvatarContainer>
      <Controller
        name="firstNameRu"
        control={control}
        render={({ field: { value } }) => (
          <Input value={value} label={t('pages.profile.creationModal.firstName')} disabled />
        )}
      />
      <Controller
        name="lastNameRu"
        control={control}
        render={({ field: { value } }) => (
          <Input value={value} label={t('pages.profile.creationModal.lastName')} disabled />
        )}
      />
      <Controller
        name="phoneRt"
        control={control}
        render={({ field: { onChange }, formState: { errors } }) => (
          <PhoneInput
            name="phoneRt"
            value={phoneRt}
            onChange={onChange}
            fullWidth
            label={t('pages.candidates.candidateCreation.phoneNumber')}
            error={!!errors?.phoneRt?.value?.message}
            helperText={errors?.phoneRt?.value?.message}
            placeholder={t('pages.profile.creationModal.enterTheNumber')}
          />
        )}
      />
      <Controller
        name="telegramRt"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Input
            label="Telegram"
            value={value}
            onChange={onChange}
            fullWidth
            error={!!errors?.telegramRt?.message}
            helperText={errors?.telegramRt?.message}
            placeholder={t('pages.profile.creationModal.enterTheNickname')}
          />
        )}
      />
      {orgUnits?.map((item, index) => (
        <React.Fragment key={index}>
          <Input
            value={item?.orgUnitName || '-'}
            disabled
            fullWidth
            label={
              language === AppLanguages.Ru ? item?.orgUnitType?.orgUnitTypeNameRu : item?.orgUnitType?.orgUnitTypeNameEn
            }
          />
          {index === orgUnits.length - 1 && (
            <Controller
              name="position"
              control={control}
              render={({ field: { value } }) => (
                <Input value={value} disabled label={t('pages.profile.creationModal.position')} fullWidth />
              )}
            />
          )}
        </React.Fragment>
      ))}
      <Controller
        name="email"
        control={control}
        render={({ field: { value } }) => (
          <Styled.EmailInput value={value} disabled label="E-mail" $unitsLength={orgUnits.length} />
        )}
      />
    </Styled.FormContainer>
  );
};

export default ProfileForm;
