import { createReducer } from '@reduxjs/toolkit';
import { preloadedState } from './administration.preloaded-state';
import {
  resetEmployeesAdministrationsStateAction,
  resetEmployeeFromHrmByEmailAction,
  selectAllUserAuthDataPermissionsAction,
  setNewEmployeeRoleFromHrmAction,
  setPagableAction,
  unSelectAllUserAuthDataPermissionsAction,
  updateExistingUserRoleAction,
  updateUserSelectedAuthDataPermissionsAction,
  clearCurrentStatusAction,
  clearReferralStateAction,
  clearUnitsStateAction,
  clearSourcingStateAction,
} from './administration.actions';
import {
  getEmployeeFromHrmByEmailThunk,
  searchEmployeesThunk,
  searchRequestOptionsThunk,
  searchVacancyOptionsThunk,
  addEmployeeThunk,
  searchEmailByEmailPartThunk,
  rolePermissionAssignThunk,
  fetchCitiesByLanguagesThunk,
  mergeLocationsThunk,
  createStatusThunk,
  updateStatusThunk,
  getStatusItemByIdThunk,
  deleteStatusItemByIdThunk,
  getReferralDataThunk,
  updateReferralsManagerThunk,
  getReferralsManagerThunk,
  resolveReferralStatusThunk,
  getReferralManagerOptionsThunk,
  exportReferralDataThunk,
  getAllUnitsThunk,
  createUnitThunk,
  updateUnitThunk,
  getAllSourcingDataThunk,
  updateSourcingEmployeeThunk,
  getActualUnitsThunk,
} from './administration.thunk';
import { getArrayIds, getStringArrayIds, localizedNameObject, transformArrayToObject } from '@innowise-group/utilities';
import { findNewOptions } from './administration.mappers';
import { logoutAction } from '../common';

export const administrationReducer = createReducer(preloadedState, (builder) =>
  builder
    .addCase(updateSourcingEmployeeThunk.fulfilled, (state, { payload }) => {
      state.sourcing.items = {
        ...state.sourcing.items,
        [payload.employeeId]: {
          ...state.sourcing.items[payload.employeeId],
          requiredNumberOfEvents: Number(payload.requiredNumberOfEvents),
        },
      };
    })

    .addCase(getAllSourcingDataThunk.pending, (state) => {
      state.sourcing.isLoading = true;
    })
    .addCase(getAllSourcingDataThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.page === 1) {
        state.sourcing.totalElements = payload.totalElements;
        state.sourcing.items = transformArrayToObject(payload.content);
        state.sourcing.ids = getStringArrayIds(payload.content);
      } else {
        state.sourcing.items = { ...state.sourcing.items, ...transformArrayToObject(payload.content) };
        state.sourcing.ids = [...new Set([...state.sourcing.ids, ...getStringArrayIds(payload.content)])];
      }
      state.sourcing.isLoading = false;
      state.sourcing.totalPages = payload.totalPages;
    })
    .addCase(getAllSourcingDataThunk.rejected, (state) => {
      state.sourcing.isLoading = preloadedState.sourcing.isLoading;
    })

    .addCase(exportReferralDataThunk.pending, (state) => {
      state.referral.isExportLoading = true;
    })
    .addCase(exportReferralDataThunk.fulfilled, (state) => {
      state.referral.isExportLoading = false;
    })
    .addCase(exportReferralDataThunk.rejected, (state) => {
      state.referral.isExportLoading = false;
    })

    .addCase(getReferralManagerOptionsThunk.pending, (state) => {
      state.referral.isManagerOptionsLoading = true;
    })
    .addCase(getReferralManagerOptionsThunk.fulfilled, (state, { payload }) => {
      state.referral.isManagerOptionsLoading = false;
      state.referral.managerOptions = payload.map(({ id, ...data }) => {
        return {
          title: localizedNameObject(data).localizedFullName,
          value: id.toString(),
          miniPhotoUrl: data.photoRt?.id?.toString() || null,
        };
      });
    })
    .addCase(getReferralManagerOptionsThunk.rejected, (state) => {
      state.referral.isManagerOptionsLoading = false;
    })
    .addCase(resolveReferralStatusThunk.fulfilled, (state, { payload }) => {
      state.referral.isLoading = false;
      state.referral.items = state.referral.items.map((item) =>
        item.yearMonth === payload.yearMonth
          ? {
              ...item,
              candidateReferralDtos: item.candidateReferralDtos.map((curr) =>
                curr.id.toString() === payload.id.toString() ? { ...curr, accepted: payload.accepted } : curr,
              ),
            }
          : item,
      );
    })
    .addCase(getReferralDataThunk.pending, (state) => {
      state.referral.isLoading = true;
    })
    .addCase(getReferralDataThunk.fulfilled, (state, { payload }) => {
      state.referral.isLoading = false;
      state.referral.items = payload;
    })
    .addCase(getReferralDataThunk.rejected, (state) => {
      state.referral.isLoading = false;
    })

    .addCase(getReferralsManagerThunk.pending, (state) => {
      state.referral.isManagerLoading = true;
    })
    .addCase(getReferralsManagerThunk.fulfilled, (state, { payload }) => {
      state.referral.isManagerLoading = false;
      state.referral.manager = payload;
    })
    .addCase(getReferralsManagerThunk.rejected, (state) => {
      state.referral.isManagerLoading = false;
    })

    .addCase(updateReferralsManagerThunk.pending, (state) => {
      state.referral.isManagerLoading = true;
    })
    .addCase(updateReferralsManagerThunk.fulfilled, (state) => {
      state.referral.isManagerLoading = false;
    })
    .addCase(updateReferralsManagerThunk.rejected, (state) => {
      state.referral.isManagerLoading = false;
    })

    .addCase(createUnitThunk.fulfilled, (state, { payload }) => {
      state.units.ids = [...state.units.ids, payload.id];
      state.units.items = { ...state.units.items, [payload.id]: payload };
    })
    .addCase(updateUnitThunk.fulfilled, (state, { payload }) => {
      state.units.items[payload.id] = payload;
    })

    .addCase(getAllUnitsThunk.pending, (state) => {
      state.units.isLoading = true;
    })
    .addCase(getAllUnitsThunk.fulfilled, (state, { payload }) => {
      state.units.isLoading = false;
      state.units.ids = getArrayIds(payload);
      state.units.items = transformArrayToObject(payload);
    })
    .addCase(getAllUnitsThunk.rejected, (state) => {
      state.units.isLoading = false;
    })

    .addCase(getActualUnitsThunk.pending, (state) => {
      state.units.isLoading = true;
    })
    .addCase(getActualUnitsThunk.fulfilled, (state, { payload }) => {
      state.units.isLoading = false;
      state.units.ids = getArrayIds(payload);
      state.units.items = transformArrayToObject(payload);
    })
    .addCase(getActualUnitsThunk.rejected, (state) => {
      state.units.isLoading = false;
    })

    .addCase(searchEmployeesThunk.pending, (state) => {
      state.employees.isLoading = true;
    })
    .addCase(searchEmployeesThunk.fulfilled, (state, { payload }) => {
      state.employees.isLoading = false;
      state.employees.totalElements = payload.totalElements;
      state.employees.totalPages = payload.totalPages;
      state.employees.employees = transformArrayToObject(payload.content);
      state.employees.employeesIds = getArrayIds(payload.content);
      state.employees.vacanciesOptions = payload.vacanciesOptions;
      state.employees.requestsOptions = payload.requestsOptions;
    })
    .addCase(searchEmployeesThunk.rejected, (state) => {
      state.employees.isLoading = preloadedState.employees.isLoading;
      state.employees.employeesIds = preloadedState.employees.employeesIds;
      state.employees.employees = preloadedState.employees.employees;
      state.employees.totalElements = preloadedState.employees.totalElements;
      state.employees.totalPages = preloadedState.employees.totalPages;
    })

    .addCase(searchVacancyOptionsThunk.pending, (state) => {
      state.employees.optionsLoading = true;
    })
    .addCase(searchVacancyOptionsThunk.fulfilled, (state, { payload }) => {
      const { ids, options } = payload.reduce(
        (acc, val) => {
          return {
            ...acc,
            ids: [...acc.ids, val.value.toString()],
            options: {
              ...acc.options,
              [val.value.toString()]: val,
            },
          };
        },
        { ids: [], options: {} },
      );
      state.employees.vacanciesOptions.ids = [...new Set([...state.employees.vacanciesOptions.ids, ...ids])];
      state.employees.vacanciesOptions.options = { ...state.employees.vacanciesOptions.options, ...options };
      state.employees.optionsLoading = false;
      state.employees.searchedVacanciesOptions = payload;
    })
    .addCase(searchVacancyOptionsThunk.rejected, (state) => {
      state.employees.optionsLoading = false;
      state.employees.searchedVacanciesOptions = preloadedState.employees.searchedVacanciesOptions;
    })

    .addCase(searchRequestOptionsThunk.pending, (state) => {
      state.employees.optionsLoading = true;
    })
    .addCase(searchRequestOptionsThunk.fulfilled, (state, { payload }) => {
      const { ids, options } = payload.reduce(
        (acc, val) => {
          return {
            ...acc,
            ids: [...acc.ids, val.value.toString()],
            options: {
              ...acc.options,
              [val.value.toString()]: val,
            },
          };
        },
        { ids: [], options: {} },
      );
      state.employees.requestsOptions.ids = [...new Set([...state.employees.requestsOptions.ids, ...ids])];
      state.employees.requestsOptions.options = { ...state.employees.requestsOptions.options, ...options };
      state.employees.optionsLoading = false;
      state.employees.searchedRequestsOptions = payload;
    })
    .addCase(searchRequestOptionsThunk.rejected, (state) => {
      state.employees.optionsLoading = false;
      state.employees.searchedRequestsOptions = preloadedState.employees.searchedRequestsOptions;
    })

    .addCase(getEmployeeFromHrmByEmailThunk.pending, (state) => {
      state.employees.userLoading = true;
    })
    .addCase(getEmployeeFromHrmByEmailThunk.fulfilled, (state, { payload }) => {
      state.employees.userLoading = false;
      state.employees.userToAdd = payload;
    })
    .addCase(getEmployeeFromHrmByEmailThunk.rejected, (state) => {
      state.employees.userLoading = preloadedState.employees.userLoading;
      state.employees.userToAdd = preloadedState.employees.userToAdd;
    })

    .addCase(addEmployeeThunk.pending, (state) => {
      state.employees.isLoading = true;
    })
    .addCase(addEmployeeThunk.fulfilled, (state) => {
      state.employees.isLoading = false;
    })
    .addCase(addEmployeeThunk.rejected, (state) => {
      state.employees.isLoading = false;
    })

    .addCase(searchEmailByEmailPartThunk.pending, (state) => {
      state.employees.employeesEmailsListLoading = true;
    })
    .addCase(searchEmailByEmailPartThunk.fulfilled, (state, { payload }) => {
      state.employees.employeesEmailsListLoading = false;
      state.employees.employeesEmailsList = payload;
    })
    .addCase(searchEmailByEmailPartThunk.rejected, (state) => {
      state.employees.employeesEmailsListLoading = false;
    })

    .addCase(rolePermissionAssignThunk.fulfilled, (state, { meta }) => {
      if (state.employees.employees[meta.arg.employeeId].role) {
        state.employees.employees[meta.arg.employeeId].role.name = meta.arg.roleName;
      }
    })

    .addCase(resetEmployeeFromHrmByEmailAction, (state) => {
      state.employees.userToAdd = preloadedState.employees.userToAdd;
    })

    .addCase(setNewEmployeeRoleFromHrmAction, (state, { payload }) => {
      state.employees.userToAdd.role = payload;
    })

    .addCase(updateExistingUserRoleAction, (state, { payload }) => {
      state.employees.employees[payload.id].newRole = payload.newRole;
    })

    .addCase(setPagableAction, (state, { payload }) => {
      if (payload?.currentPage) state.employees.pagable.currentPage = payload.currentPage;
      if (payload?.pageSize) state.employees.pagable.pageSize = payload.pageSize;
    })
    .addCase(updateUserSelectedAuthDataPermissionsAction, (state, { payload }) => {
      if (payload.entity === 'permittedVacancies') {
        const { newIds, newOptions } = findNewOptions(payload.selectedIds, state.employees.searchedVacanciesOptions);
        state.employees.vacanciesOptions = {
          options: {
            ...state.employees.vacanciesOptions.options,
            ...newOptions,
          },
          ids: [...new Set([...state.employees.vacanciesOptions.ids, ...newIds])],
        };
      }
      if (payload.entity === 'permittedVacancyRequestIds') {
        const { newIds, newOptions } = findNewOptions(payload.selectedIds, state.employees.searchedRequestsOptions);
        state.employees.requestsOptions = {
          options: {
            ...state.employees.requestsOptions.options,
            ...newOptions,
          },
          ids: [...new Set([...state.employees.requestsOptions.ids, ...newIds])],
        };
      }
      state.employees.employees[payload.id].authData[payload.entity] = payload.selectedIds.length
        ? payload.selectedIds
        : null;
    })
    .addCase(fetchCitiesByLanguagesThunk.pending, (state) => {
      state.locations.isLoading = true;
    })
    .addCase(fetchCitiesByLanguagesThunk.fulfilled, (state, { payload }) => {
      state.locations.isLoading = false;
      state.locations.citiesDataByLanguage = payload;
    })
    .addCase(fetchCitiesByLanguagesThunk.rejected, (state) => {
      state.locations.isLoading = false;
    })
    .addCase(mergeLocationsThunk.pending, (state) => {
      state.locations.isLoading = true;
    })
    .addCase(mergeLocationsThunk.fulfilled, (state) => {
      state.locations.isLoading = false;
    })
    .addCase(mergeLocationsThunk.rejected, (state) => {
      state.locations.isLoading = false;
    })
    .addCase(createStatusThunk.pending, (state) => {
      state.statuses.isLoading = true;
    })
    .addCase(createStatusThunk.fulfilled, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(createStatusThunk.rejected, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(updateStatusThunk.pending, (state) => {
      state.statuses.isLoading = true;
    })
    .addCase(updateStatusThunk.fulfilled, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(updateStatusThunk.rejected, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(getStatusItemByIdThunk.pending, (state) => {
      state.statuses.isLoading = true;
    })
    .addCase(getStatusItemByIdThunk.fulfilled, (state, { payload }) => {
      state.statuses.isLoading = false;
      state.statuses.currentStatus = payload;
    })
    .addCase(getStatusItemByIdThunk.rejected, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(deleteStatusItemByIdThunk.pending, (state) => {
      state.statuses.isLoading = true;
    })
    .addCase(deleteStatusItemByIdThunk.fulfilled, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(deleteStatusItemByIdThunk.rejected, (state) => {
      state.statuses.isLoading = false;
    })
    .addCase(selectAllUserAuthDataPermissionsAction, (state, { payload }) => {
      state.employees.employees[payload.id].authData[payload.entity] = [];
    })
    .addCase(unSelectAllUserAuthDataPermissionsAction, (state, { payload }) => {
      state.employees.employees[payload.id].authData[payload.entity] = null;
    })
    .addCase(resetEmployeesAdministrationsStateAction, (state) => {
      state.employees = preloadedState.employees;
    })
    .addCase(clearUnitsStateAction, (state) => {
      state.units = preloadedState.units;
    })
    .addCase(clearSourcingStateAction, (state) => {
      state.sourcing = preloadedState.sourcing;
    })
    .addCase(clearCurrentStatusAction, (state) => {
      state.statuses.currentStatus = preloadedState.statuses.currentStatus;
    })
    .addCase(clearReferralStateAction, (state) => {
      state.referral = preloadedState.referral;
    })
    .addCase(logoutAction, () => preloadedState),
);
