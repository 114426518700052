import { CalendarState } from './calendar.types';

export const preloadedState: CalendarState = {
  isLoading: false,
  isCurrentMeetLoading: false,
  meetings: {},
  meetingsIds: [],
  currentMeet: null,
  timeSlots: [],
};
