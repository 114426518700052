import { useEffect, useMemo } from 'react';
import { CompressService } from '../services';
import useSearchParamsState from './use-search-params-state.hook';
import { useSearchParams } from 'react-router-dom';

const useCompressedStateInQuery = <TState extends object>(
  initialState?: TState,
): readonly [searchParamsState: Partial<TState>, setSearchParamsState: (newState: Partial<TState>) => void] => {
  const [stateFromQuery, setStateFromQuery] = useSearchParamsState<{ s: string; d?: string }>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (initialState) {
      setState(initialState);
    }
  }, []);

  const state = useMemo(() => {
    if (stateFromQuery) {
      return {
        ...(stateFromQuery.s ? CompressService.decompressStringAndParseToObject(stateFromQuery.s) : {}),
        ...(stateFromQuery.d ? CompressService.decompressStringAndParseToObject(stateFromQuery.d) : {}),
      };
    }
  }, [stateFromQuery, CompressService]);

  const setState = (state: TState) => {
    const newState = CompressService.compressObject(state);
    setStateFromQuery({
      s: newState,
      d: searchParams.get('d'),
    });
  };

  return [state, setState];
};

export default useCompressedStateInQuery;
