import { RolesValues } from '@constants';

export const roles = {
  [RolesValues.Admin]: {
    title: 'roles.admin',
    value: RolesValues.Admin,
  },
  [RolesValues.Supervisor]: {
    title: 'roles.supervisor',
    value: RolesValues.Supervisor,
  },
  // [RolesValues.Sourcer]: {
  //   title: 'roles.sourcer',
  //   value: RolesValues.Sourcer,
  // },
  [RolesValues.Recruiter]: {
    title: 'roles.recruiter',
    value: RolesValues.Recruiter,
  },
  [`${RolesValues.ManagerFourthLevel}_${RolesValues.ManagerFourthLevel}`]: {
    title: 'pages.administration.newUser.manager',
    value: RolesValues.ManagerFourthLevel,
  },
  [RolesValues.ManagerFirstLevel]: {
    title: 'pages.administration.newUser.managerFirstLevel',
    value: RolesValues.ManagerFirstLevel,
  },
  [RolesValues.ManagerSecondLevel]: {
    title: 'pages.administration.newUser.managerSecondLevel',
    value: RolesValues.ManagerSecondLevel,
  },
  [RolesValues.ManagerThirdLevel]: {
    title: 'pages.administration.newUser.managerThirdLevel',
    value: RolesValues.ManagerThirdLevel,
  },
  [RolesValues.ManagerFourthLevel]: {
    title: 'pages.administration.newUser.managerFourthLevel',
    value: RolesValues.ManagerFourthLevel,
  },
};
