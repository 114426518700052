import { createAsyncThunk } from '@reduxjs/toolkit';
import { citiesMapper, optionsMapper } from './options.mapper';
import {
  CountriesResponse,
  DictionaryService,
  EmployeesService,
  MyKnownError,
  ShortEmployeeItemResponse,
} from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import { RolesValues } from '@constants';

export const getAllSelectOptionsThunk = createAsyncThunk<
  Awaited<ReturnType<typeof optionsMapper>>,
  undefined,
  { rejectValue: MyKnownError }
>('dictionary/getAllSelectOptions', async (data, { rejectWithValue }) => {
  try {
    const { data } = await DictionaryService.getDictionarySelectOptions();
    return optionsMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCountrySelectOptionsThunk = createAsyncThunk<
  { options: { [key: string]: SelectOption[] }; items: CountriesResponse[] },
  undefined,
  { rejectValue: MyKnownError }
>('dictionary/getCountrySelectOptions', async (data, { rejectWithValue }) => {
  try {
    const { data } = await DictionaryService.getCountrySelectOptions();
    return {
      options: {
        countryOptions: data.map((item) => {
          return { title: item.name, value: item.id.toString(), parent: null };
        }),
      },
      items: data,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCitySelectOptionsThunk = createAsyncThunk<
  { citiesOptions: SelectOption[]; locationOptions: SelectOption[] },
  undefined,
  { rejectValue: MyKnownError }
>('dictionary/getCitySelectOptions', async (data, { rejectWithValue }) => {
  try {
    const { data } = await DictionaryService.getCitySelectOptions({ hasCompanyOffice: false });
    const { data: locationData } = await DictionaryService.getCitySelectOptions({ hasCompanyOffice: true });
    return {
      citiesOptions: citiesMapper(data),
      locationOptions: citiesMapper(locationData),
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const addCityToDictionaryThunk = createAsyncThunk<
  SelectOption,
  { countryId: string; cityNames: { [key: string]: string } },
  { rejectValue: MyKnownError }
>('dictionary/addCity', async (data, { rejectWithValue }) => {
  try {
    const response = await DictionaryService.addCityToDictionary(data);
    return { title: response.data.name, value: response.data.id, parent: response.data.countryId.toString() };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getEmployeesByRolesOptionsThunk = createAsyncThunk<
  ShortEmployeeItemResponse[],
  { roles: RolesValues[] },
  { rejectValue: MyKnownError }
>('employees/getByRoles', async ({ roles }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.searchEmployeesByRoles(roles);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
