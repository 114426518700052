import { styled } from '@innowise-group/mui-kit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const SheetNamesContainer = styled.div`
  display: flex;
  padding-top: 0.75;
  flex-direction: column;
`;

export const SheetContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[40]}
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;

  & table {
    border-collapse: collapse;
  }

  & td,
  & th {
    border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[80]};
    padding: 4px;
  }
`;
