import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ClaimTypes } from '@constants';
import {
  useControlFormField,
  useCurrentCandidate,
  useResizeObserver,
  useStatusConfigById,
  useValidationSchemaCandidateStatus,
} from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';
import { MobileView } from './mobile-view';
import { DesktopView } from './desktop-view';

const FeedbackSection: React.FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const currentCandidate = useCurrentCandidate();
  const { setValue, watch, resetField, clearErrors } = useFormContext();
  const statusId = watch('statusId');
  const statusConfig = useStatusConfigById(statusId);
  const { validationSchema } = useValidationSchemaCandidateStatus(statusConfig, currentCandidate);
  const { handleChangeBooleanItem } = useControlFormField(validationSchema);
  const isActive = watch('claims.0.isActive');

  const filterPassedTime = useCallback((time) => {
    const selectedDate = new Date(time);
    return new Date().getTime() < selectedDate.getTime();
  }, []);

  useEffect(() => {
    resetField('claims.0.isActive');
  }, [resetField, statusId]);

  useEffect(() => {
    setValue('claims.0.claimType', ClaimTypes.FEEDBACK);
  }, [setValue]);

  useEffect(() => {
    if (!isActive) {
      setValue('claims.0.deadline', null);
      clearErrors('claims.0.deadline');
    }
  }, [setValue, isActive]);

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component
      isActive={isActive}
      handleChangeBooleanItem={handleChangeBooleanItem}
      filterPassedTime={filterPassedTime}
    />
  );
};

export default FeedbackSection;
