import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeesService, MyKnownError, ShortEmployeeItemResponse } from '@innowise-group/core';

export const getEmployeeByIdThunk = createAsyncThunk<
  ShortEmployeeItemResponse,
  { employeeId: string | number },
  { rejectValue: MyKnownError }
>('employees/getById', async ({ employeeId }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.getEmployeeById(employeeId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchEmployeesListThunk = createAsyncThunk<
  ShortEmployeeItemResponse[],
  { partOfName: string },
  { rejectValue: MyKnownError }
>('employees/getList', async ({ partOfName }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.searchEmployees({ partOfName });
    return response.data.content.map((item) => {
      return { ...item, id: item.employeeId };
    });
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const addEmployeesForCandidateDiffMergeThunk = createAsyncThunk<
  ShortEmployeeItemResponse,
  { employeeId: string | number },
  { rejectValue: MyKnownError }
>('employees/addEmployeesForCandidateDiffMergeThunk', async ({ employeeId }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.getEmployeeById(employeeId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getMentionedEmployeesThunk = createAsyncThunk<
  { [key: string]: ShortEmployeeItemResponse },
  { candidateId: number },
  { rejectValue: MyKnownError }
>('employees/getMentionedList', async ({ candidateId }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.getMentionedEmployees(candidateId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
