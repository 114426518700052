import { FC } from 'react';
import { ContactsFields } from '@innowise-group/core';
import { DiffBaseProps, LabelTransformer, DiffBase } from '../diff-base';
import { useTranslation } from 'react-i18next';

interface ContactsFieldsDiff extends Omit<DiffBaseProps, 'labelTransformer'> {
  fieldName: ContactsFields;
}

const ContactsFieldsDiff: FC<ContactsFieldsDiff> = ({ fieldName, ...props }) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<typeof fieldName> = ({ data }) => data || t('buttons.leaveBlank');
  return <DiffBase fieldName={fieldName} labelTransformer={labelTransformer} {...props} />;
};

export default ContactsFieldsDiff;
