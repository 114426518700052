export const FilesFormats = [
  'application/x-zip-compressed',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/png',
  'image/gif',
  'image/jpeg',
  'application/x-rar-compressed',
  'application/zip',
  'application/pdf',
  'rar',
  'text/plain',
];
export const OfferFilesFormats = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];

export const FilesAcceptFormats = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.png',
  '.gif',
  '.jpg',
  '.jpeg',
  '.rar',
  '.zip',
  '.pdf',
  '.txt',
];
export const OfferFilesAcceptFormats = ['.doc', '.docx', '.pdf'];
export const FilesFormatsObject = {
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  xlsx: 'xlsx',
  png: 'png',
  gif: 'gif',
  jpg: 'jpg',
  jpeg: 'jpeg',
  rar: 'rar',
  zip: 'zip',
  pdf: 'pdf',
  txt: 'txt',
};
export const MaxFileSize = 26214400;
export enum FileTypesEnum {
  'doc' = 'application/msword',
  'docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'xls' = 'application/vnd.ms-excel',
  'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'png' = 'image/png',
  'gif' = 'image/gif',
  'jpeg' = 'image/jpeg',
  'jpg' = 'image/jpg',
  'rar' = 'application/x-rar-compressed',
  'zip' = 'application/zip',
  'pdf' = 'application/pdf',
  'txt' = 'text/plain',
}

export const CandidateFromFileAcceptFormats = ['.txt', '.doc', '.docx', '.pdf'];
export const CandidateFromFileMaxSize = 2097152;

export const CandidateFromFileFormats = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'text/plain',
];

export const FileTypesIcons = {
  doc: 'u_docs_type',
  docx: 'u_docs_type',
  xls: 'u_table_type',
  xlsx: 'u_table_type',
  png: 'u_image_type',
  gif: 'u_image_type',
  jpg: 'u_image_type',
  jpeg: 'u_image_type',
  rar: 'u_archive_type',
  zip: 'u_archive_type',
  pdf: 'u_docs_type',
  txt: 'u_docs_type',
};
