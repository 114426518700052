import { FC, memo } from 'react';
import { Icon, type AppIconsType } from '@innowise-group/mui-kit';
import * as Styled from './report-button.styles';

type Buttons = 'primary' | 'secondary';

export interface ReportButtonProps {
  onChange: (val: string) => void;
  disabled?: boolean;
  value: ReportUnion | ReportTypeUnion;
  variants: {
    [key in Buttons]: string;
  };
  icons: {
    [key in Buttons]: {
      type: AppIconsType;
      viewBox: string;
    };
  };
}

export enum ReportUnion {
  Table = 'TABLE',
  Chart = 'CHART',
}

export enum ReportTypeUnion {
  Linear = 'LINEAR',
  Round = 'ROUND',
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}

const ReportButton: FC<ReportButtonProps> = ({ variants, value, icons, onChange, disabled }) => {
  const onClickHandler = (args: string) => () => onChange(args);

  const getButtonProps = (key: keyof typeof variants) => ({
    onClick: onClickHandler(variants[key]),
    $active: value === variants[key],
    disabled,
  });

  return (
    <Styled.Wrapper disabled={disabled}>
      <Styled.Button {...getButtonProps('primary')}>
        <Icon {...icons.primary} />
      </Styled.Button>
      <Styled.Button {...getButtonProps('secondary')}>
        <Icon {...icons.secondary} />
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default memo(ReportButton);
