import { Input } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px 1rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({ theme }) => theme.breakpoints.down('ss')} {
    display: flex;
    flex-direction: column;
  }
`;

export const AvatarContainer = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmailInput = styled(Input)<{ $unitsLength: number }>`
  &.MuiTextField-root {
    grid-column: ${({ $unitsLength }) => (($unitsLength + 1) % 3 !== 1 ? 1 : 2)} / span 2;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiTextField-root {
      grid-column: 1 / span 2;
    }
  }
`;
