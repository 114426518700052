import { DiffState } from './consolidation.types';

export const preloadedState: DiffState = {
  diff: null,
  loadingDiff: false,
  loadingDuplicates: false,
  unsavedCandidate: null,
  loadingMergeMany: false,
  duplicatesInDiff: null,
  possibleDuplicates: {
    ids: [],
    items: null,
  },
};
