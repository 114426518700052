import { BaseFilterType, FiltersEntity, NumberRangeFilterType } from '@innowise-group/core';
import { Dispatch, SetStateAction, forwardRef, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './number-range.styles';
import { ResetButton } from '../reset-button';

export interface NumberRangeProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  id: string;
  state: NumberRangeFilterType['state'];
  addFilter: (filter: string) => void;
  onValueChange: (args: { [key in keyof NumberRangeFilterType['state']]: number | boolean }) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: NumberRangeFilterType['state']) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
  onClickFilterOutside: () => void;
}

const NumberRange: React.FC<NumberRangeProps> = forwardRef<HTMLDivElement, NumberRangeProps>(
  ({ state, id, labelKey, onValueChange, addFilter, removeFilter, setOpenedFilter }, ref) => {
    const { t } = useTranslation();
    const [minInputValue, setMinInputValue] = useState('');
    const [maxInputValue, setMaxInputValue] = useState('');

    const onValueChangeHandler = (...args: Parameters<typeof onValueChange>) => {
      addFilter(id);
      return onValueChange(...args);
    };

    const onMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChangeHandler({ ...state, from: Number(e.target.value) });
      setMinInputValue(e.target.value);
    };

    const onMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChangeHandler({ ...state, to: Number(e.target.value) });
      setMaxInputValue(e.target.value);
    };

    const onReset = () => {
      setMinInputValue('');
      setMaxInputValue('');
      onValueChangeHandler({ ...state, from: state.minValue, to: state.maxValue });
    };

    const onMinInputBlur = () => {
      if (!minInputValue) return;
      return setMinInputValue(state.from?.toString());
    };

    const onMaxInputBlur = () => {
      if (!maxInputValue) return;
      return setMaxInputValue(state.to?.toString());
    };

    const onMinInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (!e.target.value) {
        setMinInputValue('');
        onValueChangeHandler({ ...state, from: state.minValue });
        return;
      }
      if (Number(e.target.value) <= state.to && Number(e.target.value) >= Number(state.minValue)) return onMinChange(e);
      return setMinInputValue(e.target.value);
    };

    const onMaxInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (!e.target.value) {
        setMaxInputValue('');
        onValueChangeHandler({ ...state, to: state.maxValue });
        return;
      }
      if (Number(e.target.value) >= state.from && Number(e.target.value) <= state.maxValue) return onMaxChange(e);
      return setMaxInputValue(e.target.value);
    };

    useEffect(() => {
      setMaxInputValue(state.to.toString());
      setMinInputValue(state.from.toString());
    }, []);

    const removeFilterHandle = () => {
      setOpenedFilter('');
      return removeFilter({
        filter: id,
        updatedState: { ...state, from: state.minValue, to: state.maxValue } as unknown as Parameters<
          typeof removeFilter
        >[0]['updatedState'],
      });
    };

    return (
      <Styled.Wrapper>
        <Styled.Title>{t(labelKey)}</Styled.Title>
        <Styled.ControlsContainer>
          <Styled.RangePrice>
            <Styled.RangePriceField>
              <Styled.RangePriceLabel htmlFor="min">{t('filters.from')}</Styled.RangePriceLabel>
              <Styled.RangePriceInput
                onChange={onMinInputChangeHandler}
                value={minInputValue}
                onBlur={onMinInputBlur}
                placeholder={t('filters.from')}
                inputMode="numeric"
                type="number"
                name="min"
              />
            </Styled.RangePriceField>
            <Styled.RangePriceField>
              <Styled.RangePriceLabel htmlFor="max">{t('filters.to')}</Styled.RangePriceLabel>
              <Styled.RangePriceInput
                onChange={onMaxInputChangeHandler}
                value={maxInputValue}
                onBlur={onMaxInputBlur}
                placeholder={t('filters.to')}
                inputMode="numeric"
                type="number"
                name="max"
              />
            </Styled.RangePriceField>
          </Styled.RangePrice>
          <Styled.CustomDualSlider
            ref={ref}
            minValue={state.from}
            maxValue={state.to}
            step={state.step}
            minRange={state.minValue}
            maxRange={state.maxValue}
            onMinChange={onMinChange}
            onMaxChange={onMaxChange}
            onReset={onReset}
          />
        </Styled.ControlsContainer>
        <ResetButton onClick={removeFilterHandle}>{t('filters.resetFilter')}</ResetButton>
      </Styled.Wrapper>
    );
  },
);

export default memo(NumberRange);
