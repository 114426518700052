import { Button, styled, Divider as BaseDivider } from '@innowise-group/mui-kit';

export const Divider = styled(BaseDivider)`
  margin-top: 0.75rem;
`;

export const DatesSection = styled.div`
  display: flex;
  gap: 0rem;
  justify-content: end;
  margin-bottom: 0.5rem;
  & > * {
    flex: 1;
  }
`;

export const ButtonsSection = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  & > * {
    flex: 1;
  }
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[60]};
`;
