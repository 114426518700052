import { styled } from '@innowise-group/mui-kit';
import { Checkbox as CheckboxBase, Input as InputBase } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  padding: 10px;
`;

export const Input = styled(InputBase)`
  .MuiOutlinedInput-root {
    height: 45px;
  }
  .MuiFormLabel-root {
    top: 0px;
  }
  .MuiFormHelperText-root {
    height: 0;
  }
`;

export const ScrollableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectItemWrapper = styled.li<{ $disabled?: boolean }>`
  list-style-type: none;
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.general.darkGrey[40]};
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
`;

export const Checkbox = styled(CheckboxBase)`
  & .MuiSvgIcon-root {
    font-size: 25px;
  }
`;

export const SelectItemLabel = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  ${({ theme }) => theme.fonts.primary[80]}
`;
