import { css, styled } from '@innowise-group/mui-kit';
import { Button as MuiButton } from '@mui/material';

const baseStyles = css`
  width: auto;
  padding: 0.875rem 1.25rem;
  line-height: 1;
  text-transform: none;
  ${({ theme }) => theme.fonts.primary[60]};
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0.75rem 0.75rem;

    & .MuiButton-startIcon {
      margin: 2px;
    }
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root.MuiButton-contained {
    ${baseStyles}
    box-shadow: none;
    text-transform: none;
    line-height: 1;
    background-color: ${({ theme }) => theme.palette.general.smart};
    color: ${({ theme }) => theme.palette.general.white};
    &:hover {
      background-color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.hover};
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      color: ${({ theme }) => theme.palette.general.white};
      background-color: ${({ theme }) => theme.palette.general.smart};
    }

    & svg {
      transition: 0.2s;
      fill: ${({ theme }) => theme.palette.general.white};
      flex-shrink: 0;
    }
  }
  &.MuiButton-root.MuiButton-text {
    ${baseStyles}
    color: ${({ theme }) => theme.palette.general.smart};
    &:hover {
      background-color: transparent;
      color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
      & svg {
        fill: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
      }
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
    & svg {
      transition: 0.2s;
      fill: ${({ theme }) => theme.palette.general.smart};
      flex-shrink: 0;
    }
  }
  &.MuiButton-root.MuiButton-outlined {
    ${baseStyles}
    color: ${({ theme }) => theme.palette.general.smart};
    border-color: ${({ theme }) => theme.palette.general.smart};
    &:hover {
      background-color: transparent;
      color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
      border-color: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
      & svg {
        fill: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
      }
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
    & svg {
      transition: 0.2s;
      fill: ${({ theme }) => theme.palette.general.smart};
      flex-shrink: 0;
    }
  }
`;
