export enum AppLanguages {
  Ru = 'ru',
  En = 'en',
}

export const AppNameLanguages = {
  [AppLanguages.Ru]: 'RUSSIAN',
  [AppLanguages.En]: 'ENGLISH',
};

export enum LanguagesIds {
  ru = '2f85535f-f9c6-4c53-b74c-b39157cccbfd',
  en = '5bcb1015-1880-49c8-a5f5-ab74a5db56f4',
}
