import { useEffect, useRef } from 'react';

const useClickOutside = <T extends HTMLElement>(
  onClickAway: (event: MouseEvent | TouchEvent) => void,
  isOpen: boolean,
) => {
  const ref = useRef<T | null>(null);

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (ref?.current && !ref.current.contains(event.target as Node) && isOpen) {
      onClickAway(event);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside, { capture: true });
      document.addEventListener('touchstart', handleClickOutside, { capture: true });
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
      document.removeEventListener('touchstart', handleClickOutside, { capture: true });
    };
  }, [isOpen, handleClickOutside]);

  return ref;
};

export default useClickOutside;
