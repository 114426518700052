import { FC } from 'react';
import { format } from 'date-fns';
import { es, ru } from 'date-fns/locale';
import { DiffBase, type LabelTransformer } from '../diff-base';
import * as Styled from './education-diff.styles';
import { AppLanguages, DateFormats } from '@constants';
import { getLanguageFromLS } from '@innowise-group/utilities';

const EducationDiff: FC = (props) => {
  const ln = getLanguageFromLS();
  const locale = ln === AppLanguages.En ? es : ru;
  const labelTransformer: LabelTransformer<'candidateEducations'> = ({
    studiedFrom,
    studiedTo,
    studyPlace,
    profession,
    comment,
  }) => {
    const workedFromDate = studiedFrom ? format(new Date(studiedFrom), DateFormats.LongLocalizedDate, { locale }) : '';
    const workedToDate = studiedTo ? format(new Date(studiedTo), DateFormats.LongLocalizedDate, { locale }) : '';
    return (
      <Styled.OptionWrapper>
        <Styled.Field>{`${studyPlace} / ${profession}`}</Styled.Field>
        <Styled.Field>
          {workedFromDate ? workedFromDate : ''} {workedToDate ? ` - ${workedToDate}` : ''}
        </Styled.Field>
        <Styled.FieldLabel>{comment}</Styled.FieldLabel>
      </Styled.OptionWrapper>
    );
  };
  return <DiffBase multiple fieldName="candidateEducations" labelTransformer={labelTransformer} {...props} />;
};

export default EducationDiff;
