export interface CountryItem {
  name: string;
  dialCode: string;
  isoCode: string;
  format: string[];
  flag: { 'background-position': string };
}

export const countryData: CountryItem[] = [
  {
    name: 'Afghanistan',
    dialCode: '+93',
    isoCode: 'AF',
    format: ['##-###-####'],
    flag: { 'background-position': '-48px 0' },
  },
  {
    name: 'Albania',
    dialCode: '+355',
    isoCode: 'AL',
    format: ['(###)###-###'],
    flag: { 'background-position': '-96px 0' },
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    isoCode: 'DZ',
    format: ['##-###-####'],
    flag: { 'background-position': '-112px -33px' },
  },
  {
    name: 'AmericanSamoa',
    dialCode: '+1684',
    isoCode: 'AS',
    format: ['(684)###-####'],
    flag: { 'background-position': '-160px 0' },
  },
  { name: 'Andorra', dialCode: '+376', isoCode: 'AD', format: ['###-###'], flag: { 'background-position': '-16px 0' } },
  {
    name: 'Angola',
    dialCode: '+244',
    isoCode: 'AO',
    format: ['(###)###-###'],
    flag: { 'background-position': '-128px 0' },
  },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    isoCode: 'AI',
    format: ['(264)###-####'],
    flag: { 'background-position': '-80px 0' },
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    isoCode: 'AG',
    format: ['(268)###-####'],
    flag: { 'background-position': '-64px 0' },
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    isoCode: 'AR',
    format: ['(###)###-####'],
    flag: { 'background-position': '-144px 0' },
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    isoCode: 'AM',
    format: ['##-###-###'],
    flag: { 'background-position': '-112px 0' },
  },
  { name: 'Aruba', dialCode: '+297', isoCode: 'AW', format: ['###-####'], flag: { 'background-position': '-208px 0' } },
  {
    name: 'Australia',
    dialCode: '+61',
    isoCode: 'AU',
    format: ['#-####-####'],
    flag: { 'background-position': '-192px 0' },
  },
  {
    name: 'Austria',
    dialCode: '+43',
    isoCode: 'AT',
    format: ['(###)###-####'],
    flag: { 'background-position': '-176px 0' },
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    isoCode: 'AZ',
    format: ['##-###-##-##'],
    flag: { 'background-position': '-224px 0' },
  },
  {
    name: 'Bahamas',
    dialCode: '+1242',
    isoCode: 'BS',
    format: ['(242)###-####'],
    flag: { 'background-position': '-192px -11px' },
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    isoCode: 'BH',
    format: ['####-####'],
    flag: { 'background-position': '-80px -11px' },
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    isoCode: 'BD',
    format: ['1###-######'],
    flag: { 'background-position': '-16px -11px' },
  },
  {
    name: 'Barbados',
    dialCode: '+1246',
    isoCode: 'BB',
    format: ['(246)###-####'],
    flag: { 'background-position': '0 -11px' },
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    isoCode: 'BY',
    format: ['(##)###-##-##'],
    flag: { 'background-position': '-240px -11px' },
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    isoCode: 'BE',
    format: ['(###)###-###'],
    flag: { 'background-position': '-32px -11px' },
  },
  { name: 'Belize', dialCode: '+501', isoCode: 'BZ', format: ['###-####'], flag: { 'background-position': '0 -22px' } },
  {
    name: 'Benin',
    dialCode: '+229',
    isoCode: 'BJ',
    format: ['##-##-####'],
    flag: { 'background-position': '-112px -11px' },
  },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    isoCode: 'BM',
    format: ['(441)###-####'],
    flag: { 'background-position': '-128px -11px' },
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    isoCode: 'BT',
    format: ['##-###-###', '77-###-###', '#-###-###'],
    flag: { 'background-position': '-208px -11px' },
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    isoCode: 'BO',
    format: ['#-###-####'],
    flag: { 'background-position': '-160px -11px' },
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    isoCode: 'BA',
    format: ['##-####', '##-#####'],
    flag: { 'background-position': '-240px 0' },
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    isoCode: 'BW',
    format: ['##-###-###'],
    flag: { 'background-position': '-224px -11px' },
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    isoCode: 'BR',
    format: ['(##)####-####', '(##)#####-####'],
    flag: { 'background-position': '-176px -11px' },
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    isoCode: 'IO',
    format: ['###-####'],
    flag: { 'background-position': '-224px -55px' },
  },
  {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    isoCode: 'BN',
    format: ['###-####'],
    flag: { 'background-position': '-144px -11px' },
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    isoCode: 'BG',
    format: ['(###)###-###'],
    flag: { 'background-position': '-64px -11px' },
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    isoCode: 'BF',
    format: ['##-##-####'],
    flag: { 'background-position': '-48px -11px' },
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    isoCode: 'BI',
    format: ['##-##-####'],
    flag: { 'background-position': '-96px -11px' },
  },
  {
    name: 'Cambodia',
    dialCode: '+855',
    isoCode: 'KH',
    format: ['##-###-###'],
    flag: { 'background-position': '-128px -66px' },
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    isoCode: 'CM',
    format: ['####-####'],
    flag: { 'background-position': '-144px -22px' },
  },
  {
    name: 'Canada',
    dialCode: '+1',
    isoCode: 'CA',
    format: ['(###)###-####'],
    flag: { 'background-position': '-16px -22px' },
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    isoCode: 'CV',
    format: ['(###)##-##'],
    flag: { 'background-position': '-224px -22px' },
  },
  {
    name: 'Cayman Islands',
    dialCode: '+1345',
    isoCode: 'KY',
    format: ['(345)###-####'],
    flag: { 'background-position': '-240px -66px' },
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    isoCode: 'CF',
    format: ['##-##-####'],
    flag: { 'background-position': '-48px -22px' },
  },
  {
    name: 'Chad',
    dialCode: '+235',
    isoCode: 'TD',
    format: ['##-##-##-##'],
    flag: { 'background-position': '-112px -132px' },
  },
  {
    name: 'Chile',
    dialCode: '+56',
    isoCode: 'CL',
    format: ['#-####-####'],
    flag: { 'background-position': '-128px -22px' },
  },
  {
    name: 'China',
    dialCode: '+86',
    isoCode: 'CN',
    format: ['(###)####-###', '(###)####-####', '##-#####-#####'],
    flag: { 'background-position': '-160px -22px' },
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    isoCode: 'CO',
    format: ['(###)###-####'],
    flag: { 'background-position': '-176px -22px' },
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    isoCode: 'KM',
    format: ['##-#####'],
    flag: { 'background-position': '-160px -66px' },
  },
  {
    name: 'Congo',
    dialCode: '+242',
    isoCode: 'CG',
    format: ['##-#####'],
    flag: { 'background-position': '-64px -22px' },
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    isoCode: 'CK',
    format: ['##-###'],
    flag: { 'background-position': '-112px -22px' },
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    isoCode: 'CR',
    format: ['####-####'],
    flag: { 'background-position': '-192px -22px' },
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    isoCode: 'HR',
    format: ['##-###-###'],
    flag: { 'background-position': '-112px -55px' },
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    isoCode: 'CU',
    format: ['#-###-####'],
    flag: { 'background-position': '-208px -22px' },
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    isoCode: 'CY',
    format: ['##-###-###'],
    flag: { 'background-position': '0 -33px' },
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    isoCode: 'CZ',
    format: ['(###)###-###'],
    flag: { 'background-position': '-16px -33px' },
  },
  {
    name: 'Democratic Republic of the Congo',
    dialCode: '+243',
    isoCode: 'CD',
    format: ['(###)###-###'],
    flag: { 'background-position': '-32px -22px' },
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    isoCode: 'DK',
    format: ['##-##-##-##'],
    flag: { 'background-position': '-64px -33px' },
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    isoCode: 'DJ',
    format: ['##-##-##-##'],
    flag: { 'background-position': '-48px -33px' },
  },
  {
    name: 'Dominica',
    dialCode: '+1767',
    isoCode: 'DM',
    format: ['(767)###-####'],
    flag: { 'background-position': '-80px -33px' },
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    isoCode: 'DO',
    format: ['(809)###-####', '(829)###-####', '(849)###-####'],
    flag: { 'background-position': '-96px -33px' },
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    isoCode: 'EC',
    format: ['#-###-####', '##-###-####'],
    flag: { 'background-position': '-128px -33px' },
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    isoCode: 'EG',
    format: ['(###)###-####'],
    flag: { 'background-position': '-160px -33px' },
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    isoCode: 'SV',
    format: ['##-##-####'],
    flag: { 'background-position': '-32px -132px' },
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    isoCode: 'GQ',
    format: ['##-###-####'],
    flag: { 'background-position': '-240px -44px' },
  },
  {
    name: 'Eritrea',
    dialCode: '+291',
    isoCode: 'ER',
    format: ['#-###-###'],
    flag: { 'background-position': '-176px -33px' },
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    isoCode: 'EE',
    format: ['###-####', '####-####'],
    flag: { 'background-position': '-144px -33px' },
  },
  {
    name: 'Eswatini',
    dialCode: '+268',
    isoCode: 'SZ',
    format: ['##-##-####'],
    flag: { 'background-position': '-80px -132px' },
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    isoCode: 'ET',
    format: ['##-###-####'],
    flag: { 'background-position': '-208px -33px' },
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    isoCode: 'FK',
    format: ['#####'],
    flag: { 'background-position': '0 -44px' },
  },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    isoCode: 'FO',
    format: ['###-###'],
    flag: { 'background-position': '-32px -44px' },
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    isoCode: 'FJ',
    format: ['##-#####'],
    flag: { 'background-position': '-240px -33px' },
  },
  {
    name: 'Finland',
    dialCode: '+358',
    isoCode: 'FI',
    format: ['(###)###-##-##'],
    flag: { 'background-position': '-224px -33px' },
  },
  {
    name: 'France',
    dialCode: '+33',
    isoCode: 'FR',
    format: ['(###)###-###'],
    flag: { 'background-position': '-48px -44px' },
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    isoCode: 'GF',
    format: ['#####-####'],
    flag: { 'background-position': '-128px -44px' },
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    isoCode: 'PF',
    format: ['##-##-##'],
    flag: { 'background-position': '-16px -110px' },
  },
  {
    name: 'Gabon',
    dialCode: '+241',
    isoCode: 'GA',
    format: ['#-##-##-##'],
    flag: { 'background-position': '-64px -44px' },
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    isoCode: 'GM',
    format: ['(###)##-##'],
    flag: { 'background-position': '-192px -44px' },
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    isoCode: 'GE',
    format: ['(###)###-###'],
    flag: { 'background-position': '-112px -44px' },
  },
  {
    name: 'Germany',
    dialCode: '+49',
    isoCode: 'DE',
    format: ['###-###', '(###)##-##', '(###)##-###', '(###)##-####', '(###)###-####', '(####)###-####'],
    flag: { 'background-position': '-32px -33px' },
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    isoCode: 'GH',
    format: ['(###)###-###'],
    flag: { 'background-position': '-144px -44px' },
  },
  {
    name: 'Gibraltar',
    dialCode: '+350',
    isoCode: 'GI',
    format: ['###-#####'],
    flag: { 'background-position': '-160px -44px' },
  },
  {
    name: 'Greece',
    dialCode: '+30',
    isoCode: 'GR',
    format: ['(###)###-####'],
    flag: { 'background-position': '0 -55px' },
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    isoCode: 'GL',
    format: ['##-##-##'],
    flag: { 'background-position': '-176px -44px' },
  },
  {
    name: 'Grenada',
    dialCode: '+1473',
    isoCode: 'GD',
    format: ['(473)###-####'],
    flag: { 'background-position': '-96px -44px' },
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    isoCode: 'GP',
    format: ['(###)###-###'],
    flag: { 'background-position': '-224px -44px' },
  },
  {
    name: 'Guam',
    dialCode: '+1671',
    isoCode: 'GU',
    format: ['(671)###-####'],
    flag: { 'background-position': '-32px -55px' },
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    isoCode: 'GT',
    format: ['#-###-####'],
    flag: { 'background-position': '-16px -55px' },
  },
  {
    name: 'Guinea',
    dialCode: '+224',
    isoCode: 'GN',
    format: ['##-###-###'],
    flag: { 'background-position': '-208px -44px' },
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    isoCode: 'GW',
    format: ['#-######'],
    flag: { 'background-position': '-48px -55px' },
  },
  {
    name: 'Guyana',
    dialCode: '+592',
    isoCode: 'GY',
    format: ['###-####'],
    flag: { 'background-position': '-64px -55px' },
  },
  {
    name: 'Haiti',
    dialCode: '+509',
    isoCode: 'HT',
    format: ['##-##-####'],
    flag: { 'background-position': '-128px -55px' },
  },
  {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    isoCode: 'VA',
    format: ['06 698#####'],
    flag: { 'background-position': '-160px -143px' },
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    isoCode: 'HN',
    format: ['####-####'],
    flag: { 'background-position': '-96px -55px' },
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    isoCode: 'HK',
    format: ['####-####'],
    flag: { 'background-position': '-80px -55px' },
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    isoCode: 'HU',
    format: ['(###)###-###'],
    flag: { 'background-position': '-144px -55px' },
  },
  {
    name: 'Iceland',
    dialCode: '+354',
    isoCode: 'IS',
    format: ['###-####'],
    flag: { 'background-position': '-16px -66px' },
  },
  {
    name: 'India',
    dialCode: '+91',
    isoCode: 'IN',
    format: ['(####)###-###'],
    flag: { 'background-position': '-208px -55px' },
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    isoCode: 'ID',
    format: ['##-###-##', '##-###-###', '##-###-####', '(8##)###-###', '(8##)###-##-###'],
    flag: { 'background-position': '-160px -55px' },
  },
  {
    name: 'Iran',
    dialCode: '+98',
    isoCode: 'IR',
    format: ['(###)###-####'],
    flag: { 'background-position': '0 -66px' },
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    isoCode: 'IQ',
    format: ['(###)###-####'],
    flag: { 'background-position': '-240px -55px' },
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    isoCode: 'IE',
    format: ['(###)###-###'],
    flag: { 'background-position': '-176px -55px' },
  },
  {
    name: 'Italy',
    dialCode: '+39',
    isoCode: 'IT',
    format: ['(###)####-###'],
    flag: { 'background-position': '-32px -66px' },
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: '+225',
    isoCode: 'CI',
    format: ['##-###-###'],
    flag: { 'background-position': '-96px -22px' },
  },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    isoCode: 'JM',
    format: ['(876)###-####'],
    flag: { 'background-position': '-48px -66px' },
  },
  {
    name: 'Japan',
    dialCode: '+81',
    isoCode: 'JP',
    format: ['(###)###-###', '##-####-####'],
    flag: { 'background-position': '-80px -66px' },
  },
  {
    name: 'Jersey',
    dialCode: '+44',
    isoCode: 'JE',
    format: ['(####)####-######'],
    flag: { 'background-position': '-144px -154px' },
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    isoCode: 'JO',
    format: ['#-####-####'],
    flag: { 'background-position': '-64px -66px' },
  },
  {
    name: 'Kazakhstan',
    dialCode: '+7',
    isoCode: 'KZ',
    format: ['###-#######'],
    flag: { 'background-position': '0 -77px' },
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    isoCode: 'KE',
    format: ['###-######'],
    flag: { 'background-position': '-96px -66px' },
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    isoCode: 'KI',
    format: ['##-###'],
    flag: { 'background-position': '-144px -66px' },
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    isoCode: 'KP',
    format: ['###-###', '####-####', '##-###-###', '###-####-###', '191-###-####', '####-#############'],
    flag: { 'background-position': '-192px -66px' },
  },
  {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    isoCode: 'KR',
    format: ['##-###-####'],
    flag: { 'background-position': '-208px -66px' },
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    isoCode: 'XK',
    format: ['##-###-###', '###-###-###'],
    flag: { 'background-position': '-128px -154px' },
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    isoCode: 'KW',
    format: ['####-####'],
    flag: { 'background-position': '-224px -66px' },
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    isoCode: 'KG',
    format: ['(###)###-###'],
    flag: { 'background-position': '-112px -66px' },
  },
  {
    name: 'Laos',
    dialCode: '+856',
    isoCode: 'LA',
    format: ['##-###-###', '(20##)###-###'],
    flag: { 'background-position': '-16px -77px' },
  },
  {
    name: 'Latvia',
    dialCode: '+371',
    isoCode: 'LV',
    format: ['##-###-###'],
    flag: { 'background-position': '-160px -77px' },
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    isoCode: 'LB',
    format: ['#-###-###', '##-###-###'],
    flag: { 'background-position': '-32px -77px' },
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    isoCode: 'LS',
    format: ['#-###-####'],
    flag: { 'background-position': '-112px -77px' },
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    isoCode: 'LR',
    format: ['##-###-###'],
    flag: { 'background-position': '-96px -77px' },
  },
  {
    name: 'Libya',
    dialCode: '+218',
    isoCode: 'LY',
    format: ['##-###-###', '21-###-####'],
    flag: { 'background-position': '-176px -77px' },
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    isoCode: 'LI',
    format: ['(###)###-####'],
    flag: { 'background-position': '-64px -77px' },
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    isoCode: 'LT',
    format: ['(###)##-###'],
    flag: { 'background-position': '-128px -77px' },
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    isoCode: 'LU',
    format: ['(###)###-###'],
    flag: { 'background-position': '-144px -77px' },
  },
  {
    name: 'Macau',
    dialCode: '+853',
    isoCode: 'MO',
    format: ['####-####'],
    flag: { 'background-position': '-96px -88px' },
  },
  {
    name: 'Madagascar',
    dialCode: '+261',
    isoCode: 'MG',
    format: ['##-##-#####'],
    flag: { 'background-position': '0 -88px' },
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    isoCode: 'MW',
    format: ['1-###-###', '#-####-####'],
    flag: { 'background-position': '-224px -88px' },
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    isoCode: 'MY',
    format: ['#-###-###', '##-###-###', '(###)###-###', '##-###-####'],
    flag: { 'background-position': '0 -99px' },
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    isoCode: 'MV',
    format: ['###-####'],
    flag: { 'background-position': '-208px -88px' },
  },
  {
    name: 'Mali',
    dialCode: '+223',
    isoCode: 'ML',
    format: ['##-##-####'],
    flag: { 'background-position': '-48px -88px' },
  },
  {
    name: 'Malta',
    dialCode: '+356',
    isoCode: 'MT',
    format: ['####-####'],
    flag: { 'background-position': '-176px -88px' },
  },
  {
    name: 'Marshall Islands',
    dialCode: '+692',
    isoCode: 'MH',
    format: ['###-####'],
    flag: { 'background-position': '-16px -88px' },
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    isoCode: 'MQ',
    format: ['(###)##-##-##'],
    flag: { 'background-position': '-128px -88px' },
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    isoCode: 'MR',
    format: ['##-##-####'],
    flag: { 'background-position': '-144px -88px' },
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    isoCode: 'MU',
    format: ['###-####'],
    flag: { 'background-position': '-192px -88px' },
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    isoCode: 'MX',
    format: ['##-##-####', '(###)###-####'],
    flag: { 'background-position': '-240px -88px' },
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    isoCode: 'FM',
    format: ['###-####'],
    flag: { 'background-position': '-16px -44px' },
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    isoCode: 'MD',
    format: ['####-####'],
    flag: { 'background-position': '-224px -77px' },
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    isoCode: 'MC',
    format: ['##-###-###', '(###)###-###'],
    flag: { 'background-position': '-208px -77px' },
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    isoCode: 'MN',
    format: ['##-##-####'],
    flag: { 'background-position': '-80px -88px' },
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    isoCode: 'ME',
    format: ['##-###-###'],
    flag: { 'background-position': '-112px -154px' },
  },
  {
    name: 'Montserrat',
    dialCode: '+1664',
    isoCode: 'MS',
    format: ['(664)###-####'],
    flag: { 'background-position': '-160px -88px' },
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    isoCode: 'MA',
    format: ['##-####-###'],
    flag: { 'background-position': '-192px -77px' },
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    isoCode: 'MZ',
    format: ['##-###-###'],
    flag: { 'background-position': '-16px -99px' },
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    isoCode: 'MM',
    format: ['###-###', '#-###-###', '##-###-###'],
    flag: { 'background-position': '-64px -88px' },
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    isoCode: 'NA',
    format: ['##-###-####'],
    flag: { 'background-position': '-32px -99px' },
  },
  {
    name: 'Nauru',
    dialCode: '+674',
    isoCode: 'NR',
    format: ['###-####'],
    flag: { 'background-position': '-176px -99px' },
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    isoCode: 'NP',
    format: ['##-###-###'],
    flag: { 'background-position': '-160px -99px' },
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    isoCode: 'NL',
    format: ['##-###-####'],
    flag: { 'background-position': '-128px -99px' },
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    isoCode: 'NC',
    format: ['##-####'],
    flag: { 'background-position': '-48px -99px' },
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    isoCode: 'NZ',
    format: ['#-###-###', '(###)###-###', '(###)###-####'],
    flag: { 'background-position': '-208px -99px' },
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    isoCode: 'NI',
    format: ['####-####'],
    flag: { 'background-position': '-112px -99px' },
  },
  {
    name: 'Niger',
    dialCode: '+227',
    isoCode: 'NE',
    format: ['##-##-####'],
    flag: { 'background-position': '-64px -99px' },
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    isoCode: 'NG',
    format: ['##-###-##', '##-###-###', '(###)###-####'],
    flag: { 'background-position': '-96px -99px' },
  },
  { name: 'Niue', dialCode: '+683', isoCode: 'NU', format: ['####'], flag: { 'background-position': '-192px -99px' } },
  {
    name: 'Norfolk Island',
    dialCode: '+672',
    isoCode: 'NF',
    format: ['3##-###'],
    flag: { 'background-position': '-80px -99px' },
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    isoCode: 'MK',
    format: ['##-###-###'],
    flag: { 'background-position': '-32px -88px' },
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    isoCode: 'MP',
    format: ['(670)###-####'],
    flag: { 'background-position': '-112px -88px' },
  },
  {
    name: 'Norway',
    dialCode: '+47',
    isoCode: 'NO',
    format: ['(###)##-###'],
    flag: { 'background-position': '-144px -99px' },
  },
  {
    name: 'Oman',
    dialCode: '+968',
    isoCode: 'OM',
    format: ['##-###-###'],
    flag: { 'background-position': '-224px -99px' },
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    isoCode: 'PK',
    format: ['(###)###-####'],
    flag: { 'background-position': '-64px -110px' },
  },
  {
    name: 'Palau',
    dialCode: '+680',
    isoCode: 'PW',
    format: ['###-####'],
    flag: { 'background-position': '-160px -110px' },
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    isoCode: 'PS',
    format: ['##-###-####'],
    flag: { 'background-position': '-128px -110px' },
  },
  {
    name: 'Panama',
    dialCode: '+507',
    isoCode: 'PA',
    format: ['###-####'],
    flag: { 'background-position': '-240px -99px' },
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    isoCode: 'PG',
    format: ['(###)##-###'],
    flag: { 'background-position': '-32px -110px' },
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    isoCode: 'PY',
    format: ['(###)###-###'],
    flag: { 'background-position': '-176px -110px' },
  },
  {
    name: 'Peru',
    dialCode: '+51',
    isoCode: 'PE',
    format: ['(###)###-###'],
    flag: { 'background-position': '0 -110px' },
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    isoCode: 'PH',
    format: ['(###)###-####'],
    flag: { 'background-position': '-48px -110px' },
  },
  {
    name: 'Poland',
    dialCode: '+48',
    isoCode: 'PL',
    format: ['(###)###-###'],
    flag: { 'background-position': '-80px -110px' },
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    isoCode: 'PT',
    format: ['##-###-####'],
    flag: { 'background-position': '-144px -110px' },
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    isoCode: 'PR',
    format: ['(787) ### ####', '(939) ### ####'],
    flag: { 'background-position': '-112px -110px' },
  },
  {
    name: 'Qatar',
    dialCode: '+974',
    isoCode: 'QA',
    format: ['####-####'],
    flag: { 'background-position': '-192px -110px' },
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    isoCode: 'RE',
    format: ['#####-####'],
    flag: { 'background-position': '-208px -110px' },
  },
  {
    name: 'Romania',
    dialCode: '+40',
    isoCode: 'RO',
    format: ['##-###-####'],
    flag: { 'background-position': '-224px -110px' },
  },
  {
    name: 'Russia',
    dialCode: '+7',
    isoCode: 'RU',
    format: ['(###)###-##-##'],
    flag: { 'background-position': '0 -121px' },
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    isoCode: 'RW',
    format: ['(###)###-###'],
    flag: { 'background-position': '-16px -121px' },
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    isoCode: 'SH',
    format: ['####'],
    flag: { 'background-position': '-128px -121px' },
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    isoCode: 'KN',
    format: ['(869)###-####'],
    flag: { 'background-position': '-176px -66px' },
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1758',
    isoCode: 'LC',
    format: ['(758)###-####'],
    flag: { 'background-position': '-48px -77px' },
  },
  {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    isoCode: 'PM',
    format: ['##-####'],
    flag: { 'background-position': '-96px -110px' },
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    isoCode: 'VC',
    format: ['(784)###-####'],
    flag: { 'background-position': '-176px -143px' },
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    isoCode: 'WS',
    format: ['##-####'],
    flag: { 'background-position': '-32px -154px' },
  },
  {
    name: 'San Marino',
    dialCode: '+378',
    isoCode: 'SM',
    format: ['####-######'],
    flag: { 'background-position': '-192px -121px' },
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    isoCode: 'ST',
    format: ['##-#####'],
    flag: { 'background-position': '-16px -132px' },
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    isoCode: 'SA',
    format: ['#-###-####', '5#-####-####'],
    flag: { 'background-position': '-32px -121px' },
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    isoCode: 'SN',
    format: ['##-###-####'],
    flag: { 'background-position': '-208px -121px' },
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    isoCode: 'RS',
    format: ['##-###-####'],
    flag: { 'background-position': '-240px -110px' },
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    isoCode: 'SC',
    format: ['#-###-###'],
    flag: { 'background-position': '-64px -121px' },
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    isoCode: 'SL',
    format: ['##-######'],
    flag: { 'background-position': '-176px -121px' },
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    isoCode: 'SG',
    format: ['####-####'],
    flag: { 'background-position': '-112px -121px' },
  },
  {
    name: 'Sint Maarten',
    dialCode: '+1721',
    isoCode: 'SX',
    format: ['(721)###-####'],
    flag: { 'background-position': '-48px -132px' },
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    isoCode: 'SK',
    format: ['(###)###-###'],
    flag: { 'background-position': '-160px -121px' },
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    isoCode: 'SI',
    format: ['##-###-###'],
    flag: { 'background-position': '-144px -121px' },
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    isoCode: 'SB',
    format: ['#####', '###-####'],
    flag: { 'background-position': '-48px -121px' },
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    isoCode: 'SO',
    format: ['#-###-###', '##-###-###'],
    flag: { 'background-position': '-224px -121px' },
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    isoCode: 'ZA',
    format: ['##-###-####'],
    flag: { 'background-position': '-64px -154px' },
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    isoCode: 'SS',
    format: ['##-###-####'],
    flag: { 'background-position': '0 -132px' },
  },
  {
    name: 'Spain',
    dialCode: '+34',
    isoCode: 'ES',
    format: ['(###)###-###'],
    flag: { 'background-position': '-192px -33px' },
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    isoCode: 'LK',
    format: ['##-###-####'],
    flag: { 'background-position': '-80px -77px' },
  },
  {
    name: 'Sudan',
    dialCode: '+249',
    isoCode: 'SD',
    format: ['##-###-####'],
    flag: { 'background-position': '-80px -121px' },
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    isoCode: 'SR',
    format: ['###-###', '###-####'],
    flag: { 'background-position': '-240px -121px' },
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    isoCode: 'SE',
    format: ['##-###-####'],
    flag: { 'background-position': '-96px -121px' },
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    isoCode: 'CH',
    format: ['##-###-####'],
    flag: { 'background-position': '-80px -22px' },
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    isoCode: 'SY',
    format: ['##-####-###'],
    flag: { 'background-position': '-64px -132px' },
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    isoCode: 'TW',
    format: ['####-####', '#-####-####'],
    flag: { 'background-position': '-48px -143px' },
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    isoCode: 'TJ',
    format: ['##-###-####'],
    flag: { 'background-position': '-160px -132px' },
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    isoCode: 'TZ',
    format: ['##-###-####'],
    flag: { 'background-position': '-64px -143px' },
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    isoCode: 'TH',
    format: ['##-###-###', '##-###-####'],
    flag: { 'background-position': '-144px -132px' },
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    isoCode: 'TL',
    format: ['###-####', '77#-#####', '78#-#####'],
    flag: { 'background-position': '-192px -132px' },
  },
  {
    name: 'Togo',
    dialCode: '+228',
    isoCode: 'TG',
    format: ['##-###-###'],
    flag: { 'background-position': '-128px -132px' },
  },
  {
    name: 'Tokelau',
    dialCode: '+690',
    isoCode: 'TK',
    format: ['####'],
    flag: { 'background-position': '-176px -132px' },
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    isoCode: 'TO',
    format: ['#####'],
    flag: { 'background-position': '-240px -132px' },
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    isoCode: 'TT',
    format: ['(868)###-####'],
    flag: { 'background-position': '-16px -143px' },
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    isoCode: 'TN',
    format: ['##-###-###'],
    flag: { 'background-position': '-224px -132px' },
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    isoCode: 'TR',
    format: ['(###)###-####'],
    flag: { 'background-position': '0 -143px' },
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    isoCode: 'TM',
    format: ['#-###-####'],
    flag: { 'background-position': '-208px -132px' },
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    isoCode: 'TC',
    format: ['(249)###-###'],
    flag: { 'background-position': '-96px -132px' },
  },
  {
    name: 'Tuvalu',
    dialCode: '+688',
    isoCode: 'TV',
    format: ['2####', '90####'],
    flag: { 'background-position': '-32px -143px' },
  },
  {
    name: 'Uganda',
    dialCode: '+256',
    isoCode: 'UG',
    format: ['(###)###-###'],
    flag: { 'background-position': '-96px -143px' },
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    isoCode: 'UA',
    format: ['(##)###-##-##'],
    flag: { 'background-position': '-80px -143px' },
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    isoCode: 'AE',
    format: ['#-###-####', '5#-###-####'],
    flag: { 'background-position': '-32px 0' },
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    isoCode: 'GB',
    format: ['##-####-####'],
    flag: { 'background-position': '-80px -44px' },
  },
  {
    name: 'United States',
    dialCode: '+1',
    isoCode: 'US',
    format: ['(###)###-####'],
    flag: { 'background-position': '-112px -143px' },
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    isoCode: 'UY',
    format: ['#-###-##-##'],
    flag: { 'background-position': '-128px -143px' },
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    isoCode: 'UZ',
    format: ['##-###-####'],
    flag: { 'background-position': '-144px -143px' },
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    isoCode: 'VU',
    format: ['#####', '##-#####'],
    flag: { 'background-position': '0 -154px' },
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    isoCode: 'VE',
    format: ['(###)###-####'],
    flag: { 'background-position': '-192px -143px' },
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    isoCode: 'VN',
    format: ['##-####-###', '(###)####-###'],
    flag: { 'background-position': '-240px -143px' },
  },
  {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    isoCode: 'VG',
    format: ['(284)###-####'],
    flag: { 'background-position': '-208px -143px' },
  },
  {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    isoCode: 'VI',
    format: ['(340)###-####'],
    flag: { 'background-position': '-224px -143px' },
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    isoCode: 'WF',
    format: ['##-####'],
    flag: { 'background-position': '-16px -154px' },
  },
  {
    name: 'Yemen',
    dialCode: '+967',
    isoCode: 'YE',
    format: ['#-###-###', '##-###-###', '###-###-###'],
    flag: { 'background-position': '-48px -154px' },
  },
  {
    name: 'Zambia',
    dialCode: '+260',
    isoCode: 'ZM',
    format: ['##-###-####'],
    flag: { 'background-position': '-80px -154px' },
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    isoCode: 'ZW',
    format: ['#-######'],
    flag: { 'background-position': '-96px -154px' },
  },
];
