import { OpenVacanciesStatusId, PurposeEnum } from '@constants';
import { VacancyItem, useOptionsApi, useResizeObserver, useUnitsAPI } from '@innowise-group/core';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@innowise-group/mui-kit';
import { useEffect, useMemo } from 'react';
import { DesktopView } from './components/desktop-view';
import { MobileView } from './components/mobile-view';

interface VacancyFormProps {
  isRequests?: boolean;
  isEdit?: boolean;
  forRequestCreation?: boolean;
  handleClose: () => void;
  onSubmit: (data: VacancyItem) => void;
}

const VacancyForm: React.FC<VacancyFormProps> = (props) => {
  const { watch, setValue, reset } = useFormContext();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);

  const hiringPurpose = watch('hiringPurpose');
  const published = watch('published');
  const countries = watch('countries');
  const cities = watch('cities');
  const { getActualUnits, clearUnitsState } = useUnitsAPI();
  const { countryOptions, locationOptions } = useOptionsApi();

  const locationCountryOptions = useMemo(() => {
    return countryOptions.filter((item) =>
      locationOptions?.find((curr) => curr.parent.toString() === item.value.toString()),
    );
  }, [locationOptions]);

  const locationCityOptions = useMemo(() => {
    return locationOptions.filter((item) =>
      countries?.find((curr) => curr.value.toString() === item.parent.toString()),
    );
  }, [locationOptions, countries]);

  useEffect(() => {
    if (!countries?.length) {
      setValue('cities', []);
      return;
    }
    const filteredCities = cities.filter((item) => countries.find((curr) => curr.value === item.parent));
    setValue('cities', filteredCities);
  }, [countries]);

  useEffect(() => {
    if (hiringPurpose !== PurposeEnum.Trainee) {
      setValue('published', null);
    }
  }, [hiringPurpose]);

  useEffect(() => {
    if (published) {
      reset((prev) => ({
        ...prev,
        published: true,
        vacancyStatusId: OpenVacanciesStatusId,
        vacancyProfessions: [prev.vacancyProfessions[0]],
      }));
    }
  }, [published]);

  useEffect(() => {
    getActualUnits();
    return () => {
      clearUnitsState();
    };
  }, []);

  const Component = isDesktopView ? DesktopView : MobileView;
  return (
    <Component {...props} locationCityOptions={locationCityOptions} locationCountryOptions={locationCountryOptions} />
  );
};

export default VacancyForm;
