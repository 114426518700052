import {
  SelectOption,
  VacancyLanguage,
  VacancyPosition,
  useControlFormField,
  useOptionsApi,
  useUnitsAPI,
  useUnitsAdministrationOptions,
  useValidationSchemaVacancy,
} from '@innowise-group/core';
import { Button, Icon, Select } from '@innowise-group/mui-kit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from './optional-section.styles';
import { MaxLanguagesCount, MaxProfessionsCount } from '@constants';

interface OptionalSectionProps {
  handleNextStep: () => void;
  handlePrevStep: () => void;
  isRequests?: boolean;
  isEdit?: boolean;
  locationCountryOptions: SelectOption[];
  locationCityOptions: SelectOption[];
}

const OptionalSection: React.FC<OptionalSectionProps> = ({
  isEdit,
  isRequests,
  locationCityOptions,
  locationCountryOptions,
  handleNextStep,
  handlePrevStep,
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const unitsOptions = useUnitsAdministrationOptions();
  const { isLoading: isUnitsLoading } = useUnitsAPI();
  const { domainOptions, technologyOptions, gradeOptions, professionOptions, languageLevelOptions, languageOptions } =
    useOptionsApi();
  const published = watch('published');
  const countries = watch('countries');
  const languages = watch('vacancyLanguages');
  const professions = watch('vacancyProfessions');

  const { validationSchema } = useValidationSchemaVacancy();
  const { handleAddItem, handleRemoveItem, handleChangeSelectItem } = useControlFormField(validationSchema);

  return (
    <React.Fragment>
      {!!professions?.length &&
        professions.map((item: VacancyPosition, index: number) => (
          <React.Fragment key={index}>
            <Styled.CardContainer>
              <Controller
                name={`vacancyProfessions.${index}.profession`}
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={handleChangeSelectItem(onChange, 'vacancyProfessions')}
                    options={professionOptions}
                    disabled={isRequests || (isEdit && published)}
                    disableSortByAlphabet={false}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          required={!index}
                          fullWidth
                          label={`${t('pages.vacancies.creationModal.specialty')} ${index ? index + 1 : ''}`}
                          placeholder={t('pages.vacancies.creationModal.selectSpecialty')}
                          error={
                            !!(
                              errors?.vacancyProfessions?.[index]?.profession?.message ||
                              errors?.vacancyProfessions?.[index]?.message ||
                              errors?.vacancyProfessions?.message
                            )
                          }
                          helperText={(
                            errors?.vacancyProfessions?.[index]?.profession?.message ||
                            errors?.vacancyProfessions?.[index]?.message ||
                            errors?.vacancyProfessions?.message
                          )?.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
              <Controller
                name={`vacancyProfessions.${index}.grade`}
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={handleChangeSelectItem(onChange, 'vacancyProfessions')}
                    options={gradeOptions}
                    placeholder={t('pages.vacancies.creationModal.selectGrade')}
                    disabled={isRequests || (isEdit && published)}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={`${t('pages.vacancies.creationModal.grade')} ${index ? index + 1 : ''}`}
                          placeholder={t('pages.candidates.candidateCreation.selectGrade')}
                          error={
                            !!(
                              errors?.vacancyProfessions?.[index]?.grade?.message ||
                              errors?.vacancyProfessions?.[index]?.message
                            )
                          }
                          helperText={(
                            errors?.vacancyProfessions?.[index]?.grade?.message ||
                            errors?.vacancyProfessions?.[index]?.message
                          )?.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
              {professions.length > 1 && !isRequests && !(isEdit && published) && (
                <Styled.DeleteButton
                  variant="outlined"
                  onClick={handleRemoveItem<VacancyPosition>(index, 'vacancyProfessions', professions)}
                >
                  <Styled.DeleteIcon type="u_plus" />
                </Styled.DeleteButton>
              )}
            </Styled.CardContainer>
            {professions?.length < MaxProfessionsCount && !isRequests && index === professions.length - 1 && (
              <Styled.MoreButton
                onClick={handleAddItem('vacancyProfessions', professions, { profession: '', grade: '' })}
                variant="text"
                disabled={published}
                startIcon={<Icon type="u_plus" size={14} />}
              >
                {t('pages.vacancies.creationModal.specialty')}
              </Styled.MoreButton>
            )}
          </React.Fragment>
        ))}
      {!!languages?.length &&
        languages.map((item: VacancyLanguage, index: number) => (
          <React.Fragment key={index}>
            <Styled.CardContainer>
              <Controller
                name={`vacancyLanguages.${index}.language`}
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={handleChangeSelectItem(onChange, 'vacancyLanguages')}
                    options={languageOptions}
                    disabled={isRequests || (isEdit && published)}
                    disableSortByAlphabet={false}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={`${t('pages.vacancies.creationModal.language')} ${index ? index + 1 : ''}`}
                          placeholder={t('pages.vacancies.creationModal.selectLanguage')}
                          error={
                            !!(
                              errors?.vacancyLanguages?.[index]?.language?.message ||
                              errors?.vacancyLanguages?.[index]?.message
                            )
                          }
                          helperText={(
                            errors?.vacancyLanguages?.[index]?.language?.message ||
                            errors?.vacancyLanguages?.[index]?.message
                          )?.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
              <Controller
                name={`vacancyLanguages.${index}.languageLevel`}
                control={control}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                  <Select
                    value={value}
                    onChange={handleChangeSelectItem(onChange, 'vacancyLanguages')}
                    options={languageLevelOptions}
                    disabled={isRequests || (isEdit && published)}
                    renderInput={(params) => {
                      return (
                        <Styled.FullInput
                          {...params}
                          fullWidth
                          label={`${t('pages.vacancies.creationModal.level')} ${index ? index + 1 : ''}`}
                          placeholder={t('pages.vacancies.creationModal.selectLevel')}
                          error={!!errors?.vacancyLanguages?.[index]?.languageLevel?.message}
                          helperText={errors?.vacancyLanguages?.[index]?.languageLevel?.message.toString()}
                        />
                      );
                    }}
                  />
                )}
              />
              {languages.length > 1 && !isRequests && !(isEdit && published) && (
                <Styled.DeleteButton
                  variant="outlined"
                  onClick={handleRemoveItem<VacancyLanguage>(index, 'vacancyLanguages', languages)}
                >
                  <Styled.DeleteIcon type="u_plus" />
                </Styled.DeleteButton>
              )}
            </Styled.CardContainer>
            {languages?.length < MaxLanguagesCount && !isRequests && index === languages.length - 1 && (
              <Styled.MoreButton
                onClick={handleAddItem('vacancyLanguages', languages, { language: '', languageLevel: '' })}
                variant="text"
                disabled={isEdit && published}
                startIcon={<Icon type="u_plus" size={14} />}
              >
                {t('pages.vacancies.creationModal.language')}
              </Styled.MoreButton>
            )}
          </React.Fragment>
        ))}
      <Controller
        name="vacancyTechnologies"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            multiple
            limitTags={1}
            options={technologyOptions}
            disabled={isRequests || (isEdit && published)}
            disableCloseOnSelect
            renderInput={(params) => (
              <Styled.FullInput
                {...params}
                label={t('pages.vacancies.creationModal.technology')}
                placeholder={t('pages.vacancies.creationModal.selectTechnology')}
                error={!!errors?.['vacancyTechnologies']?.message}
                fullWidth
                helperText={errors?.['vacancyTechnologies']?.message.toString()}
              />
            )}
          />
        )}
      />
      <Controller
        name="vacancyDomains"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            multiple
            limitTags={1}
            options={domainOptions}
            disabled={isRequests || (isEdit && published)}
            disableCloseOnSelect
            renderInput={(params) => (
              <Styled.FullInput
                {...params}
                label={t('pages.vacancies.creationModal.domain')}
                placeholder={t('pages.vacancies.creationModal.selectDomain')}
                error={!!errors?.['vacancyDomains']?.message}
                fullWidth
                helperText={errors?.['vacancyDomains']?.message.toString()}
              />
            )}
          />
        )}
      />
      <Controller
        name="countries"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCountryOptions}
            multiple
            limitTags={1}
            disabled={isRequests || (isEdit && published)}
            disableCloseOnSelect
            disableSortByAlphabet={false}
            renderInput={(params) => {
              return (
                <Styled.FullInput
                  {...params}
                  fullWidth
                  label={t('pages.vacancies.creationModal.location')}
                  placeholder={t('pages.vacancies.creationModal.selectLocation')}
                  error={!!errors?.['countries']?.message}
                  helperText={errors?.['countries']?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="cities"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCityOptions}
            limitTags={1}
            multiple
            disabled={isRequests || !countries?.length || (isEdit && published)}
            disableSortByAlphabet={false}
            disableCloseOnSelect
            renderInput={(params) => {
              return (
                <Styled.FullInput
                  {...params}
                  fullWidth
                  label={t('pages.vacancies.creationModal.city')}
                  placeholder={t('pages.vacancies.creationModal.selectTheCity')}
                  error={!!errors?.['cities']?.message}
                  helperText={errors?.['cities']?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="structuralUnit"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Select
              value={value}
              onChange={onChange}
              disabled={isRequests || (isEdit && published)}
              options={unitsOptions}
              loading={isUnitsLoading}
              renderInput={(params) => {
                return (
                  <Styled.FullInput
                    {...params}
                    fullWidth
                    label={t('pages.profile.profileDetails.department')}
                    placeholder={t('pages.candidates.offer.orgUnitPlaceholder')}
                    error={!!errors?.structuralUnitId?.message}
                    helperText={errors?.structuralUnitId?.message.toString()}
                  />
                );
              }}
            />
          );
        }}
      />
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
          {t('buttons.back')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </React.Fragment>
  );
};

export default OptionalSection;
