import { Dispatch, FC, memo, SetStateAction } from 'react';
import * as Styled from './date-segment.styles';
import { DatePicker, LabeledRadioButton } from '@shared-mui-components';
import { BaseFilterType, DateSegmentFilterType, FiltersEntity } from '@innowise-group/core';
import { useTranslation } from 'react-i18next';
import { getDateStringWithoutTimezone } from '@innowise-group/utilities';
import { ResetButton } from '../reset-button';

export interface DateSegmentProps extends Pick<BaseFilterType, 'isOpen' | 'labelKey'> {
  id: string;
  state: DateSegmentFilterType['state'];
  addFilter: (filter: string) => void;
  onValueChange: (args: DateSegmentFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: DateSegmentFilterType['state']) => void;
  onClickFilterOutside: () => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
  setOpenedFilter: Dispatch<SetStateAction<string>>;
}

const DateSegment: FC<DateSegmentProps> = ({
  id,
  state,
  labelKey,
  addFilter,
  removeFilter,
  onValueChange,
  setOpenedFilter,
}) => {
  const { t } = useTranslation();

  const onDateChange = (date: Date) => {
    if (!date) return onValueChange({ ...state, date: null });
    addFilter(id);
    onValueChange({ ...state, date: getDateStringWithoutTimezone(date) });
  };

  const onSegmentChange = (currentSegment: DateSegmentFilterType['state']['currentSegment']) => () => {
    addFilter(id);
    onValueChange({ ...state, currentSegment });
  };

  const removeFilterHandle = () => {
    setOpenedFilter('');
    return removeFilter({
      filter: id,
      updatedState: { ...state, date: null } as unknown as Parameters<typeof removeFilter>[0]['updatedState'],
    });
  };

  return (
    <Styled.FilterWrapper>
      <Styled.Title>{t(labelKey)}</Styled.Title>
      <DatePicker
        InputProps={{ readOnly: true }}
        selected={state.date ? new Date(state.date) : null}
        onChange={onDateChange}
        // TODO: Get min date and year according first "request" in database
        minDate={new Date('2023-01-01T00:00:00')}
        minYear={2023}
        withoutDays
        placeholderText={t('pages.candidates.candidateCreation.from')}
      />
      <Styled.SegmentsContainer>
        {state.segments.map((segment) => (
          <Styled.Segment key={segment}>
            <LabeledRadioButton
              value={'false'}
              onChange={onSegmentChange(segment)}
              checked={state.currentSegment === segment}
              label={t(`filters.union.${segment.toLowerCase()}`)}
            />
          </Styled.Segment>
        ))}
      </Styled.SegmentsContainer>
      <ResetButton onClick={removeFilterHandle}>{t('filters.resetFilter')}</ResetButton>
    </Styled.FilterWrapper>
  );
};

export default memo(DateSegment);
