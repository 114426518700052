import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './comment-deletion-modal.styles';

export interface CommentDeletionModalProps {
  handleDelete: () => void;
  isLoading: boolean;
  isStatus?: boolean;
}

const CommentDeletionModal: React.FC<ModalProps & CommentDeletionModalProps> = ({
  close,
  handleDelete,
  isLoading,
  isStatus,
}) => {
  const { t } = useTranslation();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>
          {isStatus ? t('pages.candidates.comments.deleteStatus') : t('pages.candidates.comments.deleteComment')}
        </Styled.Header>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Styled.Description>
            {isStatus
              ? t('pages.candidates.comments.sureDeleteStatus')
              : t('pages.candidates.comments.sureDeleteComment')}
            ?
          </Styled.Description>
        )}
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.delete'),
            onClick: handleDelete,
            highlighted: true,
            type: 'button',
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(CommentDeletionModal, 'comment-deletion');
