import { styled } from '@innowise-group/mui-kit';

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem 1rem;
`;

export const TwoColumnCell = styled.div`
  display: flex;
  grid-column: span 2;
  & .MuiTextField-root {
    width: 100%;
  }
`;

export const ThreeColumnCell = styled.div`
  display: flex;
  grid-column: span 3;
`;

export const FourColumnCell = styled.div`
  display: flex;
  grid-column: span 4;
`;

export const FullColumnsCell = styled.div`
  display: flex;
  grid-column: 1 / -1;
`;
