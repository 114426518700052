import { FC } from 'react';
import { DiffBase, type LabelTransformer } from '../diff-base';

interface DictionaryValuesDiffProps {
  field: 'candidateTechnologies' | 'candidateDomains';
}

const DictionaryValuesDiff: FC<DictionaryValuesDiffProps> = ({ field, ...props }) => {
  const labelTransformer: LabelTransformer<typeof field> = ({ dictionaryValue }) => dictionaryValue.translation;
  return <DiffBase multiple fieldName={field} labelTransformer={labelTransformer} {...props} />;
};

export default DictionaryValuesDiff;
