import { ClaimTypes, FormItemTypes } from '@constants';
import { LocalizedStatusItemResponse, useResizeObserver, useStatusConfigById } from '@innowise-group/core';
import React, { useMemo } from 'react';
import { EmployeeSection } from '../employee-section';
import { useTheme } from '@innowise-group/mui-kit';
import * as Styled from './interested-person-section.styles';
import { useTranslation } from 'react-i18next';

interface InterestedPersonSectionProps {
  status: LocalizedStatusItemResponse;
}

const InterestedPersonSectionFormItems: string[] = [
  FormItemTypes.AGREEMENT,
  FormItemTypes.FEEDBACK,
  FormItemTypes.NOTIFICATION,
];

const InterestedPersonSection: React.FC<InterestedPersonSectionProps> = ({ status }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const statusConfig = useStatusConfigById(status.status?.id);

  const isEmpty = useMemo(() => {
    return (
      !status.hrmProfile?.urlToProfile &&
      statusConfig.formItems.reduce((accum, curr) => {
        if (InterestedPersonSectionFormItems.includes(curr.item)) {
          return false;
        }
        return accum;
      }, true)
    );
  }, [statusConfig]);

  const { feedbackClaim, notificationClaim, agreementClaims } = useMemo(() => {
    const feedbackClaim = status.claims.find((item) => item.claimType === ClaimTypes.FEEDBACK);
    const notificationClaim = status.claims.find((item) => item.claimType === ClaimTypes.NOTIFICATION);
    const data = {
      feedbackClaim: feedbackClaim ? [feedbackClaim] : [],
      notificationClaim: notificationClaim ? [notificationClaim] : [],
      agreementClaims: status.claims.filter((item) => item.claimType === ClaimTypes.AGREEMENT),
    };
    return data;
  }, [status, statusConfig]);

  if (isEmpty) return null;
  return isDesktopView ? (
    <React.Fragment>
      {!!notificationClaim?.length && <EmployeeSection claims={notificationClaim} />}
      {!!agreementClaims?.length && <EmployeeSection claims={agreementClaims} />}
      {!!feedbackClaim?.length && <EmployeeSection claims={feedbackClaim} />}
      {!!status.hrmProfile?.urlToProfile && (
        <Styled.HRMContainer>
          <Styled.HRMTitle>{t('pages.candidates.statuses.hrmLink')}</Styled.HRMTitle>
          <Styled.HRMLink to={status.hrmProfile.urlToProfile} target="_blank">
            {status.hrmProfile.urlToProfile}
          </Styled.HRMLink>
        </Styled.HRMContainer>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>
      {!!notificationClaim?.length && (
        <Styled.NamedSection title={t('pages.candidates.statuses.notification')}>
          <EmployeeSection claims={notificationClaim} />
        </Styled.NamedSection>
      )}
      {!!agreementClaims?.length && (
        <Styled.NamedSection title={t('pages.candidates.statuses.requireAgreement')}>
          <EmployeeSection claims={agreementClaims} />
        </Styled.NamedSection>
      )}
      {!!feedbackClaim?.length && (
        <Styled.NamedSection title={t('pages.candidates.statuses.requireFeedback')}>
          <EmployeeSection claims={feedbackClaim} />
        </Styled.NamedSection>
      )}
      {!!status.hrmProfile?.urlToProfile && (
        <Styled.HRMContainer>
          <Styled.HRMTitle>{t('pages.candidates.statuses.hrmLink')}</Styled.HRMTitle>
          <Styled.HRMLink to={status.hrmProfile.urlToProfile} target="_blank">
            {status.hrmProfile.urlToProfile}
          </Styled.HRMLink>
        </Styled.HRMContainer>
      )}
    </React.Fragment>
  );
};

export default InterestedPersonSection;
