import React from 'react';
import * as Styled from './requirements-step.styles';
import { ReminderSection } from '../reminder-section';
import { CalendarSection } from '../calendar-section';
import { Button } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import { NotificationSection } from '../notification-section';
import { AgreementSection } from '../agreement-section';
import { FeedbackSection } from '../feedback-section';
import { CandidateStatusGroupEnum } from '@constants';
import { CandidateStatusListItem } from '@innowise-group/core';
import { HRMProfile } from '../hrm-profile';
import { ProposalSection } from '../proposal-section';

interface RequirementsStepProps {
  photoId?: number;
  localizedFullName: string;
  isEdit?: boolean;
  eventId?: number;
  statusConfig: CandidateStatusListItem;
  flags: {
    [key: string]: boolean;
  };
  handleNextStep: () => void;
  handlePrevStep: () => void;
}

const RequirementsStep: React.FC<RequirementsStepProps> = ({
  eventId,
  flags,
  isEdit,
  handlePrevStep,
  handleNextStep,
  statusConfig,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Styled.DatesSection>
        {flags['isReminderField'] && <ReminderSection />}
        {flags['isCalendarField'] && <CalendarSection isEdit={isEdit} eventId={eventId} />}
      </Styled.DatesSection>
      {flags['isNotificationField'] && <NotificationSection />}
      {(flags['isAgreementField'] || flags['isFeedbackField']) && (
        <Styled.Title>{t('pages.candidates.statuses.requirements')}</Styled.Title>
      )}
      {flags['isAgreementField'] && <AgreementSection />}
      {flags['isAgreementField'] && flags['isFeedbackField'] && <Styled.Divider />}
      {flags['isFeedbackField'] && <FeedbackSection />}
      {statusConfig.group === CandidateStatusGroupEnum.JOB_ENTRY && <HRMProfile />}
      {statusConfig?.group === CandidateStatusGroupEnum.OFFER && <ProposalSection />}
      <Styled.ButtonsSection>
        <Styled.GrayButton onClick={handlePrevStep} variant="outlined">
          {t('buttons.back')}
        </Styled.GrayButton>
        <Button onClick={handleNextStep}>{t('buttons.next')}</Button>
      </Styled.ButtonsSection>
    </React.Fragment>
  );
};

export default RequirementsStep;
