import { createReducer } from '@reduxjs/toolkit';
import { getVacancyRequestsThunk } from '../vacancies/vacancies.thunk';
import {
  clearRequestsOptionsAction,
  clearRequestsAction,
  clearCurrentRequestsAction,
  clearLinkedCandidatesByRequestAction,
  setTabToOpenAction,
  clearLoggerDataAction,
} from './requests.actions';
import { preloadedRequestsState } from './requests.preloaded-state';
import {
  getRequestByIdThunk,
  createRequestThunk,
  updateRequestThunk,
  getAllRequestsThunk,
  exportRequestsThunk,
  deleteRequestThunk,
  getRequestLoggerDataThunk,
  getSelectedCandidatesByRequestThunk,
  approveSelectedCandidatesFromVacancyResponseThunk,
  deleteCandidateFromVacancyResponseThunk,
} from './requests.thunk';
import { transformArrayToObject, getArrayIds, calculateDateWithoutTimeZone } from '@innowise-group/utilities';
import { logoutAction } from '../common';
import { DateFormats } from '@constants';
import { format } from 'date-fns';

export const requestsReducer = createReducer(preloadedRequestsState, (builder) =>
  builder
    .addCase(createRequestThunk.fulfilled, (state) => {
      state.requests.isLoading = false;
    })
    .addCase(createRequestThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(createRequestThunk.rejected, (state) => {
      state.requests.isLoading = false;
    })
    .addCase(deleteRequestThunk.fulfilled, (state) => {
      state.requests.isLoading = false;
      state.requests.currentRequest = null;
    })
    .addCase(deleteRequestThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(deleteRequestThunk.rejected, (state) => {
      state.requests.isLoading = false;
    })
    // TODO: Normalize implementation <<<BEGIN
    // https://innowise-group.atlassian.net/jira/software/projects/ITSRT/boards/1455?selectedIssue=ITSRT-504&text=504
    // US-6.2:
    // АС-7
    .addCase(setTabToOpenAction, (state, { payload }) => {
      state.tabToOpen = payload;
    })
    .addCase(updateRequestThunk.fulfilled, (state, { payload }) => {
      state.requests.currentRequest = payload;
      state.requests.items[payload.id] = payload;
      state.requests.isLoading = false;
    })
    .addCase(updateRequestThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(updateRequestThunk.rejected, (state) => {
      state.requests.isLoading = false;
    })
    .addCase(clearRequestsOptionsAction, (state) => {
      state.selectOptions = preloadedRequestsState.selectOptions;
    })

    .addCase(getRequestLoggerDataThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.params.page === 1) {
        state.logger.items = transformArrayToObject(payload.content);
        state.logger.ids = getArrayIds(payload.content);
      } else {
        state.logger.items = { ...state.logger.items, ...transformArrayToObject(payload.content) };
        state.logger.ids = [...new Set([...state.logger.ids, ...getArrayIds(payload.content)])];
      }
      state.logger.isLoading = false;
      state.logger.totalPages = payload.totalPages;
      state.logger.totalElements = payload.totalElements;
    })
    .addCase(getRequestLoggerDataThunk.pending, (state) => {
      state.logger.isLoading = true;
    })
    .addCase(getRequestLoggerDataThunk.rejected, (state) => {
      state.logger = preloadedRequestsState.logger;
    })

    .addCase(getVacancyRequestsThunk.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.page === 1) {
        state.requests.items = transformArrayToObject(payload.items);
        state.requests.ids = getArrayIds(payload.items);
      } else {
        state.requests.items = { ...state.requests.items, ...transformArrayToObject(payload.items) };
        state.requests.ids = [...new Set([...state.requests.ids, ...getArrayIds(payload.items)])];
      }
      state.requests.isLoading = false;
      state.requests.totalPages = payload.totalPages;
    })
    .addCase(getVacancyRequestsThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(getVacancyRequestsThunk.rejected, (state) => {
      state.requests = preloadedRequestsState.requests;
    })
    .addCase(getRequestByIdThunk.fulfilled, (state, { payload }) => {
      state.requests.ids = getArrayIds([payload]);
      state.requests.items = transformArrayToObject([payload]);
      state.requests.currentRequest = payload;
      state.requests.isLoading = false;
    })
    .addCase(getRequestByIdThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(getRequestByIdThunk.rejected, (state) => {
      state.requests = preloadedRequestsState.requests;
    })
    .addCase(clearRequestsAction, (state) => {
      state.requests = preloadedRequestsState.requests;
    })
    .addCase(clearCurrentRequestsAction, (state) => {
      state.requests.currentRequest = null;
    })
    .addCase(getAllRequestsThunk.fulfilled, (state, { payload }) => {
      state.requests.items = transformArrayToObject(payload.content);
      state.requests.ids = getArrayIds(payload.content);
      state.requests.isLoading = false;
      state.requests.totalElements = payload.totalElements;
      state.requests.totalPages = payload.totalPages;
    })
    .addCase(getAllRequestsThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(getAllRequestsThunk.rejected, (state) => {
      state.requests = preloadedRequestsState.requests;
    })
    .addCase(exportRequestsThunk.fulfilled, (state) => {
      state.requests.isExportLoading = false;
    })
    .addCase(exportRequestsThunk.pending, (state) => {
      state.requests.isExportLoading = true;
    })
    .addCase(exportRequestsThunk.rejected, (state) => {
      state.requests.isExportLoading = false;
    })
    .addCase(getSelectedCandidatesByRequestThunk.fulfilled, (state, { payload, meta }) => {
      if (state.requests.items[meta.arg.vacancyRequestId]) {
        state.requests.items[meta.arg.vacancyRequestId].linkedCandidatesIsLoading = false;
        state.requests.items[meta.arg.vacancyRequestId].linkedCandidatesIds = getArrayIds(payload);
        state.requests.items[meta.arg.vacancyRequestId].linkedCandidatesItems = transformArrayToObject(payload);
      }
      state.requests.currentRequest.linkedCandidatesIsLoading = false;
      state.requests.currentRequest.linkedCandidatesIds = getArrayIds(payload);
      state.requests.currentRequest.linkedCandidatesItems = transformArrayToObject(payload);
    })
    .addCase(getSelectedCandidatesByRequestThunk.pending, (state, { meta }) => {
      if (state.requests.items[meta.arg.vacancyRequestId]) {
        state.requests.items[meta.arg.vacancyRequestId].linkedCandidatesIsLoading = true;
      }
      state.requests.currentRequest.linkedCandidatesIsLoading = true;
    })
    .addCase(getSelectedCandidatesByRequestThunk.rejected, (state, { meta }) => {
      if (state.requests.items[meta.arg.vacancyRequestId]) {
        state.requests.items[meta.arg.vacancyRequestId].linkedCandidatesIsLoading = false;
      }
      state.requests.currentRequest.linkedCandidatesIsLoading = false;
    })
    .addCase(clearLinkedCandidatesByRequestAction, (state, { payload }) => {
      if (state.requests.items[payload]) {
        state.requests.items[payload].linkedCandidatesIds = [];
        state.requests.items[payload].linkedCandidatesItems = {};
      }
    })
    .addCase(approveSelectedCandidatesFromVacancyResponseThunk.fulfilled, (state, { meta }) => {
      const requestId = state.requests.currentRequest.id;
      state.requests.items[requestId].linkedCandidatesItems = {
        ...state.requests.items[requestId].linkedCandidatesItems,
        [meta.arg.params.vacancyResponseId]: {
          ...state.requests.items[requestId].linkedCandidatesItems[meta.arg.params.vacancyResponseId],
          vacancyClaimApprovals: [
            {
              createdDate: format(calculateDateWithoutTimeZone(new Date()), DateFormats.ISO),
              approval: meta.arg.params.approval,
              createdBy: null,
            },
            ...state.requests.items[requestId].linkedCandidatesItems[meta.arg.params.vacancyResponseId]
              .vacancyClaimApprovals,
          ],
        },
      };
      state.requests.currentRequest.linkedCandidatesItems = {
        ...state.requests.currentRequest.linkedCandidatesItems,
        [meta.arg.params.vacancyResponseId]: {
          ...state.requests.currentRequest.linkedCandidatesItems[meta.arg.params.vacancyResponseId],
          vacancyClaimApprovals: [
            {
              createdDate: format(calculateDateWithoutTimeZone(new Date()), DateFormats.ISO),
              approval: meta.arg.params.approval,
              createdBy: null,
            },
            ...state.requests.currentRequest.linkedCandidatesItems[meta.arg.params.vacancyResponseId]
              .vacancyClaimApprovals,
          ],
        },
      };
    })
    .addCase(deleteCandidateFromVacancyResponseThunk.fulfilled, (state, { payload, meta }) => {
      state.requests.isLoading = false;
      state.requests.currentRequest.linkedCandidatesIds = state.requests.currentRequest.linkedCandidatesIds.filter(
        (item) => item.toString() !== payload,
      );
      state.requests.items[meta.arg.params.vacancyRequestId].linkedCandidatesIds = state.requests.items[
        meta.arg.params.vacancyRequestId
      ].linkedCandidatesIds.filter((item) => item.toString() !== payload);
    })
    .addCase(deleteCandidateFromVacancyResponseThunk.pending, (state) => {
      state.requests.isLoading = true;
    })
    .addCase(deleteCandidateFromVacancyResponseThunk.rejected, (state) => {
      state.requests.isLoading = false;
    })
    .addCase(clearLoggerDataAction, (state) => {
      state.logger = preloadedRequestsState.logger;
    })
    .addCase(logoutAction, () => preloadedRequestsState),
);
