import { styled } from '@innowise-group/mui-kit';
import { Chip } from '@mui/material';

export const CustomChip = styled(Chip)`
  .MuiChip-label {
    ${({ theme }) => theme.fonts.primary[10]}
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    display: block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
    margin: 0 3px;
    min-height: 27px;
    height: auto;
    width: min-content;
    &.Mui-disabled {
      opacity: 0.8;
    }
  }
`;
