import { FC, MouseEvent, useEffect, useMemo, useState, Fragment } from 'react';
import * as Styled from './x-ray-from.styles';
import { useFormContext } from 'react-hook-form';
import * as _ from 'lodash';
import { XrayInput, HoverConsumer } from './components';
import { generateId } from '@innowise-group/utilities';
import { Tooltip } from '@shared-components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { defaultValues } from '../../../../search.data';
import {
  Elements,
  ElementsTypes,
  MainForm,
  useSearchAPI,
  MAX_HISTORY_XRAY_QUERY_ITEMS_COUNT,
  MAX_QUERY_ITEMS,
  GoogleAnalyticsService,
  GoogleAnalyticsEvents,
  useResizeObserver,
} from '@innowise-group/core';
import { useTheme } from '@innowise-group/mui-kit';

type ClickFunction = ({ type, value }: Elements) => () => void;

interface ButtonsUnion {
  tooltipTitle: string;
  type: Exclude<ElementsTypes, ElementsTypes.Input>;
  value: string;
  clickFunction: ClickFunction;
}

const XRayFrom: FC = () => {
  const {
    watch,
    reset,
    trigger,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext<MainForm>();
  const elements = watch('query');
  const [selectedOperation, setSelectedOperation] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { manageXrayMode, addHistoryXrayQuery, historyXrayQueries } = useSearchAPI();

  const deniedIntervalsToChose = useMemo(() => {
    const deniedSet = elements.reduce((acc, val, idx) => {
      if (val?.pairId) {
        return {
          ...acc,
          [val.pairId]: (acc[val.pairId] ? [...acc[val.pairId], idx] : [idx]).sort((a, b) => a - b),
        };
      }
      return acc;
    }, {});
    return Object.values(deniedSet) as number[][];
  }, [elements]);

  useEffect(() => {
    const subscription = watch(({ query }, { name }) => {
      if (name !== 'xRaySearch') {
        const queryString = query.reduce<string>((acc, { type, value }) => {
          if (type === ElementsTypes.Input) {
            return `${acc}${value}`;
          }
          return `${acc} ${value} `;
        }, '');
        setValue('xRaySearch', queryString);
      }
    });

    return () => {
      manageXrayMode(false);
      subscription.unsubscribe();
    };
  }, []);

  const onLogicOperatorButtonClick: ClickFunction =
    ({ type, value }) =>
    () => {
      trigger();
      reset((prev) => ({
        ...prev,
        query: [
          ...(prev.query || []),
          ...[
            { type, value },
            { type: ElementsTypes.Input, value: '' },
          ],
        ],
      }));
    };

  const onInputFocus = (idx: number) => () => {
    if (!(selectedOperation === '" "')) return;
    setSelectedOperation('');
    return reset((prev) => ({
      ...prev,
      query: prev.query.map((el, i) =>
        i === idx
          ? {
              ...el,
              value: `"${el.value}"`,
            }
          : el,
      ),
    }));
  };

  const onOperatorButtonClick: ClickFunction =
    ({ value }) =>
    () => {
      trigger();
      setSelectedOperation((prev) => (prev !== value ? value : ''));
    };

  const onDeleteButtonClick = (currentIdx: number) => () =>
    reset((prev) => {
      const prevIdx = currentIdx - 1;
      const nextIdx = currentIdx + 1;
      const prevElement = prev.query[prevIdx];
      const nextElement = prev.query[nextIdx];
      if (prevElement?.type && nextElement?.type) {
        if (prevElement?.type === ElementsTypes.OpenBracket && nextElement?.type === ElementsTypes.CloseBracket) {
          return {
            ...prev,
            query: prev.query.filter((_, i) => ![prevIdx, nextIdx, currentIdx, prevIdx - 1].includes(i)),
          };
        }
        if (prevElement?.type !== ElementsTypes.OpenBracket && nextElement?.type === ElementsTypes.CloseBracket) {
          return {
            ...prev,
            query: prev.query.filter((_, i) => i !== currentIdx && i !== prevIdx),
          };
        }
      }
      if (!nextElement?.type) {
        return {
          ...prev,
          query: prev.query.filter((_, i) => i !== currentIdx && i !== prevIdx),
        };
      }
      return {
        ...prev,
        query: prev.query.filter((_, i) => i !== currentIdx && i !== nextIdx),
      };
    });

  const isDisabledLogicOperatorButton =
    elements.filter((v) => v.type === ElementsTypes.Input).length >= MAX_QUERY_ITEMS;

  const isDisabledSaveButton = !(historyXrayQueries?.length < MAX_HISTORY_XRAY_QUERY_ITEMS_COUNT);

  const buttons: ButtonsUnion[] = [
    {
      type: ElementsTypes.AndButton,
      value: 'AND',
      tooltipTitle: !isDisabledLogicOperatorButton
        ? t('tooltips.xrayAndButton')
        : t('tooltips.isMaximumSearchFieldsAdded', { count: MAX_QUERY_ITEMS }),
      clickFunction: onLogicOperatorButtonClick,
    },
    {
      type: ElementsTypes.OrButton,
      value: 'OR',
      tooltipTitle: !isDisabledLogicOperatorButton
        ? t('tooltips.xrayOrButton')
        : t('tooltips.isMaximumSearchFieldsAdded', { count: MAX_QUERY_ITEMS }),
      clickFunction: onLogicOperatorButtonClick,
    },
    {
      type: ElementsTypes.NotButton,
      value: 'NOT',
      tooltipTitle: !isDisabledLogicOperatorButton
        ? t('tooltips.xrayNotButton')
        : t('tooltips.isMaximumSearchFieldsAdded', { count: MAX_QUERY_ITEMS }),
      clickFunction: onLogicOperatorButtonClick,
    },
    {
      type: ElementsTypes.QuotesButton,
      value: '" "',
      tooltipTitle: t('tooltips.xrayQuotesButton'),
      clickFunction: onOperatorButtonClick,
    },
    {
      type: ElementsTypes.BracketsButton,
      value: '()',
      tooltipTitle: t('tooltips.xrayBracketsButton'),
      clickFunction: onOperatorButtonClick,
    },
  ];

  const onSelectInputRange = (args: { start: number; end: number }) => {
    const { start, end } = args;
    let startIndex: number;
    let endIndex: number;
    if (start < end) {
      startIndex = start;
      endIndex = end;
    } else {
      startIndex = end;
      endIndex = start;
    }
    const pairId = generateId();
    setSelectedOperation('');
    return reset((prev) => ({
      ...prev,
      query: [
        ...prev.query.slice(0, startIndex),
        { type: ElementsTypes.OpenBracket, value: '(', pairId },
        ...prev.query.slice(startIndex, endIndex + 1),
        { type: ElementsTypes.CloseBracket, value: ')', pairId },
        ...prev.query.slice(endIndex + 1),
      ],
    }));
  };

  const onDeleteBracketsButtonClick = (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    return reset((prev) => ({
      ...prev,
      query: prev.query.filter(({ pairId }) => pairId !== id),
    }));
  };

  const onResetFormButtonClick = () => {
    reset(defaultValues);
    return setSelectedOperation('');
  };

  const onSubmitButtonClick = () => {
    GoogleAnalyticsService.event(GoogleAnalyticsEvents.SubmitXRayForm);
    return reset((prev) => ({
      ...prev,
      debouncedXRaySearch: prev.xRaySearch,
    }));
  };

  const saveRequest = () => {
    GoogleAnalyticsService.event(GoogleAnalyticsEvents.SaveXRayHistory);
    const { query, xRaySearch } = getValues();
    const id = generateId();
    return addHistoryXrayQuery({
      id,
      query: _.cloneDeep(query),
      xRaySearch,
    });
  };

  return (
    <Styled.Wrapper>
      <HoverConsumer
        deniedIntervalsToChose={deniedIntervalsToChose}
        active={selectedOperation === '()'}
        WrapperComponent={Styled.InputsContainer}
        onSelectInputRange={onSelectInputRange}
      >
        {elements.map(({ type, value = '', pairId }, idx, originArray) => {
          if (type === ElementsTypes.Input) {
            const isDisabledInputDeleteButton =
              originArray.findIndex(({ type }) => type === ElementsTypes.Input) === idx;
            return (
              <XrayInput
                role="input"
                placeholder={t('pages.searchPage.searchInputPlaceholder')}
                {...register(`query.${idx}.value`)}
                onFocus={onInputFocus(idx)}
                disabledDelete={isDisabledInputDeleteButton}
                key={idx}
                onButtonClick={onDeleteButtonClick(idx)}
                error={!!errors?.query?.[idx]?.value?.message}
                helperText={errors?.query?.[idx]?.value?.message}
              />
            );
          }
          if ([ElementsTypes.OpenBracket, ElementsTypes.CloseBracket].includes(type)) {
            return (
              <Tooltip
                key={idx}
                placement="top"
                tabIndex={-1}
                title={<Styled.CloseIcon onClick={onDeleteBracketsButtonClick(pairId)} />}
              >
                <Box tabIndex={-1}>
                  <Styled.BracketsButton tabIndex={-1} role="button">
                    {value}
                  </Styled.BracketsButton>
                </Box>
              </Tooltip>
            );
          }
          return (
            <Fragment key={idx}>
              <Styled.SeparateButton variant="text" disabled tabIndex={-1} role="button" key={idx}>
                {value}
              </Styled.SeparateButton>
              {!isDesktopView && <Styled.BreakRow />}
            </Fragment>
          );
        })}
      </HoverConsumer>
      <Styled.BottomContainer>
        <Styled.ButtonsContainer>
          {buttons.map(({ type, value, tooltipTitle, clickFunction }) => (
            <Tooltip placement="bottom-end" title={tooltipTitle} key={type}>
              <Box>
                <Styled.OperatorButton
                  $active={value === selectedOperation}
                  variant="outlined"
                  onClick={clickFunction({ type, value })}
                  disabled={
                    isDisabledLogicOperatorButton &&
                    type !== ElementsTypes.BracketsButton &&
                    type !== ElementsTypes.QuotesButton
                  }
                >
                  {value}
                </Styled.OperatorButton>
              </Box>
            </Tooltip>
          ))}
        </Styled.ButtonsContainer>
        {errors?.query?.message && <Styled.Error>{errors.query.message}</Styled.Error>}
        <Styled.SubmitButtonContainer>
          <Styled.ResetButton variant="outlined" onClick={onResetFormButtonClick}>
            {t('buttons.reset')}
          </Styled.ResetButton>
          <Styled.SubmitButton variant="contained" type="button" onClick={handleSubmit(onSubmitButtonClick)}>
            {t('buttons.apply')}
          </Styled.SubmitButton>
          <Styled.SaveButtonContainer>
            <Tooltip disableHoverListener={!isDisabledSaveButton} title={t('tooltips.disabledXraySaveButton')}>
              <Box>
                <Styled.SaveButton
                  disabled={isDisabledSaveButton}
                  variant="contained"
                  onClick={handleSubmit(saveRequest)}
                >
                  {t('buttons.saveForFeatureSearches')}
                </Styled.SaveButton>
              </Box>
            </Tooltip>
          </Styled.SaveButtonContainer>
        </Styled.SubmitButtonContainer>
      </Styled.BottomContainer>
    </Styled.Wrapper>
  );
};

export default XRayFrom;
