import React from 'react';
import { PaginationProps } from '@mui/material';
import * as Styled from './pagination.styles';

const Pagination: React.FC<PaginationProps> = ({
  boundaryCount = 1,
  siblingCount = 1,
  shape = 'rounded',
  ...props
}) => {
  return <Styled.Pagination {...props} boundaryCount={boundaryCount} siblingCount={siblingCount} shape={shape} />;
};

export default React.memo(Pagination);
