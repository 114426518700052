import { useCallback } from 'react';
import { normalizeDatesInCandidateItemResponse, useAppDispatch, useAppSelector } from '@innowise-group/core';
import { consolidationStateSelector, unresolvedConflictsSelector } from './consolidation.selectors';
import {
  cleanUpFieldDiffAction,
  clearDiffAction,
  ignoreAllDiffsAction,
  removeDuplicateItemFromListAction,
  saveMappedCandidateDiffsAction,
  saveUnsavedCandidateAction,
} from './consolidation.actions';
import {
  compareCandidatesByIdsThunk,
  compareCandidatesThunk,
  getPossibleDuplicatesThunk,
  mergeManyThunk,
} from './consolidation.thunk';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const useDiffApi = () => {
  const { t } = useTranslation();
  const state = useAppSelector(consolidationStateSelector);
  const unresolvedConflictsKeys = useSelector(unresolvedConflictsSelector);

  const unresolvedConflictsNames = unresolvedConflictsKeys.map((key) =>
    t(`pages.candidates.consolidation.unresolvedFields.${key}`),
  );

  const dispatch = useAppDispatch();

  const clearDiff = useCallback(() => dispatch(clearDiffAction()), [dispatch]);

  const ignoreAllDiffs = useCallback(() => dispatch(ignoreAllDiffsAction()), [dispatch]);

  const cleanUpFieldDiff = useCallback(
    (...args: Parameters<typeof cleanUpFieldDiffAction>) => dispatch(cleanUpFieldDiffAction(...args)),
    [dispatch],
  );

  const removeDuplicateItemFromList = useCallback(
    (...args: Parameters<typeof removeDuplicateItemFromListAction>) =>
      dispatch(removeDuplicateItemFromListAction(...args)),
    [dispatch],
  );

  const saveUnsavedCandidate = useCallback(
    (...args: Parameters<typeof saveUnsavedCandidateAction>) => {
      const normalizedCandidate = normalizeDatesInCandidateItemResponse(...args);
      dispatch(saveUnsavedCandidateAction(normalizedCandidate));
    },
    [dispatch],
  );

  const saveMappedCandidateDiffs = useCallback(
    (...args: Parameters<typeof saveMappedCandidateDiffsAction>) => dispatch(saveMappedCandidateDiffsAction(...args)),
    [dispatch],
  );

  const compareCandidatesByIds = useCallback(
    (...args: Parameters<typeof compareCandidatesByIdsThunk>) => dispatch(compareCandidatesByIdsThunk(...args)),
    [dispatch],
  );

  const compareCandidates = useCallback(
    (...args: Parameters<typeof compareCandidatesThunk>) => dispatch(compareCandidatesThunk(...args)),
    [dispatch],
  );

  const getPossibleDuplicates = useCallback(
    (...args: Parameters<typeof getPossibleDuplicatesThunk>) => dispatch(getPossibleDuplicatesThunk(...args)),
    [dispatch],
  );

  const mergeMany = useCallback(
    (...args: Parameters<typeof mergeManyThunk>) => dispatch(mergeManyThunk(...args)),
    [dispatch],
  );

  return {
    ...state,
    unresolvedConflictsNames,
    clearDiff,
    mergeMany,
    ignoreAllDiffs,
    cleanUpFieldDiff,
    compareCandidates,
    saveUnsavedCandidate,
    getPossibleDuplicates,
    compareCandidatesByIds,
    saveMappedCandidateDiffs,
    removeDuplicateItemFromList,
  };
};
