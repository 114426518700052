import { RequestsState } from './requests.types';

export const preloadedRequestsState: RequestsState = {
  requests: {
    isLoading: false,
    isExportLoading: false,
    items: {},
    ids: [],
    currentRequest: null,
    totalElements: 0,
    totalPages: 0,
  },
  selectOptions: {
    isLoading: false,
    items: {},
  },
  // TODO: Normalize implementation <<<BEGIN
  // https://innowise-group.atlassian.net/jira/software/projects/ITSRT/boards/1455?selectedIssue=ITSRT-504&text=504
  // US-6.2:
  // АС-7
  tabToOpen: null,
  logger: {
    isLoading: false,
    items: {},
    ids: [],
    totalElements: 0,
    totalPages: 0,
  },
};
