import { ChangeEventHandler, FC, memo, MouseEvent, useState } from 'react';
import { type FilterSelectProps } from '../../../../types';
import * as Styled from './multi-select.styles';
import { useTranslation } from 'react-i18next';
import { FiltersEntity } from '@innowise-group/core';

interface MultiSelectFilter extends FilterSelectProps {
  id: string;
  singleSelect?: boolean;
  addFilter: (filter: string) => void;
  removeFilter: (args: { filter: string; updatedState: FiltersEntity[keyof FiltersEntity] }) => void;
}

const MultiSelectFilter: FC<MultiSelectFilter> = ({
  onValueChange,
  removeFilter,
  addFilter,
  state,
  labelKey,
  singleSelect,
  id: filterId,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const onClickHandler = (id: string) => (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const updatedState = { ...state, [id]: { ...state[id], active: !state[id].active, comment: '' } };
    const isEmpty = !Object.values(updatedState).some(({ active }) => !!active);
    if (isEmpty) {
      return removeFilter({
        filter: filterId,
        updatedState: updatedState as unknown as Parameters<typeof removeFilter>[0]['updatedState'],
      });
    }
    addFilter(filterId);
    onValueChange(updatedState);
  };

  const onSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    return setSearch(e.currentTarget.value);
  };

  const { selected, rest } = Object.entries(state).reduce(
    (acc, [, { title, value, active }]) => {
      let key;
      if (title.toLowerCase().startsWith(search.toLowerCase()) && active) {
        key = 'selected';
      }
      if (title.toLowerCase().startsWith(search.toLowerCase()) && !active) {
        key = 'rest';
      }
      if (!key) return acc;
      return {
        ...acc,
        total: acc.total + 1,
        [key]: acc.total + 1,
        [key]: {
          ...acc[key],
          count: active ? acc.selected.count + 1 : acc.rest.count + 1,
          ids: [...acc[key].ids, value],
        },
      };
    },
    {
      selected: {
        count: 0,
        ids: [],
      },
      rest: { count: 0, ids: [] },
      total: 0,
    },
  );

  return (
    <Styled.Wrapper>
      <Styled.Input placeholder={t('buttons.search')} label={t(labelKey)} value={search} onChange={onSearch} />
      <Styled.ScrollableWrapper>
        {selected.ids.map((id, idx) => (
          <Styled.SelectItemWrapper key={idx} onClick={onClickHandler(id)}>
            <Styled.Checkbox readOnly defaultChecked id={id} />
            <Styled.SelectItemLabel>{state[id].title}</Styled.SelectItemLabel>
          </Styled.SelectItemWrapper>
        ))}
        {rest.ids.map((id, idx) => (
          <Styled.SelectItemWrapper
            $disabled={!!(singleSelect && selected.ids.length)}
            key={idx}
            onClick={singleSelect && selected.ids.length ? null : onClickHandler(id)}
          >
            <Styled.Checkbox disabled={singleSelect && !!selected.ids.length} readOnly defaultChecked={false} id={id} />
            <Styled.SelectItemLabel>{state[id].title}</Styled.SelectItemLabel>
          </Styled.SelectItemWrapper>
        ))}
      </Styled.ScrollableWrapper>
    </Styled.Wrapper>
  );
};

export default memo(MultiSelectFilter);
