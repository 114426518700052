import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SelectOption } from '@innowise-group/core';

const sortCallback = (a: SelectOption, b: SelectOption) => {
  const nameA = a.title.toUpperCase();
  const nameB = b.title.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
};

export const optionsState = createSelector(
  (state: RootState) => state.options,
  (state) => {
    return {
      ...state,
      ...(state.options
        ? {
            options: {
              ...state.options,
              officeOptions: state.options?.officeOptions?.map((el) => el)?.sort(sortCallback),
              domainOptions: state.options?.domainOptions?.map((el) => el)?.sort(sortCallback),
              citiesOptions: state.options?.citiesOptions?.map((el) => el)?.sort(sortCallback),
              allCitiesOptions: [...(state.options?.citiesOptions || []), ...(state.options?.locationOptions || [])]
                .map((el) => el)
                ?.sort(sortCallback),
              countryOptions: state.options?.countryOptions?.map((el) => el)?.sort(sortCallback),
              languageOptions: state.options?.languageOptions?.map((el) => el)?.sort(sortCallback),
              locationOptions: state.options?.locationOptions?.map((el) => el)?.sort(sortCallback),
              technologyOptions: state.options?.technologyOptions?.map((el) => el)?.sort(sortCallback),
              professionOptions: state.options?.professionOptions?.map((el) => el)?.sort(sortCallback),
              candidateSourceOptions: state.options?.candidateSourceOptions?.map((el) => el)?.sort(sortCallback),
              countriesOptions: state.options?.countriesOptions,
            },
          }
        : {}),
    };
  },
);
