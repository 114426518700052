import { Button } from '@innowise-group/mui-kit';
import { styled } from '@innowise-group/mui-kit';
import { LabeledCheckbox, NumberInput } from '@shared-mui-components';

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  & > * {
    flex: 1;
  }
`;

export const ButtonsSection = styled.div`
  display: flex;
  gap: 0.75rem;
  & > * {
    flex: 1;
  }
  margin-top: 1rem;
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const FullNumberInput = styled(NumberInput)`
  &.MuiTextField-root {
    width: 100% !important;
  }
`;

export const DatePickerContainer = styled.div`
  & .MuiTextField-root {
    width: 100% !important;
  }
  & .MuiOutlinedInput-root {
    padding-right: 2px;
  }
`;

export const CustomLabeledCheckbox = styled(LabeledCheckbox)`
  padding: 8px 0;
`;
