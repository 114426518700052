import { styled } from '@innowise-group/mui-kit';

export const Header = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  max-width: 550px;
`;

export const BodyContainer = styled.div`
  ${({ theme }) => theme.fonts.primary[50]}
  line-height: 22px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  max-width: 550px;
  white-space: pre-wrap;
`;
