import {
  DropdownListContainer,
  Button,
  Tab,
  Tabs,
  styled,
  DropdownListItem as DropdownListItemUI,
  Icon,
} from '@innowise-group/mui-kit';

export const CustomTabs = styled(Tabs)`
  margin: 0;
`;

export const CustomTab = styled(Tab)`
  cursor: pointer;
  padding: 0 12px;
  min-height: 30px;
  min-width: 90px;
  & > div > div {
    left: 3px;
    right: 3px;
  }
  & > * {
    ${({ theme }) => theme.fonts.primary[80]}
    line-height: 25px;
  }
`;

export const TableFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-right: 30px;
  & > * {
    width: auto;
  }
`;

export const FilterIcon = styled(Icon)`
  margin-right: 4px;
  & > svg {
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]} !important;
  }
`;

export const DropdownListItem = styled(DropdownListItemUI)`
  display: flex;
  padding: 0 9px 0 10px;
  align-items: center;
  min-height: 30px;
  width: 100%;
  ${({ theme }) => theme.fonts.primary[10]}
  font-weight: 600;
  line-height: 24px;
  user-select: 'initial';
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  transition: 0.2s;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  &:last-child {
    margin: 0;
  }
`;

export const ListContainer = styled(DropdownListContainer)`
  width: 300px;
  top: 100%;
  left: 15px;
`;

export const GrayButton = styled(Button)`
  position: relative;
  &.MuiButton-root.MuiButton-text {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    &:hover {
      color: ${({ theme }) => theme.palette.general.darkGrey[80]};
      & svg {
        fill: ${({ theme }) => theme.palette.general.darkGrey[80]} !important;
      }
    }
  }
`;
