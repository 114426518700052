import { FC, PropsWithChildren, memo, useContext } from 'react';
import { SortableItemContext } from '../sorable-context';
import { Wrapper } from './draggable.styles';

const Draggable: FC<PropsWithChildren> = ({ children }) => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  return (
    <Wrapper {...attributes} {...listeners} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default memo(Draggable);
