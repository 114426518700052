import React, { TouchEvent, MouseEventHandler, useRef, useEffect } from 'react';
import ReactDom from 'react-dom';
import { MobileBottomMenuProps } from './mobile-bottom-menu.types';
import * as Styled from './mobile-bottom-menu.styles';
import { useGestureDetection } from '@innowise-group/core';
import { useTranslation } from 'react-i18next';

const MobileBottomMenu: React.FC<MobileBottomMenuProps> = ({
  goBack,
  closeMenu,
  onAccept,
  onCancel,
  acceptButtonTitle,
  cancelButtonTitle,
  children,
  open,
  disableCloseOnSwipe,
  direction = 'left',
  className,
}) => {
  const { t } = useTranslation();
  const swipeRef = useRef(null);
  const { isRightSwipe, isLefSwipe, isBottomSwipe, isTopSwipe } = useGestureDetection(swipeRef);

  useEffect(() => {
    if (!disableCloseOnSwipe) {
      if (direction === 'left' && isRightSwipe) closeMenu();
      if (direction === 'right' && isLefSwipe) closeMenu();
      if (direction === 'up' && isBottomSwipe) closeMenu();
      if (direction === 'down' && isTopSwipe) closeMenu();
    }
  }, [isRightSwipe, isLefSwipe, isBottomSwipe, isTopSwipe]);

  const preventTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    return e.stopPropagation();
  };

  const preventEvent: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return ReactDom.createPortal(
    <Styled.Slide direction={direction} in={open} ref={swipeRef}>
      <Styled.ContentContainer onClick={preventEvent}>
        <Styled.TopButtonsContainer>
          {goBack && (
            <Styled.GoBackButton
              onClick={goBack}
              variant="text"
              startIcon={<Styled.CloseIcon size={25} viewBox="0 0 24 24" type="u_angle-left-b" />}
            >
              {t('buttons.back')}
            </Styled.GoBackButton>
          )}
          <Styled.CloseButton
            onClick={closeMenu}
            variant="text"
            endIcon={<Styled.CloseIcon size={25} viewBox="0 0 24 24" type="u_angle-right-b" />}
          >
            {t('buttons.close')}
          </Styled.CloseButton>
        </Styled.TopButtonsContainer>
        <Styled.ChildrenContainer
          className={className}
          onTouchStart={preventTouchMove}
          onTouchMove={preventTouchMove}
          onTouchEnd={preventTouchMove}
        >
          {children}
        </Styled.ChildrenContainer>
        <Styled.ButtonsContainer>
          <Styled.Button onClick={onAccept} variant="contained">
            {acceptButtonTitle}
          </Styled.Button>
          <Styled.Button onClick={onCancel} variant="outlined">
            {cancelButtonTitle}
          </Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.ContentContainer>
    </Styled.Slide>,
    document.body,
  );
};

export default MobileBottomMenu;
