import { AppLanguages, AppRoutes } from '@constants';

export type HightLightedField = {
  [key: string]: string[];
};

type SearchBaseResponse<TContent, TEntityInfo> = {
  searchHits: {
    index: string;
    id: string;
    score: number;
    content: TContent;
    title?: string;
    redirectBaseUrl?: string;
    highlightFields: HightLightedField;
    routing: string;
    entityInfo: TEntityInfo;
  }[];
  totalHits: number;
  maxScore: number;
  empty: boolean;
};

type VacancyRequestContent = {
  id: number;
  comment: string;
};

type VacancyContent = {
  id: number;
  name: string;
  comment: string;
  domains: string[];
  professionsWithGrades: string[];
  technologies: string[];
  languagesWithGrades: string[];
};

type CandidateContent = {
  id: number;
  firstNameRu: string;
  lastNameRu: string;
  firstNameEn: string;
  lastNameEn: string;
  aboutMe: string;
  candidateProfessionsWithGrades: string[];
  candidateTechnologies: string[];
  candidateDomains: string[];
  languagesWithGrades: string[];
  expectedSalaryComment: string;
  candidateExperience: string[];
  candidateEducation: string[];
  candidateCourses: string[];
  candidateSkills: string;
  isBlockedReason: string;
  sourceDetails: string;
  comments: {
    id: number;
    documentType: string;
    comment: string;
  }[];
};

export type SearchVacancyResponse = SearchBaseResponse<VacancyContent, VacancyHistoryItem>;
export type SearchCandidateResponse = SearchBaseResponse<CandidateContent, CandidateHistoryElem>;
export type SearchRequestResponse = SearchBaseResponse<VacancyRequestContent, RequestHistoryItem>;

export type Content =
  | SearchVacancyResponse['searchHits']
  | SearchCandidateResponse['searchHits']
  | SearchRequestResponse['searchHits'];
export type GlobalSearchResponse = SearchVacancyResponse | SearchCandidateResponse | SearchRequestResponse;

export enum SearchEntities {
  Candidates = 'candidates',
  Requests = 'requests',
  Vacancies = 'vacancies',
}

interface HistoryItemBase {
  redirectUrlBasePath: AppRoutes;
  entityId: number;
}

interface VacancyHistoryItem extends HistoryItemBase {
  entity: SearchEntities.Vacancies;
  title: string;
}

interface CandidateHistoryElem extends HistoryItemBase {
  entity: SearchEntities.Candidates;
  title: {
    [key in AppLanguages]: string;
  };
}

interface RequestHistoryItem extends HistoryItemBase {
  entity: SearchEntities.Requests;
  title: number;
}

export interface MappedEntity extends HistoryItemBase {
  entity: SearchEntities;
  title: string | number;
}

export type HistoryEntitiesUnion = VacancyHistoryItem | CandidateHistoryElem | RequestHistoryItem;

export type SearchState = {
  loading: boolean;
  candidatesSearchContent: SearchCandidateResponse['searchHits'];
  vacanciesSearchContent: SearchVacancyResponse['searchHits'];
  requestsSearchContent: SearchRequestResponse['searchHits'];
  searchHistory: {
    entities: HistoryEntitiesUnion[];
    queries: string[];
    xrayQuery: (Pick<MainForm, 'query' | 'xRaySearch'> & { id: string })[];
  };
  isLastPage: boolean;
  activeXrayMode: boolean;
};

export enum ElementsTypes {
  Input = 'input',
  AndButton = 'and-button',
  OrButton = 'or-button',
  NotButton = 'not-button',
  BracketsButton = 'brackets-button',
  QuotesButton = 'quotes-button',
  OpenBracket = 'open-bracket',
  CloseBracket = 'close-bracket',
}

export interface Elements {
  type: ElementsTypes;
  value: string;
  pairId?: string;
}

export interface MainForm {
  globalSearch: string;
  xRaySearch: string;
  debouncedXRaySearch: string;
  query: Elements[];
}
