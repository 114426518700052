import { CurrencyEnum, EmploymentFormsEnum, WorkFormatsEnum, WorkLoadsEnum } from './options-values.constants';
import { CandidateItem, CandidateItemDiff } from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';
import { ContactsType } from './contacts-types.constants';

export interface CandidateItemFormValues extends CandidateItem {
  vacancy?: SelectOption[];
  candidatePrioritizedVacancies?: [];
}

export const candidateDefaultWorkFormats = [
  { dictionaryValueId: WorkFormatsEnum.Office, checked: false },
  { dictionaryValueId: WorkFormatsEnum.Mixed, checked: false },
  { dictionaryValueId: WorkFormatsEnum.Remote, checked: false },
];

export const candidateDefaultWorkloads = [
  { dictionaryValueId: WorkLoadsEnum.PartTime, checked: false },
  { dictionaryValueId: WorkLoadsEnum.FullTime, checked: false },
];

export const candidateDefaultEmploymentForms = [
  { dictionaryValueId: EmploymentFormsEnum.Employee, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Contractor, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Entrepreneur, checked: false },
  { dictionaryValueId: EmploymentFormsEnum.Internship, checked: false },
];

export const candidateFormDefaultValues: CandidateItemFormValues = {
  readyForBusinessTrip: false,
  photoId: 0,
  firstNameRu: '',
  firstNameEn: '',
  lastNameRu: '',
  lastNameEn: '',
  gender: '',
  aboutMe: '',
  candidatePrioritizedVacancies: [],
  birthDate: null,
  email: '',
  citizenshipId: [],
  isBlocked: false,
  isBlockedReason: '',
  visaAvailability: false,
  sourceDetails: '',
  relocationAvailability: false,
  sourceId: '',
  candidateReferral: {
    recommenderEmail: '',
    reward: '',
    referralComment: '',
    startGrade: '',
  },
  candidateContacts: [
    { contactType: ContactsType.TELEGRAM, contact: { value: '' } },
    { contactType: ContactsType.LINKEDIN, contact: { value: '' } },
    { contactType: ContactsType.GITHUB, contact: { value: '' } },
    { contactType: ContactsType.PHONE, contact: { value: '', country: '' } },
    { contactType: ContactsType.ADDITIONAL_PHONE, contact: { value: '', country: '' } },
    { contactType: ContactsType.BEHANCE, contact: { value: '' } },
    { contactType: ContactsType.SKYPE, contact: { value: '' } },
  ],
  candidateExperiences: [
    { company: '', position: '', workedFrom: null, workedTo: null, stillWorking: false, comment: '', itSphere: false },
  ],
  candidateProfessions: [{ professionId: '', gradeId: '' }],
  candidateLanguages: [{ languageId: '', languageLevelId: '' }],
  responsibleEmployee: '',
  candidateVisas: [{ visaTypeId: '', validTo: null, isFullVisaDate: false }],
  candidateSalary: {
    expectedSalaryComment: '',
    expectedSalaryMin: '',
    expectedSalaryMax: '',
    currencyId: CurrencyEnum.USD,
  },
  candidateLocation: {
    locationCountryId: '',
    currentLocationCityId: '',
    currentLocationCountryId: '',
    locationCityId: '',
  },
  candidateDomains: [],
  candidateTechnologies: [],
  candidateWorkFormats: candidateDefaultWorkFormats,
  candidateWorkloads: candidateDefaultWorkloads,
  employmentForms: candidateDefaultEmploymentForms,
  candidateRelocationCountries: [],
  candidateLinks: [{ name: '', url: '' }],
  candidateSkills: '',
  candidateEducations: [
    {
      studyPlace: '',
      profession: '',
      studiedFrom: null,
      studiedTo: null,
      comment: '',
      withoutPeriod: false,
    },
  ],
  vacancy: [],
  filesMeta: [],
  candidateCourses: [{ studyPlace: '', courseName: '', studiedFrom: null, studiedTo: null, comment: '' }],
  vacancyIdWithRequestAttached: '',
};

export interface CandidateItemDiffFormValues extends CandidateItemDiff {
  vacancy?: SelectOption[];
}

export const candidateFormDiffDefaultValues: CandidateItemDiffFormValues = {
  photoId: 0,
  aboutMe: '',
  firstNameRu: '',
  firstNameEn: '',
  lastNameRu: '',
  lastNameEn: '',
  birthDate: null,
  email: '',
  citizenshipId: [],
  isBlocked: false,
  isBlockedReason: '',
  visaAvailability: false,
  sourceDetails: '',
  relocationAvailability: false,
  sourceId: '',
  filesMeta: [],
  candidateContacts: [
    { contactType: ContactsType.TELEGRAM, contact: { value: '' } },
    { contactType: ContactsType.LINKEDIN, contact: { value: '' } },
    { contactType: ContactsType.GITHUB, contact: { value: '' } },
    { contactType: ContactsType.PHONE, contact: { value: '', country: '' } },
    { contactType: ContactsType.ADDITIONAL_PHONE, contact: { value: '', country: '' } },
    { contactType: ContactsType.SKYPE, contact: { value: '' } },
    { contactType: ContactsType.BEHANCE, contact: { value: '' } },
  ],
  candidateExperiences: [
    { company: '', position: '', workedFrom: null, workedTo: null, stillWorking: false, comment: '', itSphere: false },
  ],
  candidateProfessions: [{ professionId: '', gradeId: '' }],
  candidateLanguages: [{ languageId: '', languageLevelId: '' }],
  responsibleEmployee: '',
  // TODO: update after package consolidation
  candidateVisas: [{ visaTypeId: '', validTo: null, isFullVisaDate: false }],
  candidateSalary: {
    expectedSalaryComment: '',
    expectedSalaryMin: '',
    expectedSalaryMax: '',
    currencyId: CurrencyEnum.USD,
  },
  candidateLocation: {
    locationCountryId: '',
    currentLocationCityId: '',
    currentLocationCountryId: '',
    locationCityId: '',
  },
  candidateDomains: [],
  candidateTechnologies: [],
  candidateWorkFormats: candidateDefaultWorkFormats,
  candidateWorkloads: candidateDefaultWorkloads,
  employmentForms: candidateDefaultEmploymentForms,
  candidateRelocationCountries: [],
  candidateLinks: [{ name: '', url: '' }],
  candidateSkills: '',
  candidateEducations: [
    {
      studyPlace: '',
      profession: '',
      studiedFrom: null,
      studiedTo: null,
      comment: '',
    },
  ],
  vacancy: [],
  candidateCourses: [{ studyPlace: '', courseName: '', studiedFrom: null, studiedTo: null, comment: '' }],
};
