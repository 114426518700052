import { FC } from 'react';
import { DiffBaseProps, LabelTransformer, DiffBase } from '../diff-base';
import { useTranslation } from 'react-i18next';

const CitizenshipDiff: FC<Omit<DiffBaseProps, 'labelTransformer' | 'fieldName'>> = (props) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<'citizenships'> = (el) => {
    if (!el?.data?.length) return t('buttons.leaveBlank');
    return el.data.map(({ name }) => name).join();
  };
  return <DiffBase fieldName="citizenships" labelTransformer={labelTransformer} {...props} />;
};

export default CitizenshipDiff;
