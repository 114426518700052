import React, { HTMLAttributes } from 'react';
import { Icon } from '../icon';
import { getNotificationIconByType } from '@innowise-group/utilities';
import * as Styled from './static-notification.styles';
import { NotificationType } from '../notification';

interface StaticNotificationProps extends HTMLAttributes<HTMLDivElement> {
  type: NotificationType;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
}

const StaticNotification: React.FC<StaticNotificationProps> = ({
  type,
  title,
  subtitle,
  onClose,
  children,
  ...props
}) => {
  return (
    <Styled.NotificationContainer {...props} containerType={type}>
      <Icon type={getNotificationIconByType(type)} size={30} />
      <Styled.NotificationContentContainer>
        {children || (
          <>
            <Styled.NotificationTitle>{title}</Styled.NotificationTitle>
            <Styled.NotificationSubTitle>{subtitle}</Styled.NotificationSubTitle>
          </>
        )}
      </Styled.NotificationContentContainer>
      {onClose && <Styled.CustomIcon type="u_multiply" onClick={onClose} />}
    </Styled.NotificationContainer>
  );
};

export default StaticNotification;
