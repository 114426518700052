import { MutableRefObject, useEffect, useState } from 'react';

const MIN_SWIPE_DISTANCE = 30;

interface Coordinates {
  clientX: number;
  clientY: number;
}

const useGestureDetection = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  distance: number = MIN_SWIPE_DISTANCE,
) => {
  const [touchCoordinatesStart, setTouchCoordinatesStart] = useState<Coordinates>({ clientX: 0, clientY: 0 });
  const [touchCoordinatesEnd, setTouchCoordinatesEnd] = useState<Coordinates>({ clientX: 0, clientY: 0 });

  const onTouchStart = (e: TouchEvent) => {
    e.stopPropagation();
    const { clientX, clientY } = e.targetTouches[0];
    return setTouchCoordinatesStart({ clientX, clientY });
  };

  const onTouchMove = (e: TouchEvent) => {
    e.stopPropagation();
    const { clientX, clientY } = e.targetTouches[0];
    return setTouchCoordinatesEnd({ clientX, clientY });
  };

  const onTouchEnd = (e: TouchEvent) => {
    e.stopPropagation();
    setTouchCoordinatesStart({ clientX: 0, clientY: 0 });
    return setTouchCoordinatesEnd({ clientX: 0, clientY: 0 });
  };

  useEffect(() => {
    ref.current.ontouchstart = onTouchStart;
    ref.current.ontouchmove = onTouchMove;
    ref.current.ontouchend = onTouchEnd;
  }, []);

  const isBottomSwipe =
    touchCoordinatesStart.clientY < touchCoordinatesEnd.clientY &&
    Math.abs(touchCoordinatesStart.clientY - touchCoordinatesEnd.clientY) > distance;
  const isTopSwipe =
    touchCoordinatesStart.clientY > touchCoordinatesEnd.clientY &&
    Math.abs(touchCoordinatesStart.clientY - touchCoordinatesEnd.clientY) > distance;
  const isLefSwipe =
    touchCoordinatesStart.clientX > touchCoordinatesEnd.clientX &&
    Math.abs(touchCoordinatesStart.clientX - touchCoordinatesEnd.clientX) > distance;
  const isRightSwipe =
    touchCoordinatesStart.clientX < touchCoordinatesEnd.clientX &&
    Math.abs(touchCoordinatesStart.clientX - touchCoordinatesEnd.clientX) > distance;

  const isOnlyRightSwipe = isRightSwipe && !isLefSwipe && !isTopSwipe && !isBottomSwipe;
  const isOnlyLeftSwipe = isLefSwipe && !isRightSwipe && !isTopSwipe && !isBottomSwipe;
  const isOnlyTopSwipe = isTopSwipe && !isRightSwipe && !isLefSwipe && !isBottomSwipe;
  const isOnlyBottomSwipe = isBottomSwipe && !isRightSwipe && !isLefSwipe && !isTopSwipe;

  return {
    touchCoordinatesEnd,
    touchCoordinatesStart,
    isTopSwipe: isOnlyTopSwipe,
    isLefSwipe: isOnlyLeftSwipe,
    isRightSwipe: isOnlyRightSwipe,
    isBottomSwipe: isOnlyBottomSwipe,
  };
};

export default useGestureDetection;
