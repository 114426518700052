import { Typography } from '../typography';
import { styled } from '../theme';

export const FormContainer = styled.form`
  position: relative;
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormFieldLabel = styled(Typography)<{ required?: boolean; disabled?: boolean }>`
  margin-bottom: 8px;
  min-height: 22px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 22px;
  position: relative;
  &::after {
    content: ${({ required }) => (required ? "'*'" : ' ')};
    color: ${({ theme }) => theme.palette.state.error.default};
    padding-left: 3px;
  }
`;

export const FormFieldError = styled.div<{ width?: number }>`
  color: ${({ theme }) => theme.palette.state.error.default};
  position: relative;
  ${({ theme }) => theme.fonts.primary[10]}
  min-height: 17px;
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const FormActionsContainer = styled.div`
  display: flex;
  padding-top: 20px;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
