import { styled } from '@innowise-group/mui-kit';
import { ReadOnlyChip } from '../read-only-chip';

export const CustomChip = styled(ReadOnlyChip)`
  .MuiChip-label {
    color: ${({ theme }) => theme.palette.info.default};
  }
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.info.backgroundSecondary};
    &:hover {
      background-color: ${({ theme }) => theme.palette.info.backgroundSecondary};
    }
  }
`;
