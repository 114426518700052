import { Modal, ModalProps, modalHoc } from '@innowise-group/mui-kit';
import { Loader } from '@innowise-group/mui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './file-access-modal.styles';
import { useCandidateFilesAPI } from '@innowise-group/core';

interface FileAccessModalProps {
  successCallback: () => void;
  closeCallback: () => void;
}

const FileAccessModal: React.FC<ModalProps & FileAccessModalProps> = ({ closeCallback, successCallback }) => {
  const { t } = useTranslation();
  const { isFilesIdsLoading } = useCandidateFilesAPI();
  return (
    <Modal.Container size="small">
      <Modal.Header onClose={closeCallback}>
        <Styled.ModalTitle>{t('pages.candidates.fileAccessModal.infoAccess')}</Styled.ModalTitle>
      </Modal.Header>
      {isFilesIdsLoading ? (
        <Loader />
      ) : (
        <>
          <Modal.Body>
            <Styled.BodyContainer>{t('pages.candidates.fileAccessModal.restrictAccess')}?</Styled.BodyContainer>
          </Modal.Body>
          <Modal.Actions
            noVerticalPadding={true}
            position={'full'}
            actions={[
              {
                title: t('buttons.no'),
                onClick: closeCallback,
                type: 'button',
              },
              {
                title: t('buttons.yes'),
                onClick: successCallback,
                highlighted: true,
                type: 'button',
              },
            ]}
          />
        </>
      )}
    </Modal.Container>
  );
};

export default modalHoc(FileAccessModal, 'file-access');
