import { styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.span``;

export const Value = styled.span`
  display: inline-block;
  margin-left: 5px;
  padding: 5px;
  border-radius: 3px;
  background: ${({ theme }) => theme.palette.general.lightGrey[50]};
`;
