import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './calendar-section.styles';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { DateFormats } from '@constants';
import { format } from 'date-fns';
import { DatePicker } from '@shared-mui-components';
import { useCalendarAPI, useCalendarTimeSlots, useProfileAPI } from '@innowise-group/core';
import { InfoTooltip } from '@shared-components';

interface CalendarSectionProps {
  isEdit?: boolean;
  eventId?: number;
}

const CalendarSection: React.FC<CalendarSectionProps> = ({ isEdit, eventId }) => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const includedTimes = useCalendarTimeSlots();
  const { fetchTimeSlots } = useCalendarAPI();
  const { control, watch, trigger } = useFormContext();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const isFirstRender = useRef(true);

  const resetDate = (onChange: (value: Date) => void) => () => {
    onChange(null);
  };
  const eventDate = watch('eventDate');
  const eventDateDay = eventDate ? format(new Date(eventDate), DateFormats.MonthFirst) : null;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      trigger('claims');
      trigger('agreements');
    }
  }, [eventDate, trigger]);

  const filterPassedTime = (time) => {
    const currentDate = startDate;
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const getTimeSlots = useCallback(() => {
    const agreements = watch('agreements');
    const claims = watch('claims');
    const employeeIds = [profile.id];
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      if (element.employeeId) employeeIds.push(element.employeeId);
    }
    for (let index = 0; index < claims.length; index++) {
      const element = claims[index];
      if (element.employeeId) employeeIds.push(element.employeeId);
    }
    const fromDate = eventDateDay ? new Date(eventDateDay) : new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const toDate = eventDateDay ? new Date(eventDateDay) : new Date();
    toDate.setDate(toDate.getDate() + 1);

    fetchTimeSlots({
      ...(isEdit && eventId && { excludeEventId: eventId }),
      employeeIds,
      fromDate: format(fromDate, DateFormats.YearFirst),
      toDate: format(toDate, DateFormats.YearFirst),
    });
  }, [eventDateDay, isEdit, eventId]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots]);

  const renderCustomInput =
    (value: Date, errors: FieldErrors<FieldValues>, resetDate: () => void) =>
    (handleClick: (event: React.MouseEvent<HTMLInputElement>) => void) => {
      const handleResetDate = (event: React.MouseEvent<HTMLInputElement>) => {
        resetDate();
        event.stopPropagation();
      };
      const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
        setStartDate(new Date());
        getTimeSlots();
        handleClick(event);
      };

      return (
        <Styled.RequiredBlock required={!value && !!errors?.eventDate?.message} onClick={handleOpen}>
          {!!value && (
            <React.Fragment>
              <Styled.ResetIcon type="u_multiply" onClick={handleResetDate} size={18} />
              <Styled.DateContainer isError={!!errors?.eventDate?.message}>
                {format(new Date(value), DateFormats.DaySlashFirst)}
              </Styled.DateContainer>
              <Styled.DateContainer isError={!!errors?.eventDate?.message}>
                {format(new Date(value), DateFormats.OnlyTime)}
              </Styled.DateContainer>
            </React.Fragment>
          )}
          {value ? (
            <Styled.CalendarIcon type="u_calendar" size={18} />
          ) : (
            <Styled.CustomButton variant="text">
              <Styled.CalendarIcon type="u_calendar" size={18} />
              {t('buttons.calendar')}
            </Styled.CustomButton>
          )}
        </Styled.RequiredBlock>
      );
    };

  const getTimeClassNames = (date) => {
    const isAvailable = includedTimes.find((item) => new Date(item).getTime() === new Date(date).getTime());
    if (isAvailable) return '';
    return 'already-taken';
  };

  return (
    <Controller
      name="eventDate"
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <Styled.DateValueContainer>
            <DatePicker
              withTime
              selected={value}
              value={value}
              isConstrained={false}
              onChange={onChange}
              startDate={startDate}
              filterTime={filterPassedTime}
              minYear={startDate.getFullYear()}
              timeClassName={getTimeClassNames}
              renderCustomInput={renderCustomInput(value, errors, resetDate(onChange))}
            />
            <InfoTooltip title={t('tooltips.timeSlots')} placement="top-end" />
          </Styled.DateValueContainer>
        );
      }}
    />
  );
};

export default CalendarSection;
