import React, { PropsWithChildren } from 'react';
import { SkeletonProps as MuiSkeletonProps } from '@mui/material';
import * as Styled from './skeleton.styles';

interface SkeletonProps extends MuiSkeletonProps, PropsWithChildren {
  isLoading: boolean;
}

const Skeleton: React.FC<SkeletonProps> = ({ isLoading, children, animation = 'wave', ...props }) => {
  return isLoading ? (
    <Styled.Skeleton animation={animation} {...props}>
      {children}
    </Styled.Skeleton>
  ) : (
    <div>{children}</div>
  );
};

export default React.memo(Skeleton);
