import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelTransformer, DiffBase } from '../diff-base';

interface StringArrayDataDiffProps {
  field: 'candidateWorkloads' | 'employmentForms' | 'candidateWorkFormats';
  i18Prefix:
    | 'options.employmentFormsOptions_'
    | 'options.candidateWorkloadsOptions_'
    | 'options.candidateWorkFormatsOptions_';
}

const StringArrayDataDiff: FC<StringArrayDataDiffProps> = ({ field, i18Prefix, ...props }) => {
  const { t } = useTranslation();
  const labelTransformer: LabelTransformer<typeof field> = (el) => {
    if (!el?.data?.length) return t('buttons.leaveBlank');
    return el.data.map((e) => t(`${i18Prefix}${e}`)).join();
  };
  return <DiffBase fieldName={field} labelTransformer={labelTransformer} {...props} />;
};

export default StringArrayDataDiff;
