import { NAVIGATION_BAR_LAPTOP_WIDTH, NAVIGATION_BAR_WIDTH } from '@constants';
import { styled } from '@innowise-group/mui-kit';

export const AppContainer = styled.section`
  flex: 1;
  display: flex;
  max-height: calc(100% - 80px);
  background-color: ${({ theme }) => theme.palette.general.lightGrey[40]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  }
`;

export const ContentContainer = styled.section`
  position: relative;
  z-index: 3;
  flex: 1;
  max-width: 100%;
  overflow-x: auto;

  /* ::-webkit-scrollbar {
    display: none;
  } */

  @media (max-width: ${NAVIGATION_BAR_LAPTOP_WIDTH}px) {
    padding-left: 3.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;

export const TonedBlock = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: ${NAVIGATION_BAR_WIDTH}rem;
  bottom: 0;
  z-index: 1305;
  background: ${({ theme }) => theme.palette.general.darkGrey[100]}4D;
  @media (max-width: ${NAVIGATION_BAR_LAPTOP_WIDTH}px) {
    display: block;
  }
`;
