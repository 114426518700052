import { memo, useEffect, useRef, useState } from 'react';
import * as Styled from './boolean.styles';
import { BaseFilterType, BooleanFilterType } from '@innowise-group/core';
import { FilterButton } from '../shared';
import { useTranslation } from 'react-i18next';
import { LabeledRadioButton } from '@shared-mui-components';
export interface BooleanFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: BooleanFilterType['state'];
  onValueChange: (args: BooleanFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: BooleanFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const BooleanFilter: React.FC<BooleanFilterProps> = ({
  onClickFilterOutside,
  onValueChange,
  onRemoveFilter,
  onToggleFilter,
  state,
  labelKey,
  isOpen,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const onSubmitHandler = () => onValueChange({ ...state, checked: true });
  const onDeclineHanler = () => onValueChange({ ...state, checked: false });
  const onRemoveFilterHandle = () => onRemoveFilter({ checked: false });

  const ref = useRef(null);

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <Styled.Wrapper ref={ref}>
      <FilterButton
        action="delete"
        isActive={isOpen}
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={onRemoveFilterHandle}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          <Styled.SelectedCount>{t(state.checked ? 'buttons.yes' : 'buttons.no')}</Styled.SelectedCount>
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutside}
      >
        <Styled.FilterWrapper>
          <Styled.Label>{t(labelKey)}</Styled.Label>
          <Styled.ControlsContainer>
            <LabeledRadioButton
              onChange={onSubmitHandler}
              checked={state.checked}
              value={state.checked.toString()}
              label={t('buttons.yes')}
            />
            <LabeledRadioButton
              onChange={onDeclineHanler}
              checked={!state.checked}
              value={(!state.checked).toString()}
              label={t('buttons.no')}
            />
          </Styled.ControlsContainer>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Wrapper>
  );
};

export default memo(BooleanFilter);
