import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationSchemaHistoryComment = (isEdit?: boolean) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      changeStoryGroupId: Yup.number().required(t('errors.requiredField')),
      isProtected: Yup.boolean().required(t('errors.requiredField')),
      comment: Yup.string()
        .trim()
        .max(5000, t('errors.characterLimit'))
        .when('root', {
          is: () => !isEdit,
          then: (schema) => schema.required(t('errors.requiredField')),
        }),
    });
  }, [t, isEdit]);

  return { validationSchema };
};
