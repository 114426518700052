import { RouteProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Styled from './not-found.styles';

const NotFound: React.FC<RouteProps> = () => {
  const { t } = useTranslation();

  return (
    <Styled.NotFoundContainer>
      <Styled.Image background="./404_not_found.svg" />
      <Styled.Error type="h1">{t('pages.notFound.error')}</Styled.Error>
      <Styled.ErrorMessage type="h3">{t('pages.notFound.errorMessage')}</Styled.ErrorMessage>
    </Styled.NotFoundContainer>
  );
};

export default NotFound;
