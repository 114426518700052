import { styled } from '@innowise-group/mui-kit';

export const Container = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 4px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => theme.fonts.primary[20]};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[40]};
  }
`;

export const Message = styled.p`
  ${({ theme }) => theme.fonts.primary[20]};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.fonts.primary[40]}
  }
`;
