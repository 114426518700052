import { SelectOption } from '@innowise-group/core';
import React, { useEffect, useRef } from 'react';
import * as Styled from '../../custom-date-picker.styles';
import { getMonths } from './full-date-header.data';

interface FullDateHeaderProps {
  date: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
  startYear: number;
  endYear: number;
  yearsOptions: SelectOption[];
  changeMonth: (value: number) => void;
  changeYear: (value: number) => void;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const FullDateHeader: React.FC<FullDateHeaderProps> = ({
  date,
  decreaseYear,
  increaseYear,
  startYear,
  endYear,
  yearsOptions,
  changeMonth,
  changeYear,
  onChange,
  minDate,
  maxDate,
}) => {
  const months = getMonths();

  return (
    <Styled.CalendarHeader>
      <Styled.ChangeBlock>
        <Styled.CustomSelect
          value={date.getMonth().toString()}
          options={months}
          placeholder={months[date.getMonth()]?.title || ''}
          onValueChange={(value: string) => {
            const newDate = new Date(date);
            newDate.setMonth(Number(value));
            changeMonth(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate.getMonth()
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate.getMonth()
                : Number(value),
            );
            onChange(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate
                : new Date(new Date(date).setMonth(Number(value))),
            );
          }}
          valueTransformer={(value: string) => {
            return months.find((item) => item.value === value)?.title || '';
          }}
          renderCustomListItem={(
            value: SelectOption,
            onClick: () => void,
            selected: boolean,
            parentRef: React.MutableRefObject<HTMLDivElement>,
          ) => {
            return (
              <ListItem
                key={value.value}
                handleSelect={onClick}
                title={value.title}
                selected={selected}
                parentRef={parentRef}
                disabled={
                  (minDate &&
                    Number(value.value) < minDate.getMonth() &&
                    date.getFullYear() <= minDate.getFullYear()) ||
                  (maxDate && Number(value.value) > maxDate.getMonth() && date.getFullYear() >= maxDate.getFullYear())
                }
              />
            );
          }}
        />
        <Styled.CustomSelect
          value={date.getFullYear().toString()}
          options={yearsOptions}
          placeholder={date.getFullYear().toString()}
          onValueChange={(value: string) => {
            const newDate = new Date(date);
            newDate.setFullYear(Number(value));
            changeYear(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate.getFullYear()
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate.getFullYear()
                : Number(value),
            );
            onChange(
              minDate && newDate.getTime() < minDate.getTime()
                ? minDate
                : maxDate && newDate.getTime() > maxDate.getTime()
                ? maxDate
                : new Date(new Date(date).setFullYear(Number(value))),
            );
          }}
          size="small"
          renderCustomListItem={(
            value: SelectOption,
            onClick: () => void,
            selected: boolean,
            parentRef: React.MutableRefObject<HTMLDivElement>,
          ) => {
            return (
              <ListItem
                key={value.value}
                handleSelect={onClick}
                title={value.title}
                selected={selected}
                parentRef={parentRef}
              />
            );
          }}
        />
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.ArrowButton variant="text" onClick={decreaseYear} disabled={date.getFullYear() <= startYear}>
          <Styled.IconItem type="u_angle-right-b" size={16} isLeft />
        </Styled.ArrowButton>
        <Styled.ArrowButton variant="text" onClick={increaseYear} disabled={date.getFullYear() >= endYear}>
          <Styled.IconItem type="u_angle-right-b" size={16} isRight />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
    </Styled.CalendarHeader>
  );
};

const ListItem: React.FC<{
  handleSelect: () => void;
  title: string;
  selected: boolean;
  parentRef: React.MutableRefObject<HTMLDivElement>;
  disabled?: boolean;
}> = ({ handleSelect, title, selected, parentRef, disabled }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selected && itemRef.current && parentRef.current) {
      const topPos = itemRef.current.offsetTop;
      parentRef.current.scrollTop = topPos;
    }
  }, [selected]);

  return (
    <Styled.DropdownListItem
      ref={itemRef}
      onClick={disabled ? null : handleSelect}
      selected={selected}
      disabled={disabled}
    >
      {title}
    </Styled.DropdownListItem>
  );
};

export default FullDateHeader;
