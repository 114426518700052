import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localizedNameObject } from '@innowise-group/utilities';

export const getProfileState = (state: RootState) => state.profile;

export const getProfileDataState = createSelector(getProfileState, (state) => {
  const item = state.item ? { ...state.item, ...localizedNameObject(state.item) } : null;
  return { ...state, item };
});

export const getProfileOrgUnits = createSelector(getProfileState, (state) => {
  // TODO: make a cycle to maintain order
  return state.item?.orgUnits || [];
});

export const getDefaultCreationProfileData = createSelector(getProfileState, ({ item }) => {
  return {
    id: item.id,
    miniPhotoUrl: { url: item.miniPhotoHrmUrl || item.photoHrmUrl || '', id: 0 },
    photoRt: { id: item?.photoRt?.id || 0 },
    phoneRt: {
      value: item.phone?.replace('+', '') || '',
    },
    firstNameRu: item.firstNameRu,
    lastNameRu: item.lastNameRu,
    orgUnits: [],
    position: item.jobTitle.translation,
    telegramRt: item.telegram || '',
    email: item.email,
  };
});

export const getDefaultEditionProfileData = createSelector(getProfileState, ({ item }) => {
  return {
    id: item.id,
    miniPhotoUrl: { id: item?.photoRt?.id || 0 },
    phoneRt: {
      value: item.phoneRt?.replace('+', '') || '',
    },
    firstNameRu: item.firstNameRu,
    lastNameRu: item.lastNameRu,
    orgUnits: [],
    position: item.jobTitle.translation,
    telegramRt: item.telegramRt || '',
    email: item.email,
  };
});
