import {
  ClaimTypes,
  ContactsIcons,
  ContactsType,
  DateFormats,
  LifecycleStatuses,
  RolesValues,
  StatusLifecycleStatuses,
} from '@constants';
import {
  calculateDateWithTimeZone,
  calculateWordDeclension,
  candidateHistoryMapper,
  localizedNameObject,
} from '@innowise-group/utilities';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import i18n from '@localization';
import { mapCandidateItemResponseToCandidateItem } from './candidates.mappers';
import { format } from 'date-fns';
import { CandidateEventCommentResponse, CandidateOfferItem } from '@innowise-group/core';

export const getCandidatesState = (state: RootState) => state.candidates;

export const getCandidatesFilesToAttachState = createSelector(getCandidatesState, (state) => {
  return state.filesToAttach;
});

export const getCandidatesOffersState = createSelector(getCandidatesState, (state) => {
  return state.offers;
});

export const getCandidatesRequestClaimState = createSelector(getCandidatesState, (state) => {
  return state.claim;
});

export const getRecruiterCandidatesState = createSelector(getCandidatesState, (state) => {
  return state.recruiterMode;
});

export const getCandidatesEventsState = createSelector(getCandidatesState, (state) => {
  const events = state.events.ids.reduce((accum, item) => {
    const event = state.events.items[item];
    accum[item] = {
      ...event,
      author: { ...event.author, ...localizedNameObject(event.author) },
      claims: event.claims.map((curr) => {
        return { ...curr, employee: { ...curr.employee, ...localizedNameObject(curr.employee) } };
      }),
      comment: {
        ...event.comment,
        author: {
          ...event.comment.author,
          ...localizedNameObject(event.comment.author),
        },
        commentThread: (event?.comment?.commentThread || []).map((curr) => {
          return { ...curr, author: { ...curr.author, ...localizedNameObject(curr.author) } };
        }),
      },
    };
    return accum;
  }, {});
  return { ...state.events, items: events };
});

export const getCandidateDeletedFilesIds = createSelector(getCandidatesState, (state) => state.files.deletedIds);

export const getCandidatesFilesIdsEditionState = createSelector(getCandidatesState, (state) => {
  return {
    ids: state.filesToAttach.ids.concat(state.files.ids),
    allFiles: Object.assign({}, state.files.items, state.filesToAttach.items),
  };
});

export const getCandidatesFilesState = createSelector(getCandidatesState, (state) => {
  return state.files;
});

export const getCandidatesAllStatusesState = createSelector(getCandidatesState, (state) => {
  const ids = [...state.allStatuses.ids].sort((idA, idB) => {
    if (
      state.allStatuses.items[idA].lifecycleStatus === StatusLifecycleStatuses.Deleted &&
      state.allStatuses.items[idB].lifecycleStatus !== StatusLifecycleStatuses.Deleted
    ) {
      return 1;
    } else if (
      state.allStatuses.items[idA].lifecycleStatus !== StatusLifecycleStatuses.Deleted &&
      state.allStatuses.items[idB].lifecycleStatus === StatusLifecycleStatuses.Deleted
    ) {
      return -1;
    } else {
      if (state.allStatuses.items[idA].orderValue !== state.allStatuses.items[idB].orderValue) {
        return (state.allStatuses.items[idA].orderValue || 0) - (state.allStatuses.items[idB].orderValue || 0);
      }
      return 0;
    }
  });
  return { ...state.allStatuses, ids };
});

export const getCurrentStatusInfo = createSelector(getCandidatesState, (state) => {
  return state.currentStatus;
});

export const getCurrentStatusState = createSelector(getCandidatesState, (state) => {
  if (!state.currentStatus.response?.comment) return null;
  const claims = state.currentStatus.form.claims?.filter((item) => item.claimType !== ClaimTypes.AGREEMENT) || [];
  return {
    ...(state.currentStatus.response
      ? {
          response: {
            ...state.currentStatus.response,
            comment: {
              ...state.currentStatus.response.comment,
              commentThread: state.currentStatus.response.comment.commentThread.map((item) => {
                const newThread = (thread: CandidateEventCommentResponse) => {
                  return {
                    ...thread,
                    author: {
                      ...item.author,
                      ...localizedNameObject(item.author),
                      commentThread: item.commentThread.map((curr) => newThread(curr)),
                    },
                  };
                };
                return newThread(item);
              }),
              author: {
                ...state.currentStatus.response.comment.author,
                ...localizedNameObject(state.currentStatus.response.comment.author),
              },
            },
            claims: state.currentStatus.response?.claims?.map((curr) => {
              return { ...curr, employee: { ...curr.employee, ...localizedNameObject(curr.employee) } };
            }),
            author: {
              ...state.currentStatus.response.author,
              ...localizedNameObject(state.currentStatus.response.author),
            },
          },
        }
      : null),
    form: {
      ...state.currentStatus.form,
      ...(state.currentStatus.form.periodFrom && {
        periodFrom: new Date(format(new Date(state.currentStatus.form.periodFrom), DateFormats.ISO)),
      }),
      ...(state.currentStatus.form.periodTo && {
        periodTo: new Date(format(new Date(state.currentStatus.form.periodTo), DateFormats.ISO)),
      }),
      ...(state.currentStatus.form.reminderDate && {
        reminderDate: new Date(
          format(calculateDateWithTimeZone(state.currentStatus.form.reminderDate), DateFormats.ISO),
        ),
      }),
      ...(state.currentStatus.form.eventDate && {
        eventDate: new Date(format(calculateDateWithTimeZone(state.currentStatus.form.eventDate), DateFormats.ISO)),
      }),
      file: state.currentStatus.form.file || undefined,
      claims: claims.map((item, index) => {
        if (!item) {
          return {
            claimType: Object.values(ClaimTypes)[index],
            isActive: false,
            deadline: null,
            employeeId: '',
            employeeFullName: '',
          };
        }
        return {
          ...item,
          deadline: item.deadline ? format(calculateDateWithTimeZone(item.deadline), DateFormats.ISO) : null,
          ...(item?.employeeId && { employeeId: item.employeeId.toString() }),
        };
      }),
      agreements: state.currentStatus.form.agreements?.map((item) => {
        if (!item) {
          return {
            claimType: ClaimTypes.AGREEMENT,
            isActive: false,
            deadline: null,
            employeeId: '',
            employeeFullName: '',
          };
        }
        return {
          ...item,
          deadline: item.deadline ? format(calculateDateWithTimeZone(item.deadline), DateFormats.ISO) : null,
          ...(item?.employeeId && { employeeId: item.employeeId.toString() }),
        };
      }),
    },
  };
});

export const getActualCandidateStatus = createSelector(getCandidatesEventsState, (state) => {
  const index = state.ids
    .filter((item) => state.items[item].status?.id && state.items[item].lifecycleStatus !== LifecycleStatuses.Deleted)
    .shift();

  const event = state.items[index];
  return {
    ...event,
    author: { ...event.author, ...localizedNameObject(event.author) },
    comment: {
      ...event.comment,
      author: {
        ...event.comment.author,
        ...localizedNameObject(event.comment.author),
      },
      commentThread: (event?.comment?.commentThread || []).map((curr) => {
        return { ...curr, author: { ...curr.author, ...localizedNameObject(curr.author) } };
      }),
    },
  };
});

export const getCandidatesHistoryState = (role: RolesValues) =>
  createSelector(getCandidatesState, (state) => {
    const data = state.history.ids.reduce((accum, id) => {
      accum[id] = {
        ...state.history.items[id],
        hasComments:
          state.history.idsWithComments.includes(id) ||
          (!!state.history.items[id].comments.ids.length && role === RolesValues.Admin),
      };
      return accum;
    }, {});
    const sessions = candidateHistoryMapper(state.history.ids, data, state.candidates.currentCandidate);
    const items = Object.keys(sessions).reduce((accum, curr) => {
      accum[curr] = {
        ...sessions[curr],
        modifier: { ...sessions[curr].modifier, ...localizedNameObject(sessions[curr].modifier) },
      };
      return accum;
    }, {});
    return {
      ...state.history,
      items,
    };
  });

export const getHistoryCommentsState = (historyId: number) =>
  createSelector(getCandidatesState, (state) => {
    const history = state.history.items[historyId];
    if (!history) return { isLoading: false, ids: [], items: {}, deletedIds: [] };
    const comments = history.comments.ids.reduce((accum, curr) => {
      accum[curr] = {
        ...history.comments.items[curr],
        author: { ...history.comments.items[curr].author, ...localizedNameObject(history.comments.items[curr].author) },
      };
      return accum;
    }, {});
    return { ...history.comments, items: comments };
  });

export const getCandidatesItemsState = createSelector(getCandidatesState, (state) => {
  const items = state.candidates.ids.reduce((accum, curr) => {
    accum[curr] = {
      ...state.candidates.items[curr],
      ...localizedNameObject(state.candidates.items[curr]),
      responsibleEmployee: {
        ...state.candidates.items[curr].responsibleEmployee,
        ...localizedNameObject(state.candidates.items[curr].responsibleEmployee),
      },
      ...(state.candidates.items[curr].vacancyIdWithRequestAttached && {
        vacancyIdWithRequestAttached: state.candidates.items[curr].vacancyIdWithRequestAttached.toString(),
      }),
    };
    return accum;
  }, {});
  const currentCandidate = state.candidates.currentCandidate
    ? {
        ...state.candidates.currentCandidate,
        ...localizedNameObject(state.candidates.currentCandidate),
        responsibleEmployee: {
          ...state.candidates.currentCandidate.responsibleEmployee,
          ...localizedNameObject(state.candidates.currentCandidate.responsibleEmployee),
        },
        candidatePrioritizedVacancies: state.candidates.currentCandidate.candidatePrioritizedVacancies.map((item) => {
          return { ...item, ...(item.author && { author: { ...item.author, ...localizedNameObject(item.author) } }) };
        }),
        candidateReferral: state.candidates.currentCandidate.candidateReferral
          ? {
              ...state.candidates.currentCandidate.candidateReferral,
              recommender: {
                ...state.candidates.currentCandidate.candidateReferral.recommender,
                ...localizedNameObject(state.candidates.currentCandidate.candidateReferral.recommender),
              },
            }
          : null,
      }
    : null;
  return { ...state.candidates, items, currentCandidate };
});

export const getCandidateDuplicates = createSelector(getCandidatesState, (state) => ({
  isCheckedDuplicates: state.candidatesConsolidation.isCheckedDuplicates,
  duplicatesIds: state.candidatesConsolidation.candidateDuplicatesIds,
  duplicates: Object.entries(state.candidatesConsolidation.candidateDuplicates).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: {
        ...val,
        ...localizedNameObject(val),
      },
    };
  }, {}),
}));

export const getUnsavedCandidate = createSelector(getCandidatesState, (state) => {
  const candidate = state.candidatesConsolidation.unsavedCandidate || null;
  if (!candidate) return null;
  return candidate;
});

export const getCandidatesDiff = createSelector(getCandidatesState, (state) => {
  return state.candidatesConsolidation;
});

export const getCandidatesConsolidation = createSelector(getCandidatesState, (state) => {
  return state.candidatesConsolidation;
});

export const getCandidateByIdState = (id: number) =>
  createSelector(getCandidatesItemsState, (state) => {
    return state.items[id] || null;
  });

export const getCurrentCandidate = createSelector(getCandidatesItemsState, (state) => {
  return state.currentCandidate || null;
});

export const getCurrentCandidateVacancyOptions = (withoutJobOpening?: boolean) =>
  createSelector(getCurrentCandidate, (state) => {
    return [
      ...(state?.candidatePrioritizedVacancies?.map((item) => {
        return {
          value: item.id.toString(),
          title: item.name,
        };
      }) || []),
      ...(!withoutJobOpening ? [{ value: '0', title: i18n.t('pages.candidates.comments.jobOpening') }] : []),
    ];
  });

export const getCurrentCandidateAvatar = createSelector(getCandidatesItemsState, (state) => {
  return state.currentAvatarFile || null;
});

export const getCandidatesReportState = createSelector(getCandidatesState, (state) => {
  const content = state.report.items.content.map((item) => {
    return {
      ...item,
      candidate: {
        ...item.candidate,
        ...localizedNameObject(item.candidate),
        responsibleEmployee: {
          ...item.candidate.responsibleEmployee,
          ...localizedNameObject(item.candidate.responsibleEmployee),
        },
      },
      possibleDuplicate: {
        ...item.possibleDuplicate,
        ...localizedNameObject(item.possibleDuplicate),
        responsibleEmployee: {
          ...item.possibleDuplicate.responsibleEmployee,
          ...localizedNameObject(item.possibleDuplicate.responsibleEmployee),
        },
      },
    };
  });
  return { ...state.report, items: { ...state.report.items, content } };
});

export const getCandidateContactsItems = createSelector(getCurrentCandidate, (state) => {
  const contacts = [...state.candidateContacts];
  const socialNetworks = contacts.reduce((accum, item) => {
    if (item.contactType !== ContactsType.PHONE && item.contactType !== ContactsType.ADDITIONAL_PHONE) {
      const getLinkText = () => {
        if (item.contactType === ContactsType.GITHUB) {
          return item.contact.replace(/^(http(s?):\/\/)?(www\.)?github\.([a-z])+\//, '@');
        }
        if (item.contactType === ContactsType.LINKEDIN) {
          return item.contact.replace(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\//, '@');
        }
        return '';
      };
      accum.push({
        value: item.contact || '',
        icon: ContactsIcons[item.contactType],
        linkText: getLinkText(),
      });
    }
    return accum;
  }, []);
  const phoneNumber = {
    value: contacts.find((item) => item.contactType === ContactsType.PHONE)?.contact || '',
    title: contacts.find((item) => item.contactType === ContactsType.PHONE)?.contact || '',
  };
  const additionalPhoneNumber = {
    value: contacts.find((item) => item.contactType === ContactsType.ADDITIONAL_PHONE)?.contact || '',
    title: contacts.find((item) => item.contactType === ContactsType.ADDITIONAL_PHONE)?.contact || '',
  };

  return { socialNetworks, phoneNumber, additionalPhoneNumber };
});

export const getCandidateAllExperience = createSelector(getCurrentCandidate, (candidate) => {
  const calculate = (data: { years: number; month: number }) => {
    const translationYearKey = calculateWordDeclension('year', data.years);
    const translationMonthKey = calculateWordDeclension('month', data.month);

    return (
      (data.years ? data.years + ` ${i18n.t(`pages.candidates.candidateDetails.${translationYearKey}`)} ` : '') +
      (data.month ? data.month + ` ${i18n.t(`pages.candidates.candidateDetails.${translationMonthKey}`)} ` : '')
    );
  };

  const experienceYears = {
    years: Math.floor(candidate.candidateOverallExperienceMonths / 12),
    month: candidate.candidateOverallExperienceMonths % 12,
  };
  const experienceITYears = {
    years: Math.floor(candidate.candidateItSphereExperienceMonths / 12),
    month: candidate.candidateItSphereExperienceMonths % 12,
  };

  const experienceWithoutIT =
    (calculate(experienceYears) || 0) + i18n.t('pages.candidates.candidateDetails.ofExperience');
  const experienceOnlyIT = (calculate(experienceITYears) || 0) + i18n.t('pages.candidates.candidateDetails.inIT');

  return { experienceWithoutIT, experienceOnlyIT };
});

export const getDefaultCandidateById = createSelector(getCandidatesItemsState, (state) =>
  mapCandidateItemResponseToCandidateItem(state.currentCandidate),
);

export const getUnsavedCandidateFormValues = createSelector(getCandidatesState, (state) =>
  mapCandidateItemResponseToCandidateItem(state.candidatesConsolidation.unsavedCandidate),
);

export const getStatusesOptions = createSelector(getCandidatesAllStatusesState, (state) => {
  return state.ids.reduce(
    (acc, val) => {
      if (state.items[val].lifecycleStatus !== StatusLifecycleStatuses.Deleted) {
        acc.actualStatusOptions.push({
          value: state.items[val].id,
          title: state.items[val].localizedName,
        });
      }
      acc.statusOptions.push({
        value: state.items[val].id,
        title: state.items[val].localizedName,
      });
      return acc;
    },
    { statusOptions: [], actualStatusOptions: [] },
  );
});

export const getStatusConfigById = (statusId: string) =>
  createSelector(getCandidatesAllStatusesState, (state) => {
    return state.items[statusId];
  });

export const getCandidatesOffersFormData = (id: string) =>
  createSelector(getCandidatesOffersState, (state) => {
    if (!state.items[id]) return null;
    const offer = state.items[id];
    const data: CandidateOfferItem = {
      id: offer.id.toString(),
      offerType: offer.offerType,
      offerDate: offer.offerDate || null,
      structuralUnitId: offer.structuralUnit?.id?.toString() || '',
      position: offer.position.valueId,
      salaryGross: offer.salaryGross?.toString() || '',
      salaryProbationGross: offer.salaryProbationGross?.toString() || '',
      salaryProjectGross: offer.salaryProjectGross?.toString() || '',
      estimatedReleaseDate: offer.estimatedReleaseDate || null,
      probationPeriod: offer.probationPeriod || '',
      responseDate: offer.responseDate || null,
      city: offer.city?.id || '',
      country: offer.country?.id?.toString() || '',
      office: offer.office?.valueId || '',
      project: offer.project || '',
      specialWorkingConditions: offer.specialWorkingConditions || '',
      fileId: offer.file?.id || 0,
      candidateId: 0,
      currency: offer.currency,
    };
    return data;
  });

export const getCandidateStatusesOptionsWithNestedStatuses = createSelector(getCandidatesAllStatusesState, (state) =>
  state.ids.reduce(
    (acc, id) => ({
      ...acc,
      ...(state.items[id].lifecycleStatus !== StatusLifecycleStatuses.Deleted
        ? {
            [id]: {
              title: state.items[id].localizedName,
              value: state.items[id].id,
              active: false,
              nested: state.items[id].subStatus.length
                ? state.items[id].subStatus.reduce(
                    (nestedAcc, { id, localizedName, lifecycleStatus }) => ({
                      ...nestedAcc,
                      ...(lifecycleStatus !== StatusLifecycleStatuses.Deleted
                        ? {
                            [id]: {
                              active: false,
                              title: localizedName,
                              value: id,
                            },
                          }
                        : {}),
                    }),
                    {},
                  )
                : null,
            },
          }
        : {}),
    }),
    {},
  ),
);
