import { Modal, ModalProps, modalHoc, notificationsManager } from '@innowise-group/mui-kit';
import React from 'react';
import * as Styled from './candidate-hard-deletion-modal.styles';
import { useTranslation } from 'react-i18next';
import { useCandidatesAPI } from '@innowise-group/core';

interface CandidateHardDeletionModalProps {
  handleSubmit: () => void;
  fullName: string;
  id: number;
}

const CandidateHardDeletionModal: React.FC<ModalProps & CandidateHardDeletionModalProps> = ({
  close,
  handleSubmit,
  fullName,
  id,
}) => {
  const { t } = useTranslation();
  const { deleteCandidateById, isLoading } = useCandidatesAPI();

  const onSubmit = async () => {
    await deleteCandidateById(id, false);
    notificationsManager.success({
      subtitle: t(`notifications.text.successCandidateDeleted`, { fullName }),
    });
    close();
    await handleSubmit();
  };

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('pages.candidates.deletionModal.hardDeletionProfile')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.candidates.deletionModal.hardDeletionProfileDescription')}</Styled.Description>
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: close,
            type: 'button',
          },
          {
            title: t('buttons.delete'),
            onClick: onSubmit,
            highlighted: true,
            type: 'button',
            disabled: isLoading,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(CandidateHardDeletionModal, 'candidate-hard-delete');
