import { styled } from '@innowise-group/mui-kit';

export const StatisticContainer = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.down('ss')} {
    flex-direction: column;
  }
`;

export const RequestStatisticContainer = styled.div`
  align-self: center;
  position: relative;
`;
export const VacancyStatisticContainer = styled.div`
  align-self: start;
  ${({ theme }) => theme.breakpoints.down('ss')} {
    align-self: center;
  }
  position: relative;
`;

export const Tooltip = styled.div`
  ${({ theme }) => theme.fonts.primary[40]};
  background-color: ${({ theme }) => theme.palette.general.paper};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  border-radius: 0.625rem;
  width: max-content;
  padding: 0.625rem;
  max-width: 25rem;
  opacity: 0;
  z-index: 3;
  transition: 0.2s;
  position: fixed;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;

export const RequestTotalCountContainer = styled.div<{ isDesktopView?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0;
  width: ${({ isDesktopView }) => (isDesktopView ? 200 : 160)}px;
  height: ${({ isDesktopView }) => (isDesktopView ? 200 : 160)}px;
  pointer-events: none;
  line-height: 1;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[30]};
`;

export const RequestTotalCount = styled.span`
  font-size: 3rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  font-weight: 600;
`;

export const VacancyTotalCountContainer = styled(RequestTotalCountContainer)`
  width: 100px;
  height: 100px;
`;

export const VacancyTotalCount = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  font-weight: 600;
`;
