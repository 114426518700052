import { Input, MenuItem, Modal, ModalProps, Select, modalHoc, AvatarItem } from '@innowise-group/mui-kit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import * as Styled from './choose-employee.styles';
import { SelectOption } from '@innowise-group/core';
import { useOptionsApi } from '@innowise-group/core';

interface ChooseEmployeeModalProps {
  handleSubmit: (employee: SelectOption) => () => void;
  handleClose: () => void;
}

const ChooseEmployeeModal: React.FC<ModalProps & ChooseEmployeeModalProps> = ({ handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const { statusAuthorsOptions: employeesOptions, employeesLoading, getStatusAuthorsByRoles } = useOptionsApi();
  const [value, setValue] = useState<SelectOption | null>(null);

  const onChange = (value: string) => {
    setValue(employeesOptions.find((item) => item.value === value));
  };

  const renderEmployeeListItem = (props, value: SelectOption, { selected }) => {
    return (
      <MenuItem key={value.value} {...props} selected={selected}>
        <AvatarItem photoId={value.miniPhotoUrl} name={value.title} isOption />
      </MenuItem>
    );
  };

  useEffect(() => {
    getStatusAuthorsByRoles();
  }, []);

  return (
    <Modal.Container size="small">
      <Modal.Header onClose={handleClose}>
        <Styled.Header>{t('pages.analytics.employeeReport')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <Styled.Description>{t('pages.analytics.selectTheEmployeeDescription')}</Styled.Description>
        <Select
          value={value?.value || ''}
          onChange={onChange}
          disableClearable={!value?.value}
          options={employeesOptions || []}
          loading={employeesLoading}
          renderOption={renderEmployeeListItem}
          renderInput={(params) => {
            return (
              <Input
                {...params}
                helperText={null}
                required
                label={t('pages.analytics.employeeName')}
                placeholder={t('pages.candidates.candidateCreation.selectTheEmployee')}
              />
            );
          }}
        />
      </Modal.Body>
      <Modal.Actions
        noVerticalPadding={true}
        position={'full'}
        actions={[
          {
            title: t('buttons.cancel'),
            onClick: handleClose,
            type: 'button',
          },
          {
            title: t('buttons.generateReport'),
            onClick: handleSubmit(value),
            highlighted: true,
            type: 'button',
            disabled: !value?.value,
          },
        ]}
      />
    </Modal.Container>
  );
};

export default modalHoc(ChooseEmployeeModal, 'choose-employee-modal');
