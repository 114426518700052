import { Icon, styled, ModalContainer } from '@innowise-group/mui-kit';

export const Modal = styled(ModalContainer)``;

export const ModalTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[90]}
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  overflow: hidden auto;
  min-height: 50px;
  box-sizing: border-box;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  & > * {
    width: auto;
  }
`;

export const ModalActions = styled(Footer)`
  gap: 1rem;
  padding: 0;
`;

export const LockIcon = styled(Icon)`
  margin: 0;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;
