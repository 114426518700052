import React from 'react';
import * as Styled from './stepper.styles';
import { StepIconProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface StepperProps {
  steps: { title: string; value: number }[];
  activeStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
  const { t } = useTranslation();

  const getStepIcon = (index: number) => (props: StepIconProps) => {
    const { completed, active } = props;
    return (
      <Styled.StepIcon completed={completed} active={active}>
        {completed ? (
          <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.2577 0.637124C12.1803 0.559017 12.0881 0.497021 11.9865 0.454714C11.885 0.412407 11.7761 0.390625 11.6661 0.390625C11.556 0.390625 11.4471 0.412407 11.3456 0.454714C11.244 0.497021 11.1519 0.559017 11.0744 0.637124L4.86606 6.85379L2.25772 4.23712C2.17729 4.15942 2.08234 4.09833 1.97829 4.05733C1.87424 4.01632 1.76314 3.99621 1.65132 3.99815C1.53951 4.00008 1.42917 4.02402 1.3266 4.0686C1.22403 4.11318 1.13125 4.17752 1.05356 4.25796C0.975856 4.33839 0.914761 4.43334 0.873758 4.53739C0.832755 4.64143 0.812646 4.75254 0.814581 4.86435C0.816515 4.97617 0.840455 5.08651 0.885033 5.18908C0.929611 5.29164 0.993954 5.38442 1.07439 5.46212L4.27439 8.66212C4.35186 8.74023 4.44403 8.80223 4.54557 8.84453C4.64712 8.88684 4.75605 8.90862 4.86606 8.90862C4.97607 8.90862 5.08499 8.88684 5.18654 8.84453C5.28809 8.80223 5.38025 8.74023 5.45772 8.66212L12.2577 1.86212C12.3423 1.78409 12.4098 1.68938 12.456 1.58396C12.5022 1.47855 12.526 1.36471 12.526 1.24962C12.526 1.13454 12.5022 1.0207 12.456 0.915285C12.4098 0.809869 12.3423 0.715159 12.2577 0.637124Z"
              fill="white"
            />
          </svg>
        ) : (
          index + 1
        )}
      </Styled.StepIcon>
    );
  };

  return (
    <Styled.Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Styled.Step key={label.value}>
          <Styled.StepLabel StepIconComponent={getStepIcon(index)}>{t(label.title)}</Styled.StepLabel>
        </Styled.Step>
      ))}
    </Styled.Stepper>
  );
};

export default Stepper;
