import qs from 'qs';
import { AxiosResponse } from 'axios';
import { SortingValue } from '@constants';
import { $api } from '../axios/axios';
import { ListItemsResponse } from 'shared/core/types';
import {
  NotificationMessage,
  NotificationResponseItem,
  NotificationsGroups,
  PinRequest,
  PinnedOrder,
  ViewedRequest,
} from '@innowise-group/core';
import { getTimeZoneMilliSeconds } from '@innowise-group/utilities';

export class NotificationsService {
  static async getUnreadNotificationsCount(): Promise<AxiosResponse<{ [key in NotificationsGroups]: number }>> {
    return $api.get<{ [key in NotificationsGroups]: number }>('/recruiting-tool/api/v1/notification/unread');
  }

  static async searchAllNotifications(args: {
    params?: {
      currentPage: number;
      pageSize?: number;
      sort?: SortingValue;
      vacancyId?: string;
      boosted?: boolean;
      isRecruiterMode?: boolean;
    };
    body?: object;
  }): Promise<AxiosResponse<ListItemsResponse<NotificationResponseItem>>> {
    const { body, params } = args;
    const sortItems = params?.sort ? params?.sort?.split('%') : [];
    const newData = {
      page: params.currentPage,
      size: params.pageSize,
      sort: ['priority', ...sortItems],
      zoneOffset: getTimeZoneMilliSeconds(),
    };
    return $api.post<ListItemsResponse<NotificationResponseItem>>(
      '/recruiting-tool/api/v1/notification',
      { ...body },
      {
        params: newData,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      },
    );
  }

  static async getMessageById(id: number): Promise<AxiosResponse<NotificationMessage>> {
    return $api.get<NotificationMessage>(`/recruiting-tool/api/v1/notification/${id}`, {
      params: { zoneOffset: getTimeZoneMilliSeconds() },
    });
  }

  static async deleteMessages(ids: number[]): Promise<AxiosResponse<void>> {
    return $api.delete<void>('/recruiting-tool/api/v1/notification', { data: ids });
  }

  static async changeMessagesPinStatus(data: PinRequest): Promise<AxiosResponse<void>> {
    return $api.put<void>('/recruiting-tool/api/v1/notification/pin', { ...data });
  }

  static async changeMessagesViewedStatus(data: ViewedRequest): Promise<AxiosResponse<void>> {
    return $api.put<void>('/recruiting-tool/api/v1/notification/viewed', { ...data });
  }

  static async changePinnedNotifications(data: PinnedOrder): Promise<AxiosResponse<void>> {
    return $api.put<void>('/recruiting-tool/api/v1/notification/priority', { ...data });
  }
}
