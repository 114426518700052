import { Icon, styled } from '@innowise-group/mui-kit';

export const SectionRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0;
  & .MuiTextField-root {
    input {
      padding-left: 0.75rem;
    }
  }
  & .MuiFormControlLabel-label {
    white-space: break-spaces;
  }
`;

export const DeleteIcon = styled(Icon)`
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    transition: 0.2s;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
`;
