import { styled } from '@innowise-group/mui-kit';
import { Image } from '../../mui-components/image';
import { Icon } from '../icon';

export const Container = styled.div<{ isPointer: boolean }>`
  display: flex;
  gap: 11px;
  align-items: center;
  padding-right: 10px;
  position: relative;
  cursor: ${({ isPointer }) => isPointer && 'pointer'};
`;

export const AvatarName = styled.div`
  ${({ theme }) => theme.fonts.primary[30]};
  line-height: 22px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
`;

export const Avatar = styled(Image)<{ $isOption?: boolean }>`
  object-fit: cover;
  border-radius: 50%;
  pointer-events: none;
  margin: ${({ $isOption }) => $isOption && '3px 0'};
  width: ${({ $isOption }) => ($isOption ? '35px' : '3.125rem')};
  flex-shrink: 0;
  height: ${({ $isOption }) => ($isOption ? '35px' : '3.125rem')};
`;

export const ArrowIcon = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 2px;
  svg,
  svg:hover {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;

export const NoImageContainer = styled.div<{ isOption?: boolean }>`
  width: ${({ isOption }) => (isOption ? '35px' : '3.125rem')};
  flex-shrink: 0;
  height: ${({ isOption }) => (isOption ? '35px' : '3.125rem')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[50]};
  display: flex;
  align-items: center;
  margin: ${({ isOption }) => isOption && '3px 0'};
  justify-content: center;
`;

export const NoImageIcon = styled(Icon)`
  margin: 0;
`;
