import { Icon, styled, Button } from '@innowise-group/mui-kit';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2.5rem 2.5rem 2.5rem;
  & > * {
    width: auto;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1.25rem 1.25rem 1.25rem;
    gap: 0.75rem;
    align-items: stretch;
  }
`;

export const CustomIcon = styled(Icon)`
  margin: 0 5px 0 0;
  & svg {
    transition: 0.2s;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.state.error.default};
  }
`;

export const ClaimStatusItem = styled(Button)<{ isDeclined?: boolean }>`
  cursor: default;
  opacity: 1 !important;
  &:hover,
  & {
    color: ${({ theme, isDeclined }) =>
      isDeclined ? theme.palette.state.error.pressed : theme.palette.state.success.pressed};
    & svg {
      fill: ${({ theme, isDeclined }) =>
        isDeclined ? theme.palette.state.error.pressed : theme.palette.state.success.pressed};
    }
  }
`;

export const FeedBackButton = styled(Button)`
  &.MuiButton-root {
    margin-right: auto;
  }
`;

export const CustomButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 1;
  }
`;
