import React from 'react';
import * as Styled from './section-row.styles';

const SectionRow: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Styled.Wrapper>
      <Styled.PaddingBlock />
      <Styled.FieldsWrapper>{children}</Styled.FieldsWrapper>
    </Styled.Wrapper>
  );
};

export default React.memo(SectionRow);
