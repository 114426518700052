import { styled } from '@innowise-group/mui-kit';
import { Input } from '@innowise-group/mui-kit';
import flags from './flags.png';

export const PhoneInput = styled(Input)`
  & input {
    padding-left: ${({ InputProps }) => InputProps?.startAdornment && '5px'};
  }
`;

export const ListFlagIcon = styled.div.attrs(({ positions }: { positions: { 'background-position': string } }) => ({
  style: {
    backgroundPosition: positions['background-position'],
  },
}))<{ positions: { 'background-position': string } }>`
  width: 16px;
  height: 11px;
  margin-right: 3px;
  background-image: url(${flags});
`;

export const FlagIcon = styled.div.attrs<{ positions: { 'background-position': string } }>(({ positions }) => ({
  style: {
    backgroundPosition: positions['background-position'],
  },
}))<{ positions: { 'background-position': string } }>`
  width: 16px;
  height: 11px;
  margin-right: 3px;
  background-image: url(${flags});
  position: absolute;
  z-index: 10;
  left: 7px;
  top: 50%;
  transform: translate3d(0px, -50%, 0px);
  bottom: auto;
  object-fit: contain;
  aspect-ratio: 16/9;
`;
