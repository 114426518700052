import { styled } from '@innowise-group/mui-kit';
import { Input, Icon as BaseIcon } from '@innowise-group/mui-kit';

export const CustomInput = styled(Input)`
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiFormHelperText-root {
    display: none;
  }

  &.MuiTextField-root {
    margin-top: 0;
  }

  & input::placeholder {
    color: ${({ theme }) => theme.palette.general.lightGrey[70]};
  }
`;

export const Icon = styled(BaseIcon)`
  & svg {
    fill: ${({ theme }) => theme.palette.general.lightGrey[70]};
  }
`;
