import { MyKnownError, useCandidateFilesAPI, useProfileAPI, useResizeObserver } from '@innowise-group/core';
import { Button, Modal, ModalProps, modalHoc, useTheme } from '@innowise-group/mui-kit';
import React, { useCallback, useEffect, useRef } from 'react';
import { FilesFormatsObject, RolesValues } from '@constants';
import * as Styled from './file-preview-modal.styles';
import { PDFViewer } from './components/pdf-viewer';
import { ArchivesViewer } from './components/archives-viewer';
import { ImageViewer } from './components/image-viewer';
import { TextViewer } from './components/text-viewer';
import { Trans, useTranslation } from 'react-i18next';
import { RoleGuard } from '@shared-components';
import { notificationsManager, Loader } from '@innowise-group/mui-kit';
import { ExcelViewer } from './components/excel-viewer';
import { DocxViewer } from './components/docx-viewer';

interface FilePreviewModalProps {
  fileName: string;
  id: number;
  insideModal?: boolean;
}

const FilePreviewModal: React.FC<ModalProps & FilePreviewModalProps> = ({ fileName, id, insideModal, close }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTabletView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile } = useProfileAPI();
  const { currentFile, readFileAttachment, clearFileAttachment, isCurrentFileLoading } = useCandidateFilesAPI();
  const downloadRef = useRef<null | HTMLAnchorElement>(null);

  const type = FilesFormatsObject[fileName.split('.').reverse()[0].toLowerCase()];

  const closeCallback = useCallback(() => {
    close();
    notificationsManager.error({
      subtitle: t('notifications.text.notHaveAccess'),
    });
  }, [close, t]);

  const readFile = useCallback(async () => {
    const response = await readFileAttachment(id, fileName, closeCallback);
    if ((response.payload as MyKnownError)?.errorMessage) {
      close();
    }
  }, [id, readFileAttachment, profile]);

  useEffect(() => {
    readFile();
    return () => {
      clearFileAttachment();
    };
  }, [readFile, clearFileAttachment]);

  const getAttachment = () => {
    switch (type) {
      case FilesFormatsObject.rar:
      case FilesFormatsObject.zip:
        return <ArchivesViewer fileName={fileName} currentFile={currentFile} close={close} />;
      case FilesFormatsObject.pdf:
        return <PDFViewer currentFile={currentFile} fileName={fileName} close={close} insideModal={insideModal} />;
      case FilesFormatsObject.gif:
      case FilesFormatsObject.png:
      case FilesFormatsObject.jpeg:
      case FilesFormatsObject.jpg:
        return (
          <Styled.ModalContainer>
            <Styled.ModalActions>
              <Styled.ActionItem onClick={close}>
                <Styled.CustomIcon type="u_multiply" />
              </Styled.ActionItem>
              <RoleGuard
                roles={[
                  RolesValues.Admin,
                  RolesValues.Recruiter,
                  RolesValues.Sourcer,
                  RolesValues.ManagerFirstLevel,
                  RolesValues.ManagerThirdLevel,
                ]}
              >
                <Styled.ActionItem href={currentFile} download={fileName}>
                  <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
                </Styled.ActionItem>
              </RoleGuard>
            </Styled.ModalActions>
            <ImageViewer currentFile={currentFile} />
          </Styled.ModalContainer>
        );
      case FilesFormatsObject.txt:
        return (
          <Styled.ModalContainer>
            <Styled.ModalActions>
              <Styled.ActionItem onClick={close}>
                <Styled.CustomIcon type="u_multiply" />
              </Styled.ActionItem>
              <RoleGuard
                roles={[
                  RolesValues.Admin,
                  RolesValues.Recruiter,
                  RolesValues.Sourcer,
                  RolesValues.ManagerFirstLevel,
                  RolesValues.ManagerThirdLevel,
                ]}
              >
                <Styled.ActionItem href={currentFile} download={fileName}>
                  <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
                </Styled.ActionItem>
              </RoleGuard>
            </Styled.ModalActions>
            <TextViewer currentFile={currentFile} />
          </Styled.ModalContainer>
        );
      case FilesFormatsObject.xls:
      case FilesFormatsObject.xlsx:
        return (
          <Styled.ModalContainer>
            <Styled.ModalActions>
              <Styled.ActionItem onClick={close}>
                <Styled.CustomIcon type="u_multiply" />
              </Styled.ActionItem>
              <RoleGuard
                roles={[
                  RolesValues.Admin,
                  RolesValues.Recruiter,
                  RolesValues.Sourcer,
                  RolesValues.ManagerFirstLevel,
                  RolesValues.ManagerThirdLevel,
                ]}
              >
                <Styled.ActionItem href={currentFile} download={fileName}>
                  <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
                </Styled.ActionItem>
              </RoleGuard>
            </Styled.ModalActions>
            <ExcelViewer id={id} />
          </Styled.ModalContainer>
        );
      case FilesFormatsObject.docx:
        return (
          <Styled.ModalContainer>
            <Styled.ModalActions>
              <Styled.ActionItem onClick={close}>
                <Styled.CustomIcon type="u_multiply" />
              </Styled.ActionItem>
              <RoleGuard
                roles={[
                  RolesValues.Admin,
                  RolesValues.Recruiter,
                  RolesValues.Sourcer,
                  RolesValues.ManagerFirstLevel,
                  RolesValues.ManagerThirdLevel,
                ]}
              >
                <Styled.ActionItem href={currentFile} download={fileName}>
                  <Styled.CustomIcon type="u_download" size={20} viewBox="0 0 20 20" />
                </Styled.ActionItem>
              </RoleGuard>
            </Styled.ModalActions>
            <DocxViewer id={id} fileName={fileName} />
          </Styled.ModalContainer>
        );
      case FilesFormatsObject.doc:
        return (
          <Modal.Container size="small">
            <Modal.Header>
              <Styled.Header>{t('pages.candidates.fileAccessModal.legacyFormat')}</Styled.Header>
            </Modal.Header>
            <Modal.Body>
              <Styled.BodyContainer>
                <RoleGuard
                  roles={[
                    RolesValues.Admin,
                    RolesValues.Recruiter,
                    RolesValues.Sourcer,
                    RolesValues.ManagerFirstLevel,
                    RolesValues.ManagerThirdLevel,
                  ]}
                >
                  <Trans
                    i18nKey={t('pages.candidates.fileAccessModal.docFormatDownload')}
                    components={[<Styled.LinkItem href={currentFile} download={fileName} key={fileName} />]}
                  />
                </RoleGuard>
                <RoleGuard
                  roles={[RolesValues.Supervisor, RolesValues.ManagerSecondLevel, RolesValues.ManagerFourthLevel]}
                >
                  {t('pages.candidates.fileAccessModal.outOfDateFormat')}
                </RoleGuard>
              </Styled.BodyContainer>
            </Modal.Body>
            <Modal.Actions noVerticalPadding={true} position={'center'}>
              <Button onClick={close} type="button">
                {t('buttons.ok')}
              </Button>
            </Modal.Actions>
          </Modal.Container>
        );
      default:
        return (
          <Modal.Container size="small">
            <Modal.Header>
              <Styled.Header>{t('pages.candidates.fileAccessModal.invalidFileFormat')}</Styled.Header>
            </Modal.Header>
            <Modal.Body>
              <Styled.BodyContainer>
                <RoleGuard
                  roles={[
                    RolesValues.Admin,
                    RolesValues.Recruiter,
                    RolesValues.Sourcer,
                    RolesValues.ManagerFirstLevel,
                    RolesValues.ManagerThirdLevel,
                  ]}
                >
                  <Trans
                    i18nKey={t('pages.candidates.fileAccessModal.corruptedFile')}
                    components={[<Styled.LinkItem href={currentFile} download={fileName} key={fileName} />]}
                  />
                </RoleGuard>
                <RoleGuard
                  roles={[RolesValues.Supervisor, RolesValues.ManagerSecondLevel, RolesValues.ManagerFourthLevel]}
                >
                  {t('pages.candidates.fileAccessModal.corruptedFileAskTheAdmin')}
                </RoleGuard>
              </Styled.BodyContainer>
            </Modal.Body>
            <Modal.Actions noVerticalPadding={true} position={'center'}>
              <Button onClick={close} type="button">
                {t('buttons.ok')}
              </Button>
            </Modal.Actions>
          </Modal.Container>
        );
    }
  };

  const handleDownload = () => {
    if (downloadRef.current) {
      downloadRef.current.click();
      close();
    }
  };

  return isCurrentFileLoading ? (
    <Styled.ModalContainer>
      <Loader />
    </Styled.ModalContainer>
  ) : currentFile && isTabletView ? (
    getAttachment()
  ) : (
    currentFile && (
      <Styled.ModalContainer>
        <Modal.Header onClose={close}>
          <Styled.Header>{t('pages.candidates.fileAccessModal.fileView')}</Styled.Header>
        </Modal.Header>
        <Modal.Body>
          <Styled.SecuredContainer>
            <Trans
              i18nKey={t('pages.candidates.fileAccessModal.viewInModalWithAccess')}
              components={[<Styled.LinkItem href={currentFile} download={fileName} key={fileName} ref={downloadRef} />]}
            />
          </Styled.SecuredContainer>
        </Modal.Body>
        <Modal.Actions
          noVerticalPadding={true}
          position={'center'}
          actions={[
            {
              title: t('buttons.cancel'),
              onClick: close,
              type: 'button',
              highlighted: true,
            },
            {
              title: t('buttons.download'),
              onClick: handleDownload,
              type: 'button',
              highlighted: true,
            },
          ]}
        />
      </Styled.ModalContainer>
    )
  );
};

export default modalHoc(FilePreviewModal, 'file-preview');
