import Keycloak from 'keycloak-js';

const keycloak = new Keycloak('/config/keycloak.json');

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  keycloak
    .init({
      onLoad: 'check-sso',
    })
    .then(() => {
      onAuthenticatedCallback();
    })
    .catch(console.trace);
};

const login = keycloak.login;

const logout = () => keycloak.logout();

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback?: () => void) => keycloak.updateToken(5).then(successCallback).catch(login);

const AuthService = {
  initKeycloak,
  login,
  logout,
  isLoggedIn,
  getToken,
  updateToken,
  keycloak,
};

export default AuthService;
