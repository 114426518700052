import { Button, Icon as IconBase } from '@innowise-group/mui-kit';
import { css, styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  padding: 0 2rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 1.5rem;
  }
`;

export const OperatorButton = styled(Button).attrs<{ $active?: boolean }>(({ $active }) => ({
  variant: $active ? 'contained' : 'outlined',
}))<{ $active?: boolean }>`
  &.MuiButton-root.MuiButton-outlined {
    width: 100%;
    color: ${({ theme }) => theme.palette.general.darkGrey[90]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[90]};
  }
`;

export const SeparateButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    padding: 0 5px;
    min-width: 0;
  }
`;

export const BreakRow = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const BracketsButton = styled((props) => <SeparateButton $active {...props} />)`
  &.MuiButton-root.MuiButton-contained {
    padding-left: 5px;
    padding-right: 5px;
    min-width: unset;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.general.smart};
  }
  &.MuiButton-root.MuiButton-contained:hover {
    background-color: transparent;
  }
`;

export const InputsContainer = styled.div<{ children: React.ReactElement[] }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 5px 0;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
    margin-top: 5px;
  }
`;

export const ButtonsContainer = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
`;

export const ResetButton = styled(Button)`
  grid-area: reset;
  &.MuiButton-root.MuiButton-outlined,
  &&.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
`;

const commonGreyContainedButtonStyles = css`
  &.MuiButton-root.MuiButton-contained,
  &.MuiButton-root.MuiButton-contained:hover {
    background: ${({ theme }) => theme.palette.general.darkGrey[60]};
    &:disabled {
      opacity: 0.5;
      cursor: default;
      color: ${({ theme }) => theme.palette.general.white};
      background-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
`;

export const SubmitButton = styled(Button)`
  grid-area: submit;
  ${commonGreyContainedButtonStyles};
`;

export const SaveButton = styled(Button)`
  ${commonGreyContainedButtonStyles};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.MuiButton-root.MuiButton-contained {
      width: 100%;
    }
  }
`;

export const SaveButtonContainer = styled.div`
  grid-area: save;
`;

export const SubmitButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'reset submit'
    'save save';
  gap: 5px;
`;

export const CloseIcon = styled(IconBase).attrs(({ theme }) => ({ type: 'u_plus', fill: theme.palette.general.smart }))`
  transform: rotate(45deg);
  cursor: pointer;
  & > svg:hover {
    fill: ${({ theme }) => theme.palette.general.smart};
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.palette.state.error.default};
`;
