import { CandidateCommentItem, HistoryCommentItem } from '@innowise-group/core';

export const commentFormDefaultValues: CandidateCommentItem = {
  candidateId: 0,
  comment: '',
  isProtected: false,
  linkedVacancyId: '0',
};

export const historyCommentFormDefaultValues: HistoryCommentItem = {
  comment: '',
  isProtected: false,
  changeStoryGroupId: 0,
};
