import React, { useMemo } from 'react';
import * as Styled from '../../date-picker.styles';
import { getMonths } from '@innowise-group/core';
import { SelectOption } from '@innowise-group/core';

interface DateWithTimeHeaderProps {
  date: Date;
  startYear: number;
  endYear: number;
  yearsOptions: SelectOption[];
  onChange: (value: Date) => void;
  startDate?: Date;
  maxDate?: Date;
}

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);
today.setDate(today.getDate() + 1);

const DateWithTimeHeader: React.FC<DateWithTimeHeaderProps> = ({
  date,
  startYear,
  endYear,
  yearsOptions,
  onChange,
  startDate,
  maxDate,
}) => {
  const months = getMonths();

  const days = useMemo(() => {
    const count = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const options: SelectOption[] = Array.from({ length: count }, (_, idx) => {
      return { title: (idx + 1).toString(), value: (idx + 1).toString() };
    });
    return options;
  }, [date]);

  const isDecreaseButtonDisabled = useMemo(() => {
    if (date.getFullYear() < (startDate?.getFullYear() || startYear)) return true;
    if (date.getFullYear() > (startDate?.getFullYear() || startYear)) return false;
    if (startDate) {
      if (date.getMonth() < startDate.getMonth()) return true;
      if (date.getMonth() > startDate.getMonth()) return false;
      return date.getDate() <= startDate.getDate();
    }
    if (date.getMonth() > 0) return false;
    return date.getDate() <= 1;
  }, [date, startYear, startDate]);

  const isIncreaseButtonDisabled = useMemo(() => {
    if (date.getFullYear() > (maxDate?.getFullYear() || endYear)) return true;
    if (date.getFullYear() < (maxDate?.getFullYear() || endYear)) return false;
    if (maxDate) {
      if (date.getMonth() > maxDate.getMonth()) return true;
      if (date.getMonth() < maxDate.getMonth()) return false;
      return date.getDate() >= maxDate.getDate();
    }
    if (date.getMonth() < 11) return false;
    return date.getDate() >= 31;
  }, [date, endYear, maxDate]);

  const decreaseDay = () => {
    const newDate = new Date(new Date(date).setDate(date.getDate() - 1));
    newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
    const minDate = new Date(startDate);
    minDate.setMinutes((Math.ceil(startDate.getMinutes() / 15) * 15) % 60);
    if (startDate.getMinutes() > minDate.getMinutes()) {
      minDate.setHours(minDate.getHours() + 1);
    }
    onChange(newDate.getTime() > startDate.getTime() ? newDate : minDate);
  };

  const increaseDay = () => {
    const newDate = new Date(new Date(date).setDate(date.getDate() + 1));
    newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
    onChange(newDate);
  };

  return (
    <Styled.CalendarHeader withoutGap>
      <Styled.ChangeBlock>
        <Styled.ArrowButton variant="text" onClick={decreaseDay} disabled={isDecreaseButtonDisabled}>
          <Styled.IconItem type="u_angle-right-b" size={16} isLeft disabled={isDecreaseButtonDisabled} />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.CustomSelect
          value={date.getDate().toString()}
          options={days || []}
          filterOptions={(options) => options}
          blurOnSelect
          onChange={(value: string) => {
            const newDate = new Date(new Date(date).setDate(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            const minDate = new Date(startDate);
            minDate.setMinutes((Math.ceil(startDate.getMinutes() / 15) * 15) % 60);
            if (startDate.getMinutes() > minDate.getMinutes()) {
              minDate.setHours(minDate.getHours() + 1);
            }
            onChange(newDate.getTime() > startDate.getTime() ? newDate : minDate);
          }}
          disableClearable
          renderInput={(params) => (
            <Styled.DaysInput {...params} placeholder={days[date.getDate() - 1]?.title || ''} helperText={null} />
          )}
        />
        <Styled.CustomSelect
          value={date.getMonth().toString()}
          options={months}
          disableClearable
          filterOptions={(options) => options}
          blurOnSelect
          onChange={(value: string) => {
            const newDate = new Date(new Date(date).setMonth(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            const minDate = new Date(startDate);
            minDate.setMinutes((Math.ceil(startDate.getMinutes() / 15) * 15) % 60);
            if (startDate.getMinutes() > minDate.getMinutes()) {
              minDate.setHours(minDate.getHours() + 1);
            }
            onChange(newDate.getTime() > startDate.getTime() ? newDate : minDate);
          }}
          renderInput={(params) => (
            <Styled.MonthInput {...params} placeholder={months[date.getMonth()]?.title || ''} helperText={null} />
          )}
        />
        <Styled.CustomSelect
          value={date.getFullYear().toString()}
          options={yearsOptions}
          disableClearable
          blurOnSelect
          filterOptions={(options) => options}
          onChange={(value: string) => {
            const newDate = new Date(new Date(date).setFullYear(Number(value)));
            newDate.setMinutes((Math.ceil(date.getMinutes() / 15) * 15) % 60);
            const minDate = new Date(startDate);
            minDate.setMinutes((Math.ceil(startDate.getMinutes() / 15) * 15) % 60);
            if (startDate.getMinutes() > minDate.getMinutes()) {
              minDate.setHours(minDate.getHours() + 1);
            }
            onChange(newDate.getTime() > startDate.getTime() ? newDate : minDate);
          }}
          renderInput={(params) => (
            <Styled.YearsInput {...params} placeholder={date.getFullYear().toString()} helperText={null} />
          )}
        />
      </Styled.ChangeBlock>
      <Styled.ChangeBlock>
        <Styled.ArrowButton variant="text" onClick={increaseDay} disabled={isIncreaseButtonDisabled}>
          <Styled.IconItem type="u_angle-right-b" size={16} isRight disabled={isIncreaseButtonDisabled} />
        </Styled.ArrowButton>
      </Styled.ChangeBlock>
    </Styled.CalendarHeader>
  );
};

export default DateWithTimeHeader;
