export default class LocalStorage {
  static getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (item) return JSON.parse(item);
    return null;
  }

  static setItem(key: string, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}
