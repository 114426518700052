import React from 'react';
import { Text, Document as PDFDocument, Font, StyleSheet, Page, View, Link, Image } from '@react-pdf/renderer';
import { CandidateOfferItem, CountriesResponse, SelectOption } from '@innowise-group/core';
import { format } from 'date-fns';
import { DateFormats } from '@constants';
import Montserrat from './fonts/Montserrat-Regular.ttf';
import MontserratBold from './fonts/Montserrat-Bold.ttf';
import MontserratItalic from './fonts/Montserrat-Italic.ttf';
import HeaderImg from './assets/header.png';
import { useTranslation } from 'react-i18next';

Font.register({
  family: 'Montserrat-Regular',
  fontWeight: 'normal',
  src: Montserrat,
});
Font.register({
  family: 'Montserrat-Bold',
  fontWeight: 'bold',
  src: MontserratBold,
});
Font.register({
  family: 'Montserrat-Italic',
  fontWeight: 'normal',
  fontStyle: 'italic',
  src: MontserratItalic,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    gap: 0,
    fontSize: 11,
    fontFamily: 'Montserrat-Regular',
    padding: 15,
    color: 'black',
  },
  view: { margin: '10px 30px' },
  title: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 14,
    marginBottom: '5',
  },
  highlighted: {
    color: '#980000',
  },
  mainList: {
    flexDirection: 'column',
    gap: 5,
    margin: '5px 0',
  },
  tableTitles: {
    fontFamily: 'Montserrat-Bold',
    width: '40%',
  },
  tableValues: {
    width: '60%',
  },
  tableRow: {
    flexDirection: 'row',
    gap: 10,
  },
  salaryList: {
    margin: '5px 0',
    flexDirection: 'column',
    gap: 3,
  },
  salaryValue: {
    fontFamily: 'Montserrat-Bold',
  },
  salaryTitle: {
    width: '75%',
    maxWidth: '75%',
    flexDirection: 'row',
    gap: 10,
  },
  listMarker: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: '#980000',
    position: 'relative',
    top: 4,
  },
  link: {
    color: '#980000',
    textDecoration: 'underline',
  },
});

interface GeneratedDocumentProps {
  candidateName: string;
  unitsOptions: SelectOption[];
  jobTitleOptions: SelectOption[];
  officeOptions: SelectOption[];
  citiesOptions: SelectOption[];
  countryOptions: CountriesResponse[];
}

const GeneratedOfferDocument: React.FC<GeneratedDocumentProps & CandidateOfferItem> = ({
  candidateName,
  estimatedReleaseDate,
  probationPeriod,
  structuralUnitId,
  position,
  country,
  currency,
  salaryGross,
  salaryProbationGross,
  salaryProjectGross,
  specialWorkingConditions,
  responseDate,
  office,
  city,
  unitsOptions,
  jobTitleOptions,
  officeOptions,
  citiesOptions,
  countryOptions,
}) => {
  const { t } = useTranslation();

  const structuralUnit = structuralUnitId ? unitsOptions.find((item) => item.value === structuralUnitId) : null;
  const positionValue = position ? jobTitleOptions.find((item) => item.value === position) : null;
  const cityValue = city ? citiesOptions.find((item) => item.value === city) : null;
  const officeValue = office ? officeOptions.find((item) => item.value === office) : null;
  const countryValue = country ? countryOptions.find((item) => item.id.toString() === country) : null;

  return (
    <PDFDocument>
      <Page size="A4" wrap style={{ position: 'relative' }}>
        <Image src={HeaderImg} />
        <View style={styles.page}>
          <View style={styles.view}>
            <Text style={styles.title}>
              {t('pages.candidates.offer.goodAfternoon')}, <Text style={styles.highlighted}>{candidateName}</Text>!
            </Text>
            <Text wrap>{t('pages.candidates.offer.offerPlaceholder')}</Text>
          </View>
          <View style={styles.view}>
            <Text wrap style={{ ...styles.title, alignSelf: 'center' }}>
              {t('pages.candidates.offer.mainInformation')}
            </Text>
            <View style={styles.mainList}>
              {structuralUnit && (
                <View style={styles.tableRow}>
                  <Text style={styles.tableTitles} wrap>
                    {t('pages.candidates.offer.unit')}
                  </Text>
                  <Text style={styles.tableValues}>{structuralUnit.title}</Text>
                </View>
              )}
              {positionValue && (
                <View style={styles.tableRow}>
                  <Text style={styles.tableTitles} wrap>
                    {t('pages.candidates.offer.position')}
                  </Text>
                  <Text style={styles.tableValues}>{positionValue.title}</Text>
                </View>
              )}
              {countryValue && (
                <View style={styles.tableRow}>
                  <Text style={styles.tableTitles} wrap>
                    {t('pages.candidates.offer.location')}
                  </Text>
                  <Text style={styles.tableValues}>
                    {countryValue.name}
                    {officeValue ? `, ${officeValue.title}` : !!cityValue && `, ${cityValue.title}`}
                  </Text>
                </View>
              )}
              {estimatedReleaseDate && (
                <View style={styles.tableRow}>
                  <Text style={styles.tableTitles}>{t('pages.candidates.offer.releaseDate')}</Text>
                  <Text style={styles.tableValues}>{format(new Date(estimatedReleaseDate), DateFormats.DayFirst)}</Text>
                </View>
              )}
              {probationPeriod && (
                <View style={styles.tableRow}>
                  <Text style={styles.tableTitles}>{t('pages.candidates.offer.probation')}</Text>
                  <Text style={styles.tableValues} wrap>
                    {probationPeriod}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.view}>
            <Text wrap style={{ ...styles.title, alignSelf: 'center' }}>
              {t('pages.candidates.offer.salaryTitle')}
            </Text>
            <Text wrap>
              {!countryValue
                ? t('pages.candidates.offer.cardSalary')
                : countryValue.isoCode === 'BY'
                ? t('pages.candidates.offer.twiceSalary')
                : t('pages.candidates.offer.onceSalary')}
            </Text>
            {(salaryProbationGross || salaryGross || salaryProjectGross) && (
              <View style={styles.salaryList}>
                {salaryProbationGross && (
                  <View style={styles.tableRow} wrap>
                    <View style={styles.salaryTitle}>
                      <View style={styles.listMarker} />
                      <Text wrap>{t('pages.candidates.offer.salaryProbation')}</Text>
                    </View>
                    <Text>
                      <Text style={styles.salaryValue}>
                        {salaryProbationGross} {currency}
                      </Text>{' '}
                      (GROSS)
                    </Text>
                  </View>
                )}
                {salaryGross && (
                  <View style={styles.tableRow} wrap>
                    <View style={styles.salaryTitle}>
                      <View style={styles.listMarker} />
                      <Text wrap>{t('pages.candidates.offer.salaryValue')}</Text>
                    </View>
                    <Text>
                      <Text style={styles.salaryValue}>
                        {salaryGross} {currency}
                      </Text>{' '}
                      (GROSS)
                    </Text>
                  </View>
                )}
                {salaryProjectGross && (
                  <View style={styles.tableRow} wrap>
                    <View style={styles.salaryTitle}>
                      <View style={styles.listMarker} />
                      <Text wrap>{t('pages.candidates.offer.salaryOnProject')}</Text>
                    </View>
                    <Text>
                      <Text style={styles.salaryValue}>
                        {salaryProjectGross} {currency}
                      </Text>{' '}
                      (GROSS)
                    </Text>
                  </View>
                )}
              </View>
            )}
            <Text wrap>{t('pages.candidates.offer.privacyText')}</Text>
          </View>
          {specialWorkingConditions && (
            <View style={styles.view}>
              <Text wrap style={{ ...styles.title, alignSelf: 'center' }}>
                {t('pages.candidates.offer.optional')}
              </Text>
              <Text wrap>{specialWorkingConditions}</Text>
            </View>
          )}
          <View style={styles.view}>
            {responseDate ? (
              <Text wrap>
                {`${t('pages.candidates.offer.pleaseConsiderAndAnswerStart')} `}
                <Text style={{ ...styles.highlighted, fontFamily: 'Roboto-Bold' }}>
                  {format(new Date(responseDate), DateFormats.DayFirst)}
                </Text>
                {` ${t('pages.candidates.offer.pleaseConsiderAndAnswerEnd')}`}
              </Text>
            ) : (
              <Text wrap>{t('pages.candidates.offer.pleaseConsider')}</Text>
            )}
          </View>
          <View style={styles.view}>
            <Text
              wrap
              style={{
                fontStyle: 'italic',
                fontFamily: 'Montserrat-Italic',
              }}
            >
              {t('pages.candidates.offer.waitDecision')}
            </Text>
            <Text
              wrap
              style={{
                fontStyle: 'italic',
                fontFamily: 'Montserrat-Italic',
              }}
            >
              {t('pages.candidates.offer.withRespect')}
            </Text>
            <Text
              wrap
              style={{
                fontStyle: 'italic',
                fontFamily: 'Montserrat-Italic',
              }}
            >
              {t('pages.candidates.offer.team')}
            </Text>
          </View>
          <View style={styles.view}>
            <Text>{t('pages.candidates.offer.links')}</Text>
            <View style={styles.tableRow}>
              <View style={{ width: '33%' }}>
                <Link src="https://www.instagram.com/team.innowise/" style={styles.link}>
                  Instagram
                </Link>
              </View>
              <View style={{ width: '33%' }}>
                <Link src="https://by.linkedin.com/company/innowise-group" style={styles.link}>
                  LinkedIn
                </Link>
              </View>
              <View style={{ width: '33%' }}>
                <Link src="http://www.tiktok.com/@team.innowise" style={styles.link}>
                  TikTok
                </Link>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ width: '33%' }}>
                <Link src="https://www.facebook.com/InnowiseGroup/" style={styles.link}>
                  Facebook
                </Link>
              </View>
              <View style={{ width: '33%' }}>
                <Link src="https://www.youtube.com/channel/UCl6tO0qFu8du0CJEyCjkS3A" style={styles.link}>
                  Youtube
                </Link>
              </View>
              <View style={{ width: '33%' }}>
                <Link src="https://innowise-group.com/" style={styles.link}>
                  Innowise Group
                </Link>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </PDFDocument>
  );
};

export default GeneratedOfferDocument;
